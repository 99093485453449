import {
  rechnungsabschlussDatum,
  zweckruecklageBetrag,
  zweckruecklageName,
  zweckruecklageVorhabenart,
  zweckruecklageVorhabenkosten,
  zweckruecklageZeitplan,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageVereinRücklagenbildungZweckgebunden: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.EVDe],
  vorlageKategorie: VorlageKategorie.kapital,
  vorlage_rolleTyp: [RolleInGesellschaft.Geschftsleiter],
  vollzug: [],
  bezug: [
    "vorlageVereinRücklagenbildungWiederbeschaffung",
    "vorlageVereinRücklagenbildungFrei",
  ],
  kurzBezeichnung: "Bildung einer zweckgebundenen Rücklage (Projektrücklage)",
  metaDescription:
    "Vorstandsbeschluss zur Bildung einer zweckgebundenen Rücklage im eV/Verein. Anwaltlich geprüftes Muster jetzt kostenlos nutzen.",
  tags: [
    "Rücklage",
    "Projektrücklage",
    "zweckgebundene Rücklage",
    "Investitionsrücklage",
    "Instandhaltungsrücklage",

    "Betriebsmittelrücklage",
    "Rückstellung",
  ],
  description: `<p>Vorlage, mit welcher der Vorstand eines gemeinnützigen Vereins eine zweckgebundene Rücklage beschließen kann.</p>
  <p>Man unterscheidet die folgenden Typen:</p>
  <p><ul  data-list-style="square">
    <li>
      <p>Projektrücklage,</p>
    </li>
     <li>
      <p>Investitionsrücklage,</p>
    </li>
     <li>
      <p>Instandhaltungsrücklage,</p>
    </li>
     <li>
      <p>Betriebsmittelrücklage.</p>
    </li>
  </ul></p>`,
  antrag: `
  <p>
   Im Rechnungsabschluss zum ${insertTemplateVariable(
     rechnungsabschlussDatum
   )} wird von den vorhandenen Mitteln des Vereins der folgende Betrag als Rücklage zur Erfüllung der satzungsmäßigen Zwecke des Vereins im Sinne von § 62 Abs. 1 Nr. 1 Abgabenordnung eingestellt:
  </p>
  <ul  data-list-style="none">
    <li>
      <p> ${insertTemplateVariable(
        zweckruecklageName
      )}: ${insertTemplateVariable(zweckruecklageBetrag)}</p>
    </li>
  </ul>
<p>Die Rücklage ist für das folgende Projekt bestimmt: ${insertTemplateVariable(
    zweckruecklageVorhabenart
  )}. Das Projekt wird dem Verein voraussichtlich die folgenden Kosten verursachen: ${insertTemplateVariable(
    zweckruecklageVorhabenkosten
  )}. Der Projektabschluss ist für ${insertTemplateVariable(
    zweckruecklageZeitplan
  )} geplant.
</p>

<p>Die für die Bildung einer zweckgebundenen Rücklage geltenden gesetzlichen Vorgaben werden beachtet:</p>
  <ul  data-list-style="square">
    <li>
      <p>Die Rücklage wird für bestimmte – die steuerbegünstigten Satzungszwecke verwirklichende – Projekte gebildet. (§ 62 Abs. 1 Nr. 1 AO)</p>
    </li>
    <li>
      <p>Die Rücklage ist erforderlich, um die steuerbegünstigten, satzungsmäßigen Zwecke der Körperschaft nachhaltig erfüllen zu können. (§ 62 Abs. 1 Nr. 1 AO)</p>
    </li>
     <li>
      <p>Für die Durchführung des Projekts bestehen bereits konkrete Zeitvorstellungen bzw. die Durchführung des Projekts ist zumindest bei den finanziellen Verhältnissen des Vereins in einem angemessenen Zeitraum möglich. (AEAO zu § 62 Abs. 1 Nr. 1)</p>
    </li>

  </ul>
  `,
  templateVariables: [
    rechnungsabschlussDatum,
    zweckruecklageName,
    zweckruecklageBetrag,
    zweckruecklageVorhabenart,
    zweckruecklageVorhabenkosten,
    zweckruecklageZeitplan,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
