import { alpha, createStyles, makeStyles, Theme } from "@material-ui/core";
import { defaultTransition } from "../../../helpers/styling/defaultTransition";
import {
  mobileMenuBreakpointDown,
  mobileMenuBreakpointUp,
} from "../../../layouts/theme";
import { HeaderColor } from "../../00-globals/PageLayout/PageLayoutInterfaces";
import { GenericHeaderStyleProps } from "./GenericHeaderInterfaces";

const backgroundColor = (color: HeaderColor, theme: Theme): string =>
  color === HeaderColor.Paper
    ? theme.palette.background.default
    : theme.palette.primary.dark;

export const useGenericHeaderStyles = makeStyles((theme) => {
  const burgerButtonSize = theme.spacing(2);

  return createStyles({
    appBar: {
      zIndex: 100,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      transform: "translateY(-1px)",
    },
    appBarRoot: {
      height: ({ headerHeight }) => headerHeight,
      ...defaultTransition(
        theme,
        ["transform", "opacity", "box-shadow", "background-color"],
        "complex"
      ),
      backgroundColor: ({ color }: GenericHeaderStyleProps) =>
        alpha(backgroundColor(color, theme), 0.9),
      backdropFilter: "blur(5px)",
    },
    rightPart: {
      display: "flex",
      flexGrow: 1,
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      opacity: 1,
      ...defaultTransition(theme, "opacity"),
    },
    mainNav: {
      flexDirection: "row",
    },
    ctaButton: {
      marginRight: 0,
      ...defaultTransition(theme, "color"),
      textAlign: "center",
      marginLeft: "auto",
      [theme.breakpoints.down("md")]: {
        marginLeft: 0,
      },
    },
    loginButton: {
      marginRight: theme.spacing(0.5),
      ...defaultTransition(theme, "color"),
      textAlign: "center",
      marginLeft: "auto",
      [theme.breakpoints.down("md")]: {
        marginLeft: 0,
      },
    },
    registerButton: {
      marginLeft: theme.spacing(0.5),
      ...defaultTransition(theme, "color"),
      textAlign: "center",
      [theme.breakpoints.down("md")]: {
        marginLeft: 0,
      },
    },
    mobileOnly: {
      [theme.breakpoints.up(mobileMenuBreakpointUp)]: {
        display: "none",
      },
    },
    desktopOnly: {
      [theme.breakpoints.down(mobileMenuBreakpointDown)]: {
        display: "none !important",
      },
    },
    ctaButtonTypography: {
      marginBottom: 0,
      padding: 0,
      [theme.breakpoints.down("md")]: {
        padding: theme.spacing(1, 0),
      },
    },
    logo: {
      flexShrink: 1,
      display: "block",
      height: ({ headerHeight }) => headerHeight * 0.618, // because https://en.wikipedia.org/wiki/Golden_ratio,
    },
    logoContent: {
      height: "100%",
      width: "100%",
    },
    drawer: {
      paddingTop: ({ headerHeight }) => headerHeight,
      "& .headerNavItem": {
        display: "block",
      },
    },
    headerNavBurgerButton: {
      marginLeft: "auto",
      paddingLeft: theme.spacing(2),
      "& svg": {
        width: burgerButtonSize,
        height: burgerButtonSize,
      },
    },
    centerContent: {
      flexGrow: 1,
      justifySelf: "stretch",
      overflow: "hidden",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
  });
});
