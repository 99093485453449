import {
  ausschüttung,
  bestehenderGewinnvortrag,
  bestehenderVerlustvortrag,
  bilanzgewinn,
  bilanzStichtag,
  jahresüberschuss,
  künftigerGewinnvortrag,
  pflichtrücklage,
  rücklage,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageVerwendungDesGewinnsUG: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.UgDe],
  vorlageKategorie: VorlageKategorie.regelmäßigerBeschluss,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.selfexecuting],
  bezug: ["Entlastung"],
  tags: [
    "Gewinn",
    "Ergebnis",
    "Erlös",
    "Ertrag",
    "Marge",
    "Rendite",
    "schwarze Zahlen",
    "Überschuss",
    "profit",
    "winnings",
    "gain",
    "win",

    "Gewinnvortrag",
    "Vortrag",
    "Bilanzgewinn",
    "Kapitalrücklage",
    "freie Rücklage",

    "Ausschüttung",
    "Gewinnausschüttung",
    "Gewinnverwendung",
    "Ergebnisverteilung",
    "Verteilung",
    "Aufteilung",
    "Zuteilung",
    "Auszahlung",
    "Dividende",
    "distribution",
    "payout",
    "dividend",
    "disburse",
    "disbursement",
    "distribute",

    "Pflichtrücklage",
    "Zwangsrücklage",
    "5a",
    "gesetzliche Rücklage",
  ],

  description: `
  <p>
    Diese Vorlage ist für die besonderen Bedürfnisse der UG (haftungsbeschränkt) angepasst: Hier ist eine <strong>Pflichtrücklage von 25 % des (ggf. um einen Verlustvortrag aus dem Vorjahr verminderten) Jahresüberschusses</strong> bei der Gewinnverwendung zu berücksichtigen, sodass nur 75 % hiervon anderweitig verwendet werden kann.
  </p>
  `,
  kurzBezeichnung: "Verwendung des Gewinns (UG)",
  metaDescription:
    "Gesellschafterbeschluss für Gewinnverwendung/Gewinnausschüttung in der UG. Aus juristisch geprüften Muster/Vorlagen kostenlos Beschlussdokument erstellen.",
  antrag: `
  <p>
    Der Bilanzgewinn der Gesellschaft zum ${insertTemplateVariable(
      bilanzStichtag
    )} setzt sich wie folgt zusammen:
  </p>


  <ul data-list-style="none">
    <li><p>
      <table>
        <tr>
          <td>
            Jahresergebnis
          </td>
          <td>
              ${insertTemplateVariable(jahresüberschuss)} €
          </td>
        </tr>
        <tr>
          <td>
            zuzüglich Gewinnvortrag
          </td>
          <td>
              ${insertTemplateVariable(bestehenderGewinnvortrag)} €
          </td>
        </tr>
        <tr>
          <td>
            abzüglich Verlustvortrag
          </td>
          <td>
              ${insertTemplateVariable(bestehenderVerlustvortrag)} €
          </td>
        </tr>
         <tr>
          <td>
            Pflichtrücklage (25 % des ggf. um Verlustvortrag geminderten Jahresüberschusses)
          </td>
          <td>
              ${insertTemplateVariable(pflichtrücklage)} €
          </td>
        </tr>
            <tr>
          <td>
            <strong>Bilanzgewinn</strong>
          </td>
          <td>
              <strong>${insertTemplateVariable(bilanzgewinn)} €</strong>
          </td>
        </tr>
      </table>
    </p></li>
  </ul>


  <p>
    Der Bilanzgewinn wird wie folgt verteilt:
  </p>


  <ul data-list-style="none">
    <li><p>
      <table>

        <tr>
          <td>
            Ausschüttung an die Gesellschafter
          </td>
          <td>
              ${insertTemplateVariable(ausschüttung)} €
          </td>
        </tr>
        <tr>
          <td>
            Einstellung in die freie Rücklage
          </td>
          <td>
              ${insertTemplateVariable(rücklage)} €
          </td>
        </tr>
        <tr>
          <td>
            Gewinnvortrag
          </td>
          <td>
             ${insertTemplateVariable(künftigerGewinnvortrag)} €
          </td>
        </tr>
      </table>
    </p></li>
  </ul>
`,
  begründung: undefined,
  templateVariables: [
    bilanzStichtag,
    jahresüberschuss,
    bestehenderGewinnvortrag,
    bestehenderVerlustvortrag,
    bilanzgewinn,
    pflichtrücklage,
    ausschüttung,
    rücklage,
    künftigerGewinnvortrag,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
