import { TemplateVariable } from "../../../common-interfaces/VorlageInterface";
import { TranslatedText } from "../../../interfaces/variousInterfaces";

/**
 * A type that's used to render the "Coating"
 */
export type CoatingComponent = () => JSX.Element | null;
// export type CoatingComponent = (props: {}) => JSX.Element;

export enum VotingModeIdentifier {
  AsyncDecision = "asyncDecision",
  FormalPlenum = "formalPlenum",
  Plenum = "plenum",
  SingleDecider = "singleDecider",
}

/**
 * Describes the manner in which people come together
 *  and cast their vote on a Beschluss.
 */
export interface VotingMode {
  identifier: VotingModeIdentifier;
  label: TranslatedText;
  /**
   * This part is rendered before the Beschluss
   */
  CoatingHeader: CoatingComponent;
  /**
   * This part is rendered before the Beschluss
   */
  CoatingFooter: CoatingComponent;
  /**
   * Some voting modes require additional information to be rendered correctly.
   */
  additionalInputs?: TemplateVariable[];
  /**
   * The switch values that correspond to this voting mode.
   */
  switchValues: AllVotingModeSwitchValues;
}

/**
 * All identifiers for voting mode switches
 */
export enum VotingModeSwitchKey {
  AreMultiplePeopleInvolved = "AreMultiplePeopleInvolved",
  IsUnanimous = "IsUnanimous",
  DoPeopleMeetInPerson = "DoPeopleMeetInPerson",
}

/**
 * A single switch that's used to determine the voting mode.
 */
export interface VotingModeSwitch {
  /**
   * The question we show to the user for this switch.
   */
  question: TranslatedText;
  helpTitle: TranslatedText;
  helpText: TranslatedText;
  /**
   * Not relevant could mean:
   *  - the previous switch needs answering before we decide to display this one.
   */
  isRelevant: (switchValues: AllVotingModeSwitchValues) => boolean;
}

export type InitialSwitchValue = null;

export type SwitchValue = boolean | InitialSwitchValue;

export type AllVotingModeSwitchValues = Record<
  VotingModeSwitchKey,
  SwitchValue
>;

/**
 * The sum of all yes/no questions which define the voting mode.
 */
export type VotingModeSwitches = Record<VotingModeSwitchKey, VotingModeSwitch>;
