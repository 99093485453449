import {
  adresseBevollmächtigter,
  geburtsdatumBevollmächtigter,
  nameBevollmächtigter,
  tagVollmacht,
  vollmachtArtGeschäfte,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageHandlungsvollmachtArtErteilung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.prokura,
  vorlage_rolleTyp: [
    RolleInGesellschaft.Geschftsleiter,
    RolleInGesellschaft.Mitglieder,
  ],
  vollzug: [Vollzug.zugang],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Erteilung einer Arthandlungsvollmacht",
  tags: [
    "Handlungsvollmacht",
    "handlungsbevollmächtigt",
    "Vollmacht",
    "handelsrechtliche Vollmacht",
    "bevollmächtigten",
    "Bevollmächtigter",
    "power of attorney",
    "proxy",
    "representation",

    "erteilen",
    "Erteilung",
    "confer",
    "bestellen",
    "Bestellung",
  ],
  metaDescription:
    "Handlungsvollmacht für bestimmte Art von Geschäften (Arthandlungsvollmacht) durch Geschäftführungsbeschluss erteilen. Muster/Vorlagen kostenlos nutzen.",
  description: `
  <p>
    Die Arthandlungsvollmacht berechtigt den Bevollmächtigten, eine <strong>bestimmte Art von Geschäften</strong> vorzunehmen.
    Diese Art der Vollmacht wird oft gewählt, wenn ein Unternehmen in mehrere Bereiche untergliedert ist und dafür ein Bedürfnis besteht, den leitenden Angestellten in diesem Bereich „freie Hand” zu lassen.
  </p>
  <p>
    Im Gegensatz zur Prokura ist eine Eintragung im Handelsregister nicht erforderlich.
  </p>

  <p>
    Die Erteilung der Handelsvollmacht gegenüber dem neuen Handlungsbevollmächtigten erfolgt durch Beschluss der Geschäftsführung (vgl. <a href="/beschluss-basics/zustaendigkeit-des-geschaeftsfuehrers-fuer-die-prokuraerteilung-gesellschafterbeschluss-fuer-das-innenverhaeltnis-bgh-beschluss-vom-14-02-01974-aktenzeichen-ii-zb-6-73">BGH</a> zur Prokura).
  </p>

  <p>
    Bei einer GmbH oder UG hat die Geschäftsführung zudem grundsätzlich einen zustimmenden Gesellschafterbeschluss einzuholen (<a href="/beschluss-basics/§-46-gmbhg/">§ 46 Nr. 7 GmbHG</a>), außer etwas anderes ist im Gesellschaftsvertrag bestimmt.
  </p>

  `,
  antrag: `
  <p>
    ${insertTemplateVariable(nameBevollmächtigter)},<br/>
    geboren am ${insertTemplateVariable(geburtsdatumBevollmächtigter)},<br/>
    wohnhaft in ${insertTemplateVariable(adresseBevollmächtigter)},
  </p>

  <p>
    wird mit Wirkung ab ${insertTemplateVariable(tagVollmacht)}</p>

  <p><strong>Handlungsvollmacht</strong></p>

  <p>
  erteilt.
  </p>

  <p>
    Die Handlungsvollmacht bezieht sich auf die folgende Art von Geschäften: ${insertTemplateVariable(
      vollmachtArtGeschäfte
    )}. Die Handlungsvollmacht ist beschränkt auf die dabei gewöhnlich anfallenden Geschäfte (Arthandlungsvollmacht).
  </p>

  <p>
  Ausgeschlossen von der erteilten Handlungsvollmacht sind die Geschäfte gemäß § 54 Abs. 2 HGB (Veräußerung oder Belastung von Grundstücken, Aufnahme von Darlehen, Prozeßführung, Eingehung von Wechselverbindlichkeiten).
  </p>

    <p>
    ${insertTemplateVariable(
      nameBevollmächtigter
    )} wird angewiesen, künftig mit dem Zusatz „in Vollmacht” oder „in Vertretung“ bzw. mit der entsprechenden Abkürzung „i.V.” für die Gesellschaft zu zeichnen.
  </p>
  `,
  begründung: undefined,
  templateVariables: [
    nameBevollmächtigter,
    geburtsdatumBevollmächtigter,
    adresseBevollmächtigter,
    tagVollmacht,
    vollmachtArtGeschäfte,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
