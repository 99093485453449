import { ComponentWithChildren, useBooleanState } from "@nvon/react-toolbox";
import { noop } from "lodash-es";
import { createContext, useContext, useMemo } from "react";

interface RVOModalContext {
  isShowingChatCookieHint: boolean;
  showChatCookieHint: VoidFunction;
  hideChatCookieHint: VoidFunction;
}

const contextObject = createContext<RVOModalContext>({
  isShowingChatCookieHint: false,
  showChatCookieHint: noop,
  hideChatCookieHint: noop,
});

export const useRVOModalContext = (): RVOModalContext =>
  useContext(contextObject);

export const RVOModalContextProvider: ComponentWithChildren = (props) => {
  const {
    state: isShowingChatCookieHint,
    on: showChatCookieHint,
    off: hideChatCookieHint,
  } = useBooleanState(false);

  const value: RVOModalContext = useMemo(
    () => ({
      isShowingChatCookieHint,
      showChatCookieHint,
      hideChatCookieHint,
    }),
    [hideChatCookieHint, isShowingChatCookieHint, showChatCookieHint]
  );

  return (
    <contextObject.Provider value={value}>
      {props.children}
    </contextObject.Provider>
  );
};
