import { usePostHog } from "posthog-js/react";
import { RVOTrackingEvent } from "../components/00-globals/TruendoGoogleTagManager/trackEvent";

interface FirePosthogEventProps {
  /**
   * we use a predefined Event-Enum to stay constant with our Event-Names
   */
  eventId: RVOTrackingEvent;
  /**
   * Use this to add more data to a tracking event. Could be the name of a Board or the name of a template, that was used, for example.
   */
  additionalProps?: Record<string, unknown>;
}

interface TrackingActions {
  /**
   * Fires a posthog event by calling posthogs 'capture' method
   */
  firePosthogEvent: ({
    eventId,
    additionalProps,
  }: FirePosthogEventProps) => void;
}

/**
 * Fire a posthog tracking event
 */
export const usePosthogEvent = (): TrackingActions => {
  const posthog = usePostHog();

  const firePosthogEvent = ({
    eventId,
    additionalProps,
  }: FirePosthogEventProps) => {
    posthog.capture(eventId, additionalProps);
  };

  return {
    firePosthogEvent,
  };
};
