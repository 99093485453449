import { LegacyTemplate } from "../../data/vorlagen/vorlagenShared/VorlageInterface";

export const beschlussGeneratorPath = (
  urlSlug: LegacyTemplate["urlSlug"]
): string => `${vorlagenOverviewPath}/${urlSlug}`;

export const vorlagenOverviewPath = "/beschluss-vorlagen";

export const ueberResolvioPath = "/ueber-resolvio";

export const fristenrechnerPath = "/fristenrechner";

export const fristenrechnerKündigungGfPath =
  "/fristenrechner-kuendigung-geschaeftsfuehrer";

export const wissenPath = "/wissen";

export const wissenGrundlagenPath = "/wissen/grundlagen";

export const wissenGrundlagenPostsPath = (
  urlSlug: LegacyTemplate["urlSlug"]
): string => `${wissenGrundlagenPath}/${urlSlug}`;

export const aboutPath = "/ueber-resolvio";

export const pricingPath = "/preise";
export const pricingMitarbeiterbeteiligungPath =
  "/preise/mitarbeiterbeteiligung";

export const forTaxConsultantsPath = "/fuer-steuerberater";

export const forSupervisoryBoardsPath = "/fuer-aufsichtsraete";

export const forCondosAustriaPath = "/fuer-wegs-oesterreich";

export const securityAndPrivacyPath = "/sicherheit-und-datenschutz";

export const basicsPaths = "/beschluss-basics";

export const basicsPostsPath = (urlSlug: LegacyTemplate["urlSlug"]): string =>
  `${basicsPaths}/${urlSlug}`;

export const homePath = "/";

export const queryParameterForVotingMode = "abstimmungsmodus";
