import {
  nameEinzelunternehmer,
  nameEinzelunternehmung,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";
import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageUnbeschrankteBefreiungVomWettbewerbsverbotEinzelunternehmen: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.regelmäßigerBeschluss,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    kurzBezeichnung:
      "Unbeschränkte Befreiung vom Wettbewerbsverbot (Einzelunternehmen)",
    antrag: `${insertTemplateVariable(
      nameEinzelunternehmer
    )} ist es gestattet, ohne Einschränkung das Einzelunternehmen ${insertTemplateVariable(
      nameEinzelunternehmung
    )} zu betreiben.`,
    templateVariables: [nameEinzelunternehmer, nameEinzelunternehmung],
    vollzug: [Vollzug.zugang],
  });
