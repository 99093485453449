import { VotingMode, VotingModeIdentifier } from "../votingModeInterfaces";
import { votingModeAsyncDecision } from "./votingModeAsyncDecision";
import { votingModeFormalPlenum } from "./votingModeFormalPlenum";
import { votingModePlenum } from "./votingModePlenum";
import { votingModeSingleDecider } from "./votingModeSingleDecider";

export const votingModeByIdentifier: Record<VotingModeIdentifier, VotingMode> =
  {
    [VotingModeIdentifier.AsyncDecision]: votingModeAsyncDecision,
    [VotingModeIdentifier.FormalPlenum]: votingModeFormalPlenum,
    [VotingModeIdentifier.Plenum]: votingModePlenum,
    [VotingModeIdentifier.SingleDecider]: votingModeSingleDecider,
  };

export const allVotingModes: VotingMode[] = Object.values(
  votingModeByIdentifier
);
