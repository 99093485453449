import {
  VotingModeSwitch,
  VotingModeSwitches,
  VotingModeSwitchKey,
} from "./votingModeInterfaces";

const multiplePeople: VotingModeSwitch = {
  question: (t) => t("votingMode.switches.areMultipleInvolved.question"),
  helpTitle: (t) => t("votingMode.switches.areMultipleInvolved.helpTitle"),
  helpText: (t) => t("votingMode.switches.areMultipleInvolved.helpText"),
  isRelevant: (): true => true,
};

const isUnanimous: VotingModeSwitch = {
  question: (t) => t("votingMode.switches.isUnanimous.question"),
  helpTitle: (t) => t("votingMode.switches.isUnanimous.helpTitle"),
  helpText: (t) => t("votingMode.switches.isUnanimous.helpText"),
  isRelevant: (switchValues) =>
    switchValues[VotingModeSwitchKey.AreMultiplePeopleInvolved] === true,
};

const meetInPerson: VotingModeSwitch = {
  question: (t) => t("votingMode.switches.doPeopleMeetInPerson.question"),
  helpTitle: (t) => t("votingMode.switches.doPeopleMeetInPerson.helpTitle"),
  helpText: (t) => t("votingMode.switches.doPeopleMeetInPerson.helpText"),
  isRelevant: (switchValues) =>
    switchValues[VotingModeSwitchKey.AreMultiplePeopleInvolved] === true &&
    switchValues[VotingModeSwitchKey.IsUnanimous] === true,
};

export const votingModeSwitches: VotingModeSwitches = {
  [VotingModeSwitchKey.AreMultiplePeopleInvolved]: multiplePeople,
  [VotingModeSwitchKey.IsUnanimous]: isUnanimous,
  [VotingModeSwitchKey.DoPeopleMeetInPerson]: meetInPerson,
};
