import { Grid, Typography } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import RVOSpecialTextArea from "../../../../components/02-molecules/RVOSpecialTextArea/RVOSpecialTextArea";
import { RVOWissenTextArea } from "../../../../components/02-molecules/RVOSpecialTextArea/RVOSpecialTextAreaInterfaces";
import {
  RVOCompareUmlaufbeschlussDiligent,
  wpCodeRVOCompareUmlaufbeschlussDiligent,
} from "../../../../components/03-organisms/RVOComparisionTablesUmlaufbeschluss/RVOCompareUmlaufbeschlussDilgent";
import {
  RVOCompareUmlaufbeschlussDilitrust,
  wpCodeRVOCompareUmlaufbeschlussDilitrust,
} from "../../../../components/03-organisms/RVOComparisionTablesUmlaufbeschluss/RVOCompareUmlaufbeschlussDilitrust";
import {
  RVOCompareUmlaufbeschlussSherpany,
  wpCodeRVOCompareUmlaufbeschlussSherpany,
} from "../../../../components/03-organisms/RVOComparisionTablesUmlaufbeschluss/RVOCompareUmlaufbeschlussSherpany";
import { TagToComponentMap } from "../replaceHtmlElementInterfaces";

export interface RatingProp {
  label?: string;
  value: number;
}

const ratingRegex = /\*\*\*rating([0-5])(?:\[([^\]]*)\])?\*\*\*/;

const getRatingMatch = (str: string | undefined | null) => {
  if (typeof str === "string") {
    const ratingMatch = str.match(ratingRegex);

    const result = {
      value: ratingMatch ? Number(ratingMatch[1]) : null,
      label: ratingMatch?.[2] ?? null,
    };
    return result;
  }
};

const getRatingComponent = (rating: RatingProp) => {
  if (rating.value && rating.label) {
    return (
      <Grid
        container={true}
        style={{
          flexDirection: "row",
          gap: "10px",
          alignItems: "center",
          padding: "4px",
        }}
      >
        <Typography variant="body2" style={{ width: "200px" }}>
          {rating.label}
        </Typography>

        <Rating defaultValue={rating.value} readOnly={true} />
      </Grid>
    );
  }
  return <Rating defaultValue={rating.value} readOnly={true} />;
};

export const mappedParagraphs: TagToComponentMap = {
  p: ({ children, replacedElement }) => {
    const isTip = replacedElement.attribs.class?.includes(
      RVOWissenTextArea.Tip
    );
    const isExample = replacedElement.attribs.class?.includes(
      RVOWissenTextArea.Example
    );
    const isTldr = replacedElement.attribs.class?.includes(
      RVOWissenTextArea.Tldr
    );
    const isAttention = replacedElement.attribs.class?.includes(
      RVOWissenTextArea.Attention
    );
    const rating = getRatingMatch(children?.toString());
    const isRVOCompareUmlaufbeschlussSherpany = children
      ?.toString()
      .includes(wpCodeRVOCompareUmlaufbeschlussSherpany);
    const isRVOCompareUmlaufbeschlussDiligent = children
      ?.toString()
      .includes(wpCodeRVOCompareUmlaufbeschlussDiligent);

    const isRVOCompareUmlaufbeschlussDilitrust = children
      ?.toString()
      .includes(wpCodeRVOCompareUmlaufbeschlussDilitrust);

    if (isTip) {
      return (
        <RVOSpecialTextArea variant={RVOWissenTextArea.Tip}>
          {children}
        </RVOSpecialTextArea>
      );
    } else if (isExample) {
      return (
        <RVOSpecialTextArea variant={RVOWissenTextArea.Example}>
          {children}
        </RVOSpecialTextArea>
      );
    } else if (isTldr) {
      return (
        <RVOSpecialTextArea variant={RVOWissenTextArea.Tldr}>
          {children}
        </RVOSpecialTextArea>
      );
    } else if (isAttention) {
      return (
        <RVOSpecialTextArea variant={RVOWissenTextArea.Attention}>
          {children}
        </RVOSpecialTextArea>
      );
    } else if (rating?.value) {
      return getRatingComponent({
        value: rating.value,
        label: rating.label ?? undefined,
      });
    } else if (isRVOCompareUmlaufbeschlussSherpany) {
      return <RVOCompareUmlaufbeschlussSherpany />;
    } else if (isRVOCompareUmlaufbeschlussDiligent) {
      return <RVOCompareUmlaufbeschlussDiligent />;
    } else if (isRVOCompareUmlaufbeschlussDilitrust) {
      return <RVOCompareUmlaufbeschlussDilitrust />;
    } else {
      return <p {...replacedElement.attribs}>{children}</p>;
    }
  },
};
