import { getTemplateSlug } from "../../createVorlageLandingpage";
import { beschlussGeneratorPath } from "../frontendUrlPaths";
import { LegacyTemplateBase } from "./../../../data/vorlagen/vorlagenShared/VorlageInterface";

/**
 * Insert a link to a Vorlage inside a chunk of html.
 *
 * @param linkText By default, Vorlage.kurzBezeichnung is taken as the link text. Use this param to override it.
 *
 * @returns an html string containing the anchor element.
 */
export const insertVorlagenLink: Parameters<LegacyTemplateBase>[0]["insertVorlagenLink"] =
  (vorlage, linkText): string => {
    // We just want the kurzBezeichnung.
    // In order to get it, the vorlage must be called,
    //  but we can use noop functions.
    const vorlagePopulated = vorlage({
      insertVorlagenLink: noopReturningString,
      insertTemplateVariable: noopReturningString,
    });

    const text = linkText || vorlagePopulated.kurzBezeichnung;
    const slug = getTemplateSlug(vorlagePopulated.kurzBezeichnung);
    const href = beschlussGeneratorPath(slug);

    return `<a href="${href}">${text}</a>`;
  };

/**
 * Insert a link to a Vorlage inside a chunk of html.
 *
 * @param linkText By default, Vorlage.kurzBezeichnung is taken as the link text. Use this param to override it.
 *
 * @returns an html string containing the anchor element.
 */
export const insertVorlagenLinkApp = (
  vorlage: LegacyTemplateBase,
  linkText?: string
): string => {
  // We just want the kurzBezeichnung.
  // In order to get it, the vorlage must be called,
  //  but we can use noop functions.
  const vorlagePopulated = vorlage({
    insertVorlagenLink: noopReturningString,
    insertTemplateVariable: noopReturningString,
  });

  const text = linkText || vorlagePopulated.kurzBezeichnung;

  return `<strong>${text}</strong>`;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const noopReturningString = (_a: unknown, _b: unknown) =>
  "__this_is_not_visible";
