import {
  headerHeight,
  resolvioAppLoginUrl,
  resolvioAppRegisterUrl,
} from "../../../../../helpers/clientSide/constants";
import { homePath } from "../../../../../helpers/general/frontendUrlPaths";
import { ResolvioLogo } from "../../../../01-atoms/ResolvioLogo/ResolvioLogo";
import {
  handleLoginButtonClicked,
  handleRegisterButtonClicked,
} from "../../../../02-molecules/RVOAppPromotionContent/RVOAppPromotionContent";
import { HeaderColor, PageLayoutOptions } from "../../PageLayoutInterfaces";
import RVOHeaderCenterMenu from "./RVOHeaderCenterMenu/RVOHeaderCenterMenu";

export const defaultLayout: PageLayoutOptions = {
  logo: {
    label: (t) => t("header.zuResolvio"),
    component: ({ className }) => <ResolvioLogo className={className} />,
    href: homePath,
  },
  centerContent: RVOHeaderCenterMenu,
  color: HeaderColor.Paper,
  loginButton: {
    href: resolvioAppLoginUrl,
    label: (t) => t("header.appLogin"),
    onClick: handleLoginButtonClicked,
  },
  registerButton: {
    href: resolvioAppRegisterUrl,
    label: (t) => t("header.registerNow"),
    onClick: handleRegisterButtonClicked,
  },
  headerHeight: headerHeight,
};
