import {
  eckpunkte,
  vertragBezeichnung,
  vertragPartner,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageZustimmungVertragsabschlussEckpunkte: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [
      CompanyTypeName.GmbHDe,
      CompanyTypeName.UgDe,
      CompanyTypeName.GmbHCoKgDe,
      CompanyTypeName.UgCoKgDe,
      CompanyTypeName.GbRDe,
      CompanyTypeName.KgDe,
      CompanyTypeName.OHgDe,
      CompanyTypeName.PartGmbBDe,
      CompanyTypeName.PartGDe,
    ],
    vorlageKategorie: VorlageKategorie.geschäftsführer,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.zugang],
    bezug: [],
    zustimmungErforderlichDes: undefined,
    metaDescription: `Gesellschafterbeschluss, um Vertragsabschluss durch Geschäftsführer mit bestimmten Vertragsbedingungen zuzustimmen (Genehmigung/Einwilligung). Juristisch geprüfte Muster/Vorlagen kostenlos.`,

    description: `
  <p>
  Wenn du dir als Geschäftsführer <strong>für einen Vertragsabschluss</strong> den Segen der Gesellschafter (oder des Beirats oder Aufsichtsrats) einholen möchtest, ist diese Vorlage genau das Richtige für dich!
  </p>

  <p>
  Falls du für etwas anderes die Zustimmung der Gesellschafter brauchst, nutze die <a href="/beschluss-vorlagen/zustimmung-fur-geschaftsfuhrung-(freitext)"><strong>Freitext-Zustimmungsvorlage</strong></a>.
  </p>
  `,
    tags: [
      "Vertrag",
      "Kontrakt",
      "Einigung",
      "Deal",
      "contract",
      "Abschluss",
      "Vertragsschluss",
      "Vertragsabschluss",
      "Kauf",
      "Miet",
      "Schenk",
      "Auftrag",
      "Vergabe",
      "Order",
      "Bestellung",
      "bestellen",

      "Entscheider",
      "Entscheidungsträger",
      "Direktor",
      "Director",
      "Vorstand",
      "Vorsitzender",
      "Führung",
      "Leitung",
      "Leiter",
      "Geschäftsführung",
      "Geschäftsleitung",
      "Geschäftsleiter",
      "Chef",
      "Leiter",
      "Vorgesetzter",
      "Führungskraft",
      "Geschäftsführer",
      "Manager",
      "CEO",
      "CTO",
      "CFO",
      "leader",
      "head",
      "chief",
      "executive",
      "boss",
      "governor",
      "governance",

      "feststellen",
      "Feststellung",
      "genehmigen",
      "absegnen",
      "zustimmen",
      "grünes Licht",
      "Weg frei machen",
      "Okay geben",
      "O.K. geben",
      "OK geben",
      "Erlaubnis",
      "Unterstützung",
      "erlauben",
      "unterstützen",
      "einwilligen",
      "Einwilligung",
      "Genehmigung",
      "Zustimmung",
      "Approval",
      "blessing",
      "confirmation",
      "consent",
      "endorsement",
      "green light",
      "persmission",
      "ratification",
      "support",
    ],
    kurzBezeichnung:
      "Zustimmung zur Geschäftsführungsmaßnahme: Abschluss eines Vertrags (Eckpunkte)",

    antrag: `
  <p>
   Der Geschäftsführung wird die Zustimmung erteilt,
  </p>

  <p>
    <ul data-list-style="none">
      <li><p>
      einen ${insertTemplateVariable(vertragBezeichnung)} mit
      ${insertTemplateVariable(vertragPartner)}
      </p></li>
    </ul>
  </p>

  <p>
  im Namen der und mit Wirkung für die Gesellschaft abzuschließen.
  </p>

  <p>
    Die Zustimmung gilt nur für einen ${insertTemplateVariable(
      vertragBezeichnung
    )}, der die folgenden Eckpunkte erfüllt:
  </p>


   <ul data-list-style="none">
      <li><p>
     ${insertTemplateVariable(eckpunkte)}
      </p></li>
   </ul>

   <p>
    Zum Abschluss des vorbezeichneten Rechtsgeschäfts wird zugleich Befreiung von den Beschränkungen des § 181 BGB erteilt, soweit hierfür erforderlich.
  </p>

  `,
    begründung: undefined,
    templateVariables: [vertragBezeichnung, vertragPartner, eckpunkte],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
