import {
  createTheme,
  responsiveFontSizes,
  Theme,
} from "@material-ui/core/styles";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
/*
 * Lab components are not included in the theme. this fixes the error
 * https://github.com/mui-org/material-ui/issues/19655#issuecomment-864445820
 */
import type {} from "@material-ui/lab/themeAugmentation";
import {
  containerMaxWidth,
  containerPaddingBigDevices,
  containerPaddingSmallDevicesLeftRight,
  containerPaddingSmallDevicesTopBottom,
} from "../helpers/clientSide/constants";
import { allHeadlineTags } from "../helpers/styling/allHeadlines";
import { important } from "../helpers/styling/important";

export const fontWeightRegular = 400;
export const fontWeightBold = 600;

// Depending on which breakpoint utility you use, you need one or the other.
export const mobileMenuBreakpointUp: Breakpoint = "lg";
export const mobileMenuBreakpointDown: Breakpoint = "md";

export const accordionSpacing = 2;

export const primaryMain = "#455E81";
export const primaryDark = "#1D3054";

export const secondaryLight = "#f7f9ff";
export const secondaryMain = "#eff3fc";
export const secondaryDark = "#dee7fa";

export const successMain = "#278067";
export const errorMain = "#B65671";

export const warningLight = "#d8c69c";
export const warningMain = "#ddbf74";
export const warningDark = "#b68f2f";

export const grey = {
  100: "#eef1f5", // Light grey (shadows)
  200: "#a1a1a1", // Medium grey
};

export const fontFamilySerif = `'Playfair Display', serif`;
export const fontFamilySansSerif = `'Montserrat', sans-serif`;

export const rslvBoxShadow = `0 0.375rem 0.75rem ${grey[100]}`;

const theme = createTheme({
  shape: {
    borderRadius: 5,
  },
  palette: {
    primary: {
      main: primaryMain,
      dark: primaryDark,
    },
    secondary: {
      light: secondaryLight,
      main: secondaryMain,
      dark: secondaryDark,
      contrastText: primaryDark,
    },

    background: {
      default: "#fff", // White
      paper: "#fff", // White
    },
    grey: {
      100: grey[100], // Light grey (shadows)
      200: grey[200], // Medium grey
    },
    info: {
      main: primaryDark,
      contrastText: "#fff",
    },
    warning: {
      light: warningLight,
      main: warningMain,
      dark: warningDark,
    },
    success: {
      main: successMain,
    },
    error: {
      main: errorMain,
    },
  },
  transitions: {
    duration: {
      complex: 1000,
    },
  },
  typography: {
    fontFamily: fontFamilySansSerif,
    fontWeightRegular,
    fontWeightBold,
    h1: {
      fontFamily: fontFamilySerif,
      fontWeight: fontWeightBold,
      color: primaryDark,
      fontSize: "4.5rem",
      marginBottom: "2rem",
    },
    h2: {
      fontSize: "2.25rem",
      marginBottom: "1.5rem",
      textAlign: "left",
      fontWeight: fontWeightBold,
    },
    h3: {
      fontSize: "1.875rem",
      marginBottom: "1.5rem",
      textAlign: "left",
      fontWeight: fontWeightBold,
    },
    h4: {
      fontSize: "1.25rem",
      marginBottom: "1.5rem",
      textAlign: "left",
      fontWeight: fontWeightBold,
    },
    h5: {
      fontSize: "1.15rem",
      fontWeight: fontWeightBold,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: fontWeightBold,
    },
    body1: {
      fontSize: "1rem", // 16px
      textAlign: "left",
    },
    body2: {
      textAlign: "left",
    },
    button: {
      fontSize: "1.125rem", // 18px
    },
    subtitle1: {
      fontSize: "1rem",
      textAlign: "left",
      marginBottom: "1.8rem",
      textTransform: "uppercase",
      fontWeight: fontWeightRegular,
    },
    subtitle2: {
      fontSize: "1rem",
      fontWeight: fontWeightRegular,
      lineHeight: 1.75,
    },
    caption: {
      fontSize: "0.8rem",
      color: grey[200],
    },
    allVariants: {
      color: primaryDark,
    },
  },
  spacing: (factor) => `${1 * factor}rem`,
  props: {
    MuiPaper: {
      elevation: 5,
    },
    MuiLink: {
      underline: "always",
    },
    MuiButton: {
      disableElevation: true,
    },
    MuiCheckbox: {
      color: "primary",
    },
    MuiTooltip: {
      arrow: false,
    },
    MuiListItem: {
      disableGutters: true,
    },
    MuiTabs: {
      variant: "fullWidth",
    },
  },
});

const themeWithOverrides: Theme = {
  ...theme,
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          overflowX: "hidden",
        },
        body: {
          fontSize: theme.typography.body1.fontSize,
          color: theme.typography.body1.color,
          overflowX: "hidden",
        },
        ".wp-block-video": {
          "& video": {
            maxWidth: "100%",
          },
        },
        "span.wp-block-image > img": {
          display: "none",
        },
        [allHeadlineTags.join(", ")]: {
          "&.wp-block-heading": {
            "& img": {
              height: "1em",
            },
          },
        },
        "img.emoji": {
          width: "1em",
          height: "1em",
          display: "inline-block",
        },
        // Fixes for using @mui (v5) components
        ".MuiTimelineDot-root": {
          backgroundColor: `${theme.palette.primary.main} !important`,
          "&.MuiTimelineDot-filledSecondary": {
            backgroundColor: `${theme.palette.grey[500]} !important`,
          },
        },
        ".MuiCalendarPicker-root .MuiIconButton-root": {
          color: `${theme.palette.primary.main} !important`,
        },
        ".PrivatePickersToolbar-root * .MuiIconButton-root": {
          color: `${theme.palette.primary.main} !important`,
        },
        ".MuiRadio-colorSecondary.Mui-checked": {
          color: `${theme.palette.primary.main} !important`,
        },
        ".MuiTooltip-tooltip": {
          fontSize: "0.8em !important",
        },
        a: {
          "&, &:link, &:visited, &:hover, &:active": {
            textDecoration: "none",
          },
        },
      },
    },
    MuiFab: {
      root: {
        "&.MuiFab-primary.Mui-disabled": {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    MuiLink: {
      root: {
        textUnderlineOffset: "3px",
        paddingBottom: "3px",
        marginTop: "3px",
      },
      underlineAlways: {
        "&, &:link, &:visited, &:hover, &:active": {
          textDecoration: "underline",
        },
      },
    },
    MuiTypography: {
      colorTextPrimary: {
        color: theme.palette.primary.dark,
      },
      colorTextSecondary: {
        color: theme.palette.secondary.light,
      },
      h2: {
        fontWeight: fontWeightBold,
        "&.marginTop": {
          marginTop: theme.spacing(2),
        },
      },
      h3: {
        fontWeight: fontWeightBold,
        "&.marginTop": {
          marginTop: theme.spacing(2),
        },
      },
      h4: {
        fontWeight: fontWeightBold,
        "&.marginTop": {
          marginTop: theme.spacing(2),
        },
      },
      body1: {
        fontWeight: fontWeightRegular,
        "&.marginBottom": {
          marginBottom: theme.spacing(1),
        },
      },
      button: {
        letterSpacing: "0.019rem", // 0.3px
        textTransform: "none",
        fontWeight: fontWeightBold,
      },
    },
    MuiContainer: {
      root: {
        position: "relative",
        [theme.breakpoints.up("lg")]: {
          maxWidth: `${containerMaxWidth}px !important`,
        },
        paddingTop: theme.spacing(containerPaddingBigDevices),
        [theme.breakpoints.down("sm")]: {
          paddingTop: theme.spacing(containerPaddingSmallDevicesTopBottom),
          paddingLeft: theme.spacing(containerPaddingSmallDevicesLeftRight),
          paddingRight: theme.spacing(containerPaddingSmallDevicesLeftRight),
        },
      },
    },
    MuiAppBar: {
      root: {
        color: theme.palette.background.default,
        elevation: 0,
      },
    },
    MuiTooltip: {
      arrow: {
        "&::before": {
          border: `1px solid ${theme.palette.secondary.dark}`,
        },
        color: theme.palette.secondary.light,
      },
      tooltip: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.secondary.dark}`,
      },
    },
    MuiPaper: {
      root: {
        color: theme.typography.body1.color,
        backgroundColor: theme.palette.background.default,
        "&:focus-visible": {
          outline: "none",
        },
      },
    },
    MuiBackdrop: {
      root: {
        backdropFilter: "blur(5px)",
      },
    },
    MuiDrawer: {
      root: {
        zIndex: important(10),
      },
    },
    MuiChip: {
      root: {
        borderRadius: "20px!important",
        borderColor: theme.palette.secondary.dark,
        backgroundColor: theme.palette.secondary.main,
      },
      deleteIcon: {
        color: theme.palette.primary.light,
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        "& .MuiTypography-root": {
          textAlign: "center",
        },
      },
      outlined: {
        border: `2px solid ${theme.palette.primary.dark}`,
      },
      contained: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        "&:hover": {
          backgroundColor: primaryMain,
        },
        // make sure bg color is also dark on non-hover devices like smartphones
        // because default is lightgrey
        "@media (hover: none)": {
          "&:hover": {
            backgroundColor: important(primaryDark),
          },
        },
      },
      containedPrimary: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        "&:hover": {
          backgroundColor: important(primaryDark),
        },
        // make sure bg color is also dark on non-hover devices like smartphones
        // because default is lightgrey
        "@media (hover: none)": {
          "&:hover": {
            backgroundColor: primaryMain,
          },
        },
      },
    },
    MuiCard: {
      root: {
        padding: theme.spacing(1), // 32px
        boxShadow: rslvBoxShadow,
      },
    },
    MuiAccordion: {
      root: {
        margin: "0 auto !important",
        backgroundColor: "transparent",
        boxShadow: "none",
        "&.MuiAccordion-rounded:nth-of-type(1):before": {
          backgroundColor: "transparent",
        },
        "&.MuiAccordion-rounded:first-child": {
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        },
        "&.MuiAccordion-rounded:last-child": {
          border: "none",
        },
        "&.Mui-expanded": {
          backgroundColor: "#FFF !important",
          borderBottom: "0px !important",
          borderRadius: "8px !important",
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: theme.spacing(accordionSpacing),
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: theme.spacing(accordionSpacing),
        paddingTop: 0,
        paddingBottom: theme.spacing(accordionSpacing / 2),
      },
    },
    MuiAccordionActions: {
      root: {
        padding: theme.spacing(accordionSpacing),
        paddingTop: 0,
      },
    },
    MuiAutocomplete: {
      paper: {
        boxShadow: rslvBoxShadow,
      },
    },
    MuiCheckbox: {
      root: {
        color: theme.palette.primary.main,
      },
    },
    MuiInputLabel: {
      root: {
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiStep: {
      vertical: {
        width: "100%",
      },
    },
    MuiStepButton: {
      vertical: {
        boxShadow: rslvBoxShadow,
        alignItems: "center",
        justifyContent: "center",
      },
    },
    MuiStepIcon: {
      completed: {
        color: important(theme.palette.success.main),
      },
    },
    MuiStepContent: {
      root: {
        paddingRight: 0,
      },
    },
    MuiInputBase: {
      root: {
        "&.Mui-disabled": {
          opacity: 0.6,
        },
      },
      input: {
        background: theme.palette.background.default,
        "&.Mui-disabled": {
          backgroundColor: theme.palette.grey[50],
        },
      },
    },
    MuiFormControl: {
      root: {
        margin: theme.spacing(1, 0, 0, 0),
      },
    },
    MuiToggleButton: {
      root: {
        color: theme.palette.grey[800],
        padding: theme.spacing(0.2, 1.7),
        "&.Mui-selected": {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
          "&:hover": {
            backgroundColor: important(theme.palette.primary.dark),
            color: theme.palette.common.white,
          },
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: "unset",
        paddingRight: theme.spacing(0.5),
      },
      alignItemsFlexStart: {
        marginTop: "4px",
      },
    },
    MuiTabs: {
      indicator: {
        backgroundColor: theme.palette.primary.main,
      },
      root: {
        backgroundColor: theme.palette.secondary.light,
        borderRadius: theme.shape.borderRadius,
      },
    },
    MuiTab: {
      root: {
        textTransform: "none",
      },
      selected: {
        color: theme.palette.primary.dark,
        backgroundColor: theme.palette.secondary.main,
      },
    },
  },
};

const themeWithResponsiveFontSizes = responsiveFontSizes(themeWithOverrides, {
  factor: 3,
  breakpoints: ["xs", "sm", "md", "lg", "xl"],
});

export default themeWithResponsiveFontSizes;
