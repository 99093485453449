import {
  beschlussDatumFrühereEinziehung,
  geschäftsanteileNennbetragEinzelNeu,
  geschäftsanteileNennbetragGesamtFrühereEinziehung,
  geschäftsanteileNeuAnzahl,
  geschäftsanteileNummernNeu,
  nameGesellschafterEingezogen,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGesellschafterEinziehungRevalorisierung: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.ausscheidenEinesGesellschafters,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.handelsregisterGesellschafterliste],
    bezug: [
      "GesellschafterEinziehungAusWichtigemGrund",
      "GesellschafterEinziehungAusSonstigemGrund",
      "GesellschafterEinziehungZustimmung",
      "GesellschafterEinziehungAufstockung",
    ],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung:
      "Revalorisierung nach Einziehung (Neubildung eines Geschäftsanteils)",
    description: undefined,
    antrag: `
  <p>Mit Beschluss vom ${insertTemplateVariable(
    beschlussDatumFrühereEinziehung
  )} wurde die Einziehung der Geschäftsanteile von ${insertTemplateVariable(
      nameGesellschafterEingezogen
    )} mit einem Gesamt-Nennbetrag von ${insertTemplateVariable(
      geschäftsanteileNennbetragGesamtFrühereEinziehung
    )} beschlossen, sodass die Summe der Nennbeträge der Geschäftsanteile und das satzungsmäßige Stammkapital nunmehr in Höhe von ${insertTemplateVariable(
      geschäftsanteileNennbetragGesamtFrühereEinziehung
    )} auseinanderfallen. </p>

  <ol><li><p> Zur Wiederherstellung der Konvergenz der Nennbetragssumme aller Geschäftsanteile mit dem satzungsmäßigen Stammkapital werden ${insertTemplateVariable(
    geschäftsanteileNeuAnzahl
  )} neue Geschäftsanteile mit den laufenden Nummern ${insertTemplateVariable(
      geschäftsanteileNummernNeu
    )} zum Nennbetrag von je ${insertTemplateVariable(
      geschäftsanteileNennbetragEinzelNeu
    )} gebildet. Diese neugebildeten Geschäftsanteile werden der Gesellschaft als eigene Anteile zugeordnet.</p></li>

    <li><p>Die Geschäftsführung wird angewiesen, eine aktualisierte Gesellschafterliste gemäß § 40 Abs. 1 GmbHG zum Handelsregister einzureichen und eine Meldepflicht der Gesellschaft zum Transparenzregister zu prüfen und ggf. zu erfüllen.</p></li>

</ol>`,
    begründung: undefined,
    templateVariables: [
      beschlussDatumFrühereEinziehung,
      nameGesellschafterEingezogen,
      geschäftsanteileNennbetragGesamtFrühereEinziehung,
      geschäftsanteileNeuAnzahl,
      geschäftsanteileNummernNeu,
      geschäftsanteileNennbetragEinzelNeu,
    ],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: true,
    noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass aufgrund des geänderten Gesellschafterbestandes eine aktualisierte Gesellschafterliste zum Handelsregister durch die Geschäftsführer eingereicht werden muss (§ 40 Abs. 1 GmbHG). Die neue Gesellschafterliste muss von den Geschäftsführern in vertretungsberechtigter Zahl unterschrieben und über einen Notar zum Handelsregister eingereicht werden.</p>`,
  });
