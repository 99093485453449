import { GrCase } from "../../../interfaces/GrCase";
import { GrNumber } from "../../../interfaces/grNumber";
import { AnyRole } from "../../../interfaces/variousInterfaces";
import { CompanyTypeName } from "../../vorlagen/vorlagenShared/generatedTypes/CompanyTypeName";
import { assertCompanyTypeLandingpage } from "../isCompanyTypeLandingpage";
import { isRegularRole } from "../namesForRoles/getRoleName";
import {
  meetingNamesForAdditionalRoles,
  meetingNamesForRechtsform,
} from "./meetingNamesForRechtsform";

const defaultCase: GrCase = GrCase.Nominative;
const defaultNumber: GrNumber = GrNumber.Singular;

/**
 * Return the meeting name for a certain role in a Rechtsform.
 *
 * Can be singular or plural, and different cases.
 */
export const getMeetingName = (
  role: AnyRole | null,
  companyType: CompanyTypeName | null,
  grNumber: GrNumber = defaultNumber,
  grCase: GrCase = defaultCase
): string | null => {
  if (!role || !companyType || !grNumber || !grCase) {
    return null;
  }

  assertCompanyTypeLandingpage(companyType);

  if (isRegularRole(role)) {
    return meetingNamesForRechtsform[companyType][role][grNumber][grCase];
  }

  return meetingNamesForAdditionalRoles[role][grNumber][grCase];
};
