import { createStyles, makeStyles } from "@material-ui/core";

export const useDefaultPageLayoutStyles = makeStyles(() =>
  createStyles({
    layoutWrap: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
    main: {
      paddingTop: ({ headerHeight }: { headerHeight: number }) => headerHeight,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      scrollMarginTop: ({ headerHeight }: { headerHeight: number }) =>
        headerHeight,
    },
  })
);
