import { Box, Container } from "@material-ui/core";
import { clsx } from "clsx";
import { RVOContainerProps } from "./RVOContainerInterfaces";
import { useRVOContainerStyles } from "./RVOContainerStyles";

const RVOContainer = (props: RVOContainerProps): JSX.Element => {
  const classes = useRVOContainerStyles(props);

  return (
    <Box
      id={props.id || undefined}
      className={clsx(classes.wrapper, props.className)}
    >
      <Container className={classes.container}>{props.children}</Container>
    </Box>
  );
};

export default RVOContainer;
