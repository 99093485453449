import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useCallback, useMemo } from "react";
import { inferVotingModeFromSwitches } from "../../data/juristicalData/votingModes/inferVotingModeFromSwitches";
import {
  AllVotingModeSwitchValues,
  InitialSwitchValue,
  SwitchValue,
  VotingMode,
  VotingModeSwitchKey,
} from "../../data/juristicalData/votingModes/votingModeInterfaces";
import { useAppDispatch, useAppSelector } from "../hooks";
import { RootState } from "../storeInterfaces";
import { stateVotingModeEmpty } from "./states/stateVotingModeEmpty";

export const initialSwitchValue: InitialSwitchValue = null;

export interface VotingModeState {
  /**
   * Input for all voting modes. null means untouched.
   */
  switchValues: AllVotingModeSwitchValues;
  /**
   * A global trigger to enable changing the voting mode anywhere,
   *  even outside of the EphemeralUserInput context.
   */
  changeVotingModeTrigger: number;
}

interface SwitchAction {
  key: VotingModeSwitchKey;
  value: SwitchValue;
}

export const votingModeSlice = createSlice({
  name: "votingMode",
  initialState: stateVotingModeEmpty,
  reducers: {
    setSwitchValue: (state, action: PayloadAction<SwitchAction>) => {
      const { key, value } = action.payload;
      state.switchValues[key] = value;
    },
    triggerVotingModeChange: (state) => {
      state.changeVotingModeTrigger++;
    },
    resetVotingModeTrigger: (state) => {
      state.changeVotingModeTrigger = 0;
    },
  },
});

// Actions …

export const {
  setSwitchValue: setSwitchValueUserAction,
  triggerVotingModeChange: triggerVotingModeChangeUserAction,
  resetVotingModeTrigger: resetVotingModeTriggerUserAction,
} = votingModeSlice.actions;

// … and hooks to use the actions

export const useSetSwitchValue = (): ((
  switchKey: VotingModeSwitchKey,
  value: SwitchValue
) => void) => {
  const dispatcher = useAppDispatch();
  return (key, value) => dispatcher(setSwitchValueUserAction({ key, value }));
};

export const useTriggerVotingModeChange = (): VoidFunction => {
  const dispatcher = useAppDispatch();
  return () => dispatcher(triggerVotingModeChangeUserAction());
};

export const useResetVotingModeTrigger = (): VoidFunction => {
  const dispatcher = useAppDispatch();
  return useCallback(
    () => dispatcher(resetVotingModeTriggerUserAction()),
    [dispatcher]
  );
};

// Selectors …

export const selectSwitchValues = (
  state: RootState
): VotingModeState["switchValues"] => state.votingMode.switchValues;

export const selectVotingModeTrigger = (state: RootState): number =>
  state.votingMode.changeVotingModeTrigger;

// … and hooks to get the data from the selectors

export const useSwitchValues = (): ReturnType<typeof selectSwitchValues> =>
  useAppSelector(selectSwitchValues);

export const useVotingModeChangeTrigger = (): number =>
  useAppSelector(selectVotingModeTrigger);

/**
 * The selected voting mode.
 *
 * This is currently inferred from the given switchValues.
 */
export const useSelectedVotingMode = (): VotingMode | null => {
  const switchValues = useSwitchValues();

  const inferredMode = useMemo(
    () => inferVotingModeFromSwitches(switchValues),
    [switchValues]
  );

  return inferredMode;
};

export default votingModeSlice.reducer;
