import {
  adresseLiquidator,
  geburtsdatumLiquidator,
  nameLiquidator,
  tagBestellungLiquidator,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageLiquidatorBestellung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.OHgDe,
  ],
  vorlageKategorie: VorlageKategorie.liquidation,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.zugang, Vollzug.handelsregisterAnmeldung],
  bezug: [
    "LiquidatorVertretungsbefugnisEinzel",
    "LiquidatorVertretungsbefugnisGesamt",
    "LiquidatorBefreiung181",
  ],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Bestellung eines neuen Liquidators",
  description: undefined,
  antrag: `Zum neuen Liquidator der Gesellschaft wird mit Wirkung ab ${insertTemplateVariable(
    tagBestellungLiquidator
  )} bestellt:

  <p>${insertTemplateVariable(nameLiquidator)},<br/>
  geboren am ${insertTemplateVariable(geburtsdatumLiquidator)},<br/>
  wohnhaft/geschäftsansässig in ${insertTemplateVariable(
    adresseLiquidator
  )}.</p>`,
  begründung: undefined,
  templateVariables: [
    tagBestellungLiquidator,
    nameLiquidator,
    geburtsdatumLiquidator,
    adresseLiquidator,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: true,
  noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss. Die Handelsregisteranmeldung muss von den Geschäftsführern (bei einer GmbH oder UG) bzw. persönlich haftenden Gesellschaftern (bei KG/OHG) in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
});
