import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { ReactNode } from "react";
import { useRVOImageHighlightBoxStyles } from "./RVOImageHighlightBoxStyles";

const RVOImageHighlightBox = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useRVOImageHighlightBoxStyles({ isSmallScreen });

  return <Box className={classes.wrapper}>{children}</Box>;
};

export default RVOImageHighlightBox;
