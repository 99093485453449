import { createStyles, makeStyles } from "@material-ui/core";
import { fontWeightRegular } from "../../../../layouts/theme";

export const useTemplateVariableRenderedStyles = makeStyles((theme) =>
  createStyles({
    button: {
      textDecoration: "none!important",
    },
    beschlussVariable: {
      color: theme.palette.primary.main,
      background: theme.palette.secondary.main,
      padding: theme.spacing(0.1, 0.3),
      fontWeight: fontWeightRegular,
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "transparent",
    },
    beschlussVariableFilled: {
      /* we change the color instead of removing the border entirely to prevent jumping */
      borderColor: "transparent",
      color: `${theme.palette.common.black} !important`,
      backgroundColor: theme.palette.grey[50],
      background: "none",
      letterSpacing: 0,
    },
    beschlussVariableFocused: {
      color: theme.palette.primary.main,
      borderColor: theme.palette.warning.light,
    },
    beschlussVariableGenerating: {
      padding: theme.spacing(0),
      border: "none",
      letterSpacing: 0,
      background: "none",
      backgroundColor: "unset",
      fontWeight: fontWeightRegular,
    },
  })
);
