import {
  grundOrganhaftung,
  nameGeschäftsführer,
  nameProzessvertreter,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGeschaftsfuhrerGeltendmachungSchadenersatzanspruche: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.geschäftsführerAusscheiden,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [],
    bezug: [
      "GeschäftsführerAbberufung",
      "GeschäftsführerAnstellungsvertragKündigungAusWichtigemGrund",
      "GeschäftsführerAnstellungsvertragKündigungOrdentlich",
      "GeschäftsführerEntlastungVerweigerung",
    ],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung:
      "Geltendmachung von Schadenersatzansprüchen gegen den Geschäftsführer",
    description: undefined,
    antrag: `<ol><li><p>Die Gesellschaft macht gegenüber ${insertTemplateVariable(
      nameGeschäftsführer
    )}  im Zusammenhang mit ${insertTemplateVariable(
      grundOrganhaftung
    )}  geltend.</p></li>

<li><p>${insertTemplateVariable(
      nameProzessvertreter
    )} wird beauftragt, die vorbezeichneten Ansprüche zu prüfen und diese außergerichtlich sowie gerichtlich geltend zu machen. ${insertTemplateVariable(
      nameProzessvertreter
    )}  wird insoweit zum Prozessvertreter der Gesellschaft bestellt.</p></li>

<li><p>Zum Abschluss eines Vergleiches zwischen der Gesellschaft und ${insertTemplateVariable(
      nameGeschäftsführer
    )} ist ${insertTemplateVariable(
      nameProzessvertreter
    )}  nicht ermächtigt. Ein solcher Vergleich bedarf der ausdrücklichen vorherigen Zustimmung der Gesellschafter.</p></li></ol>
`,
    begründung: undefined,
    templateVariables: [
      nameGeschäftsführer,
      grundOrganhaftung,
      nameProzessvertreter,
    ],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
