import { useTranslation } from "react-i18next";
import { UserlikeChat } from "../../../__generated__/svgComponents/UserlikeChat";
import { useRVOModalContext } from "../../00-globals/RVOModalContextProvider/RVOModalContextProvider";
import { RVOHelpButtonButtonOnly } from "../../02-molecules/RVOHelpButton/RVOHelpButtonButtonOnly";
import { useRVOChatBubblePlaceholderStyles } from "./RVOChatBubblePlaceholderStyles";

/**
 * Placeholder, showing until:
 *  - the user has accepted the cookies to show our chat widet
 *  - and the chat widget is fully loaded
 */
const RVOChatBubblePlaceholder = (): JSX.Element | null => {
  const classes = useRVOChatBubblePlaceholderStyles();
  const { t } = useTranslation();
  const { showChatCookieHint } = useRVOModalContext();

  return (
    <RVOHelpButtonButtonOnly
      helpTitle={t("chatButton.chatButtonOverlayTitle")}
      iconOverride={<UserlikeChat />}
      helpButtonIconClassName={classes.icon}
      className={classes.bubble}
      onClick={showChatCookieHint}
    />
  );
};

export default RVOChatBubblePlaceholder;
