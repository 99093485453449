import { initializeDataLayer } from "./startGoogleTagManager";

/**
 * The event values match events in google tag manager.
 */
export enum RVOTrackingEvent {
  GeneratePdfClicked = "generatePdfClicked",
  PdfDownloadClicked = "pdfDownloadClicked",
  SkipPromotionClicked = "skipPromotionClicked",
  RegisterOnAppClicked = "registerOnAppClicked",
  LoginOnAppClicked = "loginOnAppClicked",
  NotarizeFormOpened = "notarizeFormOpened",
  BookDemoClicked = "BookDemoClicked",
  CallResolvioClicked = "CallResolvioClicked",
  WatchDemoVideoClicked = "WatchDemoVideoClicked",
  VideoPlayed = "VideoPlayed",
}

export const trackEvent = (event: RVOTrackingEvent): void => {
  initializeDataLayer();
  window.dataLayer.push({ event });
};
