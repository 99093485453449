import { Fragment } from "react";

/**
 * Combine multiple lines of text into a single fragment,
 *  which joins the lines using <br /> tags.
 */
export const multilineTextFragment = (
  ...linesOfText: string[]
): JSX.Element => (
  <>
    {linesOfText.map((line, index) => {
      const isLastLine = index + 1 === linesOfText.length;
      return (
        // It's all we have here.
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          {line}

          {isLastLine ? null : <br />}
        </Fragment>
      );
    })}
  </>
);
