import { useMediaQuery } from "@material-ui/core";
import { clsx } from "clsx";
import theme from "../../../layouts/theme";
import { ResolvioLogoProps } from "./ResolvioLogoInterfaces";
import { useResolvioLogoStyles } from "./ResolvioLogoStyles";
import RawSvgFile from "./resolvio-logo.svg";

const viewBoxCheckOnly = "0 0 138 96.3";

export const ResolvioLogo = ({
  className,
  collapseOnMobile,
}: ResolvioLogoProps): JSX.Element => {
  const classes = useResolvioLogoStyles();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const collapse = isMobile && collapseOnMobile;

  return (
    <RawSvgFile
      height={55}
      width={collapse ? "85" : "255"}
      className={clsx(className, classes.svg)}
      viewBox={collapse ? viewBoxCheckOnly : undefined}
    />
  );
};
