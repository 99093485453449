import { extend, locale } from "dayjs";
import localeDataPlugin from "dayjs/plugin/localeData";
import localizedFormatPlugin from "dayjs/plugin/localizedFormat";
import { useEffect } from "react";
import { defaultLanguage } from "../../../../config/LanguageConfiguration";

extend(localizedFormatPlugin);
extend(localeDataPlugin);
require("dayjs/locale/de");

/*
 * Could also be done at runtime:
 * https://day.js.org/docs/en/i18n/changing-locale
 *
 * Problem with this appraoch is, it does not cause a re-render in react.
 * So when moving this appraoch to a multi-lang page, make sure it does.
 */
locale(defaultLanguage);

/**
 * Side effects that dayjs needs to run properly:
 * - loading plugins
 * - setting locale
 *
 * This should be used on a global component.
 */
export const useDayJs = (): void => {
  useEffect(() => {
    /*
     * Right now, nothing is happening here.
     * Sadly, both extending the plugins and setting the locale must be done upfront.
     *
     * This function, right now, just serves as a "carrier",
     *  so that the global stuff (dayjs extends & requires) gets included.
     */
  }, []);
};
