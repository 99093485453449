import { VotingModeSwitch, VotingModeSwitchKey } from "./votingModeInterfaces";
import { votingModeSwitches } from "./votingModeSwitches";

/**
 * All voting mode entries as key/value objects.
 */

export const votingModeSwitchEntries: [
  VotingModeSwitchKey,
  VotingModeSwitch
][] = Object.entries(votingModeSwitches) as [
  VotingModeSwitchKey,
  VotingModeSwitch
][];
