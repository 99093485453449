import { createStyles, makeStyles, Theme } from "@material-ui/core";
import {
  fontWeightBold,
  mobileMenuBreakpointDown,
} from "../../../../layouts/theme";

export const useNVONHeaderLinkStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerLink: {
      display: "inline-block",
      marginLeft: theme.spacing(4),
      "& span": {
        display: "block",
      },
      [theme.breakpoints.down(mobileMenuBreakpointDown)]: {
        textAlign: "center",
        display: "block",
        marginLeft: 0,
        padding: theme.spacing(1, 0),
      },
    },
    headerNavLi: {
      listStyle: "none",
      "& > a": {
        marginBottom: 0,
        "& > span": {
          marginBottom: 0,
          textAlign: "center",
          fontWeight: fontWeightBold,
          "& > p": {
            padding: 0,
          },
        },
      },
      [theme.breakpoints.down(mobileMenuBreakpointDown)]: {
        width: "100%",
      },
    },
  })
);
