import { Box, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { RVOFootnotesSectionProps } from "./RVOFootnotesSectionInterfaces";
import { useRVOFootnotesSectionStyles } from "./RVOFootnotesSectionStyles";

const RVOFootnotesSection = (props: RVOFootnotesSectionProps): JSX.Element => {
  const classes = useRVOFootnotesSectionStyles();
  const { t } = useTranslation();

  return (
    <Box mt={4}>
      <Typography variant="h4" component="h2">
        {t("general.footnotes")}
      </Typography>

      <ol className={classes.list}>{props.children}</ol>
    </Box>
  );
};

export default RVOFootnotesSection;
