import {
  auftragGegenstand,
  auftragKanzlei,
  auftragVergütung,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageZustimmungVertragsabschlussBeauftragungRA: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [
      CompanyTypeName.GmbHDe,
      CompanyTypeName.UgDe,
      CompanyTypeName.GmbHCoKgDe,
      CompanyTypeName.UgCoKgDe,
      CompanyTypeName.GbRDe,
      CompanyTypeName.KgDe,
      CompanyTypeName.OHgDe,
      CompanyTypeName.PartGmbBDe,
      CompanyTypeName.PartGDe,
    ],
    vorlageKategorie: VorlageKategorie.geschäftsführer,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.zugang],
    bezug: [],
    zustimmungErforderlichDes: undefined,
    metaDescription: `Gesellschafterbeschluss, um Beauftragung eines Anwalts durch Geschäftsführer zuzustimmen (Genehmigung/Einwilligung). Juristisch geprüfte Muster/Vorlagen kostenlos.`,

    kurzBezeichnung:
      "Zustimmung zur Geschäftsführungsmaßnahme: Beauftragung eines Rechtsanwalts",
    description: `
  <p>
    Diese Vorlage ist auf die Zustimmung zur Beauftragung eines Rechtsanwalts zugeschnitten. Dieser Zustimmungsbeschluss ist typischerweise <strong>in Startups erforderlich</strong>, weil dort oft niedrige Wertgrenzen für das zustimmungsfreie Tätigwerden des Geschäftsführer festgelegt sind. Rechtsanwaltliche Beratung kann z.B. bei der <strong>Markenanmeldung</strong> erforderlich sein.
  </p>

    <p>
  Sofern die Zustimmung für einen bereits abgeschlossenen Vertrag eingeholt werden soll, kann auch auf die Vorlage <a href="/beschluss-vorlagen/zustimmung-fur-geschaftsfuhrung-zum-abschluss-eines-vertrags-(mit-anlage)"><strong>Zustimmung zum Abschluss eines Vertrags (mit Anlage)</strong></a> zurückgegriffen werden.
  </p>

  <p>
  Falls du für etwas anderes die Zustimmung der Gesellschafter brauchst, nutze die <a href="/beschluss-vorlagen/zustimmung-fur-geschaftsfuhrung-(freitext)"><strong>Freitext-Zustimmungsvorlage</strong></a>.
  </p>
  `,
    tags: [
      "Mandatierung",
      "Beauftragung",
      "Auftrag",

      "Rechtsanwalt",
      "Anwalt",
      "Berater",

      "Entscheider",
      "Entscheidungsträger",
      "Direktor",
      "Director",
      "Vorstand",
      "Vorsitzender",
      "Führung",
      "Leitung",
      "Leiter",
      "Geschäftsführung",
      "Geschäftsleitung",
      "Geschäftsleiter",
      "Chef",
      "Leiter",
      "Vorgesetzter",
      "Führungskraft",
      "Geschäftsführer",
      "Manager",
      "CEO",
      "CTO",
      "CFO",
      "leader",
      "head",
      "chief",
      "executive",
      "boss",
      "governor",
      "governance",

      "feststellen",
      "Feststellung",
      "genehmigen",
      "absegnen",
      "zustimmen",
      "grünes Licht",
      "Weg frei machen",
      "Okay geben",
      "O.K. geben",
      "OK geben",
      "Erlaubnis",
      "Unterstützung",
      "erlauben",
      "unterstützen",
      "einwilligen",
      "Einwilligung",
      "Genehmigung",
      "Zustimmung",
      "Approval",
      "blessing",
      "confirmation",
      "consent",
      "endorsement",
      "green light",
      "persmission",
      "ratification",
      "support",
    ],

    antrag: `
  <p>
   Der Geschäftsführung wird die Zustimmung erteilt,
  </p>

  <p>
    <ul data-list-style="none">
      <li><p>
      die Kanzlei ${insertTemplateVariable(
        auftragKanzlei
      )} mit ${insertTemplateVariable(auftragGegenstand)}
      </p></li>
    </ul>
  </p>

  <p>
  im Namen der und mit Wirkung für die Gesellschaft zu beauftragen.
  </p>

  <p>
    Die Zustimmung gilt nur für eine Beauftragung, die die folgenden Vergütungsbedingungen erfüllt:
  </p>

   <ul data-list-style="none">
      <li><p>
     ${insertTemplateVariable(auftragVergütung)}
      </p></li>
   </ul>

  <p>
    Zum Abschluss des vorbezeichneten Rechtsgeschäfts wird zugleich Befreiung von den Beschränkungen des § 181 BGB erteilt, soweit hierfür erforderlich.
  </p>
  `,
    begründung: undefined,
    templateVariables: [auftragKanzlei, auftragGegenstand, auftragVergütung],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
