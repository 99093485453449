import { Typography } from "@material-ui/core";
import {
  ErrorTwoTone,
  InfoTwoTone,
  ViewAgendaTwoTone,
} from "@material-ui/icons";
import { Alert, AlertTitle } from "@mui/lab";
import {
  RVOSpecialTextAreaProps,
  RVOWissenTextArea,
  RVOWissenTextAreaProps,
} from "./RVOSpecialTextAreaInterfaces";

import { useTranslation } from "react-i18next";
import { useRVOSpecialTextAreaStyles } from "./RVOSpecialTextAreaStyles";

export const RVOWissenTextAreas: Record<
  RVOWissenTextArea,
  RVOWissenTextAreaProps
> = {
  [RVOWissenTextArea.Tip]: {
    color: "info",
    title: (t) => t("wissen.tip"),
    icon: <InfoTwoTone />,
  },
  [RVOWissenTextArea.Attention]: {
    color: "warning",
    title: (t) => t("wissen.attention"),
    icon: <ErrorTwoTone />,
  },
  [RVOWissenTextArea.Example]: {
    color: "info",
    title: (t) => t("wissen.example"),
    icon: <ErrorTwoTone />,
  },
  [RVOWissenTextArea.Tldr]: {
    color: "success",
    title: (t) => t("wissen.tldr"),
    icon: <ViewAgendaTwoTone />,
  },
};

const RVOSpecialTextArea = (props: RVOSpecialTextAreaProps): JSX.Element => {
  const classes = useRVOSpecialTextAreaStyles();
  const { t } = useTranslation();

  return (
    <Alert
      color={RVOWissenTextAreas[props.variant].color}
      className={classes.tippArea}
      icon={RVOWissenTextAreas[props.variant].icon}
    >
      <AlertTitle> {RVOWissenTextAreas[props.variant].title(t)}</AlertTitle>

      <Typography>{props.children}</Typography>
    </Alert>
  );
};

export default RVOSpecialTextArea;
