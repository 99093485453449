import {
  nameGeschäftsführerAlt,
  nameLiquidator,
  nameVerwahrer,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";
import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageLiquidationMitEinzeliquidator: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
  vorlageKategorie: VorlageKategorie.liquidation,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  kurzBezeichnung: "Liquidation mit Einzeliquidator",
  antrag: `
  <ul>
    <li><p>
        ${insertTemplateVariable(
          nameGeschäftsführerAlt
        )} wird als Geschäftsführer abberufen.
    </p></li>
    <li><p>
         ${insertTemplateVariable(
           nameLiquidator
         )} wird zum alleinigen, von den Beschränkungen des § 181 BGB befreiten Liquidator bestellt.
    </p></li>
    <li><p>
        ${insertTemplateVariable(
          nameLiquidator
        )} vertritt die Gesellschaft allein, solange er alleiniger Liquidator ist. Ansonsten vertritt er die Gesellschaft gemeinschaftlich mit einem anderen Liquidator.
    </p></li>
    <li><p>
        Die Bücher und Schriften der Gesellschaft werden nach Beendigung der Liquidation durch ${insertTemplateVariable(
          nameVerwahrer
        )} verwahrt.
    </p></li>
  </ul>`,
  templateVariables: [nameGeschäftsführerAlt, nameLiquidator, nameVerwahrer],
  begründung: undefined,
  anhangName: undefined,
  anhangDatei: undefined,
  dateOfCreation: undefined,
  vollzug: [Vollzug.handelsregisterAnmeldung],
});
