import { useMemo } from "react";
import { replaceEmojis } from "../../helpers/clientSide/replaceEmojis";
import { sanitizeHtml } from "../../helpers/general/sanitizeHtml";

/**
 * sanitizeHtml, wrapped in a useMemo
 *
 * @see sanitizeHtml
 */
export const useSanitizedHtml: typeof sanitizeHtml = (
  html,
  options
): string => {
  const sanitizedHtml = useMemo(() => {
    const emojisReplaced = replaceEmojis(html);

    if (typeof emojisReplaced === "string") {
      return sanitizeHtml(emojisReplaced, options);
    }

    return html;
  }, [html, options]);

  return sanitizedHtml;
};
