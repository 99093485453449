import { configureStore } from "@reduxjs/toolkit";
import beschlussVorlageReducer from "./beschlussVorlage/beschlussVorlageSlice";
import companyInfoReducer from "./companyInfo/companyInfoSlice";
import onboardingHelpReducer from "./onboardingHelp/onboardingHelpSlice";
import { loadState, persistStoreState } from "./persistStoreState";
import { rvoStateGetter } from "./rvoStateGetter";
import votingModeReducer from "./votingMode/votingModeSlice";

export const store = configureStore({
  reducer: {
    votingMode: votingModeReducer,
    beschlussVorlage: beschlussVorlageReducer,
    companyInfo: companyInfoReducer,
    onboardingHelp: onboardingHelpReducer,
  },
  preloadedState: loadState(rvoStateGetter),
});

persistStoreState(store);
