import {
  AppBar,
  Box,
  Button,
  ButtonBase,
  Divider,
  Drawer,
  Grid,
  Typography,
  useScrollTrigger,
  useTheme,
} from "@material-ui/core";
import { useBooleanState } from "@nvon/react-toolbox";
import { clsx } from "clsx";
import { Link } from "gatsby";
import Hamburger from "hamburger-react";
import { noop } from "lodash-es";
import { MouseEvent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-use";
import { useInitializedMediaQuery } from "../../../hooks/useInitializedMediaQuery";
import useSmoothScrollTo from "../../../hooks/useSmoothScrollTo/useSmoothScrollTo";
import { mobileMenuBreakpointUp } from "../../../layouts/theme";
import {
  HeaderColor,
  PageLayoutOptions,
} from "../../00-globals/PageLayout/PageLayoutInterfaces";
import RVOContainer from "../../02-molecules/RVOContainer/RVOContainer";
import { scrollSpringConfig } from "../NVONHeader/NVONHeaderNavigationMenu/NVONHeaderNavigationMenuStyles";
import { useGenericHeaderStyles } from "./GenericHeaderStyles";

const GenericHeader = (props: PageLayoutOptions): JSX.Element => {
  const theme = useTheme();

  const { isMatching: isMobile } = useInitializedMediaQuery(
    theme.breakpoints.down(mobileMenuBreakpointUp)
  );
  const classes = useGenericHeaderStyles({
    color: props.color,
    disableMobileCollapse: props.disableMobileCollapse,
    headerHeight: props.headerHeight,
  });

  const isPageScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: isMobile ? 30 : 100,
  });

  const { smoothScrollTo } = useSmoothScrollTo(scrollSpringConfig);

  const {
    state: mobileMenuOpen,
    off: setMobileMenuOpenFalse,
    toggle: toggleMobileMenuOpen,
  } = useBooleanState(false);

  const currentUrl = useLocation();

  // Close the menu on url changes
  useEffect(() => {
    setMobileMenuOpenFalse();
  }, [setMobileMenuOpenFalse, currentUrl]);

  const { t } = useTranslation();

  const menu = (className?: string): JSX.Element => (
    <>
      {props.centerContent && (
        <Box
          className={clsx(classes.centerContent, className)}
          role="presentation"
          onClick={() => {
            // This is a little hack to make the smoothScrolling work when we click on a link of the section page. May be changed!
            setTimeout(() => {
              setMobileMenuOpenFalse();
            }, 500);
          }}
          onKeyDown={setMobileMenuOpenFalse}
        >
          <props.centerContent color={props.color} />
        </Box>
      )}

      {props.ctaButton && (
        <Button
          variant="contained"
          className={clsx(classes.ctaButton, className)}
          color={props.color === HeaderColor.Paper ? "primary" : "secondary"}
          aria-label={props.ctaButton.label(t)}
          to={props.ctaButton.href}
          onClick={props.ctaButton.onClick || noop}
          component={props.ctaButton.href ? Link : ButtonBase}
          target="_blank"
        >
          <Typography
            component="p"
            color={
              props.color === HeaderColor.Paper
                ? "textSecondary"
                : "textPrimary"
            }
            className={classes.ctaButtonTypography}
          >
            {props.ctaButton.label(t)}
          </Typography>
        </Button>
      )}

      {props.loginButton && (
        <Button
          variant="outlined"
          className={clsx(classes.loginButton, className)}
          color={props.color === HeaderColor.Paper ? "primary" : "secondary"}
          aria-label={props.loginButton.label(t)}
          href={props.loginButton.href}
          onClick={props.loginButton.onClick || noop}
          component={props.loginButton.href ? ButtonBase : Link}
          target="_blank"
        >
          <Typography
            component="p"
            color={
              props.color === HeaderColor.Paper
                ? "textPrimary"
                : "textSecondary"
            }
            className={classes.ctaButtonTypography}
          >
            {props.loginButton.label(t)}
          </Typography>
        </Button>
      )}

      {props.registerButton && props.loginButton && (
        <Divider
          className={classes.desktopOnly}
          orientation="vertical"
          flexItem={true}
        />
      )}

      {props.registerButton && (
        <Button
          variant="contained"
          className={clsx(classes.registerButton, className)}
          color={props.color === HeaderColor.Paper ? "primary" : "secondary"}
          aria-label={props.registerButton.label(t)}
          href={props.registerButton.href}
          onClick={props.registerButton.onClick || noop}
          component={props.registerButton.href ? ButtonBase : Link}
          target="_blank"
        >
          <Typography
            component="p"
            color={
              props.color === HeaderColor.Paper
                ? "textSecondary"
                : "textPrimary"
            }
            className={classes.ctaButtonTypography}
          >
            {props.registerButton.label(t)}
          </Typography>
        </Button>
      )}
    </>
  );

  const showBurgerMenu = !props.disableMobileCollapse;

  return (
    <AppBar
      className={classes.appBar}
      elevation={isPageScrolled && !mobileMenuOpen ? 5 : 0}
      classes={{ root: classes.appBarRoot }}
    >
      <RVOContainer noPaddingTop={true}>
        <Grid
          container={true}
          direction="row"
          wrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <ButtonBase
            to={props.logo.href}
            component={Link}
            onClick={(event: MouseEvent) => {
              const pageIsActive = window.location.pathname === props.logo.href;
              if (pageIsActive) {
                smoothScrollTo(0);
                event.preventDefault();
              }
            }}
            disableRipple={true}
            title={props.logo.label(t)}
            className={classes.logo}
          >
            <props.logo.component className={classes.logoContent} />
          </ButtonBase>

          <Box className={classes.rightPart}>
            {showBurgerMenu && (
              <Box
                className={clsx(
                  classes.headerNavBurgerButton,
                  classes.mobileOnly
                )}
              >
                <Hamburger
                  toggled={mobileMenuOpen}
                  toggle={toggleMobileMenuOpen}
                  color={
                    props.color === HeaderColor.Paper
                      ? theme.palette.primary.dark
                      : theme.palette.primary.contrastText
                  }
                />
              </Box>
            )}

            {showBurgerMenu && (
              <Drawer
                className={classes.mobileOnly}
                open={mobileMenuOpen}
                classes={{
                  paper: classes.drawer,
                }}
                anchor="top"
                onClose={setMobileMenuOpenFalse}
              >
                {menu()}
              </Drawer>
            )}

            {menu(
              props.disableMobileCollapse ? undefined : classes.desktopOnly
            )}
          </Box>
        </Grid>
      </RVOContainer>
    </AppBar>
  );
};

export default GenericHeader;
