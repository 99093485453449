import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";
import {
  variantsVereinGruendungsmitgliederStimmrecht,
  vereinAnfallBerechtigter,
  vereinKassenprueferQualifikation,
  vereinMitgliederMindestalter,
  vereinMitgliederVetorecht,
  vereinName,
  vereinSitz,
  vereinVorstandGeborenesMitglied,
  vereinVorstandMindestgehalt,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

export const vorlageVereinSatzungAnbauvereinigung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.EVDe],
  vorlageKategorie: VorlageKategorie.anbauvereinigung,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.handelsregisterEintragung],
  bezug: [],
  tags: [
    "Verein",
    "Satzung",
    "eV",
    "e.V.",
    "Anbauvereinigung",
    "Anbauverein",
    "Cannabis Social Club",
    "Cannabis Club",
    "Cannabis Verein",
    "Cannabis e.V.",
    "Cannabis Social Club e.V.",
    "Cannabis Club e.V.",
    "Cannabis Verein e.V.",
    "Cannabis Social Club Satzung",
    "Cannabis Club Satzung",
    "Cannabis Verein Satzung",
    "Cannabis e.V. Satzung",
    "CSC",
  ],
  zustimmungErforderlichDes: "",
  kurzBezeichnung:
    "Gründung Anbauvereinigung als e.V. mit Satzung gemäß Cannabisgesetz (Cannabis Social Club)",
  metaDescription:
    "Gründungsbeschluss + Satzung für Anbauvereinigung (Cannabis Social Club CSC) als e.V./Verein mit Satzung gemäß Cannabisgesetz. Juristisch geprüfte Muster/Vorlagen kostenlos nutzen.",
  description: `
  <p>
    Dieses Muster enthält einen Gründungsbeschluss mit einer anwaltlich geprüften Standardsatzung für einen Anbauvereinigung (Cannabis Social Club/CSC) in der Rechtsform des eingetragenen nicht wirtschaftlichen Vereins (e.V.).
  </p>
  <p>
    Die Satzung erfüllt die Vorgaben des Cannabisgesetzes. Vereine, die sich mit der Satzung gründen, können die behördliche Erlaubnis für den gemeinschaftlichen Eigenanbau von Cannabis für den Eigenbedarf beantragen. Die Satzung ist so ausgestaltet, dass sie den Anforderungen des Cannabisgesetzes entspricht und die behördliche Erlaubnis/Lizenz für den gemeinschaftlichen Eigenanbau von Cannabis für den Eigenbedarf beantragt werden kann.
  </p>
  <p>
      Neben den gesetzlich vorgegebenen Satzungsinhalten enthält die Satzung passgenaue Regelungen für einen starken einköpfigen Vorstand mit einer Mindestvergütung kraft Satzung. Außerdem können bestimmte Mitglieder mit einem Vetorecht ausgestattet werden.
  </p>
  `,
  antrag: `
  <h2>
        Vereinsgründung
 </h2>

<ol data-list-style="decimal" data-list-style-prefix="§">
    <li>
      <h3>Errichtung, Gründungsmitglieder</h3>
       <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li>
            <p>
                Der Verein
            </p>
            <p style="text-align:center">
            <strong>
                ${insertTemplateVariable(vereinName)}
            </strong>
            </p>
            <p>
                mit dem Sitz in ${insertTemplateVariable(
                  vereinSitz
                )} wird hiermit errichtet.
            </p>
         </li>
          <li>
            <p>
                Dem Verein wird die nachfolgende Satzung gegeben.
            </p>
         </li>
      </ol>
    </li>
    <li>
         <h3>
            Gründungsvorstand
         </h3>

        <p>
            Die folgenden Mitglieder werden in den Vorstand berufen:
        </p>
        <ul data-list-style="none">
            <li>
                <p>
                   als alleiniges Vorstandsmitglied:
                </p>
                <p>
                   ${insertTemplateVariable(vereinVorstandGeborenesMitglied)}
                </p>
                <p>
                    Es ist stets alleinvertretungsbefugt und von den Beschränkungen des § 181 BGB befreit.
                </p>
            </li>
        </ul>
    </li>
</ol>

  <h2>Satzung</h2>


  <ol data-list-style="decimal" data-list-style-prefix="§">
    <li>
      <h3>Name, Sitz, Geschäftsjahr</h3>
       <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li>
            <p>Der Verein trägt den Namen "${insertTemplateVariable(
              vereinName
            )}".</p>
        </li>

        <li><p>
            Er ist ein rechtsfähiger Verein mit Sitz in ${insertTemplateVariable(
              vereinSitz
            )}.
         </p>
         </li>

        <li>
            <p>
                Der Verein soll in das Vereinsregister eingetragen werden und trägt dann den Zusatz „e. V.“.
            </p>
         </li>
        <li>
            <p>
                Geschäftsjahr ist das Kalenderjahr.
            </p>
         </li>
      </ol>
    </li>

    <li>
        <h3>
            Zweck des Vereins
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">

             <li>
                <p>
                    Der Verein ist Anbauvereinigung im Sinne des Cannabisgesetzes.
                </p>
            </li>

            <li>
                <p>
                    Ausschließlicher Zweck des Vereins ist
                    <ul data-list-style="square">
                        <li>
                             der gemeinschaftliche Eigenanbau und die Weitergabe des in gemeinschaftlichem Eigenanbau angebauten Cannabis durch und an ihre Mitglieder zum Eigenkonsum,
                        </li>
                        <li>
                            die Information von Mitgliedern über cannabisspezifische Suchtprävention und -beratung sowie
                        </li>
                        <li>
                            die Weitergabe von beim gemeinschaftlichen Eigenanbau gewonnenem Vermehrungsmaterial für den privaten Eigenanbau an ihre Mitglieder, an sonstige Personen, die das 18. Lebensjahr vollendet haben, oder an andere Anbauvereinigungen.
                        </li>
                    </ul>
                </p>
            </li>

            <li>
                <p>
                    Der Verein ist berechtigt, zur Verwirklichung des Vereinszwecks Mitgliedschaften in Vereinen und Verbänden einzugehen, Gesellschaften zu gründen und sich an solchen zu beteiligen.
                </p>
             </li>
        </ol>
    </li>

    <li>
        <h3>
            Selbstlosigkeit, Werte
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Der Verein ist selbstlos tätig; er verfolgt nicht in erster Linie eigenwirtschaftliche Zwecke.
                </p>
            </li>

            <li>
                <p>
                    Der Verein gewährleistet, dass Cannabis und Vermehrungsmaterial innerhalb des befriedeten Besitztums des Vereins ausreichend gegen den Zugriff durch unbefugte Dritte, insbesondere Kinder und Jugendliche, geschützt ist.
                </p>
            </li>
            <li>
                <p>
                    Der Verein gewährleistet die Einhaltung der sonstigen Vorgaben des Cannabisgesetzes und der auf Grund dieses Gesetzes erlassenen Vorschriften für Anbauvereinigungen.
                </p>
             </li>
            <li>
                <p>
                    Mittel des Vereins dürfen nur für die satzungsmäßigen Zwecke verwendet werden. Die Mitglieder erhalten keine Gewinnanteile oder -ausschüttungen. Es darf keine Person durch Ausgaben, die den Vereinszwecken fremd sind, oder durch unverhältnismäßig hohe Vergütungen begünstigt werden.
                </p>
             </li>
        </ol>
    </li>

    <li>
        <h3>
            Mitgliedschaft
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Mitglied des Vereins kann jede natürliche Person sein, die das ${insertTemplateVariable(
                      vereinMitgliederMindestalter
                    )}. Lebensjahr vollendet und einen Wohnsitz oder gewöhnlichen Aufenthalt in Deutschland hat.
                </p>
            </li>

            <li>
                <p>
                    Als Mitglied darf nur aufgenommen werden,
                </p>
                <ol data-list-style="lower-latin">
                    <li>
                        <p>
                            wer gegenüber dem Verein schriftlich oder elektronisch versichert, dass er oder sie kein Mitglied in einer anderen Anbauvereinigung ist,
                        </p>
                    </li>

                    <li>
                        <p>
                            wer durch Vorlage eines amtlichen Lichtbildausweises oder sonstiger geeigneter amtlicher Dokumente nachweist, dass er oder sie einen Wohnsitz oder gewöhnlichen Aufenthalt in Deutschland hat und das ${insertTemplateVariable(
                              vereinMitgliederMindestalter
                            )}. Lebensjahr vollendet hat.
                        </p>
                    </li>
                </ol>
            </li>
            <li>
                <p>
                    Der Erwerb und die Fortdauer der Mitgliedschaft ist an einen Wohnsitz oder einen gewöhnlichen Aufenthalt in Deutschland geknüpft. Mitglieder sind verpflichtet, Änderungen des Wohnsitzes oder des gewöhnlichen Aufenthalt dem Vorstand des Vereins unverzüglich mitzuteilen.
                </p>
            </li>
            <li>
                <p>
                   Der Antrag auf Mitgliedschaft erfolgt an den Vorstand, der über die Aufnahme entscheidet. Über den Antrag auf Aufnahme entscheidet der Vorstand nach freiem Ermessen. Ein Anspruch auf Aufnahme besteht nicht.
                </p>
            </li>
            <li>
                <p>
                    Die Mitgliedschaft endet:
                </p>
                <ol data-list-style="lower-latin">
                    <li>
                        <p>
                            durch deren Tod oder Verlust der Geschäftsfähigkeit;
                        </p>
                    </li>
                    <li>
                        <p>
                            durch Austritt;
                        </p>
                    </li>
                    <li>
                        <p>
                            durch Ausschluss.
                        </p>
                    </li>
                </ol>
            </li>
            <li>
                <p>
                    Die Mindestmitgliedschaftsdauer beträgt drei Monate. Der Austritt eines Mitgliedes erfolgt durch Erklärung in Textform gegenüber dem Vorstand und kann mit einer Frist von einem Monat zum Ende des Geschäftsjahres erklärt werden, jedoch nicht vor Ablauf der Mindestmitgliedschaftsdauer. Das austretende Mitglied erhält von dem Vorstand eine Bestätigung des Austritts.
                </p>
            </li>
            <li>
                <p>
                    Ein Mitglied kann durch den Vorstand mit sofortiger Wirkung aus wichtigem Grund aus dem Verein ausgeschlossen werden. Ein solcher wichtiger Grund liegt insbesondere vor, wenn das Mitglied den Vereinsinteressen oder den Vereinswerten (§ 3) grob zuwider gehandelt hat oder durch sein Verhalten oder Äußerungen einen sonstigen wichtigen Grund herbei geführt hat, der die Fortführung der Mitgliedschaft für den Verein oder seine Mitglieder unzumutbar erscheinen lässt. Als solcher wichtiger Grund gilt auch ein Beitragsrückstand trotz Mahnung über einen Zeitraum von mehr als 3 Monaten oder den Verlust eines Wohnsitzes oder gewöhnlichen Aufenthalts in Deutschland. Dem Mitglied ist vor seinem Ausschluss Gelegenheit zur Anhörung zu geben. Sofern der Ausschluss nicht in dem Verlust des Wohnsitzes bzw. gewöhnlichen Aufenthalts in Deutschland begründet ist, kann das Mitglied gegen den Ausschluss innerhalb einer Frist von einem Monat nach dem Zugang der Ausschlusserklärung die nächste ordentliche Mitgliederversammlung anrufen, die dann abschließend entscheidet. Bis zur Entscheidung der Mitgliederversammlung ruht die Mitgliedschaft.
                </p>
             </li>
            <li>
                <p>
                    An Mitglieder, deren Wohnsitz oder gewöhnlicher Aufenthalt sich nicht mehr in Deutschland befindet, darf kein Cannabis oder Vermehrungsmaterial abgegeben werden.
                </p>
             </li>
            <li>
                <p>
                    Die Mitgliedschaft ist nicht übertragbar.
                </p>
             </li>
        </ol>
    </li>

    <li>
        <h3>
            Pflichten der Mitglieder, Beiträge und Bezüge, Kommunikation
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Mit dem Antrag auf Mitgliedschaft erkennen die Mitglieder den Inhalt der Satzung und der sonstigen Vereinsordnungen an. Die Mitglieder sind verpflichtet, die Ziele und Interessen des Vereins zu wahren sowie die Beschlüsse und Anordnungen der Vereinsorgane zu befolgen.
                </p>
            </li>
            <li>
                <p>
                    Die Mitglieder entrichten Beiträge in Geld und/oder Arbeitsleistung an den Verein. Die Beiträge können regelmäßig oder im Einzelfall erhoben werden. Das Nähere regelt der Vorstand in einer Beitragsordnung. Die Beitragsordnung regelt sowohl die Höhe als auch die Fälligkeit der Beiträge und Bezüge und kann auch einen Aufnahmebeitrag bestimmen. Die Beitragsordnung kann insbesondere auch eine Beitragsstaffelung vorsehen, die die Beiträge und Bezüge nach der Höhe der Geld- und Arbeitsleistungen der Mitglieder staffelt.
                </p>
            </li>
             <li>
                <p>
                    Neben der in der Beitragsordnung vorgesehenen Beiträge können einmalige Beiträge in Form von im Einzelfall zu beschließenden Umlagen erhoben werden. Hierüber beschließt der Vorstand.
                </p>
            </li>
            <li>
                <p>
                    Die Mitglieder sind verpflichtet, dem Vorstand eine E-Mail-Adresse mitzuteilen und den Vorstand über jede Änderung ihres Namens und/oder E-Mail-Adresse unverzüglich zu informieren.
                </p>
            </li>
            <li>
                <p>
                    Sofern in dieser Satzung nicht ausdrücklich etwas anderes geregelt ist, können Vorstand und Mitglieder sämtliche Erklärungen und alle sonstige Kommunikation neben der Schrift- auch in Textform elektronisch per E-Mail oder auf sonstige Weise - insbesondere über die Resolvio-Plattform - abgeben. Erklärungen und Kommunikation der Mitglieder per E-Mail an den Verein und/oder den Vorstand können wirksam nur an die auf der Vereinshomepage genannten E-Mailadressen des Vorstands oder der Geschäftsstelle erfolgen.
                </p>
            </li>
        </ol>
    </li>

    <li>
        <h3>
            Organe, Abteilungen und Beistatuten
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Organe des Vereins sind:
                </p>
                <ol data-list-style="lower-latin">
                    <li>
                        <p>
                            die Vereinsmitglieder;
                        </p>
                    </li>
                    <li>
                        <p>
                            der Vorstand.
                        </p>
                    </li>
                </ol>
            </li>
            <li>
                <p>
                    Der Verein kann durch Beschluss des Vorstandes Abteilungen als unselbständige Untergliederungen des Vereins bilden. Die Abteilungen können sich eine Abteilungssatzung geben, die der Zustimmung des Vorstands bedarf und im Einklang mit der Vereinssatzung stehen muss. Die Abteilungen dürfen kein eigenes Vermögen bilden.
                </p>
            </li>
            <li>
                <p>
                    Der Vorstand ist berechtigt, neben den in dieser Satzung genannten Fällen weitere satzungsunabhängige Vereinsordnungen in Textform zu beschließen, die den Mitgliedern bekannt zu geben und für diese verbindlich sind. Dies gilt zB für Gesundheits- und Jugendschutzkonzept, Nutzungs-, Haus- und Werteordnungen, und Sanktionskataloge.
                </p>
            </li>
        </ol>
    </li>

    <li>
        <h3>
            Beschlüsse und Aufgaben der Vereinsmitglieder
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Die Vereinsmitglieder entscheiden per Beschluss in der Mitgliederversammlung oder im Umlaufverfahren.
                </p>
            </li>
            <li>
                <p>
                    Die Mitglieder können Beschlüsse ohne Mitgliederversammlung auf schriftlichem oder elektronischem Weg  – insbesondere über die Resolvio-Plattform – fassen (Umlaufverfahren), wenn sämtliche Mitglieder am Umlaufverfahren beteiligt wurden. Die Durchführung des Umlaufverfahrens und den Verfahrensablauf legt der Vorstand fest. Ungültige Stimmen gelten im Umlaufverfahren als abgegebene Stimmen und als Enthaltung. Das Beschlussergebnis des Umlaufverfahrens ist durch den Vorstand den Mitgliedern innerhalb von 14 Tagen nach Fristablauf bekannt zu geben. Unwirksame Umlaufverfahren können – auch mehrfach – wiederholt werden.
                </p>
            </li>
            <li>
                <p>
                      Aufgaben der Vereinsmitglieder sind insbesondere:
                </p>
                <ol data-list-style="lower-latin">
                    <li>
                        <p>
                            die Wahl von Vorstandsmitglieder, die nicht bereits kraft Satzung Amtsträger sind;
                        </p>
                    </li>
                    <li>
                        <p>
                            die Entlastung von Vorstandsmitgliedern;
                        </p>
                    </li>
                    <li>
                        <p>
                            die Wahl von Vorstandsmitglieder, die nicht bereits kraft Satzung Amtsträger sind;
                        </p>
                    </li>
                    <li>
                        <p>
                            die Entlastung von Vorstandsmitgliedern;
                        </p>
                    </li>
                    <li>
                        <p>
                            die Änderung oder Neufassung der Satzung;
                        </p>
                    </li>
                    <li>
                        <p>
                            die Beschlussfassung über Beschwerden gegen den Ausschluss von Vereinsmitgliedern;
                        </p>
                    </li>
                    <li>
                        <p>
                            die Beschlussfassung über die Auflösung des Vereins;
                        </p>
                    </li>
                    <li>
                        <p>
                            Beschlussfassung zu einer Vergütung des Vorstands;
                        </p>
                    </li>
                    <li>
                        <p>
                            sämtliche sonstigen den Vereinsmitgliedern durch Gesetz oder an anderer Stelle der Satzung übertragenen Aufgaben.
                        </p>
                    </li>
                </ol>
            </li>
             <li>
                <p>
                  	Jedem Mitglied kommt ein einfaches Stimmrecht zu. ${insertTemplateVariable(
                      variantsVereinGruendungsmitgliederStimmrecht
                    )}
                </p>
            </li>
            <li>
                <p>
                  	Beschlüsse der Vereinsmitglieder werden – soweit das Gesetz oder diese Satzung nichts anderes bestimmen – mit einfacher Mehrheit der abgegebenen Stimmen gefasst. Stimmenthaltungen und ungültige Stimmen gelten als nicht abgegebene Stimmen. Über Auflösung des Vereins kann nur in der Mitgliederversammlung abgestimmt werden. Zur Änderung der Satzung und zur Auflösung des Vereins ist eine Mehrheit von 2/3 der abgegebenen Stimmen erforderlich. Gegen die Stimme(n) von ${insertTemplateVariable(
                      vereinMitgliederVetorecht
                    )} kann in keinem Fall ein Beschluss gefasst werden.
                </p>
            </li>
            <li>
                <p>
                    Bei Wahlen sind die Kandidaten gewählt, die die einfache Mehrheit der abgegebenen Stimmen erhalten. Erhalten mehrere Kandidaten für eine bestimmte Vorstandsposition die einfache Mehrheit, ist der Kandidat gewählt, auf welchen mehr Stimmen entfallen. Bei Stimmengleichheit erfolgt zwischen den stimmgleichen Kandidaten eine Stichwahl. Bei erneuter Stimmengleichheit gilt der ältere Kandidat als gewählt
                </p>
            </li>
            <li>
                <p>
                    Die Beschlüsse und Wahlergebnisse der Vereinsmitglieder sind zu protokollieren und vom Versammlungs- bzw. Abstimmungsleiterleiter zu unterzeichnen. Die Protokolle sind dauerhaft aufzubewahren. Eine elektronische Aufbewahrung auf Resolvio genügt.
                </p>
            </li>
        </ol>
    </li>

    <li>
        <h3>
            Mitgliederversammlung
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                   Die ordentliche Mitgliederversammlung ist mindestens einmal jährlich einzuberufen. Ort, Termin und Tagesordnung bestimmt der Vorstand.
                </p>
            </li>
            <li>
                <p>
                    Mitgliederversammlungen sind ferner einzuberufen, wenn das Interesse des Vereins es erfordert oder die Einberufung von mindestens 1/3 der Mitglieder unter Angabe einer begründeten Tagesordnung vom Vorstand verlangt wird (außerordentliche Mitgliederversammlung). Die beantragte Tagesordnung ist verpflichtend zu übernehmen.
                </p>
            </li>
            <li>
                <p>
                    Die Einberufung der Mitgliederversammlung erfolgt schriftlich oder elektronisch – etwa mit Resolvio - durch den Vorstand mit einer Einladungsfrist von zwei Wochen. Die Frist beginnt am Tage der Versendung der Einladung. Eine schriftliche Einladung erfolgt an die von dem Mitglied zuletzt mitgeteilte Adresse, eine Einladung per E-Mail erfolgt an die von dem Mitglied zuletzt mitgeteilte E-Mail-Adresse oder über Resolvio.
                </p>
            </li>
            <li>
                <p>
                    Jedes Mitglied kann bis spätestens eine Woche vor dem Tag der Mitgliederversammlung beim Vorstand schriftlich oder per E-Mail beantragen, dass weitere Angelegenheiten auf die Tagesordnung gesetzt werden. Eine hieraus folgende Änderung der Tagesordnung ist spätestens zu Beginn der Mitgliederversammlung bekanntzugeben. Anträge zur Wahl oder Abwahl von Vorstandsmitgliedern, Änderung der Satzung oder Auflösung des Vereins, die nicht bereits in der fristgemäßen Einladung nach Satz 1 angekündigt wurden, sind von einer Ergänzung der Tagesordnung ausgeschlossen und können erst auf der nächsten Mitgliederversammlung behandelt werden.
                </p>
            </li>
            <li>
                <p>
                    Der Vorstand ist berechtigt, nach seinem Ermessen Mitgliedern die Teilnahme an der Versammlung ohne Anwesenheit am Versammlungsort auf elektronischem Weg zu ermöglichen oder die Mitgliederversammlung vollständig auf elektronischem Weg durchzuführen.
                </p>
            </li>
            <li>
                <p>
                    Zur Teilnahme an der Mitgliederversammlung sind alle Mitglieder des Vereins berechtigt. Durch Beschluss des Vorstandes können Gäste zur Anwesenheit berechtigt werden.
                </p>
            </li>
            <li>
                <p>
                    Die Mitgliederversammlung wird durch den 1. Vorsitzenden, bei dessen Verhinderung durch den 2. Vorsitzenden, weiter ersatzweise durch ein sonstiges anwesendes Vorstandmitglied geleitet. Ist kein Vorstandsmitglied anwesend, wählt die Versammlung aus ihrer Mitte einen Versammlungsleiter. Zu Beginn der Mitgliederversammlung ist durch den Versammlungsleiter ein Protokollführer zu bestimmen und sind etwaige Änderungen der Tagesordnung durch den Versammlungsleiter bekanntzugeben.
                </p>
            </li>
            <li>
                <p>
                    Jede ordnungsgemäß einberufene Mitgliederversammlung ist ohne Rücksicht auf die Zahl der erschienenen Mitglieder beschlussfähig.
                </p>
            </li>
            <li>
                <p>
                    Die Stimmabgabe in der Mitgliederversammlung erfolgt durch Handzeichen der anwesenden, stimmberechtigten Mitglieder. Abweichend von Satz 1 erfolgt eine schriftliche Stimmabgabe, wenn auf Befragen des Versammlungsleiters mindestens ein Viertel der anwesenden, stimmberechtigten Mitglieder eine solche geheime Wahl verlangt. Der Versammlungsleiter hat die Befragung der Mitgliederversammlung nur auf Antrag eines oder mehrerer anwesender, stimmberechtigter Mitglieder durchzuführen. Auf die Frage des Versammlungsleiters erklären sich die eine geheime Wahl verlangenden Mitglieder durch Handzeichen.
                </p>
            </li>
            <li>
                <p>
                    Der Vorstand ist berechtigt, Mitgliedern die Stimmabgabe ohne Teilnahme an der Mitgliederversammlung in schriftlicher Form vor der Versammlung oder auf elektronischem Weg vor oder während der Versammlung zu ermöglichen.
                </p>
            </li>
            <li>
                <p>
                    Erfolgt die Mitgliederversammlung ganz oder teilweise auf elektronischem Weg, bestimmt der Vorstand das Versammlungsmedium, den technischen Versammlungsablauf und die Art der elektronischen Stimmabgabe nach seinem Ermessen. Die vorstehenden Regelungen gelten für solche Mitgliederversammlungen sinngemäß, wobei die Abstimmung durch Handzeichen und in Schriftform durch elektronische Stimmabgabe - etwa über Resolvio - ersetzt werden können.
                </p>
            </li>
        </ol>
    </li>

     <li>
        <h3>
          Vorstand
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                   Der Vorstand besteht aus einem Vorstandsmitglied.
                </p>
            </li>
            <li>
                <p>
                   ${insertTemplateVariable(
                     vereinVorstandGeborenesMitglied
                   )} ist Vorstandsmitglied, ohne dass es hierfür einer Wahl bedarf ("geborenes Vorstandsmitglied").
                </p>
            </li>
            <li>
                <p>
                   Soweit geborene Vorstandsmitglieder ihr Amt niederlegen oder aus wichtigen Grund abberufen werden, wird der Vorstand durch Wahl der Mitglieder aus dem Kreis der Mitglieder besetzt.
                </p>
            </li>
            <li>
                <p>
                    Die Vorstandsmitglieder müssen Vereinsmitglieder sein, unbeschränkt geschäftsfähig sein und die für den Umgang mit Cannabis und Vermehrungsmaterial erforderliche Zuverlässigkeit besitzen.
                </p>
            </li>
            <li>
                <p>
                   Der Vorstand wird von der Vereinsmitgliedern auf die Dauer von zwei Jahren, gerechnet vom Tage der Wahl an, gewählt. Der Vorstand bleibt im Amt bis zur satzungsmäßigen Bestellung eines neuen Vorstands. Scheidet ein Mitglied des Vorstands während der Amtsperiode aus, so wählen die Mitglieder ein Ersatzmitglied für die restliche Amtsdauer des Ausgeschiedenen. Geborene Vorstandsmitglieder können nur aus wichtigem Grund abberufen werden; ein solcher Grund ist insbesondere grobe Pflichtverletzung oder Unfähigkeit zur ordnungsmäßigen Geschäftsführung.
                </p>
            </li>
                 <li>
                <p>
                   Der Vorstand führt die Geschäfte des Vereins nach eigenem Ermessen. Eine Pflicht zur Einholung eines zustimmenden Mitgliederbeschlusses besteht nur bei außergewöhnlichen Geschäftsführungsmaßnahmen.
                </p>
            </li>
            <li>
                <p>
                   Der Verein wird gerichtlich und außergerichtlich durch jedes Vorstandsmitglied einzelvertretungsberechtigt vertreten. Die Vertretungsmacht ist mit Wirkung gegenüber Dritten unbeschränkt.
                </p>
            </li>
            <li>
                <p>
                    Wählbar als Vorstandsmitglied sind nur volljährige Mitglieder des Vereins.
                </p>
            </li>
            <li>
                <p>
                    Eine Pflicht zur Einholung eines zustimmenden Mitgliederbeschlusses besteht nur bei außergewöhnlichen Geschäftsführungsmaßnahmen, im Übrigen führt der Vorstand die Geschäfte gerichtlich und außergerichtlich nach eigenem Ermessen, insbesondere die folgenden Geschäfte:
                </p>
                <ol data-list-style="lower-latin">
                  <li>
                        <p>
                            Abschluss und Kündigung von Miet- und Pachtverträgen;
                        </p>
                    </li>
                    <li>
                        <p>
                            Erwerb und Veräußerung von Gegenständen des Anlage- und Umlaufvermögens;
                        </p>
                    </li>
                    <li>
                        <p>
                            Führen der Bücher;
                        </p>
                    </li>
                    <li>
                        <p>
                            Abschluss und Kündigung von Dienst- und Arbeitsverträgen;
                        </p>
                    </li>
                    <li>
                        <p>
                            Ausübung des Weisungsrechtes gegenüber Mitarbeitern;
                        </p>
                    </li>
                    <li>
                        <p>
                            Aufnahme und Ausschluss von Mitgliedern;
                        </p>
                    </li>
                    <li>
                        <p>
                            Aufstellen der Beitragsordnung
                        </p>
                    </li>
                </ol>
            </li>
            <li>
                <p>
                      Im Übrigen obliegen dem Vorstand insbesondere die folgenden Aufgaben:
                </p>
                <ol data-list-style="lower-latin">
                  <li>
                        <p>
                            Durchführung von Umlaufbeschlüssen der Vereinsmitglieder;
                        </p>
                    </li>
                    <li>
                        <p>
                            Vorbereitung und Einberufung der Mitgliederversammlung; Aufstellung der Tagesordnung;
                        </p>
                    </li>
                    <li>
                        <p>
                            Ausführung der Beschlüsse der Vereinsmitglieder.
                        </p>
                    </li>
                </ol>
            </li>
            <li>
                <p>
                    Der Vorstand kann Satzungsänderungen beschließen, ohne dass es eines Beschlusses der Mitglieder bedarf, die durch die folgenden Behörden verlangt werden:
                </p>
                <ol data-list-style="lower-latin">
                    <li>
                        <p>
                            das Vereinsregister führende Amtsgericht;
                        </p>
                    </li>
                    <li>
                        <p>
                           die Finanzbehörde;
                        </p>
                    </li>
                    <li>
                        <p>
                           die für die Erteilung von Erlaubnissen nach dem Gesetz zum Umgang mit Konsumcannabis zuständigen Behörde.
                        </p>
                    </li>
                </ol>
            </li>
            <li>
                <p>
                    Den Mitgliedern des Vorstandes werden die bei der Vereinsarbeit entstandenen, angemessenen Auslagen ersetzt. Mitglieder des Vorstands erhalten darüber hinaus eine angemessene Vergütung für ihre Vorstandstätigkeit, die mindestens einem Bruttomonatsgehalt von ${insertTemplateVariable(
                      vereinVorstandMindestgehalt
                    )} entspricht. Für eine hierüber hinausgehende Vergütung sowie den Abschluss eines Anstellungsvertrags im Übrigen ist ein Beschluss der Mitglieder erforderlich.
                </p>
            </li>
             <li>
                <p>
                    Die Mitglieder des Vorstands haften dem Verein gegenüber nur für vorsätzliches oder grob fahrlässiges Verhalten. Werden Vorstandsmitglieder aufgrund ihrer Vorstandstätigkeit von Dritter Seite in Anspruch genommen, stellt der Verein das betroffene Vorstandsmitglied von diesen Ansprüchen frei, sofern das Vorstandsmitglied nicht vorsätzlich oder grob fahrlässig handelte.
                </p>
            </li>
        </ol>
    </li>

    <li>
        <h3>Beschlüsse des Vorstands</h3>
          <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Der Vorstand fasst Beschlüsse im Umlaufverfahren oder in Sitzungen.
                 </p>
            </li>
            <li>
                <p>
                    Beschlüsse des Vorstands können ohne Einhaltung von Ladungsfristen schriftlich oder elektronisch – insbesondere mit Resolvio – gefasst werden (Umlaufverfahren), wenn alle Vorstandsmitglieder zu diesem Verfahren ihre Zustimmung erklären. Die Stimmabgabe im Umlaufverfahren gilt als Zustimmung.
                 </p>
            </li>
             <li>
                <p>
                    Die Einladung zu Vorstandssitzungen erfolgt schriftlich oder per E-Mail mit einer Frist von mindestens einer Woche durch den 1. Vorsitzenden, ersatzweise den 2. Vorsitzenden. Eine Verkürzung der Ladungsfrist ist mit Zustimmung sämtlicher Vorstandsmitglieder möglich. Die Zustimmung gilt mit dem Erscheinen zur Vorstandssitzung als erteilt. Nach Maßgabe der Regelungen in Satz 1–3 können Vorstandssitzungen auch fernmündlich oder in elektronischer Form (zB per Videokonferenz) erfolgen.
                </p>
            </li>
             <li>
                <p>
                    Vorstandssitzungen sind beschlussfähig, wenn mindestens zwei Vorstandsmitglieder – darunter der 1. oder 2. Vorsitzende – anwesend sind. Beschlüsse des Vorstands werden mit einfacher Mehrheit der abgegebenen Stimmen gefasst. Bei Stimmengleichheit entscheidet die Stimme des 1. Vorsitzenden, ersatzweise des 2. Vorsitzenden.
                </p>
            </li>
             <li>
                <p>
                    Sämtliche Beschlüsse des Vorstands – auch Umlaufbeschlüsse – sind zu protokollieren, von dem jeweiligen Sitzungs- oder Abstimmungsleiter zu unterzeichnen und aufzubewahren.
                </p>
            </li>
             <li>
                <p>
                    Der Vorstand ist berechtigt, sich eine Geschäftsordnung zu geben.
                </p>
            </li>
        </ol>
    </li>
     <li>
        <h3>
            Kassenprüfung
        </h3>
        <p>
           Die Vereinsmitgliedern können nach ihrem Ermessen einen Kassenprüfer sowie einen stellvertretenden Kassenprüfer bestimmen, die nicht dem Vorstand angehören dürfen${insertTemplateVariable(
             vereinKassenprueferQualifikation
           )}. Der Kassenprüfer, im Falle seiner Verhinderung sein Stellvertreter, prüft die Buchführung und den Jahresabschluss, berichtet über die Prüfungsergebnisse gegenüber den Vereinsmitgliedern und gibt eine Empfehlung zur Beschlussfassung über die Entlastung des Vorstands ab.
        </p>
    </li>

     <li>
        <h3>
            Auflösung des Vereins
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Für den Beschluss, den Verein aufzulösen, ist eine 2/3-Mehrheit der in der Mitgliederversammlung anwesenden Mitglieder erforderlich. Der Beschluss kann nur nach rechtzeitiger Ankündigung in der Einladung zur Mitgliederversammlung gefasst werden.
                </p>
            </li>
            <li>
                <p>
                	Bei Auflösung des Vereins fällt das Vermögen an ${insertTemplateVariable(
                    vereinAnfallBerechtigter
                  )}, außer die Mitglieder bestimmen einen hiervon anderen Anfallberechtigten durch Beschluss.
                </p>
            </li>
        </ol>
    </li>

     <li>
        <h3>
            Datenschutz
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                   Der Verein verarbeitet personenbezogene Daten im erforderlichen Rahmen zur Erfüllung seiner Aufgaben und Zwecke. Dabei werden die gesetzlichen Vorgaben, insbesondere die Bestimmungen der Datenschutz-Grundverordnung und des Bundesdatenschutzgesetzes, beachtet.
                </p>
            </li>
            <li>
                <p>
                    Der Vorstand des Vereins ist berechtigt, eine Datenschutzordnung beschließen, in welcher der rechtskonforme Umgang mit personenbezogenen Daten zu regeln ist.
                </p>
            </li>
        </ol>
    </li>
    <li>
        <h3>
            Schlussbestimmung
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                  Änderungen dieser Satzung bedürfen zu ihrer Wirksamkeit der Textform, soweit nicht gesetzlich eine Beurkundung oder andere Form vorgeschrieben ist. Dies gilt auch für die Änderung dieser Textformklausel.
                </p>
            </li>
            <li>
                <p>
                    Sollten einzelne Bestimmungen dieser Satzung unwirksam sein oder werden, so wird die Rechtswirksamkeit der übrigen Bestimmungen dadurch nicht berührt. Die betreffende Bestimmung ist durch eine wirksame zu ersetzen, die dem angestrebten wirtschaftlichen Zweck möglichst nahe kommt.
                </p>
            </li>
            <li>
                <p>
                    Soweit in dieser Satzung die schriftliche Form vorgeschrieben ist, genügt für deren Einhaltung die Textform, soweit dies gesetzlich zulässig ist.
                </p>
            </li>
        </ol>
    </li>
  </ol>`,
  begründung: undefined,
  vollzugshinweise: `
    <p>
        Nach Abschluss des Beschlussverfahrens sind die folgenden Schritte notwendig, damit der Verein in das Vereinsregister eingetragen wird:
    </p>
    <ol>
        <li>
            <p>
                <strong>Registeranmeldung</strong>: Für die Eintragung des Vereins in das Vereinsregister ist eine sogenannte Registeranmeldung erforderlich. Um Notarkosten zu sparen können Sie das Registeranmeldungs-Dokument hier ohne Zusatzkosten durch Resolvio generieren lassen. Alternativ kann die Registeranmeldung kostenpflichtig durch den Notar erstellt werden. <br/><button data="RegisteranmeldungGenerieren"></button>
            </p>
        </li>
         <li>
            <p>
                <strong>Beglaubigungstermin vereinbaren</strong>: Die Registeranmeldung muss in jedem Fall von einem Notar beglaubigt werden. Hierfür genügt es, dass ein einzelvertretungsberechtigtes Vorstandsmitglied einen Beglaubigungstermin bei einem beliebigen Notar vereinbart. Der Notar kümmert sich um die Einreichung beim Registergericht. <br/><button data="NotarterminVereinbaren"></button>
            </p>
        </li>
    </ol>

    <p>
        Nach der Eintragung in das Vereinsregister erhält der Verein eine Registernummer und ist damit rechtsfähig. Der Verein kann dann auch ein Geschäftskonto eröffnen und die Erlaubnis für den gemeinschaftlichen Eigenanbau nach dem Cannabisgesetz beantragen.
  </p>`,
  templateVariables: [
    vereinName,
    vereinSitz,
    vereinVorstandGeborenesMitglied,
    vereinMitgliederMindestalter,
    variantsVereinGruendungsmitgliederStimmrecht,
    vereinMitgliederVetorecht,
    vereinVorstandMindestgehalt,
    vereinKassenprueferQualifikation,
    vereinAnfallBerechtigter,
  ],
});
