import { createStyles, makeStyles } from "@material-ui/core";

export const useRVOChatCookieModalStyles = makeStyles((theme) =>
  createStyles({
    showConsentOverlayButton: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  })
);
