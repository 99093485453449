import {
  ausschüttung,
  bestehenderGewinnvortrag,
  bestehenderVerlustvortrag,
  bilanzgewinn,
  jahresgewinnPrognose,
  verteilungsschluessel,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageVerwendungDesGewinnsVorabausschuettung: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.regelmäßigerBeschluss,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.selfexecuting],
    bezug: [],
    tags: [
      "Vorabausschüttung",
      "Gewinnvorab",
      "Vorschuss",
      "Gewinnvorschuss",
      "Abschlagsdividende",
      "Zwischendividende",

      "Gewinn",
      "Ergebnis",
      "Erlös",
      "Ertrag",
      "Marge",
      "Rendite",
      "schwarze Zahlen",
      "Überschuss",
      "profit",
      "winnings",
      "gain",
      "win",

      "Gewinnvortrag",
      "Vortrag",
      "Bilanzgewinn",
      "Kapitalrücklage",
      "freie Rücklage",

      "Ausschüttung",
      "Ergebnisverteilung",
      "Gewinnausschüttung",
      "Gewinnverwendung",
      "Verteilung",
      "Aufteilung",
      "Zuteilung",
      "Auszahlung",
      "Dividende",
      "distribution",
      "payout",
      "dividend",
      "disburse",
      "disbursement",
      "distribute",
    ],
    description: `<p>Im Rahmen einer Vorabgewinnausschüttung – synonym auch als Gewinnvorschuss, Abschlags- oder Zwischendividende bezeichnet – wird erwarteter Jahresgewinn bereits vor der endgültigen Gewinnverteilung an die Gesellschafter ausgekehrt.<p>

  <p>Solch eine vorweggenommene Gewinnausschüttung kann entweder bereits während des noch laufenden Geschäftsjahres oder nach dessen Ende, aber noch vor Feststellung des Jahresabschlusses, erfolgen. Die Vorlage ist für eine Vorabausschüttung noch im laufenden Geschäftsjahr geeignet.</p>`,
    kurzBezeichnung: "Vorabausschüttung",
    metaDescription:
      "Vorabausschüttung in der GmbH/UG. Muster/Vorlagen für Gesellschafterbeschluss - juristisch geprüft - jetzt kostenlos nutzen.",
    antrag: `
  <p>Gemäß der als <b>Anlage</b> beigefügten Vorausberechnung besteht die begründete Erwartung, dass das laufende Geschäftsjahr wie folgt abgeschlossen werden wird:
  </p>

    <ul data-list-style="none">
      <li><p>
      <table>
        <tr>
          <td>
            Prognostizierter Jahresgewinn
          </td>
          <td>
              ${insertTemplateVariable(jahresgewinnPrognose)} €
          </td>
        </tr>
        <tr>
          <td>
            zuzüglich Gewinnvortrag
          </td>
          <td>
              ${insertTemplateVariable(bestehenderGewinnvortrag)} €
          </td>
        </tr>
        <tr>
          <td>
            abzüglich Verlustvortrag
          </td>
          <td>
              ${insertTemplateVariable(bestehenderVerlustvortrag)} €
          </td>
        </tr>
            <tr>
          <td>
            <strong>Bilanzgewinn</strong>
          </td>
          <td>
              <strong>${insertTemplateVariable(bilanzgewinn)} €</strong>
          </td>
        </tr>
      </table>
      </p></li>
   </ul>


  <p>
   Basierend hierauf erhalten die Gesellschafter im Wege der
  </p>

  <p><b>Vorabausschüttung</b></p>

  <p>
  eine Abschlags- bzw. Zwischendividende. Die Gesamtsumme der Ausschüttung beträgt ${insertTemplateVariable(
    ausschüttung
  )} €.
  </p>

  <p>
  Die Verteilung der Gesamtsumme auf die Gesellschafter erfolgt wie folgt:   ${insertTemplateVariable(
    verteilungsschluessel
  )}.
  </p>

  <p>
  Die Leistung der Vorabausschüttung steht unter dem Vorbehalt der Erzielung eines entsprechenden Jahresergebnisses. Daher kann die Gesellschaft die Vorabausschüttungsbeträge zurückfordern, falls sich aus dem festgestellten Jahresabschluss keine endgültige Dividende in ausreichender Höhe ergibt.
  </p>


`,
    begründung: undefined,
    templateVariables: [
      jahresgewinnPrognose,
      bestehenderGewinnvortrag,
      bestehenderVerlustvortrag,
      bilanzgewinn,
      ausschüttung,
      verteilungsschluessel,
    ],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
