import RVOFootnote from "../../../../components/02-molecules/RVOFootnote/RVOFootnote";
import { TagToComponentMap } from "../replaceHtmlElementInterfaces";

/**
 * Footnotes come widthout the right id for anchoring,
 *  so the mapping is used to add the right id.
 *
 * In addition an offset is added,
 *  so that our navigation header does not overlap with the anchors.
 */
export const mappedFootnotes: TagToComponentMap = {
  li: ({ children, replacedElement }) => {
    const tagId = replacedElement.attribs.id;
    const isFootnote = replacedElement.attribs.class?.includes("footnotes");

    if (isFootnote) {
      return (
        <RVOFootnote isFootnote={isFootnote} footnoteId={tagId}>
          {children}
        </RVOFootnote>
      );
    } else {
      return <li {...replacedElement.attribs}>{children}</li>;
    }
  },
};
