import { RolleInGesellschaft } from "../../../common-interfaces/VorlageInterface";
import { CompanyTypeName } from "../../vorlagen/vorlagenShared/generatedTypes/CompanyTypeName";
import { kommanditisten } from "./roleNamesForRechtsform";

export enum AdditionalRole {
  CoKgKommanditist = "coKgKommanditist",
}

export type AdditionalRoles = Record<CompanyTypeName, [AdditionalRole]>;

/**
 * Initially, all Rechtsformen were supposed to have the same roles,
 *  only with different names.
 *
 * Turns out, there are exceptions to this rule.
 *
 * If the number of exceptions increases, it might be better to
 *  have each Rechtsform as a distinct entity, not just an enum identifier.
 */
export const additionalRolesForRechtsform: AdditionalRoles = {
  ...Object.fromEntries(
    // default for all rechtsformen: no additional roles
    Object.values(CompanyTypeName).map((rechtsform) => [rechtsform, []])
  ),
  // For those ones, we do have additional ones
  [CompanyTypeName.GmbHCoKgDe]: [AdditionalRole.CoKgKommanditist],
  [CompanyTypeName.UgCoKgDe]: [AdditionalRole.CoKgKommanditist],
} as AdditionalRoles;

export const additionalRoleNames = {
  [AdditionalRole.CoKgKommanditist]:
    kommanditisten[RolleInGesellschaft.Mitglieder],
};
