import RVOFootnotesSection from "../../../../components/02-molecules/RVOFootnotesSection/RVOFootnotesSection";
import { TagToComponentMap } from "../replaceHtmlElementInterfaces";

/**
 * The Footnote area automatically get a headline attached.
 */
export const mappedFootnotesArea: TagToComponentMap = {
  ol: ({ children, replacedElement }) => {
    const isFootnotesArea =
      replacedElement.attribs.class?.includes("wp-block-footnotes");

    if (isFootnotesArea) {
      return <RVOFootnotesSection>{children}</RVOFootnotesSection>;
    } else {
      return <ol {...replacedElement.attribs}>{children}</ol>;
    }
  },
};
