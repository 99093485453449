import GenericHeader from "../../03-organisms/GenericHeader/GenericHeader";
import RVOFooter from "../../03-organisms/RVOFooter/RVOFooter";
import { DefaultPageLayoutProps as PageLayoutProps } from "./PageLayoutInterfaces";
import { useDefaultPageLayoutStyles } from "./PageLayoutStyles";

const PageLayout = (props: PageLayoutProps): JSX.Element => {
  const classes = useDefaultPageLayoutStyles({
    headerHeight: props.headerHeight,
  });

  return (
    <div className={classes.layoutWrap}>
      <GenericHeader {...props} />

      <main className={classes.main}>{props.children}</main>

      <RVOFooter />
    </div>
  );
};

export default PageLayout;
