import slugify from "slugify";
import {
  LegacyTemplate,
  LegacyTemplateBase,
} from "../data/vorlagen/vorlagenShared/VorlageInterface";
import { getPaymentInfo } from "../data/vorlagen/vorlagenShared/getPaymentInfo";
import { beschlussGeneratorPath } from "./general/frontendUrlPaths";
import { insertTemplateVariable } from "./general/templates/insertTemplateVariable";
import { insertVorlagenLink } from "./general/templates/insertVorlagenLink";

export interface LegacyTemplateLandingpage extends LegacyTemplate {
  landingpageGeneratorLink: string;
}

/**
 * Create a Vorlage, automatically setting the url slug.
 */
export const createVorlageLandingpage = (
  vorlageInput: LegacyTemplateBase
): LegacyTemplateLandingpage => {
  const withContext = vorlageInput({
    insertTemplateVariable,
    insertVorlagenLink,
  });

  const urlSlug = getTemplateSlug(withContext.kurzBezeichnung);

  return {
    id: urlSlug,
    urlSlug,
    ...withContext,
    landingpageGeneratorLink: beschlussGeneratorPath(urlSlug),
    ...getPaymentInfo(vorlageInput),
  };
};

export const getTemplateSlug = (
  title: LegacyTemplate["kurzBezeichnung"]
): string => slugify(title).toLowerCase();
