import { Link as MuiLink } from "@material-ui/core";
import { important } from "@nvon/react-toolbox";
import { Link as GatsbyLink } from "gatsby";
import { Link } from "gatsby-material-ui-components";
import { TagToComponentMap } from "../replaceHtmlElementInterfaces";

/**
 * Links coming from wordpress, mapped to:
 *  - a span (if no href is given)
 *  - a Mui Link using a gatsby Link for internal links
 *  - a plain Mui link for external links
 */
export const mappedLinks: TagToComponentMap = {
  a: ({ children, replacedElement }) => {
    const href = replacedElement.attribs.href;

    if (!href) {
      return <span>{children}</span>;
    }

    // Regex: starts with a slash or #, followed by a non-slash character or #
    const isInternalLink = href.match(/^\/[^/]/) || href.match(/^#[^#]/);

    if (isInternalLink) {
      return (
        <MuiLink
          component={GatsbyLink}
          to={href}
          style={{ paddingBottom: important(0), marginTop: important(0) }}
        >
          {children}
        </MuiLink>
      );
    }

    // External links always open in new tabs
    return (
      <Link target="_blank" href={href} rel="noreferrer">
        <img
          src={`https://www.google.com/s2/favicons?domain=${href}&sz=64`}
          style={{
            paddingLeft: "0rem",
            paddingRight: "0.3rem",
            width: "auto",
            height: "24px",
            marginBottom: "-0.3rem",
          }}
        />

        {children}
      </Link>
    );
  },
};
