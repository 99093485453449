import { Button, Card, Typography } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { clsx } from "clsx";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";
import { vorlagenOverviewPath } from "../../../helpers/general/frontendUrlPaths";
import theme from "../../../layouts/theme";
import { RVOCtaBlockCardProps } from "./RVOCtaBlockCardInterfaces";
import { useRVOCtaBlockCardStyles } from "./RVOCtaBlockCardStyles";

const RVOCtaBlockCard = (props: RVOCtaBlockCardProps): JSX.Element => {
  const classes = useRVOCtaBlockCardStyles();
  const { t } = useTranslation();

  const { headline, subheadline, ctaLinkTarget, backgroundImage, ctaLinkText } =
    props;

  const BackgroundHead = {
    backgroundImage: backgroundImage ? `url(${backgroundImage})` : "",
    backgroundSize: "cover",
    backgroundColor: theme.palette.primary.light,
    backgroundBlendMode: "multiply",
  };

  const linkTarget = ctaLinkTarget || vorlagenOverviewPath;
  const linkLabel = ctaLinkText || t("vorlagen.beschluss");

  return (
    <Card className={classes.ctaBlockCardWrapper} style={BackgroundHead}>
      <div className={classes.content}>
        <div className={classes.subheadline}>
          <Typography
            variant="body2"
            className={clsx(classes.center, classes.text)}
          >
            {subheadline}
          </Typography>
        </div>

        <Typography variant="h3" className={clsx(classes.center, classes.text)}>
          {headline}
        </Typography>

        <Button
          component={Link}
          to={linkTarget}
          color="secondary"
          variant="contained"
          className={classes.ctaLink}
          endIcon={<ArrowForwardIcon />}
        >
          {linkLabel}
        </Button>
      </div>
    </Card>
  );
};

export default RVOCtaBlockCard;
