// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-04-templates-beschluss-generator-beschluss-generator-tsx": () => import("./../../../src/components/04-templates/BeschlussGenerator/BeschlussGenerator.tsx" /* webpackChunkName: "component---src-components-04-templates-beschluss-generator-beschluss-generator-tsx" */),
  "component---src-components-04-templates-rvo-author-template-rvo-author-template-tsx": () => import("./../../../src/components/04-templates/RVOAuthorTemplate/RVOAuthorTemplate.tsx" /* webpackChunkName: "component---src-components-04-templates-rvo-author-template-rvo-author-template-tsx" */),
  "component---src-components-04-templates-rvo-blog-details-template-rvo-blog-details-template-tsx": () => import("./../../../src/components/04-templates/RVOBlogDetailsTemplate/RVOBlogDetailsTemplate.tsx" /* webpackChunkName: "component---src-components-04-templates-rvo-blog-details-template-rvo-blog-details-template-tsx" */),
  "component---src-components-04-templates-rvo-faq-details-template-rvo-faq-details-template-tsx": () => import("./../../../src/components/04-templates/RVOFaqDetailsTemplate/RVOFaqDetailsTemplate.tsx" /* webpackChunkName: "component---src-components-04-templates-rvo-faq-details-template-rvo-faq-details-template-tsx" */),
  "component---src-components-05-pages-404-tsx": () => import("./../../../src/components/05-pages/404.tsx" /* webpackChunkName: "component---src-components-05-pages-404-tsx" */),
  "component---src-components-05-pages-agb-app-tsx": () => import("./../../../src/components/05-pages/agb-app.tsx" /* webpackChunkName: "component---src-components-05-pages-agb-app-tsx" */),
  "component---src-components-05-pages-avv-tsx": () => import("./../../../src/components/05-pages/avv.tsx" /* webpackChunkName: "component---src-components-05-pages-avv-tsx" */),
  "component---src-components-05-pages-beschluss-basics-tsx": () => import("./../../../src/components/05-pages/beschluss-basics.tsx" /* webpackChunkName: "component---src-components-05-pages-beschluss-basics-tsx" */),
  "component---src-components-05-pages-beschluss-vorlagen-tsx": () => import("./../../../src/components/05-pages/beschluss-vorlagen.tsx" /* webpackChunkName: "component---src-components-05-pages-beschluss-vorlagen-tsx" */),
  "component---src-components-05-pages-datenschutz-app-tsx": () => import("./../../../src/components/05-pages/datenschutz-app.tsx" /* webpackChunkName: "component---src-components-05-pages-datenschutz-app-tsx" */),
  "component---src-components-05-pages-datenschutz-tsx": () => import("./../../../src/components/05-pages/datenschutz.tsx" /* webpackChunkName: "component---src-components-05-pages-datenschutz-tsx" */),
  "component---src-components-05-pages-fristenrechner-kuendigung-geschaeftsfuehrer-tsx": () => import("./../../../src/components/05-pages/fristenrechner-kuendigung-geschaeftsfuehrer.tsx" /* webpackChunkName: "component---src-components-05-pages-fristenrechner-kuendigung-geschaeftsfuehrer-tsx" */),
  "component---src-components-05-pages-fristenrechner-tsx": () => import("./../../../src/components/05-pages/fristenrechner.tsx" /* webpackChunkName: "component---src-components-05-pages-fristenrechner-tsx" */),
  "component---src-components-05-pages-fuer-aufsichtsraete-tsx": () => import("./../../../src/components/05-pages/fuer-aufsichtsraete.tsx" /* webpackChunkName: "component---src-components-05-pages-fuer-aufsichtsraete-tsx" */),
  "component---src-components-05-pages-fuer-steuerberater-tsx": () => import("./../../../src/components/05-pages/fuer-steuerberater.tsx" /* webpackChunkName: "component---src-components-05-pages-fuer-steuerberater-tsx" */),
  "component---src-components-05-pages-fuer-wegs-oesterreich-tsx": () => import("./../../../src/components/05-pages/fuer-wegs-oesterreich.tsx" /* webpackChunkName: "component---src-components-05-pages-fuer-wegs-oesterreich-tsx" */),
  "component---src-components-05-pages-impressum-tsx": () => import("./../../../src/components/05-pages/impressum.tsx" /* webpackChunkName: "component---src-components-05-pages-impressum-tsx" */),
  "component---src-components-05-pages-index-tsx": () => import("./../../../src/components/05-pages/index.tsx" /* webpackChunkName: "component---src-components-05-pages-index-tsx" */),
  "component---src-components-05-pages-preise-index-tsx": () => import("./../../../src/components/05-pages/preise/index.tsx" /* webpackChunkName: "component---src-components-05-pages-preise-index-tsx" */),
  "component---src-components-05-pages-preise-mitarbeiterbeteiligung-tsx": () => import("./../../../src/components/05-pages/preise/mitarbeiterbeteiligung.tsx" /* webpackChunkName: "component---src-components-05-pages-preise-mitarbeiterbeteiligung-tsx" */),
  "component---src-components-05-pages-sicherheit-und-datenschutz-tsx": () => import("./../../../src/components/05-pages/sicherheit-und-datenschutz.tsx" /* webpackChunkName: "component---src-components-05-pages-sicherheit-und-datenschutz-tsx" */),
  "component---src-components-05-pages-style-guide-tsx": () => import("./../../../src/components/05-pages/style-guide.tsx" /* webpackChunkName: "component---src-components-05-pages-style-guide-tsx" */),
  "component---src-components-05-pages-ueber-resolvio-tsx": () => import("./../../../src/components/05-pages/ueber-resolvio.tsx" /* webpackChunkName: "component---src-components-05-pages-ueber-resolvio-tsx" */),
  "component---src-components-05-pages-wissen-grundlagen-tsx": () => import("./../../../src/components/05-pages/wissen/grundlagen.tsx" /* webpackChunkName: "component---src-components-05-pages-wissen-grundlagen-tsx" */),
  "component---src-components-05-pages-wissen-index-tsx": () => import("./../../../src/components/05-pages/wissen/index.tsx" /* webpackChunkName: "component---src-components-05-pages-wissen-index-tsx" */)
}

