import {
  datumBeschlussBeiratsbestellung,
  datumGeschäftsordnungBeirat,
  nameBeirat,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageBeiratSchuldrechtlichDienstvertrag: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.beirat,
  zustimmungErforderlichDes: "vertragsschließenden Beiratsmitglieds",
  vorlage_rolleTyp: [RolleInGesellschaft.Geschftsleiter],
  vollzug: [Vollzug.zustimmungBetroffener],
  bezug: [],
  tags: [
    "Board",
    "Beirat",
    "beratender Beirat",
    "beratend",
    "Beratung",
    "Consulting",
    "Expertenbeirat",
    "schuldrechtlicher Beirat",
    "board",
    "non-executive",
    "Kuratorium",

    "Dienstvertrag",
  ],
  kurzBezeichnung:
    "Dienstleistungsvertrag mit Beiratsmitglied (schuldrechtlicher Beirat)",
  metaDescription:
    "Vertrag mit schuldrechtlichem Beiratsmitglied, der Rechte und Pflichten des Beiratsmitglieds entstehen lässt. Juristisch geprüfte Muster/Vorlagen kostenlos.",

  description: `
  <p>
    Ein Muster für den Abschluss eines Dienstvertrages mit dem bereits bestellten Beiratsmitglied durch die Geschäftsführung. Erst hierdurch entstehen die Rechte und Pflichten gemäß der Beiratsgeschäftsordnung mit dem einzelnen Beiratsmitglied.
  </p>

  `,

  antrag: `Zwischen der Gesellschaft und ${insertTemplateVariable(
    nameBeirat
  )} wird der nachfolgende

  <h2>Beiratsdienstvertrag</h2>

  geschlossen:

  <ol data-list-style="decimal" data-list-style-prefix="§ ">

  <li>
  <h3>Bestellung, Aufgabengebiet</h3>
  <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>${insertTemplateVariable(
          nameBeirat
        )} wurde mit Gesellschafterbeschluss vom ${insertTemplateVariable(
    datumBeschlussBeiratsbestellung
  )} zum Mitglied des schuldrechtlichen Beirats der Gesellschaft bestellt.
</p></li>

  <li><p>Dem Beiratsmitglied ist die Geschäftsordnung für den Beirat vom ${insertTemplateVariable(
    datumGeschäftsordnungBeirat
  )}  welcher diesem Vertrag als <strong>Anlage</strong> beigefügt ist und der einen wesentlichen Bestandteil dieses Vertrags darstellt, vollumfänglich und vollinhaltlich bekannt. Das Beiratsmitglied verpflichtet sich hiermit gegenüber der Gesellschaft, die in diesem Beschluss getroffenen Beiratsbestimmungen zu beachten, die ihm darin gewährten Rechte und übertragenen Pflichten, insbesondere zur Geheimhaltung und zur Einhaltung des Wettbewersverbots, als eigene anzuerkennen und zum Wohl der Gesellschaft wahrzunehmen. </p></li>

  <li><p>Das Beiratsmitglied stellt der Gesellschaft seine Kenntnisse und Erfahrungen in beratender Funktion zur Verfügung. Dem Beiratsmitglied ist nicht gestattet, Geschäfte im Namen der Gesellschaft abzuschließen.</p></li>

  <li><p>Für seine Beiratstätigkeit erhält ${insertTemplateVariable(
    nameBeirat
  )} eine Vergütung sowie Auflagenersatz gemäß den Bestimmungen der Geschäftsordnung für den Beirats vom ${insertTemplateVariable(
    datumGeschäftsordnungBeirat
  )}.</p></li>
</ol>
  </li>

  <li>
  <h3>Vertragsbeginn / Kündigung</h3>
  <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>Das Dienstverhältnis beginnt mit sofortiger Wirkung und wird auf unbestimmte Zeit abgeschlossen. Dieser Vertrag ist unter Einhaltung der gesetzlichen Kündigungsfristen nach § 620 Abs. 1, § 621 BGB nur durch das Beiratsmitglied kündbar. Die Kündigung bedarf der Schriftform.</p></li>

    <li><p>Das Dienstverhältnis endet weiter automatisch zum nächstmöglichen Zeitpunkt, der auf die Beendigung der Beiratsstellung entsprechend der Geschäftsordnung für den Beirat vom ${insertTemplateVariable(
      datumGeschäftsordnungBeirat
    )}.</p></li>

    <li><p>Die Bestellung des Beiratsmitglieds kann durch Gesellschafterbeschluss jederzeit widerrufen werden, unbeschadet seiner Ansprüche nach diesem Vertrag. Der Widerruf gilt als Kündigung dieses Dienstvertrags zum nächst zulässigen Zeitpunkt.</p></li>
    </ol>
  </li>

<li>
<h3>Schlussbestimmungen</h3>
<ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
<li><p>Änderungen und Ergänzungen, Kündigung und Aufhebung dieses Vertrags bedürfen der Schriftform und eines Gesellschafterbeschlusses, wozu auch die Aufhebung dieser Schriftformklausel gehört.</p></li>


<li><p>Die aus diesem Dienstvertrag resultierenden beiderseitigen Ansprüche sind innerhalb von drei Monaten nach Fälligkeit gegenüber dem anderen Vertragsteil schriftlich oder in Textform geltend zu machen. Geschieht die Geltendmachung nicht fristgerecht, verfallen die jeweiligen Ansprüche ersatzlos.</p></li>

<li><p>Sollten einzelne Bestimmungen dieses Vertrags unwirksam sein oder werden, wird dadurch die Gültigkeit der übrigen vertraglichen Bestimmungen nicht berührt. Die unwirksame Bestimmung wird vielmehr durch eine solche ersetzt, die in gesetzlich zulässiger Weise wirtschaftlich dem gewollten am nächsten kommt. Das gleiche gilt für die Behandlung von Vertragslücken.</p></li>

<li><p>Im Falle einer unwirksamen Bestimmung und/oder einer ergänzungsbedürftigen Lücke verpflichten sich die Vertragsteile zur Abgabe aller Willenserklärungen und Vornahme aller Rechtshandlungen, um dem wirtschaftlichen Zweck der unwirksamen Bestimmung möglichst nahe zu kommen.</p></li>
</ol>
</li>
</ol>`,
  begründung: undefined,
  templateVariables: [
    nameBeirat,
    datumBeschlussBeiratsbestellung,
    datumGeschäftsordnungBeirat,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
