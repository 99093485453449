import { Typography } from "@material-ui/core";
import TemplateVariableRendered from "../../../../../components/04-templates/BeschlussGenerator/TemplateVariableRendered/TemplateVariableRendered";
import { datumBeschluss } from "../../../../vorlagen/vorlagenShared/allTemplateVariables";
import { useVotingModeStyles } from "../votingModeStyles";
import { DefaultCoatingHeaderProps } from "./DefaultCoatingHeaderInterfaces";

const DefaultCoatingHeader = (
  props: DefaultCoatingHeaderProps
): JSX.Element => {
  const classes = useVotingModeStyles();

  return (
    <>
      <Typography variant="subtitle2" className={classes.alignRight}>
        <TemplateVariableRendered variable={datumBeschluss} />
      </Typography>

      <Typography variant="h3" component="h2">
        {props.headline}
      </Typography>

      <Typography variant="body1">{props.bodyText}</Typography>
    </>
  );
};

export default DefaultCoatingHeader;
