import {
  adresseGeschäftsführer,
  geburtsdatumGeschäftsführer,
  nameGeschäftsführer,
  tagBestellung,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageBundleGeschaftsfuhrerBestellung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
  vorlageKategorie: VorlageKategorie.geschäftsführerNeu,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.zugang, Vollzug.handelsregisterAnmeldung],
  bezug: ["GeschäftsführerAnstellungsvertrag"],
  zustimmungErforderlichDes: undefined,
  tags: [
    "Bestellung",
    "Berufung",
    "Vertretungsmacht",
    "Vertretungsbefugnis",
    "Geschäftsführungsbefugnis",
    "Befreiung",
    "181",
    "Einzelvertretung",
    "Alleinvertretung",
    "Insichgeschäft",
    "Doppelvertretung",
    "Mehrfachvertretung",
    "Selbstkontrahieren",
    "Entscheider",
    "Entscheidungsträger",
    "Direktor",
    "Director",
    "Vorstand",
    "Vorsitzender",
    "Führung",
    "Leitung",
    "Leiter",
    "Geschäftsführung",
    "Geschäftsleitung",
    "Geschäftsleiter",
    "Chef",
    "Leiter",
    "Vorgesetzter",
    "Führungskraft",
    "Geschäftsführer",
    "Manager",
    "CEO",
    "CTO",
    "CFO",
    "leader",
    "head",
    "chief",
    "executive",
    "boss",
    "governor",
    "governance",
  ],
  kurzBezeichnung: "Bestellung mit Standard-Zusätzen (Bundle)",
  metaDescription:
    "Gesellschafterbeschluss, um einzelvertretungsbefugten Geschäftsführer zu bestellen. Juristisch geprüfte Muster/Vorlagen kostenlos nutzen.",
  description: `
  <p>
    Dieses Beschlussbundle verknüpft die Geschäftsführerbestellung mit zwei weiteren Beschlüssen, die typischerweise mitbeschlossen werden:
   </p>

  <ul data-list-style="square">
    <li><p>
    Einzelvertretungsbefugnis und
    </p></li>
    <li><p>
      Befreiung von den Beschränkungen des § 181 BGB.
    </p></li>
  </ul>


  <p>
    Diese Beschlussinhalte zusammengenommen geben dem neuen Geschäftsführer besonders viele Freiheiten. Wer dagegen bevorzugt, dass die allgemeine Vertretungsregelung des Gesellschaftsvertrages greift und keine § 181 BGB-Befreiung für den neuen  Geschäftsführer gelten soll, greift auf den <a href="/beschluss-vorlagen/bestellung-eines-neuen-geschaftsfuhrers">einfachen Bestellungsbeschluss</a> zurück.
  </p>
    `,
  antrag: `
  <p>
    Zum neuen Geschäftsführer der Gesellschaft wird mit Wirkung ab ${insertTemplateVariable(
      tagBestellung
    )} bestellt:
  </p>


    <ul data-list-style="none">
      <li><p>
        <strong>${insertTemplateVariable(nameGeschäftsführer)}</strong>,<br/>
        geboren am ${insertTemplateVariable(geburtsdatumGeschäftsführer)},<br/>
        wohnhaft in ${insertTemplateVariable(adresseGeschäftsführer)}
      </p></li>
    </ul>


  <p>
    ${insertTemplateVariable(
      nameGeschäftsführer
    )} ist berechtigt, die Gesellschaft einzeln zu vertreten.
  </p>

  <p>
    ${insertTemplateVariable(
      nameGeschäftsführer
    )} wird von den Beschränkungen des § 181 BGB befreit, d.h. ${insertTemplateVariable(
    nameGeschäftsführer
  )} kann im Namen der Gesellschaft
    <ul data-list-style="square">
      <li><p>
        sowohl mit sich im eigenen Namen (Selbstkontrahieren)
      </p></li>
      <li><p>
        als auch als Vertreter eines Dritten (Doppelvertretung)
      </p></li>
    </ul>
    ein Rechtsgeschäft vornehmen.
  </p>
  `,
  begründung: undefined,
  templateVariables: [
    tagBestellung,
    nameGeschäftsführer,
    geburtsdatumGeschäftsführer,
    adresseGeschäftsführer,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: true,
  noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss (§ 39 Abs. 1 GmbHG). Die Handelsregisteranmeldung muss von den Geschäftsführern in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
});
