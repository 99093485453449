import {
  anfechtungsgründe,
  beschlussDatum,
  beschlussInhalt,
  beschlussMehrheit,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageBestatigungAnfechtbarerBeschluss: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
  vorlageKategorie: VorlageKategorie.bestätigung,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.handelsregisterAnmeldung, Vollzug.beurkundung],
  bezug: [],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Bestätigung eines anfechtbaren Beschlusses",
  description:
    "Die gemäß § 244 AktG vorgesehene Möglichkeit, dass die Hauptversammlung einen anfechtbaren Beschluss bestätigen kann, gilt analog auch für die GmbH.",
  antrag: `Der von den Gesellschaftern am ${insertTemplateVariable(
    beschlussDatum
  )} gefasste Beschluss mit dem folgenden Inhalt wird <strong>bestätigt</strong>:

  <p>${insertTemplateVariable(beschlussInhalt)} </p>

  <p>Begründung:</p>

  <p>Die Gesellschafterversammlung der Gesellschaft hat am ${insertTemplateVariable(
    beschlussDatum
  )} mit ${insertTemplateVariable(
    beschlussMehrheit
  )} einen Beschluss mit dem vorbezeichneten Inhalt gefasst. Gegen diesen Beschluss wurde Anfechtungsklage erhoben und die folgenden Anfechtungsgründe behauptet: ${insertTemplateVariable(
    anfechtungsgründe
  )}.</p>

  <p>Um der Gesellschaft den Aufwand eines Anfechtungsprozesses zu ersparen und die mit dem schwebenden Gerichtsverfahren einhergehende Rechtsunsicherheit zu unterbinden, wird der vorstehende Bestätigungsbeschluss gefasst.
`,
  begründung: undefined,
  templateVariables: [
    beschlussDatum,
    beschlussInhalt,
    beschlussMehrheit,
    anfechtungsgründe,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
