import { nameGeschäftsführer } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGeschaftsfuhrerVertretungsbefugnisGesamt: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.geschäftsführerNeu,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.zugang, Vollzug.handelsregisterAnmeldung],
    bezug: ["GeschäftsführerBestellung", "GeschäftsführerBefreiung181"],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung:
      "Geschäftsführer – Einräumung von Gesamtvertretungsbefugnis",
    description: undefined,
    metaDescription:
      "Gesellschafterbeschluss, der Geschäftsführer Gesamtvertretung gemeinsam mit anderem Geschäftsführer gestattet. Muster/Vorlagen kostenlos.",
    antrag: `
      <p>
      ${insertTemplateVariable(
        nameGeschäftsführer
      )} ist nur berechtigt, die Gesellschaft gemeinschaftlich mit einem anderen Geschäftsführer zu vertreten.
        </p>`,
    begründung: undefined,
    templateVariables: [nameGeschäftsführer],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: true,
    noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss (§ 8 Abs. 4 GmbHG). Die Handelsregisteranmeldung muss von den Geschäftsführern in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
  });
