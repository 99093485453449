import {
  datumAuflösungsbeschluss,
  nameVerwahrer,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageAuflosungFortfuhrung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
  vorlageKategorie: VorlageKategorie.liquidation,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.handelsregisterAnmeldung],
  bezug: [],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Fortführung der aufgelösten Gesellschaft",
  description: undefined,
  antrag: `Der Beschluss vom ${insertTemplateVariable(
    nameVerwahrer
  )}, ${insertTemplateVariable(
    datumAuflösungsbeschluss
  )}, mit dem die Auflösung der Gesellschaft beschlossen wurde, wird nach folgender Maßgabe aufgehoben:

  <ol><li><p>Die Gesellschaft wird mit Wirkung zum heutigen Tage als Erwerbsgesellschaft fortgeführt.</p></li>

  <li><p>Mit der Verteilung des Gesellschaftsvermögens an die Gesellschafter ist noch nicht begonnen worden. Das Vermögen der Gesellschaft deckt die Verbindlichkeiten ab.</p></li>

  <li><p>Die amtierenden Geschäftsführer sind die Liquidatoren der Gesellschaft, soweit sie nicht abberufen werden.</p></li></ol>`,
  begründung: undefined,
  templateVariables: [datumAuflösungsbeschluss, nameVerwahrer],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: true,
  noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss (vgl. § 65 Abs. 1 Satz 1 GmbHG). Die Handelsregisteranmeldung muss von den Geschäftsführern in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
});
