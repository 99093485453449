import {
  betragsGrenzeDauerschuldverhältnis,
  betragsGrenzeEinmalgeschäft,
  geschäftsverteilung,
  nameGeschäftsführungVorsitzender,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGeschaftsfuhrerGeschaftsordnungEinGeschaftsfuhrer: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.geschäftsführerNeu,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.zugang],
    bezug: [
      "GeschäftsführerBestellung",
      "GeschäftsführerVertretungsbefugnisEinzel",
      "GeschäftsführerVertretungsbefugnisGesamt",
      "GeschäftsführerVertretungsbefugnisGemischt",
      "GeschäftsführerBefreiung181",
    ],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung: "Geschäftsordnung für einzelnen Geschäftsführer",
    description: undefined,
    metaDescription:
      "Geschäftsordnung für einzelnen Geschäftsführer mit Katalog zustimmungsbedürfiger Geschäfte. Juristisch geprüfte Standard-Muster/Vorlagen kostenlos ausfüllen und downloaden.",
    tags: [
      "Geschäftsordnung",
      "Katalog zustimmungsbedürftiger Geschäfte",
      "zustimmungsbedürftige Geschäfte",
      "Berichtspflichten",
      "Beschlussfassung",
    ],
    antrag: `
    <p>Folgende</p>

  <h2>Geschäftsordnung</h2>

  <p>wird für die Geschäftsführung erlassen:</p>

  <ol data-list-style="decimal" data-list-style-prefix="§ ">
    <li>
    <h3>Aufgaben der Geschäftsführer</h3>

      <p>Der Geschäftsführer führt die Geschäfte der Gesellschaft mit der Sorgfalt eines ordentlichen und gewissenhaften Geschäftsleiters nach Maßgabe des Gesetzes, des Gesellschaftsvertrags, dieser Geschäftsordnung und den in den Dienstverträgen enthaltenen Bestimmungen. Er hat den Beschlüssen der Gesellschafter, insbesondere den geschäftsleitenden Weisungen und den Richtlinien für die allgemeine Geschäftspolitik, zu folgen.</p>

    </li>

    <li><p><h3>Zustimmungsbedürftige Geschäfte</h3>

    <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>Die Geschäftsführung bedarf im Innenverhältnis der Zustimmung der Gesellschafter zum Abschluss folgender Rechtsgeschäfte bzw. zur Vornahme folgender Rechtshandlungen:</p>

        <p>a)	Entscheidungen, die eine wesentliche Änderung der Tätigkeitsge­biete und -struktur, des Aufgabenbereichs oder der Organisation der Gesellschaft betreffen, insbesondere die Planung und Durchfüh­rung von Betriebsänderungen einschließlich der Errichtung, Verle­gung und Aufhebung von Zweigniederlassungen oder Betriebsstät­ten oder von Teilen hiervon sowie die Ausdehnung der Aktivitäten der Gesellschaft auf einen neuen Auslandsmarkt,</p>

        <p>b)	Erwerb, Gründung oder Veräußerung anderer Unternehmen oder von Beteiligungen an anderen Unternehmen einschließlich aller Än­derungen der Beteiligungsquote sowie über die Teilnahme an einer Kapitalerhöhung sowie die Belastung von Gesellschaftsanteilen,</p>

        <p>c)	Abschluss, Änderung und/oder Beendigung von Verträgen über die Eingehung stiller Beteiligungen, Unterbeteiligungen und Interessengemeinschaften sowie von Betriebsführungs- und ähnlichen Ver­trägen, </p>

        <p>d)	Erwerb, Veräußerung und Belastung von Grundeigentum und grundstücksgleichen Rechten, auch sofern diese grundsätzlich im Investitionsplan enthalten sind,</p>

        <p>e)	Aufnahme und Abruf von Krediten und Darlehen,</p>

        <p>f)	Übernahme von Bürgschaften, Zahlungsgarantien, Patronatserklä­rungen und ähnlicher Haftungen mit Ausnahme solcher, die der Ab­sicherung einer eigenen ordnungsgemäßen Vertragserfüllung die­nen,</p>

        <p>g)	Gewährung von Krediten außerhalb des laufenden Geschäftsver­kehrs,</p>

        <p>h)	Sicherungsübereignung oder Verpfändung von Gegenständen der Gesellschaft außerhalb des laufenden Geschäftsverkehrs,</p>

        <p>i)	Waren- oder Börsentermingeschäfte oder sonstige Spekulationsge­schäfte,</p>

        <p>j)	Leasingverträge, sofern die monatliche Belastung mehr als ${insertTemplateVariable(
          betragsGrenzeDauerschuldverhältnis
        )} oder die Summe aller durch den jeweiligen Vertrag be­gründeten Verpflichtungen der Gesellschaft mehr als  ${insertTemplateVariable(
      betragsGrenzeEinmalgeschäft
    )} im Geschäftsjahr beträgt,</p>

        <p>k)	Miet- und Pachtverträge mit einer monatlichen Belastung von mehr als ${insertTemplateVariable(
          betragsGrenzeDauerschuldverhältnis
        )} und/oder einer Summe aller durch den jeweiligen Vertrag be­gründeten Verpflichtungen der Gesellschaft von mehr als ${insertTemplateVariable(
      betragsGrenzeEinmalgeschäft
    )} ­im Geschäftsjahr,</p>

         <p>l)	Abschluss von Verträgen (etwa mit Beratern), die über den Rahmen des laufenden Geschäftes hinausgehen, insbesondere sofern die Summe aller durch den jeweiligen Vertrag begründeten Verpflich­tungen ${insertTemplateVariable(
           betragsGrenzeEinmalgeschäft
         )} übersteigt,</p>

        <p>m)	Verträge mit Verwandten und Verschwägerten von Gesellschaftern und Geschäftsführern,</p>

        <p>n)	Erteilung und Widerruf von Generalvollmachten und Prokuren,</p>

        <p>o)	Einstellung von Angestellten sowie Entlassungen von Angestellten,</p>

        <p>p)	Errichtung von gewinnorientierten oder anderweitig leistungsbezo­genen Vergütungspflichten gegenüber Mitarbeitern und deren Än­derung,</p>

        <p>q)	Übernahme oder Änderung von Pensionsverpflichtungen sowie Zu­sage von Abfindungen bei Dienstbeendigung, sofern diese drei Bruttomonatsgehälter übersteigen,</p>

        <p>r)	Übernahme von Garantien und anderen Zusicherungen außerhalb des gewöhnlichen Geschäftsverkehrs,</p>

        <p>s)	Einleitung von Rechtsstreitigkeiten von besonderer Bedeutung und/oder deren Streitwert ${insertTemplateVariable(
          betragsGrenzeEinmalgeschäft
        )} übersteigt, </p>

        <p>t)	ferner der Ab­schluss von Vergleichen oder der Erlass von Forderungen, sofern der durch Vergleich gewährte Nachlass oder der Nennwert erlasse­ner Forderungen ${insertTemplateVariable(
          betragsGrenzeEinmalgeschäft
        )} übersteigt,</p>

        <p>u)	Erwerb, Veräußerung und Belastung von Gegenständen des Anla­gevermögens, soweit der Verkehrswert ${insertTemplateVariable(
          betragsGrenzeEinmalgeschäft
        )} übersteigt,</p>

        <p>v)	Anmeldung von und Verfügung über Lizenzen, Patente, Urheber­rechte, Marken-, Geschmacks-, Gebrauchsmusterrechte und sonsti­ge gewerbliche Schutzrechte und Know-How.</p></li>

        <li><p>Die vorherige Zustimmung der Gesellschafter ist auch einzuholen, so­fern die Geschäftsführung bei verbundenen Unternehmen der Gesell­schaft, mitwirkt:</p>

        <p>a)	an der Anstellung, Bestellung, Abberufung oder Entlastung von Mitgliedern der Geschäftsführung oder etwaig bestehender sonstiger Organe,</p>

        <p>b)	an Änderungen der Satzung,</p>

        <p>c)	an der Aufnahme eines neuen Gesellschafters,</p>

        <p>d)	an der Feststellung des Jahresabschlusses und/oder an der Be­schlussfassung über die Ergebnisverwendung,</p>

        <p>e)	an dem Abschluss von Rechtsgeschäften bzw. an der Vornahme von Rechtshandlungen entsprechend der vorstehenden Ziffer (1).</p></li>

        <li><p>Soweit nach Ziffer 1 und/oder 2 Wertgrenzen oder anderweitige Be­grenzungen maßgeblich sind, findet zur Ermittlung dieser Grenzen eine Zusammenrechnung aller Auswirkungen statt, die sich für die Gesell­schaft und alle verbundenen Unternehmen ergeben.</p></li>

        <li><p>Soweit der Erwerb, die Veräußerung oder die Belastung von Rechten oder sonstigen Gegenständen der vorherigen Zustimmung bedürfen, sind auch die schuldrechtlichen Verpflichtungsgeschäfte zustimmungs­bedürftig. Soweit Geschäfte bei der Gesellschaft selbst der Zustimmung bedürfen, hat die Geschäftsführung sicherzustellen, daß entsprechende Geschäfte bei abhängigen Unternehmen von der vorherigen Zustim­mung der Gesellschafter des abhängigen Unternehmens gebilligt wer­den.</p></li>
      </ol>
    </p></li>
    <li><p><h3>Unternehmensplanung und Berichterstattung</h3>

    <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>Der Geschäftsführer legt den Gesellschaftern spätestens im Dezember eines jeden Jahres die Unter­nehmensplanung für das folgende Geschäftsjahr zur Genehmigung vor. Die Unter­nehmens­planung umfasst insbesondere den Investitionsplan und den Finanz- und Ergebnisplan.</p></li>

        <li><p>Der Geschäftsführer unterrichtet die Gesellschafter regelmäßig über den Gang der Geschäfte und die Lage der Gesellschaft. Bei wichtigen Anlässen und bei geschäftlichen Angelegenheiten, die auf die Lage der Gesellschaft von erheblichem Einfluss sein können, sind die Gesellschafter unverzüglich zu unterrichten.</p></li>
      </ol>
    </p></li>
  </ol> `,
    begründung: undefined,
    templateVariables: [
      nameGeschäftsführungVorsitzender,
      geschäftsverteilung,
      betragsGrenzeEinmalgeschäft,
      betragsGrenzeDauerschuldverhältnis,
    ],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
