import {
  darlehenBetragWort,
  darlehenBetragZahl,
  darlehenRückzahlungen,
  datumDarlehensvertrag,
  jahreszinsProzentpunkteÜberBasiszinssatz,
  nameGesellschafterDarlehensgeber,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGesellschafterDarlehenRangrucktritt: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.sanierung,
  vorlage_rolleTyp: [RolleInGesellschaft.Geschftsleiter],
  vollzug: [Vollzug.zustimmungBetroffener],
  bezug: [],
  zustimmungErforderlichDes: "darlehensgebenden Gesellschafters",
  kurzBezeichnung: "Qualifizierter Rangrücktritt",
  description: undefined,
  antrag: `

    <p>
      Die Gesellschaft, vertreten durch die Geschäftsführung, - nachfolgend Darlehensnehmerin genannt - vereinbaren mit

  ${insertTemplateVariable(nameGesellschafterDarlehensgeber)}

  einen</p>

  <h3>Qualifizierten Rangrücktritt<h3>

  <p>mit dem folgenden Inhalt:</p>

  <ol><li><p><span style="text-transform:uppercase;"><strong>Darlehen</strong></span>

    <p>(1) ${insertTemplateVariable(
      nameGesellschafterDarlehensgeber
    )} hat der Darlehensnehmerin ein Darlehen in Höhe von ${insertTemplateVariable(
    darlehenBetragZahl
  )} € (in Worten: ${insertTemplateVariable(
    darlehenBetragWort
  )}) mit einem Zins in Höhe von jährlich ${insertTemplateVariable(
    jahreszinsProzentpunkteÜberBasiszinssatz
  )} aufgrund des Darlehensvertrages vom ${insertTemplateVariable(
    datumDarlehensvertrag
  )} gewährt.</p>

  <p>(2) Die Darlehensnehmerin hat bislang die folgenden Rückzahlungen geleistet:  ${insertTemplateVariable(
    darlehenRückzahlungen
  )}.</p></li>

<li><p><span style="text-transform:uppercase;"><strong>Rangrücktritt</strong></span>

<p>(1) Mit sofortiger Wirkung tritt ${insertTemplateVariable(
    nameGesellschafterDarlehensgeber
  )} in einem etwaigen Insolvenzverfahren über das Vermögen der Darlehensgeberin mit dem Anspruch auf die noch ausstehende Rückzahlung des vorstehend bezeichneten Darlehensbetrags gem. §§ 19 Abs. 2 Satz 2, 39 Abs. 2 InsO im Rang hinter die in § 39 Abs. 1 InsO bezeichneten Forderungen aller gegenwärtigen und zukünftigen Gläubiger der Gesellschaft dergestalt zurück, dass Tilgung, Verzinsung und Nebenforderungen nur nach allen anderen Gläubigern und nur aus einem künftigen Jahresüberschuss, Bilanzgewinn, Liquidationsüberschuss oder aus sonstigem freien Vermögen verlangt werden können.</p></li>

<p>(2) Auch für die Zeit vor Eröffnung eines etwaigen Insolvenzverfahrens tritt ${insertTemplateVariable(
    nameGesellschafterDarlehensgeber
  )} mit dem Anspruch auf die noch ausstehende Rückzahlung des vorstehend bezeichneten Darlehensbetrags gegenüber sämtlichen gegenwärtigen und zukünftigen Forderungen von Gläubigern der Darlehensnehmerin in gleicher Art und Weise im Rang zurück (sogenannte Durchsetzungssperre). </p>

<p>(3) Zahlungen auf die Rückzahlungsforderung (Tilgung, Zinsen und Nebenforderungen) dürfen nur erfolgen, wenn die Darlehensnehmerin weder zahlungsunfähig noch im insolvenzrechtlichen Sinne überschuldet ist und soweit auch die Zahlung auf die Forderung nicht zu einer Zahlungsunfähigkeit oder insolvenzrechtlichen Überschuldung der Darlehensnehmerin führt. Entsprechendes gilt für eine Aufhebung dieser Rangrücktrittsvereinbarung.</p>

<p>(4) Ein Verzicht auf die Forderung wird nicht vereinbart.</p>

<li><p><span style="text-transform:uppercase;"><strong>Schlussbestimmungen</strong></span>
<p>(1) Sollte eine der vorstehenden Bestimmungen unwirksam sein oder werden, so wird die Wirksamkeit der übrigen Bestimmungen dieses Vertrags hierdurch nicht berührt. An Stelle der unwirksamen Bestimmung gilt eine solche als vereinbart, die im Rahmen des rechtlich möglichen dem am nächsten kommt, was von den Vertragsparteien nach dem ursprünglichen Sinn und Zweck dieses Vertrags gewollt war. Diese Regelung gilt entsprechend für etwaige Lücken dieses Vertrags.</p>

<p>(2) Änderungen, Ergänzungen und die Aufhebung dieses Vertrags bedürfen zu ihrer Wirksamkeit der Schriftform. Dies gilt auch für die Änderung dieser Schriftformklausel selbst. Ausgeschlossen sind damit insbesondere Vertragsänderungen durch betriebliche Übung. Das vorstehende Schriftformerfordernis findet keine Anwendung bei Abreden, die nach Vertragsschluss unmittelbar zwischen den Parteien mündlich getroffen werden.</p>

<p>(3) Mündliche Nebenabreden bestehen nicht.</p></li>
</ol>`,
  begründung: undefined,
  templateVariables: [
    nameGesellschafterDarlehensgeber,
    darlehenBetragZahl,
    darlehenBetragWort,
    jahreszinsProzentpunkteÜberBasiszinssatz,
    datumDarlehensvertrag,
    darlehenRückzahlungen,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
