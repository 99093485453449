import { rechnungsabschlussDatum, rücklage } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageVereinRücklagenbildungFrei: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.EVDe],
  vorlageKategorie: VorlageKategorie.kapital,
  vorlage_rolleTyp: [RolleInGesellschaft.Geschftsleiter],
  vollzug: [],
  bezug: [
    "vorlageVereinRücklagenbildungZweckgebunden",
    "vorlageVereinRücklagenbildungWiederbeschaffung",
  ],
  kurzBezeichnung: "Bildung einer freien Rücklage",
  metaDescription:
    "Vorstandsbeschluss zur Bildung einer freien Rücklage im eV/Verein. Anwaltlich geprüftes Muster jetzt kostenlos nutzen.",
  tags: ["Rücklage", "freie Rücklage", "Rückstellung"],
  description: `<p>Vorlage, mit welcher der Vorstand eines gemeinnützigen Vereins eine freie Rücklage beschließen kann.</p>

  <p>Die Rücklage kann ohne zeitliche Begrenzung frei verwendet (oder gehalten werden). Die Gesamthöhe der freien Rücklage ist nicht beschränkt.</p>

  <p>Für gemeinnützige Vereine bestehen jedoch Betragsgrenzen bei der jährlichen Mittelzuführung: Jedes Jahr darf höchstens ein Drittel des Überschusses aus der Vermögensverwaltung und darüber hinaus höchstens 10 Prozent der sonstigen zeitnah zu verwendenden Mittel (d.h. Überschüsse bzw. Gewinne aus steuerpflichtigen wirtschaftlichen Geschäftsbetrieben und Zweckbetrieben sowie die Bruttoeinnahmen aus dem ideellen Bereich) in die freie RÜcklage eingestellt werden. </p>`,
  antrag: `
  <p>
   Im Rechnungsabschluss zum ${insertTemplateVariable(
     rechnungsabschlussDatum
   )} wird von den vorhandenen Mitteln des Vereins der folgende Betrag in die freie Rücklage im Sinne von § 62 Abs. 1 Nr. 3 Abgabenordnung eingestellt:
  </p>
  <ul  data-list-style="none">
    <li>
      <p> Freie Rücklage: ${insertTemplateVariable(rücklage)}</p>
    </li>
  </ul>
<p>Die für die Bildung einer freien Rücklage geltenden gesetzlichen Vorgaben werden beachtet:</p>
  <ul  data-list-style="square">
    <li>
      <p>Der jährliche Einstellungsbetrag in die freie Rücklage beträgt höchstens ein Drittel des Überschusses aus der Vermögensverwaltung und darüber hinaus höchstens 10 Prozent der sonstigen zeitnah zu verwendenden Mittel (d.h. Überschüsse bzw. Gewinne aus steuerpflichtigen wirtschaftlichen Geschäftsbetrieben und Zweckbetrieben sowie die Bruttoeinnahmen aus dem ideellen Bereich), außer es liegt eine zulässige Nachholung eines in den beiden Vorjahren nicht ausgeschöpften Höchstbetrages vor. (§ 62 Abs. 1 Nr. 3 AO)</p>
    </li>
  </ul>
  `,
  templateVariables: [rechnungsabschlussDatum, rücklage],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
