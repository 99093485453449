import { createStyles, makeStyles } from "@material-ui/core";
import { important } from "@nvon/react-toolbox";
import { RVOModalSize, RVOModalStyleProps } from "./RVOModalInterfaces";

const maxWidthsForSizes: Record<RVOModalSize, number> = {
  [RVOModalSize.Large]: 1200,
  [RVOModalSize.Regular]: 800,
};

export const useRVOModalStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      width: "100%",
      position: "relative",
      maxWidth: ({ size }: RVOModalStyleProps) =>
        `${maxWidthsForSizes[size]}px`,
      padding: theme.spacing(3),
      overflowY: "auto",
      maxHeight: "94vh",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "90%",
        padding: theme.spacing(2),
      },
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(1),
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: important(theme.spacing(5)),
        maxHeight: "100vh",
      },
    },
    closeIconButton: {
      zIndex: 10,
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        top: theme.spacing(1),
        right: theme.spacing(1),
      },
    },
    title: {
      marginBottom: theme.spacing(2),
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(0.5),
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    levelIcon: {
      "& .emoji": {
        fontSize: "2rem",
      },
      marginRight: theme.spacing(1),
    },
  })
);
