import { CompanyTypeName } from "../../data/vorlagen/vorlagenShared/generatedTypes/CompanyTypeName";
import { vorlageAllgemein } from "../../data/vorlagen/vorlagenShared/vorlagenLibrary/vorlageAllgemein";
import { vorlagenBeingUsed } from "../../data/vorlagen/vorlagenShared/vorlagenSamples/vorlagenBeingUsed";
import { rootStateEmpty } from "../../state/initialState/rootStates/rootStateEmpty";
import { RVORootState } from "../../state/storeInterfaces";
import { createVorlageLandingpage } from "../createVorlageLandingpage";
import { checkVorlagen } from "./checkVorlagen";

/**
 * The max width of containerized content.
 * This is applied to the <Container> element on xl viewports.
 *
 * All other viewports currently use the default maxWidth.
 */
export const containerMaxWidth = 1600;
export const containerPaddingBigDevices = 10;
export const containerPaddingSmallDevicesTopBottom = 6;
export const containerPaddingSmallDevicesLeftRight = 1;
export const headerHeight = 80; // px

// See https://day.js.org/docs/en/display/format#list-of-localized-formats
export const dateFormatLong = "L";

export const dateFormatMonth = "MMMM YYYY";

export const truendoSiteId = "b5ac31b0-2f90-44cc-a44b-34a9823042ad";

export const googleTrackingId = "GTM-TNF9HLT";

export const resolvioAppRegisterUrl = "https://app.resolvio.com/auth/register";
export const resolvioAppLoginUrl = "https://app.resolvio.com/auth/signin";
export const resolvioAppPurchasePackageUrl =
  "https://app.resolvio.com/companies/payment";

export const brevoDemoUrl = "https://meet.brevo.com/resolvio-demo";
export const phoneNumber = "+49 6131 6246 560";

/**
 * On a dev environment, the global (redux) root state will initially be set to this value.
 *
 * Handy for working on a specific state without having to set it on each reload.
 *
 * rootStateEmpty; -> 1st step
 * rootStateWithCompanyInfo; -> 2nd step
 * rootStateWithCompanyInfoAndVotingModePlenum; -> 3rd step
 */
export const defaultStateOnDevelopment: RVORootState = rootStateEmpty;

/**
 * In production, the application state (redux) is persisted in local storage.
 * On development, this flag can used to disable this behaviour.
 */
export const stateSyncingInDevelopmentMode = false;

/**
 * We currently only use a subset of all Vorlagen that we have.
 */
export const vorlagenInUse = vorlagenBeingUsed
  .map(createVorlageLandingpage)
  .filter((v) => !v.isArchived);

checkVorlagen(vorlagenInUse);

/**
 * To give the impression of serious data processing,
 *  generating the resolution documents will always take at least this time.
 */
export const minimumCreateDocumentDuration = 2000;

/**
 * A generic vorlage that should cover the case when we have no better one.
 */
export const fallbackVorlage = createVorlageLandingpage(vorlageAllgemein);

/**
 * The first vorlage to which we send new users.
 */
export const firstVorlageForNewUsers =
  createVorlageLandingpage(vorlageAllgemein);

/**
 * In the landingpage, we only support a subset of the
 *  company types availble in the app.
 */
export type CompanyTypeLandingpage =
  | CompanyTypeName.GmbHDe
  | CompanyTypeName.UgDe
  | CompanyTypeName.UgCoKgDe
  | CompanyTypeName.GmbHCoKgDe
  | CompanyTypeName.KgDe
  | CompanyTypeName.OHgDe
  | CompanyTypeName.GbRDe
  | CompanyTypeName.PartGDe
  | CompanyTypeName.PartGmbBDe
  | CompanyTypeName.EVDe;

/**
 * Default discount for startups and non profit organizations
 */
export const startupNonProfitDiscount = 50;

/**
 * Brevo Chat Setup
 */

export const brevoConversationsId = "65044fc4e1ce5f651301a972";
