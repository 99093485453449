import { useBooleanState } from "@nvon/react-toolbox";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { usePosthogEvent } from "../../../hooks/useTrackingEvent";
import RVOChatBubblePlaceholder from "../../01-atoms/RVOChatBubblePlaceholder/RVOChatBubblePlaceholder";
import { useConsentData } from "../TruendoCookieConsent/TruendoConsentProvider";
import { RVOTrackingEvent } from "../TruendoGoogleTagManager/trackEvent";

export const TruendoBrevoChatWidget = (): JSX.Element => {
  const brevoChatAccepted = useConsentData().preferences;
  const { firePosthogEvent } = usePosthogEvent();

  const { on: loadBrevoScript, state: isActive } = useBooleanState(false);

  useEffect(() => {
    if (brevoChatAccepted) {
      loadBrevoScript();
    }
  }, [brevoChatAccepted, loadBrevoScript]);

  useEffect(() => {
    setTimeout(() => {
      window.BrevoConversationsSetup = {
        disabledOnMobile: true,
        onAnalyticEvent: (eventName) => {
          firePosthogEvent({
            // as we don't have a list of potential event names from Brevo, we just use the eventName as eventId
            eventId: eventName as RVOTrackingEvent,
            additionalProps: { source: "brevoChat" },
          });
        },
      };
    }, 1000);
  }, [firePosthogEvent]);

  return isActive ? (
    <Helmet>
      <script id="brevoChat" type="text/javascript" src="/scripts/brevo.js" />
    </Helmet>
  ) : (
    <RVOChatBubblePlaceholder />
  );
};

/**
 * There' some more.
 * Just check the Brevo Objects at runtime to discover.
 */
declare global {
  interface Window {
    BrevoConversations: (action: string, value: boolean) => void;
  }

  interface Window {
    BrevoConversationsSetup: {
      disabledOnMobile?: boolean;
      disableGaTracking?: boolean;
      onAnalyticEvent: (eventName: string) => void;
    };
  }
}
