import {
  Backdrop,
  Fade,
  IconButton,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { clsx } from "clsx";
import { noop } from "lodash-es";
import {
  SettingEmoji,
  SuccessEmoji,
  WarningEmoji,
} from "../../../helpers/clientSide/emojis";
import { useId } from "../../../hooks/useId";
import { EmojiComponent } from "../../01-atoms/Emoji/createEmoji";
import { SanitizeHTML } from "../../01-atoms/SanitizeHTML/SanitzeHTML";
import {
  RVOModalLevel,
  RVOModalProps,
  RVOModalSize,
} from "./RVOModalInterfaces";
import { useRVOModalStyles } from "./RVOModalStyles";

const iconsForLevels: Record<RVOModalLevel, EmojiComponent | null> = {
  [RVOModalLevel.Info]: null,
  [RVOModalLevel.Warning]: WarningEmoji,
  [RVOModalLevel.Success]: SuccessEmoji,
  [RVOModalLevel.Setting]: SettingEmoji,
};

/**
 * Show a modal box with a title and contents.
 */
const RVOModal = (props: RVOModalProps): JSX.Element => {
  const { isOpen, onClose, children, title, size } = props;
  const classes = useRVOModalStyles({ size: size || RVOModalSize.Regular });

  if (props.level && props.emoji) {
    // We want to warn here.
    // eslint-disable-next-line no-console
    console.warn(
      `Using both "level" and "emoji" on RVOModal is redundant, since "level" will be without effect.`
    );
  }

  const LevelIcon =
    props.emoji || iconsForLevels[props.level || RVOModalLevel.Info];

  const titleId = useId();
  const textId = useId();

  const wrappedContent =
    typeof children === "string" ? (
      <Typography variant="body1">{children}</Typography>
    ) : (
      children
    );

  const dynamicOnClose = props.disableClose ? noop : onClose;

  return (
    <Modal
      aria-labelledby={titleId}
      aria-describedby={textId}
      className={clsx(classes.wrapper, props.className)}
      open={isOpen}
      onClose={dynamicOnClose}
      closeAfterTransition={true}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {
          backdropFilter: `blur(${props.blur || 5}px)`,
          backgroundColor: "rgba(0,0,30,0.4)",
        },
        timeout: 500,
        onClick: (e) => {
          e.stopPropagation();
          e.preventDefault();
          dynamicOnClose();
        },
      }}
    >
      <Fade in={isOpen}>
        <Paper className={classes.paper}>
          {!props.disableClose && (
            <IconButton
              arial-label="close"
              className={classes.closeIconButton}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                dynamicOnClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          )}

          {title && (
            <Typography variant="h2" className={classes.title}>
              {LevelIcon && (
                <span className={classes.levelIcon}>
                  <LevelIcon />
                </span>
              )}

              <SanitizeHTML html={title} />
            </Typography>
          )}

          {wrappedContent}
        </Paper>
      </Fade>
    </Modal>
  );
};

export default RVOModal;
