import { Duration, Easing, Theme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

/**
 * Transition a single css prop, or a list of props,
 *  with the default theme transition.
 *
 * @example
 * ```
 * // In your style file:
 * {
 *   opacity: 1,
 *   ...defaultTransition(theme, "opacity"),
 * }
 *
 * // With multiple props, overriding the duration:
 * {
 *   ...defaultTransition(theme, ["opacity", "transform"], "")
 * }
 * ```
 */
export const defaultTransition = (
  theme: Theme,
  props: string | string[],
  duration: keyof Duration = "standard",
  easing: keyof Easing = "easeInOut"
): CSSProperties => {
  const propsArray = typeof props === "string" ? [props] : props;
  return {
    transition: theme.transitions.create(propsArray, {
      duration: theme.transitions.duration[duration],
      easing: theme.transitions.easing[easing],
    }),
  };
};
