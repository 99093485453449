import { Typography } from "@material-ui/core";
import { navigate } from "gatsby";
import { Button } from "gatsby-material-ui-components";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { VotingModeSwitchKey } from "../../../data/juristicalData/votingModes/votingModeInterfaces";
import { allVotingModes } from "../../../data/juristicalData/votingModes/votingModes/votingModes";
import { queryParameterForVotingMode } from "../../../helpers/general/frontendUrlPaths";
import { useQueryParam } from "../../../hooks/useQueryParams";
import {
  useSelectedVotingMode,
  useSetSwitchValue,
} from "../../../state/votingMode/votingModeSlice";
import { RVOSetting } from "../RVOSetting/RVOSetting";
import { useGetVotingModeSlug } from "./getVotingModeSlug";
import { useRVOVotingModeUrlSwitchStyles } from "./RVOVotingModeUrlSwitchStyles";

/**
 * Triggers a change of the current voting mode when an appropriate URL is detected.
 *
 * Displays an information to the user, informing them of the change.
 */
const RVOVotingModeUrlSwitch = (): JSX.Element | null => {
  const currentVotingMode = useSelectedVotingMode();
  const classes = useRVOVotingModeUrlSwitchStyles();
  const { t } = useTranslation();

  const getVotingModeSlug = useGetVotingModeSlug();
  const setSwitchValue = useSetSwitchValue();

  const votingModeUrlParam = useQueryParam(queryParameterForVotingMode);

  const votingModeFromUrl = votingModeUrlParam
    ? allVotingModes.find(
        (votingMode) => getVotingModeSlug(votingMode) === votingModeUrlParam
      )
    : undefined;

  const adjustAndClose = useCallback(() => {
    if (!votingModeFromUrl) {
      return;
    }

    Object.entries(votingModeFromUrl?.switchValues).forEach(
      ([switchKey, switchValue]) => {
        setSwitchValue(switchKey as VotingModeSwitchKey, switchValue);
      }
    );

    const url = new URL(window.location.href);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigate(url.pathname);
  }, [setSwitchValue, votingModeFromUrl]);

  const shouldShowAndAdjust =
    !!votingModeFromUrl &&
    votingModeFromUrl.identifier !== currentVotingMode?.identifier;

  return (
    <RVOSetting
      isOpen={shouldShowAndAdjust}
      onClose={adjustAndClose}
      title={`${t("votingMode.newModePreselected")}: ${
        votingModeFromUrl?.label(t) || ""
      }`}
    >
      <Typography variant="body1">
        {t("votingMode.newModePreselectedText1")}

        {votingModeFromUrl?.label(t) || ""}

        {t("votingMode.newModePreselectedText2")}
      </Typography>

      <br />

      <Typography variant="body1">{t("votingMode.changeAnyTime")}</Typography>

      <Button
        className={classes.okayButton}
        variant="contained"
        color="primary"
        onClick={adjustAndClose}
      >
        {t("general.gotIt")}
      </Button>
    </RVOSetting>
  );
};

export default RVOVotingModeUrlSwitch;
