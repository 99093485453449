import { Button, Grid, Typography } from "@material-ui/core";
import { Instagram, LinkedIn } from "@material-ui/icons";
import { Link } from "gatsby-material-ui-components/lib/link";
import { useTranslation } from "react-i18next";
import { Page } from "../../../../config/Pages";
import { firstVorlageForNewUsers } from "../../../helpers/clientSide/constants";
import { usePathToPage } from "../../../helpers/clientSide/usePathToPage";
import {
  forCondosAustriaPath,
  forSupervisoryBoardsPath,
  forTaxConsultantsPath,
  fristenrechnerKündigungGfPath,
  fristenrechnerPath,
  ueberResolvioPath,
  vorlagenOverviewPath,
  wissenPath,
} from "../../../helpers/general/frontendUrlPaths";
import RVOContainer from "../../02-molecules/RVOContainer/RVOContainer";
import { RVOBackgroundColor } from "../../02-molecules/RVOContainer/RVOContainerInterfaces";
import RVOPageSectionLinks from "../../02-molecules/RVOPageSectionLinks/RVOPageSectionLinks";
import { RVONavigationSection } from "../../02-molecules/RVOPageSectionLinks/RVOPageSectionLinksInterfaces";
import { useRVOFooterStyles } from "./RVOFooterStyles";

const RVOFooter = (): JSX.Element => {
  const classes = useRVOFooterStyles();
  const { t } = useTranslation();
  const pathToPage = usePathToPage();

  const showPanel = () => {
    if (typeof window !== "undefined") {
      window.Truendo?.openCookieSettings();
    }
  };

  return (
    <footer>
      <RVOContainer
        noPaddingTop={true}
        background={RVOBackgroundColor.PrimaryDarkBlue}
        fullWidth={true}
      >
        <Grid container={true} className={classes.wrapper}>
          <Grid
            container={true}
            className={classes.newsletterDastoolHilfeWrapper}
          >
            <Grid
              item={true}
              container={true}
              lg={12}
              className={classes.rigthColumnWrapper}
            >
              <Grid item={true} className={classes.dasToolWrapper}>
                <Typography
                  variant="body1"
                  component="h3"
                  color="textSecondary"
                  className={classes.columnTitle}
                >
                  {t("footer.dastool.title")}
                </Typography>

                <ul className={classes.ul}>
                  <RVOPageSectionLinks
                    navigationSection={RVONavigationSection.Footer}
                  />
                </ul>
              </Grid>

              <Grid item={true} className={classes.informationWrapper}>
                <Typography
                  variant="body1"
                  component="h3"
                  color="textSecondary"
                  className={classes.columnTitle}
                >
                  {t("footer.resolvioFor.title")}
                </Typography>

                <ul className={classes.ul}>
                  <li>
                    <Link to={forSupervisoryBoardsPath}>
                      <Typography
                        variant="body2"
                        component="span"
                        color="textSecondary"
                      >
                        {t("footer.resolvioFor.forSupervisoryBoards")}
                      </Typography>
                    </Link>
                  </li>

                  <li>
                    <Link to={forTaxConsultantsPath}>
                      <Typography
                        variant="body2"
                        component="span"
                        color="textSecondary"
                      >
                        {t("footer.resolvioFor.forTaxConsultants")}
                      </Typography>
                    </Link>
                  </li>

                  <li>
                    <Link to={forCondosAustriaPath}>
                      <Typography
                        variant="body2"
                        component="span"
                        color="textSecondary"
                      >
                        {t("footer.resolvioFor.forCondosAustria")}
                      </Typography>
                    </Link>
                  </li>
                </ul>
              </Grid>

              <Grid item={true} className={classes.informationWrapper}>
                <Typography
                  variant="body1"
                  component="h3"
                  color="textSecondary"
                  className={classes.columnTitle}
                >
                  {t("footer.informationen.title")}
                </Typography>

                <ul className={classes.ul}>
                  <li>
                    <Link to={ueberResolvioPath}>
                      <Typography
                        variant="body2"
                        component="span"
                        color="textSecondary"
                      >
                        {t("footer.informationen.aboutResolvio")}
                      </Typography>
                    </Link>
                  </li>

                  <li>
                    <Link to={vorlagenOverviewPath}>
                      <Typography
                        variant="body2"
                        component="span"
                        color="textSecondary"
                      >
                        {t("footer.informationen.chooseTemplate")}
                      </Typography>
                    </Link>
                  </li>

                  <li>
                    <Link to={wissenPath}>
                      <Typography
                        variant="body2"
                        component="span"
                        color="textSecondary"
                      >
                        {t("footer.informationen.wissen")}
                      </Typography>
                    </Link>
                  </li>

                  <li>
                    <Link to={firstVorlageForNewUsers.landingpageGeneratorLink}>
                      <Typography
                        variant="body2"
                        component="span"
                        color="textSecondary"
                      >
                        {t("footer.generator")}
                      </Typography>
                    </Link>
                  </li>

                  <li>
                    <Link to={fristenrechnerPath}>
                      <Typography
                        variant="body2"
                        component="span"
                        color="textSecondary"
                      >
                        {t("header.fristenrechner")}
                      </Typography>
                    </Link>
                  </li>

                  <li>
                    <Link to={fristenrechnerKündigungGfPath}>
                      <Typography
                        variant="body2"
                        component="span"
                        color="textSecondary"
                      >
                        {t("header.fristenrechnerKündigungGf")}
                      </Typography>
                    </Link>
                  </li>

                  <li>
                    <Link onClick={showPanel}>
                      <Typography
                        variant="body2"
                        component="span"
                        color="textSecondary"
                      >
                        {t("footer.informationen.cookie-einstellungen")}
                      </Typography>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={pathToPage(Page.PrivacyPolicy)}
                      className={classes.link}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        color="secondary"
                      >
                        {t("footer.datenschutz")}
                      </Typography>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={pathToPage(Page.Imprint)}
                      className={classes.link}
                    >
                      <Typography
                        variant="body2"
                        component="span"
                        color="secondary"
                      >
                        {t("footer.impressum")}
                      </Typography>
                    </Link>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>

          <Grid container={true} className={classes.socialMediaWrapper}>
            <Grid item={true}>
              <Button
                className={classes.socialMediaButton}
                href="https://www.instagram.com/resolvio_official"
                target="_blank"
              >
                <Instagram
                  color="secondary"
                  fontSize="large"
                  className={classes.socialMediaIcon}
                />
              </Button>
            </Grid>

            <Grid item={true}>
              <Button
                className={classes.socialMediaButton}
                href="https://www.linkedin.com/company/resolvio-de/"
                target="_blank"
              >
                <LinkedIn
                  color="secondary"
                  fontSize="large"
                  className={classes.socialMediaIcon}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </RVOContainer>
    </footer>
  );
};

export default RVOFooter;
