import { nameGeschäftsführer } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGeschaftsfuhrerGeneralbereinigung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
  vorlageKategorie: VorlageKategorie.geschäftsführerAusscheiden,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.zugang],
  bezug: [
    "GeschäftsführerGeneralbereinigung",
    "GeschäftsführerAnstellungsvertragAufhebung",
    "GeschäftsführerAnstellungsvertragKündigungAusWichtigemGrund",
    "GeschäftsführerAnstellungsvertragKündigungOrdentlich",
    "GeschäftsführerEntlastungErteilung",
    "GeschäftsführerEntlastungVerweigerung",
    "GeschäftsführerGeltendmachungSchadenersatzansprüche",
  ],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Generalbereinigung zugunsten des Geschäftsführers",

  tags: [
    "Entlastung",
    "Generalbereinigung",
    "Haftungsverzicht",
    "abberufen",
    "Abberufung",
    "abbestellen",
    "Abbestellung",

    "Entscheider",
    "Entscheidungsträger",
    "Direktor",
    "Director",
    "Vorstand",
    "Vorsitzender",
    "Führung",
    "Leitung",
    "Leiter",
    "Geschäftsführung",
    "Geschäftsleitung",
    "Geschäftsleiter",
    "Chef",
    "Leiter",
    "Vorgesetzter",
    "Führungskraft",
    "Geschäftsführer",
    "Manager",
    "CEO",
    "CTO",
    "CFO",
    "leader",
    "head",
    "chief",
    "executive",
    "boss",
    "governor",
    "governance",
  ],
  metaDescription:
    "Gesellschafterbeschluss um Geschäftsführer eine Generalbereinigung zu gewähren. Von Anwälten geprüfte Muster/Vorlagen kostenlos ausfüllen und downloaden.",
  description: `
  <p>
    Im Gegensatz zur Entlastung erstreckt wirkt die Generalbereinigung für den Geschäftsführer auch dann haftungsbefreiend, wenn das kontrollierende Gremium (meistens Gesellschafter oder Aufsichtsrat) von den haftungsrelevanten Sachverhalt noch keine Kenntnis haben konnte.
  </p>
  <p>
    Die Generalbereinigung ist für den Geschäftsführer also weitaus günstiger als die bloße Entlastung.
   </p>
`,
  antrag: `${insertTemplateVariable(
    nameGeschäftsführer
  )} wird für seine Geschäftsführungstätigkeit die Generalbereinigung erteilt.`,
  begründung: undefined,
  templateVariables: [nameGeschäftsführer],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
