import { nameGeschäftsführer } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGeschaftsfuhrerEntlastungVerweigerung: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.geschäftsführerAusscheiden,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.zugang],
    bezug: [
      "GeschäftsführerAbberufung",
      "GeschäftsführerAnstellungsvertragKündigungAusWichtigemGrund",
      "GeschäftsführerAnstellungsvertragKündigungOrdentlich",
      "GeschäftsführerGeltendmachungSchadenersatzansprüche",
    ],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung: "Verweigerung der Entlastung des Geschäftsführers",
    metaDescription:
      "Gesellschafterbeschluss, der Geschäftsführer keine Entlastung ertelt, sondern verweigert. Beschluss aus juristisch geprüften Mustern/Vorlagen kostenlos erstellen.",
    description: `
  <p>
    Die Verweigerung der Entlastung ist der Konterpart zur <a href="/beschluss-vorlagen/entlastung-des-geschaftsfuhrers">Entlastungserteilung</a>. Mit der Entlastungsverweigerung drücken die Gesellschafter ihre <strong>Missbilligung der Geschäftsführung</strong> aus. In der Folge kommt es zu <strong>keiner Haftungsfreistellung</strong> zugunsten des Geschäftsführers.
  </p>

  <p>
    Die <strong>Gesellschafter</strong> sind für den Beschluss über die Entlastungsverweigerung zuständig. Die Entlastung muss jedoch nicht zwingend ausdrücklich verweigert werden. Das schlichte Unterlassen eines positiven Entlastungsbeschlusses wirkt sich in vergleichbarer Weise zulasten des Geschäftsführers aus.
  </p>`,
    antrag: `<p>${insertTemplateVariable(
      nameGeschäftsführer
    )} wird die Entlastung als Geschäftsführer verweigert.</p>`,
    begründung: undefined,
    templateVariables: [nameGeschäftsführer],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
