import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { RootState } from "../storeInterfaces";
import { stateOnboardingHelpFresh } from "./states/stateOnboardingHelpFresh";

/**
 * A state to manage everything related to user onboarding.
 */
export interface OnboardingHelpState {
  /**
   * @see RVOChatBubblePlaceholder.tsx
   */
  hasSeenChatWindowHelp: boolean;
  /**
   * We only show the chat widget after some initial timeout.
   */
  shouldShowChatWidget: boolean;
}

export const onboardingHelpSlice = createSlice({
  name: "onboardingHelp",
  initialState: stateOnboardingHelpFresh,
  reducers: {
    setHasSeenChatWindowHelp: (state, action: PayloadAction<boolean>) => {
      state.hasSeenChatWindowHelp = action.payload;
    },
    setShouldShowChatTrue: (state) => {
      state.shouldShowChatWidget = true;
    },
  },
});

// Actions …

export const {
  setHasSeenChatWindowHelp: setHasSeenChatWindowHelpUserAction,
  setShouldShowChatTrue: setShouldShowChatTrueUserAction,
} = onboardingHelpSlice.actions;

// … and hooks to use the actions

export const useSetHasSeenChatWindowHelp = (): (() => void) => {
  const dispatcher = useAppDispatch();
  return useCallback(
    () => dispatcher(setHasSeenChatWindowHelpUserAction(true)),
    [dispatcher]
  );
};

export const useSetShouldShowChatTrue = (): (() => void) => {
  const dispatcher = useAppDispatch();
  return useCallback(
    () => dispatcher(setShouldShowChatTrueUserAction()),
    [dispatcher]
  );
};

export const useUnsetHasSeenChatWindowHelp = (): (() => void) => {
  const dispatcher = useAppDispatch();
  return useCallback(
    () => dispatcher(setHasSeenChatWindowHelpUserAction(false)),
    [dispatcher]
  );
};

// Selectors …

const hasSeenChatWindowHelp = (
  state: RootState
): OnboardingHelpState["hasSeenChatWindowHelp"] =>
  state.onboardingHelp.hasSeenChatWindowHelp;

const shouldShowChat = (
  state: RootState
): OnboardingHelpState["shouldShowChatWidget"] =>
  state.onboardingHelp.shouldShowChatWidget;

// … and hooks to get the data from the selectors

export const useHasSeenChatWindowHelp = (): ReturnType<
  typeof hasSeenChatWindowHelp
> => useAppSelector(hasSeenChatWindowHelp);

export const useShouldShowChat = (): ReturnType<typeof shouldShowChat> =>
  useAppSelector(shouldShowChat);

export default onboardingHelpSlice.reducer;
