import RVOSpecialTextArea from "../../../../components/02-molecules/RVOSpecialTextArea/RVOSpecialTextArea";
import { RVOWissenTextArea } from "../../../../components/02-molecules/RVOSpecialTextArea/RVOSpecialTextAreaInterfaces";
import { TagToComponentMap } from "../replaceHtmlElementInterfaces";

export const mappedUnorderedLists: TagToComponentMap = {
  ul: ({ children, replacedElement }) => {
    const isTldr = replacedElement.attribs.class?.includes(
      RVOWissenTextArea.Tldr
    );

    if (isTldr) {
      return (
        <RVOSpecialTextArea variant={RVOWissenTextArea.Tldr}>
          <ul>{children}</ul>
        </RVOSpecialTextArea>
      );
    } else {
      return <ul {...replacedElement.attribs}>{children}</ul>;
    }
  },
};
