// They be very smol.
/* eslint-disable react/no-multi-comp */
import { createEmoji } from "../../components/01-atoms/Emoji/createEmoji";

export const NoResultsEmoji = createEmoji("🥲", "Fröhliches Gesicht mit Träne");

export const WarningEmoji = createEmoji("⚠️", "Warnung");

export const IndexPointingRightEmoji = createEmoji("👉", "So geht's");

export const IndexPointingUpEmoji = createEmoji("☝️", "Hinweis");

export const WomanTippingHandEmoji = createEmoji("💁‍♀️", "Frau gibt Hinweis");

export const SuccessEmoji = createEmoji("🎉", "Tada");

export const SettingEmoji = createEmoji("⚙️", "Zahnrad aus Metall");

export const DocumentEmoji = createEmoji("📄", "Dokumentenseite");

export const ChatEmoji = createEmoji("💬", "Chat Sprechblase");

export const ScissorsEmoji = createEmoji("✂️", "Schere");

export const BallotBoxEmoji = createEmoji("🗳️", "Wahlurne");

export const HearNoEvilEmoji = createEmoji(
  "🙉",
  "Äffchen hält sich die Ohren zu"
);

export const MuscleEmoji = createEmoji("💪", "Muskulöser Arm");

export const VictoryEmoji = createEmoji("✌", "Victory Zeichen");

export const StarStruckEmoji = createEmoji("🤩", "Gesicht mit Sternaugen");

export const HandShakeEmoji = createEmoji("🤝", "Handshake");

export const RocketEmoji = createEmoji("🚀", "Rakete hebt ab");

export const RunningWomanEmoji = createEmoji("🏃‍♀️", "Rennende Frau");

export const ConstructionWorkerEmoji = createEmoji("👷‍♀️", "Bauarbeiterin");

export const FireEmoji = createEmoji("🔥", "Fiah");
