import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useRVOImageHighlightBoxStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: ({ isSmallScreen }: { isSmallScreen: boolean }) => ({
      "& picture": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        margin: !isSmallScreen
          ? `${theme.spacing(3)} ${theme.spacing(1)}`
          : `${theme.spacing(1)} ${theme.spacing(0.5)}`,
      },
      "& img": {
        alginSelf: "center",
        textAlgin: "center",
        width: !isSmallScreen ? "80%" : "100%",
        maxWidth: "100%",
        height: "auto",
      },
      padding: `${theme.spacing(3)} 0`,
      borderRadius: theme.shape.borderRadius,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",

      "& figure": { textAlign: "center" },
      "& figure img": { maxWidth: "100%", height: "auto" },
      "& figcaption ": {
        textAlign: "center",
        paddingTop: theme.spacing(3),
        ...theme.typography.caption,
        color: theme.palette.primary.dark,
      },
    }),
  })
);
