import { Link, Typography } from "@material-ui/core";
import { useSetShowVorlagenPicker } from "../../../state/beschlussVorlage/beschlussVorlageSlice";
import { RVOShowVorlagenPickerProps } from "./RVOShowVorlagenPickerInterfaces";
import { useRVOShowVorlagenPickerStyles } from "./RVOShowVorlagenPickerStyles";

const RVOShowVorlagenPicker = (
  props: RVOShowVorlagenPickerProps
): JSX.Element => {
  const classes = useRVOShowVorlagenPickerStyles();
  const setIsPickerShowing = useSetShowVorlagenPicker();

  return (
    <Link
      className={classes.wrapper}
      component={"button"}
      onClick={() => setIsPickerShowing(true)}
    >
      <Typography variant="body1"> {props.children}</Typography>
    </Link>
  );
};

export default RVOShowVorlagenPicker;
