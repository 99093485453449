import { RVOFootnoteNumberProps } from "./RVOFootnoteNumberInterfaces";
import { useRVOFootnoteNumberStyles } from "./RVOFootnoteNumberStyles";

const RVOFootnoteNumber = (props: RVOFootnoteNumberProps): JSX.Element => {
  const classes = useRVOFootnoteNumberStyles();

  if (props.dataFn && props.footnoteNumberId) {
    return (
      <sup
        data-fn={props.dataFn}
        id={props.footnoteNumberId}
        className={classes.numberBox}
      >
        {props.children}
      </sup>
    );
  } else {
    return <sup className={classes.numberBox}> {props.children}</sup>;
  }
};

export default RVOFootnoteNumber;
