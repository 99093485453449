import {
  betragInflationsausgleichspraemie,
  nameGeschäftsführer,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGeschaftsfuhrerInflationsausgleichspraemie: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.geschäftsführer,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.selfexecuting],
    bezug: ["GeschäftsführerAnstellungsvertrag"],
    zustimmungErforderlichDes: "Geschäftsführers",
    tags: [
      "Inflationsausgleichsprämie",
      "Inflation",
      "Ausgleichsprämie",
      "Inflation",
      "Steuerfreiheit",
      "Finanzamt",
      "steuerfrei",
      "sozialversicherungsfrei",
      "Arbeitgeberleistung",
      "Direktor",
      "Director",
      "Vorstand",
      "Vorsitzender",
      "Führung",
      "Leitung",
      "Leiter",
      "Geschäftsführung",
      "Geschäftsleitung",
      "Geschäftsleiter",
      "Chef",
      "Leiter",
      "Vorgesetzter",
      "Führungskraft",
      "Geschäftsführer",
      "Manager",
      "CEO",
      "CTO",
      "CFO",
      "leader",
      "head",
      "chief",
      "executive",
      "boss",
      "governor",
      "governance",
    ],
    kurzBezeichnung: "Inflationsausgleichsprämie an Geschäftsführer",
    metaDescription:
      "Gesellschafterbeschluss, um an Geschäftsführer steuer- und sozialversicherungsfreie Inflationsausgleichsprämie iSv § 3 Nr. 11c EStG auszuzahlen. Juristisch geprüfte Muster/Vorlagen kostenlos nutzen.",
    description: `
  <p>
    Diese Beschlussvorlage dokumentiert, dass an einen Geschäftsführer eine steuer- und sozialversicherungsfreie Inflationsausgleichsprämie iSv § 3 Nr. 11c EStG ausgezahlt werden soll.
   </p>

  <p>Die Inflationsausgleichsprämie ist ein legale Steuervergünstigung, wonach Arbeitnehmer zusätzlich zum ohnehin geschuldeten Arbeitslohn vom Arbeitgeber Zusatzzahlungen in Form von Zuschüssen und Sachbezügen steuer- und sozialversicherungsfrei erhalten dürfen. Diese Steuervergünstigung soll die gestiegenen Verbraucherpreise abmildern und gilt nur bis zu einem Betrag von 3 000 Euro. Außerdem muss die Zusatzzahlung in der Zeit vom 26. Oktober 2022 bis zum 31. Dezember 2024 gewährt werden.</p>


  <p>
    Ein Geschäftsführer einer GmbH oder UG (haftungsbeschränkt) ist einkommenssteuerrechtlich auch Arbeitnehmer, selbst wenn er Gesellschafter ist. Das gilt sogar, wenn der Geschäfsführer Alleingesellschafter ist. Deswegen kann jeder Geschäftsführer die Steuervergünstigung in Anspruch nehmen und mit dieser  Beschlussvorlage rechtssicher dokumentieren.
  </p>
    `,
    antrag: `
  <p>
    Die Gesellschaft gewährt ihrem Geschäftsführer ${insertTemplateVariable(
      nameGeschäftsführer
    )} zusätzlich zu der ohnehin geschuldeten Vergütung eine Inflationsausgleichsprämie in Höhe von
  </p>


    <ul data-list-style="none">
      <li><p>
        <strong>${insertTemplateVariable(
          betragInflationsausgleichspraemie
        )}</strong>
      </p></li>
    </ul>


  <p>
   zur Abmilderung der gestiegenen Verbraucherpreise. Der Betrag wird unverzüglich an ${insertTemplateVariable(
     nameGeschäftsführer
   )} ausgezahlt.
  </p>

  <p>
  Die vorstehende Gewährung der Inflationsausgleichsprämie erfolgt in Anwendung des § 3 Nr. 11c Einkommensteuergesetz (EStG), wonach Zahlungen zusätzlich zum ohnehin geschuldeten Arbeitslohn vom Arbeitgeber in der Zeit vom 26. Oktober 2022 bis zum 31. Dezember 2024 zur Abmilderung der gestiegenen Verbraucherpreise bis zu einem Betrag von 3 000 Euro steuerfrei gewährt werden dürfen.
  </p>
  `,
    begründung: undefined,
    templateVariables: [nameGeschäftsführer, betragInflationsausgleichspraemie],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
