import { ComponentWithChildren } from "@nvon/react-toolbox";
import { posthog } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { useEffect } from "react";
import { useLocation } from "react-use";
import { useConsentData } from "./TruendoCookieConsent/TruendoConsentProvider";

const initializePosthog = () => {
  if (typeof window !== "undefined") {
    const posthogKey = "phc_ip6HwlPmboc6ZNno8gQacyedG3kFo4bglEkdVtaoLVh";
    const posthogHost = "https://eu.i.posthog.com";

    posthog.init(posthogKey, {
      api_host: posthogHost,
      session_recording: {
        maskTextSelector: "*",
        maskAllInputs: true,
      },
      loaded: (ph) => {
        if (process.env.NODE_ENV === "development") {
          ph.debug();
        }
      },
    });
  }
};

/**
 * Posthog is our product analytics tool.
 */
export const RVOPostHogProvider: ComponentWithChildren = ({ children }) => {
  const posthogAccepted = useConsentData().services.posthog;
  const location = useLocation();

  useEffect(() => {
    if (posthogAccepted) {
      initializePosthog();
      posthog.capture("$pageview");
    }
  }, [posthogAccepted]);

  useEffect(() => {
    if (posthogAccepted) {
      posthog.capture("$pageview");
    }
    // We only want to track pageviews when the pathname changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};
