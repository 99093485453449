import { nameGeschäftsführer } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGeschaftsfuhrerVertretungsbefugnisEinzel: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.geschäftsführerNeu,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.zugang, Vollzug.handelsregisterAnmeldung],
    bezug: ["GeschäftsführerBestellung", "GeschäftsführerBefreiung181"],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung:
      "Geschäftsführer – Einräumung von Einzelvertretungsbefugnis",
    metaDescription:
      "Gesellschafterbeschluss, der Geschäftsführer Einzelvertretungsbefugnis einräumt. Beschluss aus juristisch geprüften Mustern/Vorlagen kostenlos erstellen.",
    description: undefined,
    antrag: `
    <p>
    ${insertTemplateVariable(
      nameGeschäftsführer
    )} ist berechtigt, die Gesellschaft einzeln zu vertreten.
    </p>
    `,
    begründung: undefined,
    templateVariables: [nameGeschäftsführer],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: true,
    noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss (§ 8 Abs. 4 GmbHG). Die Handelsregisteranmeldung muss von den Geschäftsführern in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
  });
