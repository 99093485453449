import {
  betragHöchstensDeckungssumme,
  betragMindestensSelbstbehalt,
  zuVersicherendePerson,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageZustimmungVertragsabschlussDUndOEckpunkte: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [
      CompanyTypeName.GmbHDe,
      CompanyTypeName.UgDe,
      CompanyTypeName.GmbHCoKgDe,
      CompanyTypeName.UgCoKgDe,
      CompanyTypeName.GbRDe,
      CompanyTypeName.KgDe,
      CompanyTypeName.OHgDe,
      CompanyTypeName.PartGmbBDe,
      CompanyTypeName.PartGDe,
    ],
    vorlageKategorie: VorlageKategorie.geschäftsführer,
    vorlage_rolleTyp: [
      RolleInGesellschaft.Mitglieder,
      RolleInGesellschaft.Aufsichtsrat,
    ],
    vollzug: [Vollzug.zugang],
    bezug: ["vorlageZustimmungDUndOVertragsabschlussAnlage"],
    zustimmungErforderlichDes: undefined,
    metaDescription: `Beschlussvorlage, um Abschluss einer D&O-Versicherung durch Geschäftsführer mit bestimmten Eckpunkten zuzustimmen. Juristisch geprüfte Muster/Vorlagen kostenlos.`,

    description: `
  <p>
  Geschäftsführer verletzten ihre Pflichten gegenüber ihrer Gesellschaft, wenn sie für sich eine Unternehmens-D&O-Versicherung abschließen, ohne dass ein legitimierender Gesellschafterbeschluss gefasst worden ist. Im Schadensfall kann der Versicherungsschutz fehlen, wenn die Gesellschafter dem Versicherungsabschluss nicht zugestimmt haben.
  </p>
  <p>
  Diese Beschlussvorlage hilft dem Geschäftsführer, sich die notwendige Zustimmung der Gesellschafter (oder des Beirats oder Aufsichtsrats) einzuholen. Dabei wird der Versicherungsvertrag nur nach Eckpunkten (Maximale Deckungssumme und Mindest-Selbstbehalt) beschrieben, um dem Geschäftsführer eine gewisse Verhandlungs- und Auswahlfreiheit bei der Auswahl des richtigen Versicherungstermins zu lassen.
  </p>
  `,
    tags: [
      "D&O",
      "Versicherung",
      "D&O-Versicherung",
      "Vermögensschaden-Haftpflichtversicherung",
      "Managerversicherung",
      "Managerhaftpflichtversicherung",
      "Organversicherung",
      "versichern",
      "Abschluss einer D&O-Versicherung",

      "Selbstbehalt",
      "Selbstbeteiligung",
      "Deckungssumme",

      "Entscheider",
      "Entscheidungsträger",
      "Direktor",
      "Director",
      "Vorstand",
      "Vorsitzender",
      "Führung",
      "Leitung",
      "Leiter",
      "Geschäftsführung",
      "Geschäftsleitung",
      "Geschäftsleiter",
      "Chef",
      "Leiter",
      "Vorgesetzter",
      "Führungskraft",
      "Geschäftsführer",
      "Manager",
      "CEO",
      "CTO",
      "CFO",
      "leader",
      "head",
      "chief",
      "executive",
      "boss",
      "governor",
      "governance",

      "feststellen",
      "Feststellung",
      "genehmigen",
      "absegnen",
      "zustimmen",
      "grünes Licht",
      "Weg frei machen",
      "Okay geben",
      "O.K. geben",
      "OK geben",
      "Erlaubnis",
      "Unterstützung",
      "erlauben",
      "unterstützen",
      "einwilligen",
      "Einwilligung",
      "Genehmigung",
      "Zustimmung",
      "Approval",
      "blessing",
      "confirmation",
      "consent",
      "endorsement",
      "green light",
      "persmission",
      "ratification",
      "support",
    ],
    kurzBezeichnung:
      "Zustimmung zum Abschluss einer D&O-Versicherung (Eckpunkte)",

    antrag: `
  <p>
   Der Geschäftsführung wird die Zustimmung erteilt,
  </p>

  <p>
    <ul data-list-style="none">
      <li><p>
      eine Vermögensschaden-Haftpflichtversicherung (D&O-Versicherung) für den Geschäftsführer
      ${insertTemplateVariable(
        zuVersicherendePerson
      )} als versicherte Person      </p></li>
    </ul>
  </p>

  <p>
  im Namen der und mit Wirkung für die Gesellschaft abzuschließen.
  </p>

  <p>
    Die Zustimmung gilt nur für einen Vertragsschluss, der die folgenden Eckpunkte beachtet:
  </p>


   <ul data-list-style="none">
      <li><p>
     Deckungssumme von höchstens ${insertTemplateVariable(
       betragHöchstensDeckungssumme
     )}
      </p></li>
       <li><p>
     Selbstbehalt für die versicherte Person von mindestens ${insertTemplateVariable(
       betragMindestensSelbstbehalt
     )}
      </p></li>
   </ul>

  `,
    begründung: undefined,
    templateVariables: [
      zuVersicherendePerson,
      betragHöchstensDeckungssumme,
      betragMindestensSelbstbehalt,
    ],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
