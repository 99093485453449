import { Text } from "domhandler/lib/node";
import { Element } from "html-react-parser";
import { TemplateVariable } from "../../../../common-interfaces/TemplateVariableInterface";
import { LegacyTemplateLandingpage } from "../../../../helpers/createVorlageLandingpage";
import { templateVariableKey } from "../../../../helpers/general/templates/templateVariableKey";
import { TemplateVariableKey } from "../../../../interfaces/variousInterfaces";

/**
 * Extract a template variable from its surrounding dom node.
 */
export const extractVariableFromDomNode = (
  domNode: Element,
  vorlage: LegacyTemplateLandingpage
): {
  templateVariable: TemplateVariable;
  templateVariableKey: TemplateVariableKey;
} => {
  const variableKey = (domNode.children[0] as Text)?.data;
  if (!variableKey) {
    throw Error(`Could not extract variable key.`);
  }
  const variable = vorlage.templateVariables.find(
    (aVariable) => templateVariableKey(aVariable) === variableKey
  );
  if (!variable) {
    throw Error(
      [
        `Did not find variable with key '${variableKey}' in Vorlage '${vorlage.kurzBezeichnung}'.`,
        "Did you include it in `Vorlage.templateVariables`?",
      ].join("\n\n")
    );
  }
  return {
    templateVariable: variable,
    templateVariableKey: variableKey,
  };
};
