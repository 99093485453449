import { planJahr } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageJahresplanungFeststellung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.regelmäßigerBeschluss,
  vorlage_rolleTyp: [
    RolleInGesellschaft.Mitglieder,
    RolleInGesellschaft.Aufsichtsrat,
    RolleInGesellschaft.Beirat,
  ],
  vollzug: [Vollzug.selfexecuting],
  tags: [
    "Jahresplanung",
    "Unternehmensplanung",
    "planen",
    "Liquiditätsplan",
    "Jahresplan",
    "Finanzplan",
    "Businessplan",
    "Business plan",
    "Ertragsplanung",
    "Investitionsplan",
    "Budgetplan",
    "Kapitalbedarfsplan",
    "Finanzierungsplan",
    "Bilanzplan",
    "Personalplan",
    "Personalbedarfsplan",
    "Beschaffungsplan",
    "Produktioinsplan",
    "Materialbedarfsplan",
    "Verwaltungsplan",
    "Absatzplan",
    "Budgetplan",
    "Wirtschaftsplan",

    "feststellen",
    "Feststellung",
    "genehmigen",
    "absegnen",
    "zustimmen",
    "grünes Licht",
    "Weg frei machen",
    "Okay geben",
    "O.K. geben",
    "OK geben",
    "Erlaubnis",
    "Unterstützung",
    "erlauben",
    "unterstützen",
    "einwilligen",
    "Einwilligung",
    "Genehmigung",
    "Zustimmung",
    "Approval",
    "blessing",
    "confirmation",
    "consent",
    "endorsement",
    "green light",
    "persmission",
    "ratification",
    "support",
  ],

  description: `
  <p>Zur ordnungsgemäßen Unternehmensleitung gehört der Blick in die Zukunft - die Erstellung einer Unternehmensplanung. Je nach Größe und Art des Unternehmens, können unterschiedliche Planungen angezeigt - gängige Teilpläne sind:
  </p>

  <p>
    <ul data-list-style="square">
      <li><p><strong>Budgetplan/Liquiditätsplan/Finanzplan</strong>: Aufstellung der zu erwartenden Einzahlungen und Auszahlungen in einer Planungsperiode.</p></li>
      <li><p><strong>Investitionsplan</strong>: Aufstellung der beabsichtigten Investitionen und Desinvestitionen im Anlagevermöge</p></li>
      <li><p><strong>Personalplan</strong>: Planung des voraussichtlichen Personalbedarfs und Aufstellung der erwarteten Einstellung oder Entlassungen</p></li>
    </ul>
  </p>

  <p>Für die Planung kommen unterschiedliche <strong>Zeithorizonte</strong> in Betracht:
  </p>
    <p>
    <ul data-list-style="square">
      <li><p>1 Jahr (operative Planung)</p></li>
      <li><p>5 - 10 Jahre (strategische Planung)</p></li>
    </ul>
  </p>
  `,
  bezug: [
    "EntlastungRegelmäßigAufsichtsrat",
    "EntlastungKomplementärRegelmäßig",
    "EntlastungRegelmäßigKomplementär",
    "EntlastungRegelmäßigGeschäftsführenderPartner",
    "EntlastungRegelmäßigBeirat",
    "EntlastungRegelmäßigGeschäftsführenderGesellschafter",
    "EntlastungRegelmäßigGeschäftsführer",
    "EntlastungRegelmäßigKomplementärGmbH",
    "AbschlussprüferBestellung",
    "JahresabschlussAufstellung",
    "JahresabschlussFeststellung",
    "VerwendungDesGewinns",
  ],
  kurzBezeichnung: "Feststellung der Unternehmensplanung",
  metaDescription:
    "Unternehmensplanung feststellen mit Gesellschafterbeschluss, zB Finanzplan oder Investitionsplan. Juristisch geprüfte Muster/Vorlagen kostenlos downloaden.",
  antrag: `
   <p>
    Die von der Geschäftsführung aufgestellte und als <strong>Anlage</strong> beigefügte
  </p>

  <p>
    <ul data-list-style="none">
      <li><p>
       Unternehmensplanung für das Geschäftsjahr ${insertTemplateVariable(
         planJahr
       )}
      </p></li>
    </ul>
  </p>

  <p>
    wird festgestellt und genehmigt.
  </p>
  `,
  begründung: undefined,
  templateVariables: [planJahr],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
