import { createStyles, makeStyles } from "@material-ui/core";

export const useVotingModeStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      "& h2": {
        textAlign: "left !important",
        padding: `${theme.spacing(0)} !important`,
        fontSize: "1rem",
      },
    },
    highlight: {
      fontWeight: 600,
    },
    alignRight: {
      textAlign: "right",
    },
    marginBottom: {
      marginBottom: "1rem !important",
    },
    marginBottomDouble: {
      marginBottom: "2rem !important",
      marginTop: "0 !important",
    },
  })
);
