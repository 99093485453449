import {
  klauselAusschluss,
  nameGesellschafter,
  wichtigerGrund,
  wichtigerGrundAbwägung,
  wichtigerGrundEntgegnung,
  wichtigerGrundUltimaratio,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGesellschafterAusschlussKlausel: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.GbRDe,
  ],
  vorlageKategorie: VorlageKategorie.ausscheidenEinesGesellschafters,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.zugang, Vollzug.handelsregisterGesellschafterliste],
  bezug: [],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung:
    "Ausschluss eines Gesellschafters aufgrund gesellschaftsvertraglicher Vorschrift (ohne Ausschlussklage)",
  description: undefined,
  antrag: `<ol><li><p>${insertTemplateVariable(
    nameGesellschafter
  )} wird aus der Gesellschaft ausgeschlossen.</p>

  <p>Eine Gesellschafterausschluss durch Beschluss ist aufgrund § ${insertTemplateVariable(
    klauselAusschluss
  )} des Gesellschaftsvertrags möglich, ohne dass es der Erhebung einer Ausschlussklage bedarf.</p></lil>

      <li><p>Die Geschäftsführung wird angewiesen, eine aktualisierte Gesellschafterliste gemäß § 40 Abs. 1 GmbHG zum Handelsregister einzureichen und eine Meldepflicht der Gesellschaft zum Transparenzregister zu prüfen und ggf. zu erfüllen.</p></li></ol>

  <p>Begründung:</p>
  <p>Die Ausschließung von ${insertTemplateVariable(
    nameGesellschafter
  )} aus der Gesellschaft ist gerechtfertigt, denn es liegt ein wichtiger Grund in der Person von ${insertTemplateVariable(
    nameGesellschafter
  )} vor, der nach Maßgabe einer unter Einbeziehung aller relevanter Umstände vorzunehmenden Gesamtbewertung seine weitere Mitgliedschaft in der Gesellschaft als untragbar erscheinen lässt bzw. die Erreichung des Gesellschaftszwecks ernsthaft gefährdet oder unmöglich macht. Der wichtige Grund lässt sich auch nicht auf eine andere Weise als durch Ausschließung aus der Gesellschaft beheben. </p>

  <p>Folgender wichtiger Grund liegt in der Person von ${insertTemplateVariable(
    nameGesellschafter
  )}vor: ${insertTemplateVariable(wichtigerGrund)}.</p>

  <p>Das Vorliegen des wichtigen Grundes lässt die weitere Mitgliedschaft in der Gesellschaft aufgrund der folgenden Abwägungsgesichtspunkte als untragbar erscheinen: ${insertTemplateVariable(
    wichtigerGrundAbwägung
  )}.</p>

   <p>Der wichtige Grund lässt sich auch nicht auf eine andere Weise als durch Ausschließung aus der Gesellschaft beheben, denn: ${insertTemplateVariable(
     wichtigerGrundUltimaratio
   )}.</p>

   <p>${insertTemplateVariable(
     nameGesellschafter
   )} wurde mit den vorstehenden ausschlussbegründenden Umstände im Einzelnen konfrontiert und ihm Gelegenheit zur Stellungnahme gegeben. Hierzu erklärte ${insertTemplateVariable(
    nameGesellschafter
  )}: ${insertTemplateVariable(wichtigerGrundEntgegnung)}.</p>
 `,
  begründung: undefined,
  templateVariables: [
    nameGesellschafter,
    klauselAusschluss,
    wichtigerGrund,
    wichtigerGrundAbwägung,
    wichtigerGrundUltimaratio,
    wichtigerGrundEntgegnung,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
