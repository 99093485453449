import {
  adresseProkurist,
  geburtsdatumProkurist,
  nameProkurist,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageProkuraBeendigung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
  vorlageKategorie: VorlageKategorie.prokura,
  vorlage_rolleTyp: [
    RolleInGesellschaft.Geschftsleiter,
    RolleInGesellschaft.Mitglieder,
  ],
  vollzug: [Vollzug.zugang, Vollzug.handelsregisterAnmeldung],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Beendigung einer Prokura",
  metaDescription:
    "Gesellschafterbeschluss, der Prokura erlöschen lässt. Juristisch geprüfte Muster/Vorlagen kostenlos ausfüllen und nutzen.",
  description: `
  <p>
    Die Beendigung der Prokura erfolgt dadurch, dass die Geschäftsführung gegenüber dem Prokuristen deren Erlöschen erklärt.
  </p>

  <p>
    Das Erlöschen der Prokura ist zur Eintragung in das Handelsregister anzumelden. Das Erlöschen kann gegenüber Geschäftspartnern zusätzlich mitgeteilt werden, um zu verhindern, dass der ehemalige Prokurist als "Scheinprokurist" weiter Verträge im Namen der Gesellschaft abschließt.
  </p>
  `,
  antrag: `
  <p>
    Die Prokura von
  </p>
  <p>
    ${insertTemplateVariable(nameProkurist)},<br/>
    geboren am ${insertTemplateVariable(geburtsdatumProkurist)},<br/>
    wohnhaft in ${insertTemplateVariable(adresseProkurist)},
  </p>
  <p>
    ist mit sofortiger Wirkung <strong>erloschen</strong>.
  </p>
  `,
  begründung: undefined,
  templateVariables: [nameProkurist, geburtsdatumProkurist, adresseProkurist],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: true,
  noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss. Die Handelsregisteranmeldung muss von den Geschäftsführern in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
});
