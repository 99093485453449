import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";
import {
  geschäftsanteileNennbetragEinziehung,
  klauselAbfindung,
  klauselEinziehung,
  nameGesellschafter,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

export const vorlageEinziehungMitAufstockung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
  vorlageKategorie: VorlageKategorie.ausscheidenEinesGesellschafters,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  kurzBezeichnung: "Einziehung mit Aufstockung",
  antrag: `Der Geschäftsanteil des Gesellschafters ${insertTemplateVariable(
    nameGesellschafter
  )} mit einem Nennbetrag von ${insertTemplateVariable(
    geschäftsanteileNennbetragEinziehung
  )} € wird gem. § ${insertTemplateVariable(
    klauselEinziehung
  )} des Gesellschaftsvertrages eingezogen. Der Geschäftsführer wird beauftragt, dem Gesellschafter ${insertTemplateVariable(
    nameGesellschafter
  )} gegenüber die Einziehung schriftlich zu erklären. Die Nennbeträge der nach der Einziehung verbleibenden Geschäftsanteile werden anteilig um den Nennbetrag des eingezogenen Geschäftsanteils im Verhältnis der Nennbeträge der verbleibenden Geschäftsanteile zum Stammkapital erhöht (Aufstockung). Der Geschäftsführer wird mit der Ermittlung und Auszahlung des Abfindungsguthabens gem. § ${insertTemplateVariable(
    klauselAbfindung
  )} des Gesellschaftsvertrages beauftragt.`,
  templateVariables: [
    nameGesellschafter,
    geschäftsanteileNennbetragEinziehung,
    klauselEinziehung,
    klauselAbfindung,
  ],
  vollzug: [
    Vollzug.zugang,
    Vollzug.handelsregisterAnmeldung,
    Vollzug.handelsregisterGesellschafterliste,
  ],
});
