import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";
import {
  adresseGeschäftsführer,
  betragGehaltNachVerzicht,
  betragGehaltVerzicht,
  betragGehaltVorVerzicht,
  geburtsdatumGeschäftsführer,
  nachzahlungszeitraumMax,
  nameGeschäftsführer,
  tagGehaltsverzichtBeginn,
  tagRückabwicklungschutzAblauf,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

export const vorlageGeschaftsfuhrerAnstellungsvertragGehaltsverzichtOhneBesserungsschein: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.geschäftsführer,
    vorlage_rolleTyp: [
      RolleInGesellschaft.Mitglieder,
      RolleInGesellschaft.Aufsichtsrat,
      RolleInGesellschaft.Beirat,
    ],
    vollzug: [Vollzug.zustimmungBetroffener],
    bezug: [
      "GeschäftsführerAnstellungsvertragEinfach",
      "GeschäftsführerAnstellungsvertragAufhebung",
    ],
    tags: [
      "Anstellung",
      "Anstellungsvertrag",
      "aufheben",
      "Aufhebungsvertrag",
      "Anstellungsvertrag ändern",
      "Gehaltsverzicht",
      "Lohnverzicht",
      "Reduzierung des Gehalts",
      "Verzicht",
      "Besserungsklausel",
      "Besserungsschein",

      "Entscheider",
      "Entscheidungsträger",
      "Direktor",
      "Director",
      "Vorstand",
      "Vorsitzender",
      "Führung",
      "Leitung",
      "Leiter",
      "Geschäftsführung",
      "Geschäftsleitung",
      "Geschäftsleiter",
      "Chef",
      "Leiter",
      "Vorgesetzter",
      "Führungskraft",
      "Geschäftsführer",
      "Manager",
      "CEO",
      "CTO",
      "CFO",
      "leader",
      "head",
      "chief",
      "executive",
      "boss",
      "governor",
      "governance",
    ],
    zustimmungErforderlichDes: "Geschäftsführers",
    kurzBezeichnung:
      "Gehaltsverzichtsvereinbarung für den Geschäftsführer ohne Besserungsklausel",
    metaDescription:
      "Beschlussvorlage für den Gehaltsverzicht des Geschäftsführer ohne Besserungsklausel. Muster/Vorlagen von Rechtsanwälten kostenlos ausfüllen und downloaden.",
    description: `<p>
        Diese Vorlage umfasst die üblichen Regelungspunkte, welche im Rahmen einer typischen Gehaltsverzichtsvereinbarung mit dem Geschäftsführer zu regeln sind. Für die Beschlussfassung sind in der Regel die Gesellschafter oder der Aufsichtsrat zuständig. Einzelheiten ergeben sich aus dem Gesellschaftsvertrag der betreffenden Gesellschaft.
      </p>
      <p>
      Damit die Vereinbarung wirksam wird, ist die Zustimmung des betroffenen Geschäftsführers erforderlich.
      </p>`,
    antrag: `

<p>Die Gesellschaft schließt mit</p>
<p>
    ${insertTemplateVariable(nameGeschäftsführer)},<br/>
    geboren am ${insertTemplateVariable(geburtsdatumGeschäftsführer)},<br/>
    wohnhaft/geschäftsansässig in ${insertTemplateVariable(
      adresseGeschäftsführer
    )},
</p>
<p>eine</p>
<h2>Vereinbarung über den Gehaltsverzicht mit Besserungsklausel</h2>
<p>mit dem folgenden Inhalt ab:</p>
<ol data-list-style="decimal" data-list-style-prefix="§ ">
    <li>
        <h3>Gehaltsverzicht</h3>
        <p>
            Mit Wirkung ab dem ${insertTemplateVariable(
              tagGehaltsverzichtBeginn
            )} wird das Brutto-Monatsgehalt von ${insertTemplateVariable(
      nameGeschäftsführer
    )} von ${insertTemplateVariable(
      betragGehaltVorVerzicht
    )} um ${insertTemplateVariable(
      betragGehaltVerzicht
    )} auf ${insertTemplateVariable(betragGehaltNachVerzicht)} herabgesetzt.
        </p>
    </li>
    <li>
        <h3>Erweiterter Kündigungsschutz</h3>
        <p>
            Wenn die Gesellschaft vor dem ${insertTemplateVariable(
              tagRückabwicklungschutzAblauf
            )} gegenüber ${insertTemplateVariable(
      nameGeschäftsführer
    )} eine ordentliche Kündigung erklären sollte, wird der Gehaltsverzicht gemäß § 1 dieser Vereinbarung vollständig rückabgewickelt.
        </p>
    </li>
    <li>
        <h3>Laufzeit des Gehaltsverzichts</h3>
        <p>
            Wenn sich die wirtschaftliche Lage der Gesellschaft nach Maßgabe dieser Vereinbarung verbessert hat, endet der Gehaltsverzicht und der Anspruch von ${insertTemplateVariable(
              nameGeschäftsführer
            )} auf das ursprüngliche Gehalt lebt wieder auf.
        </p>
    </li>

    <li>
        <h3>Schlussbestimmung</h3>
        <p>Soweit in dieser Vereinbarung nicht anders geregelt, bleiben alle übrigen vertraglichen Bestimmungen über das Anstellungsverhältnis unberührt.</p>
    </li>
</ol>
`,
    begründung: undefined,
    templateVariables: [
      adresseGeschäftsführer,
      nameGeschäftsführer,
      geburtsdatumGeschäftsführer,
      tagGehaltsverzichtBeginn,
      betragGehaltVorVerzicht,
      betragGehaltVerzicht,
      betragGehaltNachVerzicht,
      tagRückabwicklungschutzAblauf,
      nachzahlungszeitraumMax,
    ],
  });
