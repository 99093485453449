import {
  adresseGeschäftsführer,
  beginnGeschäftsführertätigkeit,
  geburtsdatumGeschäftsführer,
  nameGeschäftsführer,
  urlaubstageAnzahl,
  vergütungFix,
  vergütungTantieme,
  vergütungWeihnachten,
  wochenKündigungsfrist,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGeschaftsfuhrerAnstellungsvertragEinfach: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.geschäftsführerNeu,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.zustimmungBetroffener],
    bezug: [
      "GeschäftsführerBestellung",
      "GeschäftsführerVertretungsbefugnisEinzel",
      "GeschäftsführerVertretungsbefugnisGesamt",
      "GeschäftsführerVertretungsbefugnisGemischt",
      "GeschäftsführerBefreiung181",
    ],
    zustimmungErforderlichDes: "anzustellenden Geschäftsführers",
    kurzBezeichnung: "Einfacher Anstellungsvertrag im Beschluss",
    tags: [
      "Anstellung",
      "Anstellungsvertrag",
      "anstellen",
      "Geschäftsführervergütung",
      "Vergütung",
      "vergüten",
      "service contract",
      "managers' employment contract",
      "salary",
      "compensation",
      "remuneration",

      "Entscheider",
      "Entscheidungsträger",
      "Direktor",
      "Director",
      "Vorstand",
      "Vorsitzender",
      "Führung",
      "Leitung",
      "Leiter",
      "Geschäftsführung",
      "Geschäftsleitung",
      "Geschäftsleiter",
      "Chef",
      "Leiter",
      "Vorgesetzter",
      "Führungskraft",
      "Geschäftsführer",
      "Manager",
      "CEO",
      "CTO",
      "CFO",
      "leader",
      "head",
      "chief",
      "executive",
      "boss",
      "governor",
      "governance",
    ],
    metaDescription:
      "Gesellschafterbeschluss, der Standard-Anstellungsvertrag mit Geschäftsführer enthält. Muster/Vorlagen von Rechtsanwälten kostenlos ausfüllen und downloaden.",
    description: `
    <p>
    Diese Vorlage enthält einen standardmäßigen Anstellungsvertrag für einen Geschäftsführer. Die <strong>Vergütungsregelungen</strong> sehen eine Mischung aus monatlichem Fixgehalt, Weihnachtsbonus sowie einer gewinnabhängige Tantieme vor.
    </p>
    <p>
    Kommt ein Geschäftsführer neu in die GmbH bzw. UG, ist neben einem Anstellungsvertrag auch ein <a href="/beschluss-vorlagen/bestellung-mit-standard-zusatzen-(bundle)"><strong>Bestellungsbeschluss</strong></a> erforderlich, um dem Geschäftsführer Vertretungs- und Geschäftsführungsbefugnis einzuräumen.
    </p>
    <p>Zum Abschluss, zur Änderung und Beendigung des Dienstvertrags eines Geschäftsführers einer GmbH bzw. UG sind bei Fehlen abweichender Satzungsbestimmungen die <strong>Gesellschafter der GmbH bzw. UG zuständig</strong> [BGH, Urteil vom 3.7. 2000 – Az. II ZR 282/98; BGH, Urteil vom 3.7. 2018 – Az. II ZR 42/17].</p>
    `,
    antrag: `<p>Die Gesellschaft schließt mit</p>

  <p>${insertTemplateVariable(nameGeschäftsführer)},<br/>
  geboren am ${insertTemplateVariable(geburtsdatumGeschäftsführer)},<br/>
  wohnhaft/geschäftsansässig in ${insertTemplateVariable(
    adresseGeschäftsführer
  )},</p>

  <p>einen</p>

  <h2>Anstellungsvertrag</h2>

  <p>mit dem folgenden Inhalt ab:</p>

  <ol data-list-style="decimal" data-list-style-prefix="§ ">
  <li><h3>Geschäftsführung und Vertretung</h3>

    <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>${insertTemplateVariable(
          nameGeschäftsführer
        )} ist berechtigt und verpflichtet, die Gesellschaft nach Maßgabe der Gesetze, des Gesellschaftsvertrags, einer etwaigen Geschäftsführungsordnung sowie einer etwaig für ${insertTemplateVariable(
      nameGeschäftsführer
    )} erlassenen konkreten Vertretungsregelung zu vertreten und die Geschäfte der Gesellschaft zu führen. Weisungen der Gesellschafterversammlung sind zu befolgen, soweit Vereinbarungen in diesem Vertrag nicht entgegenstehen.</p></li>

  <li><p>${insertTemplateVariable(
    nameGeschäftsführer
  )} hat die ihm/ihr obliegenden Pflichten mit der Sorgfalt einer ordentlichen und gewissenhaften Geschäftsperson unter Wahrung der Interessen der Gesellschaft wahrzunehmen.
  </p></li>
  </ol>
  </li>

  <li><h3>Einzelne Aufgaben</h3>

    <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>${insertTemplateVariable(
          nameGeschäftsführer
        )} obliegt die Leitung und Überwachung des Unternehmens im Ganzen.</p></li>

    <li><p>${insertTemplateVariable(
      nameGeschäftsführer
    )} nimmt die Rechte und Pflichten des Arbeitgebers im Sinne der arbeits- und sozialrechtlichen Vorschriften wahr.</p></li>

    <li><p>${insertTemplateVariable(
      nameGeschäftsführer
    )} hat die steuerlichen Interessen der Gesellschaft zu wahren. ${insertTemplateVariable(
      nameGeschäftsführer
    )} ist verpflichtet, innerhalb von 3 Monaten nach Ablauf des Geschäftsjahres unter Beachtung der handels- und steuerrechtlichen Bilanzierungsregeln den Jahresabschluss (Bilanz, Gewinn- und Verlustrechnung sowie Anhang) zu erstellen. ${insertTemplateVariable(
      nameGeschäftsführer
    )} obliegt es, für eine den handels- und steuerrechtlichen Vorschriften entsprechende Buchführung und eine dem Unternehmen entsprechende Betriebsabrechnung zu sorgen.</p></li>

   <li><p>${insertTemplateVariable(
     nameGeschäftsführer
   )} hat für die rechtzeitige und ordnungsgemäße Offenlegung des Jahresabschlusses nach § 325 HGB Sorge zu tragen. Von größenabhängigen Erleichterungen nach §§ 326, 327, 327a HGB ist Gebrauch zu machen, sofern die Gesellschafterversammlung nichts anderes beschlossen hat.</p></li>

    <li><p>${insertTemplateVariable(
      nameGeschäftsführer
    )} hat den Gesellschaftern einen Geschäftsbericht zu erstatten. Auf Verlangen sind schriftliche Zwischengeschäftsberichte und Auskünfte zu erteilen.</p></li>

    <li><p>${insertTemplateVariable(
      nameGeschäftsführer
    )} hat die notwendigen Anmeldungen zum Handelsregister vorzunehmen.</p></li>

    <li><p>Genehmigungen zur Abtretung von Geschäftsanteilen und Teilgeschäftsanteilen darf ${insertTemplateVariable(
      nameGeschäftsführer
    )} nur auf Grund des Gesellschaftsvertrags und der einschlägigen Gesellschafterbeschlüsse vornehmen. Dasselbe gilt für die Anforderung etwa noch ausstehender Stammeinlagen.</p></li>

    <li><p>${insertTemplateVariable(
      nameGeschäftsführer
    )} hat gemäß § 40 GmbHG nach jeder Veränderung in den Personen der Gesellschafter oder des Umfangs ihrer Beteiligung unverzüglich eine von ihm unterschriebene Liste der Gesellschafter zum Handelsregister einzureichen.</p></li>
</ol>
  </li>

  <li><h3>Genehmigungsbedürftige Geschäfte</h3>

    <p>${insertTemplateVariable(
      nameGeschäftsführer
    )} bedarf für alle Geschäfte und Maßnahmen, die im Gesellschaftsvertrag als zustimmungsbedürftig bestimmt sind oder die über den gewöhnlichen Betrieb des Handelsgewerbes der Gesellschaft hinausgehen, der ausdrücklichen vorherigen Zustimmung der Gesellschafter.</p>
  </li>

  <li><h3>Dienstleistung</h3>

    <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>Der Geschäftsführer hat seine ganze Arbeitskraft und seine gesamten Kenntnisse und Erfahrungen der Gesellschaft zur Verfügung zu stellen.</p></li>

    <li><p>An bestimmte Arbeitszeiten ist der Geschäftsführer nicht gebunden.</p></li>
</ol>
  </li>

  <li><h3>Treuepflichten, Betriebsgeheimnisse</h3>

    <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>${insertTemplateVariable(
          nameGeschäftsführer
        )} ist verpflichtet, Dritten gegenüber strengstes Stillschweigen über alle Angelegenheiten der Gesellschaft zu wahren. Diese Pflicht besteht nach Beendigung des Anstellungsverhältnisses fort.</p></li>

    <li><p>Geschäftliche und betriebliche Unterlagen aller Art, einschließlich persönlicher Aufzeichnungen über dienstliche Angelegenheiten, dürfen nur zu geschäftlichen Zwecken verwandt werden und sind sorgfältig aufzubewahren. Bei Beendigung des Anstellungsverhältnisses sind vorstehende Unterlagen der Gesellschaft auszuhändigen.</p></li>

    <li><p>Zurückbehaltungsrechte können durch ${insertTemplateVariable(
      nameGeschäftsführer
    )}  nicht geltend gemacht werden. ${insertTemplateVariable(
      nameGeschäftsführer
    )} kann mit Ansprüchen gegen die Gesellschaft nicht aufrechnen.</p></li>
</ol>
  </li>

  <li><h3>Nebentätigkeit, Wettbewerb</h3>

    <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>Nebentätigkeit, auch die Wahrnehmung von Ehrenämtern, bedürfen der Einwilligung der Gesellschafter.</p></li>

    <li><p>${insertTemplateVariable(
      nameGeschäftsführer
    )} verpflichtet sich, für die Dauer dieses Vertrags und der darauf folgenden zwei Jahre nach dessen Beendigung ohne Zustimmung der Gesellschaft in keiner Weise für ein Konkurrenzunternehmen der Gesellschaft oder ein mit diesem verbundenen Unternehmen tätig zu werden oder sich mittelbar oder unmittelbar an einem solchen zu beteiligen sowie Geschäfte für eigene oder fremde Rechnung auf dem Arbeitsgebiet der Gesellschaft zu machen.</p></li>

    <li><p>Das Wettbewerbsverbot gilt nicht, wenn dieser Vertrag von ${insertTemplateVariable(
      nameGeschäftsführer
    )} aus wichtigem Grund zulässigerweise fristlos gekündigt wird. Es gilt ferner nicht für Beteiligungen an Unternehmen in Gestalt von Wertpapieren, die an Börsen gehandelt und die zum Zwecke der Kapitalanlage erworben werden.</p></li>

    <li><p>Nach Beendigung des Vertrags zahlt die Gesellschaft, wenn sie nicht in entsprechender Anwendung des Grundsatzes des § 75a HGB ausdrücklich auf die Geltendmachung des Wettbewerbsverbots schriftlich verzichtet, an den Geschäftsführer eine Entschädigung in Höhe von 50% des durchschnittlichen festen Jahresgehalts der letzten drei Jahre pro Jahr für die Dauer des Wettbewerbsverbots.</p></li>

    <li><p>Für jeden Fall des Verstoßes gegen das Wettbewerbsverbot zahlt ${insertTemplateVariable(
      nameGeschäftsführer
    )} der Gesellschaft eine Vertragsstrafe in Höhe eines 1/24 des Jahresgehalts; steht er nicht mehr in den Diensten der Gesellschaft, gilt der letzte von dieser gezahlte Jahresbezug. Die Vertragsstrafe tritt neben die übrigen Ansprüche der Gesellschaft aus der Wettbewerbsvereinbarung. Bei einem andauernden Wettbewerbsverstoß gilt die Tätigkeit während eines Monats als jeweils selbstständiger Verstoß im Sinne des Satzes 1.</p></li>
</ol>
  </li>

  <li><h3>Vergütung</h3>

    <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>${insertTemplateVariable(
          nameGeschäftsführer
        )} erhält ein festes Jahresgehalt von ${insertTemplateVariable(
      vergütungFix
    )} (Grundgehalt). Das Grundgehalt wird in monatlichen Teilbeträgen am jeweiligen Monatsletzten ausgezahlt.</p></li>

  <li><p>Besteht das Dienstverhältnis während eines gesamten Kalenderjahres, so erhält ${insertTemplateVariable(
    nameGeschäftsführer
  )} eine zusätzliche Weihnachtsgratifikation in Höhe von ${insertTemplateVariable(
      vergütungWeihnachten
    )}. Die Gratifikation wird mit dem letzten Teilbetrag des Grundgehalts des Jahres gezahlt.</p></li>

  <li><p>Ferner erhält der Geschäftsführer eine Tantieme in Höhe von ${insertTemplateVariable(
    vergütungTantieme
  )} % des Jahresüberschusses der Steuerbilanz nach Verrechnung mit Verlustvorträgen und vor Abzug der Körperschaft- und Gewerbesteuer. Die Bemessungsgrundlage ist nicht um Gewinnanteile stiller Gesellschafter, um die Tantieme selbst und um andere gewinnabhängige Aufwendungen der Gesellschaft zu kürzen. Die Gewinntantieme ist einen Monat nach Genehmigung des Jahresabschlusses durch die Gesellschafter fällig. Nachträgliche Änderungen der Steuerbilanz, insbesondere auf Grund abweichender steuerlicher Veranlagung, sind zu berücksichtigen. Überzahlte Beträge hat  ${insertTemplateVariable(
      nameGeschäftsführer
    )} zu erstatten. Die Tantieme entfällt, wenn dem Geschäftsführer aus wichtigem Grund gekündigt wird, für das Geschäftsjahr der Kündigung. Scheidet ${insertTemplateVariable(
      nameGeschäftsführer
    )} aus sonstigen Gründen während des Geschäftsjahres aus seinem Amt aus, hat er Anspruch auf eine zeitanteilige Tantieme.<p/></li>

  <li><p>Ein Anspruch auf Vergütung von Überstunden, Sonntags-, Feiertags- oder sonstiger Mehrarbeit besteht nicht.</p></li>

  <li><p>Im Krankheitsfall oder bei sonstiger unverschuldeter Verhinderung bleibt der Anspruch auf das Grundgehalt und eine etwaige Weihnachtsgratifikation für die Dauer von 6 Monaten bestehen. Dauert die Verhinderung länger als ununterbrochen 6 Monate an, so wird der Tantiemeanspruch entsprechend der 6 Monate überschreitenden Zeit zeitanteilig gekürzt.</p></li>

  <li><p>Eine Abtretung oder Verpfändung der Bezüge ist ohne Genehmigung der Gesellschaft unzulässig.</p></li>
</ol>
  </li>

  <li><h3>Spesen, Aufwendungsersatz</h3>

    <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>Trägt ${insertTemplateVariable(
          nameGeschäftsführer
        )} im Rahmen der ordnungsmäßigen Geschäftsführertätigkeit Kosten und Aufwendungen, so werden sie von der Gesellschaft erstattet, sofern ${insertTemplateVariable(
      nameGeschäftsführer
    )} die Geschäftsführungs- und Betriebsbedingtheit belegt oder sie offenkundig sind.</p></li>

    <li><p>Reisespesen werden bis zu den jeweils steuerlich zulässigen Höchstsätzen ersetzt.</p></li>
    </ol>

  </p></li>

  <li><h3>Urlaub</h3>

    <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>${insertTemplateVariable(
          nameGeschäftsführer
        )} hat Anspruch auf ${insertTemplateVariable(
      urlaubstageAnzahl
    )} Arbeitstage (Samstag ist kein Arbeitstag) bezahlten Urlaub im Geschäftsjahr. ${insertTemplateVariable(
      nameGeschäftsführer
    )} hat den Zeitpunkt seines Urlaubs so einzurichten, dass den Bedürfnissen der Geschäftsführung Rechnung getragen wird. Der Urlaub ist mit weiteren Geschäftsführern, sofern sie bestellt sind, abzustimmen.</p></li>

    <li><p>Kann ${insertTemplateVariable(
      nameGeschäftsführer
    )} den Jahresurlaub nicht nehmen, weil Interessen der Gesellschaft entgegenstehen, so hat ${insertTemplateVariable(
      nameGeschäftsführer
    )} Anspruch auf Abgeltung des Urlaubs unter Zugrundelegung der Höhe des Grundgehalts. Die Abgeltung wird mit dem ersten Gehalt des folgenden Geschäftsjahres gezahlt.</p></li>
</ol>
  </li>

  <li><h3> Dauer, Kündigung</h3>

    <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>Die Tätigkeit als Geschäftsführer beginnt am ${insertTemplateVariable(
          beginnGeschäftsführertätigkeit
        )}.</p></li>

    <li><p>Der Vertrag wird auf unbestimmte Zeit geschlossen und ist mit einer Frist von ${insertTemplateVariable(
      wochenKündigungsfrist
    )} Wochen zum Quartalsende kündbar.</p></li>

    <li><p>Der Vertrag ist jederzeit aus wichtigem Grund fristlos kündbar.</p></li>

    <li><p>Ein wichtiger Grund liegt für die Gesellschaft insbesondere vor, wenn</p>

    <p> a)	${insertTemplateVariable(
      nameGeschäftsführer
    )} gegen das Wettbewerbsverbot verstößt;</p>

    <p> b)	${insertTemplateVariable(
      nameGeschäftsführer
    )} ohne die erforderliche Einwilligung Geschäfte für die Gesellschaft tätigt und der Gesellschaft dadurch ein Schaden entsteht, es sei denn, dass dies wegen Eilbedürftigkeit geboten war;</p>

    <p> c)	der Geschäftsführer wissentlich einen unrichtigen Jahresabschluss aufstellt;</p>

    <p> d)	die Gesellschaft liquidiert wird.</p></li>

    <li><p>Die Kündigung ist schriftlich auszusprechen. ${insertTemplateVariable(
      nameGeschäftsführer
    )} hat ein Kündigungsschreiben an den Gesellschafter mit der höchsten Kapitalbeteiligung zu richten.</p></li>

    <li><p>Die Abberufung als Geschäftsführer ist jederzeit zulässig. Sie gilt gleichzeitig als Kündigung des Anstellungsverhältnisses zu dem nächst zulässigen Zeitpunkt.</p></li>

    <li><p>Im Falle der Abberufung sowie der Kündigung endet das Geschäftsführeramt mit dem Zugang der Mitteilung über die Abberufung bzw. über die Kündigung.</p></li>

    <li><p>Endet die Geschäftsführertätigkeit vorzeitig, so kann die Gesellschaft aus Gründen der Billigkeit eine besondere, angemessene Abfindung zahlen.</p></li>
</ol>
  </li>

  <li><h3>Schlussbestimmungen</h3>

    <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>Mündliche Nebenabreden bestehen nicht. Änderungen und Ergänzungen dieses Vertrages bedürfen zu ihrer Wirksamkeit der Schriftform. Dies gilt auch für die Aufhebung und Änderung dieser Schriftformklausel. Der Vorrang von individuellen mündlichen Vertragsabreden zwischen den Parteien nach Vertragsschluss (§ 305b BGB) bleibt hiervon unberührt.</p></li>

    <li><p>Die Ungültigkeit einzelner Bestimmungen berührt nicht die Rechtswirksamkeit des Vertrags im Ganzen. Anstelle der unwirksamen Vorschrift ist eine Regelung zu vereinbaren, die der wirtschaftlichen Zwecksetzung der Parteien am ehesten entspricht.</p></li>

    <li><p>Alle Streitigkeiten aus diesem Vertrag werden im ordentlichen Rechtsweg entschieden.</p></li>

    <li><p>Dieser Vertrag tritt am ${insertTemplateVariable(
      beginnGeschäftsführertätigkeit
    )} in Kraft.</p></li>
</ol>
  </li>

</ol>
nameFreierMitarbeiter  <br/>
  <br/>
  <p>
  ___________________________________<br/>
  Ort, Datum
  </p>
  <br/>
  <br/>
  <p>
  ___________________________________<br/>
  Unterschrift ${insertTemplateVariable(nameGeschäftsführer)}
    </p>
  `,
    begründung: undefined,
    templateVariables: [
      nameGeschäftsführer,
      geburtsdatumGeschäftsführer,
      adresseGeschäftsführer,
      vergütungFix,
      vergütungWeihnachten,
      vergütungTantieme,
      beginnGeschäftsführertätigkeit,
      urlaubstageAnzahl,
      wochenKündigungsfrist,
    ],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
