import {
  beschlussDatumFrühereEinziehung,
  geschäftsanteileAufstockungsbetragEinzel,
  geschäftsanteileNennbetragEinzel,
  geschäftsanteileNennbetragEinzelAufgestockt,
  geschäftsanteileNennbetragGesamtFrühereEinziehung,
  geschäftsanteileNummer,
  nameGesellschafterAufgestockt,
  nameGesellschafterEingezogen,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGesellschafterEinziehungAufstockung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
  vorlageKategorie: VorlageKategorie.ausscheidenEinesGesellschafters,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.handelsregisterGesellschafterliste],
  bezug: [
    "GesellschafterEinziehungAusWichtigemGrund",
    "GesellschafterEinziehungAusSonstigemGrund",
    "GesellschafterEinziehungZustimmung",
    "GesellschafterEinziehungRevalorisierung",
  ],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Aufstockung nach Einziehung",
  description: undefined,
  antrag: `
  <p>Mit Beschluss vom ${insertTemplateVariable(
    beschlussDatumFrühereEinziehung
  )} wurde die Einziehung der Geschäftsanteile von ${insertTemplateVariable(
    nameGesellschafterEingezogen
  )} mit einem Gesamt-Nennbetrag von ${insertTemplateVariable(
    geschäftsanteileNennbetragGesamtFrühereEinziehung
  )} beschlossen, sodass die Summe der Nennbeträge der Geschäftsanteile und das satzungsmäßige Stammkapital nunmehr in Höhe von ${insertTemplateVariable(
    geschäftsanteileNennbetragGesamtFrühereEinziehung
  )} auseinanderfallen. </p>

  <ol><li><p> Zur Wiederherstellung der Konvergenz der Nennbetragssumme aller Geschäftsanteile mit dem satzungsmäßigen Stammkapital werden die  Geschäftsanteile der verbleibenden Gesellschafter – ohne Gegenleistung oder Begründung einer Einlageverpflichtung – wie folgt nominell aufgestockt:

  <ul><li><p>Die Geschäftsanteile von ${insertTemplateVariable(
    nameGesellschafterAufgestockt
  )} mit den laufenden Nummern
  ${insertTemplateVariable(
    geschäftsanteileNummer
  )}  zu dem Nennbetrag von jeweils ${insertTemplateVariable(
    geschäftsanteileNennbetragEinzel
  )} werden um jeweils ${insertTemplateVariable(
    geschäftsanteileAufstockungsbetragEinzel
  )} auf einen Nennbetrag von jeweils ${insertTemplateVariable(
    geschäftsanteileNennbetragEinzelAufgestockt
  )} aufgestockt.</p></li></ul></p></li>

    <li><p>Die Geschäftsführung wird angewiesen, eine aktualisierte Gesellschafterliste gemäß § 40 Abs. 1 GmbHG zum Handelsregister einzureichen und eine Meldepflicht der Gesellschaft zum Transparenzregister zu prüfen und ggf. zu erfüllen.</p></li>
</ol>`,
  begründung: undefined,
  templateVariables: [
    beschlussDatumFrühereEinziehung,
    nameGesellschafterEingezogen,
    geschäftsanteileNennbetragGesamtFrühereEinziehung,
    nameGesellschafterAufgestockt,
    geschäftsanteileNummer,
    geschäftsanteileNennbetragEinzel,
    geschäftsanteileAufstockungsbetragEinzel,
    geschäftsanteileNennbetragEinzelAufgestockt,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: true,
  noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass aufgrund des geänderten Gesellschafterbestandes eine aktualisierte Gesellschafterliste zum Handelsregister durch die Geschäftsführer eingereicht werden muss (§ 40 Abs. 1 GmbHG). Die neue Gesellschafterliste muss von den Geschäftsführern in vertretungsberechtigter Zahl unterschrieben und über einen Notar zum Handelsregister eingereicht werden.</p>`,
});
