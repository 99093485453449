import { nameKomplementär } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageEntlastungRegelmassigKomplementar: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.KgDe],
  vorlageKategorie: VorlageKategorie.regelmäßigerBeschluss,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.zugang],
  bezug: [
    "EntlastungRegelmäßigAufsichtsrat",
    "EntlastungKomplementärRegelmäßig",
    "EntlastungRegelmäßigGeschäftsführenderPartner",
    "EntlastungRegelmäßigBeirat",
    "EntlastungRegelmäßigGeschäftsführenderGesellschafter",
    "EntlastungRegelmäßigGeschäftsführer",
    "EntlastungRegelmäßigKomplementärGmbH",
    "AbschlussprüferBestellung",
    "JahresabschlussAufstellung",
    "JahresabschlussFeststellung",
    "JahresplanungFeststellung",
    "VerwendungDesGewinns",
  ],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Entlastung des Komplementärs",
  description: undefined,
  antrag: `${insertTemplateVariable(
    nameKomplementär
  )} wird als Komplementär Entlastung erteilt.`,
  begründung: undefined,
  templateVariables: [nameKomplementär],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
