import { TemplateVariable } from "../../common-interfaces/VorlageInterface";
import { emptyUserInputValue } from "../../components/04-templates/BeschlussGenerator/beschlussGeneratorConstants";
import { TemplateUserInput } from "../../components/04-templates/BeschlussGenerator/BeschlussGeneratorInterfaces";
import { templateVariableKey } from "../../helpers/general/templates/templateVariableKey";

/**
 * Create the empty state for a non-touched template.
 */
export const initialUserInputState = (
  variables: TemplateVariable[]
): TemplateUserInput => {
  const varNamesWithInitialValues = variables.map((variable) => [
    templateVariableKey(variable),
    emptyUserInputValue,
  ]);

  return Object.fromEntries(varNamesWithInitialValues) as TemplateUserInput;
};
