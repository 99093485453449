import { createStyles, makeStyles } from "@material-ui/core";
import { defaultTransition } from "../../../../../../helpers/styling/defaultTransition";
import theme, {
  mobileMenuBreakpointDown,
} from "../../../../../../layouts/theme";
import {
  HeaderCenterComponentProps,
  HeaderColor,
} from "../../../PageLayoutInterfaces";

export const useRVOHeaderCenterMenuStyles = makeStyles(() => {
  return createStyles({
    wrapper: (props: HeaderCenterComponentProps) => {
      return {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down(mobileMenuBreakpointDown)]: {
          flexDirection: "column",
        },
        justifyContent: "space-between",
        alignItems: "center",
        flexGrow: 1,
        margin: 0,
        width: "100%",
        "&, & *": {
          lineHeight: 1,
        },
      };
    },
    mainWrapper: {
      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(1),
      },
    },
    centerLinks: {
      flex: 1,
      justifyContent: "center !important",
      paddingLeft: 0,
    },

    rightLinks: (props: HeaderCenterComponentProps) => {
      return {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        margin: 0,
        paddingLeft: 0,
        flexDirection: "row",
        width: "auto",
        [theme.breakpoints.down(mobileMenuBreakpointDown)]: {
          flexDirection: "column",
          width: "100%",
        },
        "& li a": {
          "&, & .MuiTypography-root": {
            color:
              props.color === HeaderColor.Paper
                ? theme.palette.primary.dark
                : theme.palette.primary.contrastText,
            ...defaultTransition(theme, "color", "complex"),
          },
        },
      };
    },
    wissenLink: {
      marginLeft: "auto",
      marginRight: theme.spacing(5),
      [theme.breakpoints.down(mobileMenuBreakpointDown)]: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  });
});
