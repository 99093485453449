import { extractVariableFromDomNode } from "../../../../components/04-templates/BeschlussGenerator/BeschlussPreview/extractVariableFromDomNode";
import TemplateVariableRendered from "../../../../components/04-templates/BeschlussGenerator/TemplateVariableRendered/TemplateVariableRendered";
import { HtmlReplacementComponentProps } from "../../../../interfaces/reactComponentInterfaces";
import { HtmlTag } from "../../../../interfaces/variousInterfaces";
import { LegacyTemplateLandingpage } from "../../../createVorlageLandingpage";
import { templateVariableHtmlTag } from "../../../general/templates/insertTemplateVariable";
import { TagToComponentMap } from "../replaceHtmlElementInterfaces";

/**
 * Map the template variables that we use in the vorlagen.
 *
 * We use a special (fake) tag to identify them,
 *  replacing them with their function component equivalent.
 */
export const mappedTemplateVariables = (
  vorlage: LegacyTemplateLandingpage
): TagToComponentMap => {
  return {
    [templateVariableHtmlTag as HtmlTag]: (
      props: HtmlReplacementComponentProps
    ) => {
      const { templateVariable: variable } = extractVariableFromDomNode(
        props.replacedElement,
        vorlage
      );

      return <TemplateVariableRendered variable={variable} />;
    },
  };
};
