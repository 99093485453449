import { nameBeiratsmitglied } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageEntlastungRegelmassigBeirat: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.regelmäßigerBeschluss,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.zugang],
  bezug: [
    "EntlastungRegelmäßigAufsichtsrat",
    "EntlastungKomplementärRegelmäßig",
    "EntlastungRegelmäßigKomplementär",
    "EntlastungRegelmäßigGeschäftsführenderPartner",
    "EntlastungRegelmäßigGeschäftsführenderGesellschafter",
    "EntlastungRegelmäßigGeschäftsführer",
    "EntlastungRegelmäßigKomplementärGmbH",
    "AbschlussprüferBestellung",
    "JahresabschlussAufstellung",
    "JahresabschlussFeststellung",
    "JahresplanungFeststellung",
    "VerwendungDesGewinns",
  ],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Entlastung des Beirats",
  description: undefined,
  antrag: `${insertTemplateVariable(
    nameBeiratsmitglied
  )} wird als Beiratsmitglied Entlastung erteilt.`,
  begründung: undefined,
  templateVariables: [nameBeiratsmitglied],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
