import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";
import {
  gbrBeginn,
  gbrGegenstandGeschäftsidee,
  gbrGesellschafterBeteiligungsquoten,
  gbrGesellschafterNamen,
  gbrKuendigungFristMonate,
  gbrKuendingungAusschlussDatum,
  gbrName,
  gbrSitz,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

export const vorlageGesellschaftsvertragGbrVorgruendungsgesellschaft: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GbRDe],
    vorlageKategorie: VorlageKategorie.startup,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.selfexecuting],
    bezug: [],
    tags: [
      "Vorgründungsgesellschaft",
      "Proof of Concept",
      "Machbarkeit",
      "Durchführbarkeit",

      "Wettbewerbsverbot",

      "Gesellschaft",
      "GbR",
      "Gesellschaft bürgerlichen Rechts",
      "BGB-Gesellschaft",
      "Personengesellschaft",
    ],
    zustimmungErforderlichDes: "",
    kurzBezeichnung:
      "Gesellschaftsvertrag für Vorgründungsgesellschaft eines Startups",
    metaDescription:
      "Vertragsmuster für GbR im Vorfeld zur Startup-Gründung (Proof of Concept). Juristisch geprüfte Muster/Vorlagen kostenlos nutzen.",
    description: `
  <p>
    Diese Beschlussvorlage enthält einen Standard-Gesellschaftsvertrag für Gründer, die eine Geschäftsidee zunächst auf ihre Durchführbarkeit überprüfen wollen ("Proof of Concept"), bevor für das Startup eine GmbH oder UG errichtet werden soll. Daher nennt man eine solche Gesellschaft auch Vorgründungsgesellschaft. Die Vorlage enthält Regelungen für die wichtigsten Punkte, die in einer solchen Vorgrüundungsgesellschaft zu regeln sind: Die Beteiligungsanteile der Gründer sowie das Wettbewerbsverbot.
  </p>

  <p>
    Die Vorteile der Gründung einer Vorgründungsgesellschaft vor dem eigentlichen Startup liegen auf der Hand: Die GbR-Gründung kann sofort ohne zwingende Verzögerungen erfolgen, denn es ist keine notarielle Beurkundung notwendig. Es fallen auch keine  Gründungskosten an, denn im Gegensatz zu einer GmbH oder UG fallen keine Notar- oder Handelsregisterkosten an und es ist auch kein IHK-Beitrag notwendig. Sofern zunächst keine wesentlichen Ausgaben oder Umsätze getätigt werden sollen, kann auch auf eine steuerliche Buchhaltung verzichtet werden, sodass auch keine Steuerberaterkosten anfallen.
  </p>
  <p>
    Mit dem Abschluss des in der Beschlussvorlage enthaltenen Gesellschaftsvertrags entsteht zwischen den Gründern eine GbR ("Gesellschaft bürgerlichen Rechts"), die einfachste Rechtsform für Gesellschaften in Deutschland. Bei einer GbR haften sämtliche Gründer für die Verbindlichkeiten der GbR persönlich. Da im Rahmen eines Proof of Concept in der Regel noch keine wesentlichen Verträge zwischen der Gesellschaft mit Dritten abgeschlossen werden müssen und auch keine sonstigen erheblichen Kosten entstehen, ist das Haftungsrisiko für die Gründung in der Regel gleichwohl unproblematisch. Sofern jedoch schon während der Proof of Concept-Phase erhebliche Risiken eingegangen werden sollen, sollten die Gründer prüfen, ob nicht von vorneherein die Errichtung einer haftungsbeschränkten Rechtsform (insbesondere GmbH oder UG) sinnvoll ist.
  </p>

  `,
    antrag: `


  <h2>Gesellschaftsvertrag einer Vorgründungsgesellschaft
 </h2>

  <ol data-list-style="decimal" data-list-style-prefix="§">
    <li>
      <h3>Rechtsform, Firma, Sitz</h3>
       <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li>
            <p>Die Gesellschaft ist eine Gesellschaft des bürgerlichen Rechts. Sie führt im Rechtsverkehr den Namen</p>
            <p style="text-align:center"><strong>${insertTemplateVariable(
              gbrName
            )}</strong>.</p>
        </li>

        <li><p>
            Sitz der Gesellschaft ist ${insertTemplateVariable(gbrSitz)}.
         </p></li>
      </ol>
    </li>

    <li>
        <h3>
            Gegenstand der Gesellschaft, Proof of Concept
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">


             <li>
                <p>
                    Gegenstand der Gesellschaft ist die Überprüfung der Umsetzbarkeit der folgenden Geschäftsidee:  ${insertTemplateVariable(
                      gbrGegenstandGeschäftsidee
                    )}.
                </p>
            </li>

            <li>
                <p>
                    Im Falle eines positiven Proof of Concept soll die Gründung eines Startups in einer geeigneten Rechtsform angestrebt werden. Ob und wann dies der Fall ist, obliegt der einvernehmlichen Beschlussfassung der Gesellschafter.
                </p>
            </li>

            <li>
                <p>
                    Die Gesellschaft ist berechtigt, alle Geschäfte zu tätigen, die dem Gesellschaftszweck unmittelbar oder mittelbar dienen, insbesondere auch sich an anderen Unternehmen zu beteiligen sowie Zweigniederlassungen im In- und Ausland zu errichten.
                </p>
             </li>
        </ol>
    </li>

    <li>
        <h3>
            Beginn der Gesellschaft, Geschäftsjahr
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Beginn der Gesellschaft ist ${insertTemplateVariable(
                      gbrBeginn
                    )}. Gegenüber Dritten beginnt die Gesellschaft mit Aufnahme der Geschäftstätigkeit.
                </p>
            </li>

            <li>
                <p>
                    Geschäftsjahr ist das Kalenderjahr. Das erste Geschäftsjahr ist ein Rumpfgeschäftsjahr. Es endet am 31.12.
                </p>
            </li>
        </ol>
    </li>

    <li>
        <h3>
            Dauer der Gesellschaft, Kündigung
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Die Gesellschaft wird auf unbestimmte Dauer abgeschlossen.
                </p>
            </li>

            <li>
                <p>
                    Eine ordentliche Kündigung der Gesellschaft ist bis zum ${insertTemplateVariable(
                      gbrKuendingungAusschlussDatum
                    )} ausgeschlossen. Danach ist eine Kündigung zum Schluss jeden Geschäftsjahres mit einer Frist von ${insertTemplateVariable(
      gbrKuendigungFristMonate
    )} Monaten zulässig. Das Recht zur Kündigung aus wichtigem Grund bleibt unberührt.
                </p>
            </li>
            <li>
                <p>
                    Die Kündigung bedarf zu ihrer Wirksamkeit einer Erklärung gegenüber der Gesellschaft in Textform. Die Gesellschaft teilt nach Zugang der Kündigung diese den übrigen Gesellschaftern unverzüglich mit.
                </p>
            </li>
            <li>
                <p>
                   Kündigt ein Gesellschafter, so scheidet er aus der Gesellschaft aus. Diese wird mit den verbleibenden Gesellschaftern fortgesetzt. Verbleibt nur noch ein Gesellschafter, ist er berechtigt, das Unternehmen unter Übernahme sämtlicher Aktiva und Passiva und ohne vorherige Liquidation fortzuführen.
                </p>
            </li>
            <li>
                <p>
                    Kündigt ein Gesellschafter und ist die Kündigungsfrist abgelaufen, wenn die übrigen Gesellschafter von der Kündigung Kenntnis erlangen, sind sie berechtigt, sich innerhalb von zwei Monaten nach Kenntnis von der Kündigung dieser anzuschließen.
                </p>
            </li>
        </ol>
    </li>

    <li>
        <h3>Gesellschafter und Beteiligung</h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Gesellschafter sind ${insertTemplateVariable(
                      gbrGesellschafterNamen
                    )}.
                </p>
            </li>

            <li>
                <p>
                    Die Aufnahme weiterer Gesellschafter sowie die Verfügung über Gesellschaftsanteile bedarf der Zustimmung aller Gesellschafter.
                </p>
            </li>
            <li>
                <p>
                    Die Gesellschafter sind am Gesellschaftsvermögen wie folgt beteiligt: ${insertTemplateVariable(
                      gbrGesellschafterBeteiligungsquoten
                    )}.
                </p>
            </li>
            <li>
                <p>
                   Die Beteiligung am Gesellschaftsvermögen ist auch maßgeblich für die Beteiligung am Ergebnis sowie an einem etwaigen Auseinandersetzungsguthaben der Gesellschaft sowie für sein Stimmrecht.
                </p>
            </li>
        </ol>

    </li>

    <li>
        <h3>Geschäftsführung und Vertretung</h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Zur Führung der Geschäfte sind die Gesellschafter nur gemeinschaftlich berechtigt und verpflichtet.
                </p>
            </li>

            <li>
                <p>
                    Jeder geschäftsführende Gesellschafter hat in den Angelegenheiten der Gesellschaft die Sorgfalt eines ordentlichen Geschäftsmannes zu beachten.
                </p>
            </li>
            <li>
                <p>
                    Die Gesellschafter sind nur gemeinschaftlich zur Vertretung der Gesellschaft berechtigt und nicht von den Beschränkungen des § 181 BGB befreit.
                </p>
            </li>
        </ol>

    </li>

    <li>
        <h3>
            Beiträge der Gesellschafter
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Über die Erbringung von Einlagen der Gesellschafter entscheiden die Gesellschafter einvernehmlich durch Gesellschafterbeschluss.
                </p>
            </li>

            <li>
                <p>
                    Die geschäftsführenden Gesellschafter haben ihre Arbeitskraft im angemessenen Maß für die Gesellschaft einzusetzen. Über die Festlegung bestimmter Arbeitszeiten und/oder Arbeitsbereiche entscheiden die Gesellschafter einvernehmlich durch Gesellschafterbeschluss.
                </p>
            </li>
        </ol>
    </li>

    <li>
        <h3>
            Verteilung von Einnahmen- und Ausgaben/Gewinn- und Verlust
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                   Alle Ausgaben und Einnahmen werden entsprechend der Beteiligung den Gesellschaftern verteilt.
                </p>
            </li>

            <li>
                <p>
                    Über die Einführung einer (kaufmännischen) Buchführung entscheiden die Gesellschafter einvernehmlich durch Gesellschafterbeschluss. In diesem Falle steht ein etwaiger Jahresgewinn den Gesellschaftern entsprechend ihrer Beteiligung den Gesellschaftern zu. Einen eventuellen Verlust tragen die Gesellschafter im gleichen Verhältnis.
                </p>
            </li>
        </ol>
    </li>

     <li>
        <h3>
          Gesellschafterbeschlüsse
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                   Alle den Gesellschaftern durch Gesetz oder durch diesen Gesellschaftsvertrag zugewiesenen Entscheidungen werden durch Beschlüsse der Gesellschafter getroffen. Dies gilt auch für Geschäftsführungsbeschlüsse im Falle der Geschäftsführung durch alle Gesellschafter. Beschlüsse werden in einer Gesellschafterversammlung oder im Umlaufverfahren gefasst.
                </p>
            </li>

            <li>
                <p>
                    Für die Einberufung von Gesellschaftsversammlungen gilt folgendes:
                </p>
                <ol data-list-style="lower-latin">
                    <li>
                        <p>
                            Gesellschafterversammlungen finden als Präsenzversammlung am Sitz der Gesellschaft statt. Versammlungen können auch fernmündlich oder mittels Videokommunikation abgehalten werden, wenn kein Gesellschafter widerspricht.
                        </p>
                    </li>

                    <li>
                        <p>
                            Die Einberufung der Gesellschafterversammlung erfolgt durch einen geschäftsführenden Gesellschafter schriftlich an alle Gesellschafter mit einer Frist von mindestens einer Woche. Mit der Einladung ist die Tagesordnung zu übermitteln.
                        </p>
                    </li>
                </ol>

            </li>
            <li>
                <p>
                    Die Gesellschafterversammlung ist beschlussfähig, wenn 50 % der Gesellschafter anwesend oder vertreten sind. Fehlt es an dieser Voraussetzung, so hat ein geschäftsführender Gesellschafter innerhalb von einer Woche eine neue Gesellschafterversammlung einzuberufen, die dann in jedem Fall beschlussfähig ist. Hierauf ist in der Ladung hinzuweisen.
                </p>
            </li>
            <li>
                <p>
                   Gesellschafter können sich in der Gesellschafterversammlung nur durch andere Gesellschafter vertreten lassen. Eine Vertretung durch andere Personen ist nicht gestattet. Ausnahmen kann die Gesellschafterversammlung beschließen. Jede Vollmacht bedarf mindestens der Textform.
                </p>
            </li>
            <li>
                <p>
                    Außerhalb von Versammlungen können Beschlüsse, soweit nicht zwingend eine andere Form vorgeschrieben ist, auch schriftlich, in Textform oder über die Resolvio-App vorgenommene Abstimmungen gefasst werden, sofern alle Gesellschafter dieser Art der Beschlussfassung zustimmen oder sich an der Abstimmung beteiligen. Ein Gesellschafter beteiligt sich auch an der Abstimmung, wenn er sich der Stimme enthält. Außerhalb von Versammlungen gefasste Gesellschafterbeschlüsse sind in einem Protokoll festzustellen, sofern nicht die Gesellschafter zur Unterzeichnung der Gesellschafterbeschlüsse vorgesehen sind.
                </p>
            </li>
            <li>
                <p>
                    Das Stimmgewicht eines jeden Gesellschafters entspricht der Beteiligung am Gesellschaftsvermögen.
                </p>
            </li>
            <li>
                <p>
                    Die Gesellschafter beschließen, sofern das Gesetz oder der Gesellschaftsvertrag nicht ein anderes vorsehen, mit der einfachen Mehrheit der abgegebenen Stimmen.
                </p>
            </li>
            <li>
                <p>
                    Fehlerhafte Beschlüsse, deren Zustandekommen oder Inhalt nicht gegen zwingende gesetzliche Vorschriften verstößt, können nur innerhalb von zwei Monaten seit Beschlussfassung - wenn diese im Umlaufverfahren erfolgt ist, seit Zugang der schriftlichen Mitteilung - durch Klage gegen die Gesellschaft angefochten werden.
                </p>
            </li>

        </ol>
    </li>

    <li>
        <h3>Wettbewerbsverbot</h3>
          <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Den Gesellschaftern ist es untersagt, mit der Gesellschaft in Wettbewerb zu treten, insbesondere im Bereich des Unternehmensgegenstandes nach § 2 dieses Vertrages und der dort beschriebenen Geschäftsidee eigene auf Gewinnerzielung gerichtete Tätigkeiten vorzunehmen oder sich an Unternehmen zu beteiligen, die mit der Gesellschaft im Wettbewerb stehen. Das Verbot besteht über das Ausscheiden aus der Gesellschaft hinaus und endet zwei Jahre nach dem Ausscheiden.
                 </p>
            </li>
            <li>
                <p>
                    Die Gesellschafter können durch Beschluss einem Gesellschafter Befreiung vom Wettbewerbsverbot erteilen und die näheren Einzelheiten hierzu regeln.
                 </p>
            </li>
        </ol>
    </li>
     <li>
        <h3>
          Auflösung oder Fortsetzung der Gesellschaft, Ausscheiden eines Gesellschafters
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                   Die Gesellschaft wird durch Beschluss der Gesellschafter, oder durch die Eröffnung des Insolvenzverfahrens über das Vermögen der Gesellschaft aufgelöst.
                </p>
            </li>

            <li>
                <p>
                    Ein Gesellschafter scheidet aus der Gesellschaft aus im Falle
                </p>
                <ol data-list-style="lower-latin">
                    <li>
                        <p>
                            der Zwangsvollstreckung in den Gesellschaftsanteil, den Gewinnanteil oder das Auseinandersetzungsguthaben eines Gesellschafters mit Zustellung des Pfändungs- und Überweisungsbeschlusses an die Gesellschaft, wenn dieser nicht innerhalb von drei Monaten wider aufgehoben wird, oder
                        </p>
                    </li>


                    <li>
                        <p>
                           der rechtskräftigen Eröffnung des Insolvenzverfahrens über das Vermögen eines Gesellschafters oder der Ablehnung eines entsprechenden Antrags mangels Masse, oder
                        </p>
                    </li>

                     <li>
                        <p>
                            seiner Kündigung gemäß der Regelung in diesem Gesellschaftsvertrag,
                        </p>
                    </li>
                </ol>

            </li>
            <li>
                <p>
                   Ein Gesellschafter kann außerdem durch einstimmigen Beschluss aller übrigen Gesellschafter aus der Gesellschaft ausgeschlossen werden, wenn er
                </p>
                <ol data-list-style="lower-latin">
                    <li>
                        <p>
                           die Vermögensauskunft nach § 802 f ZPO abgibt oder seine Verhaftung zur Erzwingung ihrer Abgabe angeordnet wurde, oder wenn er
                        </p>
                    </li>

                    <li>
                        <p>
                          in seiner Person einen sonstigen wichtigen Grund im Sinne des § 727 BGB erfüllt.
                        </p>
                    </li>

                </ol>

            </li>
            <li>
                <p>
                   Scheidet ein Gesellschafter nach Absatz 2 oder 3 aus der Gesellschaft aus, wird diese mit den übrigen Gesellschaftern fortgesetzt. Verbleibt nur noch ein Gesellschafter, ist er berechtigt, das Handelsgeschäft unter Übernahme aller Aktiva und Passiva fortzuführen. Übt er sein Übernahmerecht nicht aus, ist die Gesellschaft aufgelöst und tritt in Liquidation.
                </p>
            </li>
            <li>
                <p>
                   Im Fall des Ablebens eines Gesellschafters scheiden dieser bzw dessen Gesamtrechtsnachfolger aus der Gesellschaft aus; die Gesellschaft wird von den verbleibenden Gesellschaftern fortgesetzt, soweit die übrigen Gesellschafter nicht innerhalb von drei Monaten nach Kenntniserlangung vom Anfall der Erbschaft einstimmig die Fortsetzung mit einem oder mehreren Erben/Vermächtnisnehmern beschließen. Absatz 4 Sätze 2 und 3 gelten entsprechend.
                </p>
            </li>
        </ol>
    </li>

     <li>
        <h3>
            Übertragung und Belastung von Gesellschaftsanteilen, Abtretung von Ansprüchen aus dem Gesellschaftsverhältnis
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                  Verfügungen über die Gesellschaftsbeteiligung insbesondere die Übertragung, Sicherungsabtretung oder Verpfändung, sind nur mit Zustimmung aller Gesellschafter möglich.
                </p>
            </li>
            <li>
                <p>
                    Die Abtretung von Ansprüchen aus dem Gesellschaftsverhältnis ist ausgeschlossen.
                </p>
            </li>
        </ol>
    </li>

     <li>
        <h3>
            Abfindungsguthaben
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                   Scheidet ein Gesellschafter durch sein Ableben aus der Gesellschaft aus, so ist ein Abfindungsanspruch seiner Erben bzw. Vermächtnisnehmer ausgeschlossen.
                </p>
            </li>
            <li>
                <p>
                    Scheidet ein Gesellschafter aus anderen Gründen aus der Gesellschaft aus, erhält er als Abfindungsguthaben den Verkehrswert seiner Beteiligung abzüglich eines Abschlags von 20 Prozent.
                </p>
            </li>
            <li>
                <p>
                    Das Abfindungsguthaben ist in fünf gleichen Jahresraten zu zahlen. Die erste Rate ist innerhalb von zwölf Monaten nach dem Ausscheiden fällig, die übrigen Raten jeweils zwölf Monate später. Sicherheitsleistung kann nicht verlangt werden. Das Abfindungsguthaben ist mit 2 % über dem Basiszins p. a. zu verzinsen. Die Zinsen werden jeweils am Jahresende ausgezahlt. Gerät die Gesellschaft mit einer Rate ganz oder teilweise länger als sechs Monate in Verzug, ist der Restbetrag sofort fällig.
                </p>
            </li>
            <li>
                <p>
                    Befindet sich die Gesellschaft in erheblichen wirtschaftlichen Schwierigkeiten und ist sie deshalb nicht in der Lage, ihrer Verpflichtung nachzukommen, vermindert sich die Höhe der Abfindung unter entsprechender Erhöhung der Anzahl der Raten auf den für die Gesellschaft zumutbaren Betrag. Dieser ist im Streitfall von dem für die Gesellschaft tätigen bzw einem von der zuständigen Industrie- und Handelskammer zu bestellenden Wirtschaftsprüfer als Schiedsgutachter festzusetzen.
                </p>
            </li>
        </ol>
    </li>

     <li>
        <h3>
            Liquidation
        </h3>
            <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Liquidatoren sind die im Zeitpunkt der Auflösung geschäftsführenden Gesellschafter.
                </p>
            </li>

            <li>
                <p>
                    Die Gesellschafter nehmen am Liquidationserlös im Verhältnis ihrer Beteiligung am Gesellschaftsvermögen teil.
                </p>
            </li>
        </ol>
    </li>



    <li>
        <h3>
            Schlussbestimmung
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                  Änderungen dieses Vertrages bedürfen zu ihrer Wirksamkeit der Textform, soweit nicht gesetzlich eine Beurkundung oder andere Form vorgeschrieben ist. Dies gilt auch für die Änderung dieser Textformklausel.
                </p>
            </li>
            <li>
                <p>
                    Sollten einzelne Bestimmungen dieses Vertrages unwirksam sein oder werden, so wird die Rechtswirksamkeit der übrigen Bestimmungen dadurch nicht berührt. Die betreffende Bestimmung ist durch eine wirksame zu ersetzen, die dem angestrebten wirtschaftlichen Zweck möglichst nahe kommt.
                </p>
            </li>
            <li>
                <p>
                    Soweit in diesem Vertrag die schriftliche Form vorgeschrieben ist, genügt für deren Einhaltung die Textform, soweit dies gesetzlich zulässig ist.
                </p>
            </li>
        </ol>
    </li>
  </ol>`,
    begründung: undefined,
    templateVariables: [
      gbrName,
      gbrSitz,
      gbrGegenstandGeschäftsidee,
      gbrBeginn,
      gbrKuendingungAusschlussDatum,
      gbrKuendigungFristMonate,
      gbrGesellschafterNamen,
      gbrGesellschafterBeteiligungsquoten,
    ],
  });
