import {
  switchBegründung,
  switchEntlastungEinsatz,
  variantsVorstandsmitgliedDienstverhaeltnisBeendigung,
  variantsVorstandsmitgliedFreistellung,
  vorstandsmitgliedAbberufungTag,
  vorstandsmitgliedDienstverhaeltnisBeendigungTag,
  vorstandsmitgliedGeburtsdatum,
  vorstandsmitgliedName,
  vorstandsmitgliedWohnort,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageAgDeVorstandAbberufungVertragsbeendigung: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [
      CompanyTypeName.AgDe,
      CompanyTypeName.SeDe,
      CompanyTypeName.KgaADe,
    ],
    vorlageKategorie: VorlageKategorie.geschäftsführerNeu,
    vorlage_rolleTyp: [RolleInGesellschaft.Aufsichtsrat],
    vollzug: [Vollzug.zugang, Vollzug.handelsregisterAnmeldung],
    bezug: [],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung:
      "Abberufung eines Vorstandsmitglieds und Beendigung des Anstellungsvertrags",
    description: `
  <p>
    Die Beschlussvorlage ist ein Muster für den Aufsichtsratsbeschluss einer deutschen AG, in der der Aufsichtsrat über die Abberufung eines Vorstandsmitglieds von seiner Organstellung aus wichtigem Grund sowie die Beendigung des Anstellungsvertrags/Dienstverhältnisses mit dem Vorstandsmitglied beschließt.
</p>
<h3>
    1. Abberufung des Vorstandsmitglieds aus wichtigen Grund
</h3>
<p>
    Die Abberufung des Vorstandsmitglieds aus seiner organschaftlichen Stellung durch die Gesellschaft ist – anders als beim Geschäftsführer einer GmbH – nur bei Vorliegen eines wichtigen Grundes möglich. Folgende Abberufungsgründe sind gesetzlich vorgesehen (§ 84 Abs. 3 S. 1 AktG):
</p>
<ol>
    <li><p>
        grobe Pflichtverletzung,
    </p></li>
    <li><p>
         Unfähigkeit zur ordnungsgemäßen Geschäftsführung,
    </p></li>
    <li><p>
         Vertrauensentzug durch die Hauptversammlung.
    </p></li>
</ol>

<p>
    Der Vertrauensentzug rechtfertigt die Abberufung allerdings dann nicht, wenn das Vertrauen aus offenbar unsachlichen Gründen entzogen worden ist (§ 84 Abs. 3 S. 2 Alt. 3 AktG). Zum Beispiel liegt ein Vertrauensentzug aus unsachlichen Gründen vor, wenn die Gründe haltlos und willkürlich sind, oder wenn schlicht Personal im Vorstand abgebaut werden soll.
</p>
<h3>
    2. Beendigung des Anstellungsvertrags/Dienstverhältnisses
</h3>
<p>
  Folgende Möglichkeiten der Vertragsbeendigung sind in dem Muster vorgesehen:
</p>
<ul>
  <li><p>
    außerordentliche fristlose Kündigung aus wichtigem Grund (hilfsweise ordentlich),
  </p></li>
    <li><p>
    ordentliche Kündigung,
  </p></li>
    <li><p>
    einvernehmliche vorzeitige Beendigung,
  </p></li>
      <li><p>
    Ablauf zum vertragsgemäßen Beendigungstermin und Freistellung.
  </p></li>
</ul>
<p>
    Ferner können Sie noch unabhängig von der ausgewählten Beendigungsoption auswählen, ob das Vorstandsmitglied bis zum Ablauf der vertraglichen Restlaufzeit freigestellt werden soll und ob der Aufsichtsrat die Entlastung des Vorstands der Hauptversammlung empfehlen soll.
</p>
<p>
    Eine außerordentliche fristlose Kündigung ist nur zulässig, wenn ein Kündigungsgrund hierfür vorliegt (§ 626 BGB). Dabei liegt ein Kündigungsgrund <strong>nicht</strong> automatisch dann vor, wenn ein wichtiger Grund für die Abberufung vorliegt (siehe oben). Vielmehr gelten für den Kündigungsgrund höhere Anforderungen. Außerdem muss der Kündigungsbeschluss des Aufsichtsrats innerhalb von zwei Wochen nach Kenntnis des Kündigungsgrundes erfolgen (§ 626 Abs. 2 BGB).
</p>
<p>
    In der Praxis liegen die Voraussetzungen für eine außerordentliche Kündigung oft nicht vor, sodass ein Vorstandsmitglied in der Regel infolge einer ordentlichen Kündigung oder einer einvernehmlichen Vertragsaufhebung das Unternehmen vorzeitig verlässt.
</p>
<p>
     <strong>Achtung:</strong> Eine ordentliche Kündigung dann ist nur möglich, wenn dies vertraglich mit dem Vorstandsmitglied vereinbart worden ist. Warum? Da Vorstandsanstellungsverträge in der Regel auf die Amtszeit des Vorstandsmitglieds befristet sind, ist eine ordentliche Kündigung nach § 620 Abs. 1 BGB ausgeschlossen, wenn nichts Abweichendes vereinbart worden ist.
</p>
`,
    metaDescription:
      "Abberufung eines AG-Vorstandsmitglieds und Beendigung des Anstellungsvertrags durch Kündigung oder Aufhebungsvertrag. Juristisch geprüfte Standard-Muster/Vorlagen kostenlos ausfüllen und downloaden.",
    tags: [
      "Vorstand",
      "Vorstandsmitglied",
      "Kündigung",
      "außerordentliche Kündigung",
      "ordentliche Kündigung",
      "Aufhebung",
      "Aufhebungsvertrag",
      "Beendigung",
      "Dienstvertrag",
      "Anstellungsvertrag",
      "Entlastung",
    ],
    antrag: `
  <ol>
    <li>
        <p>
            Als Vorstandsmitglied der Gesellschaft wird
        </p>
        <ul data-list-style="none">
            <li>
                <p>
                  ${insertTemplateVariable(vorstandsmitgliedName)},<br/>
                  geboren am ${insertTemplateVariable(
                    vorstandsmitgliedGeburtsdatum
                  )},<br/>
                  wohnhaft in ${insertTemplateVariable(
                    vorstandsmitgliedWohnort
                  )}.
                </p>
            </li>
        </ul>
        <p>
            mit Wirkung zum ${insertTemplateVariable(
              vorstandsmitgliedAbberufungTag
            )} abberufen.
        </p>
    </li>
    <li>
        <p>
            Mit Wirkung zum ${insertTemplateVariable(
              vorstandsmitgliedDienstverhaeltnisBeendigungTag
            )} ${insertTemplateVariable(
      variantsVorstandsmitgliedDienstverhaeltnisBeendigung
    )}
        </p>
    </li>

    ${insertTemplateVariable(variantsVorstandsmitgliedFreistellung)}

      ${insertTemplateVariable(switchEntlastungEinsatz)}

    <li>
        <p>
             Der Vorsitzende des Aufsichtsrats wird beauftragt und ermächtigt,
         </p>
         <ul>
             <li><p>
                die vorstehenden Beschlüsse unverzüglich an  ${insertTemplateVariable(
                  vorstandsmitgliedName
                )} zu übermitteln und
            </p></li>
             <li><p>
                alle nach eigenem Ermessen erforderlichen und/oder zweckdienlichen Erklärungen abzugeben und entgegenzunehmen sowie alle erforderlichen und/oder zweckdienlichen Handlungen und Maßnahmen vorzunehmen, die im Zusammenhang mit der Durchführung der vorstehenden Beschlüsse stehen.
            </p></li>
        </ul>
    </li>



  </ol>
  <p>
 ${insertTemplateVariable(switchBegründung)}
  </p>

  `,
    begründung: undefined,
    templateVariables: [
      vorstandsmitgliedName,
      vorstandsmitgliedGeburtsdatum,
      vorstandsmitgliedWohnort,
      vorstandsmitgliedAbberufungTag,
      vorstandsmitgliedDienstverhaeltnisBeendigungTag,
      variantsVorstandsmitgliedDienstverhaeltnisBeendigung,
      variantsVorstandsmitgliedFreistellung,
      switchEntlastungEinsatz,
      switchBegründung,
    ],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: true,
    noteHandelsregisteranmeldung: `
  <p>
    Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss (§ 81 Abs. 1 AktG). Die Handelsregisteranmeldung muss von den Vorstandsmitgliedern in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.
</p>`,
  });
