import { Box } from "@material-ui/core";
import { TagToComponentMap } from "@nvon/react-toolbox";

const buttonPlaceholders = ["button"];

enum ButtonDataNames {
  RegisteranmeldungGenerieren = "RegisteranmeldungGenerieren",
  NotarterminVereinbaren = "NotarterminVereinbaren",
}

export const mappedImplementationButtons = (): TagToComponentMap => {
  return Object.fromEntries(
    buttonPlaceholders.map((tag) => [
      tag,
      (props) => (
        <>
          {props.replacedElement.attribs.data ===
            ButtonDataNames.RegisteranmeldungGenerieren && <Box />}

          {props.replacedElement.attribs.data ===
            ButtonDataNames.NotarterminVereinbaren && <Box />}
        </>
      ),
    ])
  );
};
