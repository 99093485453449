import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { VTSAnimatedUnderlineStyleProps } from "./VTSAnimatedUnderlineInterfaces";

export const useVTSAnimatedUnderlineStyles = makeStyles((theme: Theme) => {
  return createStyles({
    wrapper: {
      /*
       * Forming a stacking context here is necessary to not have the underline hidden under other parent containers with an
       * explicit background color.
       * See for information on forming stacking contexts:
       * https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context#the_stacking_context
       */
      position: "relative",
      zIndex: 0,
      display: "inline-block",
    },
    underline: (props: VTSAnimatedUnderlineStyleProps) => {
      const animationDuration = ".6s";
      const offset = "0.2em";

      return {
        // underline styling
        position: "absolute",
        height: "2em",
        width: `calc(100% + ${offset})`,
        left: `-${offset}`,
        stroke: theme.palette.secondary.dark,
        strokeWidth: ".5em",
        zIndex: -1,
        opacity: props.elementIsInView ? 1 : 0,
        // showing underline when it's inview
        transform: props.elementIsInView ? "scaleX(1)" : "scaleX(0.4)",
        transition: `transform ${animationDuration} ease-in-out, opacity ${animationDuration} ease-in-out`,
        transformOrigin: "left",
        transitionDelay: ".5s",
      };
    },
  });
});
