import { SpringConfig, useSpring } from "@react-spring/web";
import { useMemo } from "react";

const useSmoothScrollTo = (
  config?: SpringConfig
): {
  smoothScrollTo: (
    value: number | Element | null,
    offset?: number | undefined
  ) => void;
} => {
  const [, setY] = useSpring(() => ({ y: 0 }));

  const smoothScrollTo = useMemo(
    () =>
      (value: Element | number | null, offset = 0) => {
        let y = 0;

        if (typeof value === "number") {
          y = value;
        } else if (value !== null) {
          y = window.scrollY + value.getBoundingClientRect().top;
        } else {
          // eslint-disable-next-line no-console
          console.error("useSmoothScrollTo: Element not found");
          return;
        }

        y += offset;

        setY.start({
          y,
          reset: true,
          from: { y: window.scrollY },
          config,
          onChange: (_, ctrl) => {
            window.scroll(0, ctrl.get().y);
          },
        });
      },
    [config, setY]
  );

  return { smoothScrollTo };
};

export default useSmoothScrollTo;
