import {
  geschäftsanteileBetragGrowthSharesGesamt,
  geschäftsanteileBetragGrowthSharesJeAnteil,
  geschäftsanteileNummerGrowthShares,
  listOfBoardMembers,
  unternehmenFirma,
  unternehmenRegisterAmtsgericht,
  unternehmenRegisternummer,
  unternehmenSitz,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGesellschaftervereinbarungGrowthShares: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.startup,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.selfexecuting],
    bezug: [],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung: "Growth Shares-Gesellschaftervereinbarung",
    description: `
    <p>
        Mit diesem Beschlussmuster können Gesellschafter einer GmbH oder UG vereinbaren, dass bestimmte Geschäftsanteile zu sogenannten Growth Shares werden. Statt von "Growth Shares" spricht man oft auch von "Hurdle Shares" oder "negative Liquidationspräferenz". Dies ist ein beliebter Weg, um eine "Dry-Income"-Versteuerung zu vermeiden, wenn ein Co-Founder "nachträglich" in ein bereits bestehendes Unternehmen einsteigt, das schon eine signifikante Unternehmensbewertung erreicht hat. (Siehe dazu auch die FAQ unten.)
    </p>
    <h3>Was ist bei der Beschlussfassung zu beachten?</h3>
    <p>
        <strong>Erforderliche Mehrheit:</strong> Da es sich um eine Gesellschaftervereinbarung handelt, muss der Beschluss von allen Gesellschaftern gefasst werden.
    </p>
    <p>
        <strong>Formelles:</strong> Die Vereinbarung kann formfrei getroffen werden, d.h. es ist keine notarielle Beurkundung erforderlich. Es ist jedoch ratsam, die enthaltene Vereinbarung zu "Gewinn- und Liquidationsnachrang" in den Gesellschaftsvertrag der Gesellschaft aufzunehmen. Dafür ist ein notarieller Gesellschafterbeschluss erforderlich.
    </p>
    <p>
        <strong>Zeitpunkt:</strong> Die Vereinbarung kann vor oder nach der notariellen Anteilsübertragung an den nachträglich hinzutretenden Mitgesellschafter getroffen werden. Da bei einem nachträgliche Co-Founder in der Regel alle Altgesellschafter einen Teil ihrer Anteile abgeben und diesen Teil zu Growth Shares machen werden, hat die Growth-Shares-Vereinbarung erst einen Effekt, wenn die Growth Shares auf den neuen Gesellschafter übertragen werden. Vor der Übertragung heben sich die negative Liquidationspräferenzen gegenseitig auf.
    </p>
    <p>
        Sofern der neue Gesellschafter jedoch im Rahmen einer Kapitalerhöhung neue Anteile erhalten soll, kann die Vereinbarung nur danach getroffen werden, denn vorher existieren die Anteile noch nicht, die zu Growth Shares werden sollen.
    </p>

    <h3>Was sind Growth Shares?</h3>
    <p>
        Growth Shares sind Geschäftsanteile, für die die Gesellschafter eine besondere Verteilung von Gewinnen und Erlösen vereinbart haben (nachrangige Liquidationspräferenz). Die Vereinbarung sieht so aus, dass die Inhaber von Growth Shares nur dann an Gewinnen und Erlösen der Gesellschaft beteiligt werden, wenn die Gewinne und Erlöse einen bestimmten Betrag (dem sogenannten Präferenz-Betrag oder auch "Hurdle") übersteigen. So soll erreicht werden, dass die Inhaber von Growth Shares nur an den weiteren Bewertungszuwachs profitieren und nicht an der bisherigen Unternehmensbewertung.
    </p>
    <h3>Warum Growth Shares?</h3>
    <p>
        Mit Growth Shares kann man einerseits erreichen, dass die bestehenden Gründer wirtschaftlich nicht verwässert werden.
    </p>
    <p>
        Andererseits kann man Growth Shares wirkungsvoll einsetzen, um das sogenannte Problem der "Dry Income Versteuerung" zu lösen, welches auftritt, wenn ein Co-Founder "nachträglich" in ein bereits bestehendes Unternehmen einsteigt, das schon einen signifikante Unternehmensbewertung erreicht hat.
    </p>
    <p>
        In einem solchen Fall zahlt der nachträglich einsteigende Co-Founder für die Anteile in der Regel nur einen geringeren Kaufpreis für seine Anteile (z.B. nur den Nennbetrag der Anteile). Der Einstieg soll für die anderen Gründer kein "Exit-Event" sein, mit welchem sie "Kasse" machen können, sondern man will sich die wichtigen Kompetenzen des nachträglichen Co-Founders in die Firma holen und diesen mit den Anteilen dauerhaft an die Firma binden.
    </p>
    <p>
        Problematisch an dieser Gestaltung ist dann jedoch, dass der nachträgliche Mitgründer Anteile für "kleines Geld" erwirbt, die auf dem Papier sehr viel mehr wert sind. Das Finanzamt kann in diesem Fall eine sog. "Dry-Income-Versteuerung" verlangen. Das bedeutet, dass der spätere Co-Founder Steuern auf seinen "Gewinn" (Differenz Anteilswert und Kaufpreis) zahlen muss. Da er diesen Gewinn jedoch nicht flüssig machen kann, kann diese Steuerforderung schnell zu einer finanziellen Überforderung führen. Growth Shares sollen das verhindern.
    </p>
    <p>
      Um diese Dry-Income-Versteuerung zu vermeiden, sind Growth-Shares eine beliebte Lösung.
    </p>
    <h3>Wie hoch sollte der Präferenzbetrag sein?</h3>
    <p>
        Der Präferenzbetrag sollte so gewählt werden, dass er dem aktuellen anteiligen Unternehmenswert - abzüglich des gewünschten Anteilskaufpreises - entspricht. Hierfür ist eine Bewertung erforderlich, mit der Sie Ihren Steuerberater betrauen sollten. Falls Sie bei der Auswahl eines passenden Steuerberaters/Wirtschaftsprüfers Unterstützung benötigen, können wir Ihnen gerne weiterhelfen.
    </p>
    <p>
        Außerdem ist es ratsam, eine <strong>jährliche Verzinsung</strong> des Präferenzbetrags zu vereinbaren, denn andernfalls besteht ein erhöhtes Risiko, dass die Finanzverwaltung bei der Anteilsbewertung den Präferenzbetrag nicht berücksichtigt und es doch zu einer Dry-Income-Versteuerung kommt. Die Verzinszung bedeutet nicht, dass z.B. der Growth Shares Inhaber jährliche Zinsen zahlen muss; sie führen nur dazu, dass der verbleibende Präferenzbetrag jährlich angepasst wird. Aufgrund der Vorgaben des Bewertungsgesetzes ist ein rechnerischer Zinssatz von 5,5 % pro Jahr empfehlenswert.
    </p>
    <h3> Gibt es eine alternative Lösung für das Dry-Income-Problem?</h3>
    <p>
        Alternativ zu der Vereinbarung zu Growth-Shares kann man auch den Weg über § 19a EStG gehen. Im Unterschied zu Growth Shares ist die Versteuerung hier jedoch nicht aufgehoben, sondern nur "aufgeschoben". Der nachträglich eintretende Co-Founder muss dann die Steuern erst zahlen, wenn er die Anteile wieder verkauft. <a href="https://resolvio.com/wissen/mitarbeiterbeteiligung-steuerfrei-bzw-steueroptimiert-hierauf-kommt-es-an#ausweg-2:-startup-privileg">Nähere Informationen zu § 19a EStG und weiteren Alternativen erhalten Sie hier</a>.
    </p>


    `,
    metaDescription:
      'Growth Shares-Gesellschaftervereinbarung (auch bekannt als "Hurdle Shares" oder "negative Liquidationspräferenz"). Juristisch geprüfte Standard-Muster/Vorlagen kostenlos ausfüllen und downloaden.',
    tags: [
      "Growth Shares",
      "Hurdle Shares",
      "negative Liquidationspräferenz",
      "Gesellschaftervereinbarung",
      "Gesellschaftervertrag",
      "Shareholder Agreement",
      "Beteiligungsvertrag",
      "nachträglich",
      "Co-Founder",
      "Mitgründer",
    ],
    antrag: `

   ${insertTemplateVariable(listOfBoardMembers)}

  <p>
    nachfolgend gemeinsam auch <strong>"Gesellschafter"</strong> oder <strong>"Parteien"</strong> genannt,
  <p>
  <p>
    schließen die folgende Vereinbarung:
  </p>
  <h2>
    Gesellschaftervereinbarung zu Growth Shares
  </h2>
  <ol data-list-style="decimal" data-list-style-prefix="§">
    <li>
        <h3>
            Präambel
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Die Gesellschafter halten sämtliche Geschäftsanteile an der in das Handelsregister des ${insertTemplateVariable(
                      unternehmenRegisterAmtsgericht
                    )} unter ${insertTemplateVariable(
      unternehmenRegisternummer
    )} eingetragenen ${insertTemplateVariable(
      unternehmenFirma
    )} mit dem Sitz in ${insertTemplateVariable(
      unternehmenSitz
    )} (nachfolgend “Gesellschaft” genannt).
                </p>
            </li>
            <li>
                <p>
                    Die Gesellschafter beabsichtigen hiermit, für die nachfolgend als Growth Shares bezeichneten Geschäftsanteile eine nachrangige  Liquidationspräferenz festzulegen, d.h. eine nachrangige Teilnahme an der Gewinn- und Erlösverteilung. Hierdurch soll gewährleistet werden, dass es zu keiner Dry-Income-Versteuerung bei dem nachträglich hinzutretenden Mitgesellschafter kommt, der die Anteile zu einem entsprechend geminderten Kaufpreis erwirbt.
                </p>
                <p>
                    Eine Zuteilung von Gewinnen bzw. Erlösen an die Inhaber der Growth Shares soll also nur erfolgen, wenn und soweit die rechnerisch nach dem Verhältnis der Beteiligungen am Stammkapital auf sie entfallenden Gewinne/Erlöse einen "negativen Präferenzbetrag" übersteigen.
                </p>
                <p>
                    Dabei soll der "negative Präferenzbetrag" dem Betrag der aktuellen Bewertung der Growth Shares entsprechen.
                </p>
            </li>
        </ol>
    </li>
    <li>
        <h3>
            Gewinn- und Liquidationsnachrang
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Den jeweiligen Inhabern der Geschäftsanteile mit den laufenden Nummern ${insertTemplateVariable(
                      geschäftsanteileNummerGrowthShares
                    )} (nachfolgend als <strong>„Growth-Geschäftsanteile“</strong> bezeichnet) steht ein Recht auf
                </p>
                <ol data-list-style="lower-latin">
                    <li>
                        <p>
                            Gewinnausschüttungen (insbes. laufende Gewinne),
                        </p>
                    </li>
                    <li>
                        <p>
                            andere Ausschüttungen (insbes. Ausschüttungen aus der Auflösung von Rücklagen) oder
                        </p>
                    </li>
                    <li>
                        <p>
                            Auszahlung von Liquidationsüberschüssen im Rahmen der Veteilung des Vermögens der Gesellschaft nach §§ 72, 73 GmbHG oder § 199 Satz 2 InsO)

                        </p>
                    </li>
                </ol>
                <p>
                   nachfolgend einheitlich als <strong>„Ausschüttungen“</strong> bezeichnet,
                </p>
                <p>
                    nur dann und insoweit zu, als die Ausschüttungen, die rechnerisch jeweils auf einen Growth-Geschäftsanteil entfallen, den jeweiligen Betrag der negativen Liquidationspräferenz, nachfolgend auch <strong>„NLP-Betrag“</strong>, übersteigen.
                </p>

            </li>

            <li>
                <p>
                    Der NLP-Betrag beträgt je Geschäftsanteil anfänglich ${insertTemplateVariable(
                      geschäftsanteileBetragGrowthSharesJeAnteil
                    )}, mithin insgesamt ${insertTemplateVariable(
      geschäftsanteileBetragGrowthSharesGesamt
    )}, und wird jährlich mit 5,5 % verzinst.
                </p>
            </li>
            <li>
                <p>
                    Jegliche Ausschüttungen, die rechnerisch auf einen Growth-Geschäftsanteil entfallen, sind bis zur Höhe des jeweiligen NLP-Betrags auf alle Inhaber anderer Geschäftsanteile an der Gesellschaft - mit Ausnahme der Growth-Geschäftsanteile - anteilig nach dem Verhältnis ihrer Beteiligung an diesen anderen Geschäftsanteilen zu verteilen. Alle Ausschüttungen, die gemäß vorstehendem Satz an die Inhaber anderer Geschäftsanteile (mit Ausnahme der Growth-Geschäftsanteile) ausgeschüttet werden, reduzieren den NLP-Betrag entsprechend bis zu einem Betrag von 0,00 EUR (null Euro). Für Zwecke einer späteren Zuteilung von Ausschüttungen ist der jeweils angepasste NLP-Betrag maßgeblich.
                </p>
            </li>
            <li>
                <p>
                    Die Gesellschafter verpflichten sich, die vorstehenden Absätze dieses Paragraphen durch notariellen satzungsändernden Gesellschafterbeschluss unverzüglich in den Gesellschaftsvertrag der Gesellschaft aufzunehmen.
                </p>
            </li>
        </ol>
    </li>
    <li>
        <h3>
            Veräußerungsnachrang
        </h3>
        <p>
            Für den Fall, dass “Growth-Geschäftsanteile” ganz oder teilweise veräußert und abgetreten werden oder anderweitig erlösbringend hierüber verfügt wird, vereinbaren deren jeweiliger Inhaber und die übrigen Gesellschafter, dass der hierfür an den Inhaber bezahlte Betrag nach Abzug etwaiger Umsatzsteuer und den gemeinsamen Kosten der Veräußerung wie Ausschüttungen gemäß dem vorstehenden § 2 Absatz 3 behandelt werden.

    </li>
    <li>
        <h3>
            Verfügungen über Growth-Geschäftsanteile

        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Verfügungen über Growth-Geschäftsanteile bedürfen der Zustimmung aller Gesellschafter.
                </p>
            </li>
            <li>
                <p>
                    Sämtliche Gesellschafter verpflichten sich, ihre Zustimmung zu einer Veräußerung und/oder Abtretung oder sonstigen erlösbringenden Verfügung über die Growth-Gesellschaftsanteile nur dann zu erteilen, wenn der Erwerber zuvor oder parallel dieser Gesellschaftervereinbarung beitritt.
                </p>
            </li>
        </ol>
    </li>
    <li>
        <h3>
            Umwandlung von Growth-Geschäftsanteilen
        </h3>
        <p>
             Die vorstehenden Regelungen auch für solche Anteile, die im Rahmen einer Umwandlung entstanden sind, soweit sie die Growth-Geschäftsanteilen ersetzen bzw. diesen entsprechen.
        </p>
    </li>
    <li>
        <h3>
            Laufzeit und Kündigung
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Diese Vereinbarung ist auf unbestimmte Zeit geschlossen. Sie endet vor diesem Zeitpunkt, wenn nur noch ein Gesellschafter an der Gesellschaft beteiligt ist. Scheidet ein Gesellschafter aus der Gesellschaft, gleich aus welchem Rechtsgrund, aus, enden, soweit in dieser Vereinbarung nicht ausdrücklich anders geregelt, seine Rechte und Pflichten aus dieser Vereinbarung mit dem rechtlichen Wirksamwerden seines Ausscheidens. Dagegen bleiben bereits begründete Ansprüche auch nach diesem Zeitpunkt bestehen und sind von der jeweiligen Partei zu erfüllen.
                </p>
            </li>
            <li>
                <p>
                    Das Recht der Parteien zur Kündigung aus wichtigem Grund bleibt unberührt.
                </p>
            </li>

        </ol>
    </li>
    <li>
        <h3>
            Schlussbestimmungen
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Die Parteien sind nicht berechtigt, Ansprüche oder sonstige Rechte aus dieser Vereinbarung ohne Zustimmung der jeweils anderen Parteien zu übertragen, zu verpfänden oder in sonstiger Weise zu belasten.
                </p>
            </li>
            <li>
                <p>
                    Änderungen dieser Vereinbarung bedürfen, soweit gesetzlich keine strengere Form vorgesehen ist, der Textform. Dies gilt auch für die Änderung dieser Textformklausel.
                </p>
            </li>
            <li>
                <p>
                    Diese Vereinbarung unterliegt ausschließlich dem Recht der Bundesrepublik Deutschland unter Ausschluss der Vorschriften ihres Rechts, die zur Anwendung einer anderen Rechtsordnung führen könnten. Die Anwendung des UN-Kaufrechts ist ausgeschlossen.
                </p>
            </li>
            <li>
                <p>
                    Sämtliche Rechtsstreitigkeiten zwischen den Parteien aus und im Zusammenhang mit dieser Vereinbarung sollen von den ordentlichen deutschen Gerichten entschieden werden. Ausschließlicher Gerichtsstand ist, soweit gesetzlich zulässig, der Sitz der Gesellschaft.

                </p>
            </li>
            <li>
                <p>
                    Sollte eine Bestimmung dieser Vereinbarung ganz oder teilweise nichtig, unwirksam oder nicht durchsetzbar sein oder werden oder sollte eine an sich notwendige Regelung in ihr nicht enthalten sein, werden hiervon die Wirksamkeit und die Durchsetzbarkeit aller übrigen Bestimmungen dieser Vereinbarung nicht berührt. Anstelle der nichtigen, unwirksamen oder nicht durchsetzbaren Bestimmung oder zur Ausfüllung der Regelungslücke werden die Parteien eine rechtlich zulässige Regelung vereinbaren, die so weit wie möglich dem entspricht, was die Parteien gewollt haben oder nach dem Sinn und Zweck dieser Vereinbarung vereinbart hätten, wenn sie die Unwirksamkeit oder die Regelungslücke erkannt hätten. Beruht die Nichtigkeit einer Bestimmung auf einem darin festgelegten Maß der Leistung oder der Zeit (Frist oder Termin), so werden die Parteien eine Bestimmung mit einem dem ursprünglichen Maß am nächsten kommenden rechtlich zulässigen Maß vereinbaren. Es ist der ausdrückliche Wille der Parteien, dass diese salvatorische Klausel keine bloße Beweislastumkehr zur Folge hat, sondern § 139 BGB insgesamt abbedungen ist.
                </p>
            </li>
        </ol>
    </li>

</ol>


  `,
    begründung: undefined,
    templateVariables: [
      listOfBoardMembers,
      unternehmenRegisterAmtsgericht,
      unternehmenRegisternummer,
      unternehmenFirma,
      unternehmenSitz,
      geschäftsanteileNummerGrowthShares,
      geschäftsanteileBetragGrowthSharesJeAnteil,
      geschäftsanteileBetragGrowthSharesGesamt,
    ],
    isEmployeeParticipationTemplate: true,
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: true,
    noteHandelsregisteranmeldung: undefined,
  });
