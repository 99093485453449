import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { fontWeightBold } from "../../../layouts/theme";

export const useRVOFooterStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      rowGap: theme.spacing(3),
      padding: theme.spacing(6, 0),
      [theme.breakpoints.down("sm")]: {
        rowGap: theme.spacing(2),
      },
    },
    newsletterDastoolHilfeWrapper: {
      flexDirection: "row",
      justifyContent: "space-between",
      rowGap: theme.spacing(3),
    },
    leftColumnWrapper: {
      flexDirection: "column",
    },
    rigthColumnWrapper: {
      flexDirection: "row",
      justifyContent: "flex-end",
      columnGap: theme.spacing(10),
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
        columnGap: theme.spacing(8),
      },
    },
    columnTitle: {
      textAlign: "right",
      fontWeight: fontWeightBold,
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
      },
    },
    dasToolWrapper: {
      flexDirection: "column",
    },
    informationWrapper: {
      flexDirection: "column",
    },
    ul: {
      paddingInlineStart: 0,
      textAlign: "right",
      [theme.breakpoints.down("sm")]: {
        textAlign: "left",
      },
      "& li": {
        cursor: "pointer",
      },
    },
    link: {
      textDecoration: "underline",
      textDecorationColor: theme.palette.primary.main,
    },
    socialMediaWrapper: {
      flexDirection: "row",
      justifyContent: "flex-end",
      columnGap: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },
    },
    socialMediaButton: {
      padding: "0 !important",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minWidth: "auto",
      width: "auto",
    },
    socialMediaIcon: {
      width: "auto",
    },
  })
);
