export enum Page {
  Home = "Home",
  Grundlagen = "wissen/grundlagen",
  Wissen = "wissen",
  Imprint = "Imprint",
  PrivacyPolicy = "PrivacyPolicy",
}

export enum PageKey {
  Home = "home",
}
