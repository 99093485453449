/* components in here are very small */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import { Typography } from "@material-ui/core";
import RVOShowVorlagenPicker from "../../../../components/01-atoms/RVOShowVorlagenPicker/RVOShowVorlagenPicker";
import { VTSAnimatedUnderline } from "../../../../components/01-atoms/VTSAnimatedUnderline/VTSAnimatedUnderline";
import RVOCtaBlockCard from "../../../../components/02-molecules/RVOCtaBlockCard/RVOCtaBlockCard";
import { HtmlReplacementComponentProps } from "../../../../interfaces/reactComponentInterfaces";
import { LegacyTemplateLandingpage } from "../../../createVorlageLandingpage";
import { vorlagenPickerLinkTag } from "../../../general/templates/insertTemplateVariable";
import { extractBlockCardPropsFromDomNode } from "../../extractBlockCardPropsFromDomNode";
import { underlinedBackendTag } from "../../getUnderlinedBackendTag";
import {
  ElementIdToComponentMap,
  TagToComponentMap,
} from "../replaceHtmlElementInterfaces";
import { mappedDivs } from "./mappedDivs";
import { mappedFigures } from "./mappedFigures";
import { mappedFootnoteNumbers } from "./mappedFootnoteNumbers";
import { mappedFootnotes } from "./mappedFootnotes";
import { mappedFootnotesArea } from "./mappedFootnotesArea";
import { mappedHeadlines } from "./mappedHeadlines";
import { mappedImplementationButtons } from "./mappedImplementationButtons";
import { mappedLinks } from "./mappedLinks";
import { mappedParagraphs } from "./mappedParagraphs";
import { mappedTemplateVariables } from "./mappedTemplateVariables";
import { mappedUnorderedLists } from "./mappedUnorderedLists";

const commonElements: TagToComponentMap = {
  p: (props) => (
    <Typography variant="body1" className="marginBottom">
      {props.children}
    </Typography>
  ),
  ...mappedDivs,
  ...mappedFigures,
  ...mappedLinks,
  ...mappedFootnoteNumbers,
  ...mappedFootnotes,
  ...mappedFootnotesArea,
  ...mappedParagraphs,
  ...mappedUnorderedLists,
};

const specialRVOElements: TagToComponentMap = {
  [vorlagenPickerLinkTag]: (props) => {
    return <RVOShowVorlagenPicker>{props.children}</RVOShowVorlagenPicker>;
  },
};

export const mapCustomGutenbergBlocks: ElementIdToComponentMap = {
  "rsv-cta-block": (props: HtmlReplacementComponentProps) => {
    const ctaProps = extractBlockCardPropsFromDomNode(props.replacedElement);

    return <RVOCtaBlockCard {...ctaProps} />;
  },
};

export const cleanupWpHeadlines: TagToComponentMap = {
  p: (props) => <span>{props.children}</span>,
  [underlinedBackendTag]: VTSAnimatedUnderline,
};

export const mapGenericContent: TagToComponentMap = {
  ...commonElements,
  ...mappedHeadlines(true),
  ...specialRVOElements,
};

export const mapBeschlussVorlageContent = (
  vorlage: LegacyTemplateLandingpage,
  hyphenate: boolean
): TagToComponentMap => ({
  ...commonElements,
  ...mappedHeadlines(hyphenate),
  ...mappedTemplateVariables(vorlage),
  ...(mappedImplementationButtons() as TagToComponentMap),
});
