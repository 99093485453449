/* eslint-disable no-nested-ternary */
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  alpha,
} from "@material-ui/core";
import { TranslatedString } from "@nvon/react-toolbox";
import { motion, useAnimationControls, useInView } from "framer-motion";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import theme, { fontWeightBold } from "../../../layouts/theme";
import RVOHelpButton from "../../02-molecules/RVOHelpButton/RVOHelpButton";

export interface ComparisionRowTitleDescription {
  rowTitle: TranslatedString;
  rowDescription?: TranslatedString;
  rowExplanation?: TranslatedString;
}

export interface ComparisionColumnTitleValue {
  columnTitle: string;
  value: string;
}

export interface ComparisonRows {
  rowData: ComparisionRowTitleDescription;
  columns: ComparisionColumnTitleValue[];
}

export const RVOComparisonTable = ({
  rows,
}: {
  rows: ComparisonRows[];
}): JSX.Element => {
  const { t } = useTranslation();
  const variants = {
    hidden: { opacity: 0, y: -50 }, // Start position for the animation
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.1, // Delay each row based on its index
        duration: 0.5, // Duration of the animation
        type: "spring", // Use a spring type animation for a bounce effect
        stiffness: 100, // Spring stiffness, adjust for more/less bounce
        damping: 10, // Spring damping, adjust to change how the bounce behaves
      },
    }),
  };

  const controls = useAnimationControls();
  const ref = useRef(null);
  const isInView = useInView(ref);
  useEffect(() => {
    if (isInView) {
      void controls.start("visible");
    }
  }, [controls, isInView]);

  return (
    <TableContainer style={{ overflow: "hidden" }} ref={ref}>
      <Table style={{ overflow: "hidden" }}>
        <TableHead
          style={{
            backgroundColor: alpha(theme.palette.warning.light, 0.5),
          }}
        >
          <TableRow>
            <TableCell />

            {rows[0]?.columns.map((column) => (
              <TableCell key={column.columnTitle}>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    style={{
                      color: theme.palette.primary.dark,
                      fontWeight: fontWeightBold,
                    }}
                    variant="body2"
                  >
                    {column.columnTitle}
                  </Typography>
                </Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody style={{ overflow: "hidden" }}>
          {rows.map((row, index) => {
            return (
              <TableRow
                key={row.rowData.rowTitle(t)}
                component={motion.tr}
                variants={variants}
                initial="hidden"
                custom={index}
                animate={controls}
                transition={{ duration: 0.3 }}
              >
                <TableCell>
                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: theme.spacing(1),
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: theme.spacing(1),
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: theme.palette.primary.dark,
                          fontWeight: fontWeightBold,
                        }}
                        variant="body2"
                      >
                        {row.rowData.rowTitle(t)}
                      </Typography>

                      {row.rowData.rowExplanation && (
                        <RVOHelpButton
                          size="small"
                          iconColor={theme.palette.primary.light}
                          helpTitle={row.rowData.rowTitle(t)}
                          helpText={row.rowData.rowExplanation(t)}
                        />
                      )}
                    </Box>

                    <Typography
                      variant="body2"
                      style={{
                        color: theme.palette.primary.light,
                      }}
                    >
                      {row.rowData.rowDescription &&
                        row.rowData.rowDescription(t)}
                    </Typography>
                  </Box>
                </TableCell>

                {row.columns.map((column) => {
                  return (
                    <TableCell key={column.columnTitle}>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="body1"
                          style={{
                            fontWeight: fontWeightBold,
                            color:
                              column.value === "✔"
                                ? theme.palette.success.main
                                : column.value === "✖"
                                ? theme.palette.error.main
                                : theme.palette.primary.dark,
                          }}
                        >
                          {column.value}
                        </Typography>
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
