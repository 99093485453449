import { TemplateVariable } from "../../../common-interfaces/VorlageInterface";
import { templateVariableKey } from "./templateVariableKey";

/**
 * The html tag used to mark template variables.
 */
export const templateVariableHtmlTag = "template-variable";

export const vorlagenPickerLinkTag = "vorlagen-picker-link";

/**
 * Inserts a template variable into an html string.
 *
 * Used to define template strings from code,
 *  without having having to (re-)type <template-string>.
 */
export const insertTemplateVariable = (variable: TemplateVariable): string =>
  `<${templateVariableHtmlTag}>${templateVariableKey(
    variable
  )}</${templateVariableHtmlTag}>`;

export const v = insertTemplateVariable;

export const makeTemplateVariable = insertTemplateVariable;
