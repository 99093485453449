import {
  adresseProkurist,
  geburtsdatumProkurist,
  nameProkurist,
  tagProkura,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageErteilungGesamtprokura: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
  vorlageKategorie: VorlageKategorie.prokura,
  vorlage_rolleTyp: [
    RolleInGesellschaft.Geschftsleiter,
    RolleInGesellschaft.Mitglieder,
  ],
  vollzug: [Vollzug.zugang, Vollzug.handelsregisterAnmeldung],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Erteilung einer erweiterten Gesamtprokura",
  tags: [
    "prokura",
    "Vollmacht",
    "handelsrechtliche Vollmacht",
    "bevollmächtigten",
    "Bevollmächtigter",
    "prokurist",
    "power of attorney",
    "procuration",
    "proxy",
    "general commercial power of representation",

    "erteilen",
    "Erteilung",
    "confer",
    "bestellen",
    "Bestellung",
  ],
  metaDescription:
    "Prokura für Vertretung der Gesellschaft gemeinsam mit anderen Prokurist oder Geschäftsführer (inkl Befugnis zur Veräußerung/ Belastung von Grundstücken). Muster/Vorlagen kostenlos.",
  description: `
  <p>
    Die erweiterte Gesamtprokura ermächtigt den Prokuristen auch <strong>zur Veräußerung und Belastung von Grundstücken</strong> der Gesellschaft, allerdings stets nur gemeinschaftlich mit einem anderen Prokuristen oder einem Geschäftsführer (im Gegensatz zu einer <a href="/beschluss-vorlagen?search=einzelprokura">Einzelprokura</a>).
  </p>

  <p>
    Die Erteilung der Prokura gegenüber dem neuen Prokuristen erfolgt durch Beschluss der Geschäftsführung (<a href="/beschluss-basics/zustaendigkeit-des-geschaeftsfuehrers-fuer-die-prokuraerteilung-gesellschafterbeschluss-fuer-das-innenverhaeltnis-bgh-beschluss-vom-14-02-01974-aktenzeichen-ii-zb-6-73">BGH</a>).
  </p>

<p>
    Bei einer GmbH oder UG hat die Geschäftsführung zudem grundsätzlich einen zustimmenden Gesellschafterbeschluss einzuholen (<a href="/beschluss-basics/§-46-gmbhg/">§ 46 Nr. 7 GmbHG</a>), außer etwas anderes ist im Gesellschaftsvertrag bestimmt.
  </p>
  `,
  antrag: `<p>
  ${insertTemplateVariable(nameProkurist)},<br/>
  geboren am ${insertTemplateVariable(geburtsdatumProkurist)},<br/>
  wohnhaft in ${insertTemplateVariable(adresseProkurist)},
  </p>

  <p>
    wird mit Wirkung ab ${insertTemplateVariable(tagProkura)} derart</p>

  <p><strong>Gesamtprokura</strong></p>

  <p>erteilt, dass ${insertTemplateVariable(
    nameProkurist
  )} gemeinsam mit einem Geschäftsführer oder einem Prokuristen die Gesellschaft vertritt und insoweit auch zur Veräußerung und Belastung von Grundstücken befugt ist.
  </p>

    <p>
    ${insertTemplateVariable(
      nameProkurist
    )} wird angewiesen, künftig mit dem Zusatz "per prokura" oder mit der voranzustellenden Abkürzung „ppa." für die Gesellschaft zu zeichnen.
  </p>


  `,
  begründung: undefined,
  templateVariables: [
    nameProkurist,
    geburtsdatumProkurist,
    adresseProkurist,
    tagProkura,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: true,
  noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss. Die Handelsregisteranmeldung muss von den Geschäftsführern (bei einer GmbH) bzw. den persönlich haftenden Gesellschaftern (bei einer KG/OHG) in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
});
