import {
  nameGeschäftsführer,
  tagAufhebung,
  tantiemeAuszahlungZeitpunkt,
  tantiemePauschale,
  tantiemeZeitraumBeginn,
  vergütungBrutto,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGeschaftsfuhrerAnstellungsvertragAufhebung: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.geschäftsführerAusscheiden,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.zustimmungBetroffener],
    bezug: [
      "GeschäftsführerAbberufung",
      "GeschäftsführerAnstellungsvertragAufhebung",
      "GeschäftsführerEntlastungErteilung",
      "GeschäftsführerEntlastungVerweigerung",
    ],
    tags: [
      "Anstellung",
      "Anstellungsvertrag",
      "aufheben",
      "Aufhebungsvertrag",
      "Antellungsvertrag aufheben",
      "Anstellung beenden",
      "Anstellungsverhältnis aufheben",
      "Anstellungsverhältnis beenden",

      "Entscheider",
      "Entscheidungsträger",
      "Direktor",
      "Director",
      "Vorstand",
      "Vorsitzender",
      "Führung",
      "Leitung",
      "Leiter",
      "Geschäftsführung",
      "Geschäftsleitung",
      "Geschäftsleiter",
      "Chef",
      "Leiter",
      "Vorgesetzter",
      "Führungskraft",
      "Geschäftsführer",
      "Manager",
      "CEO",
      "CTO",
      "CFO",
      "leader",
      "head",
      "chief",
      "executive",
      "boss",
      "governor",
      "governance",
    ],
    zustimmungErforderlichDes: "ausscheidenden Geschäftsführers",
    kurzBezeichnung: "Aufhebung eines Geschäftsführer-Anstellungsvertrags",
    metaDescription:
      "Gesellschafterbeschluss für den Abschluss eines Aufhebungsvertrags mit dem Geschäftsführer. Muster/Vorlagen von Rechtsanwälten kostenlos ausfüllen und downloaden.",
    description: `<p>
        Diese Vorlage umfasst die üblichen Regelungspunkte, welche im Rahmen eines einfachen Aufhebungsvertrages zu regeln sind. Dabei geht die Vorlage davon aus, dass eine Tantieme gemäß Anstellungsvertrag geschuldet war und dass kein nachvertragliches Wettbewerbsverbot gewollt ist (ansonsten müssten insbesondere Verbotsumfang, Verbotslänge und Karenzentschädigung geregelt werden).
      </p>
      <p>
      Damit der Aufhebungsvertrag wirksam wird, ist die Zustimmung des ausscheidenden Geschäftsführers erforderlich.
      </p>`,
    antrag: `
    <p>
    Das zwischen der Gesellschaft und ${insertTemplateVariable(
      nameGeschäftsführer
    )} bestehende Anstellungsverhältnis wird mit Wirkung zum ${insertTemplateVariable(
      tagAufhebung
    )} (Beendigungstermin) beendet.</p>

  <p>Hierzu wird folgender</p>

  <h2>Aufhebungsvertrag</h2>

  <p>geschlossen:</p>

 <ol data-list-style="decimal" data-list-style-prefix="§ ">
  <li><h3>Vergütung</h3>

    <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>Die Gesellschaft ist verpflichtet, bis zum Beendigungstermin an ${insertTemplateVariable(
          nameGeschäftsführer
        )} die vertraglich geschuldete Vergütung in Höhe von ${insertTemplateVariable(
      vergütungBrutto
    )} brutto pro Monat nach Abzug von gesetzlichen Steuern und Abgaben entsprechend den Regelungen des Anstellungsvertrags auszuzahlen.</p></li>

  <li><p>Mit Rücksicht auf den Umstand, dass zurzeit noch nicht festgestellt werden kann, ob und in welchem Umfang ${insertTemplateVariable(
    nameGeschäftsführer
  )} eine Tantieme zusteht, wird die vertraglich vereinbarte Tantieme hiermit für den Zeitraum von ${insertTemplateVariable(
      tantiemeZeitraumBeginn
    )} bis zum Beendigungstermin (${insertTemplateVariable(
      tagAufhebung
    )}) mit einem Betrag in Höhe von ${insertTemplateVariable(
      tantiemePauschale
    )} pauschaliert. Der ${insertTemplateVariable(
      nameGeschäftsführer
    )} erhält die pauschalierte Tantieme bis zum ${insertTemplateVariable(
      tantiemeAuszahlungZeitpunkt
    )} ausgezahlt. Weitere Ansprüche auf eine Tantieme bestehen nicht.
    </p></li>
  </ol>
  </li>

  <li><h3>Dienstverpflichtung</h3>

  <p>Bis zum Beendigungstermin bleibt ${insertTemplateVariable(
    nameGeschäftsführer
  )} zur Geschäftsführung entsprechend den Regelungen des Anstellungsvertrages verpflichtet.
  </p></li>

  <li><h3>Freistellungsrecht</h3>

  <p>Die Gesellschaft ist berechtigt,  ${insertTemplateVariable(
    nameGeschäftsführer
  )} einseitig von der Verpflichtung zur Geschäftsführung freizustellen. Anderweitige Bezüge während der Freistellung werden auf die Vergütung angerechnet (§ 615 S. 2 BGB), soweit nicht während der Freistellung Urlaub gewährt wird.
  </p>
  </li>

  <li><h3>Wettbewerbsverbot</h3>

  <p>Bis zum Beendigungstermin bleibt ${insertTemplateVariable(
    nameGeschäftsführer
  )} an das Wettbewerbsverbot gebunden. In diesem Zeitraum ist jegliche Tätigkeit für ein Wettbewerbsunternehmen zum Unternehmen der Gesellschaft verboten, auch wenn ${insertTemplateVariable(
      nameGeschäftsführer
    )} von der Verpflichtung zur Geschäftsführung freigestellt wird.
${insertTemplateVariable(
  nameGeschäftsführer
)} bleibt verpflichtet, auch nach der Beendigung des Anstellungsverhältnisses Betriebs- und Geschäftsgeheimnisse der Gesellschaft geheim zu halten. Ein nachvertragliches Wettbewerbsverbot besteht nicht.
</p>
  </li>

  <li><h3>Urlaubsanspruch</h3>

  <p>Zwischen der Gesellschaft und ${insertTemplateVariable(
    nameGeschäftsführer
  )} besteht Einvernehmen, dass ${insertTemplateVariable(
      nameGeschäftsführer
    )} die gesamten vertraglichen Urlaubsansprüche bis zum Beendigungstermin bereits bei Unterzeichnung dieser Vereinbarung eingebracht hat und dass zum Zeitpunkt der Unterzeichnung keine Überstundenguthaben bestehen.</p>
  </li>

  <li><h3>Herausgabe von Unterlagen und Gegenständen</h3>

<p>${insertTemplateVariable(
      nameGeschäftsführer
    )} verpflichtet sich, der Gesellschaft alle Unterlagen und Gegenstände, die im Eigentum der Gesellschaft stehen oder ihr zustehen, auf Anforderung sofort, spätestens jedoch bis zum Beendigungstermin, herauszugeben. Ein Zurückbehaltungsrecht besteht nicht.
    </p>
    </li>


  <li><h3>Betriebliche Altersversorgung</h3>

<p>Sofern eine betriebliche Altersvorsorge zugesagt ist, verpflichtet sich die Gesellschaft, ${insertTemplateVariable(
      nameGeschäftsführer
    )} eine Bescheinigung gem. § 4a BetrAVG nach der Beendigung des Anstellungsverhältnisses zu erstellen und zu übersenden.</p>
  </li>

  <li><h3>Zeugnis</h3>

 <p>Die Gesellschaft verpflichtet sich, ${insertTemplateVariable(
   nameGeschäftsführer
 )} zum Beendigungstermin ein qualifiziertes wohlwollendes Zeugnis zu erteilen.</p>

  </li>

  <li><h3>Erledigungsklausel und Entlastung:</h3>

 <p>Die Gesellschaft und ${insertTemplateVariable(
   nameGeschäftsführer
 )} sind sich einig, dass sämtliche Ansprüche aus dem Anstellungsverhältnis und aus Anlass seiner Beendigung, gleich aus welchem Rechtsgrund, nicht mehr gegeneinander bestehen. ${insertTemplateVariable(
      nameGeschäftsführer
    )} wird Entlastung erteilt.</p>
  </li>

</ol> `,
    begründung: undefined,
    templateVariables: [
      nameGeschäftsführer,
      tagAufhebung,
      vergütungBrutto,
      tantiemeZeitraumBeginn,
      tantiemePauschale,
      tantiemeAuszahlungZeitpunkt,
    ],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
