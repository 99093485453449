import {
  adresseArbeitnehmer,
  arbeitsverhältnisBeginn,
  dauerArbeitszeitStunden,
  dauerProbezeitMonate,
  geburtsdatumArbeitnehmer,
  grenzeMitabgegolteneÜberstunden,
  nameArbeitnehmer,
  tätigkeitArbeitnehmer,
  tätigkeitsortArbeitnehmer,
  urlaubstageAnzahl,
  vergütungArbeitnehmer,
  verteilungArbeitszeitWochentage,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageArbeitsvertragEinfachTeilzeit: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.mitarbeiter,
  vorlage_rolleTyp: [RolleInGesellschaft.Geschftsleiter],
  vollzug: [Vollzug.zustimmungBetroffener],
  bezug: [],
  zustimmungErforderlichDes: "Arbeitnehmers",
  kurzBezeichnung: "Einfacher Arbeitsvertrag (Teilzeit) im Beschluss",
  description: undefined,
  antrag: `Die Gesellschaft, vertreten durch die Geschäftsführung, - nachfolgend Arbeitgeber genannt - schließt mit

  <p>${insertTemplateVariable(nameArbeitnehmer)},<br/>
  geboren am ${insertTemplateVariable(geburtsdatumArbeitnehmer)},<br/>
  wohnhaft in ${insertTemplateVariable(adresseArbeitnehmer)},</p>

  <p>einen

  <p style="text-align:center"><strong>Arbeitsvertrag</strong></p>

  mit dem folgenden Inhalt ab:</p>

  <ol><li><p><strong>Aufgabengebiet und Arbeitsort</strong>

    <p>(1) ${insertTemplateVariable(
      nameArbeitnehmer
    )} wird als ${insertTemplateVariable(
    tätigkeitArbeitnehmer
  )} im Rahmen eines Teilzeit-Arbeitsverhältnisses eingestellt. Tätigkeitsort ist derzeit ${insertTemplateVariable(
    tätigkeitsortArbeitnehmer
  )}.</p>

  <p>(2) Der Arbeitgeber ist berechtigt, ${insertTemplateVariable(
    nameArbeitnehmer
  )} aus betrieblichen Gründen unter Wahrung der Interessen von ${insertTemplateVariable(
    nameArbeitnehmer
  )} eine andere, gleichwertige Tätigkeit oder ein anderes Arbeitsgebiet zu übertragen, soweit dies den Fähigkeiten und Kenntnissen von${insertTemplateVariable(
    nameArbeitnehmer
  )} entspricht, oder auch gleichermaßen ${insertTemplateVariable(
    nameArbeitnehmer
  )} an einem anderen Ort einzusetzen.</p>

  <p>(3) ${insertTemplateVariable(
    nameArbeitnehmer
  )} verpflichtet sich, ohne vorherige Zustimmung des Arbeitgebers keine weiteren Tätigkeiten aufzunehmen. Der Arbeitgeber hat seine Zustimmung zu geben, wenn nicht seine berechtigten Interessen dagegen sprechen. </p></li>

  <li><p><strong>Beginn, Dauer und Beendigung des Arbeitsverhältnisses</strong>

    <p>(1) Das Arbeitsverhältnis beginnt am ${insertTemplateVariable(
      arbeitsverhältnisBeginn
    )} obliegt die Leitung und Überwachung des Unternehmens im Ganzen.</p>

    <p>(2) Die ersten ${insertTemplateVariable(
      dauerProbezeitMonate
    )} Monate des Arbeitsverhältnisses gelten als Probezeit, innerhalb derer mit einer Frist von zwei Wochen ordentlich gekündigt werden kann.</p>

    <p>(3) Nach Ablauf der Probezeit kann das Arbeitsverhältnis ordentlich mit den gesetzlichen Kündigungsfristen gekündigt werden. Eine gesetzlich verlängerte Frist gilt auch für die Kündigung durch ${insertTemplateVariable(
      nameArbeitnehmer
    )}. Das Recht zur außerordentlichen Kündigung bleibt unberührt.</p>

    <p>(4) Jede Kündigung bedarf der Schriftform.</p>

    <p>(5) Der Arbeitgeber kann im Fall einer Kündigung ${insertTemplateVariable(
      nameArbeitnehmer
    )} im Rahmen von bestehenden und noch entstehenden Urlaubsansprüchen oder sonstigen Freizeitausgleichsansprüchen und in konkreter Anrechnung von solchen freistellen. Darüber hinaus ist der Arbeitgeber berechtigt, ${insertTemplateVariable(
    nameArbeitnehmer
  )} im Fall einer Kündigung bis zum Ausscheiden unter Fortzahlung der Bezüge ganz oder teilweise von der Arbeit freizustellen, soweit sein Interesse hieran das Interesse von ${insertTemplateVariable(
    nameArbeitnehmer
  )} an der Weiterbeschäftigung überwiegt.</p>

    <p>(6) Das Arbeitsverhältnis endet, ohne dass es einer Kündigung bedarf, mit Ablauf des Monats, in dem ${insertTemplateVariable(
      nameArbeitnehmer
    )} die Voraussetzungen für den Bezug einer ungekürzten Rente wegen Alters aus der gesetzlichen Rentenversicherung erfüllt hat.</p></li>

  <li><p><strong>Arbeitszeit</strong>

    <p> (1) Die regelmäßige wöchentliche Arbeitszeit beträgt ${insertTemplateVariable(
      dauerArbeitszeitStunden
    )} Stunden ausschließlich der Pausen.</p>

     <p> (2)Die Arbeitszeit verteilt sich auf den einzelnen Arbeitstag und auf die Wochentage wie folgt: ${insertTemplateVariable(
       verteilungArbeitszeitWochentage
     )}. Der Arbeitgeber ist berechtigt, die Verteiliung der Arbeitszeit unter Einhaltung einer Ankündigungsfrist von einem Monat zu ändern, wenn betriebliche Gründe dafür vorliegen und das betriebliche Interesse an der Änderung das Interesse von ${insertTemplateVariable(
    nameArbeitnehmer
  )} an der Beibehaltung erheblich überwiegt. </p>

      <p> (3) ${insertTemplateVariable(
        nameArbeitnehmer
      )} verpflichtet sich, auf Anforderung gegebenenfalls auch Über- und Mehrarbeit sowie Wochenend-, Sonn- und Feiertagsarbeit im jeweils gesetzlich zulässigen Rahmen zu leisten.</p></li>

  <li><p><strong>Vergütung</strong>

    <p>(1) ${insertTemplateVariable(
      nameArbeitnehmer
    )} erhält für seine Tätigkeit ein Monatsentgelt in Höhe von ${insertTemplateVariable(
    vergütungArbeitnehmer
  )} brutto, das am Ende eines jeden Monats nach Abzug der gesetzlichen Steuern und Abgaben auf ein von ${insertTemplateVariable(
    nameArbeitnehmer
  )} zu benennendes Konto überwiesen wird. Eine teilbare Zusatzleistung steht ${insertTemplateVariable(
    nameArbeitnehmer
  )}  anteilig entsprechend dem Verhältnis seiner Arbeitszeit zu der eines Vollzeitarbeitnehmers zu.</p>

    <p> (2) Leistet der Arbeitgeber über das in Abs. 1 genannte Monatsentgelt hinaus Gratifikationen, Boni oder sonstige zusätzliche Sonderzahlungen, die nicht zuvor individuell vereinbart worden sind, handelt es sich um freiwillige Leistungen des Arbeitgebers aufgrund einer jeweils gesondert getroffenen Entscheidung. Ein Rechtsanspruch auf die wiederholte oder dauerhafte Gewährung derartiger freiwilliger Leistungen für die Zukunft entsteht auch nach mehrmaliger vorbehaltsloser Zahlung nicht. Die Sätze 1 und 2 gelten nicht, wenn die Leistungen auf einer individuellen Vertragsabrede mit ${insertTemplateVariable(
      nameArbeitnehmer
    )} beruhen. </p>

     <p> (3) Mit den in Abs. 1 genannten Bezügen sind sämtliche Tätigkeiten von ${insertTemplateVariable(
       nameArbeitnehmer
     )} aus diesem Vertrag inklusive Über- und Mehrarbeit abgegolten, soweit die Über- und Mehrarbeit monatlich ${insertTemplateVariable(
    grenzeMitabgegolteneÜberstunden
  )} Stunden nicht überschreitet, und soweit durch die Abgeltung der gesetzliche Mindestlohn nicht unterschritten wird. Zuschläge für Über- und Mehrarbeit sowie Wochenend-, Sonn- oder Feiertagsarbeit werden nicht gezahlt, sie sind in den Bezügen mit enthalten.</p></li>

  <li><p><strong>Urlaub</strong>

    <p>(1) ${insertTemplateVariable(
      nameArbeitnehmer
    )} hat Anspruch auf einen Jahresurlaub von ${insertTemplateVariable(
    urlaubstageAnzahl
  )} Arbeitstagen pro Kalenderjahr, der möglichst zusammenhängend zu nehmen ist.</p>

    <p>(2) ${insertTemplateVariable(
      nameArbeitnehmer
    )} kann Urlaub erst dann antreten, wenn dieser in der jeweils vom Arbeitgeber vorgeschriebenen Form genehmigt worden ist.</p></li>

  <li><p><strong>Arbeitsverhinderung</strong>

    <p>(1) Im Falle einer Arbeitsverhinderung ist ${insertTemplateVariable(
      nameArbeitnehmer
    )} verpflichtet, diese vor bzw. bei Beginn der Arbeitszeit seinem Vorgesetzten oder der Personalabteilung telefonisch oder per E-Mail mitzuteilen.</p>

    <p>(2) Vor Ablauf des dritten Tages der Arbeitsunfähigkeit ist ein ärztliches Attest über die Arbeitsunfähigkeit vorzulegen. Aus diesem soll sich auch die voraussichtliche Dauer der Arbeitsunfähigkeit ergeben. Dauert die Arbeitsunfähigkeit über die angegebene Zeit hinaus, hat ${insertTemplateVariable(
      nameArbeitnehmer
    )} den Arbeitgeber unverzüglich zu unterrichten und unverzüglich ein Anschlussattest vorzulegen. Der Arbeitgeber kann im Einzelfall auch eine frühere Vorlage des Attests verlangen.</p></li>

  <li><p><strong>Geheimhaltung</strong>

    <p>${insertTemplateVariable(
      nameArbeitnehmer
    )} verpflichtet sich, Geschäftsgeheimnisse26 sowie betriebliche Angelegenheiten vertraulicher Natur, die als solche von der Geschäftsleitung schriftlich oder mündlich bezeichnet werden bzw. offensichtlich als solche zu erkennen sind, geheim zu halten und ohne ausdrückliche Genehmigung der Geschäftsführung keinen dritten Personen zugänglich zu machen. ${insertTemplateVariable(
    nameArbeitnehmer
  )} wird die allgemeinen und speziellen Anweisungen und Maßnahmen des Arbeitgebers zur Geheimhaltung beachten. Die Verpflichtung zur Geheimhaltung gilt auch über die Beendigung des Arbeitsverhältnisses hinaus fort.</p></li>

  <li><p><strong>Herausgabepflicht</strong>

    <p>${insertTemplateVariable(
      nameArbeitnehmer
    )} verpflichtet sich, bei Beendigung des Arbeitsverhältnisses unaufgefordert und ansonsten jederzeit auf Anforderung des Arbeitgebers sämtliche ihm überlassenen oder von ihm für das Unternehmen gefertigten Schriftstücke oder sonstige Gegenstände des Arbeitgebers an diesen unverzüglich herauszugeben.</p> </p></li>

  <li><p><strong>Schriftformerfordernis</strong>

    <p>(1) Änderungen, Ergänzungen und die Aufhebung dieses Vertrags bedürfen zu ihrer Wirksamkeit der Schriftform. Dies gilt auch für die Änderung dieser Schriftformklausel selbst. Ausgeschlossen sind damit insbesondere Vertragsänderungen durch betriebliche Übung. Das vorstehende Schriftformerfordernis findet keine Anwendung bei Abreden, die nach Vertragsschluss unmittelbar zwischen den Parteien mündlich getroffen werden.</p>

    <p>(2) Mündliche Nebenabreden bestehen nicht.</p></li>

  <li><p><strong> Salvatorische Klausel</strong>

    <p>(1) Sollten einzelne Bestimmungen dieses Vertrags ganz oder teilweise unwirksam sein oder werden, bleibt die Wirksamkeit der übrigen Bestimmungen unberührt.</p>

    <p>(2) Die Vertragsparteien sind im Falle einer unwirksamen Bestimmung verpflichtet, über eine wirksame und zumutbare Ersatzregelung zu verhandeln, die dem von den Vertragsparteien mit der unwirksamen Bestimmung verfolgten wirtschaftlichen Zweck möglichst nahe kommt.</p> </p></li></ol>
      <br/>
  <br/>
  <p>
  ___________________________________<br/>
  Ort, Datum
  </p>
  <br/>
  <br/>
  <p>
  ___________________________________<br/>
  Unterschrift ${insertTemplateVariable(nameArbeitnehmer)}
    </p>`,
  begründung: undefined,
  templateVariables: [
    nameArbeitnehmer,
    geburtsdatumArbeitnehmer,
    adresseArbeitnehmer,
    tätigkeitArbeitnehmer,
    tätigkeitsortArbeitnehmer,
    arbeitsverhältnisBeginn,
    dauerProbezeitMonate,
    dauerArbeitszeitStunden,
    verteilungArbeitszeitWochentage,
    vergütungArbeitnehmer,
    grenzeMitabgegolteneÜberstunden,
    urlaubstageAnzahl,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
