import useInView from "react-cool-inview";
import { Underline } from "../../../__generated__/svgComponents/Underline";
import { VTSAnimatedUnderlineProps } from "./VTSAnimatedUnderlineInterfaces";
import { useVTSAnimatedUnderlineStyles } from "./VTSAnimatedUnderlineStyles";

export const VTSAnimatedUnderline = (
  props: VTSAnimatedUnderlineProps
): JSX.Element => {
  const { observe, inView, scrollDirection } = useInView<HTMLSpanElement>({
    threshold: 1, // element must be fully visible
    unobserveOnEnter: true,
  });

  const classes = useVTSAnimatedUnderlineStyles({
    elementIsInView: inView,
    scrollingDown: scrollDirection.vertical === "up",
  });

  return (
    <span ref={observe} className={classes.wrapper}>
      <Underline className={classes.underline} />

      {props.children && props.children}
    </span>
  );
};
