import { Link, Tooltip, Typography } from "@material-ui/core";
import { clsx } from "clsx";
import { motion } from "framer-motion";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { multilineTextFragment } from "../../../../helpers/clientSide/multilineTextFragment";
import { templateVariableKey } from "../../../../helpers/general/templates/templateVariableKey";
import { useUserInputOnCurrentVorlage } from "../../../../state/beschlussVorlage/beschlussVorlageSlice";
import { emptyUserInputValue } from "../beschlussGeneratorConstants";
import {
  BeschlussGeneratorState,
  EphemeralUserInputContext,
} from "../ephemeralUserInput/EphemeralUserInputContext";
import { TemplateVariableRenderedProps } from "./TemplateVariableRenderedInterfaces";
import { useTemplateVariableRenderedStyles } from "./TemplateVariableRenderedStyles";

/**
 * A template variable that is rendered visually.
 *
 * It is usually embedded in the full template, giving the user
 *  a live impression of the final document.
 */
const TemplateVariableRendered = (
  props: TemplateVariableRenderedProps
): JSX.Element => {
  const { variable } = props;
  const userInput = useUserInputOnCurrentVorlage();

  const variableInputValue =
    userInput?.[templateVariableKey(variable)] || emptyUserInputValue;

  const key = templateVariableKey(variable);

  const { t } = useTranslation();
  const ariaLabel = t("general.editSomething", { thing: variable.name });

  const { currentlyFocusedVariable, externallyFocusVariable, generatorState } =
    useContext(EphemeralUserInputContext);

  const onClick = useCallback(
    () => externallyFocusVariable(key),
    [externallyFocusVariable, key]
  );

  const hasInput = variableInputValue !== emptyUserInputValue;
  const matchingInputIsFocused = currentlyFocusedVariable === key;

  const classes = useTemplateVariableRenderedStyles();

  /*
   * What's being rendered when there's no value:
   * Just the variable name during editing, otherwise an actual placeholder.
   */
  const variablePlaceholder =
    generatorState === BeschlussGeneratorState.UserInput ? (
      variable.name
    ) : (
      <span className="inputPlaceholder">
        {`_________________ (${variable.name})`}
      </span>
    );

  const valueToShow = hasInput
    ? multilineTextFragment(...variableInputValue.split("\n"))
    : variablePlaceholder;

  return (
    <Tooltip
      aria-label={ariaLabel}
      title={matchingInputIsFocused ? "" : t("general.edit")}
      arrow={true}
    >
      <Link
        aria-label={ariaLabel}
        component="button"
        className={classes.button}
        onClick={onClick}
      >
        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 1 }}>
          <Typography
            className={clsx(
              hasInput && classes.beschlussVariableFilled,
              matchingInputIsFocused && classes.beschlussVariableFocused,
              generatorState === BeschlussGeneratorState.Generating
                ? classes.beschlussVariableGenerating
                : "",
              classes.beschlussVariable
            )}
            variant="body1"
          >
            {valueToShow}
          </Typography>
        </motion.div>
      </Link>
    </Tooltip>
  );
};

export default TemplateVariableRendered;
