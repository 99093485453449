import {
  ausnutzungFrist,
  bankDatenGesellschaft,
  genehmigtesKapitalMaximum,
  neueGeschäftsanteileAnzahl,
  neueGeschäftsanteileAusgabebetrag,
  neueGeschäftsanteileBetrag,
  satzungErmächtigung,
  satzungStammkapital,
  stammkapitalBetragNachErhöhung,
  stammkapitalBetragNachErhöhungInWorten,
  stammkapitalErhöhungsbetrag,
  tagBeginnGewinnberechtigung,
  übernahmeFrist,
  übernahmeNachfristAndereGesellschafter,
  übernahmeZugelasseneGesellschafter,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageAusnutzungGenehmigtesKapitalBareinlagen: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.kapital,
    vorlage_rolleTyp: [RolleInGesellschaft.Geschftsleiter],
    vollzug: [
      Vollzug.handelsregisterAnmeldung,
      Vollzug.handelsregisterGesellschafterliste,
      Vollzug.transparenzregister,
      Vollzug.kapErhöhung_abgabeÜbernahmeerklärungen,
    ],
    bezug: [],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung:
      "Vollständige Ausnutzung genehmigten Kapitals gegen Bareinlagen",
    description:
      "Die Vorlage geht davon aus, dass das genehmigte Kapital vollständig ausgenutzt werden soll.",
    antrag: `<ol><li><p>Grundlage dieses Beschlusses ist ${insertTemplateVariable(
      satzungErmächtigung
    )} des Gesellschaftsvertrags, wonach die Geschäftsführung ermächtigt ist, das Stammkapital der Gesellschaft bis zum ${insertTemplateVariable(
      ausnutzungFrist
    )} durch Ausgabe von neuen Geschäftsanteilen gegen Bareinlagen um bis zu ${insertTemplateVariable(
      genehmigtesKapitalMaximum
    )} zu erhöhen.</p></li>

  <li><p>Das Stammkapital der Gesellschaft wird gegen Bareinlagen von insgesamt ${insertTemplateVariable(
    stammkapitalErhöhungsbetrag
  )} um ${insertTemplateVariable(
      stammkapitalErhöhungsbetrag
    )} auf ${insertTemplateVariable(
      stammkapitalBetragNachErhöhung
    )} durch Ausgabe von ${insertTemplateVariable(
      neueGeschäftsanteileAnzahl
    )} neuen Geschäftsanteilen im Nennbetrag von jeweils ${insertTemplateVariable(
      neueGeschäftsanteileBetrag
    )} erhöht.</p></li>

  <li><p>Der Ausgabebetrag beträgt ${insertTemplateVariable(
    neueGeschäftsanteileAusgabebetrag
  )} je Geschäftsanteil.</p></li>

  <li><p>Die neuen Geschäftsanteile sind ab ${insertTemplateVariable(
    tagBeginnGewinnberechtigung
  )} gewinnberechtigt.</p></li>

  <li><p>Die Bareinlagen nebst Aufgeld sind sofort nach formgerechter Unterzeichnung der Übernahmeerklärung in voller Höhe zur Einzahlung fällig und auf das Konto der Gesellschaft ${insertTemplateVariable(
    bankDatenGesellschaft
  )} einzuzahlen.</p></li>

  <li><p>Zur Übernahme der neuen Geschäftsanteile werden die Gesellschafter wie folgt zugelassen: ${insertTemplateVariable(
    übernahmeZugelasseneGesellschafter
  )}.

  <p>Die Gesellschafter werden aufgefordert, die neuen Anteile bis zum ${insertTemplateVariable(
    übernahmeFrist
  )}  zu übernehmen. Nicht übernommene Anteile werden den anderen Gesellschaftern innerhalb von weiteren ${insertTemplateVariable(
      übernahmeNachfristAndereGesellschafter
    )}  zur Übernahme angeboten. Sollten auch diese die Anteile nicht übernehmen wollen, behält sich die Geschäftsführung vor, auch außenstehende Dritte zur Übernahme zuzulassen.</p></li>

  <li><p>Gemäß der Ermächtigung in ${insertTemplateVariable(
    satzungErmächtigung
  )} des Gesellschaftsvertrags beschließt die Geschäftsführung weiter, dass aufgrund der vollständigen Ausnutzung des genehmigten Kapitals mit der Eintragung der Durchführung der Kapitalerhöhung im Handelsregister ${insertTemplateVariable(
      satzungErmächtigung
    )} des Gesellschaftsvertrags (Genehmigtes Kapital)  gestrichen wird und ${insertTemplateVariable(
      satzungStammkapital
    )} des Gesellschaftsvertrags (Stammkapital) folgende Fassung erhält:

    <dl><dd><p style="text-align:center">„${insertTemplateVariable(
      satzungStammkapital
    )} Stammkapital</p>

    <p>Das Stammkapital der Gesellschaft beträgt ${insertTemplateVariable(
      stammkapitalBetragNachErhöhung
    )} – in Worten: ${insertTemplateVariable(
      stammkapitalBetragNachErhöhungInWorten
    )}."</p></dd></dl></p></li></ol>`,
    begründung: undefined,
    templateVariables: [
      satzungErmächtigung,
      satzungStammkapital,
      ausnutzungFrist,
      genehmigtesKapitalMaximum,
      stammkapitalErhöhungsbetrag,
      stammkapitalBetragNachErhöhung,
      stammkapitalBetragNachErhöhungInWorten,
      neueGeschäftsanteileAnzahl,
      neueGeschäftsanteileBetrag,
      neueGeschäftsanteileAusgabebetrag,
      tagBeginnGewinnberechtigung,
      bankDatenGesellschaft,
      übernahmeZugelasseneGesellschafter,
      übernahmeFrist,
      übernahmeNachfristAndereGesellschafter,
    ],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: true,
    noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss (§ 57 Abs. 1 i.V.m. § 78 GmbHG). Die Handelsregisteranmeldung muss von sämtlichen Geschäftsführern unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
  });
