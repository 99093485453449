import { Typography } from "@material-ui/core";
import SignaturePlaceholder from "../../../../components/01-atoms/SignaturePlaceholder/SignaturePlaceholder";
import TemplateVariableRendered from "../../../../components/04-templates/BeschlussGenerator/TemplateVariableRendered/TemplateVariableRendered";
import { GrCase } from "../../../../interfaces/GrCase";
import { GrNumber } from "../../../../interfaces/grNumber";
import { useCurrentVorlage } from "../../../../state/beschlussVorlage/beschlussVorlageSlice";
import { useCompanyInfo } from "../../../../state/companyInfo/companyInfoSlice";
import {
  datumEinladung,
  vollversammlungDatum,
  vollversammlungEinladenderName,
  vollversammlungEinladenderRolle,
  vollversammlungOrt,
  vollversammlungUhrzeit,
} from "../../../vorlagen/vorlagenShared/allTemplateVariables";
import { getRoleName } from "../../namesForRoles/getRoleName";
import {
  VotingMode,
  VotingModeIdentifier,
  VotingModeSwitchKey,
} from "../votingModeInterfaces";
import { useCoatingInformation } from "./useCoatingInformation";
import { useVotingModeStyles } from "./votingModeStyles";

/**
 * Multiple people, controversial decision
 *
 * "Förmliche Gesellschafterversammlung"
 */
export const votingModeFormalPlenum: VotingMode = {
  identifier: VotingModeIdentifier.FormalPlenum,
  CoatingHeader: () => {
    const classes = useVotingModeStyles();
    const { firma, sitz, rechtsform, whoCanDecide, articleGenitive, isVerein } =
      useCompanyInfo();
    const volage = useCurrentVorlage();
    const coatingInformationSing = useCoatingInformation(GrNumber.Singular);

    const whoGenitive = getRoleName(
      whoCanDecide,
      rechtsform,
      GrNumber.Plural,
      GrCase.Genitive
    );

    const whoNominative = getRoleName(
      whoCanDecide,
      rechtsform,
      GrNumber.Plural
    );

    const whoNominativeSingular = getRoleName(
      whoCanDecide,
      rechtsform,
      GrNumber.Singular
    );
    const whoCompoundForm = getRoleName(
      whoCanDecide,
      rechtsform,
      GrNumber.Singular,
      GrCase.CompoundForm
    );

    if (
      !whoNominative ||
      !whoGenitive ||
      !rechtsform ||
      !whoNominativeSingular ||
      !whoCompoundForm
    ) {
      return null;
    }

    if (!coatingInformationSing) {
      return null;
    }

    return (
      <div className={classes.wrapper}>
        <Typography variant="body1" className={classes.highlight}>
          Übergabe-Einschreiben
        </Typography>

        <Typography variant="body1" className={classes.marginBottom}>
          {`An die ${whoNominative} der ${firma}`}
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.alignRight} ${classes.marginBottomDouble}`}
        >
          <TemplateVariableRendered variable={datumEinladung} />
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.highlight} ${classes.marginBottom}`}
        >
          {`Einberufung der ${whoCompoundForm}${coatingInformationSing.meetingNameSingNomNoncap} ${articleGenitive} ${firma} mit Sitz in ${sitz} `}
        </Typography>

        <Typography variant="body1" className={classes.marginBottom}>
          {`Sehr geehrter ${whoNominativeSingular},`}
        </Typography>

        <Typography variant="body1">
          {`als `}

          <TemplateVariableRendered
            variable={vollversammlungEinladenderRolle}
          />

          {` ${articleGenitive} ${firma} mit Sitz in ${sitz} - nachfolgend auch ${
            isVerein ? '"Verein"' : '"Gesellschaft"'
          } genannt - lade ich Sie zu einer ${whoCompoundForm}${
            coatingInformationSing.meetingNameSingNomNoncap
          } ein`}
        </Typography>

        <Typography variant="body1" component="div">
          <ul>
            <li>
              <p>
                {"am "}

                <TemplateVariableRendered variable={vollversammlungDatum} />
              </p>
            </li>

            <li>
              <p>
                {"um "}

                <TemplateVariableRendered variable={vollversammlungUhrzeit} />
              </p>
            </li>

            <li>
              <p>
                {"in "}

                <TemplateVariableRendered variable={vollversammlungOrt} />
              </p>
            </li>
          </ul>
        </Typography>

        <Typography variant="body1" className={classes.marginBottomDouble}>
          Die Tagesordnung lautet:
        </Typography>

        <Typography variant="h4" className={classes.marginBottom}>
          TOP 1: {volage?.titleInDocument || volage?.kurzBezeichnung}
        </Typography>

        <Typography variant="body1">
          Der folgende Beschlussantrag wird zur Abstimmung gestellt:
        </Typography>
      </div>
    );
  },
  CoatingFooter: () => {
    const classes = useVotingModeStyles();
    const { whoCanDecide, rechtsform } = useCompanyInfo();
    const who = getRoleName(whoCanDecide, rechtsform, GrNumber.Plural);

    if (!who) {
      return null;
    }

    return (
      <div className={classes.wrapper}>
        <Typography variant="h4" className={classes.marginBottomDouble}>
          TOP 2: Verschiedenes
        </Typography>

        <Typography variant="body1" component="div">
          Freundliche Grüße
          <SignaturePlaceholder>
            <TemplateVariableRendered
              variable={vollversammlungEinladenderName}
            />

            <br />

            <TemplateVariableRendered
              variable={vollversammlungEinladenderRolle}
            />
          </SignaturePlaceholder>
        </Typography>
      </div>
    );
  },
  label: (t) => t("votingMode.formalPlenum"),
  additionalInputs: [
    datumEinladung,
    vollversammlungEinladenderRolle,
    vollversammlungEinladenderName,
    vollversammlungDatum,
    vollversammlungUhrzeit,
    vollversammlungOrt,
  ],
  switchValues: {
    [VotingModeSwitchKey.AreMultiplePeopleInvolved]: true,
    [VotingModeSwitchKey.IsUnanimous]: false,
    [VotingModeSwitchKey.DoPeopleMeetInPerson]: null,
  },
};
