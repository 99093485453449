// tslint:disable
/**
 * Resolvio API
 * This is the API documentation for the Resolvio API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: team@resolvio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum CompanyTypeName {
  AgDe = "AG__DE",
  ArDe = "AöR__DE",
  AssociationFr = "Association__FR",
  BenutzerdefinierteOrganisation = "Benutzerdefinierte Organisation__",
  BruchteilsgemeinschaftDe = "Bruchteilsgemeinschaft__DE",
  EGDe = "eG__DE",
  EigentmergemeinschaftAt = "Eigentümergemeinschaft__AT",
  ErbengemDe = "Erbengem.__DE",
  EVDe = "eV__DE",
  EwivDe = "EWIV__DE",
  FondationsDentrepriseFr = "Fondations d’entreprise__FR",
  FondDeDotationFr = "Fond de dotation__FR",
  GbRDe = "GbR__DE",
  GGmbHDe = "gGmbH__DE",
  GmbHDe = "GmbH__DE",
  GmbHCoKgDe = "GmbH & Co. KG__DE",
  GUgDe = "gUG__DE",
  IncUs = "Inc.__US",
  KgDe = "KG__DE",
  KgaADe = "KGaA__DE",
  LlcUs = "LLC__US",
  NeVDe = "neV__DE",
  OHgDe = "oHG__DE",
  PartGDe = "PartG__DE",
  PartGmbBDe = "PartGmbB__DE",
  SaFr = "SA__FR",
  SarlFr = "SARL__FR",
  SasFr = "SAS__FR",
  ScaFr = "SCA__FR",
  SccvFr = "SCCV__FR",
  SceDe = "SCE__DE",
  SceaFr = "SCEA__FR",
  SciFr = "SCI__FR",
  ScmFr = "SCM__FR",
  ScpFr = "SCP__FR",
  ScpiFr = "SCPI__FR",
  ScsFr = "SCS__FR",
  SeFr = "SE__FR",
  SeDe = "SE__DE",
  SelFr = "SEL__FR",
  SncFr = "SNC__FR",
  SocitDassuranceMutuellesFr = "Société d'assurance mutuelles__FR",
  StiftungDe = "Stiftung__DE",
  SyndicatDeCopropritairesFr = "Syndicat de copropriétaires__FR",
  UgDe = "UG__DE",
  UgCoKgDe = "UG & Co. KG__DE",
  VvaGDe = "VVaG__DE",
  WegDe = "WEG__DE",
  ZweckverbandDe = "Zweckverband__DE",
}
