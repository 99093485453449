import { createStyles, makeStyles } from "@material-ui/core";
import { important } from "@nvon/react-toolbox";

export const useAppPromotionContentStyles = makeStyles((theme) => {
  return createStyles({
    actions: {
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        "& button, & a": {
          width: "100%",
          marginLeft: important(0),
        },
      },
    },
  });
});
