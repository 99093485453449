import {
  bankDatenGesellschaft,
  darlehenBetragWort,
  darlehenBetragZahl,
  darlehenSicherheiten,
  darlehenTagRückzahlung,
  jahreszinsProzentpunkteÜberBasiszinssatz,
  nameGesellschafterDarlehensgeber,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";
import { vorlageGesellschafterKapitalrucklage } from "./vorlageGesellschafterKapitalrucklage";
import { vorlageKapitalerhohung_ } from "./vorlageKapitalerhohung_";
export const vorlageGesellschafterDarlehenGewahrung: LegacyTemplateBase = ({
  insertTemplateVariable,
  insertVorlagenLink,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.kapital,
  vorlage_rolleTyp: [RolleInGesellschaft.Geschftsleiter],
  vollzug: [Vollzug.zustimmungBetroffener],
  bezug: [],
  tags: [
    "Darlehen",
    "Darlehn",
    "Kredit",
    "Pump",
    "Finanzierung",
    "Zins",
    "Kapital",
    "Liquidität",
    "Risiko",
    "Venture Capital",
    "Bonität",
    "Einzahlen",
    "Privatvermögen",
    "Gesellschafterdarlehen",
    "Gesellschafterdarlehn",
    "Verzinsung",
    "Mittelbeschaffung",
    "Fazilität",
    "Kreditlinie",
    "Kreditrahmen",
    "Mittel",
    "Geld",
    "sanierung",
    "loan",
    "allowance",
    "credit",
    "inveestment",
    "invest",
    "mortage",
    "risk",
    "money",
    "capital",
    "kapitalerhöhung",
    "kapitalrücklage",
    "rücklage",
    "eigenkapital",
    "krise",
    "fremdkapital",
    "Gesellschafter",
    "Mitglied",
    "Anteilsinhaber",
    "shareholder",
    "Partner",
    "Teilhaber",
    "Socius",
    "shareowner",
    "bondholder",
  ],
  zustimmungErforderlichDes: "darlehensgebenden Gesellschafters",
  kurzBezeichnung: "Gewährung eines Gesellschafterdarlehens",
  metaDescription:
    "Gesellschafterdarlehen an Gesellschaft geben. Muster/Vorlagen von Rechtsanwälten und Steuerberatern juristisch geprüft. Jetzt kostenlos nutzen.",
  description: `
  <p>
    Die Gewährung eines Gesellschafterdarlehens ist eine Möglichkeit für die Gesellschafter, um ihre GmbH  mit <strong>liquiden Mitteln</strong> auszustatten.
  </p>

  <p>
  Weitere Möglichkeiten sind:
  </p>

  <p>
    <ul data-list-style="square">
      <li><p>${insertVorlagenLink(
        vorlageGesellschafterKapitalrucklage
      )},</p></li>
      <li><p>${insertVorlagenLink(
        vorlageKapitalerhohung_,
        "Kapitalerhöhung"
      )}.</p></li>
    </ul>
  </p>
  `,
  antrag: `Die Gesellschaft, vertreten durch die Geschäftsführung, - nachfolgend Darlehensnehmerin genannt - schließt mit

  <p>${insertTemplateVariable(nameGesellschafterDarlehensgeber)}</p>

  <p>einen</p>

  <h2>Darlehensvertrag</h2>

  <p>mit dem folgenden Inhalt ab:</p>

  <ol data-list-style="decimal" data-list-style-prefix="§ ">
   <li><p>
      <h3>Darlehensgewährung</h3>
      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>${insertTemplateVariable(
          nameGesellschafterDarlehensgeber
        )} gewährt hiermit der Darlehensnehmerin ein Darlehen in Höhe von  ${insertTemplateVariable(
    darlehenBetragZahl
  )} € (in Worten: ${insertTemplateVariable(darlehenBetragWort)} ).</p></li>

  <li><p> Die Auszahlung des Darlehens erfolgt binnen fünf Werktagen nach Erhalt der vereinbarten Sicherheiten auf das folgende Konto der Gesellschaft:  ${insertTemplateVariable(
    bankDatenGesellschaft
  )}.</p></li>
        </ol>
  </p></li>

  <li><p>
   <h3>Darlehenszins</h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
          <li><p>
           Der Zinssatz für das in Anspruch genommene Darlehen beträgt jährlich ${insertTemplateVariable(
             jahreszinsProzentpunkteÜberBasiszinssatz
           )}.</p>
          </p></li>

          <li><p>${insertTemplateVariable(
            nameGesellschafterDarlehensgeber
          )} ist berechtigt, den Zinssatz zu erhöhen und herabzusetzen, wenn sich die Verhältnisse am Geld- oder Kapitalmarkt erheblich ändern. Die Erhöhung oder Herabsetzung wird der Darlehensgeber nach billigem Ermessen gemäß § 315 BGB unter Berücksichtigung der Veränderungen am Geld- oder Kapitalmarkt vornehmen.</p></li>

          <li><p>Zinsen sind monatlich fällig und zahlbar.</p></li>
        </ol>
  </p></li>

<li><p> <h3>Rückzahlung</h3>
      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>Das Darlehen ist am ${insertTemplateVariable(
          darlehenTagRückzahlung
        )} zurückzuzahlen.</p></li>

<li><p>Die Darlehensnehmerin ist zur vorzeitigen Rückzahlung des Darlehens jederzeit berechtigt.</p></li>
  </ol>
</p></li>

<li><p>
  <h3>Sicherheiten</h3>
      <ol data-list-style="none" >
        <li><p>
<p>Die Darlehensnehmerin stellt zur Absicherung des Darlehens die folgenden Sicherheiten: ${insertTemplateVariable(
    darlehenSicherheiten
  )}.</p></li>
  </ol>
 </p></li>
<li><p>
  <h3>Kündigung</h3>
      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>
          Das Darlehen ist unter Vorbehalt der Kündigungsrechte gemäß §§ 489, 490 BGB beiderseits kündbar.</p>
        </p></li>
        <li><p></p>
          Die Kündigung bedarf der Schriftform.</p>
        </p></li>
      </ol>
</p></li>

<li><p>
 <h3>Schlussbestimmungen</h3>
      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>
          Sollte eine der vorstehenden Bestimmungen unwirksam sein oder werden, so wird die Wirksamkeit der übrigen Bestimmungen dieses Vertrags hierdurch nicht berührt. An Stelle der unwirksamen Bestimmung gilt eine solche als vereinbart, die im Rahmen des rechtlich möglichen dem am nächsten kommt, was von den Vertragsparteien nach dem ursprünglichen Sinn und Zweck dieses Vertrags gewollt war. Diese Regelung gilt entsprechend für etwaige Lücken dieses Vertrags.</p>
        </p></li>

        <li><p>
          Änderungen, Ergänzungen und die Aufhebung dieses Vertrags bedürfen zu ihrer Wirksamkeit der Schriftform. Dies gilt auch für die Änderung dieser Schriftformklausel selbst. Ausgeschlossen sind damit insbesondere Vertragsänderungen durch betriebliche Übung. Das vorstehende Schriftformerfordernis findet keine Anwendung bei Abreden, die nach Vertragsschluss unmittelbar zwischen den Parteien mündlich getroffen werden.</p>
        </p></li>

        <li><p>
          Mündliche Nebenabreden bestehen nicht.</p>
        </p></li>
      </ol>
</p></li>
</ol>`,
  begründung: undefined,
  templateVariables: [
    nameGesellschafterDarlehensgeber,
    darlehenBetragZahl,
    darlehenBetragWort,
    bankDatenGesellschaft,
    jahreszinsProzentpunkteÜberBasiszinssatz,
    darlehenTagRückzahlung,
    darlehenSicherheiten,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
