import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { headerHeight } from "../../../helpers/clientSide/constants";

export const useRVOFootnoteStyles = makeStyles((theme: Theme) =>
  createStyles({
    footnoteWrapper: {
      paddingTop: headerHeight,
      marginTop: -headerHeight,
    },
    footnoteBox: {
      border: `0.5px solid ${theme.palette.secondary.dark}`,
      borderRadius: "8px",
      margin: "2px 0px 4px 0px",
      padding: 0,
      zIndex: 2,
      overflow: "hidden",
      display: "inline-block",
    },

    footnote: {
      marginRight: "12px",
      marginTop: "0px",
      marginBottom: "0px",
      counterIncrement: "item",
      zIndex: 1,
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      gap: 0,
      fontSize: theme.typography.body2.fontSize,
      lineHeight: theme.typography.body2.lineHeight,
      background: theme.palette.secondary.main,
      "&:before": {
        flexDirection: "column",
        zIndex: 1,
        content: "counter(item)",
        color: theme.palette.primary.light,
        fontWeight: "bold",
        padding: "8px",
        marginRight: "8px",
        background: theme.palette.secondary.main,
        display: "flex",
      },
    },
    footnoteInlet: {
      background: theme.palette.common.white,
      padding: "8px",
    },
  })
);
