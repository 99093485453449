import { createStyles, makeStyles } from "@material-ui/core";

export const useRVOChatBubblePlaceholderStyles = makeStyles((theme) =>
  createStyles({
    bubble: {
      position: "fixed",
      width: 60,
      zIndex: 10,
      height: 60,
      bottom: theme.spacing(1),
      right: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        right: theme.spacing(1),
      },
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    icon: {
      "& svg": {
        fontSize: "2rem",
      },
    },
  })
);
