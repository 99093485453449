import {
  Box,
  DialogActions,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Button } from "gatsby-material-ui-components";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { resolvioAppRegisterUrl } from "../../../helpers/clientSide/constants";
import {
  RVOTrackingEvent,
  trackEvent,
} from "../../00-globals/TruendoGoogleTagManager/trackEvent";
import { useAppPromotionContentStyles } from "./RVOAppPromotionContentStyles";

export interface RVOAppPromotionContentProps {
  /**
   * If you provide a callback, the overlay will be closed when the user clicks on the "skip" button.
   * If you don't provide a callback, the "skip" button will not be rendered.
   *
   */
  closeOverlayCallback?: VoidFunction;
}

export const handleRegisterButtonClicked = (): void => {
  trackEvent(RVOTrackingEvent.RegisterOnAppClicked);
};

export const handleLoginButtonClicked = (): void => {
  trackEvent(RVOTrackingEvent.LoginOnAppClicked);
};

export const RVOAppPromotionContent = ({
  closeOverlayCallback,
}: RVOAppPromotionContentProps): JSX.Element => {
  const classes = useAppPromotionContentStyles();
  const { t } = useTranslation();

  const listItemFacts = t("generatorAppPromotionOverlay.listItemFacts", {
    returnObjects: true,
  });

  const handleCloseOverlay = useCallback(() => {
    if (closeOverlayCallback) {
      closeOverlayCallback();
    }
    trackEvent(RVOTrackingEvent.SkipPromotionClicked);
  }, [closeOverlayCallback]);

  return (
    <>
      <Box>
        <Grid container={true} spacing={2}>
          <Grid item={true} xs={12} sm={6}>
            <Box>
              <img
                alt={t("generatorAppPromotionOverlay.imgAlt")}
                src="/app/screenshots/promo-screenshot.png"
                width="100%"
                height="auto"
              />
            </Box>
          </Grid>

          <Grid item={true} xs={12} sm={6}>
            <Typography variant="h3">
              {t("generatorAppPromotionOverlay.title")}
            </Typography>

            <List>
              {listItemFacts.map((fact) => (
                <ListItem key={fact}>
                  <ListItemIcon>
                    <CheckCircleIcon color="primary" />
                  </ListItemIcon>

                  <ListItemText primary={fact} />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Box>

      <DialogActions className={classes.actions}>
        {closeOverlayCallback && (
          <Button variant="outlined" onClick={handleCloseOverlay}>
            {t("generatorAppPromotionOverlay.actions.skip")}
          </Button>
        )}

        <Link target="_blank" href={resolvioAppRegisterUrl}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRegisterButtonClicked}
          >
            {t("generatorAppPromotionOverlay.actions.test")}
          </Button>
        </Link>
      </DialogActions>
    </>
  );
};
