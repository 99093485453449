import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";
import {
  praeventivbeauftragterGeburtsdatum,
  praeventivbeauftragterName,
  praeventivbeauftragterWohnort,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

export const vorlageVereinVorstandErnennungPraeventivbeauftragter: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.EVDe],
    vorlageKategorie: VorlageKategorie.anbauvereinigung,
    vorlage_rolleTyp: [RolleInGesellschaft.Geschftsleiter],
    vollzug: [Vollzug.zugang],
    bezug: [],
    tags: [
      "Verein",

      "eV",
      "e.V.",
      "Anbauvereinigung",
      "Anbauverein",
      "Cannabis Social Club",
      "Cannabis Club",
      "Cannabis Verein",
      "Cannabis e.V.",
      "Cannabis Social Club e.V.",
      "Cannabis Club e.V.",
      "Cannabis Verein e.V.",
      "CSC",
      "Präventionsbeauftragter",
      "Gesundheits- und Jugendschutzkonzept",
      "Suchtprävention",
      "Schulung",
    ],
    zustimmungErforderlichDes: "",
    kurzBezeichnung:
      "Ernennung des Präventionsbeauftragten gemäß Cannabisgesetz (Cannabis Social Club)",
    metaDescription:
      "Vorstandsbeschluss über die Ernennung des Präventionsbeauftragten in einer Anbauvereinigung als Verein gemäß Cannabisgesetz. Juristisch geprüfte Muster/Vorlagen kostenlos nutzen.",
    description: `
  <p>
    Dieses Muster enthält eine Vorlage für einen Vorstandsbeschluss für eine Anbauvereinigung als eingetragener Verein (e.V.). Der Vorstand beschließt die Ernennung des Präventionsbeauftragten gemäß § 23 Abs. 4 Cannabisgesetz.
  </p>
  <p>
    Die Ernennung des Präventionsbeauftragten ist ein wichtiger Schritt, um die behördliche Lizenz als Anbauvereinigung zu erhalten. Der Präventionsbeauftragte ist für die Erstellung und die Einhaltung des Gesundheits- und Jugendschutzkonzepts verantwortlich und stellt sicher, dass der Verein geeignete Maßnahmen zur Suchtprävention trifft.
  </p>
  <p>
      Vor der Ernennung musst der Präventionsbeauftragter eine Suchtpräventionsschulung bei einer geeigneten Einrichtung absolvieren. Als geeignete Einrichtungen kommen in Betracht: Landes- oder Fachstellen für Suchtprävention oder Suchtberatung oder vergleichbar qualifizierte öffentlich geförderte Einrichtungen.
  </p>
  `,
    antrag: `
<ol data-list-style="decimal" data-list-style-suffix=")">
    <li>
        <p>
            Der Vorstand des Vereins ernennt ${insertTemplateVariable(
              praeventivbeauftragterName
            )}, geboren am ${insertTemplateVariable(
      praeventivbeauftragterGeburtsdatum
    )}, wohnhaft in ${insertTemplateVariable(
      praeventivbeauftragterWohnort
    )}, als Präventivbeauftragte/n gemäß § 23 Abs. 4 Cannabisgesetz.
        </p>
    </li>
    <li>
        <p>
            ${insertTemplateVariable(
              praeventivbeauftragterName
            )} ist selbst Vereinsmitglied und hat gegenüber dem Verein nachgewiesen, dass dem Vorstand eine Bescheinigung der Teilnahme an einer  Suchtpräventionsschulungen bei einer der folgenden Einrichtungen erbracht: Landes- oder Fachstellen für Suchtprävention oder Suchtberatung oder bei einer vergleichbar qualifizierten öffentlich geförderten Einrichtung.
        </p>
    </li>
    <li>
        <p>
            Als Präventionsbeauftrage/r steht ${insertTemplateVariable(
              praeventivbeauftragterName
            )} den Mitgliedern des Vereins als Ansprechperson für Fragen der Suchtprävention zur Verfügung. Er/Sie stellt sicher, dass der Verein geeignete Maßnahmen zur Erreichung eines umfassenden Jugend- und Gesundheitsschutzes sowie zur Suchtprävention trifft. Insbesondere bringt der Präventionsbeauftragte seine Kenntnisse bei der Erstellung des Gesundheits- und Jugendschutzkonzepts nach § 23 Absatz 6 Cannabisgesetz ein und stellt dessen Umsetzung sicher.
        </p>
    </li>
  </ol>`,
    begründung: undefined,
    vollzugshinweise: `
    <p>
        <strong>Mitteilung an Präventionsbeauftragten</strong>: Damit die Ernennung wirksam wird, muss die Ernennung dem Präventionsbeauftragten mitgeteilt werden. Das kann am einfachsten durch die Übersendung des Vorstandsbeschlusses als Pdf-Datei per E-Mail erfolgen.
    </p>
    <p>
        <strong>Erstellung des Gesundheits- und Jugendschutzkonzept</strong>: Gemeinsam mit dem Präventionsbeauftragten erstellt der Vorstand das Gesundheits- und Jugendschutzkonzept. Dieses Konzept muss den Anforderungen des § 23 Absatz 6 Cannabisgesetz entsprechen und insbesondere Maßnahmen zu einem risikoreduzierten Konsum von Cannabis und Suchtprävention enthalten. Der Präventionsbeauftragte soll bei der Erstellung des Konzepts seine Kenntnisse aus der Suchtpräventionsschulung einbringen.
    </p>
    `,
    templateVariables: [
      praeventivbeauftragterName,
      praeventivbeauftragterGeburtsdatum,
      praeventivbeauftragterWohnort,
    ],
  });
