import {
  domainName,
  domainRegistrierungsstelle,
  domainWert,
  domainWertBegründung,
  gesellschafterPersönlicheAngaben,
  nameGesellschafter,
  nameGesellschafterVertragspartner,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGesellschafterEinbringungsvertragÜbertragungDomain: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.startup,
    vorlage_rolleTyp: [RolleInGesellschaft.Geschftsleiter],
    vollzug: [Vollzug.zustimmungGesellschafter],
    bezug: [],
    tags: [
      "Kapitalrücklage",
      "Rücklage",
      "Eigenkapital",
      "freie Rücklage",

      "Gesellschafter",
      "Mitglied",
      "Anteilsinhaber",
      "shareholder",
      "Partner",
      "Teilhaber",
      "Socius",
      "shareowner",
      "bondholder",

      "Domain",
      "URL",
      "TLD",
      "Internetadresse",
      "www",

      "Einbringung",
      "Übertragung",
      "Abtretung",
      "einbringen",
      "übertragen",
      "abtreten",

      "Startup",
    ],
    zustimmungErforderlichDes: "vertragsschließenden Gesellschafters",
    kurzBezeichnung: "Einbringung einer Domain (unentgeltlich)",
    metaDescription:
      "Gesellschafter überträgt Domain an Gesellschaft. Juristisch geprüfte Muster/Vorlagen für Einbringungsvertrag kostenlos nutzen.",
    description: `

  <p>
Diese Vorlage ist vor allem für <strong>Startups</strong> gedacht, bei denen ein Gründer noch vor der GmbH/UG-Gründung die Startup-Domain registriert hat. Diese Domain soll in aller Regel möglichst zeitnah auf die neue GmbH bzw. UG übertragen werden, weil sie regelmäßig einen wesentlichen Asset des Startups darstellt.
  </p>

  <p>
  Die Vorlage sieht die Domaineinbringung als <strong>unentgeltliche Sacheinlagenzuführung im Wege der Erhöhung der Kapitalrücklage</strong> vor. Alternativ hierzu kommt eine Sacheinlage gegen Ausgabe neuer Geschäftsanteile im Wege einer Sachkapitalerhöhung in Betracht. Hierbei müssen jedoch die strengen Formvorschriften der Sachkapitalerhöhung beachtet werden.
  </p>


  <p>
  Sofern die Domain aus dem Privatvermögen eines Gründers übertragen wird, kann es zu keiner Gewinnrealisierung beim einlegenden Gesellschafter kommen. Unerwünschte Steuerfolgen können jedoch auftreten, wenn die Domain aus einem Betriebsvermögens heraus übertragen wird. <strong>In Zweifelsfragen sollte ein Steuerberater hinzugezogen werden.</strong>
  </p>
  `,
    antrag: `
  <p>
    Die Gesellschaft, vertreten durch die Geschäftsführung,<br/>
    (nachfolgend auch "<strong>Gesellschaft</strong>" genannt)
  </p>

  <p>
    schließt mit
  </p>

  <p>
    <strong>${insertTemplateVariable(
      nameGesellschafterVertragspartner
    )}</strong>,<br/>
    ${insertTemplateVariable(gesellschafterPersönlicheAngaben)}
  </p>
  <p>
  - ${insertTemplateVariable(
    nameGesellschafterVertragspartner
  )} nachfolgend auch <strong>einbringender Gesellschafter</strong> genannt -
  </p>
  <p>
    einen
  </p>

  <h2>Domain-Einbringungsvertrag</h2>

  <p>mit dem folgenden Inhalt ab:</p>

  <ol data-list-style="decimal" data-list-style-prefix="§ ">
    <li>
      <h3>Vertragsgegenstand</h3>
      <p>
        Gegenstand dieses Vertrages ist die Domain</p>

        <p><strong>„${insertTemplateVariable(domainName)}”,</strong></p>

        <p>welche für den Verkäufer bei der Registrierungsstelle ${insertTemplateVariable(
          domainRegistrierungsstelle
        )} registriert ist, nachfolgend auch die <strong>„Domain”</strong> genannt.
        </p>
    </li>

    <li>
      <h3>Einbringung und Übertragung</h3>

      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>
         Der einbringende Gesellschafter bringt die Domain hiermit in die Gesellschaft ein einschließlich sämtlicher hierauf bezogener Rechte, insbesondere sämtlicher Rechte des einbringenden Gesellschafters gegen die Registrierungsstelle.
    </p></li>
    <li><p>
          Mit Abschluss dieses Vertrages gehen sämtliche Rechte des einbringenden Gesellschafters an der Domain unwiderruflich auf die Gesellschaft über. Der einbringende Gesellschafter tritt zu diesem Zwecke sämtliche Rechte an der Domain an die Gesellschaft ab. Die Gesellschaft nimmt diese Abtretung an.
    </p></li>

      </ol>
    </li>

    <li><h3>Gegenleistung</h3>

      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>
      Die Abtretung erfolgt ohne Gegenleistung seitens der Gesellschaft.
      </p></li>
      <li><p>
      Die Parteien sind sich jedoch einig, dass die Domain mit dem Verkehrswert aktiviert und der Gegenwert als Eigenkapital (Kapitalrücklage gemäß § 272 Abs. 2 Nr. 4 HGB) ausgewiesen wird.
        </p></li>

         <li><p>
      Der Verkehrswert der Domain wird mit  ${insertTemplateVariable(
        domainWert
      )} angegeben. Diese Bewertung beruht auf den folgenden Umständen: ${insertTemplateVariable(
      domainWertBegründung
    )}.
        </p></li>


      </ol>
    </li>

    <li>
      <h3>Nebenpflichten</h3>
      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>
         Der einbringende Gesellschafter verpflichtet sich zur Unterstützung bei der Umschreibung der Domain bei der Registrierungsstelle. Insbesondere verpflichtet sich der einbringende Gesellschafter, unverzüglich sämtliche für die ausschließliche Registrierung der Domain auf die Gesellschaft notwendigen Mitwirkungshandlungen vorzunehmen und sämtliche dafür erforderlichen Erklärungen gegenüber dem Provider der Gesellschaft und dem Provider des einbringenden Gesellschafters, und, soweit erforderlich, auch gegenüber der Registrierungsstelle und gegenüber sonstigen Dritten abzugeben.
        </p></li>

        <li><p>
        Die Gesellschaft verpflichtet sich zur Unterstützung bei der Umschreibung der Domain bei der Registrierungsstelle. Insbesondere verpflichtet sich die Gesellschaft, unverzüglich sämtliche für die ausschließliche Registrierung der Domain auf sie notwendigen Mitwirkungshandlungen vorzunehmen und sämtliche dafür erforderlichen Erklärungen gegenüber seinem Provider, und soweit erforderlich, auch gegenüber der Registrierungsstelle und gegenüber sonstigen Dritten abzugeben. Der Gesellschaft sind die Domainrichtlinien und  Domainbedingungen der Registrierungsstelle bekannt.
        </p></li>

        </ol>
    </li>

    <li>
    <h3>Garantien, Ausschluss der Mängelrechte</h3>
      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>
        Der einbringende Gesellschafter garantiert, dass er ausschließlicher Inhaber der Domain ist und dass er in Bezug auf die Domain keine Abmahnungen oder sonstige Informationen wegen kennzeichen- oder namensrechtlicher Kollisionen oder Informationen von sonstigen gegen die Registrierung und Benutzung der Domain angeführten Einwänden Dritter erhalten hat. Er garantiert ferner, dass er auch nicht auf andere Weise Kenntnis von der erfolgten oder beabsichtigten Geltendmachung von Unterlassungs- oder Schadensersatzansprüchen gegen die Domain erlangt hat.
        </p></li>

        <li><p>
        Unbeschadet der Regelung in Absatz 1 ist eine Gewährleistung durch den einbringenden Gesellschafter für Rechtsmängel ausgeschlossen.
        </p></li>
      </ol>
    </li>

    <li>
      <h3>Schlussbestimmungen</h3>
      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>
        Sollten einzelne Bestimmungen dieses Vertrages ganz oder teilweise unwirksam oder nichtig sein oder werden, so wird dadurch die Wirksamkeit des Vertrages im Übrigen nicht berührt. Die Parteien verpflichten sich, die unwirksame oder nichtige Bestimmung durch eine andere wirksame Bestimmung zu ersetzen, die dem gewollten wirtschaftlichen Zweck am nächsten kommt. Das Entsprechende gilt im Fall einer Lücke.
        </p></li>

        <li><p>
        Etwaige Nebenkosten im Zusammenhang mit der Umschreibung der Domain hat die Gesellschaft zu tragen.
        </p></li>

        <li><p>
        Dieser Vertrag unterliegt deutschem Recht. Das UN-Kaufrecht wird ausgeschlossen.
        </p></li>

        <li><p>
        Ausschließlicher Gerichtsstand für sämtliche Streitigkeiten aus oder im Zusammenhang mit diesem Vertrag ist der Sitz der Gesellschaft.
        </p></li>


      </ol>
    </li>


  </ol>`,
    begründung: undefined,
    templateVariables: [
      nameGesellschafter,
      gesellschafterPersönlicheAngaben,
      domainName,
      domainRegistrierungsstelle,
      domainWert,
      domainWertBegründung,
    ],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
