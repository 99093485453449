import {
  adresseBeirat,
  geburtsdatumBeirat,
  nameBeirat,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageBeiratBestellung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.beirat,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.selfexecuting],
  bezug: [],
  kurzBezeichnung:
    "Bestellung eines neuen Beiratsmitglieds (schuldrechtlicher Beirat)",
  metaDescription:
    "Gesellschafterbeschluss, um neues Beiratsmitglied zu ernennen. Juristisch geprüfte Muster/Vorlagen jetzt kostenlos nutzen.",
  description: `
  <p>
    Ein Muster für die Bestellung eines Beiratsmitglied durch Gesellschafterbeschluss, nachdem der schuldrechtliche Beirat bereits eingerichtet worden ist.
  </p>

  <p>
Nach der Beiratsbestellung muss die Geschäftsführung noch einen Dienstleistungsvertrag mit dem Beiratsmitglied schließen, damit die Rechte und Pflichten aus der Beirats-Geschäftsordnung konkret werden.</p>

  `,
  antrag: `Zum neuen Beiratsmitglied der Gesellschaft wird mit sofortiger Wirkung bestellt:

  <p>${insertTemplateVariable(nameBeirat)},<br/>
  geboren am ${insertTemplateVariable(geburtsdatumBeirat)},<br/>
  wohnhaft in ${insertTemplateVariable(adresseBeirat)}.</p>

  <p>Die Geschäftsführung wird angewiesen, mit ${insertTemplateVariable(
    nameBeirat
  )} umgehend einen Dienstleistungsvertrag gemäß der aktuellen Geschäftsordnung des Beirats abzuschließen, der die Rechte und Pflichten der Beiratsmitglieder, insbesondere bezüglich des Ersatzes ihrer Auslagen und ihrer Vergütung, gegenüber der Gesellschaft abschließend regelt</p>`,
  templateVariables: [nameBeirat, geburtsdatumBeirat, adresseBeirat],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
