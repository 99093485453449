import {
  rechnungsabschlussDatum,
  wiederbeschaffungsruecklageBetrag,
  wiederbeschaffungsruecklageName,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageVereinRücklagenbildungWiederbeschaffung: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.EVDe],
    vorlageKategorie: VorlageKategorie.kapital,
    vorlage_rolleTyp: [RolleInGesellschaft.Geschftsleiter],
    vollzug: [],
    bezug: [
      "vorlageVereinRücklagenbildungZweckgebunden",
      "vorlageVereinRücklagenbildungFrei",
    ],
    kurzBezeichnung: "Bildung einer Wiederbeschaffungsrücklage",
    metaDescription:
      "Vorstandsbeschluss zur Bildung einer Wiederbeschaffungsrücklage im eV/Verein. Anwaltlich geprüftes Muster jetzt kostenlos nutzen.",
    tags: ["Rücklage", "Wiederbeschaffung", "Rückstellung"],
    description: `<p>Vorlage, mit welcher der Vorstand eines gemeinnützigen Vereins eine Wiederbeschaffungsücklage beschließen kann. Hierdurch kann das notwendige Kapital für z.B. ein Neubau eines Vereinsgebäudes oder die Ersetzung eines vom Verein genutzten Kfz finanziert werden.</p>`,
    antrag: `
  <p>
   Im Rechnungsabschluss zum ${insertTemplateVariable(
     rechnungsabschlussDatum
   )} wird von den vorhandenen Mitteln des Vereins der folgende Betrag als Wiederbeschaffungsrücklage des Vereins im Sinne von § 62 Abs. 1 Nr. 2 Abgabenordnung eingestellt:
  </p>
  <ul  data-list-style="none">
    <li>
      <p> ${insertTemplateVariable(
        wiederbeschaffungsruecklageName
      )}: ${insertTemplateVariable(wiederbeschaffungsruecklageBetrag)}</p>
    </li>
  </ul>
<p>Die für die Bildung einer Wiederbeschaffungsrücklage geltenden gesetzlichen Vorgaben werden beachtet:</p>
  <ul  data-list-style="square">
    <li>
      <p> Die beabsichtigte Wiederbeschaffung ist zur Verwirklichung der steuerbegünstigten, satzungsmäßigen Zwecke des Vereins erforderlich. (§ 62 Abs. 1 Nr. 1 AO)</p>
    </li>

    <li>
      <p>Reicht die Zuführung von Mitteln in Höhe der Abschreibungen für eine beabsichtigte Wiederbeschaffung nicht aus, dann können auch höhere Mittel der Rücklage zugeführt werden. Der Nachweis darüber ist dann durch den Verein zu erbringen. (AEAO zu § 62 Abs. 1 Nr. 2)</p>
    </li>
     <li>
      <p>Die Einstellung von Mitteln in die Rücklage darf im Jahr des Mittelzuflusses und in den beiden nachfolgenden Jahren erfolgen (§ 61 Abs. 2 S. 1 AO).</p>
    </li>
  </ul>
  `,
    templateVariables: [
      rechnungsabschlussDatum,
      wiederbeschaffungsruecklageName,
      wiederbeschaffungsruecklageBetrag,
    ],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
