import { begünstigtePerson } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageZustimmungVertragsabschlussPensionszusageAnlage: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [
      CompanyTypeName.GmbHDe,
      CompanyTypeName.UgDe,
      CompanyTypeName.GmbHCoKgDe,
      CompanyTypeName.UgCoKgDe,
      CompanyTypeName.GbRDe,
      CompanyTypeName.KgDe,
      CompanyTypeName.OHgDe,
      CompanyTypeName.PartGmbBDe,
      CompanyTypeName.PartGDe,
    ],
    vorlageKategorie: VorlageKategorie.geschäftsführer,
    vorlage_rolleTyp: [
      RolleInGesellschaft.Mitglieder,
      RolleInGesellschaft.Aufsichtsrat,
    ],
    vollzug: [Vollzug.zugang],
    bezug: [""],
    zustimmungErforderlichDes: undefined,
    metaDescription: `Gesellschafterbeschluss zur Pensionszusage. Juristisch geprüfte Muster/Vorlagen kostenlos.`,

    kurzBezeichnung: "Zustimmung zur Pensionszusage (mit Anlage)",
    description: `
  <p>
  Diese Beschlussvorlage hilft dabei, für eine Pensionszusage den notwendigen Zustimmungsbeschluss der Gesellschafter (oder ggf. des Beirats oder Aufsichtsrats) einzuholen. Der Pensionszusage-Vertrag, für den die Zustimmung gelten soll, ist beizufügen.
  </p>
  `,
    tags: [
      "Pensionszusage",
      "Altersvorsorge",
      "Versorgung",
      "Pension",
      "Versorgungszusage",
      "Altersvorsorge",
      "Betriebsrente",
      "Rente",
      "Vorsorge",
      "Rentenversicherung",
      "Finanzberater",
      "Versicherung",

      "Entscheider",
      "Entscheidungsträger",
      "Direktor",
      "Director",
      "Vorstand",
      "Vorsitzender",
      "Führung",
      "Leitung",
      "Leiter",
      "Geschäftsführung",
      "Geschäftsleitung",
      "Geschäftsleiter",
      "Chef",
      "Leiter",
      "Vorgesetzter",
      "Führungskraft",
      "Geschäftsführer",
      "Manager",
      "CEO",
      "CTO",
      "CFO",
      "leader",
      "head",
      "chief",
      "executive",
      "boss",
      "governor",
      "governance",

      "feststellen",
      "Feststellung",
      "genehmigen",
      "absegnen",
      "zustimmen",
      "grünes Licht",
      "Weg frei machen",
      "Okay geben",
      "O.K. geben",
      "OK geben",
      "Erlaubnis",
      "Unterstützung",
      "erlauben",
      "unterstützen",
      "einwilligen",
      "Einwilligung",
      "Genehmigung",
      "Zustimmung",
      "Approval",
      "blessing",
      "confirmation",
      "consent",
      "endorsement",
      "green light",
      "persmission",
      "ratification",
      "support",
    ],

    antrag: `
  <p>
   Die <strong>in der Anlage beigefügte</strong>
  </p>


    <ul data-list-style="none">
    <li><p>
      Pensionszusage zugunsten von ${insertTemplateVariable(begünstigtePerson)}
      </p>
        </p></li>
    </ul>

     <p>
  wird genehmigt.
  </p>

  `,
    begründung: undefined,
    templateVariables: [begünstigtePerson],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
