import { ContainerProps } from "@material-ui/core";
import { createEnumTypeGuard } from "@nvon/baseline";

export enum RVOOverflow {
  Hidden = "hidden",
  Visible = "visible",
}

// Add here just the colors of the theme palette that you need for the background
export enum RVOBackgroundColor {
  PrimaryDarkBlue = "primaryDarkBlue",
  SecondaryLightBlue = "secondaryLightBlue",
  SecondaryMediumBlue = "secondaryMediumBlue",
  WarningLightGold = "warningLightGold",
}

export enum RVOBackgroundGradient {
  SecondaryLightToMediumBlue = "secondaryLightToMediumBlue",
}

export const isBackgroundColor = createEnumTypeGuard(RVOBackgroundColor);
export const isBackgroundGradient = createEnumTypeGuard(RVOBackgroundGradient);

type GradientTuple = [RVOBackgroundColor, RVOBackgroundColor];

export const GradientColors: Record<RVOBackgroundGradient, GradientTuple> = {
  [RVOBackgroundGradient.SecondaryLightToMediumBlue]: [
    RVOBackgroundColor.SecondaryLightBlue,
    RVOBackgroundColor.SecondaryMediumBlue,
  ],
};

export interface RVOContainerProps {
  id?: string;
  children: ContainerProps["children"];
  fullWidth?: boolean;
  className?: string;
  overflow?: RVOOverflow;
  background?: RVOBackgroundColor | RVOBackgroundGradient;
  /**
   * There's a padding top by default. This prop deletes that padding.
   */
  noPaddingTop?: boolean;
  /**
   * There's no padding bottom by default. This prop adds a padding bottom.
   */
  paddingBottom?: boolean;
}
