import {
  gesellschaftsvertragEinfügenBezeichnungNeuePassage,
  gesellschaftsvertragEinfügenBezeichnungVorpassage,
  gesellschaftsvertragNeuePassage,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGesellschaftsvertragEinfügen: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.gesellschaftsvertrag,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [
    Vollzug.handelsregisterAnmeldung,
  ] /* nur bei GmbH,UG, ansotntsen nicht beurkundungsbedürftig und nicht anmeldepflichtig */,
  bezug: [],
  kurzBezeichnung:
    "Änderung des Gesellschaftsvertrags einer Personengesellschaft (Einfügen einer Passage)",
  metaDescription:
    "Gesellschafterbeschluss, der im Gesellschaftsvertrag einer GmbH & Co. KG, KG, GbR etc eine Regelung einfügt. Juristisch geprüfte Muster/Vorlagen kostenlos.",
  tags: [
    "Gesellschaftsvertrag",
    "Satzung",
    "Satzungsänderung",
    "Satzungsergänzung",
  ],
  description: `
  <p>
    Eine Änderung des Gesellschaftsvertrags von allen <strong>Personengesellschaften (GmbH & Co. KG, KG, ohG, GbR, Parterschaft etc)</strong> ist nicht beurkundungspflichtig.
  </p>
  <p>
  Allerdings kann in Spezialfällen ein Gang mit dem Beschluss zum Notar notwendig sein, um Vertragsänderungen zum Handelsregister anzumelden. Bei <strong>Personenhandelsgesellschaften (KG, oHG, GmbH & Co. KG und UG & Co. KG)</strong> sind Vertragsänderungen in den folgenden Fällen durch alle Gesellschafter zum Handelsregister anzumelden:
  </p>

  <ul  data-list-style="square">
    <li>
      <p>Firma der Gesellschaft (§ 107 bzw. § 106 Abs. 6 idF des MoPeG)</p>
    </li>
    <li>
      <p>Sitz der Gesellschaft (§ 107 bzw. § 106 Abs. 6 idF des MoPeG)</p>
    </li>
    <li>
      <p>inländische Geschäftsanschrift (§ 107 bzw. § 106 Abs. 6 idF des MoPeG)</p>
    </li>
    <li>
      <p>Gesellschafterbestand (§§ 107, 162 Abs. 1 S. 2, Abs. 3 HGB bzw. § 106 Abs. 6 idF des MoPeG)</p>
    </li>
    <li>
      <p>Vertretungsmacht (§ 107 bzw. § 106 Abs. 6 idF des MoPeG)</p>
    </li>
    <li>
      <p>Erhöhung oder Herabsetzung von Kommanditeinlagen (§ 175 HGB)</p>
    </li>
  </ul>


  `,
  antrag: `
  <p>
    Hinter
  	 ${insertTemplateVariable(
       gesellschaftsvertragEinfügenBezeichnungVorpassage
     )} des Gesellschaftsvertrags wird die neue Passage ${insertTemplateVariable(
    gesellschaftsvertragEinfügenBezeichnungNeuePassage
  )} eingefügt und erhält folgenden Wortlaut:
  </p>

  <ul data-list-style="none">
    <li>
      <p>"${insertTemplateVariable(gesellschaftsvertragNeuePassage)}"</p>
    </li>
  </ul>
  `,
  templateVariables: [
    gesellschaftsvertragEinfügenBezeichnungVorpassage,
    gesellschaftsvertragEinfügenBezeichnungNeuePassage,
    gesellschaftsvertragNeuePassage,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: true,
  noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss, sofern die Vertragsänderung zu einer Änderung der folgenden Regelungen geführt hat:</p>
  <ul  data-list-style="square">
    <li>
      <p>Firma der Gesellschaft (§ 107 bzw. § 106 Abs. 6 idF des MoPeG)</p>
    </li>
    <li>
      <p>Sitz der Gesellschaft (§ 107 bzw. § 106 Abs. 6 idF des MoPeG)</p>
    </li>
    <li>
      <p>inländische Geschäftsanschrift (§ 107 bzw. § 106 Abs. 6 idF des MoPeG)</p>
    </li>
    <li>
      <p>Gesellschafterbestand (§§ 107, 162 Abs. 1 S. 2, Abs. 3 HGB bzw. § 106 Abs. 6 idF des MoPeG)</p>
    </li>
    <li>
      <p>Vertretungsmacht (§ 107 bzw. § 106 Abs. 6 idF des MoPeG)</p>
    </li>
    <li>
      <p>Erhöhung oder Herabsetzung von Kommanditeinlagen (§ 175 HGB)</p>
    </li>
  </ul>
  <p>Die Handelsregisteranmeldung muss von den persönlich haftenden Gesellschaftern in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
});
