import { Box, Button, Link, Typography } from "@material-ui/core";
import { Link as GatsbyLink } from "gatsby";
import { useTranslation } from "react-i18next";
import { ChatEmoji } from "../../../helpers/clientSide/emojis";
import { useSetHasSeenChatWindowHelp } from "../../../state/onboardingHelp/onboardingHelpSlice";
import { useRVOModalContext } from "../../00-globals/RVOModalContextProvider/RVOModalContextProvider";
import HtmlFromLangFile from "../../01-atoms/HtmlFromLangFile/HtmlFromLangFile";
import RVOModal from "../RVOModal/RVOModal";
import { RVOModalLevel } from "../RVOModal/RVOModalInterfaces";
import { useRVOChatCookieModalStyles } from "./RVOChatCookieModalStyles";

export const RVOChatCookieModal = (): JSX.Element => {
  const classes = useRVOChatCookieModalStyles();
  const setHasSeenHelp = useSetHasSeenChatWindowHelp();
  const { hideChatCookieHint, isShowingChatCookieHint } = useRVOModalContext();
  const { t } = useTranslation();

  const onAnyButton = () => {
    hideChatCookieHint();

    /*
     * We set this value so that we can instantly show the chat window
     *  as soon as consent is given.
     */
    setHasSeenHelp();
  };

  const accept = () => {
    window.Truendo?.togglePreferences();
    onAnyButton();
  };

  const showPanel = () => {
    window.Truendo?.openCookieSettings();
    onAnyButton();
  };

  return (
    <RVOModal
      title={t("chatButton.chatButtonOverlayTitle")}
      isOpen={isShowingChatCookieHint}
      level={RVOModalLevel.Info}
      emoji={ChatEmoji}
      onClose={hideChatCookieHint}
    >
      <Box>
        <Typography variant="body1">
          <HtmlFromLangFile
            contentContainingHtml={t("chatButton.chatButtonOverlayDescription")}
          />

          <Link
            component={GatsbyLink}
            to="/datenschutz"
            onClick={hideChatCookieHint}
          >
            {t("general.privacyPageName")}.
          </Link>
        </Typography>

        <br />

        <Typography variant="body1">
          <HtmlFromLangFile
            contentContainingHtml={t(
              "chatButton.chatButtonOverlayDescriptionII"
            )}
          />
        </Typography>

        <Button
          variant="outlined"
          fullWidth={true}
          className={classes.showConsentOverlayButton}
          onClick={showPanel}
        >
          {t("chatButton.chatButtonOverlaySecondaryButtonLabel")}
        </Button>

        <Button variant="contained" fullWidth={true} onClick={accept}>
          {t("chatButton.chatButtonOverlayPrimaryButtonLabel")}
        </Button>
      </Box>
    </RVOModal>
  );
};
