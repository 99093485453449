import { ComponentWithClassName } from "@nvon/react-toolbox";
import { ReactNode } from "react";
import {
  ButtonOrLink,
  TranslatedText,
} from "../../../interfaces/variousInterfaces";

export enum HeaderColor {
  Paper = "paper",
  Primary = "primary",
}

export interface HeaderCenterComponentProps {
  color: HeaderColor;
}

export type HeaderCenterComponent = (
  props: HeaderCenterComponentProps
) => JSX.Element;

/**
 * Options for the page layout.
 *
 * All options relate to the header right now, since it's the only dynamic part.
 */
export interface PageLayoutOptions {
  logo: {
    component: ComponentWithClassName;
    href: string;
    label: TranslatedText;
  };

  color: HeaderColor;
  ctaButton?: ButtonOrLink;
  registerButton?: ButtonOrLink;
  loginButton?: ButtonOrLink;
  /**
   * A component that renders the center content depending on
   *  the selected color.
   */
  centerContent?: HeaderCenterComponent;
  headerHeight: number;
  /**
   * By default, both center content and CTA button
   *  are put in the burger menu on mobile.
   * Setting this to true will disable this behavior.
   */
  disableMobileCollapse?: boolean;
}

export interface DefaultPageLayoutProps extends PageLayoutOptions {
  children: ReactNode;
}
