export enum RVOVariableTypeId {
  Options = "Options",
  MultilineText = "MultilineText",
  Money = "Money",
  Number = "Number",
  SliderNumber = "SliderNumber",
  String = "String",
  Switch = "Switch",
  ListOfBoardMembers = "ListOfBoardMembers",
}
