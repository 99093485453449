import { LegacyTemplateBase } from "../VorlageInterface";
import { vorlageAbschlusspruferBestellung } from "../vorlagenLibrary/vorlageAbschlusspruferBestellung";
import { vorlageAgDeAufsichtsratGeschäftsordnungMitMitbestimmungMitAusschüssen } from "../vorlagenLibrary/vorlageAgDeAufsichtsratGeschäftsordnungMitMitbestimmungMitAusschüssen";
import { vorlageAgDeAufsichtsratGeschäftsordnungOhneMitbestimmungMitAusschüssen } from "../vorlagenLibrary/vorlageAgDeAufsichtsratGeschäftsordnungOhneMitbestimmungMitAusschüssen";
import { vorlageAgDeVorstandAbberufungVertragsbeendigung } from "../vorlagenLibrary/vorlageAgDeVorstandAbberufungVertragsbeendigung";
import { vorlageAgDeVorstandBestellung } from "../vorlagenLibrary/vorlageAgDeVorstandBestellung";
import { vorlageAllgemein } from "../vorlagenLibrary/vorlageAllgemein";
import { vorlageArbeitsvertragEinfach } from "../vorlagenLibrary/vorlageArbeitsvertragEinfach";
import { vorlageArbeitsvertragPhantomStock } from "../vorlagenLibrary/vorlageArbeitsvertragPhantomStock";
import { vorlageAuflosungFortfuhrung } from "../vorlagenLibrary/vorlageAuflosungFortfuhrung";
import { vorlageAuflosungMitLiquidationUndSperrjahr } from "../vorlagenLibrary/vorlageAuflosungMitLiquidationUndSperrjahr";
import { vorlageAuflosungOhneLiquidationUndOhneSperrjahr } from "../vorlagenLibrary/vorlageAuflosungOhneLiquidationUndOhneSperrjahr";
import { vorlageAusnutzungGenehmigtesKapitalBareinlagen } from "../vorlagenLibrary/vorlageAusnutzungGenehmigtesKapitalBareinlagen";
import { vorlageAusnutzungGenehmigtesKapitalBareinlagenTeilweise } from "../vorlagenLibrary/vorlageAusnutzungGenehmigtesKapitalBareinlagenTeilweise";
import { vorlageAusnutzungGenehmigtesKapitalSacheinlageDarlehen } from "../vorlagenLibrary/vorlageAusnutzungGenehmigtesKapitalSacheinlageDarlehen";
import { vorlageAusnutzungGenehmigtesKapitalSacheinlageDarlehenTeilweise } from "../vorlagenLibrary/vorlageAusnutzungGenehmigtesKapitalSacheinlageDarlehenTeilweise";
import { vorlageBeiratBestellung } from "../vorlagenLibrary/vorlageBeiratBestellung";
import { vorlageBeiratEinrichtenSchuldrechtlich } from "../vorlagenLibrary/vorlageBeiratEinrichtenSchuldrechtlich";
import { vorlageBeiratSchuldrechtlichDienstvertrag } from "../vorlagenLibrary/vorlageBeiratSchuldrechtlichDienstvertrag";
import { vorlageBestatigungAnfechtbarerBeschluss } from "../vorlagenLibrary/vorlageBestatigungAnfechtbarerBeschluss";
import { vorlageBundleGeschaftsfuhrerBestellung } from "../vorlagenLibrary/vorlageBundleGeschaftsfuhrerBestellung";
import { vorlageBundleGeschaftsfuhrerwechsel } from "../vorlagenLibrary/vorlageBundleGeschaftsfuhrerwechsel";
import { vorlageEinziehungMitAufstockung } from "../vorlagenLibrary/vorlageEinziehungMitAufstockung";
import { vorlageEntlastungRegelmassigAufsichtsrat } from "../vorlagenLibrary/vorlageEntlastungRegelmassigAufsichtsrat";
import { vorlageEntlastungRegelmassigBeirat } from "../vorlagenLibrary/vorlageEntlastungRegelmassigBeirat";
import { vorlageEntlastungRegelmassigGeschaftsfuhrenderGesellschafter } from "../vorlagenLibrary/vorlageEntlastungRegelmassigGeschaftsfuhrenderGesellschafter";
import { vorlageEntlastungRegelmassigGeschaftsfuhrenderPartner } from "../vorlagenLibrary/vorlageEntlastungRegelmassigGeschaftsfuhrenderPartner";
import { vorlageEntlastungRegelmassigGeschaftsfuhrer } from "../vorlagenLibrary/vorlageEntlastungRegelmassigGeschaftsfuhrer";
import { vorlageEntlastungRegelmassigKomplementar } from "../vorlagenLibrary/vorlageEntlastungRegelmassigKomplementar";
import { vorlageEntlastungRegelmassigKomplementarGmbH } from "../vorlagenLibrary/vorlageEntlastungRegelmassigKomplementarGmbH";
import { vorlageErrichtungZweigniederlassung } from "../vorlagenLibrary/vorlageErrichtungZweigniederlassung";
import { vorlageErteilungEinzelprokura } from "../vorlagenLibrary/vorlageErteilungEinzelprokura";
import { vorlageErteilungEinzelprokuraEng } from "../vorlagenLibrary/vorlageErteilungEinzelprokuraEng";
import { vorlageErteilungGesamtprokura } from "../vorlagenLibrary/vorlageErteilungGesamtprokura";
import { vorlageErteilungGesamtprokuraEng } from "../vorlagenLibrary/vorlageErteilungGesamtprokuraEng";
import { vorlageWirtschaftsplanFeststellung } from "../vorlagenLibrary/vorlageFeststellungWirtschaftsplan";
import { vorlageGeschaftsfuhrerAbberufung } from "../vorlagenLibrary/vorlageGeschaftsfuhrerAbberufung";
import { vorlageGeschaftsfuhrerAnstellungsvertragAufhebung } from "../vorlagenLibrary/vorlageGeschaftsfuhrerAnstellungsvertragAufhebung";
import { vorlageGeschaftsfuhrerAnstellungsvertragAufhebungBeauftragungAnlage } from "../vorlagenLibrary/vorlageGeschaftsfuhrerAnstellungsvertragAufhebungBeauftragungAnlage";
import { vorlageGeschaftsfuhrerAnstellungsvertragAufhebungBeuftragungEckpunkte } from "../vorlagenLibrary/vorlageGeschaftsfuhrerAnstellungsvertragAufhebungBeuftragungEckpunkte";
import { vorlageGeschaftsfuhrerAnstellungsvertragBeauftragungAnlage } from "../vorlagenLibrary/vorlageGeschaftsfuhrerAnstellungsvertragBeauftragungAnlage";
import { vorlageGeschaftsfuhrerAnstellungsvertragEinfach } from "../vorlagenLibrary/vorlageGeschaftsfuhrerAnstellungsvertragEinfach";
import { vorlageGeschaftsfuhrerAnstellungsvertragGehaltsverzichtMitBesserungsschein } from "../vorlagenLibrary/vorlageGeschaftsfuhrerAnstellungsvertragGehaltsverzichtMitBesserungsschein";
import { vorlageGeschaftsfuhrerAnstellungsvertragGehaltsverzichtOhneBesserungsschein } from "../vorlagenLibrary/vorlageGeschaftsfuhrerAnstellungsvertragGehaltsverzichtOhneBesserungsschein";
import { vorlageGeschaftsfuhrerAnstellungsvertragKundigungAusWichtigemGrund } from "../vorlagenLibrary/vorlageGeschaftsfuhrerAnstellungsvertragKundigungAusWichtigemGrund";
import { vorlageGeschaftsfuhrerAnstellungsvertragKundigungOrdentlich } from "../vorlagenLibrary/vorlageGeschaftsfuhrerAnstellungsvertragKundigungOrdentlich";
import { vorlageGeschaftsfuhrerBefreiung181 } from "../vorlagenLibrary/vorlageGeschaftsfuhrerBefreiung181";
import { vorlageGeschaftsfuhrerBefreiung181Einzellfall } from "../vorlagenLibrary/vorlageGeschaftsfuhrerBefreiung181Einzellfall";
import { vorlageGeschaftsfuhrerBestellung } from "../vorlagenLibrary/vorlageGeschaftsfuhrerBestellung";
import { vorlageGeschaftsfuhrerEntlastungVerweigerung } from "../vorlagenLibrary/vorlageGeschaftsfuhrerEntlastungVerweigerung";
import { vorlageGeschaftsfuhrerGeltendmachungSchadenersatzanspruche } from "../vorlagenLibrary/vorlageGeschaftsfuhrerGeltendmachungSchadenersatzanspruche";
import { vorlageGeschaftsfuhrerGeneralbereinigung } from "../vorlagenLibrary/vorlageGeschaftsfuhrerGeneralbereinigung";
import { vorlageGeschaftsfuhrerGeschaftsordnungEinGeschaftsfuhrer } from "../vorlagenLibrary/vorlageGeschaftsfuhrerGeschaftsordnungEinGeschaftsfuhrer";
import { vorlageGeschaftsfuhrerGeschaftsordnungMehrereGeschaftsfuhrer } from "../vorlagenLibrary/vorlageGeschaftsfuhrerGeschaftsordnungMehrereGeschaftsfuhrer";
import { vorlageGeschaftsfuhrerInflationsausgleichspraemie } from "../vorlagenLibrary/vorlageGeschaftsfuhrerInflationsausgleichspraemie";
import { vorlageGeschaftsfuhrerVertretungsbefugnisEinzel } from "../vorlagenLibrary/vorlageGeschaftsfuhrerVertretungsbefugnisEinzel";
import { vorlageGeschaftsfuhrerVertretungsbefugnisGemischt } from "../vorlagenLibrary/vorlageGeschaftsfuhrerVertretungsbefugnisGemischt";
import { vorlageGeschaftsfuhrerVertretungsbefugnisGesamt } from "../vorlagenLibrary/vorlageGeschaftsfuhrerVertretungsbefugnisGesamt";
import { vorlageGesellschafterAusschlussKlage } from "../vorlagenLibrary/vorlageGesellschafterAusschlussKlage";
import { vorlageGesellschafterAusschlussKlagePersG } from "../vorlagenLibrary/vorlageGesellschafterAusschlussKlagePersG";
import { vorlageGesellschafterAusschlussKlausel } from "../vorlagenLibrary/vorlageGesellschafterAusschlussKlausel";
import { vorlageBeschluesseZumJahresabschlussGbR } from "../vorlagenLibrary/vorlageGesellschafterBeschluesseZumJahresabschlussGbR";
import { vorlageGesellschafterBeschluesseZumJahresabschlussGmbH } from "../vorlagenLibrary/vorlageGesellschafterBeschluesseZumJahresabschlussGmbH";
import { vorlageGesellschafterBeschluesseZumJahresabschlussGmbHKlein } from "../vorlagenLibrary/vorlageGesellschafterBeschluesseZumJahresabschlussGmbHKlein";
import { vorlageBeschluesseZumJahresabschlussPartG } from "../vorlagenLibrary/vorlageGesellschafterBeschluesseZumJahresabschlussPartG";
import { vorlageGesellschafterBeschluesseZumJahresabschlussUG } from "../vorlagenLibrary/vorlageGesellschafterBeschluesseZumJahresabschlussUG";
import { vorlageGesellschafterDarlehenForderungsverzicht } from "../vorlagenLibrary/vorlageGesellschafterDarlehenForderungsverzicht";
import { vorlageGesellschafterDarlehenGewahrung } from "../vorlagenLibrary/vorlageGesellschafterDarlehenGewahrung";
import { vorlageGesellschafterDarlehenRangrucktritt } from "../vorlagenLibrary/vorlageGesellschafterDarlehenRangrucktritt";
import { vorlageGesellschafterEinforderungAusstehendeEinlagen } from "../vorlagenLibrary/vorlageGesellschafterEinforderungAusstehendeEinlagen";
import { vorlageGesellschafterEinziehung } from "../vorlagenLibrary/vorlageGesellschafterEinziehung";
import { vorlageGesellschafterEinziehungAufstockung } from "../vorlagenLibrary/vorlageGesellschafterEinziehungAufstockung";
import { vorlageGesellschafterEinziehungAusSonstigemGrund } from "../vorlagenLibrary/vorlageGesellschafterEinziehungAusSonstigemGrund";
import { vorlageGesellschafterEinziehungAusWichtigemGrund } from "../vorlagenLibrary/vorlageGesellschafterEinziehungAusWichtigemGrund";
import { vorlageGesellschafterEinziehungRevalorisierung } from "../vorlagenLibrary/vorlageGesellschafterEinziehungRevalorisierung";
import { vorlageGesellschafterGeschaftsanteilTeilung } from "../vorlagenLibrary/vorlageGesellschafterGeschaftsanteilTeilung";
import { vorlageGesellschafterKapitalrucklage } from "../vorlagenLibrary/vorlageGesellschafterKapitalrucklage";
import { vorlageGesellschafterEinbringungsvertragÜbertragungDomain } from "../vorlagenLibrary/vorlageGesellschafterKapitalrucklageÜbertragungDomain";
import { vorlageGesellschaftervereinbarungGrowthShares } from "../vorlagenLibrary/vorlageGesellschaftervereinbarungGrowthShares";
import { vorlageGesellschafterZustimmungAbtretung } from "../vorlagenLibrary/vorlageGesellschafterZustimmungAbtretung";
import { vorlageGesellschafterZustimmungBeherrUndGewinnabfuhrVertrag } from "../vorlagenLibrary/vorlageGesellschafterZustimmungBeherrUndGewinnabfuhrVertrag";
import { vorlageGesellschaftsvertragEinfügen } from "../vorlagenLibrary/vorlageGesellschaftsvertragErgänzung";
import { vorlageGesellschaftsvertragGbrVorgruendungsgesellschaft } from "../vorlagenLibrary/vorlageGesellschaftsvertragGbrVorgruendungsgesellschaft";
import { vorlageGesellschaftsvertragKGMitarbeiterbeteiligung } from "../vorlagenLibrary/vorlageGesellschaftsvertragKGMitarbeiterbeteiligung";
import { vorlageGesellschaftsvertragÄndern } from "../vorlagenLibrary/vorlageGesellschaftsvertragÄndern";
import { vorlageHandlungsvollmachtArtErteilung } from "../vorlagenLibrary/vorlageHandlungsvollmachtArtErteilung";
import { vorlageHandlungsvollmachtGeneralErteilung } from "../vorlagenLibrary/vorlageHandlungsvollmachtGeneralErteilung";
import { vorlageJahresabschlussAufstellung } from "../vorlagenLibrary/vorlageJahresabschlussAufstellung";
import { vorlageJahresabschlussFeststellung } from "../vorlagenLibrary/vorlageJahresabschlussFeststellung";
import { vorlageJahresplanungFeststellung } from "../vorlagenLibrary/vorlageJahresplanungFeststellung";
import { vorlageLiquidationMitEinzeliquidator } from "../vorlagenLibrary/vorlageLiquidationMitEinzeliquidator";
import { vorlageLiquidatorAbberufung } from "../vorlagenLibrary/vorlageLiquidatorAbberufung";
import { vorlageLiquidatorBefreiung181 } from "../vorlagenLibrary/vorlageLiquidatorBefreiung181";
import { vorlageLiquidatorBestellung } from "../vorlagenLibrary/vorlageLiquidatorBestellung";
import { vorlageLiquidatorVertretungsbefugnisEinzel } from "../vorlagenLibrary/vorlageLiquidatorVertretungsbefugnisEinzel";
import { vorlageLiquidatorVertretungsbefugnisGesamt } from "../vorlagenLibrary/vorlageLiquidatorVertretungsbefugnisGesamt";
import { vorlagePlayground } from "../vorlagenLibrary/vorlagePlayground";
import { vorlageProkuraBeendigung } from "../vorlagenLibrary/vorlageProkuraBeendigung";
import { vorlageSocialMediaPolicy } from "../vorlagenLibrary/vorlageSocialMediaPolicy";
import { vorlageUnbeschrankteBefreiungVomWettbewerbsverbotEinzelunternehmen } from "../vorlagenLibrary/vorlageUnbeschrankteBefreiungVomWettbewerbsverbotEinzelunternehmen";
import { vorlageVereinAnbauvereinigungBeitragsordnung } from "../vorlagenLibrary/vorlageVereinAnbauvereinigungBeitragsordnung";
import { vorlageVereinRücklagenbildungFrei } from "../vorlagenLibrary/vorlageVereinRücklagenbildungFrei";
import { vorlageVereinRücklagenbildungWiederbeschaffung } from "../vorlagenLibrary/vorlageVereinRücklagenbildungWiederbeschaffung";
import { vorlageVereinRücklagenbildungZweckgebunden } from "../vorlagenLibrary/vorlageVereinRücklagenbildungZweckgebunden";
import { vorlageVereinSatzungAnbauvereinigung } from "../vorlagenLibrary/vorlageVereinSatzungAnbauvereinigung";
import { vorlageVereinVorstandErnennungPraeventivbeauftragter } from "../vorlagenLibrary/vorlageVereinVorstandErnennungPraeventivbeauftragter";
import { vorlageVerwendungDesGewinns } from "../vorlagenLibrary/vorlageVerwendungDesGewinns";
import { vorlageVerwendungDesGewinnsUG } from "../vorlagenLibrary/vorlageVerwendungDesGewinnsUG";
import { vorlageVerwendungDesGewinnsVorabausschuettung } from "../vorlagenLibrary/vorlageVerwendungDesGewinnsVorabausschuettung";
import { vorlageWeisungAllgemein } from "../vorlagenLibrary/vorlageWeisungAllgemein";
import { vorlageWeisungVertragsabschlussAnlage } from "../vorlagenLibrary/vorlageWeisungVertragsabschlussAnlage";
import { vorlageWeisungVertragsabschlussEckpunkte } from "../vorlagenLibrary/vorlageWeisungVertragsabschlussEckpunkte";
import { vorlageZustimmungAllgemein } from "../vorlagenLibrary/vorlageZustimmungAllgemein";
import { vorlageZustimmungVertragsabschlussAnlage } from "../vorlagenLibrary/vorlageZustimmungVertragsabschlussAnlage";
import { vorlageZustimmungVertragsabschlussBeauftragungRA } from "../vorlagenLibrary/vorlageZustimmungVertragsabschlussBeauftragungRA";
import { vorlageZustimmungDUndOVertragsabschlussAnlage } from "../vorlagenLibrary/vorlageZustimmungVertragsabschlussDUndOAnlage";
import { vorlageZustimmungVertragsabschlussDUndOEckpunkte } from "../vorlagenLibrary/vorlageZustimmungVertragsabschlussDUndOEckpunkte";
import { vorlageZustimmungVertragsabschlussEckpunkte } from "../vorlagenLibrary/vorlageZustimmungVertragsabschlussEckpunkteAllgemein";
import { vorlageZustimmungVertragsabschlussKaufvertrag } from "../vorlagenLibrary/vorlageZustimmungVertragsabschlussKaufvertrag ";
import { vorlageZustimmungVertragsabschlussMietvertrag } from "../vorlagenLibrary/vorlageZustimmungVertragsabschlussMietvertrag";
import { vorlageZustimmungVertragsabschlussPensionszusageAnlage } from "../vorlagenLibrary/vorlageZustimmungVertragsabschlussPensionszusageAnlage";
import { vorlageZustimmungZurAnteilsabtretung } from "../vorlagenLibrary/vorlageZustimmungZurAnteilsabtretung";

export const vorlagenBeingUsed: LegacyTemplateBase[] = [
  vorlagePlayground,
  vorlageAllgemein,

  vorlageGesellschafterBeschluesseZumJahresabschlussGmbH,
  vorlageGesellschafterBeschluesseZumJahresabschlussGmbHKlein,
  vorlageGesellschafterBeschluesseZumJahresabschlussUG,
  vorlageBeschluesseZumJahresabschlussGbR,
  vorlageBeschluesseZumJahresabschlussPartG,
  vorlageJahresabschlussAufstellung,

  vorlageZustimmungAllgemein,
  vorlageZustimmungVertragsabschlussEckpunkte,
  vorlageZustimmungVertragsabschlussAnlage,
  vorlageZustimmungVertragsabschlussMietvertrag,
  vorlageZustimmungVertragsabschlussKaufvertrag,
  vorlageZustimmungVertragsabschlussBeauftragungRA,
  vorlageZustimmungDUndOVertragsabschlussAnlage,
  vorlageZustimmungVertragsabschlussDUndOEckpunkte,
  vorlageZustimmungVertragsabschlussPensionszusageAnlage,

  vorlageWeisungAllgemein,
  vorlageWeisungVertragsabschlussAnlage,
  vorlageWeisungVertragsabschlussEckpunkte,

  vorlageVerwendungDesGewinnsVorabausschuettung,
  vorlageJahresabschlussFeststellung,
  vorlageVerwendungDesGewinns,
  vorlageVerwendungDesGewinnsUG,
  vorlageJahresplanungFeststellung,
  vorlageWirtschaftsplanFeststellung,

  vorlageGeschaftsfuhrerAnstellungsvertragGehaltsverzichtMitBesserungsschein,
  vorlageGeschaftsfuhrerAnstellungsvertragGehaltsverzichtOhneBesserungsschein,
  vorlageGesellschaftsvertragGbrVorgruendungsgesellschaft,

  vorlageArbeitsvertragEinfach,

  vorlageGesellschafterZustimmungAbtretung,

  vorlageGesellschafterEinbringungsvertragÜbertragungDomain,
  vorlageArbeitsvertragPhantomStock,
  vorlageGesellschaftsvertragKGMitarbeiterbeteiligung,
  vorlageGesellschaftervereinbarungGrowthShares,
  vorlageSocialMediaPolicy,

  vorlageGesellschafterKapitalrucklage,
  vorlageGesellschafterDarlehenGewahrung,

  vorlageBundleGeschaftsfuhrerBestellung,
  vorlageBundleGeschaftsfuhrerwechsel,

  vorlageBeiratEinrichtenSchuldrechtlich,
  vorlageBeiratBestellung,
  vorlageBeiratSchuldrechtlichDienstvertrag,

  vorlageGesellschaftsvertragÄndern,
  vorlageGesellschaftsvertragEinfügen,

  vorlageGeschaftsfuhrerAbberufung,
  vorlageGeschaftsfuhrerAnstellungsvertragAufhebung,
  vorlageGeschaftsfuhrerAnstellungsvertragKundigungAusWichtigemGrund,
  vorlageGeschaftsfuhrerAnstellungsvertragKundigungOrdentlich,
  vorlageGeschaftsfuhrerGeneralbereinigung,
  vorlageEntlastungRegelmassigGeschaftsfuhrer,
  vorlageGeschaftsfuhrerEntlastungVerweigerung,

  vorlageGeschaftsfuhrerAnstellungsvertragEinfach,
  vorlageGeschaftsfuhrerBestellung,
  vorlageGeschaftsfuhrerBefreiung181,
  vorlageGeschaftsfuhrerBefreiung181Einzellfall,
  vorlageGeschaftsfuhrerVertretungsbefugnisEinzel,
  vorlageGeschaftsfuhrerVertretungsbefugnisGemischt,
  vorlageGeschaftsfuhrerVertretungsbefugnisGesamt,
  vorlageGeschaftsfuhrerGeschaftsordnungEinGeschaftsfuhrer,
  vorlageGeschaftsfuhrerGeschaftsordnungMehrereGeschaftsfuhrer,
  vorlageGeschaftsfuhrerInflationsausgleichspraemie,

  vorlageErteilungGesamtprokura,
  vorlageErteilungEinzelprokura,
  vorlageErteilungGesamtprokuraEng,
  vorlageErteilungEinzelprokuraEng,
  vorlageProkuraBeendigung,
  vorlageHandlungsvollmachtGeneralErteilung,
  vorlageHandlungsvollmachtArtErteilung,

  vorlageAbschlusspruferBestellung,

  vorlageVereinRücklagenbildungFrei,
  vorlageVereinRücklagenbildungZweckgebunden,
  vorlageVereinRücklagenbildungWiederbeschaffung,

  vorlageVereinSatzungAnbauvereinigung,
  vorlageVereinVorstandErnennungPraeventivbeauftragter,
  vorlageVereinAnbauvereinigungBeitragsordnung,

  // =>>>>> new vorlagen after clean-up <<<<<=

  vorlageZustimmungZurAnteilsabtretung,
  vorlageEntlastungRegelmassigAufsichtsrat,
  vorlageEntlastungRegelmassigBeirat,
  vorlageGesellschafterDarlehenRangrucktritt,
  vorlageGesellschafterDarlehenForderungsverzicht,
  vorlageEntlastungRegelmassigKomplementarGmbH,
  vorlageEntlastungRegelmassigKomplementar,
  vorlageEntlastungRegelmassigGeschaftsfuhrenderGesellschafter,
  vorlageEntlastungRegelmassigGeschaftsfuhrenderPartner,
  vorlageGesellschafterGeschaftsanteilTeilung,
  vorlageGeschaftsfuhrerGeltendmachungSchadenersatzanspruche,
  vorlageLiquidatorAbberufung,
  vorlageGesellschafterEinziehung,
  vorlageEinziehungMitAufstockung,
  vorlageGesellschafterEinforderungAusstehendeEinlagen,
  vorlageGesellschafterZustimmungBeherrUndGewinnabfuhrVertrag,
  vorlageUnbeschrankteBefreiungVomWettbewerbsverbotEinzelunternehmen,

  vorlageErrichtungZweigniederlassung,
  vorlageAuflosungFortfuhrung,
  vorlageGesellschafterAusschlussKlagePersG,
  vorlageGesellschafterAusschlussKlage,
  vorlageGesellschafterAusschlussKlausel,
  vorlageBestatigungAnfechtbarerBeschluss,

  vorlageAuflosungMitLiquidationUndSperrjahr,
  vorlageAuflosungOhneLiquidationUndOhneSperrjahr,
  vorlageLiquidationMitEinzeliquidator,
  vorlageLiquidatorBestellung,
  vorlageLiquidatorVertretungsbefugnisEinzel,
  vorlageLiquidatorVertretungsbefugnisGesamt,
  vorlageLiquidatorBefreiung181,

  vorlageGesellschafterEinziehungAusWichtigemGrund,
  vorlageGesellschafterEinziehungAusSonstigemGrund,
  vorlageGesellschafterEinziehungAufstockung,
  vorlageGesellschafterEinziehungRevalorisierung,

  vorlageAusnutzungGenehmigtesKapitalBareinlagen,
  vorlageAusnutzungGenehmigtesKapitalBareinlagenTeilweise,
  vorlageAusnutzungGenehmigtesKapitalSacheinlageDarlehen,
  vorlageAusnutzungGenehmigtesKapitalSacheinlageDarlehenTeilweise,

  vorlageGeschaftsfuhrerAnstellungsvertragAufhebungBeauftragungAnlage,
  vorlageGeschaftsfuhrerAnstellungsvertragAufhebungBeuftragungEckpunkte,
  vorlageGeschaftsfuhrerAnstellungsvertragBeauftragungAnlage,

  vorlageAgDeVorstandBestellung,
  vorlageAgDeVorstandAbberufungVertragsbeendigung,
  vorlageAgDeAufsichtsratGeschäftsordnungOhneMitbestimmungMitAusschüssen,
  vorlageAgDeAufsichtsratGeschäftsordnungMitMitbestimmungMitAusschüssen,
];
