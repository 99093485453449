import { useLocation } from "@reach/router";
import { useMemo } from "react";
import { vorlagenOverviewPath } from "../../../helpers/general/frontendUrlPaths";
import { PageLayoutOptions } from "./PageLayoutInterfaces";
import { beschlussLayout } from "./layouts/beschlussLayout";
import { defaultLayout } from "./layouts/defaultLayout/defaultLayout";

const layouts: {
  pathMatch: string | RegExp;
  layout: PageLayoutOptions;
}[] = [
  {
    pathMatch: new RegExp(`${vorlagenOverviewPath}/.+`),
    layout: beschlussLayout,
  },
];

export const useCurrentPageLayout = (): PageLayoutOptions => {
  const currentUrl = useLocation();

  const currentLayout = useMemo(() => {
    const matchingLayout = layouts.find(({ pathMatch }) =>
      currentUrl.pathname.match(pathMatch)
    )?.layout;

    return matchingLayout || defaultLayout;
  }, [currentUrl.pathname]);

  return currentLayout;
};
