import {
  bankDatenGesellschaft,
  neueGeschäftsanteileAnzahl,
  neueGeschäftsanteileAusgabebetrag,
  neueGeschäftsanteileBetrag,
  notarmitarbeiter,
  satzungStammkapital,
  stammkapitalBetragNachErhöhung,
  stammkapitalBetragNachErhöhungInWorten,
  stammkapitalErhöhungsbetrag,
  tagBeginnGewinnberechtigung,
  übernahmeFrist,
  übernahmeNachfristAndereGesellschafter,
  übernahmeZugelasseneGesellschafter,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageKapitalerhohung_: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
  vorlageKategorie: VorlageKategorie.kapital,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [
    Vollzug.handelsregisterAnmeldung,
    Vollzug.handelsregisterGesellschafterliste,
    Vollzug.transparenzregister,
    Vollzug.kapErhöhung_abgabeÜbernahmeerklärungen,
    Vollzug.beurkundung,
  ],
  bezug: ["SchaffungGenehmigtenKapitals"],
  tags: [
    "Darlehen",
    "Darlehn",
    "Kredit",
    "Pump",
    "Cash",
    "Finanzierung",
    "Zins",
    "Kapital",
    "Liquidität",
    "Risiko",
    "Venture Capital",
    "Bonität",
    "Einzahlen",
    "Privatvermögen",
    "Gesellschafterdarlehen",
    "Gesellschafterdarlehn",
    "Verzinsung",
    "Mittelbeschaffung",
    "Fazilität",
    "Kreditlinie",
    "Kreditrahmen",
    "Mittel",
    "Geld",
    "sanierung",
    "loan",
    "allowance",
    "credit",
    "inveestment",
    "invest",
    "mortage",
    "risk",
    "money",
    "capital",
    "kapitalerhöhung",
    "kapitalrücklage",
    "rücklage",
    "eigenkapital",
    "krise",
    "fremdkapital",
    "stammkapital",
    "Festkapital",
    "festes Kapital",
    "gebundenes Kapital",
    "Gesellschafter",
    "Mitglied",
    "Anteilsinhaber",
    "shareholder",
    "Partner",
    "Teilhaber",
    "Socius",
    "shareowner",
    "bondholder",
  ],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Barkapitalerhöhung",
  metaDescription:
    "Satzungsmäßiges Stammkapital der GmbH bzw. UG durch Gesellschafterbeschluss erhöhen. Juristisch geprüfte Muster/Vorlagen kostenlos downloaden.",
  description: `
   <p>
    Die Barkapitalerhöhung ist eine Möglichkeit für die Gesellschafter, um ihre GmbH mit <strong>liquiden Mitteln</strong> auszustatten.
    </p>

    <p>
    Weitere Möglichkeiten sind:
    </p>

    <p>
      <ul data-list-style="square">
        <li><p><a href="/beschluss-vorlagen/einzahlung-in-die-kapitalrucklage">Einzahlung in die Kapitalrücklage</a>.</p></li>
        <li><p><a href="/beschluss-vorlagen/gewahrung-eines-gesellschafterdarlehens">Gesellschafterdarlehen</a>,</p></li>

    <ul data-list-style="square">
      <li><p><a href="/beschluss-vorlagen/einzahlung-in-die-kapitalrucklage">Einzahlung in die Kapitalrücklage</a>.</p></li>
      <li><p><a href="/beschluss-vorlagen/gewahrung-eines-gesellschafterdarlehens">Gesellschafterdarlehen</a>,</p></li>
    </ul>

   <p>
     Der Kapitalerhöhungsbeschluss ist als Satzungsänderung beurkundungsbedürftig. Die Vorlage enthält eine  Notarmitarbeitervollmacht. Diese ermöglicht es, dass die Gesellschafter den Beschluss vorab unterschreiben können und dem Notariat sodann zur Beurkundung einreichen.  Eine Anwesenheit der Gesellschafter beim Notar ist für die Beurkundung dann nicht erforderlich.
   </p>

   <p>
     Die Übernahmeerklärung des einzelnen übernehmenden Gesellschafters muss ebenfalls beurkundet werden. Die Gesellschafter können hierfür auch unterschiedliche Notare aufsuchen.
   <p>
  `,
  antrag: `
  <p>
    <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
      <li><p>
        Das Stammkapital der Gesellschaft wird <strong>um ${insertTemplateVariable(
          stammkapitalErhöhungsbetrag
        )}</strong> auf ${insertTemplateVariable(
    stammkapitalBetragNachErhöhung
  )} (in Worten: ${insertTemplateVariable(
    stammkapitalBetragNachErhöhungInWorten
  )}) erhöht. Die Kapitalerhöhung erfolgt gegen Bareinlagen von insgesamt ${insertTemplateVariable(
    stammkapitalErhöhungsbetrag
  )} durch Ausgabe von ${insertTemplateVariable(
    neueGeschäftsanteileAnzahl
  )} neuen Geschäftsanteilen im Nennbetrag von jeweils ${insertTemplateVariable(
    neueGeschäftsanteileBetrag
  )}.
      </p></li>
      <li><p>
        Der Ausgabebetrag beträgt ${insertTemplateVariable(
          neueGeschäftsanteileAusgabebetrag
        )} je Geschäftsanteil.
      </p></li>
      <li><p>
        Die neuen Geschäftsanteile sind ab ${insertTemplateVariable(
          tagBeginnGewinnberechtigung
        )} gewinnberechtigt.
      </p></li>
      <li><p>
        Die Bareinlagen nebst Aufgeld sind sofort nach formgerechter Unterzeichnung der Übernahmeerklärung in voller Höhe zur Einzahlung fällig und auf das Konto der Gesellschaft ${insertTemplateVariable(
          bankDatenGesellschaft
        )} einzuzahlen.
      </p></li>
      <li><p>
        Zur Übernahme der neuen Geschäftsanteile wird als Gesellschafter zugelassen:

        <p>
          <ul data-list-style="none">
            ${insertTemplateVariable(übernahmeZugelasseneGesellschafter)}.
          </ul>
        </p>

        <p>
          Die Gesellschafter werden aufgefordert, die neuen Anteile bis zum ${insertTemplateVariable(
            übernahmeFrist
          )} zu übernehmen. Nicht übernommene Anteile werden den anderen Gesellschaftern innerhalb von weiteren ${insertTemplateVariable(
    übernahmeNachfristAndereGesellschafter
  )} zur Übernahme angeboten.
         </p>
      </p></li>
      <li><p>
        Mit Rücksicht auf die vorstehend beschlossene Stammkapitalerhöhung wird ${insertTemplateVariable(
          satzungStammkapital
        )} des Gesellschaftsvertrags (Stammkapital) wie folgt neu gefasst:

        <ul data-list-style="none">
           <p>
            „${insertTemplateVariable(satzungStammkapital)} Stammkapital
           </p

           <p>
             Das Stammkapital der Gesellschaft beträgt ${insertTemplateVariable(
               stammkapitalBetragNachErhöhung
             )} – in Worten: ${insertTemplateVariable(
    stammkapitalBetragNachErhöhungInWorten
  )}."
            </p>
        </ul>
      </p></li>
    </ol>
  </p>
  <p>
  Zum Zwecke der notariellen Beurkundung der vorstehenden Satzungsänderung erteilen der/die unterzeichnete/n Gesellschafter  den/dem Notarmitarbeiter/n  ${insertTemplateVariable(
    notarmitarbeiter
  )}, Vollmacht, jeweils unter Befreiung von § 181 BGB, alle für die vorstehende Satzungsänderung erforderlichen Erklärungen vor Notar und Gericht abzugeben sowie entgegenzunehmen, insbesondere Beschlüsse zu fassen; Untervollmacht kann erteilt werden.
  </p>
    `,
  begründung: undefined,
  templateVariables: [
    stammkapitalErhöhungsbetrag,
    stammkapitalBetragNachErhöhung,
    stammkapitalBetragNachErhöhungInWorten,
    neueGeschäftsanteileAnzahl,
    neueGeschäftsanteileBetrag,
    neueGeschäftsanteileAusgabebetrag,
    tagBeginnGewinnberechtigung,
    bankDatenGesellschaft,
    übernahmeZugelasseneGesellschafter,
    übernahmeFrist,
    übernahmeNachfristAndereGesellschafter,
    satzungStammkapital,
    notarmitarbeiter,
  ],
  isBeurkundungspflichtig: true,
  isHandelsregisterpflichtig: true,
  noteHandelsregisteranmeldung: `<p>Der Beschluss ist beurkundungspflichtig und ohne notarielle Beurkundung nicht wirksam (§ 53 Abs. 3 GmbHG). Der Notar kümmert sich auch um die zusätzlichen weiteren Formalien gegenüber dem Handelsregister:
  </p>

  <ul  data-list-style="square">
    <li>
      <p>Anmeldung zum Handelsregister (§ 54 Abs. 1 S. 1 GmbHG)</p>
    </li>
     <li>
      <p>Einreichung einer neuen Gesellschafterliste (§ 40 Abs. 2 GmbHG)</p>
    </li>
    <li>
      <p>Beifügung des vollständigen Wortlauts des (geänderten) Gesellschaftsvertrags nebst Notarbescheinigung (§ 54 Abs. 1 S. 2 GmbHG)</p>
    </li>
    <li>
      <p>Eintragung in das Handelsregister: <strong>Erst mit der Eintragung wird die Satzungsänderung wirksam.</strong> (§ 54 Abs. 3 GmbHG)</p>
    </li>
  </ul></p>`,
});
