import { Box, createStyles, makeStyles, NoSsr } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useTranslation } from "react-i18next";
import { homePath } from "../../../../helpers/general/frontendUrlPaths";
import { useSetShowVorlagenPicker } from "../../../../state/beschlussVorlage/beschlussVorlageSlice";
import {
  useSelectedVotingMode,
  useTriggerVotingModeChange,
} from "../../../../state/votingMode/votingModeSlice";
import { ResolvioLogo } from "../../../01-atoms/ResolvioLogo/ResolvioLogo";
import { SanitizeHTML } from "../../../01-atoms/SanitizeHTML/SanitzeHTML";
import DropdownText from "../../../02-molecules/DropdownText/DropdownText";
import {
  HeaderCenterComponent,
  HeaderColor,
  PageLayoutOptions,
} from "../PageLayoutInterfaces";
import { defaultLayout } from "./defaultLayout/defaultLayout";

export const useBeschlussCenterComponentStyles = makeStyles((theme) => {
  return createStyles({
    backButton: {
      marginRight: theme.spacing(1),
    },
    leftButton: {
      marginRight: theme.spacing(1),
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
      },
    },
    wrapper: {
      marginLeft: theme.spacing(3),
      overflow: "hidden",
      display: "flex",
      flexDirection: "row",
      justifyContent: "right",
      alignItems: "center",
      width: "100%",
      flexWrap: "nowrap",
      [theme.breakpoints.down("xs")]: {
        marginLeft: theme.spacing(1),
      },
    },
    typo: {
      marginBottom: 0,
      textAlign: "center",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
  });
});

const BeschlussCenterComponent: HeaderCenterComponent = (): JSX.Element => {
  const { t } = useTranslation();
  const votingMode = useSelectedVotingMode();
  const setIsPickerShowing = useSetShowVorlagenPicker();
  const triggerVotingModeChange = useTriggerVotingModeChange();

  const classes = useBeschlussCenterComponentStyles();

  return (
    <Box className={classes.wrapper}>
      <NoSsr>
        {votingMode && (
          <DropdownText
            className={classes.leftButton}
            iconOverride={<EditOutlinedIcon />}
            onClick={triggerVotingModeChange}
          >
            <SanitizeHTML
              tag="span"
              html={votingMode.label(t)}
              hyphenateText={true}
            />
          </DropdownText>
        )}
      </NoSsr>

      <DropdownText
        iconOverride={<EditOutlinedIcon />}
        onClick={() => setIsPickerShowing(true)}
      >
        {t("vorlagen.changeVorlage")}
      </DropdownText>
    </Box>
  );
};

export const beschlussLayout: PageLayoutOptions = {
  ...defaultLayout,
  centerContent: BeschlussCenterComponent,
  color: HeaderColor.Paper,
  logo: {
    label: (t) => t("header.zuResolvio"),
    component: ({ className }) => (
      <ResolvioLogo collapseOnMobile={true} className={className} />
    ),
    href: homePath,
  },
  ctaButton: undefined,
  loginButton: undefined,
  registerButton: undefined,
  disableMobileCollapse: true,
};
