import { nameGeschäftsführer, tagAbberufung } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGeschaftsfuhrerAbberufung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
  vorlageKategorie: VorlageKategorie.geschäftsführerAusscheiden,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.zugang, Vollzug.handelsregisterAnmeldung],
  bezug: [
    "GeschäftsführerAnstellungsvertragAufhebung",
    "GeschäftsführerAnstellungsvertragKündigungAusWichtigemGrund",
    "GeschäftsführerAnstellungsvertragKündigungOrdentlich",
    "GeschäftsführerEntlastungErteilung",
    "GeschäftsführerEntlastungVerweigerung",
    "GeschäftsführerGeltendmachungSchadenersatzansprüche",
  ],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Abberufung des Geschäftsführers",

  tags: [
    "Bestellung",
    "Widerruf der Bestellung",
    "Bestellung widerrufen",
    "abberufen",
    "Abberufung",
    "abbestellen",
    "Abbestellung",

    "Entscheider",
    "Entscheidungsträger",
    "Direktor",
    "Director",
    "Vorstand",
    "Vorsitzender",
    "Führung",
    "Leitung",
    "Leiter",
    "Geschäftsführung",
    "Geschäftsleitung",
    "Geschäftsleiter",
    "Chef",
    "Leiter",
    "Vorgesetzter",
    "Führungskraft",
    "Geschäftsführer",
    "Manager",
    "CEO",
    "CTO",
    "CFO",
    "leader",
    "head",
    "chief",
    "executive",
    "boss",
    "governor",
    "governance",
  ],
  metaDescription:
    "Gesellschafterbeschluss für Abberufung des Geschäftsführers aus der GmbH/UG. Von Anwälten geprüfte Muster/Vorlagen kostenlos ausfüllen und downloaden.",
  description: `
  <p>
    Die Abberufung wird auch "<strong>Widerruf der Bestellung</strong>" genannt.
  </p>
  <p>
    Sie entzieht dem Geschäftsführer die <strong>Vertretungs- und Geschäftsführungsbefugnis</strong>, sodass der abberufene Geschäftsführer grundsätzlich keine Handlungen mit Wirkung für und gegen die Gesellschaft mehr abschließen kann.
   </p>

   <p>
    Wichtig ist allerdings, dass die Abberufung <strong>rasch zum Handelsregister angemeldet</strong> wird. Denn bis zur Eintragung der Abberufung in das Handelsregister kann die verkehrsschützende Publizitätswirkung noch dazu führen, dass sich Dritte darauf berufen können, dass der abberrufene Geschäftsführer noch als amtierender Geschäftsführer im Handelsregister eingetragen war.
  </p>`,
  antrag: `${insertTemplateVariable(
    nameGeschäftsführer
  )}  wird mit Wirkung zum ${insertTemplateVariable(
    tagAbberufung
  )} als Geschäftsführer abberufen.`,
  begründung: undefined,
  templateVariables: [nameGeschäftsführer, tagAbberufung],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: true,
  noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss (§ 39 Abs. 1 GmbHG). Die Handelsregisteranmeldung muss von den Geschäftsführern in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
});
