import { bilanzStichtag } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageJahresabschlussAufstellung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.regelmäßigerBeschluss,
  vorlage_rolleTyp: [RolleInGesellschaft.Geschftsleiter],
  vollzug: [Vollzug.selfexecuting],
  bezug: [
    "EntlastungRegelmäßigAufsichtsrat",
    "EntlastungKomplementärRegelmäßig",
    "EntlastungRegelmäßigKomplementär",
    "EntlastungRegelmäßigGeschäftsführenderPartner",
    "EntlastungRegelmäßigBeirat",
    "EntlastungRegelmäßigGeschäftsführenderGesellschafter",
    "EntlastungRegelmäßigGeschäftsführer",
    "EntlastungRegelmäßigKomplementärGmbH",
    "AbschlussprüferBestellung",
    "JahresabschlussFeststellung",
    "JahresplanungFeststellung",
    "VerwendungDesGewinns",
  ],
  kurzBezeichnung: "Aufstellung des Jahresabschlusses",
  description: `
  <p>Mit dieser Vorlage beschließt die Geschäftsführung, dass sie den Jahresabschluss in der beigefügten Fassung für richtig hält und den Gesellschaftern zur Feststellung vorlegen möchte. Dies nennt man „Aufstellung des Jahresabschlusses” (auch wenn die Geschäftsführung für die tatsächliche Aufstellung in der Regel einen Steuerberater bzw. Wirtschaftsprüfer zur Hilfe nimmt).</p>

  <p>Der Aufstellungsbeschluss der Geschäftsführung ist die logische Voraussetzung dafür, dass die Gesellschafter die Feststellung des Jahresabschlusses und die Gewinnausschüttung etc. beschließen können. Deswegen ist eine rechtssichere Dokumentation auch des Aufstellungsbeschlusses der Geschäftsführung ratsam.</p>

 <p>Zu beachten ist, dass für die Geschäftsführer, die für die Aufstellung des Jahresabschlusses zuständig sind, gemäß § 264 Abs. 1 Sätze 3 und 4 HGB recht kurze Fristen gelten (3 Monate im Normalfall, für kleine GmbHs im Sinne von § 267 Abs. 1 GmbHG ausnahmsweise 6 Monate).</p>
 `,
  antrag: `Der Jahresabschluss zum ${insertTemplateVariable(
    bilanzStichtag
  )}  wird gemäß der beigefügten <strong>Anlage</strong> aufgestellt. Er ist unverzüglich den Gesellschaftern zur Feststellung vorzulegen.`,
  begründung: undefined,
  templateVariables: [bilanzStichtag],
  tags: [
    "Jahresabschluss",
    "Abschluss",
    "Bilanz",
    "Rechnungslegung",
    "Aufstellung",
    "Schlussrechnung",
    "Abrechnung",

    "feststellen",
    "Feststellung",
    "genehmigen",
    "absegnen",
    "zustimmen",
    "grünes Licht",
    "Weg frei machen",
    "Okay geben",
    "O.K. geben",
    "OK geben",
    "Erlaubnis",
    "Unterstützung",
    "erlauben",
    "unterstützen",
    "einwilligen",
    "Einwilligung",
    "Genehmigung",
    "Zustimmung",
    "Approval",
    "blessing",
    "confirmation",
    "consent",
    "endorsement",
    "green light",
    "persmission",
    "ratification",
    "support",

    "Gewinn",
    "Ergebnis",
    "Erlös",
    "Ertrag",
    "Marge",
    "Rendite",
    "schwarze Zahlen",
    "Überschuss",
    "profit",
    "winnings",
    "gain",
    "win",

    "Gewinnvortrag",
    "Vortrag",
    "Bilanzgewinn",
    "Kapitalrücklage",
    "freie Rücklage",

    "Ausschüttung",
    "Ergebnisverteilung",
    "Gewinnausschüttung",
    "Gewinnverwendung",
    "Verteilung",
    "Aufteilung",
    "Zuteilung",
    "Auszahlung",
    "Dividende",
    "distribution",
    "payout",
    "dividend",
    "disburse",
    "disbursement",
    "distribute",

    "jährlich",
    "Abberufen",
    "Abberufung",
    "Schadenersatz",
    "Risiko",
    "ordentliche Gesellschafterversammlung",
    "Haftung",
    "Verzicht",
    "Erlass",
    "Vertrauen",
    "Entscheider",
    "Entscheidungsträger",
    "Direktor",
    "Director",
    "Vorstand",
    "Vorsitzender",
    "Führung",
    "Leitung",
    "Leiter",
    "Geschäftsführung",
    "Geschäftsleitung",
    "Geschäftsleiter",
    "Chef",
    "Leiter",
    "Vorgesetzter",
    "Führungskraft",
    "Geschäftsführer",
    "Manager",
    "CEO",
    "CTO",
    "CFO",
    "leader",
    "head",
    "chief",
    "executive",
    "boss",
    "governor",
    "governance",

    "abschlussprüfer",
    "abschlussprüfung",
    "Jahresabschluss",
    "Wirtschaftsprüfer",
    "Wirtschaftsprüfungsgesellschaften",
    "Bilanz",
    "Bilanzprüfung",
    "Bestätigungsvermerk",
    "audit investigation",
    "auditing of accounts",
    "audit",
    "public accountant",
    "annual auditor",
    "statutory auditor",
    "auditor of annual accounts",
    "accounts",

    "bestellen",
    "Bestellung",
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
