import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { SpringConfig } from "@react-spring/core";
import theme from "../../../../layouts/theme";

const burgerButtonSize = "2.5rem";

export const scrollSpringConfig: SpringConfig = {
  tension: 183,
  friction: 32,
  precision: 0.005,
  clamp: true,
};

export const useNVONHeaderNavigationMenuStyles = makeStyles((them: Theme) =>
  createStyles({
    headerNav: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      flexGrow: 1,
      [theme.breakpoints.down("sm")]: {
        paddingTop: "100px",
        width: "100%",
        paddingBottom: theme.spacing(3),
        flexDirection: "column",
      },
      "&, & *": {
        lineHeight: 1,
      },
    },
    headerNavLinkList: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: 0,
      paddingLeft: 0,
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        flexDirection: "column",
      },
    },
    headerNavBurgerButton: {
      marginLeft: "auto",
      "& svg": {
        width: burgerButtonSize,
        height: burgerButtonSize,
      },
    },
    ctaButton: {
      position: "relative",
      zIndex: 1,
      margin: "0 auto",
      marginLeft: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        marginLeft: 0,
      },
    },
    resolutionButtonTypography: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.5rem",
      },
    },
    headerNavTop: {
      flex: 1,
      justifyContent: "center",
    },
  })
);
