import {
  nameBevollmächtigter,
  nameGeschäftsführer,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGeschaftsfuhrerAnstellungsvertragAufhebungBeauftragungAnlage: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.geschäftsführerAusscheiden,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.zugang],
    bezug: [
      "GeschäftsführerAbberufung",
      "GeschäftsführerAnstellungsvertragAufhebung",
      "GeschäftsführerEntlastungErteilung",
      "GeschäftsführerEntlastungVerweigerung",
    ],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung:
      "Aufhebungsvertrag Geschäftsführer: Beauftragung mit Anlage",
    description: undefined,
    antrag: `${insertTemplateVariable(
      nameBevollmächtigter
    )} wird damit beauftragt und hierzu bevollmächtigt, den <strong>in der Anlage beigefügten</strong> Aufhebungsvertrag mit

  <p>${insertTemplateVariable(nameGeschäftsführer)},</p>

  <p>im Namen der und mit Wirkung für die Gesellschaft zu schließen.</p>

  `,
    begründung: undefined,
    templateVariables: [nameBevollmächtigter, nameGeschäftsführer],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
