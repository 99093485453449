import { LegacyTemplate, LegacyTemplateBase } from "./VorlageInterface";
import { StripeProduct } from "./generatedTypes/StripeProduct";
import { premiumVorlagen } from "./vorlagenSamples/premiumVorlagen";

/**
 * Information about which template is premium or not is stored centrally (premiumVorlagen),
 *  not within every template.
 *
 * Since we still want to access the information on each template object,
 *  we add it using this function.
 */
export const getPaymentInfo = (
  template: LegacyTemplateBase
): Pick<LegacyTemplate, "availableWithProducts" | "isPremium"> => {
  const availableWithProducts = getAvailableWithProducts(template);

  return {
    availableWithProducts,
    isPremium: !availableWithProducts.includes(StripeProduct.Free),
  };
};

const getAvailableWithProducts = (
  template: LegacyTemplateBase
): LegacyTemplate["availableWithProducts"] => {
  if (premiumVorlagen[StripeProduct.Pro].includes(template)) {
    return [StripeProduct.Pro];
  }

  if (premiumVorlagen[StripeProduct.Starter].includes(template)) {
    return [StripeProduct.Starter, StripeProduct.Pro];
  }

  return [StripeProduct.Free, StripeProduct.Starter, StripeProduct.Pro];
};
