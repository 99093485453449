import { TranslatableText } from "../src/interfaces/TranslatableText";
import { Language } from "./LanguageConfiguration";
import { Page } from "./Pages";

export const mapPageToPath: { [key in Page]: TranslatableText } = {
  [Page.Home]: { [Language.English]: "", [Language.German]: "" },
  [Page.Imprint]: {
    [Language.English]: "imprint",
    [Language.German]: "impressum",
  },
  [Page.PrivacyPolicy]: {
    [Language.English]: "privacy-policy",
    [Language.German]: "datenschutz",
  },
  [Page.Wissen]: {
    [Language.English]: "wissen",
    [Language.German]: "wissen",
  },
  [Page.Grundlagen]: {
    [Language.English]: "wissen/grundlagen",
    [Language.German]: "wissen/grundlagen",
  },
};
