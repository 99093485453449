/* eslint-disable no-console */
import { isServerEnvironment } from "@nvon/baseline";
import { EnhancedStore } from "@reduxjs/toolkit";
import { debounce } from "lodash-es";
import { defaultStateOnDevelopment } from "../helpers/clientSide/constants";

/**
 * This function contains the generic logic of synchronizing a redux store to localStorage.
 *
 * It should be kept project-agnostic, and eventually put in a helper library.
 */

const LOCAL_STORAGE_KEY = "resolvioRedux";

export interface StoreSynchronizerOptions {
  /**
   * The synchonizing will be debounced by this value, meaning that it takes
   *  this time after the last store update to sync to the store.
   */
  debounceDuration: number;
}

const defaults: StoreSynchronizerOptions = {
  debounceDuration: 500,
};

export const persistStoreState = <RootState>(
  store: EnhancedStore<RootState>,
  options?: StoreSynchronizerOptions
): void => {
  if (isServerEnvironment() || !localStorage) {
    return;
  }

  const mergedOptions = { ...defaults, ...options };

  store.subscribe(
    /*
     * we use debounce to save the state once each 800ms
     * for better performances in case multiple changes occur in a short time
     */
    debounce(() => {
      saveState(store.getState());
    }, mergedOptions.debounceDuration)
  );
};

export type StateGetter<RootState> = (
  savedState: RootState | null
) => RootState;

/**
 * This function must be called in the store's preloadedState config.
 *
 * It accepts a stateGetter, which recieves the savedState and must
 *  return a valid RootState object.
 */
export const loadState = <RootState>(
  stateGetter: StateGetter<RootState>
): RootState => {
  const savedState =
    isServerEnvironment() || !localStorage ? null : getSavedState<RootState>();

  return stateGetter(savedState);
};

const getSavedState = <RootState>(): RootState | null => {
  try {
    const devStateOrNull =
      process.env.NODE_ENV === "production" ? null : defaultStateOnDevelopment;

    const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (!serializedState) {
      return devStateOrNull as RootState | null;
    }

    return JSON.parse(serializedState) as RootState;
  } catch (e) {
    console.error("Failed to load redux state from local storage.", e);

    return null;
  }
};

const saveState = <RootState>(state: RootState): void => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(LOCAL_STORAGE_KEY, serializedState);
  } catch (e) {
    console.error("Failed to save redux state to local storage.", e);
  }
};
