/**
 * URL-sichere Bezeichnung der Vorlage. Muss unique sein.
 */
export type URLSlug = string;
export type RelatedBeschlussVorlagen = unknown[];

/**
 * @deprecated this gets replaced by the LegacyTemplate interface
 *  found in the /vorlagen folder
 */
export interface Vorlage {
  id: string;
  description?: string;
  metaDescription?: string;
  tags?: string[];
  vorlageTyp: VorlageTyp;
  templateVariables: TemplateVariable[];
  urlSlug: URLSlug;
  /**
   * Rechtsformen, für die diese Vorlage geeignet ist
   */
  geeignetFuerRechtsformen: Rechtsform[];
  vorlageKategorie: VorlageKategorie | VorlageKategorie[];
  vorlage_rolleTyp: RolleInGesellschaft[];
  kurzBezeichnung: string;
  /**
   * An alternative title that's used when rendering the document.
   *
   * For example: "Allgmeine Beschlussvorlage" becomes "Beschlussfassung"
   *  inside the votingModeFormalPlenum header.
   */
  titleInDocument?: string;
  anhangName?: string;
  dateOfCreation?: string;
  anhangDatei?: string;
  bezug?: RelatedBeschlussVorlagen;
  zustimmungErforderlichDes?: string;
  antrag: string;
  begründung?: string;
  vollzug: Vollzug[];
  isBeurkundungspflichtig: boolean;
  isHandelsregisterpflichtig: boolean;
  noteHandelsregisteranmeldung?: string;
}

/**
 * Variabeln beschreiben die Stellen in der Vorlage, die für die Anpassung durch den Verwender vorgesehen sind.
 */
export interface TemplateVariable {
  name: string;
  variableType?: TemplateVariableType;
  description: string;
}
/**
 * This entity represents the type of a variable that's used in a template.
 */
export interface TemplateVariableType {
  baseType: BaseType;
  widget?: Widget;
}

export enum VorlageTyp {
  resolvioVorlage = "resolvioVorlage",
  vorlageAusBeschluss = "vorlageAusBeschluss",
}
export enum BaseType {
  number = "number",
  string = "string",
  static = "static",
}
export enum Widget {
  textarea = "textarea",
}
/**
 * @deprecated should be replaced by CompanyTypeName
 */
export enum Rechtsform {
  UG = "UG",
  GmbH = "GmbH",
  GmbHundCoKG = "GmbHundCoKG",
  UGundCoKG = "UGundCoKG",
  KG = "KG",
  oHG = "oHG",
  GbR = "GbR",
  Partnerschaft = "Partnerschaft",
  PartGmbB = "PartGmbB",
  eV = "e.V.",
  // Stiftung = "Stiftung",
}
export enum VorlageKategorie {
  regelmäßigerBeschluss = "regelmäßigerBeschluss",
  geschäftsführerAusscheiden = "geschäftsführerAusscheiden",
  geschäftsführerNeu = "geschäftsführerNeu",
  ausscheidenEinesGesellschafters = "ausscheidenEinesGesellschafters",
  geschäftsführer = "geschäftsführer",
  steuerrecht = "steuerrecht",
  liquidation = "liquidation",
  beirat = "beirat",
  mitarbeiter = "mitarbeiter",
  weisungGeschäftsführer = "weisungGeschäftsführer",
  leererBeschluss = "leererBeschluss",
  bestätigung = "bestätigung",
  kapital = "kapital",
  struktur = "struktur",
  handelsrecht = "handelsrecht",
  prokura = "prokura",
  sanierung = "sanierung",
  übertragungBeteiligung = "übertragungBeteiligung",
  startup = "startup",
  gesellschaftsvertrag = "gesellschaftsvertrag",
  anbauvereinigung = "anbauvereinigung",
}
/**
 * Die Rolle, die eine Person innerhalb einer Gesellschaft innehat.
 */

export enum NamesForMeeting {
  versammlung = "versammlung",
  sitzung = "sitzung",
}

export enum RolleInGesellschaft {
  Mitglieder = "Mitglieder",
  Geschftsleiter = "Geschäftsleiter",
  Aufsichtsrat = "Aufsichtsrat",
  Beirat = "Beirat",
}

export enum Vollzug {
  selfexecuting = "selfexecuting",
  beurkundung = "beurkundung",
  handelsregisterAnmeldung = "handelsregisterAnmeldung",
  handelsregisterEintragung = "handelsregisterEintragung",
  handelsregisterGesellschafterliste = "handelsregisterGesellschafterliste",
  transparenzregister = "transparenzregister",
  zustimmungGesellschafter = "zustimmungGesellschafter",
  zustimmungBetroffener = "zustimmungBetroffener",
  kapErhöhung_abgabeÜbernahmeerklärungen = "kapErhöhung_abgabeÜbernahmeerklärungen",
  kapErhöhung_sacheinlageEinbringungsvertrag = "kapErhöhung_sacheinlageEinbringungsvertrag",
  kapErhöhung_sacheinlageSachkapitalerhöhungsbericht = "kapErhöhung_sacheinlageSachkapitalerhöhungsbericht",
  kapErhöhung_sacheinlageUnterlagenWerthaltigkeit = "kapErhöhung_sacheinlageUnterlagenWerthaltigkeit",
  zugang = "zugang",
  bundesanzeiger = "bundesanzeiger",
}
