import { RolleInGesellschaft } from "../../../common-interfaces/VorlageInterface";
import { CompanyTypeLandingpage } from "../../../helpers/clientSide/constants";
import { GrCase } from "../../../interfaces/GrCase";
import { GrNumber } from "../../../interfaces/grNumber";
import { StringInNumberAndCase } from "../../../interfaces/StringInNumberAndCase";
import { CompanyTypeName } from "../../vorlagen/vorlagenShared/generatedTypes/CompanyTypeName";
import { AdditionalRole } from "../namesForRoles/additionalRolesForRechtsform";

const versammlung = {
  [GrNumber.Singular]: {
    [GrCase.Nominative]: "Versammlung",
    [GrCase.Genitive]: "Versammlung",
    [GrCase.CompoundForm]: "Versammlungs",
  },
  [GrNumber.Plural]: {
    [GrCase.Nominative]: "Versammlungen",
    [GrCase.Genitive]: "Versammlungen",
    [GrCase.CompoundForm]: "Versammlungs",
  },
};

const sitzung = {
  [GrNumber.Singular]: {
    [GrCase.Nominative]: "Sitzung",
    [GrCase.Genitive]: "Sitzung",
    [GrCase.CompoundForm]: "Sitzungs",
  },
  [GrNumber.Plural]: {
    [GrCase.Nominative]: "Sitzungen",
    [GrCase.Genitive]: "Sitzungen",
    [GrCase.CompoundForm]: "Sitzungs",
  },
};

const beirat = {
  [RolleInGesellschaft.Beirat]: {
    ...sitzung,
  },
};

const gesellschafter = {
  [RolleInGesellschaft.Mitglieder]: {
    ...versammlung,
  },
};

const geschaeftsfuehrer = {
  [RolleInGesellschaft.Geschftsleiter]: {
    ...sitzung,
  },
};

const aufsichtsrat = {
  [RolleInGesellschaft.Aufsichtsrat]: {
    ...sitzung,
  },
};

const coKgKommanditist = {
  [AdditionalRole.CoKgKommanditist]: {
    ...versammlung,
  },
};

const partner = {
  [RolleInGesellschaft.Mitglieder]: {
    ...sitzung,
  },
};

const komplementaere = {
  [RolleInGesellschaft.Geschftsleiter]: {
    ...versammlung,
  },
};

const geschaeftsfuehrenderGesellschafter = {
  [RolleInGesellschaft.Geschftsleiter]: {
    ...versammlung,
  },
};

const geschaeftsfuehrenderPartner = {
  [RolleInGesellschaft.Geschftsleiter]: {
    ...sitzung,
  },
};

const vorstandsmitglieder = {
  [RolleInGesellschaft.Geschftsleiter]: {
    ...sitzung,
  },
};

const mitglieder = {
  [RolleInGesellschaft.Mitglieder]: {
    ...versammlung,
  },
};

const aufsichtsratBeirat = {
  ...aufsichtsrat,
  ...beirat,
};

const kg = {
  ...gesellschafter,
  ...komplementaere,
  ...aufsichtsratBeirat,
  ...coKgKommanditist,
};

const gmbh = {
  ...gesellschafter,
  ...geschaeftsfuehrer,
  ...aufsichtsratBeirat,
};

const personenGesellschaft = {
  ...gesellschafter,
  ...geschaeftsfuehrenderGesellschafter,
  ...aufsichtsratBeirat,
};

const partnerSchaft = {
  ...partner,
  ...geschaeftsfuehrenderPartner,
  ...aufsichtsratBeirat,
};

const eV = {
  ...vorstandsmitglieder,
  ...mitglieder,
  ...aufsichtsratBeirat,
};

/* const stiftung = {
  ...gesellschafter,
  ...vorstandsmitglieder,
  ...aufsichtsratBeirat,
}; */

/**
 * Depending on the Rechtsform and Rolle, the meeting has a different name.
 *
 * This name can be retrieved in different numbers (singular/plural), and different cases.
 */
export const meetingNamesForRechtsform: Record<
  CompanyTypeLandingpage,
  Record<RolleInGesellschaft, StringInNumberAndCase>
> = {
  [CompanyTypeName.GmbHDe]: {
    ...gmbh,
  },
  [CompanyTypeName.UgDe]: {
    ...gmbh,
  },
  [CompanyTypeName.UgCoKgDe]: {
    ...kg,
  },
  [CompanyTypeName.GmbHCoKgDe]: {
    ...kg,
  },
  [CompanyTypeName.KgDe]: {
    ...kg,
  },
  [CompanyTypeName.OHgDe]: {
    ...personenGesellschaft,
  },
  [CompanyTypeName.GbRDe]: {
    ...personenGesellschaft,
  },
  [CompanyTypeName.PartGDe]: {
    ...partnerSchaft,
  },
  [CompanyTypeName.PartGmbBDe]: {
    ...partnerSchaft,
  },
  [CompanyTypeName.EVDe]: {
    ...eV,
  },
};

export const meetingNamesForAdditionalRoles: Record<
  AdditionalRole,
  StringInNumberAndCase
> = {
  ...coKgKommanditist,
};
