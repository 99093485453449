import {
  nameGeschäftsführer,
  nameGeschäftsleiter,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGeschaftsfuhrerBefreiung181: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
  ],
  vorlageKategorie: VorlageKategorie.geschäftsführerNeu,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.zugang, Vollzug.handelsregisterAnmeldung],
  bezug: [
    "GeschäftsführerBestellung",
    "GeschäftsführerVertretungsbefugnisEinzel",
    "GeschäftsführerVertretungsbefugnisGesamt",
    "GeschäftsführerVertretungsbefugnisGemischt",
  ],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Befreiung von den Beschränkungen des § 181 BGB (generell)",
  description: undefined,
  metaDescription:
    "Befreiung des Geschäftsführers von Verbot des Insichgeschäfts und der Mehrfachvertretung (§ 181 BGB) mit Gesellschafterbeschluss. Muster/Vorlagen kostenlos.",
  antrag: `  <p>
  ${insertTemplateVariable(
    nameGeschäftsleiter
  )} wird von den Beschränkungen des § 181 BGB befreit, d.h. ${insertTemplateVariable(
    nameGeschäftsführer
  )} kann im Namen der Gesellschaft sowohl mit sich im eigenen Namen oder als auch als Vertreter eines Dritten ein Rechtsgeschäft vornehmen.
    </p>`,
  begründung: undefined,
  templateVariables: [nameGeschäftsleiter, nameGeschäftsführer],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: true,
  noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss (§ 8 Abs. 4 GmbHG). Die Handelsregisteranmeldung muss von den Geschäftsführern in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
});
