import {
  nameGesellschafter,
  nameProzessvertreter,
  wichtigerGrund,
  wichtigerGrundAbwägung,
  wichtigerGrundEntgegnung,
  wichtigerGrundUltimaratio,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGesellschafterAusschlussKlagePersG: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.OHgDe,
  ],
  vorlageKategorie: VorlageKategorie.ausscheidenEinesGesellschafters,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.zugang, Vollzug.handelsregisterGesellschafterliste],
  bezug: [],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Ausschluss eines Gesellschafters mit Ausschlussklage",
  description: undefined,
  antrag: `<ol><li><p>${insertTemplateVariable(
    nameGesellschafter
  )} soll durch gerichtliches Urteil aus wichtigem Grund aus der Gesellschaft ausgeschlossen werden.</p></li>

  <li><p>Zum besonderen Prozessvertreter der Gesellschaft für die Erhebung und Führung der Ausschlussklage wird ${insertTemplateVariable(
    nameProzessvertreter
  )} bestellt.</p></li>

  <p>Begründung:</p>
  <p>Die Ausschließung von ${insertTemplateVariable(
    nameGesellschafter
  )} aus der Gesellschaft ist gerechtfertigt, denn es liegt ein wichtiger Grund in der Person von ${insertTemplateVariable(
    nameGesellschafter
  )} vor, der nach Maßgabe einer unter Einbeziehung aller relevanter Umstände vorzunehmenden Gesamtbewertung seine weitere Mitgliedschaft in der Gesellschaft als untragbar erscheinen lässt bzw. die Erreichung des Gesellschaftszwecks ernsthaft gefährdet oder unmöglich macht. Der wichtige Grund lässt sich auch nicht auf eine andere Weise als durch Ausschließung aus der Gesellschaft beheben. </p>

  <p>Folgender wichtiger Grund liegt in der Person von ${insertTemplateVariable(
    nameGesellschafter
  )}vor: ${insertTemplateVariable(wichtigerGrund)}.</p>

  <p>Das Vorliegen des wichtigen Grundes lässt die weitere Mitgliedschaft in der Gesellschaft aufgrund der folgenden Abwägungsgesichtspunkte als untragbar erscheinen: ${insertTemplateVariable(
    wichtigerGrundAbwägung
  )}.</p>

   <p>Der wichtige Grund lässt sich auch nicht auf eine andere Weise als durch Ausschließung aus der Gesellschaft beheben, denn: ${insertTemplateVariable(
     wichtigerGrundUltimaratio
   )}.</p>

   <p>${insertTemplateVariable(
     nameGesellschafter
   )} wurde mit den vorstehenden ausschlussbegründenden Umstände im Einzelnen konfrontiert und ihm Gelegenheit zur Stellungnahme gegeben. Hierzu erklärte er: ${insertTemplateVariable(
    wichtigerGrundEntgegnung
  )}.</p>
 `,
  begründung: undefined,
  templateVariables: [
    nameGesellschafter,
    nameProzessvertreter,
    wichtigerGrund,
    wichtigerGrundAbwägung,
    wichtigerGrundUltimaratio,
    wichtigerGrundEntgegnung,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
