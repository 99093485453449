import { noop } from "lodash-es";
import { createContext } from "react";
import { TemplateVariableKey } from "../../../../interfaces/variousInterfaces";

export enum BeschlussGeneratorState {
  /**
   * The user is filling in data.
   */
  UserInput = "UserInput",
  /**
   * The final documents are being generated.
   */
  Generating = "Generating",
  /**
   * The generated documents are ready to be downloaded.
   */
  DocumentsReady = "DocumentsReady",
}

export type ExternallyFocusedStep = {
  step: number;
} | null;

/**
 *
 * This interface represents the non-permanent state of the user interface.
 *
 * - user input (stuff they put in fields) typically should be preserved, and belongs in redux
 * - focus/hover states (what field they're currently editing) should not be preserved, this belongs here
 */
export interface EphemeralUserInputState {
  generatorState: BeschlussGeneratorState;
  setGeneratorState: (newState: BeschlussGeneratorState) => void;
  /**
   * Key of the variable the user is currently editing.
   */
  currentlyFocusedVariable: TemplateVariableKey | null;
  /**
   * This assigns a number to each variable key.
   * By increasing this number, we can trigger an effect on the input field.
   */
  externallyFocusedVariables: Record<TemplateVariableKey, number> | null;
  /**
   * Reset all counters
   */
  resetExternallyFocusedVariables: VoidFunction;
  focusVariable: (variableKey: TemplateVariableKey) => void;
  unFocusVariable: () => void;
  externallyFocusVariable: (variableKey: TemplateVariableKey) => void;
  externallyFocusedStep: ExternallyFocusedStep;
  /**
   * Used to set focus on a certain step programatically.
   * Always replace the full object (not just the number) to trigger listeners.
   */
  externallyFocusStep: (stepNumber: number) => void;
}

export const EphemeralUserInputContext = createContext<EphemeralUserInputState>(
  {
    generatorState: BeschlussGeneratorState.UserInput,
    setGeneratorState: noop,
    externallyFocusStep: noop,
    currentlyFocusedVariable: null,
    externallyFocusedStep: null,
    externallyFocusedVariables: null,
    focusVariable: noop,
    resetExternallyFocusedVariables: noop,
    unFocusVariable: noop,
    externallyFocusVariable: noop,
  }
);
