import { variableTypeListOfBoardMembers } from "./variableTypes/variableTypeListOfBoardMembers";
import { variableTypeNumber } from "./variableTypes/variableTypeNumber";
import { variableTypeNumberMoney } from "./variableTypes/variableTypeNumberMoney";
import { variableTypeNumberSlider } from "./variableTypes/variableTypeNumberSlider";
import { variableTypeString } from "./variableTypes/variableTypeString";
import { variableTypeSwitch } from "./variableTypes/variableTypeSwitch";
import { variableTypeTextarea } from "./variableTypes/variableTypeTextarea";
import { variableTypeVariants } from "./variableTypes/variableTypeVariants";
import { TemplateVariable } from "./VorlageInterface";

export const vorlagenPickerLinkTag = "vorlagen-picker-link";

export const muster: TemplateVariable = {
  name: "Muster",
  description: "Das ist ein Muster.",
  variableType: variableTypeString,
};

export const freitext: TemplateVariable = {
  name: "Beschlussinhalt",
  description: `Hier können Sie einen freien Beschlussinhalt formulieren.<br /><br />Alternativ halten wir zahlreiche professionelle Vorlagen bereit.`,
  variableType: variableTypeTextarea,
};

export const listOfBoardMembers: TemplateVariable = {
  name: "Liste der Board Members",
  description:
    'Listet alle Board Members auf. Zwischen dem vorletzten und dem letzten aufgelisteten Board Member wird ein "und" gesetzt.',
  variableType: variableTypeListOfBoardMembers,
};

// Abschlussprüfer

export const nameAbschlussprüfer: TemplateVariable = {
  name: "Name des Abschlussprüfers",
  description:
    "Name des Wirtschaftsprüfers bzw. der Wirtschaftsprüfungsgesellschaft, der für das laufende Geschäftsjahr bestellt werden soll.",
  variableType: variableTypeString,
};

export const zuPrüfendesJahr: TemplateVariable = {
  name: "laufendes Geschäftsjahr",
  description:
    "Geschäftsjahr, welches der Abschlussprüfer prüfen soll, z.B. 2022.",
  variableType: variableTypeString,
};

export const abschlussjahr: TemplateVariable = {
  name: "Abschlussjahr",
  variableType: variableTypeString,
  description: "Jahr, auf das sich der Jahresabschluss bezieht",
};

// Anstellung

export const beginnGeschäftsführertätigkeit: TemplateVariable = {
  name: "Beginn der Geschäftsführertätigkeit",
  description:
    "Tag, an welchem der Geschäftsführer seine Geschäftsführungstätigkeit aufnehmen soll. Beispiel: 01. Januar 2022.",
  variableType: variableTypeString,
};

export const wochenKündigungsfrist: TemplateVariable = {
  name: "Frist in Wochen für Kündigung zum Quaraltsende",
  description:
    "Kündigungsfrist in Wochen, die für eine Kündigung zum Quartalsende gelten sollen. Beispiel: 4.",
  variableType: variableTypeString,
};

export const urlaubstageAnzahl: TemplateVariable = {
  name: "Urlaubstage",
  description: "Anzahl der Urlaubstage. Beispiel: 30.",
  variableType: variableTypeString,
};

export const vergütungTantieme: TemplateVariable = {
  name: "Prozentsatz für Gewinntantieme",
  description:
    "Prozentsatz für Gewinntantieme bezogen auf Höhe des Jahresüberschusses der Steuerbilanz nach Verrechnung mit Verlustvorträgen und vor Abzug der Körperschaft- und Gewerbesteuer. Beispiel: 10.",
  variableType: variableTypeString,
};

export const vergütungWeihnachten: TemplateVariable = {
  name: "Betrag des 13. Gehalt",
  description:
    "Höhe der Weihnachtsgratifikation (13. Gehalt). Das 13. Gehalt wird nach der Regelung des Anstellungsvertrages nur ausgezahlt, wenn das Dienstverhältnis mit dem Geschäftsführer während eines gesamten Kalenderjahres bestanden hat. Beispiel: 5.000 EUR.",
  variableType: variableTypeString,
};

export const vergütungFix: TemplateVariable = {
  name: "Jahresbetrag des Grundgehalts",
  description:
    "Höhe des Grundgehalts (im Jahr), das in monatlichen Teilbeträgen ausgezahlt wird. Beispiel: 100.000 EUR.",
  variableType: variableTypeString,
};

// Auflösung

export const nameVerwahrer: TemplateVariable = {
  name: "Name des Verwahrers",
  description:
    "Person, die die Bücher und Schriften der Gesellschaft für die Dauer von 10 Jahren aufbewahren muss. Beispiel: Max Müller.",
  variableType: variableTypeString,
};

export const anschriftVerwahrer: TemplateVariable = {
  name: "Anschrift des Verwahrers",
  description: "Beispiel: Erlenweg 10, 56220 Koblenz.",
  variableType: variableTypeString,
};

export const datumAuflösungsbeschluss: TemplateVariable = {
  name: "Datum des Auflösungsbeschlusses",
  description: "Beispiel: 01.11.2020.",
  variableType: variableTypeString,
};

export const tagBestellungLiquidator: TemplateVariable = {
  name: "Bestellungstag",
  description:
    "Tag, ab dem der Liquidiator Geschäftsführungs- und Vertretungsbefugnis haben soll.",
  variableType: variableTypeString,
};

export const nameLiquidator: TemplateVariable = {
  name: "Name des Liquidators ",
  description:
    "Name des Liquidators, den der Beschluss betreffen soll. Beispiel: Marlene Musterfrau",
  variableType: variableTypeString,
};

export const geburtsdatumLiquidator: TemplateVariable = {
  name: "Geburtsdatum des Liquidators",
  description:
    "Geburtsdatum des Liquidators, den der Beschluss betreffen soll. Beispiel: 30.06.1988",
  variableType: variableTypeString,
};

export const adresseLiquidator: TemplateVariable = {
  name: "Adresse des Liquidators",
  description:
    "Straße, PLZ und Ort des Liquidators, den der Beschluss betreffen soll. Dabei ist die Wohnadresse anzugeben Beispiel: Lindenweg 10, 60316 Frankfurt am Main",
  variableType: variableTypeString,
};

// Aufsichtsrat

export const vorstandZustimmungspflichtSchwelle: TemplateVariable = {
  name: "Wertschwelle für zustimmungspflichtige Geschäfte",
  description: `<p>
        Diese Wertschwelle gibt an, ab welchem Wert bei bestimmten Geschäften die Zustimmung des Aufsichtsrats erforderlich ist.
    </p>
    <p>
        Die Festlegung des konkreten Betrags hängt von dem konkreten Unternehmen ab und sollte an der folgenden Frage ausgerichtet werden: Welcher Betrag ist bei dem Unternehmen so erheblich, dass sich die Ertragsaussichten oder die Risikoexposition der Gesellschaft grundlegend verändert?
    </p>
     <p>
        Beispiel: 100.000 €
    </p`,
  variableType: variableTypeNumberMoney,
};

export const vorstandZustimmungspflichtSchwelleMitarbeiter: TemplateVariable = {
  name: "Mitarbeiter-Schwelle für zustimmungspflichtige Geschäfte",
  description: `<p>
        Produktionsstandorte, die diese Mitarbeiter-Schwelle überschreiten, machen die Zustimmung des Aufsichtsrats erforderlich.
    </p>
    <p>
        Beispiel: "10" Mitarbeiter
    </p>`,
  variableType: variableTypeNumber,
  unitInput: "Mitarbeiter",
  unitOutput: "Mitarbeitern",
};

export const aufsichtsratPflichtsitzungenAnzahl: TemplateVariable = {
  name: "Pflichtsitzungen pro Halbjahr",
  step: 1,
  sliderValues: [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 4, label: "4" },
    { value: 6, label: "6" },
  ],
  min: 1,
  max: 6,
  defaultValue: 2,
  unitOutput: "Sitzungen",
  unitOutputSingle: "Sitzung",
  description: `
    <p>
        Die gesetzliche Mindestanzahl der Pflichtsitzungen ist abhängig von der Börsennotierung der Gesellschaft.
    </p>
    <p>
         Bei <strong>börsennotierten</strong> Gesellschaften muss der Aufsichtsrat gemäß § 110 Abs. 3 AktG zwei Sitzungen im Kalenderhalbjahr abhalten.
    </p>
    <p>
        Bei <strong>nicht-börsennotierten</strong> Gesellschaften kann in der Geschäftsordnung eine geringere Mindestanzahl beschlossen werden. Allerdings sind in der Praxis bei nicht ganz kleinen Aktiengesellschaften auch hier 2 Pflichtsitzungen im Kalenderhalbjahr die Regel, um eine effektive Überwachung des Vorstands zu gewährleisten.
    </p>
    `,
  variableType: variableTypeNumberSlider,
};

export const aufsichtsratLadungsfristWochen: TemplateVariable = {
  name: "Ladungsfrist in Wochen",
  step: 1,
  sliderValues: [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
  ],
  min: 1,
  max: 4,
  defaultValue: 2,
  description: `
    <p>
        Die Ladungsfrist ist der Abstand zwischen der Einladung zur Sitzung und dem Tag, an dem die Sitzung stattfindet.  Für Aufsichtsratssitzungen ist eine Ladungsfrist von 1 bis 2 Wochen gängig.
    `,
  variableType: variableTypeNumberSlider,
  unitOutput: "Wochen",
  unitOutputSingle: "Woche",
};

export const nameAufsichtsratsmitglied: TemplateVariable = {
  name: "Name des Aufsichtsratsmitglieds",
  description: "Name des Aufsichtsratsmitglieds. Beispiel: Marlene Musterfrau.",
  variableType: variableTypeString,
};

export const ausschussPraesidial: TemplateVariable = {
  name: "Präsidialausschuss",
  description: `
        <p>
            Der Präsidialausschuss kümmert sich in der Regel vor allem um die Vorbereitung der Aufsichtsratssitzungen und die Personalentscheidungen des Aufsichtsrats.
        <p>
    `,
  variableType: variableTypeSwitch,
  html: `<li>
        <h3 data-list-style="decimal" data-list-style-prefix="§ ">
            Präsidialausschuss
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Der Aufsichtsrat hat einen Präsidialausschuss.
                </p>
            </li>
            <li>
                <p>
                    Der Präsidialausschuss besteht aus dem Vorsitzenden des Aufsichtsrats, seinem Stellvertreter und einem weiteren, vom Aufsichtsrat zu wählenden Mitglied.
                </p>
            </li>
            <li>
                <p>
                    Der Präsidialausschuss hat folgende Aufgaben:
                </p>
                <ol data-list-style="lower-latin">
                    <li>
                        <p>
                            Vorbereitung von Aufsichtsratssitzungen;
                        </p>
                    </li>
                    <li>
                        <p>
                            die Beratung über wichtige Vorlagen, die der Vorstand dem Aufsichtsrat vorlegen wird;
                        </p>
                    </li>
                    <li>
                        <p>
                            die Vorbereitung der Personalentscheidungen des Aufsichtsrats, insbesondere auch die Vorbereitung der Gesamtvergütung der Vorstandsmitglieder und des Vergütungssystems des Vorstands;
                        </p>
                    </li>
                    <li>
                        <p>
                            Beschlussfassung gemäß dem nachfolgenden Abs. 4.
                        </p>
                    </li>
                </ol>
            </li>
            <li>
                <p>
                    Der Präsidialausschuss ist ermächtigt, an Stelle des Aufsichtsrats über folgende Angelegenheiten zu beschließen:
                </p>
                <ol data-list-style="lower-latin">
                    <li>
                        <p>
                            sonstige Rechtsgeschäfte gegenüber Vorstandsmitgliedern nach § 112 AktG;
                        </p>
                    </li>
                    <li>
                        <p>
                            Zustimmung zur Erteilung von Prokuren und Generalvollmachten.
                        </p>
                    </li>
                </ol>
            </li>
            <li>
                <p>
                    Der Präsidialausschuss ist nur beschlussfähig, wenn alle Mitglieder an der Beschlussfassung mitwirken. Über seine Beschlüsse ist regelmäßig in der nächstfolgenden Aufsichtsratssitzung zu berichten.
                </p>
            </li>
        </ol>
    </li>`,
};

export const ausschussTechnologie: TemplateVariable = {
  name: "Technologieausschuss",
  description: `<p>
        Der Technologieausschuss berät den Gesamtaufsichtsrats bei Fragen der Digitalisierung und IT-Sicherheit/technologische Innovationen und erarbeitet entsprechende Handlungsempfehlungen für den Gesamtaufsichtsrat.
    </p>
    `,
  variableType: variableTypeSwitch,
  html: `<li>
        <h3 data-list-style="decimal" data-list-style-prefix="§ ">
            Technologieausschuss
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Der Aufsichtsrat hat einen Technologieausschuss.
                </p>
            </li>
                </p>
            </li>
            <li>
                <p>
                    Dem Technologieausschuss gehören drei vom Aufsichtsrat zu wählende Aufsichtsratsmitglieder an.
                </p>
            </li>
            <li>
                <p>
                    Der Technologieausschuss berät den Gesamtaufsichtsrats bei Fragen der Digitalisierung und IT-Sicherheit/technologische Innovationen und erarbeitet entsprechende Handlungsempfehlungen für den Gesamtaufsichtsrat.
                </p>
            </li>

        </ol>
    </li>`,
};

export const ausschussPruefung: TemplateVariable = {
  name: "Audit Committee (Prüfungsausschuss)",
  description: `<p>
        Das Audit Committee wird auch Prüfungsausschuss genannt. Es befasst sich insbesondere den folgenden Fragen:
    </p>
    <ul>
        <li>
             <p>
            Rechnungslegung,
             </p>
        </li>
        <li>
             <p>
            Wirksamkeit des internen Kontrollsystems,
             </p>
        </li>
        <li>
            <p>
            Risikomanagementsystem und internes Revisionssystem
             </p>
        </li>
        <li>
             <p>
                Abschlussprüfung /Auswahl und Unabhängigkeit des Abschlussprüfers, Erteilung des Prüfungsauftrages an den Abschlussprüfer, Bestimmung von Prüfungsschwerpunkten und Honorarvereinbarung).
            </p>
         </li>`,
  variableType: variableTypeSwitch,
  html: `<li>
        <h3 data-list-style="decimal" data-list-style-prefix="§ ">
            Audit Committee
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Der Aufsichtsrat hat ein Audit Committee (Prüfungsausschuss).
                </p>
            </li>
            <li>
                <p>
                    Dem Audit Committee gehören drei vom Aufsichtsrat zu wählende Aufsichtsratsmitglieder an. Der Vorsitzende des Audit Committee soll weder der Aufsichtsratsvorsitzende noch ein ehemaliges Vorstandsmitglied der Gesellschaft sein, dessen Bestellung vor weniger als 2 Jahren endete, und soll unabhängig von Vorstand und Gesellschaft sein. Die Aufsichtsratsmitglieder müssen über besonderen Sachverstand und Erfahrungen in der Anwendung von Rechnungslegungsgrundsätzen und internen Kontrollverfahren verfügen und mit dem Sektor, in dem die Gesellschaft tätig ist, vertraut sein. Zudem muss er mehrheitlich mit Mitgliedern besetzt sein, bei denen keine Besorgnis eines Interessenkonflikts aufgrund ihrer Beziehung zu nahestehenden Personen gemäß § 111a Abs. 1 S. 2 AktG besteht. Für die Ausschussmitglieder wird ein Ersatzmitglied bestellt, das den zuvor genannten Aufforderungen entspricht.
                </p>
            </li>
            <li>
                <p>
                    Das Audit Committee befasst sich insbesondere mit Fragen der Rechnungslegung, der Wirksamkeit des internen Kontrollsystems, des Risikomanagementsystems und des internen Revisionssystems sowie der Abschlussprüfung und der Compliance, der erforderlichen Auswahl und Unabhängigkeit des Abschlussprüfers, der Erteilung des Prüfungsauftrages an den Abschlussprüfer, der Bestimmung von Prüfungsschwerpunkten und der Honorarvereinbarung. Das Audit Committee wird von dem vorgesehenen Abschlussprüfer alle Informationen einholen, die zur Beurteilung der Unabhängigkeit des Abschlussprüfers geeignet sind. Wird für die Wahl des Abschlussprüfers ein anderer als der bisherige Abschlussprüfer vorgeschlagen, müssen mindestens zwei Kandidaten in der Empfehlung umfasst sein. Der Aufsichtsrat soll mit dem Abschlussprüfer vereinbaren, dass der Vorsitzende des Audit Committee über während der Prüfung auftretende mögliche Ausschluss- oder Befangenheitsgründe unverzüglich unterrichtet wird, sofern diese nicht beseitigt werden. Das Audit Committee soll regelmäßig die Beurteilung der Wirksamkeit der Abschlussprüfung vornehmen.
                </p>
            </li>
            <li>
                <p>
                    Das Audit Committee bereitet die Entscheidung des Aufsichtsrats über die Feststellung des Jahresabschlusses vor. Ihm obliegt eine Vorprüfung des Jahresabschlusses, des Lageberichts, des Konzernabschlusses, des Konzernlageberichts und des Vorschlags für die Gewinnverwendung. Der Abschlussprüfer soll an diesen Sitzungen des Audit Committee teilnehmen.
                </p>
            </li>
            <li>
                <p>
                    Das Audit Committee ist weiterhin befugt über die Zustimmung nach § 111b Abs. 1 AktG zu beschließen. Vor dem Beschluss ist zu prüfen, ob bei einem der Mitglieder eine konkrete Beziehung geschäftlicher, finanzieller oder persönlicher Art vorliegt, die das Urteilsvermögen des Mitglieds bei der Entscheidungsfindung beeinflussen könnte. Dabei ist jedes Mitglied verpflichtet, eine mögliche schädliche Beziehung zu offenbaren. Liegt nach Ansicht des Gesamtaufsichtsrats eine solche vor, scheidet das Mitglied aus dem Audit Committee aus und das Ersatzmitglied rückt ein.
                </p>
            </li>
             <li>
                <p>
                    Die einzelnen Rechte und Pflichten des Audit Committees werden in einer vom Gesamtaufsichtsrat verabschiedeten Geschäftsordnung für das Audit Committee festgelegt.
                </p>
            </li>
        </ol>
    </li>`,
};

export const ausschussNominierung: TemplateVariable = {
  name: "Nominierungsausschuss",
  description: `
    <p>
        Der Nominierungsausschuss berät über Vorschläge für die Wahl von Aufsichtsratsmitgliedern und bereitet die Wahlvorschläge für die Hauptversammlung vor. Er achtet dabei in der Regel auf die Vielfalt (Diversity) und insbesondere auf eine Beteiligung von Frauen entsprechend der festgelegten Zielgröße.
    </p>
    `,
  variableType: variableTypeSwitch,
  html: `<li>
        <h3 data-list-style="decimal" data-list-style-prefix="§ ">
            Nominierungsausschuss
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Der Aufsichtsrat hat einen Nominierungsausschuss.
                </p>
            </li>
            <li>
                <p>
                    Dem Nominierungsausschuss gehören drei vom Aufsichtsrat zu wählende Anteilseignervertreter an.
                </p>
            </li>
            <li>
                <p>
                    Der Nominierungsausschuss schlägt dem Aufsichtsrat für dessen Wahlvorschläge an die Hauptversammlung geeignete Kandidaten vor. Dabei hat er zu berücksichtigen, dass die vorgeschlagenen Kandidaten über die zur Wahrnehmung ihrer Aufgaben erforderlichen Kenntnisse, Fähigkeiten und fachlichen Erfahrungen verfügen. Der Aufsichtsrat hat bei seinen Vorschlägen auch auf eine ausreichende Anzahl unabhängiger Mitglieder, auf die internationale Ausrichtung des Unternehmens, potentielle Interessenkonflikte sowie auf Vielfalt (Diversity) und insbesondere auf eine Beteiligung von Frauen entsprechend der festgelegten Zielgröße zu achten. Er hat dabei die Vorgaben über die beschlossenen Ziele für die Zusammensetzung des Aufsichtsrats zu wahren und die Ausfüllung des Kompetenzprofils für das Gesamtorgan zum Ziel zu haben.
                </p>
            </li>
        </ol>
    </li>`,
};

// Beirat allgemein

export const nameBeiratsmitglied: TemplateVariable = {
  name: "Name des Beiratsmitglieds",
  description: "Name des Beiratsmitglieds. Beispiel: Marlene Musterfrau.",
  variableType: variableTypeString,
};

// Gehaltsverzicht

export const tagGehaltsverzichtBeginn: TemplateVariable = {
  name: "Beginn des Gehaltsverzichts",
  description:
    "Tag, ab dem die Gehaltsverzichtserklärung gelten soll. Beispiel: 01.06.2024.",
  variableType: variableTypeString,
};

export const betragGehaltVorVerzicht: TemplateVariable = {
  name: "Bisheriges Brutto-Monatsgehalt",
  description:
    "Betrag des Brutto-Monatsgehalts vor dem Gehaltsverzicht. Beispiel: 5.000 EUR.",
  variableType: variableTypeString,
};

export const betragGehaltVerzicht: TemplateVariable = {
  name: "Verzichtsbetrag",
  description:
    "Betrag, um den das Brutto-Monatsgehalt reduziert werden soll. Beispiel: 500 EUR.",
  variableType: variableTypeString,
};

export const betragGehaltNachVerzicht: TemplateVariable = {
  name: "Brutto-Monatsgehalt nach dem Verzicht",
  description:
    "Betrag des reduzierten Brutto-Monatsgehalts aufgrund des Gehaltsverzichts. Beispiel: 4.500 EUR.",
  variableType: variableTypeString,
};

export const tagRückabwicklungschutzAblauf: TemplateVariable = {
  name: "Ablauf des erweiterten Kündigungsschutz",
  description:
    "Datum, bis wann ein erweiterter Kündigungsschutz aufgrund des Gehaltsverzichts gelten soll. Der erweiterter Kündigungsschutz führt dazu, dass die Nachzahlung der Verzichtsbeträge sofort fällig werden, wenn der Geschäftsführer bis zu dem Datum ordentlich gekündigt wird. Beispiel: 31.12.2026.",
  variableType: variableTypeString,
};

export const nachzahlungszeitraumMax: TemplateVariable = {
  name: "Maximal-Zeitraum für Nachzahlungsbegrenzung",
  description:
    "Um das Nachzahlungrisiko für die Gesellschaft besser kalkulierbar zu machen, wird der Zeitraum für eine rückwirkende Nachzahlung üblicherweise auf einen Höchstzeitraum begrenzt. Beispiel: 2 Jahre.",
  variableType: variableTypeString,
};

export const bilanzgewinnMin: TemplateVariable = {
  name: "Minimaler Bilanzgewinn zur Verbesserung der wirtschaftlichen Lage",
  description:
    "Höhe des Bilanzgewinns, der die Nachzahlungsverpflichtung auslöst. Beispiel: '300.000 EUR.'",
  variableType: variableTypeString,
};

// Geschäftsleiter

export const nameGeschäftsleiter: TemplateVariable = {
  name: "Name der geschäftsleitenden Person",
  description:
    "Name der Person, die geschäftsleitende Position bekleidet (Geschäftsführer, geschäftsführender Gesellschafter, geschäftsführender Partner oder Komplementär). Beispiel: Marlene Musterfrau.",
  variableType: variableTypeString,
};

export const nameKomplementärGmbH: TemplateVariable = {
  name: "Firma der Komplementär-GmbH (Komplementär-UG)",
  description:
    "Firma der GmbH bzw. UG, die als Komplementärin fungiert. Beispiel: ABC GmbH.",
  variableType: variableTypeString,
};

export const nameKomplementär: TemplateVariable = {
  name: "Name des Komplementärs",
  description:
    "Name des Komplementärs, den der Beschluss betreffen soll. Beispiel: Petra Musterfrau.",
  variableType: variableTypeString,
};

export const nameGeschäftsführenderGesellschafter: TemplateVariable = {
  name: "Name des geschäftsführenden Gesellschafters",
  description:
    "Name des geschäftsführenden Gesellschafters, den der Beschluss betreffen soll. Beispiel: Petra Musterfrau.",
  variableType: variableTypeString,
};

export const nameGeschäftsführenderPartner: TemplateVariable = {
  name: "Name des geschäftsführenden Partners",
  description:
    "Name des geschäftsführenden Partners, den der Beschluss betreffen soll. Beispiel: Petra Musterfrau.",
  variableType: variableTypeString,
};

// Geschäftsführer allgemein

export const nameGeschäftsführer: TemplateVariable = {
  name: "Name des Geschäftsführers",
  description:
    "Name des Geschäftsführers, den der Beschluss betreffen soll. Beispiel: Marlene Musterfrau",
  variableType: variableTypeString,
};

export const nameGeschäftsführerNeu: TemplateVariable = {
  name: "Name des neuen Geschäftsführers",
  description:
    "Name des Geschäftsführers, der neu bestellt werden soll. Beispiel: Marlene Musterfrau",
  variableType: variableTypeString,
};

export const nameGeschäftsführerAlt: TemplateVariable = {
  name: "Name des ausscheidenden Geschäftsführers",
  description:
    "Name des Geschäftsführers,der ausscheiden soll. Beispiel: Peter Müller",
  variableType: variableTypeString,
};

export const geburtsdatumGeschäftsführer: TemplateVariable = {
  name: "Geburtsdatum des Geschäftsführers",
  description:
    "Geburtsdatum des Geschäftsführers, den der Beschluss betreffen soll. Beispiel: 30.06.1988",
  variableType: variableTypeString,
};

export const adresseGeschäftsführer: TemplateVariable = {
  name: "Wohnort des Geschäftsführers",
  description:
    "Für die Registereintragung wird der Wohnort benötigt (§ 43 Nr. 4 HRV). Eine Angabe von Straße und Hausnummer ist nicht notwendig. Beispiel: Frankfurt am Main",
  variableType: variableTypeString,
};

export const geburtsdatumGeschäftsführerNeu: TemplateVariable = {
  name: "Geburtsdatum des neuen Geschäftsführers",
  description:
    "Geburtsdatum des Geschäftsführers, der neu bestellt werden soll. Beispiel: 30.06.1988",
  variableType: variableTypeString,
};

export const adresseGeschäftsführerNeu: TemplateVariable = {
  name: "Wohnort des neuen Geschäftsführers",
  description:
    "Für die Registereintragung wird der Wohnort benötigt (§ 43 Nr. 4 HRV). Eine Angabe von Straße und Hausnummer ist nicht notwendig. Beispiel: Frankfurt am Main",
  variableType: variableTypeString,
};

export const geburtsdatumGeschäftsführerAlt: TemplateVariable = {
  name: "Geburtsdatum des alten Geschäftsführers",
  description:
    "Geburtsdatum des alten Geschäftsführers, den abberufen werden soll. Beispiel: 30.06.1988",
  variableType: variableTypeString,
};

export const adresseGeschäftsführerAlt: TemplateVariable = {
  name: "Adresse des alten Geschäftsführers",
  description:
    "Straße, PLZ und Ort des Geschäftsführers, der abberufen werden soll. Dabei ist die Wohnadresse anzugeben. Beispiel: Lindenweg 10, 60316 Frankfurt am Main",
  variableType: variableTypeString,
};

// Gesellschafter

export const nameGesellschafter: TemplateVariable = {
  name: "Name des Gesellschafters",
  description:
    "Name des Gesellschafters, den der Beschluss betreffen soll. Beispiel: Marlene Musterfrau",
  variableType: variableTypeString,
};

export const nameGesellschafterVertragspartner: TemplateVariable = {
  name: "Name des Gesellschafters",
  description:
    "Name des Gesellschafters, mit dem der Vertrag geschlossen werden soll. Beispiel: Marlene Musterfrau",
  variableType: variableTypeString,
};

export const geburtsdatumGesellschafter: TemplateVariable = {
  name: "Geburtsdatum des Gesellschafters",
  description:
    "Geburtsdatum des Gesellschafters, mit dem der Vertrag geschlossen werden soll. Beispiel: 09.08.1972",
  variableType: variableTypeString,
};

export const adresseGesellschafter: TemplateVariable = {
  name: "Anschrift des Gesellschafters",
  description:
    "Anschrift des Gesellschafters, mit dem der Vertrag geschlossen werden soll. Beispiel: Charlottenstraße 4, 10969 Berlin",
  variableType: variableTypeString,
};

// Gesellschaftsvertrag

export const gesellschaftsvertragÄnderungPassage: TemplateVariable = {
  name: "Wortlaut geänderte Passage",
  description:
    "Wortlaut der neuen Passage, die an die Stelle der alten Regelung treten soll.",
  variableType: variableTypeTextarea,
};

export const gesellschaftsvertragÄnderungPassagenbezeichnung: TemplateVariable =
  {
    name: "Bezeichnung der zu ändernden Passage",
    description:
      "Die Passage, welche geändert werden soll, kann ein ganzer Paragraph, eine Ziffer oder auch nur ein Satz sein. Beispiele: § 10 (Beirat), § 2 Abs. 1 Satz 3, Ziffer 10.1",
    variableType: variableTypeString,
  };

export const gesellschaftsvertragNeuePassage: TemplateVariable = {
  name: "Wortlaut neue Passage",
  description:
    "Wortlaut der neuen Passage, die in den Gesellschaftsvertrag eingefügt werden soll.",
  variableType: variableTypeTextarea,
};

export const gesellschaftsvertragEinfügenBezeichnungVorpassage: TemplateVariable =
  {
    name: "Bezeichnung der Vorpassage",
    description:
      "Bezeichnung der Vorpassage, hinter welcher der Wortlaut der neuen Passage eingefügt werden soll. Das kann ein ganzer Paragraph, eine Ziffer oder auch nur ein Satz sein. Beispiele: § 10 (Beirat), § 2 Abs. 1 Satz 3, Ziffer 10.1",
    variableType: variableTypeString,
  };

export const gesellschaftsvertragEinfügenBezeichnungNeuePassage: TemplateVariable =
  {
    name: "Bezeichnung der Vorpassage",
    description:
      "Bezeichnung der neuen Passage, welche eingefügt werden soll. Das kann ein ganzer Paragraph, eine Ziffer oder auch nur ein Satz sein. Beispiele: § 10 (Beirat), § 2 Abs. 1 Satz 3, Ziffer 10.1",
    variableType: variableTypeString,
  };

// Jahresabschluss, Verwendung des Gewinns/ Vorabausschüttung

export const jahresgewinnPrognose: TemplateVariable = {
  name: "prognostizierter Jahresgewinn",
  description:
    "Betrag des Jahresgewinns, der sich aus der Vorausberechnung für das laufende Geschäftsjahr ergibt, z.B. 500.000",
  variableType: variableTypeString,
};

export const verteilungsschluessel: TemplateVariable = {
  name: "Verteilungsschlüssel",
  description:
    'Maßstab, nach welchem die Verteilung an die Gesellschafter erfolgen soll, z.B. "nach Anteilen" oder "Maria Fuchs: 400.000 €, Peter Schäfer: 100.000 €". Beachte: Eine disproportionale Ausschüttung ist nur wirksam, wenn alle Gesellschafter zustimmen.',
  variableType: variableTypeString,
};

export const gewinnverteilung: TemplateVariable = {
  name: "Gewinnverteilung",
  description:
    'Beträge/Verteilungsschlüssel, nach welchem die Verteilung an die Gesellschafter erfolgen soll, z.B. "nach Anteilen" oder "Maria Fuchs: 400.000 €, Peter Schäfer: 100.000 €". Der Veteilungsschlüssel ist in der Regel im Gesellschaftsvertrag festgelegt, ansonsten gilt § 709 Abs. 3 BGB.',
  variableType: variableTypeString,
};

export const jahresüberschuss: TemplateVariable = {
  name: "Jahresergebnis",
  description:
    "Betrag des Jahresergebnisses (Jahresgewinn bzw. Jahresverlust). Bei einem Jahresverlust bitte eine negative Zahl eintragen, z.B. - 500.000",
  variableType: variableTypeString,
};

export const gewinn: TemplateVariable = {
  name: "Gewinn",
  description:
    "Betrag der im Rechnungsabschluss/Jahresabschluss als Gewinn ausgewiesen ist. Beispiel: 500.000 € Bei einem Verlust/Fehlbetrag bitte eine negative Zahl eintragen, z.B. - 500.000 €",
  variableType: variableTypeString,
};

export const bestehenderGewinnvortrag: TemplateVariable = {
  name: "Gewinnvortrag Vorjahr",
  description:
    "Betrag des Gewinnvortrags aus dem letzten Jahr. Sofern unzutreffend, bitte 0 eintragen.",
  variableType: variableTypeString,
};

export const bestehenderVerlustvortrag: TemplateVariable = {
  name: "Verlustvortrag Vorjahr",
  description:
    "Betrag des Verlustvortrags aus dem letzten Jahr. Sofern unzutreffend, bitte 0 eintragen.",
  variableType: variableTypeString,
};

export const bilanzgewinn: TemplateVariable = {
  name: "Bilanzergebnis",
  description:
    "Betrag des Bilanzergebnisses (Bilanzgewinn bzw. Bilanzverlust). Bei einem Bilanzverlust bitte eine negative Zahl eintragen, z.B. - 500.000",
  variableType: variableTypeString,
};

export const ausschüttung: TemplateVariable = {
  name: "Ausschüttung",
  description:
    "Betrag, der an die Gesellschafter ausgeschüttet werden soll. Beispiel: 100.000",
  variableType: variableTypeString,
};

export const pflichtrücklage: TemplateVariable = {
  name: "Pflichtrücklage 25 %",
  description:
    "Betrag, der in die Pflichtrücklage eingestellt werden soll. Die  Pflichtrücklage muss 25 % des Bilanzgewinns (um Verlustvortrag geminderter Jahresüberschusses) betragen.",
  variableType: variableTypeString,
};

export const rücklage: TemplateVariable = {
  name: "Rücklage",
  description: "Betrag, der in die freie Rücklage eingestellt werden soll",
  variableType: variableTypeString,
};

export const künftigerGewinnvortrag: TemplateVariable = {
  name: "Künftiger Gewinnvortrag",
  description:
    "Betrag des Gewinnvortrags auf das Folgejahr.Sofern unzutreffend, bitte 0 eintragen.",
  variableType: variableTypeString,
};

export const künftigerVerlustvortrag: TemplateVariable = {
  name: "Künftiger Verlustvortrag",
  description:
    "Betrag des Verlustvortrags auf das Folgejahr. Dieser Wert ist nur relevant, sofern der Jahresabschluss einen Bilanzverlust ausweist. In Gewinnjahren ist hier ein Wert in höhe von 0 einzutragen.",
  variableType: variableTypeString,
};

export const bilanzStichtag: TemplateVariable = {
  name: "Bilanzstichtag",
  description:
    'Tag, auf den sich der am Ende des Geschäfts- bzw. Haushaltsjahres aufzustellende Jahresabschluss (auch "Bilanz") bezieht.',
  variableType: variableTypeString,
};

export const rechnungsabschlussStichtag: TemplateVariable = {
  name: "Stichtag Rechnungsabschluss",
  description:
    "Tag, auf den sich der aufgestellte Rechnungsabschluss bezieht. Beispiel: 31.12.2023.",
  variableType: variableTypeString,
};

export const planJahr: TemplateVariable = {
  name: "Planungsjahr",
  description: "Geschäftsjahr, auf welches sich die Planung bezieht.",
  variableType: variableTypeString,
};

// Bestätigung

export const beschlussDatum: TemplateVariable = {
  name: "Beschlussdatum",
  description: "Datum des anfechtbaren Beschlusses",
  variableType: variableTypeString,
};

export const beschlussMehrheit: TemplateVariable = {
  name: "Beschlussmehrheit",
  description:
    "Beispiele: 75 % des vertretenen Stammkapitals, d75 % der abgegebenen Stimmen",
  variableType: variableTypeString,
};

export const beschlussInhalt: TemplateVariable = {
  name: "Beschlussinhalt",
  description:
    "Wortlaut des Beschlusstextes, der Gegenstand der Bestätigung sein soll.",
  variableType: variableTypeTextarea,
};

export const anfechtungsgründe: TemplateVariable = {
  name: "Anfechtungsgründe",
  description:
    "Knappe Wiedergabe der in der Anfechtungsklage behaupteten Anfechtungsgründe.",
  variableType: variableTypeTextarea,
};

// Bevollmächtigter allgemein

export const eckpunkte: TemplateVariable = {
  name: "Eckpunkte für den Vertragsabschluss",
  description: "Eckpunkte, die beim Vertragsabschluss beachtet werden müssen.",
  variableType: variableTypeTextarea,
};

export const notarmitarbeiter: TemplateVariable = {
  name: "Notarmitarbeiter für die Notarvollmacht",
  description:
    "Die Namen der zu bevollmächtigenden Mitarbeiter sind bei dem Notar zu erfragen, der die Kapitalerhöhung beurkunden soll. Die Notarmitarbeitervollmacht ermöglicht es, dass die Gesellschafter den Beschluss vorab unterschreiben können und dem Notariat sodann zur Beurkundung einreichen. Eine Anwesenheit der Gesellschafter beim Notar ist dann nicht erforderlich. ",
  variableType: variableTypeTextarea,
};

// ArbeitsvertragEinfach

export const nameArbeitnehmer: TemplateVariable = {
  name: "Name des Arbeitnehmers",
  description:
    "Name des neuen Arbeitnehmers, der neu eingestellt werden soll. Beispiel: Peter Mustermann.",
  variableType: variableTypeString,
};

export const geburtsdatumArbeitnehmer: TemplateVariable = {
  name: "Geburtsdatum des Arbeitnehmers",
  description: "Beispiel: 01.07.1970.",
  variableType: variableTypeString,
};

export const adresseArbeitnehmer: TemplateVariable = {
  name: "Wohnadresse des Arbeitnehmers",
  description:
    "Straße, PLZ und Ort des neuen Arbeitnehmers. Beispiel: Heidestraße 10, 60316 Frankfurt am Main.",
  variableType: variableTypeString,
};

export const tätigkeitArbeitnehmer: TemplateVariable = {
  name: "Tätigkeit",
  description:
    "Beschreibendes Schlagwort für die Tätigkeit(en), für welche der Arbeitnehmers eingestellt werden soll. Beispiele: Web-Developer, Schlosser, Buchhalter etc.",
  variableType: variableTypeString,
};

export const tätigkeitsortArbeitnehmer: TemplateVariable = {
  name: "Arbeitsort",
  description:
    "Nennung der Arbeitsorte, in welchen der Arbeitnehmer eingesetzt werden soll. Beispiel: Köln, Mainz, Frankfurt am Main etc.",
  variableType: variableTypeString,
};

export const arbeitsverhältnisBeginn: TemplateVariable = {
  name: "Beginn des Arbeitsverhältnisses",
  description: "Datum des ersten Arbeitstages. Beispiel: 01. Juni 2022.",
  variableType: variableTypeString,
};

export const dauerProbezeitMonate: TemplateVariable = {
  name: "Dauer der Probezeit",
  description:
    "Anzahl der Monate, die die Probezeit betragen soll (max. sechs Monate gemäß § 622 Abs. 3 BGB). Beispiel: 3",
  variableType: variableTypeString,
};

export const dauerArbeitszeitStunden: TemplateVariable = {
  name: "Wöchtentliche Arbeitszeit in Stunden",
  description: "Beispiel: 8.",
  variableType: variableTypeString,
};

export const vergütungArbeitnehmer: TemplateVariable = {
  name: "Monatsvergütung (brutto)",
  description: "Beispiel: 3.000 EUR.",
  variableType: variableTypeString,
};

export const grenzeMitabgegolteneÜberstunden: TemplateVariable = {
  name: "Mitabgegoltene monatliche Überstunden",
  description:
    "Anzahl der Überstunden, die monatlich mitabgegolten sind. Üblich ist Obergrenze von 10 % der regelmäßigen Monatsarbeitszeit. Eine höhere Obergrenze kann unwirksam sein. Beispiel: 16",
  variableType: variableTypeString,
};

export const verteilungArbeitszeitWochentage: TemplateVariable = {
  name: "Arbeitszeitverteilung auf Wochentage",
  description:
    "Beispiel für eine regelmäßige wöchentliche Arbeitszeit von 20 Stunden:  Montag und Dienstag: 8 Stunden, Mittwoch: 4 Stunden.",
  variableType: variableTypeString,
};

export const mitarbeiterName: TemplateVariable = {
  name: "Name des Mitarbeiters",
  description:
    "Name des Mitarbeiters, mit dem der Vertrag geschlossen werden soll. Beispiel: Peter Mustermann.",
  variableType: variableTypeString,
};

export const mitarbeiterGeburtsdatum: TemplateVariable = {
  name: "Geburtsdatum des Mitarbeiters",
  description: "Beispiel: 01.07.1970.",
  variableType: variableTypeString,
};

export const mitarbeiterAdresse: TemplateVariable = {
  name: "Wohnadresse des Mitarbeiters",
  description:
    "Straße, PLZ und Ort des Mitarbeiters. Beispiel: Heidestraße 10, 60316 Frankfurt am Main.",
  variableType: variableTypeString,
};

export const mitarbeiterVertrag: TemplateVariable = {
  name: "Primäres Vertragsverhältnisses",
  description:
    "Bezeichnung des primären Vertragsverhältnisses mit dem Mitarbeiter, welches Grundlage für sein Tätigwerden für die Gesellschaft ist. Beispiele: Arbeitsvertrag, Geschäftsführer-Anstellungsvertrag, Freelancer-Vertrag, etc.",
  variableType: variableTypeString,
};

export const mitarbeiterVertragDatum: TemplateVariable = {
  name: "Datum des primären Vertrags",
  description:
    "Datum des Tages, an welchem das primären Vertragsverhältnis vereinbart worden ist. Beispiel: 30.06.2021.",
  variableType: variableTypeString,
};

export const cliffPeriode: TemplateVariable = {
  name: "Beginn der Cliff-Periode",
  description:
    "Datum des Tages, an dem die zwölfmonatige Cliff-Periode beginnen soll. Erst nach der Cliff-Periode erhält der Mitarbeiter seine ersten virtuellen Geschäftsanteile. Scheidet der Mitarbeiter vor der Cliff-Periode aus, hat er keine Ansprüche aus einer virtuellen Beteiligung. Beispiel: 30.06.2021.",
  variableType: variableTypeString,
};

export const phantomstockPercentAfterCliffPeriode: TemplateVariable = {
  name: "Beteiligung nach Cliff-Periode",
  description:
    "Umfang der virtuellen Geschäftsanteile in Prozent, die der Mitarbeiter erhalten soll, wenn die Cliff-Periode abgelaufen ist. Beispiel: 2",
  variableType: variableTypeString,
};

export const phantomstockPercentAfterCliffPeriodeMonthly: TemplateVariable = {
  name: "Beteiligungserhöhung pro Monat",
  description:
    "Umfang der virtuellen Geschäftsanteile in Prozent, die der Mitarbeiter monatlich hinzuerhalten soll, nachdem die Cliff-Periode abgelaufen ist. Beispiel: 1/12",
  variableType: variableTypeString,
};

export const phantomstockPercentMaximal: TemplateVariable = {
  name: "Maximalumfang der virtuellen Geschäftsanteile",
  description:
    "Umfang der virtuellen Geschäftsanteile in Prozent, die der Mitarbeiter höchstens erhalten soll. Beispiel: 8",
  variableType: variableTypeString,
};

export const datumAcceleratedVesting: TemplateVariable = {
  name: "Accelerated Vesting-Datum",
  description:
    "Wenn der Exit vor dem Accelerated Vesting-Datum stattfindet, erhält der Mitarbeiter sofort den Maximalumfang der virtuellen Geschäftsanteile, sodass er die Vesting-Periode nicht vollständig abwarten muss. Beispiel: 8",
  variableType: variableTypeString,
};

export const exitSchwelleProzent: TemplateVariable = {
  name: "Exit-Schwelle",
  description:
    "Die Exit-Schwelle legt den Umfang der echten Geschäftsanteile in Prozent fest, die veräußert worden sein muss, damit definitionsgemäß ein Exit vorliegt. Beispiel: 80",
  variableType: variableTypeString,
};

// Mitarbeiterbeteiligungs-KG

export const variantsPriviligiertePersonen: TemplateVariable = {
  name: "Privilegierter Personenkreis",
  description: `<p>
        Der privilegierte Personenkreis sind die Personen, die an der Pooling-KG beteiligt werden sollen. Zur Auswahl stehen die Personengruppen "Mitarbeiter" und "selbstständige Vertriebspartner".
    </p>
    <p>
        Bitte beachten Sie, dass das Muster nur für die Mitarbeiter-Kommanditisten spezielle Regelungen für Ausscheiden und Abfinden enthält (Vesting, Good-Leaver-/Bad-Leaver-Regelung). Für Vertriebspartner-Kommanditisten sind solche Regelungen in der Regel nicht erforderlich.
    </p>
    `,

  variableType: variableTypeVariants,
  options: [
    {
      label: "Mitarbeiter",
      html: `
            <ul>
                <li>
                    <p>
                       Mitarbeiter der Hauptgesellschaft oder einer ihrer Tochtergesellschaften, im folgenden <strong>„Mitarbeiter-Kommanditisten“</strong> genannt.
                    </p>
                </li>
            </ul>
            `,
    },

    {
      label: "Mitarbeiter und Vertriebspartner",
      html: `
            <ul>
                <li>
                    <p>
                       Mitarbeiter der Hauptgesellschaft oder einer ihrer Tochtergesellschaften, im folgenden <strong>„Mitarbeiter-Kommanditisten“</strong> genannt;
                    </p>
                </li>
                <li>
                    <p>
                       selbstständige Vertriebspartnern der Hauptgesellschaft oder einer ihrer Tochtergesellschaften, im folgenden <strong>„Vertriebspartner-Kommanditisten“</strong> genannt.
                    </p>
                </li>
            </ul>
            `,
    },
  ],
};

export const unternehmenRegisterAmtsgericht: TemplateVariable = {
  name: "Registergericht d. Unternehmens",
  description: `<p>Registergericht des Unternehmens.</p>
    <p>Beispiel: Amtsgericht München.</p>`,
  variableType: variableTypeString,
};

export const unternehmenRegisternummer: TemplateVariable = {
  name: "Registernummer d. Unternehmens",
  description: `<p>Registernummer des Unternehmens. </p><p> Beispiel: HRB 123456.</p>`,
  variableType: variableTypeString,
};
export const unternehmenFirma: TemplateVariable = {
  name: "Firma d. Unternehmens",
  description: `<p>Firma des Unternehmens. </p><p> Beispiel: Unicorn GmbH.</p>`,
  variableType: variableTypeString,
};

export const unternehmenSitz: TemplateVariable = {
  name: "Sitz d. Unternehmens",
  description: `<p>Sitz des Unternehmens. </p><p> Beispiel: Berlin.</p>`,
  variableType: variableTypeString,
};

export const unternehmenStammkapital: TemplateVariable = {
  name: "Stammkapital d. Unternehmens",
  description:
    "Stammkapital des Unternehmens, an dem die Mitarbeiter bzw. sonstigen privilegierten Personen beteiligt werden sollen. Beispiel: 25.000 €.",
  variableType: variableTypeNumberMoney,
};

export const unternehmenGesellschafter: TemplateVariable = {
  name: "Gesellschafter d. Unternehmens",
  description:
    "Gesellschafter des Unternehmens, an dem die Mitarbeiter bzw. sonstigen privilegierten Personen beteiligt werden sollen. Beispiel: Peter Müller Holding UG, Crazy Ventures GmbH und Thorsten Schneider.",
  variableType: variableTypeTextarea,
};

export const mitarbeiterbeteiligungsKgKomplementärin: TemplateVariable = {
  name: "Bezeichnung der Komplementärin",
  description:
    'Die Komplementärin übernimmt die Rolle des persönlich haftenden Gesellschafters in einer KG. In der Pooling-KG werden in der Regel eine von den Altgesellschaftern gegründete UG (haftungsbeschränkt) oder die Altgesellschafter (bzw. deren Holding UGs/GmbHs selbst) zur Komplementärin. Beispiel: "Unicorn Mitarbeiterbeteiligung Verwaltungs UG mit Sitz in München" oder "Gründer A Holding UG, Bad Tölz, und Gründer B Holding UG, Gräfelfing"',
  variableType: variableTypeTextarea,
};
export const mitarbeiterbeteiligungsKgFirma: TemplateVariable = {
  name: "Firma d. Pooling-KG",
  description:
    'Firma der mit diesem Gesellschaftsvertrag neugegründeten Pooling-KG. Z.B. wenn die Komplementärin eine UG ist: "Unicorn Mitarbeiterbeteiligungs UG (haftungsbeschränkt) & Co. KG". Wenn die Komplementärinnen eine GmbH und eine UG sind: : "Unicorn Mitarbeiterbeteiligungs GmbH & Co. KG".',
  variableType: variableTypeString,
};

export const mitarbeiterbeteiligungsKgSitz: TemplateVariable = {
  name: "Sitz d. Pooling-KG",
  description:
    "Ort, an welchem die Pooling-KG ihren Satzungssitz haben soll. Beispiel: München.",
  variableType: variableTypeString,
};

export const mitarbeiterbeteiligungsKgHafteinlage: TemplateVariable = {
  name: "Hafteinlage (Betrag)",
  description: `
    <p>
        Hafteinlage (auch Kommanditeinlage, Haftsumme) ist der im Handelsregister eingetragene Geldbetrag, auf den die Haftung des Kommanditisten nach außen, das heißt gegenüber Dritten, beschränkt ist.
    </p>
    <p>
        In diesem Muster ist es vorgesehen, dass die Gründungskommanditisten die Hafteinlage nach Gründung der Pooling-KG in bar auf das (neu einzurichtende) Geschäftskonto der Pooling-KG einzahlt.
   </p>
   <p>
        Bei einer Pooling-KG entspricht die Hafteinlage in der Regel dem Nennbetrag der Unternehmens-Geschäftsanteile bzw. -Aktien, der über die Pooling-KG gehalten wird (oder einem Vielfachen hiervon). Z.B. "2.500 €".
    </p>`,
  variableType: variableTypeNumberMoney,
};

export const mitarbeiterbeteiligungsKgPflichteinlage: TemplateVariable = {
  name: "Pflichteinlage",
  description: `<p>
        Pflichteinlage ist in dieser Mustervorlage eine zusätzliche Einlage vorgesehen, zu deren Erbringung sich ein Kommanditist verpflichtet.
    </p>
    <p>
        Wenn das Unternehmen bereits eigene Anteile (an sich selbst) hält, die für die Beteiligung der Mitarbeiter bzw. anderer privilegierter Personengruppen bestimmt sind, kann die Pflichteinlage unmittelbar in der Einbringung der Anteile bestehen. In diesem Fall würde die Pflichteinlage z.B. lauten: "Einbringung von 10 % der Geschäftsanteile an der Unicorn GmbH".
    </p>
    <p>
        Wenn die Mitarbeiterbeteiligungs-KG die Unternehmens-Anteile noch von den Altgesellschaftern erwerben muss, sollten Haft- und Pflichteinlage zusammen den Erwerbspreis abdecken. Der Erwerbspreis wird typischerweise dem Nennbetrag der Anteile entsprechen. Wenn eine Unternehmens-GmbH ein Stammkapital von 25.000 € hat und 10 % der Anteile für die Mitarbeiterbeteiligung von den Altgesellschaftern erworben werden soll, muss die Pflichteinlage dann z.B. 2.500 € betragen, um den Erwerbspreis von 2.500 € abzudecken.
    </p>
    <p>
        Außerdem ist es sinnvoll, dass die Pflichteinlage in beiden Fällen noch einen zusätzlichen Puffer-Betrag enthält, um die künftigen Verwaltungskosten, z.B. für die künftigen Jahresabschlüsse und Steuererklärungen abdecken zu können, z.B. "5.000 €".
    </p>`,
  variableType: variableTypeTextarea,
};
export const mitarbeiterbeteiligungsKgVestingperiode: TemplateVariable = {
  name: "Vesting-Periode",
  description: `
    <p>
        Nach Ablauf der Vesting-Periode sind sämtliche Mitarbeiter-Kommanditanteile unverfallbar und der Mitarbeiter kann ohne Verluste das Unternehmen verlassen und die Anteile behalten. Wenn der Mitarbeiter während der Vesting-Periode das Unternehmen verlässt, muss er dagegen damit rechnen, dass er seine Mitarbeiter-Kommanditanteile <strong>gegen eine Abfindung</strong> zeitanteilig (Good-Leaver) oder vollständig (Bad-Leaver) verliert. Typische Vesting-Perioden sind Zeiträume zwischen 3 bis 5 Jahren.
    </p>
    <p>
        Beispiel: "4 Jahre"
    </p>`,
  variableType: variableTypeNumberSlider,
  step: 1,
  sliderValues: [
    { value: 1, label: "1" },

    { value: 5, label: "5" },

    { value: 10, label: "10" },
  ],
  min: 1,
  max: 10,
  defaultValue: 4,
  unitOutput: "Jahren",
  unitOutputSingle: "Jahr",
};
export const unternehmenMonateNachFinanzierungsrunde: TemplateVariable = {
  name: "Zeitraum für Relevanz d. Finanzierungsrunde in Monaten",
  description: `<p>
        Zeitraum, in welchem die Finanzierungsrunde für die Bewertung des Unternehmens herangezogen werden soll.
     </p>
     <p>
     Beispiel: "12 Monate"
     </p>`,
  variableType: variableTypeNumber,
  unitInput: "Monate",
  unitInputSingle: "Monat",
  unitOutput: "Monaten",
  unitOutputSingle: "Monat",
};

// Ausschluss Gesellschafter

export const klauselAusschluss: TemplateVariable = {
  name: "Ausschlussklausel im Gesellschaftsvertrag",
  description:
    "Vorschrift im Gesellschaftsvertrag, die einen Ausschluss auch ohne Erhebung einer Ausschlussklage vorsieht. Ohne eine solche Vorschrift muss der Ausschlussbeschluss auf die Erhebung einer zusätzlichen Ausschlussklage gerichtet sein.",
  variableType: variableTypeString,
};

export const wichtigerGrund: TemplateVariable = {
  name: "Wichtiger Grund",
  description:
    "Grund, der die Ausschließung rechtfertigt. In Betracht kommen verhaltensbedingte Gründe oder verhaltensunabhängige personenbedingte Gründe. <p>Beispiele für verhaltensbedingte Gründe: viele kleinere oder eine besonders schweren Pflichtverletzung, schuldhafte Herbeiführung eines unheilbaren Gesellschafterzerwürfnisses,erhebliche unzulässige Privatentnahmen unter Verweigerung der Rückzahlung.</p><p> Beispiele für personenbedingte Gründe: Andauernde schwere Erkrankung, mangelnde Vertrauens- oder Kreditwürdigkeit, ungeordnete Vermögensverhältnisse.<p/>",
  variableType: variableTypeTextarea,
};

export const wichtigerGrundAbwägung: TemplateVariable = {
  name: "Gesamtabwägung",
  description:
    "Abwägung der Gesichtspunkte, aufgrund welcher ein Verbleiben des Gesellschafters in der Gesellschaft untragbar erscheint. Insbesondere sind zu berücksichtigen: <ul><li><p>die Umstände des Ausschlussgrundes und dessen Bedeutung für die Gesellschaft in ihrer jeweils konkreten Ausprägung sowie</p></li><li><p>die Stellung des betreffenden Gesellschafters in der Gesellschaft</p></li></ul>",
  variableType: variableTypeTextarea,
};

export const wichtigerGrundUltimaratio: TemplateVariable = {
  name: "Keine milderes Mittel (ultima ratio)",
  description:
    "Erwägungen, warum keine andere, mildere Maßnahme den wichtigen Grund beseitigt. Beispiele für mögliche mildere Mittel: Anteilsübertragung auf einen Dritten,  Vereinbarung einer Treuhandkonstellation, Entzug etwaiger Sonderrechte, Abgabe einer strafbewehrten Unterlassungserklärung, Abmahnung ",
  variableType: variableTypeTextarea,
};

export const wichtigerGrundEntgegnung: TemplateVariable = {
  name: "Stellungnahme des auszuschließenden Gesellschafters",
  description:
    "Mögliche Erklärungen oder Rechtfertigungen, mit denen der Gesellschafter den wichtigen Grund möglicherweise rechtzufertigen versucht.",
  variableType: variableTypeTextarea,
};

// Dienstvertrag Freier Mitarbeiter

export const nameFreierMitarbeiter: TemplateVariable = {
  name: "Name des freien Mitarbeiters",
  description:
    "Name des neuen freien Mitarbeiters, der Diensteleistungen erbringen soll. Beispiel: Peter Mustermann.",
  variableType: variableTypeString,
};

export const adresseFreierMitarbeiter: TemplateVariable = {
  name: "Wohn-/Geschäftsadresse des freien Mitarbeiters",
  description:
    "Straße, PLZ und Ort des neuen freien Mitarbeiters. Beispiel: Heidestraße 10, 60316 Frankfurt am Main.",
  variableType: variableTypeString,
};

export const bezeichnungTätigkeitFreierMitarbeiter: TemplateVariable = {
  name: "Tätigkeit",
  description:
    "Beschreibendes Schlagwort für die Tätigkeit(en), welche der freie Mitarbeiter erbringen werden soll. Beispiele: Programmierer",
  variableType: variableTypeString,
};

export const beschreibungTätigkeitFreierMitarbeiter: TemplateVariable = {
  name: "Tätigkeitsbeschreibung ",
  description:
    "Nähere Beschreibung der Aufgaben und Tätigkeiten des freien Mitarbeiters. Beispiele: Entwicklung einer FinTech-Software",
  variableType: variableTypeString,
};

export const nameAnsprechpartnerFreierMitarbeiter: TemplateVariable = {
  name: "Ansprechpartner in der Gesellschaft",
  description:
    "Name der Person, an die sich der Mitarbeiter bei Rückfragen und zur Abstimmung wenden kann. Beispiel: Markus Müller.",
  variableType: variableTypeString,
};

export const betragHonorarNetto: TemplateVariable = {
  name: "Monatliches Nettohonorar",
  description:
    "Pauschalhonorar netto, welches der freie Mitarbeiter monatlich zuzüglich einer etwaigen gesetzlichen Umsatzsteuer in Rechnung stellen kann. Beispiel: 10.000 Euro.",
  variableType: variableTypeString,
};

export const leistungenWettbewerberFreierMitarbeiter: TemplateVariable = {
  name: "Relevante Erzeugnisse oder Tätigkeiten für Wettbewerbsverbot",
  description:
    "Der freie Mitarbeiter darf nicht für Wettbewerber tätig werden, die solche Erzeugnisse oder Leistungen herstellen, anbieten etc. Beispiel: Tiefbauleistungen.",
  variableType: variableTypeString,
};

export const ausnahmeWettbewerbsverbotUnternehmen: TemplateVariable = {
  name: "Wettbewerbsverbotfreie Unternehmen",
  description:
    "Unternehmen, für die das Wettbewerbsverbot nicht gelten soll. Beispiel: Beispiel GmbH.",
  variableType: variableTypeString,
};

export const beginnFreierMitarbeiter: TemplateVariable = {
  name: "Beginn der freien Mitarbeit",
  description:
    "Datum des ersten Tages, an dem der freie Mitarbeiter tätig werden soll. Beispiel: 1. Juli 2022.",
  variableType: variableTypeString,
};

// Domain-Einbringungsvertrag

export const gesellschafterPersönlicheAngaben: TemplateVariable = {
  name: "Persönliche Gesellschafterangaben",
  description: `
    <p>
        Persönlichen Angaben, um die Person des Gesellschafters eindeutig zu bestimmen.
    </p>
    <ul>
        <li>
            <p>
                Bei natürlichen Personen:
            </p>
            <p>
                "geboren am ***, wohnhaft in ***".
            </p>
        </li>
        <li>
            <p>
                Bei juristischen Personen:
            </p>
            <p>
                "mit dem Sitz in ***,<br/>
                eingetragen im Handelsregister des Amtsgerichts *** unter ***,<br/>
                Geschäftsadresse: ***".
            </p>
        </li>
    </ul>
`,
  variableType: variableTypeTextarea,
};

export const domainRegistrierungsstelle: TemplateVariable = {
  name: "Registrierungsstelle",
  description: `<p>
        Name der Registrierungsstelle.
    </p>
    <p>
        Beispiele:
    </p>
    <ul>
        <li><p>
            'DENIC eG' für .de,
        </p></li>
        <li><p>
            'VeriSign Global Registry Services' für .com,
        </p></li>
        <li><p>
            'Internet Computer Bureau Limited' für .io.
        </p></li>
    </ul>
    <p>
        Eine vollständige Liste aller Registrierungsstellen findest du unter https://www.iana.org/domains/root/db.
    </p>`,
  variableType: variableTypeString,
};

export const domainName: TemplateVariable = {
  name: "Domainname",
  description: `
  <p>
    Name der Domain ohne www.-Zusatz.
    </p>
    <p>
        Beispiel: resolvio.com
    </p>`,
  variableType: variableTypeString,
};

export const domainWert: TemplateVariable = {
  name: "Wert der Domain",
  description: `
    <p>
        Verkehrswert der Domain.
    </p>
    <p>
    Beispiel: 1.000,00 EUR
    </p>`,
  variableType: variableTypeNumberMoney,
};

export const domainWertBegründung: TemplateVariable = {
  name: "Begründung der Wertangabe",
  description: `
    <p>
        Hier können Anhaltspunkte genannt werden, die die Verkehrswertangabe stützen. Das kann zum Beispiel eine Domainbewertung eines entsprechenden Dienstleisters sein.
    </p>
    <p>
        Beispiele: Bewertungsgutachten von XY, Bewertung von Adresso.de
    </p>`,
  variableType: variableTypeString,
};

// Einziehung

export const beschlussDatumFrühereEinziehung: TemplateVariable = {
  name: "Datum des Einziehungsbeschlusses",
  description:
    "Datum des Beschlusses, der die Einziehung eines Geschäftsanteils enthält.",
  variableType: variableTypeString,
};

export const geschäftsanteileNennbetragGesamtFrühereEinziehung: TemplateVariable =
  {
    name: "Nennbetragssumme der eingezogenen Geschäftsanteile",
    description:
      "Summe der Nennbeträge der Geschäftsanteile, die mit dem Einziehungsbeschluss eingezogen worden sind. Beispiel: 10.000 EUR",
    variableType: variableTypeString,
  };

export const geschäftsanteileNennbetragEinziehung: TemplateVariable = {
  name: "Nennbetrag",
  variableType: variableTypeString,
  description: "Nennbetrag des einzuziehenden Geschäftsanteils",
};

export const geschäftsanteileNeuAnzahl: TemplateVariable = {
  name: "Anzahl der neuen Geschäftsanteile",
  description: "Anzahl der neu zu bildenden Geschäftsanteile. Beispiel: 10.000",
  variableType: variableTypeString,
};

export const geschäftsanteileNummernNeu: TemplateVariable = {
  name: "lfd. Nrn. der neuen Geschäftsanteile",
  description:
    "Laufende Nummern der neuen Geschäftsanteile. Mit dieser laufenden Nummer werden die neuen Geschäftsanteile in die Gesellschafterliste eingetragen. Beispiel: 25.001 - 35.000",
  variableType: variableTypeString,
};

export const geschäftsanteileNennbetragEinzelNeu: TemplateVariable = {
  name: "Nennbeträge der neuen Geschäftsanteile",
  description:
    "Einzel-Nennbeträge der neu zu bildenden Geschäftsanteile. Beispiel: 1,00 EUR",
  variableType: variableTypeString,
};

export const geschäftsanteileNummer: TemplateVariable = {
  name: "Geschäftsanteil-Nummer(n)",
  description: `<p>
        Geschäftsanteil-Nummern, die von dem Beschluss betroffen sein sollen. Die Nummern ergeben sich aus der Gesellschafterliste, die im Handelsregister veröffentlicht sind.
    </p>
    <p>
        Beispiel: "1.001 bis 1.500 und 2.001 bis 2.500"
    </p>`,
  variableType: variableTypeString,
};

export const geschäftsanteileNennbetragEinzel: TemplateVariable = {
  name: "Nennbeträge der aufzustockenden Geschäftsanteile",
  description:
    "Einzel-Nennbeträge der Geschäftsanteile vor der Aufstockung. Beispiel: 1,00 EUR",
  variableType: variableTypeString,
};

export const geschäftsanteileAufstockungsbetragEinzel: TemplateVariable = {
  name: "Aufstockungsbetrag pro Geschäftsanteil",
  description:
    "Aufstockungsbetrag, um welchen die Nennbeträge der betreffenden Geschäftsanteile erhöht werden soll. Beispiel: 1,00 EUR",
  variableType: variableTypeString,
};

export const nameGesellschafterEingezogen: TemplateVariable = {
  name: "Name des von der Einziehung betroffenen Gesellschafters",
  description:
    "Name des Gesellschafters, dessen Geschäftsanteile von dem Einziehungsbeschluss erfasst worden sind. Beispiel: Marta Musterfrau.",
  variableType: variableTypeString,
};

export const nameGesellschafterAufgestockt: TemplateVariable = {
  name: "Name des von der Aufstockung betroffenen Gesellschafters",
  description:
    "Name des Gesellschafters, dessen Geschäftsanteile von der Aufstockung profitieren sollen. Beispiel: Max Mustermann.",
  variableType: variableTypeString,
};

export const geschäftsanteileNennbetragEinzelAufgestockt: TemplateVariable = {
  name: "Nennbeträge der aufgestockten Geschäftsanteile",
  description:
    "Einzel-Nennbeträge der Geschäftsanteile nach der Aufstockung. Beispiel: 2,00 EUR",
  variableType: variableTypeString,
};

export const geschäftsanteileNennbetragGesamt: TemplateVariable = {
  name: "Gesamtbetrag der Geschäftsanteil-Nennbeträge",
  description:
    "Summe der Nennbeträge der Geschäftsanteile, die von dem Beschluss betroffen sein sollen. Auch die Nennbeträge ergeben sich aus der Gesellschafterliste, die im Handelsregister veröffentlicht ist.",
  variableType: variableTypeString,
};

export const klauselEinziehung: TemplateVariable = {
  name: "Einziehungsklausel",
  description:
    "Vorschrift im Gesellschaftsvertrag, die die Einziehung zulässt. Ohne eine solche Vorschrift kann ein Ausscheiden des Gesellschafters ggf. durch Übertragung der Geschäftsanteile, durch Ausschlussbeschluss oder Ausschlussklage bewirkt werden.",
  variableType: variableTypeString,
};

export const klauselAbfindung: TemplateVariable = {
  name: "Abfindungsklausel",
  description:
    "Vorschrift im Gesellschaftsvertrag, die bestimmt, welche Abfindung im Falle einer Einziehung zu zahlen ist.",
  variableType: variableTypeString,
};

export const sonstigerGrundEinziehung: TemplateVariable = {
  name: "Begründung Einziehungsgrund",
  description: "Nennung der Tatsachen, die den Einziehungsgrund erfüllen. ",
  variableType: variableTypeTextarea,
};

// GeschäftsführerAusscheiden: Abberufung

export const nameAbzuberufenderGeschäftsführer: TemplateVariable = {
  name: "Name des Geschäftsführers",
  description:
    "Name des bisherigen Geschäftsführers, der abberufen werden soll. Beispiel: Marlene Musterfrau.",
  variableType: variableTypeString,
};

export const tagAbberufung: TemplateVariable = {
  name: "Tag der Abberufung",
  description:
    "Tag, an dem die Abberufung in Kraft treten soll. Beispiel: 31.12.2022",
  variableType: variableTypeString,
};

// Growth Shares

export const geschäftsanteileNummerGrowthShares: TemplateVariable = {
  name: "Geschäftsanteil-Nummer(n) der Growth Shares",
  description: `<p>
        Geschäftsanteil-Nummern, die Growth Shares an den nachträglich hinzutretenden Mitgründer übertragen werden sollen bzw. bereits übertragen worden sind.
    </p>
    <p>
        Beispiel: "1.001 bis 1.500 und 2.001 bis 2.500"
    </p>`,
  variableType: variableTypeString,
};

export const geschäftsanteileBetragGrowthSharesJeAnteil: TemplateVariable = {
  name: "NLP-Betrag je Anteil",
  description: `<p>
        NLP-Betrag auf die Geschäftsanteile aufgeteilt, die als Growth Shares an den nachträglich hinzutretenden Mitgründer übertragen werden sollen bzw. bereits übertragen worden sind.
    </p>
    <h3>Wie hoch sollte der Präferenzbetrag sein?</h3>
    <p>
        Der Präferenzbetrag sollte so gewählt werden, dass er dem aktuellen anteiligen Unternehmenswert - abzüglich des gewünschten Anteilskaufpreises - entspricht. Hierfür ist eine Bewertung erforderlich, mit der Sie Ihren Steuerberater betrauen sollten. Falls Sie bei der Auswahl eines passenden Steuerberaters/Wirtschaftsprüfers Unterstützung benötigen, können wir Ihnen gerne weiterhelfen.
    </p>
    <p>
        Beispiel: Wenn es insgesamt 25.000 Anteile gibt, der nachträglich eintretende Co-Founder 10 % der Anteile (= 2.500) übernehmen soll, der Unternehmenswert 4.000.000 € beträgt und der Kaufpreis für die Anteile insgesamt 2.500 € betragen soll, ergibt sich die folgende Rechnung für den Präferenzbetrag:
    </p>
    <p>
        4.000.000 € * 10 % - 2.500 € = 397.500 €.
    </p>
    <p>
        Auf jeden Anteil entfällt dann ein Präferenzbetrag von 397.500 € / 2.500 Anteile = 159,00 €/Geschäftsanteil.
    </p>`,
  variableType: variableTypeNumberMoney,
};

export const geschäftsanteileBetragGrowthSharesGesamt: TemplateVariable = {
  name: "NLP-Betrag insgesamt",
  description: `<p>
        NLP-Betrag insgesamt für alle Anteile, die als Growth Shares an den nachträglich hinzutretenden Mitgründer übertragen werden sollen bzw. bereits übertragen worden sind.
    </p>
    <h3>Wie hoch sollte der Präferenzbetrag sein?</h3>
    <p>
        Der Präferenzbetrag sollte so gewählt werden, dass er dem aktuellen anteiligen Unternehmenswert - abzüglich des gewünschten Anteilskaufpreises - entspricht. Hierfür ist eine Bewertung erforderlich, mit der Sie Ihren Steuerberater betrauen sollten. Falls Sie bei der Auswahl eines passenden Steuerberaters/Wirtschaftsprüfers Unterstützung benötigen, können wir Ihnen gerne weiterhelfen.
    </p>
    <p>
        Beispiel: Wenn es insgesamt 25.000 Anteile gibt, der nachträglich eintretende Co-Founder 10 % der Anteile (= 2.500) übernehmen soll, der Unternehmenswert 4.000.000 € beträgt und der Kaufpreis für die Anteile insgesamt 2.500 € betragen soll, ergibt sich die folgende Rechnung für den Präferenzbetrag:
    </p>
    <p>
        4.000.000 € * 10 % - 2.500 € = 397.500 €.
    </p>
    <p>
        Auf jeden Anteil entfällt dann ein Präferenzbetrag von 397.500 € / 2.500 Anteile = 159,00 €/Geschäftsanteil.
    </p>`,
  variableType: variableTypeNumberMoney,
};

// AnstellungsvertragAufhebung

export const tagAufhebung: TemplateVariable = {
  name: "Tag der Aufhebung",
  description:
    "Tag, an dem die Aufhebung des Anstellungsvertrags in Kraft treten soll. Beispiel: 31.12.2022",
  variableType: variableTypeString,
};

export const tantiemeZeitraumBeginn: TemplateVariable = {
  name: "Beginn Tantiemezeitraum",
  description:
    "Beginn des Zeitraum, für den zurzeit noch nicht festgestellt werden kann, ob und in welchem Umfang eine Tantieme zusteht",
  variableType: variableTypeString,
};

export const vergütungBrutto: TemplateVariable = {
  name: "Bruttovergütung",
  description:
    "Vergütung vor Abzug von Steuern und Sozialabgaben, z.B. 5.000 EUR",
  variableType: variableTypeString,
};

export const tantiemePauschale: TemplateVariable = {
  name: "Pauschaltantieme",
  description: "Höhe der Pauschaltantieme",
  variableType: variableTypeString,
};

export const tantiemeAuszahlungZeitpunkt: TemplateVariable = {
  name: "Auszahlungszeitpunkt für Pauschaltantieme",
  description:
    "Zeitpunkt, zu welchem die Pauschaltantieme ausgezahlt werden soll",
  variableType: variableTypeString,
};

// AnstellungsvertragKündigungAusWichtigemGrund

export const kündigungsgrund: TemplateVariable = {
  name: "Wichtiger Grund",
  description:
    "Eine Begründung der Kündigung ist nur erforderlich, wenn die Gesellschaft hierzu - etwa aufgrund des Anstellungsvertrags - verpflichtet ist. Dann sollte der Kündigungssachverhalt möglichst vollständig und detailliert beschrieben werden. Ansonsten sollte keine Begründung erfolgen, denn die Gesellschaft läuft ansonsten Gefahr, später auf den mitgeteilten Kündigungssachverhalt beschränkt zu sein.",
  variableType: variableTypeTextarea,
};

// GeschäftsführerAusscheiden: Schadensersatzansprüche

export const nameProzessvertreter: TemplateVariable = {
  name: "Name des Prozessvertreters",
  description:
    "Der besondere Vertreter ist inbesondere für die Prozessvertretung der Gesellschaft vor Gericht zuständig. Er kann selbst Geschäftsführer oder Gesellschafter sein, aber auch eine sonstige Person. Beispiel: Peter Schäfer.",
  variableType: variableTypeString,
};

export const grundOrganhaftung: TemplateVariable = {
  name: "Haftungsgrund",
  description:
    "Lebenssachverhalt, der etwaige Pflichtverletzung des Geschäftsführers begründet. Beispiel: der Durchführung von Luxus-Urlaubsreisen auf Kosten der Gesellschaft.",
  variableType: variableTypeTextarea,
};

// Geschäftsführer Neu: Bestellung

export const tagBestellung: TemplateVariable = {
  name: "Bestellungstag",
  description:
    "Tag, ab dem der Geschäftsführer Geschäftsführungs- und Vertretungsbefugnis haben soll.",
  variableType: variableTypeString,
};

// Geschäftsführer Geschäftsordnung

export const nameGeschäftsführungVorsitzender: TemplateVariable = {
  name: "Name des Vorsitzenden der Geschäftsführung",
  description:
    "Geschäftsführer, der dem Geschäftsführerkreis künftig vorsitzen soll. Mit dem Vorsitzenden Amts verbindet die Geschäftsordnung besondere Aufgaben. Beispiel: Maria Musterfrau.",
  variableType: variableTypeString,
};

export const geschäftsverteilung: TemplateVariable = {
  name: "Geschäftsverteilungsplan",
  description:
    "In einem Geschäftsverteilungsplan werden bestimmte Geschäftsbereiche/Ressorts bestimmten Geschäftsführern zugewiesen. Beispiel: Maria Musterfrau (Vertretung: Max Mustermann): Vertrieb, Finanzen und Personal; Max Mustermann (Vertretung: Maria Musterfrau): Einkauf, Produktion, Technik und Entwicklung.",
  variableType: variableTypeString,
};

export const betragsGrenzeEinmalgeschäft: TemplateVariable = {
  name: "Betragsgrenze für einmalige Geschäfte bzw. Jahressummen",
  description:
    "Betragsgrenze, bei deren Überschreiten ein Geschäft zustimmungsbedürftig wird. Diese Betragsgrenze gilt für bedeutende Einmalgeschäfte oder Jahressummen, z.B. Investitionen oder die Summe aller Mietbeträge eines Jahres. Der Betrag ist stark abhängig von den Bedürfnissen der einzelnen Gesellschaft. Beispiel: 20.000,00 EUR .",
  variableType: variableTypeString,
};

export const betragsGrenzeDauerschuldverhältnis: TemplateVariable = {
  name: "Betragsgrenze für Dauerschuldverhältnisse",
  description:
    "Betragsgrenze, bei deren Überschreiten ein Geschäft zustimmungsbedürftig wird. Diese Betragsgrenze gilt für monatliche Zahlungen aufgrund eines Dauerschuldverhältnisses, z.B. Miet- oder Pachtverträge. Beispiel: 1.000,00 EUR  .",
  variableType: variableTypeString,
};

// Geschäftsführer Inflationsausgleichsprämie

export const betragInflationsausgleichspraemie: TemplateVariable = {
  name: "Betrag in Euro",
  description:
    "Die Inflationsausgleichsprämie darf höchstens auf 3.000 EUR lauten, um steuerfrei zu sein. Beispiel: 3.000 EUR",
  variableType: variableTypeString,
};

// Gesellschaftsverträge

export const gbrName: TemplateVariable = {
  name: "Name der Gesellschaft",
  description: "Der Name der GbR. Beispiel: Unicorn GbR",
  variableType: variableTypeString,
};

export const gbrSitz: TemplateVariable = {
  name: "Sitz der Gesellschaft",
  description:
    "Der Sitz der GbR ist der Name des Orts, wo der örtliche Schwerpunkt der GbR liegen soll (zu Beginn meist Wohnort der Gründer). Beispiel: München",
  variableType: variableTypeString,
};

export const gbrGegenstandGeschäftsidee: TemplateVariable = {
  name: "Geschäftsidee",
  description:
    "Beschreibung der Geschäftsidee. Die Idee sollte ausreichend genau beschrieben werden, damit das Wettbewerbsverbot greifen kann.",
  variableType: variableTypeTextarea,
};
export const gbrBeginn: TemplateVariable = {
  name: "Beginn der Gesellschaft",
  description:
    "Datum, an dem die Gesellschaft entstehen soll. Beispiel: 01.01.2024",
  variableType: variableTypeString,
};
export const gbrKuendingungAusschlussDatum: TemplateVariable = {
  name: "Ablaufdatum des Kündigungsausschlusses",
  description:
    'Datum, bis wann die Gesellschaft von keinem Gesellschafter einseitig gekündigt werden darf. Beispiel: 31.12.2025. Sofern kein Kündigungsausschluss vereinbart werden soll, kann "kein Kündigungsfrist" eingegeben werden.',
  variableType: variableTypeString,
};
export const gbrKuendigungFristMonate: TemplateVariable = {
  name: "Kündigungsfrist in Monaten",
  description:
    'Anzahl der Monate, die ein kündigender Gesellschafter abwarten muss, bevor er aus der Gesellschaft ausscheidet. Beispiel: "6". Sofern keine Kündigungsfrist vereinbart werden soll, kann "0" eingegeben werden. ',
  variableType: variableTypeString,
};
export const gbrGesellschafterNamen: TemplateVariable = {
  name: "Namen der Gesellschafter",
  description:
    "Die Namen der Gesellschafter, unter welchen der Gesellschaftsvertrag geschlossen werden soll. Beispiel: Susanne Schmidt, Markus Müller, Paula Peters.",
  variableType: variableTypeTextarea,
};

export const gbrGesellschafterBeteiligungsquoten: TemplateVariable = {
  name: "Beteiligungsquoten",
  description:
    "Die Beteiligungsquoten, mit welchen die Gesellschafter an der Gesellschaft beteiligt werden soll. Beteiligungsquoten können in Prozent oder als Brüche ausgedrückt werden. Beispiel: Susanne Schmidt: 1/3, Markus Müller: 1/3, Paula Peters: 1/3. ",
  variableType: variableTypeTextarea,
};

// Kapital

export const betragKapitalrücklage: TemplateVariable = {
  name: "Betrag der Kapitalrücklage",
  description: "Beispiel: 10.000 EUR",
  variableType: variableTypeString,
};

export const nameGesellschafterDarlehensgeber: TemplateVariable = {
  name: "Name des darlehensgebenden Gesellschafters",
  description:
    "Name des Gesellschafters, der das Darlehen an die Gesellschaft gewährt. Beispiel: Peter Schäfer",
  variableType: variableTypeString,
};

export const darlehenBetragZahl: TemplateVariable = {
  name: "Darlehensbetrag (Zahl)",
  description: "Beispiel: 10.000 EUR",
  variableType: variableTypeString,
};

export const darlehenBetragWort: TemplateVariable = {
  name: "Darlehensbetrag (in Worten)",
  description: "Beispiel: zehntausend Euro",
  variableType: variableTypeString,
};

export const datumDarlehensvertrag: TemplateVariable = {
  name: "Datum des Darlehensvertrages",
  description: "Beispiel: 16.03.2022",
  variableType: variableTypeString,
};

export const darlehenRückzahlungen: TemplateVariable = {
  name: "Rückzahlungsbetrag",
  description: "Beispiel: 'keine' oder '5.000 EUR'",
  variableType: variableTypeString,
};

export const jahreszinsProzentpunkteÜberBasiszinssatz: TemplateVariable = {
  name: "Jährlicher Zinssatz ",
  description:
    "Beispiel: '2 %' oder '2 Prozentpunkte über dem jeweiligen Basiszinssatz'",
  variableType: variableTypeString,
};

export const darlehenTagRückzahlung: TemplateVariable = {
  name: "Tag der Darlehensrückzahlung",
  description:
    "Tag, an welchem das Darlehen zur Rückzahlung fällig wird. Beispiel: 31.12.2040",
  variableType: variableTypeString,
};

export const darlehenSicherheiten: TemplateVariable = {
  name: "Sicherheiten",
  description:
    "Aufzählungen etwaiger Sicherheiten. Beispiel: Grundpfandrechte etc, oder auch 'keine'",
  variableType: variableTypeString,
};

export const anteilEinzahlung: TemplateVariable = {
  name: "eingezahlter Anteil",
  description:
    "Bislang eingezahlter Anteil der Nennbeträge der Geschäftsanteile. Beispiel: 50 %",
  variableType: variableTypeString,
};

export const satzungErmächtigung: TemplateVariable = {
  name: "Regelung des genehmigten Kapitals im Gesellschaftsvertrag",
  description:
    "Bezeichnung der Vorschrift im Gesellschaftsvertrag, die die Ermächtigung der Geschäftsführung zur Ausnutzung des genehmigten Kapitals und der Satzungsanpassung enthält. Beispiel § 3 Abs. 2",
  variableType: variableTypeString,
};

export const satzungStammkapital: TemplateVariable = {
  name: "Regelung des Stammkapitals im Gesellschaftsvertrag",
  description:
    "Bezeichnung der Vorschrift im Gesellschaftsvertrag, die die Höhe des Stammkapitals regelt. Beispiel § 3",
  variableType: variableTypeString,
};

export const ausnutzungFrist: TemplateVariable = {
  name: "Fristablauf für die Ausnutzung des genehmigten Kapital",
  description:
    "Zeitpunkt, an dem die Ermächtigung zur Ausnutzung des genehmigten Kapitals abläuft. Höchstens fünf Jahre gemäß § 55a Abs. 2 GmbHG. Beispiel: 31.12.2026",
  variableType: variableTypeString,
};

export const genehmigtesKapitalMaximum: TemplateVariable = {
  name: "Maximaler Kapitalerhöhungsbetrag",
  description:
    "Die Ermächtigung zur Ausnutzung des genehmigten Kapitals ist auf diesen Höchstbetrag begrenzt. Beispiel: 20.000 EUR",
  variableType: variableTypeString,
};

export const stammkapitalErhöhungsbetrag: TemplateVariable = {
  name: "Betrag der Kapitalerhöhung",
  description:
    "Betrag, um welchen das Stammkapital erhöht werden soll. Beispiel: 20.000 EUR",
  variableType: variableTypeString,
};

export const stammkapitalBetragNachErhöhung: TemplateVariable = {
  name: "Erhöhtes Stammkapitals",
  description:
    "Betrag des erhöhten Stammkapitals, der sich ergibt, wenn die Kapitalerhöhung durchgeführt worden ist. Beispiel: 20.000 EUR",
  variableType: variableTypeString,
};

export const stammkapitalBetragNachErhöhungInWorten: TemplateVariable = {
  name: "In Worten: Erhöhtes Stammkapital",
  description:
    "Betrag des erhöhten Stammkapitals in Worten, der sich ergibt, wenn die Kapitalerhöhung durchgeführt worden ist. Beispiel: 20.000 EUR",
  variableType: variableTypeString,
};

export const neueGeschäftsanteileAnzahl: TemplateVariable = {
  name: "Anzahl der neuen Geschäftsanteile",
  description:
    "Anzahl der neuen Geschäftsanteile. Beispiel für den Fall, dass im Gesellschaftsvertrag 1,00 EUR pro Geschäftsanteil bestimmt ist: 20.000",
  variableType: variableTypeString,
};

export const neueGeschäftsanteileBetrag: TemplateVariable = {
  name: "Einzelbeträge der neuen Geschäftsanteile",
  description:
    "Einzelner Nennbetrag, auf den die neuen Geschäftsanteile jeweils lauten sollen. Beispiel: 1,00 EUR",
  variableType: variableTypeString,
};

export const neueGeschäftsanteileAusgabebetrag: TemplateVariable = {
  name: "Ausgabebetrag je neuen Geschäftsanteils",
  description:
    'Betrag, gegen den die neuen Geschäftsanteile jeweils ausgegeben werden sollen. Mindestbetrag ist der Nennbetrag. Die Differenz zwischen Ausgabebetrag und Nennbetrag ergibt das sogenannte "Aufgeld" oder "Agio". Beispiel für den Fall, dass das Aufgeld 1 EUR betragen soll: 2,00 EUR',
  variableType: variableTypeString,
};

export const tagBeginnGewinnberechtigung: TemplateVariable = {
  name: "Erster Tag der Gewinnberechtigung",
  description:
    'Beispiel: "Tag der Handelsregistereintragung" oder "01.01.2022"',
  variableType: variableTypeString,
};

export const bankDatenGesellschaft: TemplateVariable = {
  name: "IBAN Gesellschaftskonto",
  description:
    "IBAN zum Bankkonto der Gesellschaft, auf welches Zahlungen an die Gesellschafte erfolgen sollen. Beispiel: DE90 12345678 123456789",
  variableType: variableTypeString,
};

export const übernahmeZugelasseneGesellschafter: TemplateVariable = {
  name: "Übernahmeberechtigte/r Gesellschafter",
  description:
    "Name des/der Gesellschafter, des/die zur Übernahme der Geschäftsanteile durch Abgabe der Übernahmeerklärungen berechtigt sein soll/en. Beispiel: Tim Schäfer, Peter Müller",
  variableType: variableTypeString,
};

export const darlehenDatum: TemplateVariable = {
  name: "Datum des Darlehensvertrages",
  description:
    "Datum, an dem der Vertrag über das Darlehen abgeschlossen worden ist, welches nun zu einem neuen Geschäftsanteil umgewandelt werden soll. Beispiel: 01.02.2020",
  variableType: variableTypeString,
};

export const darlehenHöhe: TemplateVariable = {
  name: "Darlehensbetrag",
  description:
    "Höhe des Darlehens, welches nun zu einem neuen Geschäftsanteil umgewandelt werden soll. Beispiel: 10.000 EUR",
  variableType: variableTypeString,
};

export const übernahmeFrist: TemplateVariable = {
  name: "Ablauf der Übernahmefrist",
  description:
    "Zeitpunkt, bis zu welchem die übernahmeberechtigten Gesellschafter spätestens die Übernahme erklären können sollen. Beispiel: 31.03.2022",
  variableType: variableTypeString,
};

export const übernahmeNachfristAndereGesellschafter: TemplateVariable = {
  name: "Nachfrist-Zeitraum ",
  description:
    "Zeitraum, innerhalb dessen andere Gesellschafter spätestens die Übernahme erklären können sollen, wenn die Übernahmefrist für die ursprünglich übernahmeberechtigten Gesellschafter bereits abgelaufen ist. Beispiel: drei Wochen",
  variableType: variableTypeString,
};

export const satzungGenehmigtesKapitalAngepassteFassung: TemplateVariable = {
  name: "Angepasster Passus zum genehmigten Kapital ",
  description:
    'Fassung der Regelung zum genehmigten Kapital im Gesellschaftsvertag (Ermächtigung des Vorstands), die sich nach der teilweisen Ausnutzung des genehmigten Kapitals ergibt. Beispiel für den Fall, dass von einem genehmigten Kapital iHv. 20.000 EUR nur 10.000 EUR ausgenutzt worden sind: "Die Geschäftsführung ist ermächtigt, das Stammkapital der Gesellschaft bis zum 31.12.2026 durch Ausgabe neuer Geschäftsanteile gegen Bareinlagen oder gegen Sacheinlagen einmalig oder mehrmals insgesamt jedoch höchstens um 10.000 EUR zu erhöhen. Die Geschäftsführung ist zudem ermächtigt, das Bezugsrecht der Gesellschafter im Rahmen der Ausnutzung des genehmigten Kapitals ganz oder teilweise auszuschließen, soweit dies ausnahmsweise sachlich gerechtfertigt ist. Die Geschäftsführung wird weiter ermächtigt, nach der teilweisen oder vollständigen Ausnutzung des genehmigten Kapitals oder Ablauf der Frist für die Ausnutzung des genehmigten Kapitals die Fassung der Satzung entsprechend anzupassen."',
  variableType: variableTypeTextarea,
};

// Teilung

export const geschäftsanteileNummerVorTeilung: TemplateVariable = {
  name: "Lfd. Nr(n) vor Teilung",
  description:
    "Laufende Nummer(n) der Geschäftsanteile, die aufgeteilt werden sollen.",
  variableType: variableTypeString,
};

export const geschäftsanteileTeilungNennbetragGesamt: TemplateVariable = {
  name: "Gesamtnennbetrag",
  description:
    "Summe der Nennbeträge der Geschäftsanteile, die geteilt werden sollen. Beispiel: 10.000 EUR",
  variableType: variableTypeString,
};

export const geschäftsanteileNachTeilungAnzahl: TemplateVariable = {
  name: "Anzahl nach Teilung",
  description:
    "Anzahl der Nennbeträge, nachdem sie aufgeteilt worden sind. Beispiel: 10.000",
  variableType: variableTypeString,
};

export const geschäftsanteileNachTeilungNennbetragJeweils: TemplateVariable = {
  name: "Einzel-Nennbetrag nach Teilung",
  description:
    "Einzelne Nennbeträge, die die Geschäftsanteile nach der Teilung haben sollen. Beispiel: 1,00 EUR",
  variableType: variableTypeString,
};

// Pensionszusage

export const begünstigtePerson: TemplateVariable = {
  name: "Begünstigte Person",
  description:
    "Person, die durch die Pesnionszusage begünstigt wird. Beispiel: Max Mustermann",
  variableType: variableTypeString,
};

// Prokurist

export const nameProkurist: TemplateVariable = {
  name: "Name des Prokuristen",
  description:
    "Name des Prokuristen, den der Beschluss betreffen soll. Beispiel: Marlene Musterfrau",
  variableType: variableTypeString,
};

export const geburtsdatumProkurist: TemplateVariable = {
  name: "Geburtsdatum des Prokuristen",
  description:
    "Geburtsdatum des Prokuristen, den der Beschluss betreffen soll. Beispiel: 30.06.1988",
  variableType: variableTypeString,
};

export const adresseProkurist: TemplateVariable = {
  name: "Adresse des Prokuristen",
  description:
    "Straße, PLZ und Ort des Prokuristen, den der Beschluss betreffen soll. Dabei ist die Wohnadresse anzugeben. Beispiel: Lindenweg 10, 60316 Frankfurt am Main",
  variableType: variableTypeString,
};

export const tagProkura: TemplateVariable = {
  name: "Erteilungstag",
  description: "Tag, ab dem die Prokuraerteilung wirksam werden soll.",
  variableType: variableTypeString,
};

// Rücklagenbildung

export const zweckruecklageName: TemplateVariable = {
  name: "Name der Rücklage",
  description:
    'Bezeichnung, aus dem der Zweck der Rücklage ersichtlich wird. Bsp: "Investitionsrücklage für Erweiterungsbau" ',
  variableType: variableTypeString,
};

export const zweckruecklageBetrag: TemplateVariable = {
  name: "Betrag der Rücklage",
  description:
    "Betrag, der in die zweckgebundene Rücklage eingestellt werden soll. Bsp: 3 Mio. EUR",
  variableType: variableTypeString,
};

export const zweckruecklageVorhabenart: TemplateVariable = {
  name: "Projektbeschreibung",
  description:
    'Bereits zu Beginn der Rücklagenbildung müssen die Art des Projekts konkret feststehen, z.B. "Neubau des Vereinsgebäudes"',
  variableType: variableTypeString,
};

export const zweckruecklageVorhabenkosten: TemplateVariable = {
  name: "Voraussichtliche Projektkosten",
  description:
    'Auch die voraussichtlichen Kosten müssen für die zulässige Rücklagenbildung bezifferbar sein, z.B. ca. "3 Mio. €"',
  variableType: variableTypeString,
};

export const zweckruecklageZeitplan: TemplateVariable = {
  name: "Zeitplan für Umsetzung",
  description:
    'Zusätzlich muss eine konkrete Vorstellung für den Zeitpunkt des Projektabschlusses bestehe, damit die Rücklagenbildung zulässig ist, z.B. "Oktober 2026". Bei den Sonderfällen einer Betriebsmittelrücklage oder Instandhaltungsrücklage kann der Abschluss auch "regelmäßig wiederkehrend" sein.',
  variableType: variableTypeString,
};

export const rechnungsabschlussDatum: TemplateVariable = {
  name: "Stichtag des Rechnungsabschlusses",
  description:
    "Datum, für welchen der Rechnungsabschluss des Vereins aufgestellt worden ist. Bsp: 31.12.2022",
  variableType: variableTypeString,
};

export const wiederbeschaffungsruecklageName: TemplateVariable = {
  name: "Name der Rücklage",
  description:
    'Bezeichnung, aus dem die konkret beabsichtigte Wiederbeschaffung ersichtlich wird. Bsp: "Neubau des Vereinsheims" ',
  variableType: variableTypeString,
};

export const wiederbeschaffungsruecklageBetrag: TemplateVariable = {
  name: "Betrag der Rücklage",
  description:
    "Betrag, der in die Wiederbeschaffungsrücklage eingestellt werden soll. Bsp: 3 Mio. EUR",
  variableType: variableTypeString,
};

// Social Media Policy
export const unternehmenFirmaSocialPolicy: TemplateVariable = {
  name: "Firma d. Unternehmens",
  description:
    "Firma des Unternehmens, für die die Social Media Policy/Guidelines gelten sollen. Beispiel: Unicorn GmbH.",
  variableType: variableTypeString,
};

// Verein

export const vereinKassenprueferQualifikation: TemplateVariable = {
  name: "Kassenprüfer müssen Gründungsmitglieder sein",
  description:
    "Mit dieser Option wird in die Satzung eine Klausel eingefügt, die vorsieht, dass die Kassenprüfer Gründungsmitglieder des Vereins sein müssen. Diese Klausel kann sinnvoll sein, um sicherzustellen, dass die Kassenprüfer mit den Besonderheiten des Vereins in besonderem Maße vertraut sind. ",
  variableType: variableTypeSwitch,
  html: `&nbsp;und Gründungsmitglieder sein müssen`,
};

export const variantsVereinGruendungsmitgliederStimmrecht: TemplateVariable = {
  name: "Mehrstimmrecht für Gründungsmitglieder",
  description:
    "Ein Mehrstimmrecht für Gründungsmitglieder wird für zulässig angesehen, soweit diese nicht einzelne Mitglieder einen beherrschenden Einfluss auf die Mitgliederbeschlüsse verschaffen („Mehrheitsstimmrecht“).",
  variableType: variableTypeVariants,
  options: [
    {
      label: "Kein besonderes Stimmrecht",
      html: `<span></span>`,
    },
    {
      label: "Doppeltes Stimmrecht",
      html: "Hiervon abweichend kommt den Gründungsmitgliedern ein doppeltes Stimmrecht zu.",
    },
    {
      label: "Dreifaches Stimmrecht",
      html: "Hiervon abweichend kommt den Gründungsmitgliedern ein dreifaches Stimmrecht zu.",
    },
    {
      label: "Fünffaches Stimmrecht",
      html: "Hiervon abweichend kommt den Gründungsmitgliedern ein fünffaches Stimmrecht zu.",
    },
    {
      label: "Zehnfaches Stimmrecht",
      html: "Hiervon abweichend kommt den Gründungsmitgliedern ein zehnfaches Stimmrecht zu.",
    },
  ],
};

export const vereinMitgliederMindestalter: TemplateVariable = {
  name: "Mindestalter für Mitgliedschaft in Jahren",
  description: "Hier ist das Mindestalter in Jahren einzugeben. Beispiel: 21",
  variableType: variableTypeNumber,
  unitInput: "Jahre",
  unitOutput: undefined,
};

export const aufnahmegebuehrHoehe: TemplateVariable = {
  name: "Aufnahmegebühr",
  description:
    "Hier ist der Betrag der einmaligen Aufnahmegebühr einzugeben. Beispiel: 100,00",
  variableType: variableTypeNumberMoney,
};

export const grundbeitragGeldBasic: TemplateVariable = {
  name: "Basic-Geldbetrag (monatlich)",
  description:
    "Höhe des Mitgliedsbeitrags, den ein Vereinsmitglied im Basic-Tarif monaltlich zu zahlen hat. Beispiel: 20,00",
  variableType: variableTypeNumberMoney,
};

export const grundbeitragStundenBasic: TemplateVariable = {
  name: "Basic-Arbeitsstunden (monatlich)",
  description:
    "Anzahl der Arbeitsstunden, die ein Vereinsmitglied im Basic-Tarif monaltlich zu erbringen hat. Beispiel: 20",
  variableType: variableTypeNumber,
};

export const grundbeitragGeldBronze: TemplateVariable = {
  name: "Bronze-Geldbetrag (monatlich)",
  description:
    "Höhe des Mitgliedsbeitrags, den ein Vereinsmitglied im Bronze-Tarif monaltlich zu zahlen hat. Beispiel: 30,00",
  variableType: variableTypeNumberMoney,
};

export const grundbeitragStundenBronze: TemplateVariable = {
  name: "Bronze-Arbeitsstunden (monatlich)",
  description:
    "Anzahl der Arbeitsstunden, die ein Vereinsmitglied im Bronze-Tarif monaltlich zu erbringen hat. Beispiel: 30",
  variableType: variableTypeNumberMoney,
};

export const grundbeitragGeldSilber: TemplateVariable = {
  name: "Silber-Geldbetrag (monatlich)",
  description:
    "Höhe des Mitgliedsbeitrags, den ein Vereinsmitglied im Silber-Tarif monaltlich zu zahlen hat. Beispiel: 40,00",
  variableType: variableTypeNumberMoney,
};

export const grundbeitragStundenSilber: TemplateVariable = {
  name: "Silber-Arbeitsstunden (monatlich)",
  description:
    "Anzahl der Arbeitsstunden, die ein Vereinsmitglied im Silber-Tarif monaltlich zu erbringen hat. Beispiel: 40",
  variableType: variableTypeNumber,
};

export const grundbeitragGeldGold: TemplateVariable = {
  name: "Gold-Geldbetrag (monatlich)",
  description:
    "Höhe des Mitgliedsbeitrags, den ein Vereinsmitglied im Gold-Tarif monaltlich zu zahlen hat. Beispiel: 50,00",
  variableType: variableTypeNumberMoney,
};

export const grundbeitragStundenGold: TemplateVariable = {
  name: "Gold-Arbeitsstunden (monatlich)",
  description:
    "Anzahl der Arbeitsstunden, die ein Vereinsmitglied im Gold-Tarif monaltlich zu erbringen hat. Beispiel: 50",
  variableType: variableTypeNumber,
};

export const praeventivbeauftragterName: TemplateVariable = {
  name: "Name des/der Präventionsbeauftragte/n",
  description:
    "Name des des/der Präventionsbeauftragte/n, der/die ernannt werden soll. Beispiel: Carola Schusters",
  variableType: variableTypeString,
};

export const praeventivbeauftragterGeburtsdatum: TemplateVariable = {
  name: "Geburtsdatum des/der Präventionsbeauftragte/n",
  description:
    "Geburtsdatum des/der Präventionsbeauftragte/n, der/die ernannt werden soll. Beispiel: 30.06.1988",
  variableType: variableTypeString,
};

export const praeventivbeauftragterWohnort: TemplateVariable = {
  name: "Adresse des/der Präventionsbeauftragte/n",
  description:
    "Straße, PLZ und Ort des/der Präventionsbeauftragte/n, der/die ernannt werden soll. Dabei ist die Wohnadresse anzugeben. Beispiel: Lindenweg 10, 60316 Frankfurt am Main",
  variableType: variableTypeString,
};

export const vereinName: TemplateVariable = {
  name: `Vereinsname (ohne "e.V.")`,
  description: `<p>
        Name des Vereins (ohne "e.V."), der gegründet werden soll.
    </p>
    <p>
        Beispiel: Musterverein
    </p>`,
  variableType: variableTypeString,
};

export const vereinSitz: TemplateVariable = {
  name: "Vereinssitz",
  description:
    "Der Sitz des Vereins ist der Name des Orts, wo der örtliche Schwerpunkt des Vereins liegen soll (zu Beginn meist Wohnort der Gründungsmitglieder). Beispiel: Berlin",
  variableType: variableTypeString,
};

export const vereinVorstandErsterVorsitzender: TemplateVariable = {
  name: `1. Vorsitzende/r (Name Geburtsdatum Wohnort)`,
  description:
    "Angaben, die für die Berufung zum Vorstand und Eintragung in das Vereinsregister benötigt werden. Beispiel: Petra Musterfrau, 01.01.1980, Berlin",
  variableType: variableTypeString,
};

export const vereinVorstandZweiterVorsitzender: TemplateVariable = {
  name: `2. Vorsitzende/r (Name Geburtsdatum Wohnort)`,
  description:
    "Angaben, die für die Berufung zum Vorstand und Eintragung in das Vereinsregister benötigt werden. Beispiel: Petra Musterfrau, 01.01.1980, Berlin",
  variableType: variableTypeString,
};

export const vereinVorstandSchatzmeister: TemplateVariable = {
  name: `Schatzmeister/in (Name Geburtsdatum Wohnort)`,
  description:
    "Angaben, die für die Berufung zum Vorstand und Eintragung in das Vereinsregister benötigt werden. Beispiel: Petra Musterfrau, 01.01.1980, Berlin",
  variableType: variableTypeString,
};

export const vereinVorstandGeborenesMitglied: TemplateVariable = {
  name: `Vorstandsmitglied kraft Satzung (Name Geburtsdatum Wohnort)`,
  description:
    'Angaben, die für die Bestimmung zum Vorstand kraft Satzung ("geborenes Vorstandsmitglied") benötigt werden. Beispiel: Petra Musterfrau, 01.01.1980, Berlin',
  variableType: variableTypeString,
};

export const vereinVorstandMindestgehalt: TemplateVariable = {
  name: `Mindestgehalt (brutto) für Vorstand`,
  description:
    "Bruttobetrag, den die Vorstandsmitglieder jedenfalls als Vergütung erhalten sollen. Beispiel: 5.000 EUR",
  variableType: variableTypeNumberMoney,
};

export const vereinMitgliederVetorecht: TemplateVariable = {
  name: `Mitglieder mit Vetorecht`,
  description:
    "Benennung der Mitglieder, denen ein Vetorecht zukommt. Beispiel: Petra Musterfrau, 01.01.1980, Berlin",
  variableType: variableTypeString,
};

export const vereinAnfallBerechtigter: TemplateVariable = {
  name: `Anfallberechtigte Person`,
  description:
    "Möglichst genaue Bezeichnung der anfallberechtigten Person. Beispiel: XYZ GmbH mit Sitz in Köln oder Petra Musterfrau, 01.01.1980, Berlin",
  variableType: variableTypeString,
};

// Versicherung

export const zuVersicherendePerson: TemplateVariable = {
  name: "Zu versichernde Person",
  description:
    "Name des Geschäftsführers, für den der Versicherungsvertrag abgeschlossen werden soll. Beispiel: Max Power",
  variableType: variableTypeString,
};
export const betragHöchstensDeckungssumme: TemplateVariable = {
  name: "Deckungssumme (Höchstbetrag)",
  description:
    "Maximaler Erstattungsbetrag im Schadensfall, der höchstens im Versicherungvertrag vereinbart werden darf. Hohe Deckungssummen bedeuten in der Regel höhere Versicherungsbeiträgen. Typisch sind Deckungssummen zwischen 1 und 10 Millionen Euro. Beispiel: 1 Million Euro",
  variableType: variableTypeString,
};
export const betragMindestensSelbstbehalt: TemplateVariable = {
  name: "Selbstbehalt (Mindestbetrag)",
  description:
    "Eigenbeteiligung der versicherten Person im Schadensfall, die mindestens im Versicherungsvertrag vereinbart werden muss. Durch die Vereinbarung einer angemessenen Eigenbeteiligung möchten Unternehmen sicherstellen, dass Geschäftsführer etc nicht leichtfertig handeln und unverantwortliche Entscheidungen treffen. Hohe Selbstbehalte führen zu geringeren Versicherungsbeiträgen. Beispiel: 10% des Schadens, höchstens jedoch das 1½-fache der festen Jahresvergütung",
  variableType: variableTypeString,
};

// Vollmacht

export const nameBevollmächtigter: TemplateVariable = {
  name: "Name des Bevollmächtigten",
  description:
    "Name des Bevollmächtigten, den der Beschluss betreffen soll. Beispiel: Marlene Musterfrau",
  variableType: variableTypeString,
};

export const geburtsdatumBevollmächtigter: TemplateVariable = {
  name: "Geburtsdatum des Bevollmächtigten",
  description:
    "Geburtsdatum des Bevollmächtigten, den der Beschluss betreffen soll. Beispiel: 30.06.1988",
  variableType: variableTypeString,
};

export const adresseBevollmächtigter: TemplateVariable = {
  name: "Adresse des Bevollmächtigten",
  description:
    "Straße, PLZ und Ort des Bevollmächtigten, den der Beschluss betreffen soll. Dabei ist die Wohnadresse anzugeben. Beispiel: Lindenweg 10, 60316 Frankfurt am Main",
  variableType: variableTypeString,
};

export const tagVollmacht: TemplateVariable = {
  name: "Erteilungstag",
  description: "Tag, ab dem die Bevollmächtigung wirksam werden soll.",
  variableType: variableTypeString,
};

export const vollmachtArtGeschäfte: TemplateVariable = {
  name: "Art der Geschäfte",
  description:
    "Geschäftsart, zu deren Abschluss die Handlungsvollmacht berechtigen soll.",
  variableType: variableTypeString,
};

// Vorstand

export const switchBegründung: TemplateVariable = {
  name: "Begründung der Abberufung/Vertragsbeendigung",
  description: `
        <p>
            Für die Wirksamkeit des Abberufungsbeschlusses sind Ausführungen zum wichtigen Grund für die Abberufung nicht zwingend erforderlich (§ 107 Abs. 2 S. 3 AktG). Auch der wichtige Grund für die außerordentliche Kündigung muss nach der Rechtsprechung des BGH nicht zwingend im Aufsichtsratsbeschluss dargelegt werden.
        </p>
        <p>
            <strong>Praxistipp:</strong> Um die sorgfaltsgemäße Ausübung seiner Pflichten zu dokumentieren, legt der Aufsichtsrat in der Praxis trotzdem in dem meisten Fällen zumindest zusammengefasst die Gründe dar, die ihn zur Abberufung (und ggf. zur außerordentlichen Kündigung des Anstellungsvertrages) bewogen haben.
        </p>
    `,
  variableType: variableTypeSwitch,
  html: undefined,
  additionalInput: variableTypeTextarea,
  additionalInputPrefix: `<p>Zur Begründung führt der Aufsichtsrat aus:</p>`,
  additionalInputSuffix: undefined,
};

export const switchVorstandsmitgliedRessortzuweisung: TemplateVariable = {
  name: "Ressort zuweisen",
  description: `
        <p>
             Eine Ressortzuweisung ist in der Regel optional, allerdings in der Praxis bei einem mehrköpfigen Vorstand üblich, denn einerseits werden Vorstandsmitglieder gerade auch zur Wahrnehmung bestimmter Vorstandsaufgaben angestellt, andererseits reduziert eine Ressortzuweisung die Haftung für die nicht ressortverantwortlichen Vorstandsmitglieder.
        </p>
        <p>
            Ausnahmsweise kann eine Ressortzuweisung zwingend vorzunehmen sein, nämlich bei der Bestellung eines Vorstandsmitglieds als Arbeitsdirektor einer (paritätisch) mitbestimmten AG. Dem Arbeitsdirektor müssen dann im Rahmen seiner Bestellung das Ressort "Personal- und Sozialangelegenheiten" der Gesellschaft zugewiesen werden.
        </p>
    `,
  variableType: variableTypeSwitch,
  html: undefined,
  additionalInput: variableTypeString,
  additionalInputPrefix: `<li><p>Das neue Vorstandsmitglied erhält die Zuständigkeit für das folgende Ressort: <strong>`,
  additionalInputSuffix: `</strong></p></li>`,
};

export const switchVorstandsmitgliedBesonderesAmt: TemplateVariable = {
  name: "Ernennung für besonderes Amt",
  description: `
        <p>
             Die Ernennung für ein besonderes Amt (zB Vorstandsvorsitzender, Vorstandssprecher, o.ä.) kann optional in einem Bestellungsbeschluss enthalten sein.
         </p>
         <p>
            Aus einer solchen Ernennung folgen zwar keine besonderen gesetzlichen Befugnisse bzw. Pflichten, allerdings spiegelt ein solches Amt in der Praxis einen faktischen Führungsanspruch wider.
        </p>
    `,
  variableType: variableTypeSwitch,
  html: undefined,
  additionalInput: variableTypeString,
  additionalInputPrefix: `<li><p>Das neue Vorstandsmitglied wird zur/zum<strong>`,
  additionalInputSuffix: `</strong>ernannt.</p></li>`,
};

export const switchVorstandsmitgliedAnstellungsvertrag: TemplateVariable = {
  name: "Anstellungsvertrag abschließen",
  description: `
        <p>
            Der Abschluss eines als Anlage beigefügten Anstellungsvertrags kann optional mitbeschlossen werden. Alternativ kann ein gesonderter Beschluss hierzu gefasst werden.
        </p>
    `,
  variableType: variableTypeSwitch,
  html: ` <li><p>
            Der Aufsichtsratsvorsitzende wird beauftragt und ermächtigt, den als <strong>Anlage</strong> beigefügten Anstellungsvertrag im Namen des Aufsichtsrates der Gesellschaft für die Gesellschaft mit dem neuen Vorstandsmitglied abzuschließen.
        </p>
    </li>`,
};

export const switchEntlastungEinsatz: TemplateVariable = {
  name: "Einsatz für Entlastung der Hauptversammlung",
  description: `
        <p>
            In der Praxis wünscht das ausscheidende Vorstandsmitglied häufig, dass der Aufsichtsrat der Hauptversammlung das Vorstandsmitglied für eine Entlastung vorschlägt (§ 120 Abs. 2 S.1 AktG).
        </p>
        <p>
            Eine solche Entlastung stellt aber selbst im Fall ihrer Gewährung durch die hierfür zuständige Hauptversammlung der Gesellschaft keinen bindenden Verzicht auf etwaige Ersatzansprüche der Gesellschaft dar (vgl. § 120 Abs. 2 S. 2 AktG).
        </p>
        <p>
             Wenn sich das ausscheidende Vorstandsmitglied wirksam vor einer späteren Inhaftungnahme durch die Gesellschaft schützen möchte, ist es nach der Rechtsprechung auch nicht ausreichend, dass sich die Gesellschaft zur Unterlassung der Inanspruchnahme verpflichtet. Die einzige zumindest beschränkt wirksame Schutz ist die sogenannte "Versicherungslösung": Ein Dritter kann sich verpflichten, das Vorstandsmitglied von der Haftung gegenüber der Gesellschaft freizustellen.
        </p>
    `,
  variableType: variableTypeSwitch,
  html: ` <li><p>
            Der Aufsichtsrat wird sich dafür einsetzen, dass dem Vorstandsmitglied von der Hauptversammlung Entlastung für seine Vorstandstätigkeit erteilt wird.
        </p>
    </li>`,
};

export const variantsVorstandsmitgliedFreistellung: TemplateVariable = {
  name: 'Freistellung ("Suspendierung")',
  description: `

        <p>
            Eine Freistellung des Vorstandsmitglieds kann sinnvoll sein, wenn das Vorstandsmitglieds erst zum regulären Vertragsende ausscheidet oder wenn die Anstellung aufgrund einer Kündigung oder eines Aufhebungsvertrags vor dem regulären Vertragsende endet.
        </p>
        <p>
            Die Freistellung kann einerseits dazu dienen, dem Vorstandsmitglied von seinen Pflichten zu entbinden und ihm die Möglichkeit zu geben, sich auf die Suche nach einer neuen Tätigkeit zu konzentrieren.
        </p>
        <p>
            Die Freistellung kann jedoch auch sinnvoll sein, um das Vorstandsmitglied von seinen Pflichten zu entbinden und zu verhindern, dass es noch weitere Unruhe/Schaden im  Unternehmen anricht.
        </p>
        <p>
            Nur bei einer <strong>unwiderruflichen Freistellung</strong> kann die Freistellung mit einer Urlaubsabgeltung verbunden sein, um den Urlaubsanspruch des Vorstandsmitglieds abzugelten. Mit einer nur widerruflichen Freistellung lässt sich der Urlaubsanspruch nach der Rechtsprechung des BAG nicht abgelten.
        </p>
    `,

  variableType: variableTypeVariants,
  options: [
    {
      label: "Keine Freistellung",
      html: `<span></span>`,
    },
    {
      label: "Unwiderrufliche Freistellung mit Urlaubsabgeltung",
      html: ` <li><p>Das vorgenannte Vorstandsmitglied wird mit sofortiger Wirkung bis zum Beendigungszeitpunkt seines Anstellungsverhältnisses unter Fortzahlung der vertragsgemäßen Bezüge unwiderruflich freigestellt. Durch die Freistellung sind sämtliche Urlaubsansprüche abgegolten.</p></li>`,
    },
    {
      label: "Widerrufliche Freistellung",
      html: ` <li><p>Das vorgenannte Vorstandsmitglied wird mit sofortiger Wirkung bis zum Beendigungszeitpunkt seines Anstellungsverhältnisses unter Fortzahlung der vertragsgemäßen Bezüge widerruflich freigestellt.</p></li>`,
    },
  ],
};

// KI-Prüfung: Ist die Kündigung fristgemäß?
export const variantsVorstandsmitgliedDienstverhaeltnisBeendigung: TemplateVariable =
  {
    name: "Beendigung Anstellung",
    description: `<p>
      Die Abberufung führt lediglich zum Entzug der Vertretungs- und Geschäftsführungsbefugnisse des Vorstandsmitglieds. Daneben ist noch eine Beendigung des Anstellungsvertrags notwendig.
    </p>
    <ul>
        <li>
            <p>
                <strong>Außerordentliche Kündigung:</strong>
            </p>
            <p>
                Die Kündigung eines Dienstverhältnisses aus wichtigem Grund ist stets möglich und nicht abdingbar (§ 626 BGB). Damit die außerordentliche Kündigung zulässig ist, muss ein Kündigungsgrund vorliegen. Hier sind höhere Anforderungen als an den wichtigen Abberufungsgrund zu stellen.
            </p>
            <p>
                Eine vorherige Abmahnung und Anhörung ist bei der Kündigung eines Vorstandsmitglieds nur erforderlich, wenn dies in der Satzung oder im Anstellungsvertrag vorgesehen ist.
            </p>
        </li>
        <li>
            <p>
                <strong>Ordentliche Kündigung:</strong>
            </p>
            <p>
                Da Vorstandsanstellungsverträge in der Regel auf die Amtszeit des Vorstandsmitglieds befristet sind, ist eine ordentliche Kündigung nach § 620 Abs. 1 BGB ausgeschlossen, wenn nichts Abweichendes vereinbart worden ist. Sie bedarf keines Kündigungsgrundes, da das KSchG nicht gilt.
            </p>
        </li>
        <li>
            <p>
                <strong>Einvernehmliche (vorzeitige) Aufhebung:</strong>
            </p>
            <p>
                Der Aufsichtsrat ist auch für den Abschluss einer einvernehmlichen Aufhebung des Anstellungsvertrags mit dem Vorstandsmitglied möglich. Wenn komplexe Regelungen getroffen werden sollen, ist es ratsm, neben diesem Beschluss noch gesondert über den Text eines ausführlichen Aufhebungsvertrags zu beschießen. Hierfür bietet Resolvio eine gesonderte Vorlage an.
            </p>
        </li>
        <li>
            <p>
                <strong>Beendigung zum Vertragsablauf:</strong>
            </p>
            <p>
                Sofern weder die Voraussetzungen für eine außerordentlilche oder ordentliche Kündigung des Anstellungsvertrags vorliegen, noch eine einvernehmliche vorzeitige Aufhebung des Dienstverhältnisses möglich ist, bleibt noch die Beendigung des Anstellungsvertrags zum regulären Vertragsablauf.
            </p>
        </li>
    <ul>`,
    variableType: variableTypeVariants,
    options: [
      {
        label: "Außerordentliche Kündigung aus wichtigen Grund",
        html: `wird das mit dem vorgenannten Vorstandsmitglied bestehende Anstellungsverhältnis außerordentlich aus wichtigem Grund gekündigt, hilfsweise ordentlich zum nächstzulässigen Termin.`,
      },
      {
        label: "Ordentliche Kündigung",
        html: "wird das mit dem vorgenannten Vorstandsmitglied bestehende Anstellungsverhältnis ordentlich und fristgemäß gekündigt, hilfsweise zum nächstzulässigen Termin.",
      },
      {
        label: "Einvernehmliche (vorzeitige) Aufhebung",
        html: "wird das mit dem vorgenannten Vorstandsmitglied bestehende Anstellungsverhältnis einvernehmlich beendet.",
      },
      {
        label: "Beendigung zum regulären Vertragsablauf",
        html: "ist das Anstellungsverhältnis des vorgenannten Vorstandsmitglieds beendet.",
      },
    ],
  };

export const vorstandsmitgliedAbberufungTag: TemplateVariable = {
  name: "Abberufungstag",
  description: `
    <p>
        Tag, zu welchem das Vorstandsmitglied seine Befugnisse verlieren soll.
    </p>
     <p>
        Wenn eine fristlose Abberufung gewünscht ist, ist hier "heutigen Tag" einzutragen.
    </p>`,
  variableType: variableTypeString,
};

export const vorstandsmitgliedDienstverhaeltnisBeendigungTag: TemplateVariable =
  {
    name: "Tag für das Wirksamwerden der Beendigungsregelung",
    description: `
    <p>
      Zeitpunkt, zum die Beendigung wirksam werden soll bzw. wird (Kündigung, vorzeitige Aufhebung, reguläre Vertragsbeendigung).
    </p>
    <p>
      Wenn eine fristlose Kündigung gewünscht ist, ist hier "heutigen Tag" einzutragen.
    </p>`,
    variableType: variableTypeString,
  };

export const vorstandsmitgliedBestellungTag: TemplateVariable = {
  name: "Bestellungstag",
  description: `<p>
        Tag, ab dem das neue Vorstandsmitglied Geschäftsführungs- und Vertretungsbefugnis haben soll.
    </p>
    <p>
        Hier ist lediglich zu beachten, dass der Termin nicht mehr als ein Jahr nach dem Bestellungsbeschluss des Aufsichtsrates liegen darf.
    </p>`,
  variableType: variableTypeString,
};

export const vorstandsmitgliedName: TemplateVariable = {
  name: "Name des Vorstandsmitglieds",
  description:
    "Name des Vorstandsmitglieds, das der Beschluss betreffen soll. Beispiel: Marlene Musterfrau",
  variableType: variableTypeString,
};

export const vorstandsmitgliedAmtszeit: TemplateVariable = {
  name: "Amtszeit in Jahren",
  step: 1,
  sliderValues: [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
  ],
  min: 1,
  max: 5,
  defaultValue: 5,
  description: `<p>
        Die Amtszeit des Vorstands kann höchstens 5 Jahre betragen (§ 84 Abs. 1 Satz 1 AktG). Jenseits hiervon kann sie von dem Aufsichtsrat frei bestimmt werden. Die Satzung kann dem Aufsichtsrat hierzu keine Vorgaben machen.
    </p>
    <p>
        Eine wiederholte Bestellung nach Ablauf der Amtszeit ist möglich.
    </p>`,
  variableType: variableTypeNumberSlider,
};

export const vorstandsmitgliedGeburtsdatum: TemplateVariable = {
  name: "Geburtsdatum des Vorstandsmitglieds",
  description:
    "Geburtsdatum des Vorstandsmitglieds, das der Beschluss betreffen soll. Beispiel: 30.06.1988",
  variableType: variableTypeString,
};

export const vorstandsmitgliedWohnort: TemplateVariable = {
  name: "Wohnort des Vorstandsmitglieds",
  description:
    "Für die Registereintragung wird der Wohnort benötigt (§ 43 Nr. 4 HRV). Eine Angabe von Straße und Hausnummer ist nicht notwendig. Beispiel: Frankfurt am Main",
  variableType: variableTypeString,
};

export const vorstandsVerguetung: TemplateVariable = {
  name: "Vergütung des Vorstandsmitglieds",
  description: "",
  variableType: variableTypeNumberMoney,
};

// KI-Prüfung: Ist der Aufsichtsrat in der Satzung ermächtigt, eine abweichende Vertretungsbefugnis festzulegen.
export const variantsVorstandsmitgliedVertretungsbefugnis: TemplateVariable = {
  name: "Vertretungsbefugnis",
  description:
    "<p>Der Aufsichtsrat kann bestimmen, dass einzelne Vorstandsmitglieder allein oder gemeinsam mit einem Prokuristen die Gesellschaft vertreten können, wenn der Aufsichtsart hierzu in der Satzung ermächtigt ist.</p>",
  variableType: variableTypeVariants,

  options: [
    {
      label: "Keine besondere Regelung",
      html: "<li><p>Die Vertretungsbefugnis richtet sich nach dem Gesetz und der Satzung.</p></li>",
    },
    {
      label: "Einzelvertretungsbefugnis",
      html: `<li><p>Das neue Vorstandsmitglied vertritt die Gesellschaft stets einzeln, auch wenn weitere Vorstandsmitglieder bestellt werden sollten.</p></li>`,
    },
    {
      label: "Unechte Gesamtvertretung",
      html: "<li><p>Das neue Vorstandsmitglied vertritt die Gesellschaft gemeinschaftlich mit einem anderen Vorstandsmitglied oder einem Prokuristen.</p></li>",
    },
  ],
};

// KI-Prüfung: Ist der Aufsichtsrat in der Satzung ermächtigt, ein Befreiung vom Verbot der Mehrfachvertretung festzulegen.
export const variantsVorstandsmitgliedBefreiungInsichgeschaeft: TemplateVariable =
  {
    name: "Befreiung vom Verbot der Mehrfachvertretung",
    description:
      "<p>Der Aufsichtsrat kann bestimmen, dass einzelne Vorstandsmitglieder vom Verbot der Mehrfachvertretung befreit werden solllen, wenn der Aufsichtsart hierzu in der Satzung ermächtigt ist. Eine Befreiung vom Verbot der Selbstkontraktion ist bei Aktiengesellschaften nicht zulässig (und daher auch hier nicht auswählbar).</p>",
    variableType: variableTypeVariants,
    options: [
      {
        label: "Keine besondere Regelung",
        html: `<span></span>`,
      },
      {
        label: "Gestattung der Mehrfachvertretung",
        html: "<li><p>Das neue Vorstandsmitglied ist von den Beschränkungen des § 181 BGB im Hinblick auf das Verbot der Mehrfachvertretung befreit.</p></li>",
      },
    ],
  };

// Weisung

export const vertragBezeichnung: TemplateVariable = {
  name: "Vertragart",
  description:
    "Bezeichnung für die Vertragsart. Beispiele: Leasingvertrag, Bauvertrag, Kreditvertrag, Kaufvertrag etc.",
  variableType: variableTypeString,
};

export const vertragPartner: TemplateVariable = {
  name: "Vertragspartner",
  description:
    "Person/Firma, mit der der Vertrag abgeschlossen werden soll. Beispiele: Sparkasse Neustadt",
  variableType: variableTypeString,
};

export const weisungInhalt: TemplateVariable = {
  name: "Inhalt der Weisung",
  description:
    "Beschreibung davon, was der Geschäftsführende machen soll. Beispiele: Kündigung eines Vertrag, Arbeitgeber Abmahnen, Informationen einholen etc.",
  variableType: variableTypeTextarea,
};

// Zustimmung

export const zustimmungInhalt: TemplateVariable = {
  name: "zugestimmte Maßnahme",
  description:
    "Vertragsschluss oder sonstige Rechtshandlung der Geschäftsführung, für die die Zustimmung erteilt werden soll. Beispiel: Anmietung einer Fertigungshalle für eine bestimmte Dauer und einen bestimmten maximalen Mietzins.",
  variableType: variableTypeTextarea,
};

export const nameGesellschafterNeu: TemplateVariable = {
  name: "Name des Erwerbers",
  description: "Name der Person, die die Geschäftsanteile erwirbt.",
  variableType: variableTypeString,
};

export const nameGesellschafterAlt: TemplateVariable = {
  name: "Name des Veräußerers",
  description: "Name des Gesellschaft, der seine Geschäftsanteile veräußert.",
  variableType: variableTypeString,
};

export const datumBUndGVertrag: TemplateVariable = {
  name: "Datum des Vertrags",
  description:
    "Datum des Beherrschungs- und Gewinnabführungsvertrags, dem zugestimmt werden soll.",
  variableType: variableTypeString,
};

export const nameHerrschendeGesellschaft: TemplateVariable = {
  name: "Name der herrschenden Gesellschaft",
  description: "Beispiel: XYZ GmbH",
  variableType: variableTypeString,
};

export const sitzHerrschendeGesellschaft: TemplateVariable = {
  name: "Sitz der herrschenden Gesellschaft",
  description: "Beispiel: Leipzig",
  variableType: variableTypeString,
};

export const nameBeherrschteGesellschaft: TemplateVariable = {
  name: "Name der beherrschten Gesellschaft",
  description: "Beispiel: ABC GmbH",
  variableType: variableTypeString,
};

export const sitzBeherrschteGesellschaft: TemplateVariable = {
  name: "Sitz der beherrschten Gesellschaft",
  description: "Beispiel: Regensburg",
  variableType: variableTypeString,
};

export const mietObjekt: TemplateVariable = {
  name: "Mietobjekt",
  description:
    "Beispiel: Erdgeschoss, Valeska-Gert-Straße 5, 10243 Berlin, 100 qm,",
  variableType: variableTypeString,
};

export const mietZins: TemplateVariable = {
  name: "Mietzins",
  description: "Beispiel: höchstens 1.000 EUR/Monat",
  variableType: variableTypeString,
};

export const vertragSonstiges: TemplateVariable = {
  name: "Sonstige Bedingungen",
  description:
    'Sonstige Vertragsbedingungen (optional). Beispiel: Maklerkosten in Höhe von zwei Nettokaltmieten. Wenn es keine sonstigen Bedingungen gibt, tragen Sie in das Feld "keine" ein.',
  variableType: variableTypeString,
};

export const mietZeit: TemplateVariable = {
  name: "Mietzeit",
  description: "Beispiel: unbefristet",
  variableType: variableTypeString,
};

export const mietBeginn: TemplateVariable = {
  name: "Mietbeginn",
  description: "Beispiel: 01.01.2022",
  variableType: variableTypeString,
};

export const kaufObjekt: TemplateVariable = {
  name: "Kaufgegenstand",
  description: "Beispiel: 2 Laptops der Marke X, Modell Y",
  variableType: variableTypeString,
};

export const kaufPreis: TemplateVariable = {
  name: "Kaufpreis",
  description: "Beispiel: 1.000 EUR",
  variableType: variableTypeString,
};

export const auftragKanzlei: TemplateVariable = {
  name: "Name der Kanzlei",
  description:
    "Bezeichnung der Kanzlei bzw. des Rechtsanwalts, der beauftragt werden soll. Beispiel: Hinz Rechtsanwälte, Köln",
  variableType: variableTypeString,
};

export const auftragGegenstand: TemplateVariable = {
  name: "Gegenstand der Beauftragung",
  description:
    "Der Gegenstand der Beauftragung beschreibt, was von der beauftragten Kanzlei gemacht werden soll und warum. Beispiele: Anmeldung der Marke X beim europäischem Markenamt, außergerichtliche Vertretung bei der Abwehr der Abmahnung vom X durch Y, gerichtliche Vertretung bei der Geltendmachung der Ansprüche gegen X wegen Y.",
  variableType: variableTypeString,
};

export const auftragVergütung: TemplateVariable = {
  name: "Vergütungsbedingungen",
  description:
    "Soweit gewünscht, kann die Zustimmung an bestimmte Vergütungsbedingungen geknüpft werden, z.B. an ein Maximalbudget, einen maximalen Stundensatz, an eine Stundungsregelung oder die Vereinbarung der gesetzlichen Anwaltsvergütung/RVG. Beispiel: Pauschalvergütung in Höhe von 1.000 EUR",
  variableType: variableTypeString,
};

// Zweigniederlassung

export const zweigniederlassungOrt: TemplateVariable = {
  name: "Ort der Niederlassung",
  description: "Beispiel: Köln.",
  variableType: variableTypeString,
};

export const firma: TemplateVariable = {
  name: "Firma der Gesellschaft",
  description: "Beispiel: XYZ GmbH.",
  variableType: variableTypeString,
};

// Beirat

export const nameBeirat: TemplateVariable = {
  name: "Name des Beiratsmitglieds",
  description: "Name des neuen Beiratsmitglieds. Beispiel: Marlene Musterfrau.",
  variableType: variableTypeString,
};

export const datumGeschäftsordnungBeirat: TemplateVariable = {
  name: "Datum der Beiratsgeschäftsordnung",
  description:
    "Datum des Beschlusses, mit dem die Geschäftsordnung für den Beirat verabschiedet worden ist. Beispiel: 30.06.2022.",
  variableType: variableTypeString,
};

export const datumBeschlussBeiratsbestellung: TemplateVariable = {
  name: "Datum der Beiratsbestellung",
  description:
    "Datum des Beschlusses, mit dem der neue Beirat bestellt worden ist. Beispiel: 30.06.2022.",
  variableType: variableTypeString,
};

export const beiratssitzungInterval: TemplateVariable = {
  name: "Sitzungsintervall",
  description:
    'Intervall, in welchen Beiratssitzungen stattfinden sollen. Beispiel: "in der Regel monatlich, mindestens jedoch zweimal pro Kalendarquartal."',
  variableType: variableTypeString,
};

export const vergütungProBeschlussfassung: TemplateVariable = {
  name: "Vergütung pro Beschluss",
  description:
    "Höhe der Vergütung, die jedes Beiratsmitglied pro Beschluss erhalten soll. Beispiel: 300,00 EUR. Beispiel: 30.06.2022.",
  variableType: variableTypeString,
};

export const höheVertragsstrafe: TemplateVariable = {
  name: "Höhe der Vertragsstrafe",
  description:
    "Vertragsstrafe, die das Beiratsmitglied für jeden Fall eines schuldhaften Verstoßes gegen das Wettbewerbsverbot bzw. die Kundenschutzklausel zu zahlen hat. Beispiel: 3.000,00 EUR.",
  variableType: variableTypeString,
};

export const geburtsdatumBeirat: TemplateVariable = {
  name: "Geburtsdatum des Beiratsmitglieds",
  description:
    "Geburtsdatum des Beiratsmitglieds, das in den Beirat bestellt werden soll.",
  variableType: variableTypeString,
};

export const adresseBeirat: TemplateVariable = {
  name: "Adresse des Beiratsmitglieds",
  description:
    "Straße, PLZ und Ort des Beiratsmitglieds, das in den Beirat bestellt werden soll.",
  variableType: variableTypeString,
};

// Additional variables for voting mode plenum

export const datumEinladung: TemplateVariable = {
  name: "Datum der Einladung",
  description: "Auf dieses Datum wird das Einladungsschreiben datiert.",
  variableType: variableTypeString,
};

export const vollversammlungEinladenderRolle: TemplateVariable = {
  name: "Rolle des Einladenden",
  description:
    "Die Rolle der Person, die die Versammlung einberuft. Beispiel: Geschäftsführer.",
  variableType: variableTypeString,
};

export const vollversammlungEinladenderName: TemplateVariable = {
  name: "Name des Einladenden",
  description:
    "Name der Person, die die Versammlung einberuft. Beispiel: Maria Musterfrau.",
  variableType: variableTypeString,
};

export const vollversammlungDatum: TemplateVariable = {
  name: "Datum der Vollversammlung",
  description: "An diesem Tag wird die Versammlung stattfinden.",
  variableType: variableTypeString,
};

export const vollversammlungUhrzeit: TemplateVariable = {
  name: "Uhrzeit der Versammlung",
  description: "Zu dieser Uhrzeit wird die Versammlung beginnen.",
  variableType: variableTypeString,
};

export const vollversammlungOrt: TemplateVariable = {
  name: "Ort der Vollversammlung",
  description:
    "An diesem Ort (vollständige Anschrift) findet die Vollversammlung statt. Beispiel: Daimlerstraße 1, 60316 Frankfurt am Main, Großer Besprechungsraum.",
  variableType: variableTypeString,
};

// General additional input

export const datumBeschluss: TemplateVariable = {
  name: "Datum des Beschlusses",
  description: "Auf diesen Tag wird der Beschluss datiert.",
  variableType: variableTypeString,
};

export const entlastungsjahr: TemplateVariable = {
  name: "Entlastungsjahr",
  variableType: variableTypeString,
  description: "Jahr, für das die Entlastung gelten soll",
};

export const bereinigungsjahr: TemplateVariable = {
  name: "Bereinigungsjahr",
  variableType: variableTypeString,
  description: "Jahr, für das die Generalbereinigung gelten soll",
};

export const startdatumGeschäftsführungsordnung: TemplateVariable = {
  name: "Startdatum der Geschäftsführungsordnung",
  variableType: variableTypeString,
  description:
    "Datum, ab wann die Geschäftsführungsordnung wirksam werden soll",
};

export const nameEinzelunternehmer: TemplateVariable = {
  name: "Einzelunternehmer",
  variableType: variableTypeString,
  description:
    "Vor- und Nachname des Einzelunternehmers, der von dem Wettbewerbsverbot befreit werden soll",
};

export const nameEinzelunternehmung: TemplateVariable = {
  name: "Einzelunternehmen",
  variableType: variableTypeString,
  description: "Bezeichnung des Einzelunternehmens",
};

export const ausscheidungsdatum: TemplateVariable = {
  name: "Ausscheidensdatum",
  variableType: variableTypeString,
  description: "Datum, ab wann der Bestellungswiderruf wirksam werden soll",
};

export const zustimmungsparagraph: TemplateVariable = {
  name: "Zustimmungsparagraph",
  variableType: variableTypeString,
  description:
    "Paragraph des Gesellschaftsvertags, der Zustimmungerfordernis vorsieht",
};
