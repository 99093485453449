import { bilanzStichtag } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageJahresabschlussFeststellung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.regelmäßigerBeschluss,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.bundesanzeiger],
  tags: [
    "Jahresabschluss",
    "Abschluss",
    "Bilanz",
    "Rechnungslegung",
    "Aufstellung",
    "Schlussrechnung",
    "Abrechnung",

    "feststellen",
    "Feststellung",
    "genehmigen",
    "absegnen",
    "zustimmen",
    "grünes Licht",
    "Weg frei machen",
    "Okay geben",
    "O.K. geben",
    "OK geben",
    "Erlaubnis",
    "Unterstützung",
    "erlauben",
    "unterstützen",
    "einwilligen",
    "Einwilligung",
    "Genehmigung",
    "Zustimmung",
    "Approval",
    "blessing",
    "confirmation",
    "consent",
    "endorsement",
    "green light",
    "persmission",
    "ratification",
    "support",
  ],
  description: `
  <p>
    Die Jahresabschluss-Feststellung ist die Voraussetzung dafür, dass der Jahresgewinn <strong>unter den Gesellschaftern verteilt</strong> werden kann. Die Verteilung des Jahresgewinns kann in einem <a href="/beschluss-vorlagen/verwendung-des-gewinns-(gewinnausschuttung)">seperaten Beschluss</a> erfolgen.
  </p>

   <p>
    Die Feststellung des Jahresabschlusses erfolgt, nachdem die Geschäftsführung den <strong>Jahresabschluss förmlich erstellt ("aufgestellt")</strong> hat und ihn den Gesellschaftern vorgelegt hat. Zu Dokumentationszwecken sollte die Geschäftsführung hierfür einen <a href="/beschluss-vorlagen/aufstellung-des-jahresabschlusses">eigenen Beschluss fassen</a>.
  </p>
  `,
  bezug: [
    "EntlastungRegelmäßigAufsichtsrat",
    "EntlastungKomplementärRegelmäßig",
    "EntlastungRegelmäßigKomplementär",
    "EntlastungRegelmäßigGeschäftsführenderPartner",
    "EntlastungRegelmäßigBeirat",
    "EntlastungRegelmäßigGeschäftsführenderGesellschafter",
    "EntlastungRegelmäßigGeschäftsführer",
    "EntlastungRegelmäßigKomplementärGmbH",
    "AbschlussprüferBestellung",
    "JahresabschlussAufstellung",
    "JahresplanungFeststellung",
    "VerwendungDesGewinns",
  ],
  kurzBezeichnung: "Feststellung des Jahresabschlusses",
  metaDescription:
    "Gesellschafterbeschluss, um Jahresabschluss festzustellen. Muster/Vorlagen von Rechtsanwälten und Steuerberatern geprüft. Jetzt kostenlos herunterladen.",
  antrag: `
  <p>
    Der als <strong>Anlage</strong> beigefügte
  </p>

  <p>
    <ul data-list-style="none">
      <li><p>
        <strong>Jahresabschluss zum ${insertTemplateVariable(
          bilanzStichtag
        )}</strong>,
       </p></li>
    </ul>
  </p>

  <p>
    der von der Geschäftsführung aufgestellt worden ist,  wird hiermit festgestellt.
  </p>

  <p>
    Die Geschäftsführung wird angewiesen, den Jahresabschluss zur Veröffentlichung beim elektronischen Unternehmensregister (unternehmensregister.de) einzureichen, sofern die Gesellschaft zur Veröffentlichung verpflichtet ist.
  </p>`,
  begründung: undefined,
  templateVariables: [bilanzStichtag],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
