import { createStyles, makeStyles } from "@material-ui/core";
import { defaultTransition } from "../../../helpers/styling/defaultTransition";

export const useRVOHelpButtonStyles = makeStyles((theme) =>
  createStyles({
    button: {
      zIndex: 10,
      ...defaultTransition(theme, "transform"),
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
  })
);
