import { Link as MuiLink, Typography } from "@material-ui/core";
import { clsx } from "clsx";
import { Link } from "gatsby";
import { useInitializedMediaQuery } from "../../../../hooks/useInitializedMediaQuery";
import theme, { mobileMenuBreakpointDown } from "../../../../layouts/theme";
import { RVONavigationSection } from "../RVOPageSectionLinksInterfaces";
import { NVONPageSectionLinkProps } from "./NVONPageSectioinLinkInterfaces";
import { useNVONHeaderLinkStyles } from "./NVONPageSectionLinkStyles";

export const NVONPageSectionLink = (
  props: NVONPageSectionLinkProps
): JSX.Element => {
  const classes = useNVONHeaderLinkStyles();

  const { isMatching: isMobile } = useInitializedMediaQuery(
    theme.breakpoints.down(mobileMenuBreakpointDown)
  );

  return (
    <>
      {/* Header */}

      {props.navigationSection === RVONavigationSection.Header && (
        <li
          className={clsx(
            classes.headerNavLi,
            props.className,
            "headerNavItem"
          )}
        >
          <MuiLink
            to={props.href}
            component={Link}
            underline="hover"
            onClick={props.onClick}
            className={classes.headerLink}
            activeStyle={{ textDecoration: "underline" }}
          >
            <Typography
              variant={isMobile ? "h3" : "body1"}
              component="span"
              color="textPrimary"
            >
              {props.children}
            </Typography>
          </MuiLink>
        </li>
      )}

      {/* Footer */}

      {props.navigationSection === RVONavigationSection.Footer && (
        <li className={clsx(props.className, "headerNavItem")}>
          <MuiLink to={props.href} component={Link} onClick={props.onClick}>
            <Typography variant="body2" component="span" color="textSecondary">
              {props.children}
            </Typography>
          </MuiLink>
        </li>
      )}
    </>
  );
};
