import {
  gewinn,
  gewinnverteilung,
  nameGeschäftsführenderPartner,
  rechnungsabschlussStichtag,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageBeschluesseZumJahresabschlussPartG: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.PartGDe,
    CompanyTypeName.PartGmbBDe,
  ],
  vorlageKategorie: VorlageKategorie.regelmäßigerBeschluss,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.selfexecuting],
  tags: [
    "Rechnungsabschluss",
    "Jahresabschluss",
    "Abschluss",
    "Bilanz",
    "Rechnungslegung",
    "Aufstellung",
    "Schlussrechnung",
    "Abrechnung",

    "feststellen",
    "Feststellung",
    "genehmigen",
    "absegnen",
    "zustimmen",
    "grünes Licht",
    "Weg frei machen",
    "Okay geben",
    "O.K. geben",
    "OK geben",
    "Erlaubnis",
    "Unterstützung",
    "erlauben",
    "unterstützen",
    "einwilligen",
    "Einwilligung",
    "Genehmigung",
    "Zustimmung",
    "Approval",
    "blessing",
    "confirmation",
    "consent",
    "endorsement",
    "green light",
    "persmission",
    "ratification",
    "support",

    "Gewinn",
    "Ergebnis",
    "Erlös",
    "Ertrag",
    "Marge",
    "Rendite",
    "schwarze Zahlen",
    "Überschuss",
    "profit",
    "winnings",
    "gain",
    "win",

    "Gewinnvortrag",
    "Vortrag",
    "Bilanzgewinn",
    "Kapitalrücklage",
    "freie Rücklage",

    "Ausschüttung",
    "Ergebnisverteilung",
    "Gewinnausschüttung",
    "Gewinnverwendung",
    "Verteilung",
    "Aufteilung",
    "Zuteilung",
    "Auszahlung",
    "Dividende",
    "distribution",
    "payout",
    "dividend",
    "disburse",
    "disbursement",
    "distribute",

    "jährlich",
    "Abberufen",
    "Abberufung",
    "Schadenersatz",
    "Risiko",
    "ordentliche Gesellschafterversammlung",
    "Haftung",
    "Verzicht",
    "Erlass",
    "Vertrauen",
    "Entscheider",
    "Entscheidungsträger",
    "Direktor",
    "Director",
    "Vorstand",
    "Vorsitzender",
    "Führung",
    "Leitung",
    "Leiter",
    "Geschäftsführung",
    "Geschäftsleitung",
    "Geschäftsleiter",
    "Chef",
    "Leiter",
    "Vorgesetzter",
    "Führungskraft",
    "Geschäftsführer",
    "Manager",
    "CEO",
    "CTO",
    "CFO",
    "leader",
    "head",
    "chief",
    "executive",
    "boss",
    "governor",
    "governance",
  ],
  description: `
  <p>In dieser Vorlage sind alle Beschlüsse versammelt, die von den Gesellschaftern einer Partnerschaftsgesellschaft oder PartG mbB jedes Jahr nach Ablauf des Geschäftsjahres in der Regel zu fassen sind:
    </p>
    <ul>
    <li>Feststellung des Rechnungsabschlusses</li>
    <li>Gewinnverwendung</li>
    <li>Entlastung der Geschäftsführung</li>

    </ul>

  `,
  bezug: [],
  kurzBezeichnung:
    "Beschlüsse zum Rechnungsabschluss für die PartG/PartG mbB (Bundle)",
  metaDescription:
    "Anwaltlich geprüfte Vorlage für alle Beschlüsse, die eine PartG mbB für den Abschluss ihres Geschäftsjahres benötigt. Feststellung Rechnungsabschluss, Gewinnverteilung, Entlastung. Jetzt kostenlos herunterladen.",
  antrag: `
  <ol>
  <li>
  <p>
    Der von den geschäftsführenden Partnern aufgestellte
  </p>

  <p>
    <ul data-list-style="none">
      <li><p>
        <strong>Rechnungsabschluss zum ${insertTemplateVariable(
          rechnungsabschlussStichtag
        )}</strong>,
       </p></li>
    </ul>
  </p>

  <p>
    der als <strong>Anlage</strong> beigefügt ist, wird hiermit festgestellt.
  </p>

  <p>
    Der Gewinn der Gesellschaft zum ${insertTemplateVariable(
      rechnungsabschlussStichtag
    )} beträgt hiernach
              ${insertTemplateVariable(gewinn)}.
  </p>
  </li>
  <li>

  <p>
    Der Gewinn wird wie folgt auf die Partner verteilt:
  </p>
  <p>
    ${insertTemplateVariable(gewinnverteilung)}.
  </p>

  </li>
   <li>
    <p>
    Dem/n geschäftsführenden Partner/n
    <strong>${insertTemplateVariable(
      nameGeschäftsführenderPartner
    )}</strong> wird
  </p>
  <ul data-list-style="none">
    <li><p>
      Entlastung
    </p></li>
  </ul>

  <p>
    erteilt.
  </p>
  </li>
  </ol>`,
  begründung: undefined,
  templateVariables: [
    rechnungsabschlussStichtag,
    gewinn,
    gewinnverteilung,
    nameGeschäftsführenderPartner,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
