import {
  adresseBevollmächtigter,
  geburtsdatumBevollmächtigter,
  nameBevollmächtigter,
  tagVollmacht,
  vollmachtArtGeschäfte,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageHandlungsvollmachtGeneralErteilung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.prokura,
  vorlage_rolleTyp: [
    RolleInGesellschaft.Geschftsleiter,
    RolleInGesellschaft.Mitglieder,
  ],
  vollzug: [Vollzug.zugang],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Erteilung einer Generalhandlungsvollmacht",
  tags: [
    "Handlungsvollmacht",
    "handlungsbevollmächtigt",
    "Vollmacht",
    "handelsrechtliche Vollmacht",
    "bevollmächtigten",
    "Bevollmächtigter",
    "power of attorney",
    "proxy",
    "representation",

    "erteilen",
    "Erteilung",
    "confer",
    "bestellen",
    "Bestellung",
  ],
  metaDescription:
    "Allgemeine Handlungsvollmacht (Generalhandlungsvollmacht) durch Geschäftführungsbeschluss erteilen. Juristisch geprüfte Muster/Vorlagen kostenlos nutzen.",
  description: `
  <p>
    Die Generalhandlungsvollmacht berechtigt den Bevollmächtigten, sämtlichen Rechtsgeschäften  vorzunehmen, die gewöhnlich für den gesamten Geschäftsbetrieb des Unternehmens in der jeweiligen Branche sind.
  </p>
  <p>
    Im Gegensatz zur Prokura ist eine Eintragung im Handelsregister nicht erforderlich.
  </p>

  <p>
    Die Erteilung der Handelsvollmacht gegenüber dem neuen Handlungsbevollmächtigten erfolgt durch Beschluss der Geschäftsführung (vgl. <a href="/beschluss-basics/zustaendigkeit-des-geschaeftsfuehrers-fuer-die-prokuraerteilung-gesellschafterbeschluss-fuer-das-innenverhaeltnis-bgh-beschluss-vom-14-02-01974-aktenzeichen-ii-zb-6-73">BGH</a> zur Prokura).
  </p>

  <p>
    Bei einer GmbH oder UG hat die Geschäftsführung zudem grundsätzlich einen zustimmenden Gesellschafterbeschluss einzuholen (<a href="/beschluss-basics/§-46-gmbhg/">§ 46 Nr. 7 GmbHG</a>), außer etwas anderes ist im Gesellschaftsvertrag bestimmt.
  </p>

  `,
  antrag: `
  <p>
    ${insertTemplateVariable(nameBevollmächtigter)},<br/>
    geboren am ${insertTemplateVariable(geburtsdatumBevollmächtigter)},<br/>
    wohnhaft in ${insertTemplateVariable(adresseBevollmächtigter)},
  </p>

  <p>
    wird mit Wirkung ab ${insertTemplateVariable(tagVollmacht)}</p>

  <p><strong>Handlungsvollmacht</strong></p>

  <p>
  erteilt.
  </p>

  <p>
    Die Handlungsvollmacht bezieht sich auf sämtliche Bereiche, die der Betrieb des Handelsgewerbes der Gesellschaft gewöhnlich mit sich bringt (Generalhandlungsvollmacht).
  </p>

  <p>
  Ausgeschlossen von der erteilten Handlungsvollmacht sind die Geschäfte gemäß § 54 Abs. 2 HGB (Veräußerung oder Belastung von Grundstücken, Aufnahme von Darlehen, Prozeßführung, Eingehung von Wechselverbindlichkeiten).
  </p>

    <p>
    ${insertTemplateVariable(
      nameBevollmächtigter
    )} wird angewiesen, künftig mit dem Zusatz „in Vollmacht” oder „in Vertretung“ bzw. mit der entsprechenden Abkürzung „i.V.” für die Gesellschaft zu zeichnen.
  </p>
  `,
  begründung: undefined,
  templateVariables: [
    nameBevollmächtigter,
    geburtsdatumBevollmächtigter,
    adresseBevollmächtigter,
    tagVollmacht,
    vollmachtArtGeschäfte,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
