import { Typography } from "@material-ui/core";
import de from "hyphenated-de";
import Hyphenated from "react-hyphen";
import { HyphenatedTypographyProps } from "./HyphenatedTypographyInterfaces";

const HyphenatedTypography = (
  props: HyphenatedTypographyProps
): JSX.Element => {
  return (
    // There are no official types for the `hyphenated-de` and `react-hyphen` packages yet.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <Hyphenated language={de}>
      <Typography {...props} />
    </Hyphenated>
  );
};

export default HyphenatedTypography;
