import { nameGeschäftsführenderGesellschafter } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageEntlastungRegelmassigGeschaftsfuhrenderGesellschafter: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GbRDe, CompanyTypeName.OHgDe],
    vorlageKategorie: VorlageKategorie.regelmäßigerBeschluss,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.zugang],
    bezug: [
      "EntlastungRegelmäßigAufsichtsrat",
      "EntlastungKomplementärRegelmäßig",
      "EntlastungRegelmäßigKomplementär",
      "EntlastungRegelmäßigGeschäftsführenderPartner",
      "EntlastungRegelmäßigBeirat",
      "EntlastungRegelmäßigGeschäftsführer",
      "EntlastungRegelmäßigKomplementärGmbH",
      "AbschlussprüferBestellung",
      "JahresabschlussAufstellung",
      "JahresabschlussFeststellung",
      "JahresplanungFeststellung",
      "VerwendungDesGewinns",
    ],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung: "Entlastung des geschäftsführenden Gesellschafters",
    description: undefined,
    antrag: `${insertTemplateVariable(
      nameGeschäftsführenderGesellschafter
    )} wird als geschäftsführender Gesellschafter Entlastung erteilt.`,
    begründung: undefined,
    templateVariables: [nameGeschäftsführenderGesellschafter],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
