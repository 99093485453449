import { createStyles, makeStyles } from "@material-ui/core";

export const useSignaturePlaceholderStyles = makeStyles((theme) =>
  createStyles({
    wrapper: {
      marginTop: theme.spacing(4),
    },
    typo: {
      display: "inline-block",
      borderTop: `1px solid ${theme.palette.primary.dark}`,
      paddingTop: theme.spacing(1),
    },
  })
);
