import { TFunction } from "react-i18next";

// This should be put in a more general place, maybe a `src/interfaces/variousInterfaces.ts` file
type TranslatedString = (t: TFunction) => string;
interface PageSection {
  title: TranslatedString;
  sectionId: string;
}
export const partnersSection: PageSection = {
  sectionId: "partners",
  title: (t) => t("sections.partners"),
};

export const resolvioExplainVideoSection: PageSection = {
  sectionId: "resolvio-explain-video",
  title: (t) => t("sections.resolvioIn90Sec"),
};

export const featuresSection: PageSection = {
  sectionId: "features",
  title: (t) => t("sections.features"),
};

export const benefitsSection: PageSection = {
  sectionId: "benefits",
  title: (t) => t("sections.benefits"),
};

export const lawyerPoweredSection: PageSection = {
  sectionId: "lawyerPowered",
  title: (t) => t`sections.lawyerPowered` as string,
};

export const comparisonSection: PageSection = {
  sectionId: "comparison",
  title: (t) => t("sections.comparison"),
};

export const reviewsSection: PageSection = {
  sectionId: "reviews",
  title: (t) => t("sections.reviews"),
};

export const demoSection: PageSection = {
  sectionId: "demo",
  title: (t) => t("sections.demo"),
};

export const faqSection: PageSection = {
  sectionId: "faq",
  title: (t) => t("sections.faq"),
};

export const allPageSections = [
  featuresSection,
  benefitsSection,
  partnersSection,
  comparisonSection,
  reviewsSection,
  demoSection,
  faqSection,
];
