import { AlertColor } from "@mui/lab";
import { TranslatedString } from "@nvon/react-toolbox";
import { ReactElement, ReactNode } from "react";

export enum RVOWissenTextArea {
  Tip = "tip",
  Attention = "attention",
  Example = "example",
  Tldr = "tldr",
}

export interface RVOSpecialTextAreaProps {
  children: ReactNode;
  variant: RVOWissenTextArea;
}

export interface RVOWissenTextAreaProps {
  color: AlertColor;
  title: TranslatedString;
  icon: ReactElement;
}
