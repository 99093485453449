import {
  geschäftsanteileNennbetragGesamt,
  geschäftsanteileNummer,
  nameGesellschafterAlt,
  nameGesellschafterNeu,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGesellschafterZustimmungAbtretung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
  vorlageKategorie: VorlageKategorie.übertragungBeteiligung,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [
    Vollzug.zugang,
    Vollzug.handelsregisterGesellschafterliste,
    Vollzug.transparenzregister,
  ],
  bezug: [],
  tags: [
    "Gesellschafter",
    "Mitglied",
    "Anteilsinhaber",
    "shareholder",
    "Partner",
    "Teilhaber",
    "Socius",
    "shareowner",
    "bondholder",

    "Verkauf",
    "Übertragung",
    "Abtretung",
    "Verfügung",
    "Assign",
    "Transfer",
    "abtreten",
    "übertragen",
    "verfügen",

    "genehmigen",
    "absegnen",
    "zustimmen",
    "grünes Licht",
    "Weg frei machen",
    "Okay geben",
    "O.K. geben",
    "OK geben",
    "Erlaubnis",
    "Unterstützung",
    "erlauben",
    "unterstützen",
    "einwilligen",
    "Einwilligung",
    "Genehmigung",
    "Zustimmung",
    "Approval",
    "blessing",
    "confirmation",
    "consent",
    "endorsement",
    "green light",
    "persmission",
    "ratification",
    "support",
  ],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung:
    "Zustimmung zur Abtretung und Veräußerung eines Geschäftsanteils",
  metaDescription:
    "Gesellschafterbeschluss, der Zustimmung zur Übertragung eines Geschäftsanteils enthält. Juristisch geprüfte Muster/Vorlagen kostenlos downloaden.",
  description: `
  <p>
    Für den Verkauf eines Geschäftsanteils ist in den meisten Fällen erforderlich, dass die Gesellschafter hierüber einen Zustimmungsbeschluss fassen. Denn in den meisten Gesellschaftsverträgen ist vorgeschrieben, dass ein Gesellschafterbeschluss für die wirksame Abtretung oder sonstige Verfügung über Geschäftsanteile erforderlich ist (sogenannte "<strong>Vinkulierungsklausel</strong>").
  </p>

  <p>
    Weitere Varianten von Zustimmungsbeschlüssen findest du hier:
  </p>

  <ul data-list-style="square">
    <li><p>
      <a href="/beschluss-vorlagen/einzahlung-in-die-kapitalrucklage">Zustimmungsbeschluss für Geschäftsleiter (Freitext)</a>,
    </p></li>
    <li><p>
      <a href="/beschluss-vorlagen/gewahrung-eines-gesellschafterdarlehens">Zustimmung für Geschäftsleiter zum Abschluss eines Vertrags</a>,
    </p></li>
  </ul>
`,
  antrag: `

  <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
    <li><p>
      Der Abtretung und Veräußerung der von ${insertTemplateVariable(
        nameGesellschafterAlt
      )} gehaltenen Geschäftsanteile mit laufender Nr. ${insertTemplateVariable(
    geschäftsanteileNummer
  )}  und einem Gesamt-Nennbetrag von ${insertTemplateVariable(
    geschäftsanteileNennbetragGesamt
  )} an ${insertTemplateVariable(nameGesellschafterNeu)} wird zugestimmt.
    </p></li>

    <li><p>
      Die Geschäftsführung wird angewiesen, eine Meldepflicht der Gesellschaft zum Transparenzregister zu prüfen und ggf. zu erfüllen.
    </p></li>
  </ol>

  `,
  begründung: undefined,
  templateVariables: [
    nameGesellschafterAlt,
    geschäftsanteileNummer,
    geschäftsanteileNennbetragGesamt,
    nameGesellschafterNeu,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
