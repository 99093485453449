import { assertTruthy } from "@nvon/baseline";
import { useSmoothWindowScroll } from "@nvon/react-toolbox";
import { useLocation } from "@reach/router";
import { noop } from "lodash-es";
import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../../../../config/Pages";
import { usePathToPage } from "../../../helpers/clientSide/usePathToPage";
import { allPageSections } from "../../03-organisms/NVONHeader/NVONPageSections/NVONPageSections";
import { NVONPageSectionLink } from "./NVONPageSectionLink/NVONPageSectionLink";
import { RVOPageSectionLinksProps } from "./RVOPageSectionLinksInterfaces";

const RVOPageSectionLinks = (props: RVOPageSectionLinksProps): JSX.Element => {
  const { t } = useTranslation();

  const pathToPage = usePathToPage();
  const location = useLocation();
  const isOnHomePage = location.pathname === "/";

  const scrollTo = useSmoothWindowScroll();
  const scrollToSection = (sectionId: string) => {
    const element = document.querySelector(`#${sectionId}`);
    assertTruthy(element, `Did not find section with id ${sectionId}`);

    scrollTo(element);
  };

  return (
    <>
      {allPageSections.map((pageSection) => (
        <NVONPageSectionLink
          key={pageSection.sectionId}
          href={pathToPage(Page.Home, `#${pageSection.sectionId}`)}
          navigationSection={props.navigationSection}
          onClick={
            isOnHomePage
              ? (event: MouseEvent<HTMLSpanElement, MouseEvent>) => {
                  scrollToSection(pageSection.sectionId);
                  // Prevent flash before scrolling
                  event.preventDefault();
                }
              : noop
          }
        >
          {pageSection.title(t)}
        </NVONPageSectionLink>
      ))}
    </>
  );
};

export default RVOPageSectionLinks;
