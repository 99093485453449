import { nameAbschlussprüfer, zuPrüfendesJahr } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageAbschlusspruferBestellung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.regelmäßigerBeschluss,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.selfexecuting],
  metaDescription:
    "Bestellung eines Abschlussprüfers mit Gesellschafterbeschluss. Von Rechtsanwälten geprüfte Muster/Vorlagen kostenlos ausfüllen und nutzen.",
  description: `<p>
  Zur Abschlussprüfung sind Gesellschaften erst verpflichtet, wenn sie bestimmte Größenmerkmale überschritten haben. Daneben ist auch bei kleineren Gesellschaften stets eine freiwillige Abschlussprüfung möglich.
  </p>

  <p>
  Bei <strong>Kapitalgesellschaften</strong> (z.B. GmbH, UG) und Personengesellschaften mit Kapitalgesellschaften als persönlich haftenden Gesellschaftern (z.B. GmbH & Co. KG) müssen <strong>zwei der folgenden drei Merkmale an zwei aufeinanderfolgenden Abschlussstichtagen</strong> überschritten worden sein, damit sie prüfungspflichtig sind [316 Abs. 1 HGB]:
  </p>

    <ul data-list-style="square">
    <li><p>Bilanzsumme: 6 Mio. €</p></li>
    <li><p>Umsatzerlöse: 12 Mio. €</p></li>
    <li><p>Arbeitnehmer im Jahresdurchschnitt: 50</p></li>
    </ul>

    <p>
  Bei <strong>Personengesellschaften</strong> (oHG, KG)  mit mindestens einer natürlichen Person als persönlich haftenden Gesellschafter  müssen <strong>zwei der folgenden drei Merkmale an drei aufeinanderfolgenden Abschlussstichtagen</strong> überschritten worden sein, damit sie prüfungspflichtig sind [§§6, 1 PublG]:
  </p>

    <ul data-list-style="square">
    <li><p>Bilanzsumme: 65 Mio. €</p></li>
    <li><p>Umsatzerlöse: 130 Mio. €</p></li>
    <li><p>Arbeitnehmer im Jahresdurchschnitt: 5.000</p></li>
    </ul>
  `,
  bezug: [
    "EntlastungRegelmäßigAufsichtsrat",
    "EntlastungKomplementärRegelmäßig",
    "EntlastungRegelmäßigKomplementär",
    "EntlastungRegelmäßigGeschäftsführenderPartner",
    "EntlastungRegelmäßigBeirat",
    "EntlastungRegelmäßigGeschäftsführenderGesellschafter",
    "EntlastungRegelmäßigGeschäftsführer",
    "EntlastungRegelmäßigKomplementärGmbH",
    "JahresabschlussAufstellung",
    "JahresabschlussFeststellung",
    "JahresplanungFeststellung",
    "VerwendungDesGewinns",
  ],
  kurzBezeichnung: "Bestellung des Abschlussprüfers",
  tags: [
    "abschlussprüfer",
    "abschlussprüfung",
    "Jahresabschluss",
    "Wirtschaftsprüfer",
    "Wirtschaftsprüfungsgesellschaften",
    "Bilanz",
    "Bilanzprüfung",
    "Bestätigungsvermerk",
    "audit investigation",
    "auditing of accounts",
    "audit",
    "public accountant",
    "annual auditor",
    "statutory auditor",
    "auditor of annual accounts",
    "accounts",

    "bestellen",
    "Bestellung",
  ],
  antrag: `
  <p>
  ${insertTemplateVariable(
    nameAbschlussprüfer
  )} wird zum Abschlussprüfer für das Geschäftsjahr ${insertTemplateVariable(
    zuPrüfendesJahr
  )} bestellt.
  </p>`,
  begründung: undefined,
  templateVariables: [nameAbschlussprüfer, zuPrüfendesJahr],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
