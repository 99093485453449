import {
  adresseGeschäftsführerNeu,
  geburtsdatumGeschäftsführerNeu,
  nameGeschäftsführerAlt,
  nameGeschäftsführerNeu,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageBundleGeschaftsfuhrerwechsel: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
  vorlageKategorie: VorlageKategorie.geschäftsführerNeu,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.zugang, Vollzug.handelsregisterAnmeldung],
  bezug: ["GeschäftsführerAnstellungsvertrag"],
  tags: [
    "Bestellung",
    "Berufung",
    "Vertretungsmacht",
    "Vertretungsbefugnis",
    "Geschäftsführungsbefugnis",
    "Befreiung",
    "181",
    "181 BGB",
    "Einzelvertretung",
    "Alleinvertretung",
    "Insichgeschäft",
    "Doppelvertretung",
    "Mehrfachvertretung",
    "Selbstkontrahieren",
    "Entscheider",
    "Entscheidungsträger",
    "Direktor",
    "Director",
    "Vorstand",
    "Vorsitzender",
    "Führung",
    "Leitung",
    "Leiter",
    "Geschäftsführung",
    "Geschäftsleitung",
    "Geschäftsleiter",
    "Chef",
    "Leiter",
    "Vorgesetzter",
    "Führungskraft",
    "Geschäftsführer",
    "Manager",
    "CEO",
    "CTO",
    "CFO",
    "leader",
    "head",
    "chief",
    "executive",
    "boss",
    "governor",
    "governance",
    "wechsel",
    "change",
    "widerruf",
    "abberufen",
    "abberufung",
  ],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Geschäftsführerwechsel mit typischen Zusätzen (Bundle)",
  metaDescription:
    "Gesellschafterbeschluss, um alten Geschäftsführer mit neuem Geschäftsführer austauschen. Juristisch geprüfte Muster/Vorlagen kostenlos nutzen.",
  description: `
    <p>
    Dieses Beschlussbundle eignet sich für die Situation, dass ein amtierender Geschäftsführer "ausgewechselt" werden soll. Das Bundle erweitert damit das <a href="/beschluss-vorlagen/bestellung-mit-standard-zusatzen-(bundle)">Beschlussbundle Geschäftsführerbestellung</a> um die Abberufung des alten Geschäftsführers.
    </p>

        <p>
    Der neue Geschäftsführer wird bewusst als Erstes bestellt, bevor der alte Geschäftsführer abberufen wird. Damit ist sichergestellt, dass der <strong>neue Geschäftsführer die Handelsregisteranmeldung selbst erledigen</strong> kann, ohne dass der alte Geschäftsführer hierfür noch benötigt wird.
    </p>

    <p>
      Je nach Hintergrund der Auswechslung sollte nicht vergessen werden, Beschlüsse zur
      <ul data-list-style="square">
        <li><p>
          Beendigung des Geschäftsführer-Anstellungsvertrags (z.B. durch <a href="/beschluss-vorlagen/einfacher-aufhebungsvertrag-im-beschluss">Aufhebung</a> oder <a href="/beschluss-vorlagen/ordentliche-kundigung-des-geschaftsfuhrers">ordentliche Kündigung</a> bzw. <a href="/beschluss-vorlagen/fristlose-kundigung-des-geschaftsfuhrers-aus-wichtigem-grund">außerordentliche Kündigung</a>) und
        </p></li>
        <li><p>
          <a href="/beschluss-vorlagen/entlastung-des-geschaftsfuhrers">Entlastung</a> oder <a href="/beschluss-vorlagen/verweigerung-der-entlastung-des-geschaftsfuhrers">Entlastungsverweigerung</a>
        </p></li>
      </ul>
      zu fassen.
    </p>

    <p>
    Für den neuen Geschäftsführer sind in diesem Beschlussbundle typische Zusatzbeschlüsse enthalten:
    <ul data-list-style="square">
      <li><p>
      Einzelvertretungsbefugnis und
      </p></li>
      <li><p>
        Befreiung von den Beschränkungen des § 181 BGB.
      </p></li>
    </ul>
  </p>

  <p>
    Diese Beschlussinhalte zusammengenommen geben dem neuen Geschäftsführer besonders viele Freiheiten. Wer dagegen bevorzugt, dass die allgemeine Vertretungsregelung des Gesellschaftsvertrages greift und keine § 181 BGB-Befreiung für den neuen  Geschäftsführer gelten soll, greift auf den <a href="/beschluss-vorlagen/bestellung-eines-neuen-geschaftsfuhrers">einfachen Bestellungsbeschluss</a> zurück.
  </p>
  `,
  antrag: `
  <p>
  Zum neuen Geschäftsführer der Gesellschaft wird mit sofortiger Wirkung bestellt:
  </p>


    <ul data-list-style="none">
      <li><p>
      <strong>${insertTemplateVariable(nameGeschäftsführerNeu)}</strong>,<br/>
      geboren am ${insertTemplateVariable(geburtsdatumGeschäftsführerNeu)},<br/>
      wohnhaft in ${insertTemplateVariable(adresseGeschäftsführerNeu)}.
      </p></li>
    </ul>

  <p>
    ${insertTemplateVariable(
      nameGeschäftsführerNeu
    )} ist berechtigt, die Gesellschaft einzeln zu vertreten.
  </p>

  <p>
  ${insertTemplateVariable(
    nameGeschäftsführerNeu
  )} wird von den Beschränkungen des § 181 BGB befreit, d.h. ${insertTemplateVariable(
    nameGeschäftsführerNeu
  )} kann im Namen der Gesellschaft
    <ul data-list-style="square">
      <li><p>
        sowohl mit sich im eigenen Namen (Selbstkontrahieren)
      </p></li>
      <li><p>
        als auch als Vertreter eines Dritten (Doppelvertretung)
      </p></li>
    </ul>
    ein Rechtsgeschäft vornehmen.
  </p>

  <p>
    ${insertTemplateVariable(
      nameGeschäftsführerAlt
    )} wird mit sofortiger Wirkung als Geschäftsführer abberufen.
  </p>`,
  begründung: undefined,
  templateVariables: [
    nameGeschäftsführerNeu,
    geburtsdatumGeschäftsführerNeu,
    adresseGeschäftsführerNeu,
    nameGeschäftsführerAlt,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: true,
  noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss (§ 39 Abs. 1 GmbHG). Die Handelsregisteranmeldung muss von den Geschäftsführern in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
});
