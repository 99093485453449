import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import {
  ComparisionRowTitleDescription,
  ComparisonRows,
  RVOComparisonTable,
} from "../RVOComparisonTable/RVOComparisonTable";

export const wpCodeRVOCompareUmlaufbeschlussSherpany =
  "***insertRVOCompareUmlaufbeschlussSherpany***";

enum Rows {
  Mitteilen = "Mitteilen",
  Stimmfrist = "Stimmfrist",
  StimmabgabenErfassen = "StimmabgabenErfassen",
  ErgebnisFeststellen = "ErgebnisFeststellen",
  Niederschrift = "Niederschrift",
  Signieren = "Signieren",
  Vorlagen = "Vorlagen",
  ZustimmungEinholen = "ZustimmungEinholen",
  Datensicherheit = "Datensicherheit",
}

const rowData: Record<Rows, ComparisionRowTitleDescription> = {
  [Rows.Mitteilen]: {
    rowTitle: (t) => t("tables.resolvioSherpany.mitteilen.title"),
    rowDescription: (t) => t("tables.resolvioSherpany.mitteilen.description"),
    rowExplanation: (t) => t("tables.resolvioSherpany.mitteilen.explanation"),
  },
  [Rows.Stimmfrist]: {
    rowTitle: (t) => t("tables.resolvioSherpany.stimmfrist.title"),
    rowDescription: (t) => t("tables.resolvioSherpany.stimmfrist.description"),
    rowExplanation: (t) => t("tables.resolvioSherpany.stimmfrist.explanation"),
  },
  [Rows.StimmabgabenErfassen]: {
    rowTitle: (t) => t("tables.resolvioSherpany.stimmabgaben.title"),
    rowDescription: (t) =>
      t("tables.resolvioSherpany.stimmabgaben.description"),
    rowExplanation: (t) =>
      t("tables.resolvioSherpany.stimmabgaben.explanation"),
  },
  [Rows.ErgebnisFeststellen]: {
    rowTitle: (t) => t("tables.resolvioSherpany.beschlussfeststellung.title"),
    rowDescription: (t) =>
      t("tables.resolvioSherpany.beschlussfeststellung.description"),
    rowExplanation: (t) =>
      t("tables.resolvioSherpany.beschlussfeststellung.explanation"),
  },
  [Rows.Niederschrift]: {
    rowTitle: (t) => t("tables.resolvioSherpany.niederschrift.title"),
    rowDescription: (t) =>
      t("tables.resolvioSherpany.niederschrift.description"),
    rowExplanation: (t) =>
      t("tables.resolvioSherpany.niederschrift.explanation"),
  },
  [Rows.Signieren]: {
    rowTitle: (t) => t("tables.resolvioSherpany.signieren.title"),
    rowDescription: (t) => t("tables.resolvioSherpany.signieren.description"),
    rowExplanation: (t) => t("tables.resolvioSherpany.signieren.explanation"),
  },
  [Rows.Vorlagen]: {
    rowTitle: (t) => t("tables.resolvioSherpany.vorlagen.title"),
    rowDescription: (t) => t("tables.resolvioSherpany.vorlagen.description"),
    rowExplanation: (t) => t("tables.resolvioSherpany.vorlagen.explanation"),
  },
  [Rows.ZustimmungEinholen]: {
    rowTitle: (t) => t("tables.resolvioSherpany.zustimmungEinholen.title"),
    rowDescription: (t) =>
      t("tables.resolvioSherpany.zustimmungEinholen.description"),
    rowExplanation: (t) =>
      t("tables.resolvioSherpany.zustimmungEinholen.explanation"),
  },
  [Rows.Datensicherheit]: {
    rowTitle: (t) => t("tables.resolvioSherpany.datensicherheit.title"),
    rowDescription: (t) =>
      t("tables.resolvioSherpany.datensicherheit.description"),
    rowExplanation: (t) =>
      t("tables.resolvioSherpany.datensicherheit.explanation"),
  },
};

enum ColumnTitles {
  Resolvio = "Resolvio",
  Sherpany = "Sherpany",
}

export const comparisonResolvioSherpany: ComparisonRows[] = [
  {
    rowData: rowData.Mitteilen,
    columns: [
      { columnTitle: ColumnTitles.Resolvio, value: "✔" },
      { columnTitle: ColumnTitles.Sherpany, value: "✖" },
    ],
  },
  {
    rowData: rowData.Stimmfrist,
    columns: [
      { columnTitle: ColumnTitles.Resolvio, value: "✔" },
      { columnTitle: ColumnTitles.Sherpany, value: "✖" },
    ],
  },
  {
    rowData: rowData.StimmabgabenErfassen,
    columns: [
      { columnTitle: ColumnTitles.Resolvio, value: "✔" },
      { columnTitle: ColumnTitles.Sherpany, value: "✖" },
    ],
  },
  {
    rowData: rowData.ErgebnisFeststellen,
    columns: [
      { columnTitle: ColumnTitles.Resolvio, value: "✔" },
      { columnTitle: ColumnTitles.Sherpany, value: "✖" },
    ],
  },
  {
    rowData: rowData.Niederschrift,
    columns: [
      { columnTitle: ColumnTitles.Resolvio, value: "✔" },
      { columnTitle: ColumnTitles.Sherpany, value: "✖" },
    ],
  },
  {
    rowData: rowData.Signieren,
    columns: [
      { columnTitle: ColumnTitles.Resolvio, value: "✔" },
      { columnTitle: ColumnTitles.Sherpany, value: "✖" },
    ],
  },
  {
    rowData: rowData.Vorlagen,
    columns: [
      { columnTitle: ColumnTitles.Resolvio, value: "✔" },
      { columnTitle: ColumnTitles.Sherpany, value: "✖" },
    ],
  },
  {
    rowData: rowData.ZustimmungEinholen,
    columns: [
      { columnTitle: ColumnTitles.Resolvio, value: "✔" },
      { columnTitle: ColumnTitles.Sherpany, value: "✖" },
    ],
  },
  {
    rowData: rowData.Datensicherheit,
    columns: [
      { columnTitle: ColumnTitles.Resolvio, value: "✔" },
      { columnTitle: ColumnTitles.Sherpany, value: "US-Cloud Act" },
    ],
  },
];

export const RVOCompareUmlaufbeschlussSherpany = (): JSX.Element => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: isSmallScreen ? "6rem" : "8rem",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "4rem",
          width: isSmallScreen ? "100%" : "100%",
          alignItems: "flex-start",
          margin: theme.spacing(2, 0),
          overflow: "auto",
        }}
      >
        <Box
          style={{
            maxWidth: "800px",
          }}
        >
          <RVOComparisonTable rows={comparisonResolvioSherpany} />
        </Box>
      </Box>
    </Box>
  );
};
