import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { clsx } from "clsx";
import { IconButton } from "gatsby-material-ui-components";
import { MouseEvent } from "react";
import { RVOHelpButtonButtonOnlyProps } from "./RVOHelpButtonInterfaces";
import { useRVOHelpButtonStyles } from "./RVOHelpButtonStyles";

/**
 * A button triggering a help view (right now, a modal).
 */
export const RVOHelpButtonButtonOnly = ({
  onClick,
  helpTitle,
  iconOverride,
  helpButtonIconClassName,
  className,
}: RVOHelpButtonButtonOnlyProps): JSX.Element => {
  const classes = useRVOHelpButtonStyles();

  const onClickFull = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onClick(e);
  };

  const iconToUse = iconOverride || <HelpOutlineIcon />;

  return (
    <IconButton
      aria-label={helpTitle}
      className={clsx(classes.button, className)}
      classes={{ label: helpButtonIconClassName }}
      color="primary"
      onClick={onClickFull}
    >
      {iconToUse}
    </IconButton>
  );
};
