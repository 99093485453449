import { isProductionEnvironment } from "@nvon/baseline";
import {
  defaultStateOnDevelopment,
  stateSyncingInDevelopmentMode,
} from "../helpers/clientSide/constants";
import { rootStateEmpty } from "./initialState/rootStates/rootStateEmpty";
import { StateGetter } from "./persistStoreState";
import { RVORootState } from "./storeInterfaces";

/**
 * resolvio-specific logic for loading store state.
 */
export const rvoStateGetter: StateGetter<RVORootState> = (savedState) => {
  if (isProductionEnvironment()) {
    return savedState || rootStateEmpty;
  }

  if (stateSyncingInDevelopmentMode) {
    return savedState || defaultStateOnDevelopment;
  }

  return defaultStateOnDevelopment;
};
