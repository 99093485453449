import { useTranslation } from "react-i18next";
import slugify from "slugify";
import { VotingMode } from "../../../data/juristicalData/votingModes/votingModeInterfaces";

export const useGetVotingModeSlug = (): ((
  votingMode: VotingMode
) => string) => {
  const { t } = useTranslation();

  return (votingMode) => slugify(votingMode.label(t)).toLowerCase();
};
