import {
  nameBevollmächtigter,
  nameGeschäftsführer,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGeschaftsfuhrerAnstellungsvertragBeauftragungAnlage: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.geschäftsführerNeu,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.zugang],
    bezug: [
      "GeschäftsführerBestellung",
      "GeschäftsführerVertretungsbefugnisEinzel",
      "GeschäftsführerVertretungsbefugnisGesamt",
      "GeschäftsführerVertretungsbefugnisGemischt",
      "GeschäftsführerBefreiung181",
    ],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung:
      "Anstellungsvertrag Geschäftsführer: Beauftragung mit Anlage",
    description: undefined,
    antrag: `${insertTemplateVariable(
      nameBevollmächtigter
    )} wird damit beauftragt und hierzu bevollmächtigt, den <strong>in der Anlage beigefügten</strong> Anstellungsvertrag mit

  <p>${insertTemplateVariable(nameGeschäftsführer)},</p>

  <p>im Namen der und mit Wirkung für die Gesellschaft zu schließen.</p>

  `,
    begründung: undefined,
    templateVariables: [nameBevollmächtigter, nameGeschäftsführer],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
