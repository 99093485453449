import {
  adresseGeschäftsführer,
  geburtsdatumGeschäftsführer,
  nameGeschäftsführer,
  tagBestellung,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGeschaftsfuhrerBestellung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
  vorlageKategorie: VorlageKategorie.geschäftsführerNeu,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.zugang, Vollzug.handelsregisterAnmeldung],
  bezug: [
    "GeschäftsführerVertretungsbefugnisEinzel",
    "GeschäftsführerVertretungsbefugnisGesamt",
    "GeschäftsführerVertretungsbefugnisGemischt",
    "GeschäftsführerBefreiung181",
  ],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Bestellung eines neuen Geschäftsführers",
  description: undefined,
  metaDescription:
    "Bestellung eines Geschäftsführers mit Gesellschafterbeschluss. Juristisch geprüfte Standard-Muster/Vorlagen kostenlos ausfüllen und downloaden.",
  tags: ["bestellen", "berufen", "installieren", "Geschäftsführer"],
  antrag: `
  <p>
  Zum neuen Geschäftsführer der Gesellschaft wird mit Wirkung ab ${insertTemplateVariable(
    tagBestellung
  )} bestellt:
  </p>

  <p>${insertTemplateVariable(nameGeschäftsführer)},<br/>
  geboren am ${insertTemplateVariable(geburtsdatumGeschäftsführer)},<br/>
  wohnhaft in ${insertTemplateVariable(adresseGeschäftsführer)}.</p>`,
  begründung: undefined,
  templateVariables: [
    tagBestellung,
    nameGeschäftsführer,
    geburtsdatumGeschäftsführer,
    adresseGeschäftsführer,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: true,
  noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss (§ 39 Abs. 1 GmbHG). Die Handelsregisteranmeldung muss von den Geschäftsführern in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
});
