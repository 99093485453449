import { createStyles, makeStyles } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { TruendoCookieConsentProps } from "./TruendoCookieConsentInterfaces";

const useTruendoStyles = makeStyles(() => {
  return createStyles({
    "@global": {
      body: {
        "& #truendo_fab": {
          zIndex: "1 !important",
        },
      },
    },
  });
});

const TruendoCookieConsent = (
  props: TruendoCookieConsentProps
): JSX.Element => {
  useTruendoStyles();
  return (
    <Helmet>
      <script
        id="truendoAutoBlock"
        type="text/javascript"
        src="https://cdn.priv.center/pc/truendo_cmp.pid.js"
        data-siteid={props.truendoSiteId}
      />
    </Helmet>
  );
};

export default TruendoCookieConsent;
