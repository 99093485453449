import { nameGeschäftsführer } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageEntlastungRegelmassigGeschaftsfuhrer: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.regelmäßigerBeschluss,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.zugang],
    bezug: [
      "EntlastungRegelmäßigAufsichtsrat",
      "EntlastungKomplementärRegelmäßig",
      "EntlastungRegelmäßigKomplementär",
      "EntlastungRegelmäßigGeschäftsführenderPartner",
      "EntlastungRegelmäßigBeirat",
      "EntlastungRegelmäßigGeschäftsführenderGesellschafter",
      "EntlastungRegelmäßigKomplementärGmbH",
      "AbschlussprüferBestellung",
      "JahresabschlussAufstellung",
      "JahresabschlussFeststellung",
      "JahresplanungFeststellung",
      "VerwendungDesGewinns",
    ],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung: "Entlastung des Geschäftsführers",
    metaDescription:
      "Gesellschafterbeschluss, um Geschäftsführer Entlastung zu erteilen und von Haftung freizustellen. Juristisch geprüfte Muster/Vorlagen kostenlos nutzen.",
    tags: [
      "jährlich",
      "Abberufen",
      "Abberufung",
      "Schadenersatz",
      "Risiko",
      "ordentliche Gesellschafterversammlung",
      "Haftung",
      "Verzicht",
      "Erlass",
      "Vertrauen",
      "Entscheider",
      "Entscheidungsträger",
      "Direktor",
      "Director",
      "Vorstand",
      "Vorsitzender",
      "Führung",
      "Leitung",
      "Leiter",
      "Geschäftsführung",
      "Geschäftsleitung",
      "Geschäftsleiter",
      "Chef",
      "Leiter",
      "Vorgesetzter",
      "Führungskraft",
      "Geschäftsführer",
      "Manager",
      "CEO",
      "CTO",
      "CFO",
      "leader",
      "head",
      "chief",
      "executive",
      "boss",
      "governor",
      "governance",
    ],
    description: `
  <p>
    Durch die Entlastung <strong>billigen die Gesellschafter die  Amtsführung des Geschäftsführers in der Vergangenheit</strong> und sprechen ihm zugleich ihr <strong>Vertrauen für die zukünftige Geschäftsführerung</strong> aus.
  </p>

  <p>
    Der Entlastungsbeschluss hat eine <strong>weitreichende Wirkung</strong>: In der Folge der Entlastung wird der Geschäftsführer von allen Haftungsansprüchen gegenüber der Gesellschaft frei, die für die Gesellschafter im Zeitpunkt des Entlastungsbeschlusses (bei Prüfung aller ihnen zugänglich gemachten Unterlagen) erkennbar waren. Die Gesellschaft kann den Geschäftsführer danach nicht mehr wegen erkennbarer Pflichtverletzungen in Haftung nehmen.
  </p>

  <p>
    Die Entlastung ist ein wichtiges Instrument, um das <strong>Haftungsrisiko</strong> des Geschäftsführers zu begrenzen und ihm auch nach mehreren Jahren der Geschäftsführung "gut schlafen" zu lassen. Es liegt oftmals nicht im Interesse der Gesellschaft, dass ein Geschäftsführer allzu ängstlich und risikoavers agiert - nur aus Angst, wegen zurückliegender Geschäftsführungsmaßnahmen noch nach Jahren zur Rechenschaft gezogen zu werden.
  </p>
    `,
    antrag: `
  <p>
    <strong>${insertTemplateVariable(
      nameGeschäftsführer
    )}</strong> wird als Geschäftsführer
  </p>


  <ul data-list-style="none">
    <li><p>
      Entlastung
    </p></li>
  </ul>


  <p>
    erteilt.
  </p>
  `,
    begründung: undefined,
    templateVariables: [nameGeschäftsführer],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
