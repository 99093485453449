import SignaturePlaceholder from "../../../../components/01-atoms/SignaturePlaceholder/SignaturePlaceholder";
import { multilineTextFragment } from "../../../../helpers/clientSide/multilineTextFragment";
import { GrNumber } from "../../../../interfaces/grNumber";
import { useCompanyInfo } from "../../../../state/companyInfo/companyInfoSlice";
import { datumBeschluss } from "../../../vorlagen/vorlagenShared/allTemplateVariables";
import { CompanyTypeName } from "../../../vorlagen/vorlagenShared/generatedTypes/CompanyTypeName";
import { getRoleName } from "../../namesForRoles/getRoleName";
import {
  VotingMode,
  VotingModeIdentifier,
  VotingModeSwitchKey,
} from "../votingModeInterfaces";
import DefaultCoatingHeader from "./DefaultCoatingHeader/DefaultCoatingHeader";
import { useCoatingInformation } from "./useCoatingInformation";

/**
 * Multiple people, unanimous decision, meeting in place.
 *
 * "Vollversammlung"
 */
export const votingModePlenum: VotingMode = {
  identifier: VotingModeIdentifier.Plenum,
  CoatingHeader: () => {
    const coatingInformation = useCoatingInformation(GrNumber.Plural);
    const coatingInformationSing = useCoatingInformation(GrNumber.Singular);
    const { rechtsform, articleGenitive, isVerein } = useCompanyInfo();

    if (!coatingInformation) {
      return null;
    }

    if (!coatingInformationSing) {
      return null;
    }

    const headline = multilineTextFragment(
      `Beschluss`,
      `der ${coatingInformation.whoGenitive}`,
      `${articleGenitive} ${coatingInformation.company}`
    );
    const bodyText = `Unter Verzicht auf alle durch Gesetz oder ${
      rechtsform === CompanyTypeName.EVDe ? "Satzung" : "Gesellschaftsvertrag"
    }  vorgesehenen Formen und Fristen für die Einberufung, Ankündigung und Abhaltung einer ${
      coatingInformationSing.meetingNameSingGenCap
    } halten sämtliche ${coatingInformation.whoNominative} ${articleGenitive} ${
      coatingInformation.company
    } mit Sitz in ${coatingInformation.sitz}  - nachfolgend auch ${
      isVerein ? '"Verein"' : '"Gesellschaft"'
    }  genannt - eine ${coatingInformation.whoCompound}${
      coatingInformationSing.meetingNameSingNomNoncap
    } ab und fassen mit allen Stimmen den folgenden Beschluss:`;

    return <DefaultCoatingHeader {...{ headline, bodyText }} />;
  },
  CoatingFooter: () => {
    const { firma, whoCanDecide, rechtsform, articleGenitive } =
      useCompanyInfo();
    const who = getRoleName(whoCanDecide, rechtsform, GrNumber.Plural);

    if (!who) {
      return null;
    }

    return (
      <SignaturePlaceholder>{`Unterschriften sämtlicher ${who} ${articleGenitive} ${firma}`}</SignaturePlaceholder>
    );
  },
  label: (t) => t("votingMode.plenum"),
  additionalInputs: [datumBeschluss],
  switchValues: {
    [VotingModeSwitchKey.AreMultiplePeopleInvolved]: true,
    [VotingModeSwitchKey.IsUnanimous]: true,
    [VotingModeSwitchKey.DoPeopleMeetInPerson]: true,
  },
};
