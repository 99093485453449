import {
  ausschüttung,
  bestehenderGewinnvortrag,
  bestehenderVerlustvortrag,
  bilanzgewinn,
  bilanzStichtag,
  jahresüberschuss,
  künftigerGewinnvortrag,
  nameGeschäftsführer,
  pflichtrücklage,
  rücklage,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGesellschafterBeschluesseZumJahresabschlussUG: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.regelmäßigerBeschluss,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.bundesanzeiger],
    tags: [
      "Jahresabschluss",
      "Abschluss",
      "Bilanz",
      "Rechnungslegung",
      "Aufstellung",
      "Schlussrechnung",
      "Abrechnung",

      "feststellen",
      "Feststellung",
      "genehmigen",
      "absegnen",
      "zustimmen",
      "grünes Licht",
      "Weg frei machen",
      "Okay geben",
      "O.K. geben",
      "OK geben",
      "Erlaubnis",
      "Unterstützung",
      "erlauben",
      "unterstützen",
      "einwilligen",
      "Einwilligung",
      "Genehmigung",
      "Zustimmung",
      "Approval",
      "blessing",
      "confirmation",
      "consent",
      "endorsement",
      "green light",
      "persmission",
      "ratification",
      "support",

      "Gewinn",
      "Ergebnis",
      "Erlös",
      "Ertrag",
      "Marge",
      "Rendite",
      "schwarze Zahlen",
      "Überschuss",
      "profit",
      "winnings",
      "gain",
      "win",

      "Gewinnvortrag",
      "Vortrag",
      "Bilanzgewinn",
      "Kapitalrücklage",
      "freie Rücklage",

      "Pflichtrücklage",
      "Zwangsrücklage",
      "5a",
      "gesetzliche Rücklage",

      "Ausschüttung",
      "Ergebnisverteilung",
      "Gewinnausschüttung",
      "Gewinnverwendung",
      "Verteilung",
      "Aufteilung",
      "Zuteilung",
      "Auszahlung",
      "Dividende",
      "distribution",
      "payout",
      "dividend",
      "disburse",
      "disbursement",
      "distribute",

      "jährlich",
      "Abberufen",
      "Abberufung",
      "Schadenersatz",
      "Risiko",
      "ordentliche Gesellschafterversammlung",
      "Haftung",
      "Verzicht",
      "Erlass",
      "Vertrauen",
      "Entscheider",
      "Entscheidungsträger",
      "Direktor",
      "Director",
      "Vorstand",
      "Vorsitzender",
      "Führung",
      "Leitung",
      "Leiter",
      "Geschäftsführung",
      "Geschäftsleitung",
      "Geschäftsleiter",
      "Chef",
      "Leiter",
      "Vorgesetzter",
      "Führungskraft",
      "Geschäftsführer",
      "Manager",
      "CEO",
      "CTO",
      "CFO",
      "leader",
      "head",
      "chief",
      "executive",
      "boss",
      "governor",
      "governance",
    ],

    description: `

  <p>In dieser Vorlage sind alle Beschlüsse versammelt, die von den Gesellschaftern einer (nicht prüfungspflichtigen) UG jedes Jahr nach Ablauf des Geschäftsjahres in der Regel zu fassen sind. Sie ist für die besonderen Bedürfnisse der UG (haftungsbeschränkt) angepasst: Hier ist eine <strong>Pflichtrücklage von 25 % des (ggf. um einen Verlustvortrag aus dem Vorjahr verminderten) Jahresüberschusses</strong> bei der Gewinnverwendung zu berücksichtigen, sodass nur 75 % hiervon anderweitig verwendet werden können.
  </p>
    </p>
    <ul>
    <li>Feststellung des Jahresabschlusses</li>
    <li>Gewinnverwendung mit Berücksichtigung der Pflichtrücklage bei der UG</li>
    <li>Entlastung der Geschäftsführung</li>
    </ul>

  `,
    bezug: [
      "EntlastungRegelmäßigAufsichtsrat",
      "EntlastungKomplementärRegelmäßig",
      "EntlastungRegelmäßigKomplementär",
      "EntlastungRegelmäßigGeschäftsführenderPartner",
      "EntlastungRegelmäßigBeirat",
      "EntlastungRegelmäßigGeschäftsführenderGesellschafter",
      "EntlastungRegelmäßigGeschäftsführer",
      "EntlastungRegelmäßigKomplementärGmbH",
      "AbschlussprüferBestellung",
      "JahresabschlussAufstellung",
      "JahresplanungFeststellung",
      "VerwendungDesGewinns",
    ],
    kurzBezeichnung: "Beschlüsse zum Jahresabschluss für eine UG (Bundle)",
    metaDescription:
      "Anwaltlich geprüfte Vorlage für alle Beschlüsse, die eine UG nach dem Abschluss ihres Geschäftsjahres benötigt (mit Berücksichtigung der gesetzlichen Pflichtrücklage). Feststellung Jahresabschluss, Gewinnverwendung, Entlastung. Jetzt kostenlos herunterladen.",
    antrag: `
  <ol>
  <li>
  <p>
    Der als <strong>Anlage</strong> beigefügte
  </p>

  <p>
    <ul data-list-style="none">
      <li><p>
        <strong>Jahresabschluss zum ${insertTemplateVariable(
          bilanzStichtag
        )}</strong>,
       </p></li>
    </ul>
  </p>

  <p>
    der von der Geschäftsführung aufgestellt worden ist, wird hiermit festgestellt.
  </p>

  <p>
    Die Geschäftsführung wird angewiesen, den Jahresabschluss zur Veröffentlichung beim elektronischen Unternehmensregister (unternehmensregister.de) einzureichen, sofern die Gesellschaft zur Veröffentlichung verpflichtet ist.
  </p>
  </li>
  <li>
 <p>
    Der Bilanzgewinn der Gesellschaft zum ${insertTemplateVariable(
      bilanzStichtag
    )} setzt sich wie folgt zusammen:
  </p>


  <ul data-list-style="none">
    <li><p>
      <table>
        <tr>
          <td>
            Jahresergebnis
          </td>
          <td>
              ${insertTemplateVariable(jahresüberschuss)}
          </td>
        </tr>
        <tr>
          <td>
            zuzüglich Gewinnvortrag
          </td>
          <td>
              ${insertTemplateVariable(bestehenderGewinnvortrag)}
          </td>
        </tr>
        <tr>
          <td>
            abzüglich Verlustvortrag
          </td>
          <td>
              ${insertTemplateVariable(bestehenderVerlustvortrag)}
          </td>
        </tr>
         <tr>
          <td>
            Pflichtrücklage (25 % des ggf. um Verlustvortrag geminderten Jahresüberschusses)
          </td>
          <td>
              ${insertTemplateVariable(pflichtrücklage)}
          </td>
        </tr>
            <tr>
          <td>
            <strong>Bilanzgewinn</strong>
          </td>
          <td>
              <strong>${insertTemplateVariable(bilanzgewinn)} </strong>
          </td>
        </tr>
      </table>
    </p></li>
  </ul>


  <p>
    Der Bilanzgewinn wird wie folgt verteilt:
  </p>


  <ul data-list-style="none">
    <li><p>
      <table>

        <tr>
          <td>
            Ausschüttung an die Gesellschafter
          </td>
          <td>
              ${insertTemplateVariable(ausschüttung)}
          </td>
        </tr>
        <tr>
          <td>
            Einstellung in die freie Rücklage
          </td>
          <td>
              ${insertTemplateVariable(rücklage)}
          </td>
        </tr>
        <tr>
          <td>
            Gewinnvortrag
          </td>
          <td>
             ${insertTemplateVariable(künftigerGewinnvortrag)}
          </td>
        </tr>
      </table>
    </p></li>
  </ul>
  </li>
   <li>
    <p>
    <strong>${insertTemplateVariable(
      nameGeschäftsführer
    )}</strong> wird als Geschäftsführer
  </p>


  <ul data-list-style="none">
    <li><p>
      Entlastung
    </p></li>
  </ul>

  <p>
    erteilt.
  </p>
  </li>
  </ol>`,
    begründung: undefined,
    templateVariables: [
      bilanzStichtag,
      jahresüberschuss,
      bestehenderGewinnvortrag,
      bestehenderVerlustvortrag,
      bilanzgewinn,
      pflichtrücklage,
      ausschüttung,
      rücklage,
      künftigerGewinnvortrag,
      nameGeschäftsführer,
    ],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
