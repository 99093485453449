import { vertragBezeichnung, vertragPartner } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageZustimmungVertragsabschlussAnlage: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.geschäftsführer,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.zugang],
  bezug: [],
  zustimmungErforderlichDes: undefined,
  metaDescription: `Zustimmungsbeschluss der Gesellschafter für beigefügten Vertragsschluss des Geschäftsführers (Genehmigung/Einwilligung). Juristisch geprüfte Muster/Vorlagen kostenlos.`,

  kurzBezeichnung:
    "Zustimmung zur Geschäftsführungsmaßnahme: Abschluss eines Vertrags (mit Anlage)",
  description: `
  <p>
  Wenn du dir als Geschäftsführer <strong>für einen Vertragsabschluss</strong> den Segen der Gesellschafter (oder des Beirats oder Aufsichtsrats) einholen möchtest, ist diese Vorlage genau das Richtige für dich!
  </p>

  <p>
  Falls du für etwas anderes die Zustimmung der Gesellschafter brauchst, nutze die <a href="/beschluss-vorlagen/zustimmung-fur-geschaftsfuhrung-(freitext)"><strong>Freitext-Zustimmungsvorlage</strong></a>.
  </p>
  `,
  tags: [
    "Vertrag",
    "Kontrakt",
    "Einigung",
    "Deal",
    "contract",
    "Abschluss",
    "Vertragsschluss",
    "Vertragsabschluss",
    "Kauf",
    "Miet",
    "Schenk",
    "Auftrag",
    "Vergabe",
    "Order",
    "Bestellung",
    "bestellen",

    "Entscheider",
    "Entscheidungsträger",
    "Direktor",
    "Director",
    "Vorstand",
    "Vorsitzender",
    "Führung",
    "Leitung",
    "Leiter",
    "Geschäftsführung",
    "Geschäftsleitung",
    "Geschäftsleiter",
    "Chef",
    "Leiter",
    "Vorgesetzter",
    "Führungskraft",
    "Geschäftsführer",
    "Manager",
    "CEO",
    "CTO",
    "CFO",
    "leader",
    "head",
    "chief",
    "executive",
    "boss",
    "governor",
    "governance",

    "feststellen",
    "Feststellung",
    "genehmigen",
    "absegnen",
    "zustimmen",
    "grünes Licht",
    "Weg frei machen",
    "Okay geben",
    "O.K. geben",
    "OK geben",
    "Erlaubnis",
    "Unterstützung",
    "erlauben",
    "unterstützen",
    "einwilligen",
    "Einwilligung",
    "Genehmigung",
    "Zustimmung",
    "Approval",
    "blessing",
    "confirmation",
    "consent",
    "endorsement",
    "green light",
    "persmission",
    "ratification",
    "support",
  ],

  antrag: `
  <p>
    Der Geschäftsführung wird die Zustimmung erteilt für den Abschluss
  </p>


    <ul data-list-style="none">
    <li><p>
       <strong>des in der Anlage beigefügten</strong> ${insertTemplateVariable(
         vertragBezeichnung
       )} mit
        ${insertTemplateVariable(vertragPartner)}.
        </p></li>
    </ul>


  <p>
    Zum Abschluss des vorbezeichneten Rechtsgeschäfts wird zugleich Befreiung von den Beschränkungen des § 181 BGB erteilt, soweit hierfür erforderlich.
  </p>
  `,
  begründung: undefined,
  templateVariables: [vertragBezeichnung, vertragPartner],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
