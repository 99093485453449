// tslint:disable
/**
 * Resolvio API
 * This is the API documentation for the Resolvio API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: team@resolvio.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @enum {string}
 */
export enum StripeProduct {
  Free = "Free",
  Starter = "Starter",
  Pro = "Pro",
}
