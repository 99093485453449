import { createStyles, makeStyles } from "@material-ui/core";

export const useEmojiStyles = makeStyles(() =>
  createStyles({
    emoji: {
      "& img": {
        width: "1em",
        height: "1em",
      },
    },
  })
);
