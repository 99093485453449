import {
  darlehenBetragWort,
  darlehenBetragZahl,
  darlehenRückzahlungen,
  datumDarlehensvertrag,
  jahreszinsProzentpunkteÜberBasiszinssatz,
  nameGesellschafterDarlehensgeber,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGesellschafterDarlehenForderungsverzicht: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [
      CompanyTypeName.GmbHDe,
      CompanyTypeName.UgDe,
      CompanyTypeName.GmbHCoKgDe,
      CompanyTypeName.UgCoKgDe,
      CompanyTypeName.GbRDe,
      CompanyTypeName.KgDe,
      CompanyTypeName.OHgDe,
      CompanyTypeName.PartGmbBDe,
      CompanyTypeName.PartGDe,
    ],
    vorlageKategorie: VorlageKategorie.sanierung,
    vorlage_rolleTyp: [RolleInGesellschaft.Geschftsleiter],
    vollzug: [Vollzug.zustimmungBetroffener],
    bezug: [],
    zustimmungErforderlichDes: "darlehensgebenden Gesellschafters",
    kurzBezeichnung: "Forderungsverzicht mit Besserungsschein",
    description: undefined,
    antrag: `Die Gesellschaft, vertreten durch die Geschäftsführung, - nachfolgend Darlehensnehmerin genannt - vereinbaren mit

  <p>${insertTemplateVariable(nameGesellschafterDarlehensgeber)}</p>

  <p>einen

  <p style="text-align:center; text-transform:uppercase; font-size: 18px;"><strong>Forderungsverzicht mit Besserungsschein</strong></p>

  mit dem folgenden Inhalt:</p>

  <ol><li><p><span style="text-transform:uppercase;"><strong>Darlehen</strong></span>

    <p>(1) ${insertTemplateVariable(
      nameGesellschafterDarlehensgeber
    )} hat der Darlehensnehmerin ein Darlehen in Höhe von ${insertTemplateVariable(
      darlehenBetragZahl
    )} € (in Worten: ${insertTemplateVariable(
      darlehenBetragWort
    )}) mit einem Zins in Höhe von jährlich jährlich ${insertTemplateVariable(
      jahreszinsProzentpunkteÜberBasiszinssatz
    )} aufgrund des Darlehensvertrages vom ${insertTemplateVariable(
      datumDarlehensvertrag
    )} gewährt.</p>

  <p>(2) Die Darlehensnehmerin hat bislang die folgenden Rückzahlungen geleistet:  ${insertTemplateVariable(
    darlehenRückzahlungen
  )}.</p></li>

<li><p><span style="text-transform:uppercase;"><strong>Verzicht</strong></span>

<p>Mit sofortiger Wirkung verzichtet hiermit ${insertTemplateVariable(
      nameGesellschafterDarlehensgeber
    )} auf die noch ausstehende Rückzahlung des vorstehend bezeichneten Darlehensbetrags einschließlich aufgelaufener rückständiger Zinsen. Die Darlehensnehmerin nimmt den Verzicht an.</p></li>

<li><p><span style="text-transform:uppercase;"><strong>Wiederaufleben</strong></span>
<p>(1) Der Verzicht steht jedoch unter der auflösenden Bedingung der Besserung der Vermögensverhältnisse der Darlehensnehmerin.</p>

<p>(2) Die Bedingung tritt jeweils zum Ende eines jeden Wirtschaftsjahrs in dem Umfang ein, wie nach der Handelsbilanz die Forderung von ${insertTemplateVariable(
      nameGesellschafterDarlehensgeber
    )} gegen die Darlehensnehmerin wieder aufleben kann, ohne dass das satzungsmäßige Stammkapital i.S.d. § 30 GmbHG angegriffen wird. </p>

<p>(3) Für den Fall des Bedingungseintritts sind auch die für die Zeit des Forderungsverzichts aufgelaufenen Zinsen nachzuzahlen.</p>

<p>(4) Lebt die Forderung auf Grund des Bedingungseintritts jeweils zum Jahresende nur teilweise auf, so bezieht sich dies vorrangig auf den Zinsanspruch und erst nach Verbrauch des jeweiligen Zinsanspruchs auf den Stammanspruch.</p>

<p>(5) Den Beteiligten ist bekannt, dass das Wiederaufleben der Darlehensforderung bei der Gesellschaft als Aufwand zu behandeln ist, die Forderung derzeit vollständig wertlos ist und der Verzicht in voller Höhe zu einem außerordentlichen Ertrag führt, der mit den bestehenden Verlustvorträgen zu verrechnen ist.</p></li>

<li><p><span style="text-transform:uppercase;"><strong>Schlussbestimmungen</strong></span>
<p>(1) Sollte eine der vorstehenden Bestimmungen unwirksam sein oder werden, so wird die Wirksamkeit der übrigen Bestimmungen dieses Vertrags hierdurch nicht berührt. An Stelle der unwirksamen Bestimmung gilt eine solche als vereinbart, die im Rahmen des rechtlich möglichen dem am nächsten kommt, was von den Vertragsparteien nach dem ursprünglichen Sinn und Zweck dieses Vertrags gewollt war. Diese Regelung gilt entsprechend für etwaige Lücken dieses Vertrags.</p>

<p>(2) Änderungen, Ergänzungen und die Aufhebung dieses Vertrags bedürfen zu ihrer Wirksamkeit der Schriftform. Dies gilt auch für die Änderung dieser Schriftformklausel selbst. Ausgeschlossen sind damit insbesondere Vertragsänderungen durch betriebliche Übung. Das vorstehende Schriftformerfordernis findet keine Anwendung bei Abreden, die nach Vertragsschluss unmittelbar zwischen den Parteien mündlich getroffen werden.</p>

<p>(3) Mündliche Nebenabreden bestehen nicht.</p></li>
</ol>`,
    begründung: undefined,
    templateVariables: [
      nameGesellschafterDarlehensgeber,
      darlehenBetragZahl,
      darlehenBetragWort,
      jahreszinsProzentpunkteÜberBasiszinssatz,
      datumDarlehensvertrag,
      darlehenRückzahlungen,
    ],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
