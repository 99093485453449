import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";
import {
  mitarbeiterbeteiligungsKgFirma,
  mitarbeiterbeteiligungsKgHafteinlage,
  mitarbeiterbeteiligungsKgKomplementärin,
  mitarbeiterbeteiligungsKgPflichteinlage,
  mitarbeiterbeteiligungsKgSitz,
  mitarbeiterbeteiligungsKgVestingperiode,
  unternehmenFirma,
  unternehmenGesellschafter,
  unternehmenMonateNachFinanzierungsrunde,
  unternehmenRegisterAmtsgericht,
  unternehmenRegisternummer,
  unternehmenStammkapital,
  variantsPriviligiertePersonen,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

export const vorlageGesellschaftsvertragKGMitarbeiterbeteiligung: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    isEmployeeParticipationTemplate: true,
    isPremium: true,
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [
      CompanyTypeName.KgDe,
      CompanyTypeName.UgCoKgDe,
      CompanyTypeName.GmbHCoKgDe,
    ],
    vorlageKategorie: VorlageKategorie.startup,
    vorlage_rolleTyp: [RolleInGesellschaft.Geschftsleiter],
    vollzug: [Vollzug.handelsregisterAnmeldung],
    bezug: [],
    tags: [
      "Mitarbeiterbeteiligung",
      "ESOP",
      "VSOP",
      "Mitarbeiter",
      "Arbeitnehmer",

      "KG",
      "Kommanditgesellschaft",
    ],
    zustimmungErforderlichDes: "",
    kurzBezeichnung:
      "Mitarbeiterbeteiligung: Gesellschaftsvertrag der Mitarbeiterbeteiligungs-KG",
    metaDescription:
      "Vertragsmuster für Mitarbeiterbeteiligungs-KG für Mitarbeiterbeteiligung an einem Unternehmen. Juristisch geprüfte Muster/Vorlagen kostenlos nutzen.",
    description: `
  <p>
    Durch den Beschluss nach dieser Beschlussvorlage kann eine Mitarbeiterbeteiligung in einer GmbH oder AG praktisch und rechtssicher realisiert werden. Die Beschlussvorlage entspricht dem State-of-the-art für die Mitarbeiterbeteiligung mit echten Anteilen an einem Unternehmen mit beliebiger Rechtsform (z.B. GmbH, AG oder SE).
  </p>

    <p>
    Die Mitarbeiterbeteiligung über eine Mitarbeiterbeteiligungs-KG erfolgt nach der folgenden Konstruktion: Die beteiligten Mitarbeiter werden in einer Mitarbeiterbeteiligungs-KG gebündelt, d.h. zwischen Mitarbeiter und Unternehmen wird eine Mitarbeiterbeteiligungs-KG zwischengeschaltet. An der KG werden die Mitarbeiter als Kommanditisten beteiligt und sind so mittelbare Gesellschafter des Unternehmens. In dieser Holding übernehmen in der Regel die Altgesellschafter oder eine von diesen gegründete UG die Rolle der sogenannten Komplementärin (= persönlich haftende Gesellschafterin). Das Unternehmen selbst als geschäftsführende Kommanditistin hält zu Beginn einen Vorrat an Mitarbeiter-Kommanditanteilen. Diese Mitarbeiteranteile kann das Unternehmen dann nach und nach an Mitarbeiter übertragen und diese so an der Wertsteigerung und dem Unternehmenserfolg des Startups gesellschaftsrechtlich beteiligen.
  </p>

    <p>
  <strong>Vorteile der Mitarbeiterbeteiligung über die Mitarbeiterbeteiligungs-KG sind:</strong>
  </p>
  <ul>
    <li>
        <p>
            Keine notarielle Beurkundungspflicht, auch wenn das Unternehmen eine GmbH, UG oder Aktiengesellschaft/SE ist.
        </p>
    </li>
    <li>
        <p>
            Die echte gesellschaftsrechtliche Beteiligung der Mitarbeiter sorgt für die gewünschte Mitarbeiter-Bindung und -Motivation!
        </p>
    </li>
    <li>
        <p>
            Als Kommanditisten haben die Mitarbeiter keinen Einfluss auf die Geschäftsführung und auch keine Weisungsrechte an die Geschäftsführung.
        </p>
    </li>
    <li>
        <p>
            Die Rechtsform der Kommanditgesellschaft ermöglicht eine finanzielle Beteiligung zur Errichtung eines Interessengleichlaufs, ohne Mitarbeitern eine Mitsprache oder Einsicht in wesentlichen Strukturentscheidungen des Unternehmens zu eröffnen; zugleich besteht praktisch kein Haftungsrisiko der beteiligten Mitarbeiter.
        </p>
    </li>
    <li>
        <p>
            Pooling: Durch die indirekte Beteiligung der Mitarbeiter wird vermieden, dass der Cap-Table zu sehr ausfranst und dadurch künftige Finanzierungsrunden erschwert würden.
        </p>
    </li>
     <li>
        <p>
            Granulare Zuteilung der Mitarbeiteranteile ist unproblematisch dadurch möglich, dass das Startup seine Kommanditanteile an seine Mitarbeiter nach und nach abtritt.
        </p>
    </li>

    <li>
        <p>
            Durch die Beteiligung des Unternehmens an der Mitarbeiterbeteiligungs-KG als geschäftsführende Kommanditistin wird verhindert, dass die KG steuerlich als gewerblich im Sinne des § 14 Abs. 3 Nr. 2 EStG gilt. Hierdurch wird erreicht, dass die KG vermögensverwaltend, also steuerlich transparent, gestaltet ist, sodass keine zusätzliche Besteuerungsebene entsteht.
        </p>
    </li>
   <li>
        <p>
            Anders als z.B. bei Genussscheinen sind bei einer Mitarbeiterbeteiligung über eine Mitarbeiterbeteiligungs-KG keine kapitalanlagerechtlichen Registrierungs- oder Meldepflichten zu beachten (§ 2 Abs. 1 Nr. 6 KAGB).
        </p>
    </li>
  </ul>
  <p>
    Nachteilig ist indes, dass mit der Mitarbeiterbeteiligungs-KG und der als Komplementärin fungierenden Tochter-UG/-GmbH zusätzliche Gesellschaften gegründet werden müssen, was einen zusätzlichen Organisationsaufwand bedeutet. Wegen der zahlreichen Vorteile wird dieser Nachteil jedoch von vielen Unternehmen und Startups gerne in Kauf genommen.
  </p>




  `,
    antrag: `

  <h2>
    Gesellschaftsvertrag der ${insertTemplateVariable(
      mitarbeiterbeteiligungsKgFirma
    )}
 </h2>

  <ol data-list-style="decimal" data-list-style-prefix="§">
    <li>
      <h3>Präambel</h3>
       <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                Im Handelsregister des ${insertTemplateVariable(
                  unternehmenRegisterAmtsgericht
                )} unter ${insertTemplateVariable(
      unternehmenRegisternummer
    )} ist die ${insertTemplateVariable(
      unternehmenFirma
    )} mit einem Stammkapital von ${insertTemplateVariable(
      unternehmenStammkapital
    )}  eingetragen, im Folgenden auch "Hauptgesellschaft" gennannt. Gesellschafter der ${insertTemplateVariable(
      unternehmenFirma
    )} sind ${insertTemplateVariable(unternehmenGesellschafter)}.
            </p>
        </li>
        <li>
        <p>
             Die ${insertTemplateVariable(
               unternehmenFirma
             )}  plant nun bestimmten Personen als Anerkennung für ihre bislang geleisteten Dienste und als Ansporn für ihren weiteren Beitrag zum wirtschaftlichen Erfolg der ${insertTemplateVariable(
      unternehmenFirma
    )} die Möglichkeit einer Beteiligung an ihr über die ${insertTemplateVariable(
      mitarbeiterbeteiligungsKgFirma
    )} als gemeinsames Beteiligungsvehikel zu verschaffen. Zu diesem Zweck soll(en) ${insertTemplateVariable(
      mitarbeiterbeteiligungsKgKomplementärin
    )} die Rolle der Komplementärin übernehmen (im folgenden <strong>"die Komplementärin"</strong>, auch wenn es sich um mehrere handelt).  Die ${insertTemplateVariable(
      unternehmenFirma
    )}  ist als geschäftsführende Gründungskommanditistin beteiligt. Mit der von der ${insertTemplateVariable(
      unternehmenFirma
    )}  zu leistenden Pflichteinlage sollen die für die Beteiligung der Mitarbeiter bestimmten Geschäftsanteile/Aktien an der ${insertTemplateVariable(
      unternehmenFirma
    )} zum Nominalwert von den Gesellschaftern der ${insertTemplateVariable(
      unternehmenFirma
    )} erworben werden. Die Mitarbeiter sollen sodann von der ${insertTemplateVariable(
      unternehmenFirma
    )}  durch Übertragung von Kommanditanteilen beteiligt werden.
         </p></li>
      </ol>
    </li>
    <li>
      <h3>Rechtsform, Firma, Sitz</h3>
       <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li>
            <p>Die Gesellschaft führt im Rechtsverkehr die Firma</p>
            <p style="text-align:center"><strong>${insertTemplateVariable(
              mitarbeiterbeteiligungsKgFirma
            )}</strong>.</p>
        </li>

        <li><p>
            Sitz der Gesellschaft ist ${insertTemplateVariable(
              mitarbeiterbeteiligungsKgSitz
            )}.
         </p></li>
      </ol>
    </li>

    <li>
        <h3>
            Gegenstand der Gesellschaft
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
             <li>
                <p>
                    Gegenstand der Gesellschaft sind der Erwerb, das Halten, die Verwaltung sowie die Veräußerung der Beteiligung an der ${insertTemplateVariable(
                      unternehmenFirma
                    )} zum ausschließlichen Zweck der Mitarbeiterbeteiligung.
                </p>
            </li>

            <li>
                <p>
                    Die Gesellschaft ist berechtigt, alle Geschäfte vorzunehmen und alle Maßnahmen zu ergreifen, die mit dem Gegenstand der Gesellschaft zusammenhängen oder ihm unmittelbar oder mittelbar förderlich sind.
                </p>
            </li>
        </ol>
    </li>

    <li>
        <h3>
            Geschäftsjahr
        </h3>
        <p>
            Das Geschäftsjahr ist das Kalenderjahr. Das erste Geschäftsjahr beginnt mit der Eintragung der Gesellschaft im Handelsregister und endet am 31.12. des betreffenden Jahres.
        </p>
    </li>

    <li>
        <h3>
            Komplementärin
        </h3>
        <p>
            Persönlich haftende Gesellschafterin ohne Kapitalanteil ist/sind ${insertTemplateVariable(
              mitarbeiterbeteiligungsKgKomplementärin
            )} (im Folgenden <strong>"Komplementärin"</strong> auch wenn es sich um mehrere handelt).
        </p>
    </li>

    <li>
        <h3>Kommanditisten</h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Gründungskommanditistin der Gesellschaft ist die ${insertTemplateVariable(
                      unternehmenFirma
                    )} mit einer Hafteinlage in Höhe von ${insertTemplateVariable(
      mitarbeiterbeteiligungsKgHafteinlage
    )} und der folgenden zusätzlichen Pflichteinlage: ${insertTemplateVariable(
      mitarbeiterbeteiligungsKgPflichteinlage
    )}.


                </p>
            </li>
            <li>
                <p>
                    Neben der Gründungskommanditistin dürfen nur die folgenden <strong>"privilegierten Personen"</strong> als Kommanditisten aufgenommen werden:
                </p>
                ${insertTemplateVariable(variantsPriviligiertePersonen)}.
                <p>
                    Die Aufnahme sonstiger Dritter ist ausgeschlossen.
                </p>
            </li>
        </ol>
    </li>




    <li>
        <h3>Geschäftsführung und Vertretung</h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Zur Geschäftsführung der Gesellschaft ist die ${insertTemplateVariable(
                      unternehmenFirma
                    )}  berechtigt und verpflichtet (<strong>„Geschäftsführende Kommanditistin“</strong>).

                </p>
            </li>

            <li>
                <p>
                   Die Geschäftsführende Kommanditistin ist für alle Geschäftsführungsmaßnahmen zuständig. Sie ist insbesondere allein und ausschließlich für sämtliche Entscheidungen im Zusammenhang mit dem Gegenstand der Gesellschaft zuständig.

                </p>
            </li>
            <li>
                <p>
                    Zur Vertretung der Gesellschaft ist die Komplementärin berechtigt und verpflichtet. Sie und ihre Geschäftsführer sind für Rechtsgeschäfte mit der Gesellschaft und ihren Gesellschaftern von den Beschränkungen des § 181 BGB befreit. Die Komplementärin hat die Beschlüsse der Geschäftsführenden Kommanditistin durchzuführen, soweit hierzu eine Vertretung der Gesellschaft erforderlich ist; insb. übt die Komplementärin das Stimmrecht der Gesellschaft in Gesellschafterversammlungen und sonstigen Beschlussfassungen der ${insertTemplateVariable(
                      unternehmenFirma
                    )}  gemäß den Vorgaben der Geschäftsführenden Kommanditistin aus.
                </p>
            </li>
        </ol>

    </li>


    <li>
        <h3>
            Kapitalkonten
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                   Für jeden Gesellschafter werden - soweit erforderlich - folgende Kapitalkonten geführt:
                </p>
                <ol data-list-style="decimal" data-list-style-suffix=".">
                    <li>
                        <p>
                           ein Festkapitalkonto, auf dem die geleistete Hafteinlage gebucht wird;
                        </p>
                    </li>

                    <li>
                        <p>
                            ein Rücklagenkonto, auf dem die geleistete Pflichteinlage verbucht wird
                        </p>
                    </li>

                    <li>
                        <p>
                            ein variables Kapitalkonto, auf dem Folgendes zu buchen ist:
                        </p>
                        <ol data-list-style="lower-latin" >
                            <li>
                                <p>
                                   der Gewinnanteil eines Jahres. Sollte zum Zeitpunkt des Entstehens des Gewinnanteils ein Verlustvortrag auf dem Verlustvortragskonto ausgewiesen sein, so ist der Gewinnanteil zunächst dem Verlustvortragskonto gutzuschreiben, so lange, bis der dortige Saldo ausgeglichen ist - nur ein darüber hinausgehender Betrag fließt dem variablen Kapitalkonto zu.
                                </p>
                            </li>

                            <li>
                                <p>
                                    Entnahmen für private Steuern sowie sonstige Verrechnungen
                                </p>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <p>
                            ein Verlustvortragskonto.
                        </p>
                    </li>

                </ol>

            </li>

            <li>
                <p>
                    Die Kapitalkonten sind im Soll und Haben nicht verzinslich.
                </p>
            </li>

            <li>
                <p>
                    Auf dem variablen Kapitalkonto sind für jeden Gesellschafter alle Buchungen im Verhältnis zu der Gesellschaft auszuweisen, soweit sie nicht auf dem Festkapitalkonto, auf dem Rücklagenkonto oder auf dem Verlustvortragskonto auszuweisen sind.

                </p>
            </li>

            <li>
                <p>
                    Auf dem Verlustvortragskonto ist für jeden Kommanditisten sein etwaiger Verlustanteil auszuweisen und sind Gewinnanteile zum Ausgleich dieses Kontos zu buchen.
                </p>
            </li>
        </ol>
    </li>

    <li>
        <h3>
          Beteiligung am Vermögen und Ergebnis

        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                   Am Vermögen der Gesellschaft sind die Gesellschafter im Verhältnis ihrer geleisteten Kapitaleinlagen zueinander nach dem Stand der Festkapitalkonten beteiligt, jedoch nicht die Komplementärin. Die Komplementärin ist am Gewinn und Verlust der Gesellschaft nicht beteiligt; Gewinne und Verluste werden nur den Kommanditisten im Verhältnis ihrer Kapitaleinlagen gemäß Satz 1 zugewiesen.

                </p>
            </li>

            <li>
                <p>
                    Das Ergebnis der Gesellschaft wird nach handelsrechtlichen Grundsätzen und aus allen Erträgen und Aufwendungen ermittelt, die gemäß § 11 der Gesellschaft zuzurechnen sind. Die Haftungsvergütung (ggf. zuzüglich der gesetzlichen Umsatzsteuer) gilt im Verhältnis zwischen den Gesellschaftern als Aufwand.
                </p>
            </li>
            <li>
                <p>
                    Jahresfehlbeträge werden dem Verlustvortragskonto belastet und mit späteren Jahresüberschüssen vorab verrechnet.

                </p>
            </li>
        </ol>
    </li>


    <li>
        <h3>
            Jahresabschluss/Gewinnverwendung/Entnahmen
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Der Jahresabschluss ist nach den Rechnungslegungsvorschriften des HGB zu erstellen.
                </p>
            </li>
            <li>
                <p>
                    Ein Jahresüberschuss ist - ggf. nach Verrechnung mit den auf den Verlustvortragskonten vorhandenen Salden - dem variablen Kapitalkonto gutzuschreiben. Die Bildung, Auflösung und Ausschüttung von gesonderten Gewinnrücklagen sowie Zuführungen und Entnahmen aus dem Rücklagenkonto bedürfen eines Gesellschafterbeschlusses.
                </p>
            </li>
            <li>
                <p>
                    Beträge, über die ein Entnahmebeschluss gefasst worden ist, sind auf das variable Kapitalkonto zu buchen.
                </p>
            </li>
            <li>
                <p>
                    Entnahmen vom variablen Kapitalkonto sind nur zulässig, soweit das Konto dadurch nicht negativ wird; abgesehen von jederzeit möglichen Entnahmen in Höhe der persönlichen Steuern, die aufgrund einer steuerlichen Ergebniszuweisung aus dieser Gesellschaft für den einzelnen Gesellschafter entstehen. Hierbei werden die Steuern nicht individuell zum persönlichen Steuersatz eines jeden Gesellschafters, sondern pauschaliert mit einem Steuersatz von 42 % auf den Ergebnisanteil je Gesellschafter ermittelt.
                </p>
            </li>
            <li>
                <p>
                    Sämtliche Einzahlungen der Gesellschafter erfolgen auf ein von der Gesellschaft gehaltenes Konto bei einem in Deutschland zugelassenen Kreditinstitut.
                </p>
            </li>
        </ol>
    </li>

    <li>
        <h3>
            Haftungsvergütung/sonstige Aufwendungen
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Für ihre Tätigkeit als persönlich haftende Gesellschafterin der Gesellschaft erhält die Komplementärin eine Haftungsvergütung in Höhe von 2,5 % p. a. ihres Stammkapitals zzgl. der gesetzlichen Umsatzsteuer. Die Haftungsvergütung wird innerhalb von zwei Wochen nach Feststellung des Jahresabschlusses der Gesellschaft zur Zahlung fällig.
                </p>
            </li>

            <li>
                <p>
                    Die Gesellschaft hat insbesondere folgende Aufwendungen zu tragen:
                </p>
                <ol data-list-style="lower-latin" >
                    <li>
                        <p>
                           Die Kosten der Gründung. Zu den Gründungskosten gehören insbesondere Aufwendungen für Notar- und Registergebühren und Rechts- und Steuerberatung im Zusammenhang mit der Gründung der Gesellschaft.
                        </p>
                    </li>

                    <li>
                        <p>
                            Kosten im Zusammenhang mit Beschlussfassungen und Gesellschafterversammlungen und Berichten an die Kommanditisten;
                        </p>
                    </li>
                    <li>
                        <p>
                            ggf. Umsatz- und andere Betriebssteuern, auch wenn und soweit sie auf Vergütungen zwischen der Gesellschaft und Gesellschaftern entfallen;
                        </p>
                    </li>
                    <li>
                        <p>
                            Kosten der Rechts- und Steuerberatung;
                        </p>
                    </li>

                    <li>
                        <p>
                            Abschreibungen, Veräußerungsverluste u. Ä.
                        </p>
                    </li>

                </ol>
            </li>
            <li>
                <p>
                    Die Geschäftsführende Kommanditistin trägt alle gewöhnlichen, durch die laufende Verwaltung der Gesellschaft veranlassten und bei ihr anfallenden Kosten.
                </p>
            </li>
        </ol>
    </li>
     <li>
        <h3>
          Gesellschafterbeschlüsse
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                   Die Geschäftsführende Kommanditistin ruft die Gesellschafterversammlungen schriftlich oder in Textform per Post, per Email oder in der Resolvio-App unter Mitteilung der Tagesordnung mit einer Frist von zwei Wochen ein. Die Frist beginnt am Tag der Versendung. Die Tagesordnung kann bis zu drei Tage vor dem Tag der Gesellschafterversammlung ergänzt werden. Die Frist ist gewahrt, wenn die Ergänzung der Tagesordnung drei Tage vor dem Tag der Gesellschafterversammlung versendet worden ist. Den Vorsitz in der Gesellschafterversammlung führt die Geschäftsführende Kommanditistin.
                </p>
            </li>

            <li>
                <p>
                   Die ordentliche Gesellschafterversammlung findet alljährlich innerhalb des ersten Halbjahres statt. Eine außerordentliche Gesellschafterversammlung ist einzuberufen, wenn dies im Interesse der Gesellschaft erforderlich erscheint.
                </p>
            </li>

            <li>
                <p>
                   Eine Gesellschafterversammlung ist, wenn entweder mehr als 50 % der Stimmen der Kommanditisten vertreten sind oder, falls 50 % der Stimmen der Kommanditisten nicht vertreten sind, mit einfacher Mehrheit beschlussfähig. Ist die Versammlung nicht beschlussfähig, so kann danach zu einer neuen Versammlung mit gleicher Tagesordnung mit einer Frist von mindestens 2 Wochen eingeladen werden, die auch dann beschlussfähig ist, wenn nicht alle Gesellschafter vertreten sind, sofern hierauf in der Einladung ausdrücklich hingewiesen wurde.
                </p>
            </li>

            <li>
                <p>
                   Außerhalb von Versammlungen können Beschlüsse, soweit nicht zwingend eine andere Form vorgeschrieben ist, auch schriftlich, in Textform oder mit der Resolvio-App vorgenommene Abstimmungen gefasst werden, sofern alle Gesellschafter dieser Art der Beschlussfassung zustimmen oder sich an der Abstimmung beteiligen. Ein Gesellschafter beteiligt sich auch an der Abstimmung, wenn er sich der Stimme enthält. Außerhalb von Versammlungen gefasste Gesellschafterbeschlüsse sind in einem Protokoll festzustellen, sofern nicht die Gesellschafter zur Unterzeichnung der Gesellschafterbeschlüsse vorgesehen sind.
                </p>
            </li>

            <li>
                <p>
                   Jeder Kommanditist kann sich in der Gesellschafterversammlung durch einen Vertreter seiner Wahl mit schriftlicher Vollmacht vertreten lassen. Den Vorsitz der Gesellschafterversammlung führt der gesetzliche Vertreter der Geschäftsführenden Kommanditistin. Über die Beschlüsse der Gesellschafterversammlung ist eine Niederschrift anzufertigen. In ihr sind alle gefassten Gesellschafterbeschlüsse im Wortlaut unter Angabe des zahlenmäßigen Abstimmungsergebnisses aufzunehmen. Die Niederschrift ist vom Vorsitzenden der Gesellschafterversammlung zu unterzeichnen.
                </p>
            </li>

            <li>
                <p>
                    Die Gesellschafterversammlung beschließt insbesondere über
                </p>
                <ol data-list-style="lower-latin" >
                    <li>
                        <p>
                            die Feststellung des von der Geschäftsführenden Kommanditistin vorgelegten Jahresabschlusses,
                        </p>
                    </li>

                    <li>
                        <p>
                            die Entlastung der Geschäftsführenden Kommanditistin sowie der Komplementärin,
                        </p>
                    </li>

                    <li>
                        <p>
                            Änderungen des Gesellschaftsvertrages,
                        </p>
                    </li>

                    <li>
                        <p>
                            Auflösung der Gesellschaft,
                        </p>
                    </li>

                    <li>
                        <p>
                            alle anderen Angelegenheiten, für die nach diesem Vertrag die Gesellschafterversammlung zuständig ist,
                        </p>
                    </li>

                    <li>
                        <p>
                            Ausschluss von Gesellschaftern in den Fällen eines negativen Vesting gem. § 14 Abs. 2.
                        </p>
                    </li>
                </ol>

            </li>
            <li>
                <p>
                    Die Gesellschafterversammlung beschließt mit einfacher Mehrheit der abgegebenen Stimmen, soweit nicht in diesem Vertrag oder aufgrund zwingender gesetzlicher Vorschriften eine andere Mehrheit vorgesehen ist. Die Geschäftsführende Kommanditistin ist bei Beschlüssen über ihre Entlastung von der Stimmabgabe ausgeschlossen.
                </p>
            </li>
            <li>
                <p>
                   Je EUR 1,00 der Hafteinlagen gewährt eine Stimme.
                </p>
            </li>
            <li>
                <p>
                    Die Unwirksamkeit von Gesellschafterbeschlüssen kann nur binnen einer Ausschlussfrist von einem Monat nach Kenntniserlangung durch Klageerhebung gegen die Gesellschaft geltend gemacht werden.
                </p>
            </li>
            <li>
                <p>
                    Die Gesellschafter beschließen, sofern das Gesetz oder der Gesellschaftsvertrag nicht ein anderes vorsehen, mit der einfachen Mehrheit der abgegebenen Stimmen.
                </p>
            </li>
            <li>
                <p>
                    Fehlerhafte Beschlüsse, deren Zustandekommen oder Inhalt nicht gegen zwingende gesetzliche Vorschriften verstößt, können nur innerhalb von zwei Monaten seit Beschlussfassung - wenn diese im Umlaufverfahren erfolgt ist, seit Zugang der schriftlichen Mitteilung - durch Klage gegen die Gesellschaft angefochten werden.
                </p>
            </li>

        </ol>
    </li>

    <li>
        <h3>Verfügung über Kommanditanteile</h3>
          <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Vorbehaltlich der Bestimmungen in diesem Paragraphen ist die vollständige oder teilweise Verfügung über Kommanditanteile nur mit vorheriger schriftlicher Zustimmung der Geschäftsführenden Kommanditistin und nur zum Ende eines Geschäftsjahres möglich. Eine Übertragung von Kommanditanteilen ist ausschließlich an privilegierte Personen zulässig. Der Geschäftsführenden Kommanditistin ist es gestattet, Teile ihrer Kommanditbeteiligung an weitere privilegierte Personen zu übertragen, ohne dass insoweit die Zustimmung der bereits beteiligten privilegierten Personen erforderlich ist.
                 </p>
            </li>
            <li>
                <p>
                    Eine Verfügung im Sinne dieser Vorschrift umfasst den Verkauf, die Abtretung, sonstige Übertragung, Verpfändung und sonstige Belastung des Kommanditanteils.
                 </p>
            </li>
        </ol>
    </li>
     <li>
        <h3>
            Ausscheiden eines Gesellschafters
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">

            <li>
                <p>
                    Ein Gesellschafter scheidet ferner aus der Gesellschaft aus, wenn
                </p>
                <ol data-list-style="lower-latin">
                    <li>
                        <p>
                            über sein Vermögen das Insolvenzverfahren eröffnet oder die Eröffnung mangels Masse abgelehnt worden ist;
                        </p>
                    </li>

                    <li>
                        <p>
                           sein Anteil von einem Gläubiger gepfändet und die Vollstreckungsmaßnahme nicht binnen eines Monat aufgehoben worden ist;
                        </p>
                    </li>

                     <li>
                        <p>
                            der Gesellschafter von den übrigen Gesellschaftern mit einfacher Mehrheit der abgegebenen Stimmen aus wichtigem Grund ausgeschlossen wird; einen solchen wichtigen Grund stellt insbesondere ein vorsätzliches oder grob fahrlässiges schädigendes Verhalten zu Lasten der Gesellschaft oder der ${insertTemplateVariable(
                              unternehmenFirma
                            )} dar. Der auszuschließende Gesellschafter hat hierbei kein Stimmrecht;
                        </p>
                    </li>

                    <li>
                        <p>
                            er gemäß dem folgenden Absatz 2 ausgeschlossen wurde.
                        </p>
                    </li>
                </ol>

            </li>
            <li>
                <p>
                  Mitarbeiter-Kommanditisten können von den Gesellschaftern durch Mehrheitsbeschluss nach Maßgabe der folgenden Bestimmungen ganz oder teilweise ausgeschlossen werden, wenn ihr Anstellungsverhältnis mit der ${insertTemplateVariable(
                    unternehmenFirma
                  )}  beendet wird; insoweit sind die übrigen Kommanditisten auf Verlangen der Geschäftsführenden Kommanditistin verpflichtet, einem solchen Ausschließungsbeschluss zuzustimmen:

                </p>
                <ol data-list-style="lower-latin" >
                    <li>
                        <p>
                           Sofern die ${insertTemplateVariable(
                             unternehmenFirma
                           )}  dem Mitarbeiter aus wichtigem Grund gem. § 626 BGB kündigt oder dieser seinerseits seine Tätigkeit für die ${insertTemplateVariable(
      unternehmenFirma
    )}  vor Ablauf von ${insertTemplateVariable(
      mitarbeiterbeteiligungsKgVestingperiode
    )} seit dem Beginn des Anstellungsverhältnisses (<strong>„Vesting-Periode“</strong>) beendet, ohne dass die ${insertTemplateVariable(
      unternehmenFirma
    )}  dies ihrerseits aus wichtigem Grund zu vertreten hat (<strong>„Bad Leaver“</strong>), unterliegt die gesamte Kommanditbeteiligung des Mitarbeiters dem Ausschluss.

                        </p>
                    </li>

                    <li>
                        <p>
                          Sofern die Tätigkeit des Mitarbeiters für die ${insertTemplateVariable(
                            unternehmenFirma
                          )}  aus anderen Gründen als vorstehend unter lit. a) genannt, beendet wird (<strong>„Good Leaver“</strong>), gilt die Kommanditbeteiligung des Mitarbeiters anteilig für jeden vollendeten Monat seiner Tätigkeit für die ${insertTemplateVariable(
      unternehmenFirma
    )}  innerhalb der Vesting-Periode als gevestet und unterliegt nur der nicht durch Zeitablauf gevestete Teil der Kommanditbeteiligung dem Ausschluss.
                        </p>
                    </li>


                    <li>
                        <p>
                         Im Falle eines Mehrheitswechsels bei der ${insertTemplateVariable(
                           unternehmenFirma
                         )}  gelten alle Kommanditanteile der Mitarbeiter-Kommanditisten als gevestet.
                        </p>
                    </li>

                </ol>

            </li>
            <li>
                <p>
                   Im Fall des Ausscheidens eines Kommanditisten wächst sein Kommanditanteil, mit dem er ausgeschlossen wurde, allein der Geschäftsführenden Kommanditistin zu; dies gilt nicht für ein Ausscheiden der Geschäftsführenden Kommanditistin.
                </p>
            </li>
            <li>
                <p>
                   Der ausscheidende Kommanditist hat Anspruch auf ein Auseinandersetzungsguthaben, das aufgrund einer Auseinandersetzungsbilanz zu ermitteln ist. Im Fall eines Ausschlusses eines Kommanditisten aus wichtigem Grund gem. § 14 Abs. 1 Buchstabe c) oder eines Mitarbeiter-Kommanditisten in einem Bad Leaver-Fall gem. § 14 Abs. 2 Buchstabe a) ist der anteilige Buchwert der ${insertTemplateVariable(
                     unternehmenFirma
                   )}, der entsprechend der Beteiligungsquote der Gesellschaft an der ${insertTemplateVariable(
      unternehmenFirma
    )}  und im Verhältnis der Festkapitalkonten für den ausscheidenden Kommanditisten zu ermitteln ist, anwendbar. In allen anderen Fällen ist der entsprechend quotal zu ermittelnde Verkehrswert der ${insertTemplateVariable(
      unternehmenFirma
    )}  unter Anwendung der gemäß der Satzung der ${insertTemplateVariable(
      unternehmenFirma
    )}  für eine Abfindung geltenden Bewertungsmethode anzusetzen und dem ausscheidenden Kommanditisten entsprechend quotal zuzurechnen. Für den Fall, dass bei der ${insertTemplateVariable(
      unternehmenFirma
    )}  innerhalb von ${insertTemplateVariable(
      unternehmenMonateNachFinanzierungsrunde
    )} vor dem Ausscheiden des Kommanditisten eine Finanzierungsrunde umgesetzt worden sein sollte, gilt die dieser Finanzierungsrunde zugrunde gelegte Post Money-Bewertung als Verkehrswert der ${insertTemplateVariable(
      unternehmenFirma
    )} .
                </p>
            </li>
            <li>
                <p>
                   Bestreitet der ausscheidende Kommanditist die Richtigkeit des ermittelten Auseinandersetzungsguthabens, ist es von einem auf Vorschlag der Wirtschaftsprüferkammer am Sitz der Gesellschaft zu beauftragenden Wirtschaftsprüfer als Schiedsgutachter nach den vorstehenden Bewertungsgrundsätzen zu ermitteln. Die Feststellungen dieses Schiedsgutachters sind für alle Beteiligten bindend. Die Kosten des Schiedsgutachters werden von den Parteien jeweils in dem Verhältnis getragen, wie sie mit ihren jeweiligen Bewertungsvorstellungen obsiegen oder unterliegen.
                </p>
            </li>
            <li>
                <p>
                   Das Auseinandersetzungsguthaben ist von der Gesellschaft und im Fall eines ausscheidensbedingten Anwachsens von der Geschäftsführenden Kommanditistin in vier gleichen Halbjahresraten auszuzahlen. Die erste Rate wird zum Ende des Kalenderjahres gezahlt, in dem das Auseinandersetzungsguthaben festgestellt wird. Das Auseinandersetzungsguthaben ist vom Tage des Ausscheidens an mit 2 Prozentpunkten über dem EURIBOR für 3-monatige Einlagen p. a. zu verzinsen. Die Zinsen sind zusammen mit den Halbjahresraten zu zahlen. Der Gesellschaft bzw. der Geschäftsführenden Kommanditistin steht eine jederzeitige frühere Auszahlung des Auseinandersetzungsguthabens frei.
                </p>
            </li>

        </ol>
    </li>

     <li>
        <h3>
            Auflösung der Gesellschaft
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                  Die Gesellschaft gilt als aufgelöst, wenn ihre Beteiligung an der ${insertTemplateVariable(
                    unternehmenFirma
                  )}  veräußert oder liquidiert ist.
                </p>
            </li>
            <li>
                <p>
                    Wird die Gesellschaft aufgelöst, so ist die Geschäftsführende Kommanditistin Liquidatorin.
                </p>
            </li>
        </ol>
    </li>

     <li>
        <h3>
            Rechnungswesen
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Die Geschäftsführende Kommanditistin führt Buch über alle Geschäftsvorfälle der Gesellschaft. Jeder Gesellschafter kann die Bücher am Ort der Geschäftsstelle der Gesellschaft während der üblichen Geschäftszeit einsehen. Das gesetzliche Kontrollrecht bleibt unberührt.
                </p>
            </li>
            <li>
                <p>
                    Unverzüglich nach Erstellung übermittelt die Geschäftsführende Kommanditistin jedem Gesellschafter eine Kopie der Steuererklärungen der Gesellschaft zur einheitlichen und gesonderten Feststellung (Gewinn und Verlust) einschließlich der darin enthaltenen Angaben über den Anteil sowie über persönliche Besteuerungsmerkmale des betreffenden Gesellschafters, sowie weitere Informationen, soweit sie zur Erfüllung der steuerlichen Pflichten eines Gesellschafters erforderlich sind. Entsprechendes gilt für Feststellungsbescheide unmittelbar nach deren Zugang. Soweit dies zur Erfüllung der steuerlichen Pflichten eines Gesellschafters erforderlich ist, macht die Geschäftsführende Kommanditistin dem Gesellschafter innerhalb von neunzig (90) Tagen nach dem Ende eines Geschäftsjahres Angaben über den Anteil des Gesellschafters am Gewinn und Verlust und Vermögen des abgelaufenen Geschäftsjahres der Gesellschaft sowie über persönliche Besteuerungsmerkmale des betreffenden Gesellschafters.
                </p>
            </li>
        </ol>
    </li>

     <li>
        <h3>
            Geheimhaltung
        </h3>
            <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Die Gesellschafter verpflichten sich zur Einhaltung aller Verpflichtungen der Gesellschaft, insbesondere zur Geheimhaltung und zur Interessenwahrung, gegenüber der ${insertTemplateVariable(
                      unternehmenFirma
                    )}.
                </p>
            </li>

            <li>
                <p>
                    Kenntnisse, die ein Gesellschafter im Zusammenhang mit seiner Beteiligung über Verhältnisse der Gesellschaft erlangt (einschließlich von Kenntnissen über Verhältnisse der ${insertTemplateVariable(
                      unternehmenFirma
                    )} ) dürfen nicht zum Nachteil der Gesellschaft oder der ${insertTemplateVariable(
      unternehmenFirma
    )}  verwendet werden und sind gegenüber Nichtgesellschaftern geheim zu halten.
                </p>
            </li>

            <li>
                <p>
                    Die Geheimhaltungspflicht beinhaltet auch die Verpflichtung, nach besten Kräften zu verhindern, dass ein Nichtgesellschafter von Kenntnissen, die der Gesellschafter erlangt hat, unbefugt Kenntnis erlangt.
                </p>
            </li>

            <li>
                <p>
                    Die Vorschriften dieses Paragraphen gelten über die Beendigung der Gesellschafterstellung hinaus.
                </p>
            </li>
        </ol>
    </li>



    <li>
        <h3>
            Schlussbestimmung
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                  Änderungen dieses Vertrages bedürfen zu ihrer Wirksamkeit der Textform, soweit nicht gesetzlich eine Beurkundung oder andere Form vorgeschrieben ist. Dies gilt auch für die Änderung dieser Textformklausel.
                </p>
            </li>
            <li>
                <p>
                    Sollten einzelne Bestimmungen dieses Vertrages unwirksam sein oder werden, so wird die Rechtswirksamkeit der übrigen Bestimmungen dadurch nicht berührt. Die betreffende Bestimmung ist durch eine wirksame zu ersetzen, die dem angestrebten wirtschaftlichen Zweck möglichst nahe kommt.
                </p>
            </li>
            <li>
                <p>
                    Dieser Vertrag und die in ihm ausdrücklich erwähnten Nebenabreden enthalten die gesamte Vereinbarung zwischen den Gesellschaftern im Zusammenhang mit dem Gegenstand dieses Vertrages.
                </p>
            </li>
            <li>
                <p>
                    Soweit in diesem Vertrag die schriftliche Form vorgeschrieben ist, genügt für deren Einhaltung die Textform, soweit dies gesetzlich zulässig ist.
                </p>
            </li>
        </ol>
    </li>
  </ol>`,
    begründung: undefined,
    templateVariables: [
      unternehmenRegisterAmtsgericht,
      unternehmenRegisternummer,
      unternehmenFirma,
      unternehmenStammkapital,
      unternehmenGesellschafter,
      mitarbeiterbeteiligungsKgKomplementärin,
      mitarbeiterbeteiligungsKgFirma,
      mitarbeiterbeteiligungsKgSitz,
      mitarbeiterbeteiligungsKgHafteinlage,
      mitarbeiterbeteiligungsKgPflichteinlage,
      variantsPriviligiertePersonen,
      mitarbeiterbeteiligungsKgVestingperiode,
      unternehmenMonateNachFinanzierungsrunde,
    ],
  });
