import { assertNotEqual, objectMap } from "@nvon/baseline";
import { attributesToProps, Element } from "html-react-parser";
import { RVOCtaBlockCardProps } from "../../components/02-molecules/RVOCtaBlockCard/RVOCtaBlockCardInterfaces";

/**
 * For each property of the CTA Block,
 *  we provide an attribute that contains its value.
 */
const attributesForCtaBlockProps: Record<keyof RVOCtaBlockCardProps, string> = {
  backgroundImage: "data-background_image",
  subheadline: "data-subheadline",
  headline: "data-headline",
  ctaLinkTarget: "data-cta-link-target",
  ctaLinkText: "data-cta-link-text",
};

export const extractBlockCardPropsFromDomNode = (
  domNode: Element
): RVOCtaBlockCardProps => {
  const parsedHtmlAttributes = attributesToProps(domNode.attribs);

  return objectMap(attributesForCtaBlockProps, (expectedAttribute) => {
    const value = parsedHtmlAttributes[expectedAttribute];

    assertNotEqual(
      value,
      undefined,
      `Expected to find attribute ${expectedAttribute} on dom node, but didn't.`
    );

    return value;
  });
};
