import {
  adresseProkurist,
  geburtsdatumProkurist,
  nameProkurist,
  tagProkura,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageErteilungEinzelprokura: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.prokura,
  vorlage_rolleTyp: [
    RolleInGesellschaft.Geschftsleiter,
    RolleInGesellschaft.Mitglieder,
  ],
  vollzug: [Vollzug.zugang, Vollzug.handelsregisterAnmeldung],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Erteilung einer erweiterten Einzelprokura",
  tags: [
    "prokura",
    "Vollmacht",
    "handelsrechtliche Vollmacht",
    "bevollmächtigten",
    "Bevollmächtigter",
    "prokurist",
    "power of attorney",
    "procuration",
    "proxy",
    "general commercial power of representation",

    "erteilen",
    "Erteilung",
    "confer",
    "bestellen",
    "Bestellung",
  ],
  metaDescription:
    "Prokura für Einzelvertretung der Gesellschaft (inkl Befugnis zur Veräußerung und Belastung von Grundstücken). Muster/Vorlagen kostenlos ausfüllen und nutzen.",
  description: `
  <p>
    Die erweiterte Einzelprokura ermächtigt den Prokuristen auch <strong>zur Veräußerung und Belastung von Grundstücken</strong> der Gesellschaft. Eine weitere Person muss hierbei nicht mitwirken (im Gegensatz zu einer <a href="/beschluss-vorlagen?search=gesamtprokura">Gesamtprokura</a>).
  </p>

  <p>
    Die Erteilung der Prokura gegenüber dem neuen Prokuristen erfolgt durch Beschluss der Geschäftsführung (<a href="/beschluss-basics/zustaendigkeit-des-geschaeftsfuehrers-fuer-die-prokuraerteilung-gesellschafterbeschluss-fuer-das-innenverhaeltnis-bgh-beschluss-vom-14-02-01974-aktenzeichen-ii-zb-6-73">BGH</a>).
  </p>

  <p>
    Bei einer GmbH oder UG hat die Geschäftsführung zudem grundsätzlich einen zustimmenden Gesellschafterbeschluss einzuholen (<a href="/beschluss-basics/§-46-gmbhg/">§ 46 Nr. 7 GmbHG</a>), außer etwas anderes ist im Gesellschaftsvertrag bestimmt.
  </p>

  `,
  antrag: `
  <p>
    ${insertTemplateVariable(nameProkurist)},<br/>
    geboren am ${insertTemplateVariable(geburtsdatumProkurist)},<br/>
    wohnhaft in ${insertTemplateVariable(adresseProkurist)},
  </p>

  <p>
    wird mit Wirkung ab ${insertTemplateVariable(tagProkura)}</p>

  <p><strong>Einzelprokura</strong></p>

  <p>mit der Befugnis zur Veräußerung und Belastung von Grundstücken erteilt.
  </p>

    <p>
    ${insertTemplateVariable(
      nameProkurist
    )} wird angewiesen, künftig mit dem Zusatz "per prokura" oder mit der voranzustellenden Abkürzung „ppa." für die Gesellschaft zu zeichnen.
  </p>
  `,
  begründung: undefined,
  templateVariables: [
    nameProkurist,
    geburtsdatumProkurist,
    adresseProkurist,
    tagProkura,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: true,
  noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss. Die Handelsregisteranmeldung muss von den Geschäftsführern (bei einer GmbH) bzw. den persönlich haftenden Gesellschaftern (bei einer KG/OHG) in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
});
