import {
  beiratssitzungInterval,
  höheVertragsstrafe,
  vergütungProBeschlussfassung,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";
import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";
import { vorlageBeiratBestellung } from "./vorlageBeiratBestellung";
import { vorlageBeiratSchuldrechtlichDienstvertrag } from "./vorlageBeiratSchuldrechtlichDienstvertrag";

export const vorlageBeiratEinrichtenSchuldrechtlich: LegacyTemplateBase = ({
  insertTemplateVariable,
  insertVorlagenLink,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.beirat,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.zustimmungBetroffener],
  bezug: [],
  tags: [
    "Board",
    "Beirat",
    "beratender Beirat",
    "beratend",
    "Beratung",
    "Consulting",
    "Expertenbeirat",
    "schuldrechtlicher Beirat",
    "board",
    "non-executive",
    "Kuratorium",

    "Einrichtung",
    "Beirat einrichten",
    "Geschäftsordnung",
    "Beirat installieren",
  ],
  kurzBezeichnung:
    "Einrichtung eines beratenden Beirats (schuldrechtlicher Beirat)",
  metaDescription:
    "Gesellschafterbeschluss, um schuldrechtlichen Beirat (ohne Satzungsänderung) installieren. Juristisch geprüfte Muster/Vorlagen jetzt kostenlos nutzen.",
  description: `
  <p>
    Ein Muster für die Einrichtung eines Beirats mit <strong>reiner Beratungsfunktion (Expertenbeirat)</strong>. Dieser Beirat hat keine Corporate Governance-Funktion, d.h. er kann die Entscheidungen der Gesellschaft nicht durch bindende Entscheidungen beeinflußen, sondern nur durch seine inhaltliche Beratung.
  </p>

  <p>
    Deswegen genügt für einen solchen Beirat eine <strong>einfache schuldrechtliche Ausgestaltung</strong> - eine notarielle Satzungsänderung ist bei einer schuldrechtlichen Ausgestaltung nicht notwendig.
  </p>

  <p>
    Nach der Einrichtung des Beirats als Gremium ist noch die <strong>Besetzung des Beirats mit Beiratsmitgliedern</strong> erforderlich. Hierfür sind zwei Beschlüsse erforderlich:
  </p>
  <ul data-list-style="square">
    <li><p>${insertVorlagenLink(
      vorlageBeiratBestellung
    )} (Gesellschafterbeschluss)</p></li>
    <li><p>${insertVorlagenLink(
      vorlageBeiratSchuldrechtlichDienstvertrag
    )} (Beschluss der Geschäftsführung)</p></li>
  </ul>
  `,
  antrag: `
  <p>
   Ein schuldrechtlicher Beirat mit beratender Funktion wird der Geschäftsführung der Gesellschaft zur Seite gestellt.
  </p>

  <p>Neue Beiratsmitglieder werden durch Gesellschafterbeschluss unter Verpflichtung auf die “Geschäftsordnung für den Beirat” bestellt.</p>

  <p>
   Hierzu wird die folgende
  </p>

  <h2>Geschäftsordnung für den Beirat</h2>

  <p>verabschiedet:</p>

  <ol data-list-style="decimal" data-list-style-prefix="§ ">
    <li>
      <h3>Beirat, Berufung, Niederlegung</h3>
      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>
         Vorbehaltlich der nachfolgenden Regelungen werden die Mitglieder des Beirats durch Gesellschafterbeschluss bestellt und abberufen.
        </p></li>

        <li><p>
         Die Beiratsmitglieder werden unabhängig vom Datum der Bestellung jeweils mit Wirkung bis zum Ablauf eines Kalenderjahres bestellt. Werden zum jeweiligen Ablauf des Kalenderjahres unter Abberufung der amtierenden Beiratsmitglieder keine neuen Beiratsmitglieder entsprechend dieser Beiratsbestimmungen bestellt, so verlängert sich die Bestellung der amtierenden Beiratsmitglieder automatisch um ein Jahr.
        </p></li>

        <li><p>
         Jedes Beiratsmitglied ist berechtigt, das Amt unter Einhaltung einer Frist von einem Monat niederzulegen.
        </p></li>
         <li><p>
         Der Vorsitzende des Beirats wird aus dem Kreise der Beiratsmitglieder mit einfacher Stimmenmehrheit gewählt.
        </p></li>
         <li><p>
        Auf den Beirat finden weder aktienrechtlichen Vorschriften über den Aufsichtsrat noch § 52 GmbHG Anwendung.
        </p></li>
      </ol>
    </li>

    <li>
      <h3>Aufgaben und Befugnisse des Beirat, Nebentätigkeiten</h3>

      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>
          Der Beirat berät die Geschäftsführung der Gesellschaft.
        </p></li>

        <li><p>
        Bei der Wahrnehmung seiner Aufgaben arbeitet der Beirat vertrauensvoll mit der Geschäftsführung zum Wohle des Unternehmens eng zusammen.
        </p></li>

        <li><p>
        Die Beiratsmitglieder sind nicht verpflichtet, ihre ganze Arbeitskraft ausschließlich für die Gesellschaft einzusetzen. Ohne vorherige Zustimmung der Gesellschaft ist es jedoch dem Beiratsmitglied nicht gestattet, auf dem Geschäftsfeld der Gesellschaft Mitglied andere Gremien oder Organen jeglicher Art von anderen Unternehmen zu sein. Bei Zweifel über die Reichweite des Geschäftsfelds hat das Beiratsmitglied die Genehmigung der Gesellschaft einzuholen.
        </p></li>

      </ol>
    </li>

    <li><h3>Beiratsbeschlüsse (allgemeine Bestimmungen)</h3>

      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>
       Beiratsbeschlüsse können gefasst werden:
       </p>
<ul data-list-style="square">
<li><p>in Beiratssitzungen (im Präsenzverfahren oder im virtuellen Verfahren),</p></li>
<li><p>ohne Beiratssitzung,</p></li>
<li><p>daneben unter ausdrücklichen Verzicht auf sämtliche Form- und Fristerfordernisse für die Beschlussfassung, wenn die Beschlussfassung einstimmig erfolgt.</p></li>
</ul>
<p>Für die Beschlussfassung in Beiratssitzungen und ohne Beiratssitzung gelten die folgenden allgemeinen Bestimmungen.</p>
        </li>

        <li><p>Eine Beiratssitzung (im Präsenzverfahren oder im virtuellen Verfahren) wird nach Bedarf durch den Vorsitzenden einberufen. Sie muss einmal im Geschäftshalbjahr einberufen werden. Ansonsten könne Beschlussfassungen auch ohne Beiratssitzung erfolgen.</p></li>

        <li><p>Beiratsbeschlüsse werden mit einfacher Mehrheit der abgegebenen Stimmen gefasst, soweit  nicht Gesetz oder Gesellschaftsvertrag eine andere Mehrheit vorsehen.</p></li>
        <li><p>Jedes Beiratsmitglied hat eine Stimme. </p></li>
        <li><p>Eine Stimmenthaltung ist zulässig; sie zählt als Nichtabgabe der Stimme. </p></li>
        <li>
          <p>
          Eine Beschlussfassung ist nur wirksam, wenn ein Beteiligungsquorum von 75 % der amtierenden Beiratsmitglieder erreicht ist. Ein Beiratsmitglied gilt als beteiligt, wenn er
          </p>
          <ul data-list-style="square">
            <li>bei einer Beiratssitzung im Präsenzverfahren anwesend ist,</p></li>
            <li><p>bei der Beiratssitzung im virtuellen Verfahren in der Videokonferenz zugeschaltet ist,</p></li>
            <li><p>bei der Beschlussfassung ohne Beiratssitzung eine Stimme, auch im Wege der Stimmenthaltung oder nach Ablauf der Stimmfrist, abgegeben hat.</p></li>
          </ul>
          <p>
          Haben sich weniger als 75 % der amtierenden Beiratsmitglieder  an der Beschlussfassung beteiligt, ist die Beschlussfassung unverzüglich zu wiederholen (wiederholte Beschlussfassung). Im Falle einer Beiratssitzung ist eine neue Beiratssitzung mit gleicher Tagesordnung einzuberufen. Im Falle einer Beschlussfassung ohne Beiratssitzung, ist der gleiche Beschlussantrag unverzüglich erneut zur Beschlussfassung zu stellen. Die Form- und Fristvorschriften für das jeweilige Beschlussfassungverfahren sind auch bei der wiederholten Beschlussfassung zu beachten. Zur Wirksamkeit einer wiederholten Beschlussfassung muss das Beteiligungsquorum jedoch nicht erfüllt sein, wenn in der Einberufung bzw. Versendung des Beschlussantrags hierauf hingewiesen wird.
          </p>
        </li>
        <li>
          <p>
          Jedes Beiratsmitglied teilt dem Beiratsvorsitzenden eine Email-Adresse mit, auf welche Einberufungen von Beiratssitzungen  und die Versendung von Beschlussanträgen zur Beschlussfassung ohne Beiratssitzung zu erfolgen haben (mitgeteilte Email-Adresse).
          </p>
        </li>
      </ol>
    </li>

    <li>
      <h3>Beschlussfassung in einer Beiratssitzung</h3>
      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>
       Die Beiratssitzung kann entweder im virtuellen Verfahren oder im Präsenzverfahren berufen werden. Im Präsenzverfahren finden sich die Beiratsmitglieder an einem bestimmten Ort zur gemeinsamen Beschlussfassung ein. Im virtuellen Verfahren schalten sich die Beiratsmitglieder zur gemeinsamen Beschlussfassung in einer Videokonferenz zusammen; die gemeinsame Anwesenheit der Mitglieder an einem Ort ist nicht erforderlich.<p>
        </li>

        <li><p>Die Beiratssitzungen finden ${insertTemplateVariable(
          beiratssitzungInterval
        )}  statt. Weitere Beiratssitzungen finden nach Bedarf statt.<p></li>

        <li><p> Beiratssitzungen werden durch den Beiratsvorsitzenden einberufen. Auf Verlangen eines Beiratsmitgliedes hat er die Beiratssitzung einzuberufen. Kommt der Beiratsvorsitzende dem Einberufungsbegehren nicht unverzüglich nach, steht dem betreffenden Beiratsmitglied ein Selbsteinberufungsrecht zu.<p></li>

        <li><p>Die Einberufung erfolgt auf einem der folgenden Wege: Übergabeeinschreiben, Fax oder im Wege elektronischer Kommunikation an jedes Beiratsmitglied unter Angabe von Tagungsort, Tag, Zeit und Tagesordnung mit einer Frist von einer Woche. Sofern die Einberufung auf einem anderen Kommunikationswege als per Email erfolgt, ist eine Abschrift der Einberufung zusätzlich per E-Mail an die mitgeteilten Email-Adressen zu übersenden. Bei Durchführung des virtuellen Verfahrens sind die Zugangsdaten in der Einladung mitzuteilen.</p></li>

        <li><p>Mitglieder der Geschäftsführung können an Beiratssitzungen teilnehmen. Eine Begleitung oder Vertretung durch Dritte ist grundsätzlich unzulässig. </p></li>

        <li><p>Über Verhandlungen der Beiratssitzung ist eine Niederschrift binnen 7 Tagen zu erstellen, in welcher der Tag der Sitzung, Teilnehmer, Ort, sonstige Anträge und Ergebnisse sowie die gefassten Beschlüsse anzugeben sind. Die Niederschrift soll durch jedes Beiratsmitglied unterschrieben werden. Jedes Beiratsmitglied und die Geschäftsführung erhalten unverzüglich eine Abschrift der Niederschrift zugesandt.</p></li>

        <li><p>Ist die Beiratssitzung weder im virtuellen Verfahren noch im Präsenzverfahren ordnungsgemäß berufen, so können Beschlüsse nur gefasst werden, wenn sich sämtliche Beiratsmitglieder an der Sitzung beteiligen und mit der Beschlussfassung einverstanden sind.</p></li>

        </ol>
    </li>

    <li>
    <h3>Beschlussfassung ohne Beiratssitzung</h3>
      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>Initiator einer Beschlussfassung ohne Beiratssitzung kann jedes Beiratsmitglied oder die Geschäftsführung sein.</p></li>

        <li><p>
       Der Initiator stellt einen Beschlussantrag zur Abstimmung, indem er den Beschlussantrag an alle Beiratsmitglieder per Übergabeeinschreiben, Telefax oder im Wege elektronischer Kommunikation versendet. Hierbei sind der Ablauftag der Stimmfrist und der Name und die physische oder elektronische Adresse des Abstimmungsleiters anzugeben. Sofern die Versendung des Beschlussantrages auf einem anderen Kommunikationswege als per Email erfolgt, ist eine Abschrift zusätzlich per E-Mail an die mitgeteilten Email-Adressen zu übersenden.
        </p></li>
        <li><p>Eine Stimmabgabe ist wirksam erfolgt, wenn sie innerhalb der Stimmfrist beim Abstimmungsleiter eingegangen ist. Verspätete Stimmabgaben gelten als nicht abgegeben.</p></li>
        <li><p>Die Stimmfrist beträgt mindestens eine Woche und beginnt mit Absendung des zur Abstimmung gestellten Beschlussantrags. Abweichend von § 193 BGB ist es für den Ablauf der Stimmfrist ohne Auswirkung, ob der Ablauftag der Stimmfrist auf einen Sams-, Sonn- und Feiertage fällt. Am Ablauftag der Stimmfrist erfolgt der Fristablauf um 24:00 Uhr.</p></li>
        <li><p>Grundsätzlich ist der Initiator zugleich der Abstimmungsleiter. Abweichend hiervon kann der Initiator den Beiratsvorsitzenden zum Abstimmungsleiter bestimmen.</p></li>
        <li><p>Der Abstimmungsleiter hat die Stimmabgaben bis zum Ablauf der Stimmfrist entgegenzunehmen. Ferner hat er das Abstimmungsergebnis nach Ablauf der Stimmfrist festzustellen und allen Beiratsmitgliedern unter Mitteilung der einzelnen Stimmabgaben unverzüglich nach Ablauf der Stimmfrist mitzuteilen. Haben sämtliche Gesellschafter ihre Stimmen bereits vor dem Ablauf der Stimmfrist abgegeben, haben die vorbeschriebene Feststellung und Mitteilung unverzüglich nach dem Zugang der letzten Stimmabgabe beim Abstimmungsleiter zu erfolgen.</p></li>
        <li><p>Ist der Beschlussantrag nicht ordnungsgemäß zur Abstimmung gestellt, so kann der Beschluss nur gefasst werden, wenn sämtliche Beiratsmitglieder ihre Stimme abgeben und mit der Beschlussfassung einverstanden sind</p></li>
      </ol>
    </li>

    <li>
      <h3>Vergütung und Auslagenersatz</h3>
      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>
           Die Vergütung beträgt für jedes Beiratsmitglied pro kostenpflichtiger Beiratssitzung und für jeden Beiratsbeschluss ohne Beiratssitzung ${insertTemplateVariable(
             vergütungProBeschlussfassung
           )} zzgl. der gesetzlichen Umsatzsteuer (z.Zt. 19 %), sollte diese anfallen.  Eine Beiratssitzung und eine Beschlussfassung ohne Beiratssitzung gilt dann als kostenpflichtig, wenn sie nach dieser Geschäftsordnung  initiiert, durchgeführt und beendet worden ist. Mit dieser Vergütung sind sämtliche Dienste des Beiratsmitglieds für die Gesellschaft (z.B. Vorbereitungsmaßnahmen, Fortbildungs- und Weiterbildungsmaßnahmen) auch bei umfangreicher Beratung abgegolten.
        </p></li>

        <li><p>
          Dem Beiratsmitglied werden die von ihm bei dienstlichen Reisen und für Repräsentationszwecke im Interesse der Gesellschaft gemachten tatsächlichen Aufwendungen nach vorheriger Absprache ersetzt. Die Abrechnung erfolgt monatlich oder nach Anfall aufgrund solcher Belege, wie sie von den Steuerbehörden als ordnungsgemäß für die Abzugsfähigkeit von Auslagen anerkannt werden.
        </p></li>
      </ol>
    </li>

        <li>
      <h3>Wettbewerbsverbot / Kundenschutzklausel</h3>
      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>
           Es ist dem Beiratsmitglied während der Dauer seiner Mitgliedschaft im Beirat untersagt, für ein Unternehmen, das mit der Gesellschaft im Wettbewerb steht, tätig zu werden, ein solches Unternehmen zu errichten oder sich daran unmittelbar oder mittelbar zu beteiligen oder sonst entgeltlich oder unentgeltlich für ein solches Unternehmen Leistungen zu erbringen. Das Beiratsmitglied darf während dieser Zeit mit der Gesellschaft auch keine einzelnen Geschäfte im Bereich des Unternehmensgegenstands der Gesellschaft auf eigene oder fremde Rechnung tätigen.
        </p></li>

        <li><p>
         Es ist dem Beiratsmitglied für die Dauer von zwei Jahren nach der Beendigung seiner Beiratsmitgliedschaft untersagt, im Rahmen einer Tätigkeit für ein fremdes Unternehmen oder im Rahmen eines eigenen Unternehmens oder eines Unternehmens, an dem er unmittelbar oder mittelbar beteiligt ist, Geschäftsbeziehungen im Bereich des Tätigkeitsfelds oder Unternehmensgegenstands der Gesellschaft zu Personen oder Unternehmen anzubahnen, aufzunehmen oder durchzuführen, die während einer Zeitspanne von fünf Jahren vor seinem Ausscheiden Vertragspartner der Gesellschaft waren oder im Zeitpunkt des Ausscheidens Vertragspartner der Gesellschaft sind.
        </p></li>

        <li><p>Für jeden Fall eines schuldhaften Verstoßes gegen dieses Wettbewerbsverbot bzw. die Kundenschutzklausel ist das Beiratsmitglied verpflichtet, eine unter Ausschluß eines Fortsetzungszusammenhanges für jeden Fall gesondert zu zahlende Vertragsstrafe in Höhe von ${insertTemplateVariable(
          höheVertragsstrafe
        )} an die Gesellschaft zu entrichten. Die Geltendmachung weiterer Rechte der Gesellschaft, insbesondere auf Herausgabe und auf Schadenersatz, und zur – ggf. außerordentlichen – Kündigung dieses Vertrags, bleibt hiervon unberührt.</p></li>
      </ol>
    </li>

        <li>
      <h3>Geheimhaltung</h3>
      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>
            Das Beiratsmitglied verpflichtet sich zur Geheimhaltung aller Vorgänge, die ihm während seiner Tätigkeit für die Gesellschaft bekannt werden. Diese Verpflichtung dauert auch über ein Ausscheiden aus dem Beirat hinaus fort. Für jeden Fall eines schuldhaften Verstoßes gegen seine Verschwiegenheitspflicht ist das Beiratsmitglied verpflichtet, eine unter Ausschluß eines Fortsetzungszusammenhangs für jeden Fall gesondert zu zahlende Vertragsstrafe in Höhe von ${insertTemplateVariable(
              höheVertragsstrafe
            )} an die Gesellschaft zu entrichten. Die Geltendmachung weiterer Rechte der Gesellschaft, insbesondere auf Herausgabe und auf Schadenersatz, und zur – ggf. außerordentlichen – Kündigung dieses Vertrags, bleibt hiervon unberührt.
        </p></li>

        <li><p>
         Geschäftliche Unterlagen, die sich bei Beendigung der Tätigkeit im Besitz des Beiratsmitglieds befinden, sind der Gesellschaft unaufgefordert zurückzugeben.
        </p></li>

      </ol>
    </li>


    <li>
      <h3>Schlussbestimmungen</h3>

      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">

        <li><p>Der Beirat kann nur durch einen Beschluss der Gesellschafter mit einer Dreiviertelmehrheit der abgegebenen Stimmen wieder abgeschafft werden. Ebenso können alle vorstehenden Bestimmungen für den Beirat einschließlich dieser Schlußbestimmung nur mit einer Dreiviertelmehrheit der abgegebenen Stimmen  abgeändert werden.</p></li>

         <li><p>Ist ein Beiratsmitglied bestellt, so hat die Geschäftsführung mit dem jeweiligen Beiratsmitgliedern umgehend ohne weiteren Weisungsbeschluß auf der Grundlage dieses Beschlusses einen Dienstleistungsvertrag abzuschließen, der die Rechte und Pflichten der Beiratsmitglieder, insbesondere bezüglich des Ersatzes ihrer Auslagen und ihrer Vergütung, gegenüber der Gesellschaft abschließend regelt und der das Kündigungsrecht der Geschäftsführung ausschließt. Die Gesellschafter, welche das Beiratsmitglied bestellt haben, sind verpflichtet, die Bestellung unverzüglich der Geschäftsführung anzuzeigen.</p></li>

        <li><p>Alle jetzigen und zukünftigen Mitglieder der Geschäftsführung sind über den Inhalt dieser Geschäftsordnung in Kenntnis zusetzen und darauf zu verpflichten, die Bestimmungen der Geschäftsordnung zu beachten un die ihm darin gewährten Rechte und übertragenen Pflichten als eigene anzuerkennen und zum Wohl der Gesellschaft wahrzunehmen.</p></li>
      </ol>
    </li>


  </ol>`,
  begründung: undefined,
  templateVariables: [
    beiratssitzungInterval,
    vergütungProBeschlussfassung,
    höheVertragsstrafe,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
