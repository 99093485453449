import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { Button } from "gatsby-material-ui-components";
import { useTranslation } from "react-i18next";
import { useRVOModalContext } from "../../00-globals/RVOModalContextProvider/RVOModalContextProvider";
import { useConsentData } from "../../00-globals/TruendoCookieConsent/TruendoConsentProvider";
import { useRVOAskUsInTheChatStyles } from "./RVOAskUsInTheChatStyles";

interface AskUsProps {
  additionalOnClick?: VoidFunction;
}

const RVOAskUsInTheChat = (props: AskUsProps): JSX.Element => {
  const classes = useRVOAskUsInTheChatStyles();
  const brevoChatAccepted = useConsentData().preferences;
  const { showChatCookieHint } = useRVOModalContext();

  const { t } = useTranslation();

  const startChat = () => {
    if (window.BrevoConversations && brevoChatAccepted) {
      window.BrevoConversations("openChat", true);
      return;
    }
    showChatCookieHint();
  };
  return (
    <Box className={classes.wrapper}>
      <Grid container={true} spacing={2} direction="column">
        <Grid item={true}>
          <Divider />
        </Grid>

        <Grid item={true}>
          <Typography variant="body1">{t("askUs.happyToHelp")}</Typography>
        </Grid>

        <Grid item={true}>
          <Button
            variant="outlined"
            onClick={() => {
              props.additionalOnClick?.();
              startChat();
            }}
          >
            {t("askUs.startChat")}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RVOAskUsInTheChat;
