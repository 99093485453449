import {
  geschäftsanteileNennbetragGesamt,
  geschäftsanteileNummer,
  klauselAbfindung,
  klauselEinziehung,
  nameGesellschafter,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGesellschafterEinziehung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
  vorlageKategorie: VorlageKategorie.ausscheidenEinesGesellschafters,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [
    Vollzug.zugang,
    Vollzug.zustimmungGesellschafter,
    Vollzug.handelsregisterGesellschafterliste,
    Vollzug.transparenzregister,
  ],
  bezug: [
    "GesellschafterEinziehungRevalorisierung",
    "GesellschafterEinziehungAufstockung",
  ],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Einziehung mit Zustimmung des betroffenen Gesellschafters",
  description: undefined,
  antrag: `<ol>
  <li><p>Der/die Geschäftsanteil(e) von ${insertTemplateVariable(
    nameGesellschafter
  )} mit laufender Nr. ${insertTemplateVariable(
    geschäftsanteileNummer
  )}  und einem Gesamt-Nennbetrag von ${insertTemplateVariable(
    geschäftsanteileNennbetragGesamt
  )} werden gemäß § ${insertTemplateVariable(
    klauselEinziehung
  )} des Gesellschaftsvertrags eingezogen. Die Geschäftsführung wird angewiesen, ${insertTemplateVariable(
    nameGesellschafter
  )} die Einziehung schriftlich und unter Beifügung dieses Einziehungsbeschlusses mitzuteilen.</p></li>

    <li><p>Die Geschäftsführung wird angewiesen, eine aktualisierte Gesellschafterliste gemäß § 40 Abs. 1 GmbHG zum Handelsregister einzureichen und eine Meldepflicht der Gesellschaft zum Transparenzregister zu prüfen und ggf. zu erfüllen.</p></li>

  <li><p>Die Gesellschaft hat für den eingezogenen Geschäftsanteil nach § ${insertTemplateVariable(
    klauselEinziehung
  )} des Gesellschaftsvertrags eine Abfindung in folgender Höhe zu zahlen: ${insertTemplateVariable(
    klauselAbfindung
  )}. Die Geschäftsführung wird angewiesen, die Abfindung zu ermitteln.</p></li>
</ol>

  <p>Begründung:</p>
  <p>Die Voraussetzung einer Einziehung liegen vor:</p>
  <ul><li><p>Die eingezogenen Geschäftsanteile sind voll eingezahlt.</p></li>
  <li><p>Die Zahlung der Abfindung kann aus dem über die Stammkapitalziffer hinaus vorhandenen Reinvermögen der Gesellschaft erfolgen, sodass keine Unterbilanz entsteht.</p></li>
  <li><p>Die Einziehung ist in der Satzung auch gemäß § 34 Abs. 1 GmbHG generell zugelassen.</p></li>
  <li><p> Der betroffene Gesellschafter ${insertTemplateVariable(
    nameGesellschafter
  )} stimmt der Einziehung zu.</p></li></ul>`,
  begründung: undefined,
  templateVariables: [
    nameGesellschafter,
    geschäftsanteileNummer,
    geschäftsanteileNennbetragGesamt,
    klauselEinziehung,
    klauselAbfindung,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: true,
  noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass aufgrund des geänderten Gesellschafterbestandes eine aktualisierte Gesellschafterliste zum Handelsregister durch die Geschäftsführer eingereicht werden muss (§ 40 Abs. 1 GmbHG). Die neue Gesellschafterliste muss von den Geschäftsführern in vertretungsberechtigter Zahl unterschrieben und über einen Notar zum Handelsregister eingereicht werden.</p>`,
});
