import twemoji from "twemoji";

const twemojiDefaults: Partial<twemoji.ParseObject> = {
  base: "/",
  folder: "svg",
  ext: ".svg",
};

/**
 * Interface taken from Twemoji package
 */
interface TwemojiOptions {
  base?: string;
  ext?: string;
  className?: string;
  size?: string | number;
  folder?: string;
  callback?(icon: string, options: TwemojiOptions): void;
  attributes?(): void;
}

export const replaceEmojis = (html: string | undefined): string | void => {
  // The named import causes a weird webpack error 🤷‍♂️
  // eslint-disable-next-line import/no-named-as-default-member
  return twemoji.parse(html || "", twemojiDefaults as TwemojiOptions);
};
