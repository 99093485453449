import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { toggle } from "./toggle";

/**
 * A wrapper for react's default useState, adding:
 *  - an additional toggle function
 *  - a () => set(true)
 *  - a () => set(false)
 *
 * Currently requires an initial state to be passed.
 */
export const useBooleanState = (
  initialState: boolean | (() => boolean)
): {
  state: boolean;
  on: VoidFunction;
  off: VoidFunction;
  toggle: VoidFunction;
  set: Dispatch<SetStateAction<boolean>>;
} => {
  const [state, stateSetter] = useState<boolean>(initialState);

  const toggleFunction = useCallback(() => stateSetter(toggle), [stateSetter]);
  const enableFunction = useCallback(() => stateSetter(true), [stateSetter]);
  const disableFunction = useCallback(() => stateSetter(false), [stateSetter]);

  return {
    state,
    on: enableFunction,
    off: disableFunction,
    toggle: toggleFunction,
    set: stateSetter,
  };
};
