import { nameGeschäftsführer } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGeschaftsfuhrerAnstellungsvertragKundigungOrdentlich: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.geschäftsführerAusscheiden,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.zugang],
    bezug: [
      "GeschäftsführerAbberufung",
      "GeschäftsführerEntlastungErteilung",
      "GeschäftsführerEntlastungVerweigerung",
      "GeschäftsführerGeltendmachungSchadenersatzansprüche",
    ],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung: "Ordentliche Kündigung des Geschäftsführers",
    metaDescription:
      "Gesellschafterbeschluss, um Geschäftsführer ordentlich mit Kündigungsfrist zu kündigen. Muster/Vorlagen von Rechtsanwälten kostenlos ausfüllen und downloaden.",
    description: `<p>
      Für die Kündigung des Geschäftsführers sind grundsätzlich die Geschäftsführer zuständig [§ 46 Nr. 5 GmbHG], außer der Gsellschaftsvertrag bestimmt etwas anderes oder es handelt sich um eine mitbestimmte GmbH.
    </p>
        <p>
      Der Beschluss muss dem Geschäftsführer zugehen, damit die Kündigung wirksam werden kann.
    </p>`,
    antrag: `<p>Das mit ${insertTemplateVariable(
      nameGeschäftsführer
    )} bestehende Anstellungsverhältnis wird ordentlich zum nächstzulässigen Termin gekündigt.</p>`,
    begründung: undefined,
    templateVariables: [nameGeschäftsführer],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
