import { nameKomplementärGmbH } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageEntlastungRegelmassigKomplementarGmbH: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [
      CompanyTypeName.GmbHCoKgDe,
      CompanyTypeName.UgCoKgDe,
    ],
    vorlageKategorie: VorlageKategorie.regelmäßigerBeschluss,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.zugang],
    bezug: [
      "EntlastungRegelmäßigAufsichtsrat",
      "EntlastungKomplementärRegelmäßig",
      "EntlastungRegelmäßigGeschäftsführenderPartner",
      "EntlastungRegelmäßigBeirat",
      "EntlastungRegelmäßigGeschäftsführenderGesellschafter",
      "EntlastungRegelmäßigGeschäftsführer",
      "EntlastungRegelmäßigKomplementärGmbH",
      "AbschlussprüferBestellung",
      "JahresabschlussAufstellung",
      "JahresabschlussFeststellung",
      "JahresplanungFeststellung",
      "VerwendungDesGewinns",
    ],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung: "Entlastung der Komplementär-GmbH",
    description: undefined,
    antrag: `${insertTemplateVariable(
      nameKomplementärGmbH
    )} wird als Komplementärin Entlastung erteilt.`,
    begründung: undefined,
    templateVariables: [nameKomplementärGmbH],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
