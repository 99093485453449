import { HtmlSanitizeOptions } from "../../hooks/useSanitizedHtml/UseSanitizedHtmlInterface";
import {
  templateVariableHtmlTag,
  vorlagenPickerLinkTag,
} from "./templates/insertTemplateVariable";

const defaultOptions: HtmlSanitizeOptions = {
  ADD_TAGS: [templateVariableHtmlTag, vorlagenPickerLinkTag],
  USE_PROFILES: {
    html: true,
  },
};

export const sanitizeHtml = (
  html: string,
  sanitizeOptions?: HtmlSanitizeOptions
): string => {
  // TODO: Sanitize !! 🙀

  const mergedOptions = { ...defaultOptions, sanitizeOptions };
  void mergedOptions;

  return html;
};
