import {
  eckpunkte,
  nameGeschäftsleiter,
  vertragBezeichnung,
  vertragPartner,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageWeisungVertragsabschlussEckpunkte: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.geschäftsführer,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.zugang],
  bezug: [],
  zustimmungErforderlichDes: undefined,
  tags: [
    "Vertrag",
    "Kontrakt",
    "Einigung",
    "Deal",
    "contract",
    "Abschluss",
    "Vertragsschluss",
    "Vertragsabschluss",
    "Kauf",
    "Miet",
    "Schenk",
    "Auftrag",
    "Vergabe",
    "Order",
    "Bestellung",
    "bestellen",

    "Weisung",
    "Anweisung",
    "Anordnung",
    "Befehl",
    "weisen",
    "anweisen",
    "anordnen",
    "Instruktion",
    "instruieren",
    "instruction",
    "instruct",
    "order",
    "command",
    "befolgen",
    "obey",

    "Entscheider",
    "Entscheidungsträger",
    "Direktor",
    "Director",
    "Vorstand",
    "Vorsitzender",
    "Führung",
    "Leitung",
    "Leiter",
    "Geschäftsführung",
    "Geschäftsleitung",
    "Geschäftsleiter",
    "Chef",
    "Leiter",
    "Vorgesetzter",
    "Führungskraft",
    "Geschäftsführer",
    "Manager",
    "CEO",
    "CTO",
    "CFO",
    "leader",
    "head",
    "chief",
    "executive",
    "boss",
    "governor",
    "governance",
  ],
  kurzBezeichnung:
    "Weisung an Geschäftsführung: Abschluss eines Vertrags (Eckpunkte)",
  metaDescription:
    "Geschäftsführer anweisen/beauftragen, Vertrag mit bestimmten Vertragsbedingungen abzuschließen. Juristisch geprüfte Muster/Vorlagen kostenlos downloaden.",
  description: `
     <p>
  Wenn einem Geschäftsführer für einen Vertragsabschluss <strong>Eckpunkte</strong> vorgegeben werden sollen, ist diese Vorlage genau das Richtige für dich!  Mit einer Weisung können Gesellschafter unmittelbar Einfluss auf die Tätigkeit der Geschäftsführung nehmen.
  </p>

    <p>
    Wenn du die Weisung noch konkreter fassen möchtest, solltest du dem Geschäftsführer ein konkretes Angebot oder einen konkreten Vertragsentwurf als Anlage an die Hand geben. Hierfür haben wir die Vorlage <a href="/beschluss-vorlagen/weisung-zum-abschluss-eines-vertrags-(mit-anlage)">"Weisung zum Abschluss eines Vertrags (<strong>mit Anlage</strong>)"</a> vorbereitet.
    </p>

    <p>
    Wir bieten dir mit der <a href="/beschluss-vorlagen/leere-weisung-(freitext)">"Weisung (Freitext)"-Vorlage</a> auch eine Möglichkeit, die Weisung frei zu formulieren.
    </p>
    `,
  antrag: `
  <p>
    ${insertTemplateVariable(nameGeschäftsleiter)} wird angewiesen,
  </p>

  <p>
    <ul data-list-style="none">
      <li><p>
      einen ${insertTemplateVariable(vertragBezeichnung)} mit
      ${insertTemplateVariable(vertragPartner)}
      </p></li>
    </ul>
  </p>

  <p>
  im Namen der und mit Wirkung für die Gesellschaft abzuschließen.
  </p>

  <p>
    Der abzuschließende ${insertTemplateVariable(
      vertragBezeichnung
    )} muss die folgenden Eckpunkte erfüllen:
  </p>


   <ul data-list-style="none">
      <li><p>
     ${insertTemplateVariable(eckpunkte)}
      </p></li>
   </ul>

   <p>
    Zum Abschluss des vorbezeichneten Rechtsgeschäfts wird zugleich Befreiung von den Beschränkungen des § 181 BGB erteilt, soweit erforderlich.
  </p>

  `,
  begründung: undefined,
  templateVariables: [
    nameGeschäftsleiter,
    vertragBezeichnung,
    vertragPartner,
    eckpunkte,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
