import { anteilEinzahlung } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGesellschafterEinforderungAusstehendeEinlagen: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.kapital,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.zugang],
    bezug: [],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung: "Einforderung von ausstehenden Bareinlagen",
    description: undefined,
    antrag: `
      <ol>

        <li><p> Die Gesellschafter haben auf die von ihnen bei der Gründung der Gesellschaft übernommenen Geschäftsanteile bislang jeweils ${insertTemplateVariable(
          anteilEinzahlung
        )} der geschuldeten Einlage in bar eingezahlt.</p></li>

        <li><p>Die Geschäftsführung wird angewiesen, von den Gesellschaftern den ausstehenden Einlagenanteil unverzüglich einzufordern.</p></li>

      </ol>`,
    begründung: undefined,
    templateVariables: [anteilEinzahlung],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
