import { unternehmenFirmaSocialPolicy } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageSocialMediaPolicy: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: Object.values(CompanyTypeName),
  vorlageKategorie: VorlageKategorie.mitarbeiter,
  vorlage_rolleTyp: [
    RolleInGesellschaft.Geschftsleiter,
    RolleInGesellschaft.Mitglieder,
  ],
  vollzug: [Vollzug.selfexecuting],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Social Media Policy / Guidelines",
  tags: [
    "social media guidelines",
    "social media policy",
    "Soziale Medien",
    "Richtlinie",
    "Guidelines",
    "Leitfaden",
    "LinkedIn",
    "Xing",
    "YouTube",
    "Facebook",
    "Instragram",
    "Threads",
    "X(Twitter)",
    "TikTok",
  ],
  metaDescription:
    "Vorlage für Beschlüss über Social Media Policy im Unternehmen (Geschäftsführung oder Gesellschafterkreis). Muster/Vorlagen kostenlos ausfüllen und nutzen.",
  description: `
     <p>
        Diese Beschlussvorlage enthält einen beispielhaften Text für eine Social Media Policy bzw. Guidelines. Social Media Policies bzw. Guidelines sind  Richtlinien für Mitarbeiter und leitende Angestellte, die den Umgang mit sozialen Medien im Unternehmen festlegen sollen.
    </p>
    <p>
        Die Entscheidung zwischen informierender Richtlinie (Guidelines) oder verpflichtendem Regelwerk (Policy) hängt dabei ganz wesentlich von der Unternehmenskultur ab.
    </p>
    <p>
        Social Media Policies bzw. Guidelines stellen dabei keinesfalls ein "Maulkorb" für Mitarbeiter dar. Ein Verbot von freien Meinungsäußerungen ist in der Regel ohnehin nicht durchsetzbar. Es geht vielmehr darum, den Mitarbeitern konkrete Leitlinien an die Hand zu geben, die den Umgang mit Social Media im beruflichen Kontext leichter machen.
    </p>
    <h3>
        Warum sind Social Media Policy bzw. Guidelines wichtig?
    </h3>
    <p>
        Die folgenden 3 Aspekte sind für die Einführung eines Social Media Policy bzw. Guidelines in der Regel entscheidend:
    </p>
    <ul>
        <li>
            <p>
                <strong>Reputationsschutz:</strong> Eine Social Media Policy schützt das Unternehmen vor negativen Auswirkungen durch unbedachte Äußerungen in sozialen Medien. Sie legt fest, wie Mitarbeiter das Unternehmen repräsentieren sollen. Durch die Festlegung von Verhaltensregeln minimiert die Social Media Policy das Risiko von Rechtsverletzungen, Rufschädigung oder Datenschutzverstößen.
            </p>
        </li>
        <li>
            <p>
            <strong>Vertraulichkeit:</strong> Die Policy definiert, welche Informationen über das Unternehmen, Kunden oder Mitarbeiter vertraulich sind und nicht in sozialen Medien geteilt werden dürfen.
            </p>
        </li>
        <li>
            <p>
                <strong>Transaprenz/klare Regeln:</strong> Mitarbeiter erhalten klare Anweisungen, wie sie sich in sozialen Netzwerken verhalten sollen. Dies fördert ein einheitliches und professionelles Auftreten.
            </p>
        </li>
    </ul>
    <h3>
       Zuständigkeit für die Festlegung der Social Media Policy als Beschluss
    </h3>
    <p>
        Für die Verabschiedung von Social Media Guidelines bzw. Policy ist ein Bescchluss der Geschäftsführung. D.h. bei einer GmbH bzw. UG wird der Beschluss durch die Mitglieder der Geschäftsüfhrung gefasst, bei einer Aktiengesellschaft (AG) durch die Vorstandsmitglieder.
    </p>
    <p>
        Auch ein Betriebsrat vorhanden ist, ist ein Abschluss einer Betriebsvereinbarung in der Regel nicht notwendig, soweit keine neuen Verpflichtungen für die Mitarbeiter begründet werden sollen. Die Beteiligung des Betriebsrats im Vorfeld der Beschlussfassung durch die Geschäftsführung kann jedoch sinnvoll sein und  die allgemeine Akzeptanz im Unternehmen begünstigen.
    </p>

  `,
  antrag: `
<p>Folgende</p>
<h2>
    Social Media Policy
</h2>
<p>
    wird für die Mitarbeiter des Unternehmens ${insertTemplateVariable(
      unternehmenFirmaSocialPolicy
    )} erlassen:
</p>
<ol data-list-style="decimal" data-list-style-prefix="§">
    <li>
        <h3>
            Vorbemerkung
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Jeder Mitarbeiter hat auch in Bezug auf persönlich gestaltete Beiträge in den Sozialen Medien jederzeit die <strong>Verschwiegenheit und Vertraulichkeit</strong> zu wahren, zu der sie oder er sich im Rahmen seines/ihres Arbeitsvertrages verpflichtet hat. Darüber hinaus sollte jeder Mitarbeiter <strong>sensibel mit eigenen Daten</strong> umgehen.
                </p>
            </li>
            <li>
                <p>
                    Die folgende Social Media Policy bietet einen Leitfaden, um die Nutzung von Social Media in Verbindung mit dem Unternehmen zu erleichtern:
                </p>
            </li>
        </ol>
    </li>
    <li>
        <h3>
            Geltungsbereich der Social Media Policy
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Die Social Media Policy richtet sich an
                </p>
                <ol data-list-style="lower-latin">
                    <li>
                        <p>
                            sämtliche Führungskräfte, Arbeitnehmer, freie Mitarbeiter und Leiharbeitnehmer (hier gemeinsam auch <strong>„Mitarbeiter“</strong> genannt), die für das Unternehmen  ${insertTemplateVariable(
                              unternehmenFirmaSocialPolicy
                            )} auf Social Media aktiv sind,
                        </p>
                    </li>
                    <li>
                        <p>
                            alle Mitarbeiter, die auf ihren privaten Social-Media-Accounts erkennbar in ihrer Eigenschaft als Führungskraft oder als Mitarbeiter des Unternehmens ${insertTemplateVariable(
                              unternehmenFirmaSocialPolicy
                            )} nach außen treten.
                        </p>
                    </li>
                </ol>
            </li>
            <li>
                <p>
                    Mit Social Media sind alle digitalen Technologien und Medien gemeint, über die Nutzer miteinander kommunizieren und Inhalte austauschen können, insbesondere
                </p>
                <ul>
                    <li>
                        <p>
                            soziale Netzwerke, wie z.B. LinkedIn, Xing, YouTube, Facebook, Instragram, Threads, X(Twitter) oder TikTok,
                        </p>
                    </li>
                    <li>
                        <p>
                            Bewertungsportale, wie z.B. Yelp, kununu, Jameda, Google Bewertungen,
                        </p>
                    </li>
                    <li>
                        <p>
                            Videoplattformen, wie z.B. Youtube,
                        </p>
                    </li>
                    <li>
                        <p>
                            Wikis, wie z.B. Wikipedia,
                        </p>
                    </li>
                    <li>
                        <p>
                            Webblogs, wie z.B. Wordpress-Blogs.
                        </p>
                    </li>
                </ul>
            </li>
        </ol>
    </li>
    <li>
        <h3>
            Eigenverantwortung
        </h3>
        <p>
            Sie sind und bleiben zu jeder Zeit verantwortlich für das, was Sie im Internet veröffentlichen - ob beruflich oder privat. Denken Sie daran, dass etwas, das einmal im Internet veröffentlicht wurde, nicht so schnell wieder verschwindet. Bewahren Sie stets Ihre Privatsphäre. Machen Sie sich mit den datenschutzrelevanten Funktionen und Einstellungen auf den von Ihnen genutzten Plattformen vertraut und passen Sie diese so an, dass ein Missbrauch Ihrer Daten erschwert wird. Lassen Sie sich in Bezug auf Beiträge, die das Unternehmen betreffen, nicht provozieren. Unternehmensbezogene Konflikte gehören nicht in die Öffentlichkeit und sollten nicht in sozialen Medien ausgetragen werden.
        </p>
    </li>
    <li>
        <h3>
            Autorisierung
        </h3>
        <p>
            Für eine erfolgreiche Social Media Kommunikation ist es notwendig, dass das Unternehmen die Verantwortlichkeiten für die Social Media Kanäle und die veröffentlichten Inhalte festlegt. Im Namen des Unternehmens dürfen nur Mitarbeiter kommunizieren, die ausdrücklich autorisiert sind, das Unternehmen in den sozialen Medien zu vertreten. Andernfalls ist eine solche Kommunikation nicht erlaubt und wird vom Unternehmen verfolgt und sanktioniert.
        </p>
    </li>
    <li>
        <h3>
            Kennzeichnung persönlicher Meinungen
        </h3>
        <p>
            Wenn Sie eine persönliche Meinung veröffentlichen möchten, kennzeichnen Sie diese bitte deutlich als solche. Machen Sie deutlich, dass es sich um Ihre persönliche Meinung und nicht um die des Unternehmens handelt. Verwenden Sie klarstellende Formulierungen wie: <i>"Dies ist mein privates Benutzerkonto"</i> oder <i>"Die hier veröffentlichten Beiträge geben meine persönliche Meinung wieder und stellen nicht die Position meines Unternehmens/Arbeitgebers dar".</i> Sprechen Sie nicht im Namen des Unternehmens, es sei denn, Sie wurden vorher ausdrücklich dazu autorisiert.
        </p>
    </li>
    <li>
        <h3>
        Einhaltung rechtlicher Anforderungen
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Bei Inhalten auf Social-Media-Kanälen müssen Sie immer die rechtlichen Vorgaben beachten. Zum Beispiel dürfen Sie keine Fotos von Personen veröffentlichen, ohne deren Zustimmung einzuholen. Wenn Sie sich nicht sicher sind, ob Sie damit gegen rechtliche Bestimmungen verstoßen, sollten Sie es lieber lassen.
                </p>
            </li>
            <li>
                <p>
                    Die Rechte an selbst erstellten Inhalten liegen regelmäßig beim Urheber. Wurden sie jedoch im Rahmen Ihres Arbeitsverhältnisses erstellt, können die Rechte bei Ihrem Arbeitgeber liegen. Wenn Sie extern erstellte Inhalte veröffentlichen, sollten Sie sich bewusst sein, dass diese in der Regel dem Urheberrecht unterliegen. In beiden Fällen sollten Sie daher darauf achten, dass Sie die geltenden Rechte einhalten und nicht unbefugt in die Rechte Dritter eingreifen.
                </p>
            </li>
            <li>
                <p>
                    Verzichten Sie außerdem auf Inhalte mit Haftungsgefahr - also Inhalte, die Sie ohne Klärung rechtlicher Vorfragen möglicherweise nicht veröffentlichen dürfen - und lassen Sie sich eine Veröffentlichung gegebenenfalls vorab vertraglich genehmigen. Nutzen Sie gegebenenfalls das Zitatrecht: Kennzeichnen Sie also immer, wenn es sich bei den von Ihnen veröffentlichten Beiträgen um Inhalte Dritter handelt.
                </p>
            </li>
            <li>
                <p>
                    Sollte es doch einmal versehentlich zu einer Urheberrechtsverletzung von Dritten kommen, entfernen Sie die entsprechenden Inhalte umgehend und – soweit von Ihnen technisch umsetzbar- vollständig.
                </p>
            </li>
        </ol>
    </li>
    <li>
        <h3>
        Vermeidung typischer rechtlicher „Fallstricke“
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    <strong>Datenschutz:</strong> Achten Sie darauf, dass Sie keine personenbezogenen Daten im Internet veröffentlichen, ohne dass die Betroffenen zugestimmt haben.
                </p>
            </li>
            <li>
                <p>
                    <strong>Urheberrecht:</strong> Bilder, Videos, Texte, Musikdateien und Vorschaubilder in Links können Urheberrechte verletzen. Das gilt auch für Text- und Bildmaterial des Unternehmens. Im Zweifelsfall fragen Sie nach und klären Sie, ob Sie die Bilder und Videos verwenden dürfen.
                </p>
            </li>
            <li>
                <p>
                    <strong>Persönlichkeitsrechte:</strong> Wenn Personen auf Fotos und Videos zu sehen sind, müssen Sie ihre Zustimmung einholen, bevor Sie die Bilder und Videos veröffentlichen.
                </p>
            </li>
            <li>
                <p>
                    <strong>Verbreitung rechtswidriger Aussagen:</strong> Verbreiten Sie keine Unwahrheiten, Beleidigungen, Schmähkritik und andere rechtswidrige Äußerungen. Das kann zu Abmahnungen und Schadenersatzansprüchen führen. Recherchieren Sie gründlich, bevor Sie sich kritisch mit einem Thema auseinandersetzen.
                </p>
            </li>
            <li>
                <p>
                    <strong>Plattformspezifische Regelungen:</strong> Zu beachten sind zudem die jeweils geltenden Bestimmungen der einzelnen Social Media Plattformen. Ein Verstoß gegen diese kann nicht nur mit der Löschung des Profils geahndet werden, sondern auch rechtliche Konsequenzen haben.
                </p>
            </li>
        </ol>
    </li>
    <li>
        <h3>
            Vertrauliche Informationen und Geschäftsgeheimnisse
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Geschäftsgeheimnisse und vertrauliche Informationen des Unternehmens dürfen nicht weitergegeben werden. Bitte teilen sie nichts mit, was Sie noch nicht in einer Pressemitteilung oder im Internet gefunden haben.
                </p>
            </li>
            <li>
                <p>
                    Auch Details, interne Themen, Prozesse oder Projekte sind vertraulich, bei denen ein betriebliches Interesse daran besteht, diese noch nicht zu veröffentlichen. Wenn Sie sich unsicher sind, können Sie sich einfach bei der zuständigen Stelle im Unternehmen erkundigen, ob Bedenken gegen eine Veröffentlichung bestehen.
                </p>
            </li>
        </ol>
    </li>
    <li>
        <h3>
            Umgang mit Kritik, Vermeidung unternehmensschädlicher Äußerungen
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Wenn Sie Kritik am Unternehmen nutzen wollen, nutzen Sie unsere internen Whistleblower-Kanäle. Wir sind für kritische Äußerungen dankbar und werden diese nutzen, um interne Missstände zu beseitigen.
                </p>
            </li>
            <li>
                <p>
                    Vermeiden Sie jedoch, Kritik am Unternehmen, Kunden oder Partnern öffentlich über Social Media zu äußern. Dies kann den wirtschaftlichen Erfolg Ihres Arbeitgebers schaden – und damit auch Ihnen.
                </p>
            </li>
            <li>
                <p>
                    Bitte beachten Sie auf Social Media die Regeln des Anstands. Beleidigungen, Obszönitäten oder Verunglimpfungen sind unakzeptaben und sollten unterbleiben.
                </p>
            </li>
            <li>
                <p>
                    Ihre Meinung ist uns wichtig. Wenn Sie einen Beitrag entdecken, der Ihrer Meinung nach schädlich für das Unternehmen sein könnte, teilen Sie uns dies bitte mit. Wir werden jede Meldung sorgfältig prüfen und alle notwendigen Schritte unternehmen oder Sie dabei unterstützen, angemessen zu reagieren, wenn Sie in Ihrer Eigenschaft als Mitarbeiter persönlich betroffen sind.
                </p>
            </li>
        </ol>
    </li>
</ol>
  `,
  begründung: undefined,
  templateVariables: [unternehmenFirmaSocialPolicy],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: true,
  noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss. Die Handelsregisteranmeldung muss von den Geschäftsführern (bei einer GmbH) bzw. den persönlich haftenden Gesellschaftern (bei einer KG/OHG) in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
});
