import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { Vorlage } from "../../common-interfaces/VorlageInterface";
import { TemplateUserInput } from "../../components/04-templates/BeschlussGenerator/BeschlussGeneratorInterfaces";
import { LegacyTemplateLandingpage } from "../../helpers/createVorlageLandingpage";
import { useAppDispatch, useAppSelector } from "../hooks";
import { RootState } from "../storeInterfaces";
import { initialUserInputState } from "./initialUserInputState";
import { stateBeschlussVorlageEmpty } from "./states/stateBeschlussVorlageEmpty";

export interface BeschlussVorlageState {
  currentVorlage: LegacyTemplateLandingpage | null;
  userInputForAllVorlagen: Record<Vorlage["id"], TemplateUserInput>;
  showVorlagenPicker: boolean;
}

export interface BeschlussUserInput {
  key: keyof TemplateUserInput;
  value: TemplateUserInput[string];
}

export const beschlussVorlageSlice = createSlice({
  name: "beschlussVorlage",
  initialState: stateBeschlussVorlageEmpty,
  reducers: {
    setCurrentVorlage: (
      state,
      action: PayloadAction<BeschlussVorlageState["currentVorlage"]>
    ) => {
      const vorlage = action.payload;

      // When this vorlage is set for the first time, create the initial empty input object.
      if (vorlage && !state.userInputForAllVorlagen[vorlage.id]) {
        state.userInputForAllVorlagen[vorlage.id] = initialUserInputState(
          vorlage.templateVariables
        );
      }

      state.currentVorlage = action.payload;
    },
    setShowVorlagenPicker: (state, action: PayloadAction<boolean>) => {
      state.showVorlagenPicker = action.payload;
    },
    setUserInputOnCurrentVorlage: (
      state,
      action: PayloadAction<BeschlussUserInput>
    ) => {
      const { key, value } = action.payload;
      const vorlage = state.currentVorlage;

      if (!vorlage) {
        throw Error(
          `Tried to set user input without having a vorlage selected.`
        );
      }

      const stateFromVorlage = state.userInputForAllVorlagen[vorlage.id];

      if (stateFromVorlage) {
        stateFromVorlage[key] = value;
      }
    },
  },
});

// Actions …

export const {
  setCurrentVorlage: setCurrentVorlageUserAction,
  setUserInputOnCurrentVorlage: setUserInputOnCurrentVorlageUserAction,
  setShowVorlagenPicker: setShowVorlagenPickerUserAction,
} = beschlussVorlageSlice.actions;

// … and hooks to use the actions

export const useSetCurrentVorlage = (): ((
  currentVorlage: BeschlussVorlageState["currentVorlage"]
) => void) => {
  const dispatcher = useAppDispatch();
  return (currentVorlage) =>
    dispatcher(setCurrentVorlageUserAction(currentVorlage));
};

export const useSetShowVorlagenPicker = (): ((show: boolean) => void) => {
  const dispatcher = useAppDispatch();
  return useCallback(
    (show) => dispatcher(setShowVorlagenPickerUserAction(show)),
    [dispatcher]
  );
};

export const useSetUserInputOnCurrentVorlage = (): ((
  key: BeschlussUserInput["key"],
  value: BeschlussUserInput["value"]
) => void) => {
  const dispatcher = useAppDispatch();
  return (key, value) =>
    dispatcher(
      setUserInputOnCurrentVorlageUserAction({
        key,
        value,
      })
    );
};

// Selectors …

export const selectCurrentVorlage = (
  state: RootState
): BeschlussVorlageState["currentVorlage"] =>
  state.beschlussVorlage.currentVorlage;

export const selectShowVorlagenPicker = (state: RootState): boolean =>
  state.beschlussVorlage.showVorlagenPicker;

export const selectUserInputOnCurrentVorlageOrThrow = (
  state: RootState
): TemplateUserInput => {
  const id = state.beschlussVorlage.currentVorlage?.id;
  if (!id) {
    throw Error(
      `Tried to access user input without having a vorlage selected.`
    );
  }
  return state.beschlussVorlage.userInputForAllVorlagen[
    id
  ] as TemplateUserInput;
};

export const selectUserInputOnCurrentVorlage = (
  state: RootState
): TemplateUserInput | null => {
  const id = state.beschlussVorlage.currentVorlage?.id;

  if (!id) {
    return null;
  }

  return state.beschlussVorlage.userInputForAllVorlagen[
    id
  ] as TemplateUserInput;
};

// … and hooks to get the data from the selectors

export const useCurrentVorlage = (): ReturnType<typeof selectCurrentVorlage> =>
  useAppSelector(selectCurrentVorlage);

export const useShowVorlagenPicker = (): ReturnType<
  typeof selectShowVorlagenPicker
> => useAppSelector(selectShowVorlagenPicker);

export const useUserInputOnCurrentVorlageOrThrow = (): ReturnType<
  typeof selectUserInputOnCurrentVorlageOrThrow
> => useAppSelector(selectUserInputOnCurrentVorlageOrThrow);

export const useUserInputOnCurrentVorlage = (): ReturnType<
  typeof selectUserInputOnCurrentVorlage
> => useAppSelector(selectUserInputOnCurrentVorlage);

/**
 * Same as useCurrentVorlage, but throws an error if there is no Vorlage selected.
 */
export const useCurrentVorlageOrThrow = (): LegacyTemplateLandingpage => {
  const vorlage = useCurrentVorlage();
  if (!vorlage) {
    throw Error(`Expected to have a currentVorlage in store, but didn't.`);
  }
  return vorlage;
};

/**
 * Returns the current vorlage and the belonging user input.
 *
 * @throws if there is no selected vorlage.
 */
export const useCurrentVorlageWithUserInput = (): {
  vorlage: LegacyTemplateLandingpage;
  userInput: TemplateUserInput;
} => {
  const vorlage = useCurrentVorlageOrThrow();
  const userInput = useUserInputOnCurrentVorlageOrThrow();
  return {
    vorlage,
    userInput,
  };
};

export default beschlussVorlageSlice.reducer;
