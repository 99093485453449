import { Typography } from "@material-ui/core";
import { Variant } from "@material-ui/core/styles/createTypography";
import HyphenatedTypography from "../../../../components/01-atoms/HyphenatedTypography/HyphenatedTypography";
import { allHeadlineTags } from "../../../styling/allHeadlines";
import { TagToComponentMap } from "../replaceHtmlElementInterfaces";

/**
 * All headlines mapped to their Typography counterpart, hyphenated or not.
 */

function getId(children: string) {
  return children
    .split(" ")
    .map((word) => word.toLowerCase())
    .join("-");
}

export const mappedHeadlines = (hyphenate: boolean): TagToComponentMap => {
  const Component = hyphenate ? HyphenatedTypography : Typography;

  return Object.fromEntries(
    allHeadlineTags.map((tag) => [
      tag,
      (props) => (
        <Component
          variant={tag as Variant}
          className="marginTop"
          {...props.replacedElement.attribs}
          id={getId(props.children?.toString() || "")}
        >
          {props.children}
        </Component>
      ),
    ])
  );
};
