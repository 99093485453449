import de from "hyphenated-de";
import Hyphenated from "react-hyphen";
import { useSanitizedHtml } from "../../../hooks/useSanitizedHtml/useSanitizedHtml";
import { SanitizeHTMLProps } from "./SanitizeHTMLInterface";

export const SanitizeHTML = (props: SanitizeHTMLProps): JSX.Element => {
  const { html, options, className } = props;
  const sanitizedHtml = useSanitizedHtml(html, options);

  const Tag = props.tag || "div";

  const SanitizedElement = (
    <Tag
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
      className={className}
    />
  );

  return props.hyphenateText ? (
    // There are no official types for the `hyphenated-de` and `react-hyphen` packages yet.
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <Hyphenated language={de}>{SanitizedElement}</Hyphenated>
  ) : (
    SanitizedElement
  );
};
