/**
 * The Grammatical Case.
 *
 * @see https://en.wikipedia.org/wiki/Grammatical_case
 * @see https://de.wikipedia.org/wiki/Fugenlaut
 */

export enum GrCase {
  Nominative = "Nominative",
  Genitive = "Genitive",
  CompoundForm = "CompoundForm",
}
