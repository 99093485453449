import RVOImageHighlightBox from "../../../../components/02-molecules/RVOImageHighlightBox/RVOImageHighlightBox";
import { TagToComponentMap } from "../replaceHtmlElementInterfaces";

/**
 * The Footnote area automatically get a headline attached.
 */
export const mappedDivs: TagToComponentMap = {
  div: ({ children, replacedElement }) => {
    const isImageDiv =
      replacedElement.attribs.class?.includes("wp-block-image");

    if (isImageDiv) {
      return <RVOImageHighlightBox>{children}</RVOImageHighlightBox>;
    } else {
      return <div {...replacedElement.attribs}>{children}</div>;
    }
  },
};
