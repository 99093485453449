import RVOImageHighlightBox from "../../../../components/02-molecules/RVOImageHighlightBox/RVOImageHighlightBox";
import { TagToComponentMap } from "../replaceHtmlElementInterfaces";

/**
 * The Footnote area automatically get a headline attached.
 */
export const mappedFigures: TagToComponentMap = {
  figure: ({ children, replacedElement }) => {
    const isImageFigure =
      replacedElement.attribs.class?.includes("wp-block-image");

    if (isImageFigure) {
      return <RVOImageHighlightBox>{children}</RVOImageHighlightBox>;
    } else {
      return <div {...replacedElement.attribs}>{children}</div>;
    }
  },
};
