import { RolleInGesellschaft } from "../../../common-interfaces/VorlageInterface";
import { CompanyTypeLandingpage } from "../../../helpers/clientSide/constants";
import { GrCase } from "../../../interfaces/GrCase";
import { GrNumber } from "../../../interfaces/grNumber";
import { StringInNumberAndCase } from "../../../interfaces/StringInNumberAndCase";
import { CompanyTypeName } from "../../vorlagen/vorlagenShared/generatedTypes/CompanyTypeName";

const beirat = {
  [RolleInGesellschaft.Beirat]: {
    [GrNumber.Singular]: {
      [GrCase.Nominative]: "Beirat",
      [GrCase.Genitive]: "Beirats",
      [GrCase.CompoundForm]: "Beirats",
    },
    [GrNumber.Plural]: {
      [GrCase.Nominative]: "Beiratsmitglieder",
      [GrCase.Genitive]: "Beiratsmitglieder",
      [GrCase.CompoundForm]: "Beirats",
    },
  },
};

export const gesellschafter = {
  [RolleInGesellschaft.Mitglieder]: {
    [GrNumber.Singular]: {
      [GrCase.Nominative]: "Gesellschafter",
      [GrCase.Genitive]: "Gesellschafters",
      [GrCase.CompoundForm]: "Gesellschafter",
    },
    [GrNumber.Plural]: {
      [GrCase.Nominative]: "Gesellschafter",
      [GrCase.Genitive]: "Gesellschafter",
      [GrCase.CompoundForm]: "Gesellschafter",
    },
  },
};

export const mitglieder = {
  [RolleInGesellschaft.Mitglieder]: {
    [GrNumber.Singular]: {
      [GrCase.Nominative]: "Mitglied",
      [GrCase.Genitive]: "Mitglieds",
      [GrCase.CompoundForm]: "Mitglieder",
    },
    [GrNumber.Plural]: {
      [GrCase.Nominative]: "Mitglieder",
      [GrCase.Genitive]: "Mitglieder",
      [GrCase.CompoundForm]: "Mitglieder",
    },
  },
};

const geschaeftsfuehrer = {
  [RolleInGesellschaft.Geschftsleiter]: {
    [GrNumber.Singular]: {
      [GrCase.Nominative]: "Geschäftsführer",
      [GrCase.Genitive]: "Geschäftsführers",
      [GrCase.CompoundForm]: "Geschäftsführer",
    },
    [GrNumber.Plural]: {
      [GrCase.Nominative]: "Geschäftsführer",
      [GrCase.Genitive]: "Geschäftsführer",
      [GrCase.CompoundForm]: "Geschäftsführer",
    },
  },
};

const vorstandsmitglieder = {
  [RolleInGesellschaft.Geschftsleiter]: {
    [GrNumber.Singular]: {
      [GrCase.Nominative]: "Vorstandsmitglied",
      [GrCase.Genitive]: "Vorstandsmitglied",
      [GrCase.CompoundForm]: "Vorstands",
    },
    [GrNumber.Plural]: {
      [GrCase.Nominative]: "Vorstandsmitglieder",
      [GrCase.Genitive]: "Vorstandsmitglieder",
      [GrCase.CompoundForm]: "Vorstands",
    },
  },
};

const aufsichtsrat = {
  [RolleInGesellschaft.Aufsichtsrat]: {
    [GrNumber.Singular]: {
      [GrCase.Nominative]: "Aufsichtsrat",
      [GrCase.Genitive]: "Aufsichtsrats",
      [GrCase.CompoundForm]: "Aufsichtsrats",
    },
    [GrNumber.Plural]: {
      [GrCase.Nominative]: "Aufsichtsratsmitglieder",
      [GrCase.Genitive]: "Aufsichtsratsmitglieder",
      [GrCase.CompoundForm]: "Aufsichtsrats",
    },
  },
};

export const kommanditisten = {
  [RolleInGesellschaft.Mitglieder]: {
    [GrNumber.Singular]: {
      [GrCase.Nominative]: "Kommanditist",
      [GrCase.Genitive]: "Kommanditisten",
      [GrCase.CompoundForm]: "Kommanditisten",
    },
    [GrNumber.Plural]: {
      [GrCase.Nominative]: "Kommanditisten",
      [GrCase.Genitive]: "Kommanditisten",
      [GrCase.CompoundForm]: "Kommanditisten",
    },
  },
};

const partner = {
  [RolleInGesellschaft.Mitglieder]: {
    [GrNumber.Singular]: {
      [GrCase.Nominative]: "Partner",
      [GrCase.Genitive]: "Partners",
      [GrCase.CompoundForm]: "Partner",
    },
    [GrNumber.Plural]: {
      [GrCase.Nominative]: "Partner",
      [GrCase.Genitive]: "Partner",
      [GrCase.CompoundForm]: "Partner",
    },
  },
};

const komplementaere = {
  [RolleInGesellschaft.Geschftsleiter]: {
    [GrNumber.Singular]: {
      [GrCase.Nominative]: "Komplementär",
      [GrCase.Genitive]: "Komplementärs",
      [GrCase.CompoundForm]: "Komplementärs",
    },
    [GrNumber.Plural]: {
      [GrCase.Nominative]: "Komplementäre",
      [GrCase.Genitive]: "Komplementäre",
      [GrCase.CompoundForm]: "Komplementärs",
    },
  },
};

const geschaeftsfuehrenderGesellschafter = {
  [RolleInGesellschaft.Geschftsleiter]: {
    [GrNumber.Singular]: {
      [GrCase.Nominative]: "geschäftsführender Gesellschafter",
      [GrCase.Genitive]: "geschäftsführenden Gesellschafters",
      [GrCase.CompoundForm]: "Geschäftsführende-Gesellschafter",
    },
    [GrNumber.Plural]: {
      [GrCase.Nominative]: "geschäftsführenden Gesellschafter",
      [GrCase.Genitive]: "geschäftsführenden Gesellschafter",
      [GrCase.CompoundForm]: "Geschäftsführende-Gesellschafter",
    },
  },
};

const geschaeftsfuehrenderPartner = {
  [RolleInGesellschaft.Geschftsleiter]: {
    [GrNumber.Singular]: {
      [GrCase.Nominative]: "geschäftsführender Partner",
      [GrCase.Genitive]: "geschäftsführenden Partners",
      [GrCase.CompoundForm]: "Geschäftsführende-Partner",
    },
    [GrNumber.Plural]: {
      [GrCase.Nominative]: "geschäftsführende Partner",
      [GrCase.Genitive]: "geschäftsführenden Partner",
      [GrCase.CompoundForm]: "Geschäftsführende-Partner",
    },
  },
};

const aufsichtsratBeirat = {
  ...aufsichtsrat,
  ...beirat,
};

const kg = {
  ...gesellschafter,
  ...komplementaere,
  ...aufsichtsratBeirat,
};

const gmbh = {
  ...gesellschafter,
  ...geschaeftsfuehrer,
  ...aufsichtsratBeirat,
};

const personenGesellschaft = {
  ...gesellschafter,
  ...geschaeftsfuehrenderGesellschafter,
  ...aufsichtsratBeirat,
};

const partnerSchaft = {
  ...partner,
  ...geschaeftsfuehrenderPartner,
  ...aufsichtsratBeirat,
};

const eV = {
  ...vorstandsmitglieder,
  ...mitglieder,
  ...aufsichtsratBeirat,
};

/* const stiftung = {
  ...gesellschafter,
  ...vorstandsmitglieder,
  ...aufsichtsratBeirat,
}; */

/**
 * Depending on the Rechtsform, each role in a company has a different name.
 *
 * This name can be retrieved in different numbers (singular/plural), and different cases.
 */
export const roleNamesForRechtsform: Record<
  CompanyTypeLandingpage,
  Record<RolleInGesellschaft, StringInNumberAndCase>
> = {
  [CompanyTypeName.GmbHDe]: {
    ...gmbh,
  },
  [CompanyTypeName.UgDe]: {
    ...gmbh,
  },
  [CompanyTypeName.UgCoKgDe]: {
    ...kg,
  },
  [CompanyTypeName.GmbHCoKgDe]: {
    ...kg,
  },
  [CompanyTypeName.KgDe]: {
    ...kg,
  },
  [CompanyTypeName.OHgDe]: {
    ...personenGesellschaft,
  },
  [CompanyTypeName.GbRDe]: {
    ...personenGesellschaft,
  },
  [CompanyTypeName.PartGDe]: {
    ...partnerSchaft,
  },
  [CompanyTypeName.PartGmbBDe]: {
    ...partnerSchaft,
  },
  [CompanyTypeName.EVDe]: {
    ...eV,
  },
} as const;

export const maleCompanyTypes: CompanyTypeName[] = [
  CompanyTypeName.EVDe,
  CompanyTypeName.NeVDe,
  CompanyTypeName.VvaGDe,
];

export const vereinCompanyTypes: CompanyTypeName[] = [
  CompanyTypeName.EVDe,
  CompanyTypeName.NeVDe,
  CompanyTypeName.VvaGDe,
];
