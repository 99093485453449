import {
  ausnutzungFrist,
  darlehenDatum,
  darlehenHöhe,
  genehmigtesKapitalMaximum,
  neueGeschäftsanteileAnzahl,
  neueGeschäftsanteileAusgabebetrag,
  neueGeschäftsanteileBetrag,
  satzungErmächtigung,
  satzungGenehmigtesKapitalAngepassteFassung,
  satzungStammkapital,
  stammkapitalBetragNachErhöhung,
  stammkapitalBetragNachErhöhungInWorten,
  stammkapitalErhöhungsbetrag,
  tagBeginnGewinnberechtigung,
  übernahmeZugelasseneGesellschafter,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageAusnutzungGenehmigtesKapitalSacheinlageDarlehenTeilweise: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.sanierung,
    vorlage_rolleTyp: [RolleInGesellschaft.Geschftsleiter],
    vollzug: [
      Vollzug.handelsregisterAnmeldung,
      Vollzug.handelsregisterGesellschafterliste,
      Vollzug.transparenzregister,
      Vollzug.kapErhöhung_abgabeÜbernahmeerklärungen,
      Vollzug.kapErhöhung_sacheinlageEinbringungsvertrag,
      Vollzug.kapErhöhung_sacheinlageSachkapitalerhöhungsbericht,
      Vollzug.kapErhöhung_sacheinlageUnterlagenWerthaltigkeit,
    ],
    bezug: [],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung:
      "Teilweise Ausnutzung genehmigten Kapitals gegen Sacheinlage (Darlehensforderung) mit Bezugsrechtsausschluss",
    description:
      "Die Vorlage geht davon aus, dass das genehmigte Kapital nur teilweise durch Umwandlung einer Darlehensforderung in einen Geschäftsanteil im Wege der Sacheinlage ausgenutzt werden soll. Das Bezugsrecht der Gesellschafter soll im Übrigen ausgeschlossen werden, weil die Kapitalerhöhung zur Sanierung der Gesellschaft erforderlich ist und die übrigen Gesellschafter jedoch nicht bereit waren, weiteres Kapital zuzuschießen.",
    antrag: `<ol><li><p>Grundlage dieses Beschlusses ist ${insertTemplateVariable(
      satzungErmächtigung
    )} des Gesellschaftsvertrags, wonach die Geschäftsführung ermächtigt ist, das Stammkapital der Gesellschaft bis zum ${insertTemplateVariable(
      ausnutzungFrist
    )} durch Ausgabe von neuen Geschäftsanteilen gegen Sacheinlagen um bis zu ${insertTemplateVariable(
      genehmigtesKapitalMaximum
    )} zu erhöhen.</p></li>

  <li><p>Das Stammkapital der Gesellschaft wird gegen Sacheinlagen von insgesamt ${insertTemplateVariable(
    stammkapitalErhöhungsbetrag
  )} um ${insertTemplateVariable(
      stammkapitalErhöhungsbetrag
    )} auf ${insertTemplateVariable(
      stammkapitalBetragNachErhöhung
    )} durch Ausgabe von ${insertTemplateVariable(
      neueGeschäftsanteileAnzahl
    )} neuen Geschäftsanteilen im Nennbetrag von jeweils ${insertTemplateVariable(
      neueGeschäftsanteileBetrag
    )} erhöht.</p></li>

  <li><p>Der Ausgabebetrag beträgt ${insertTemplateVariable(
    neueGeschäftsanteileAusgabebetrag
  )} je Geschäftsanteil.</p></li>

  <li><p>Die neuen Geschäftsanteile sind ab ${insertTemplateVariable(
    tagBeginnGewinnberechtigung
  )} gewinnberechtigt.</p></li>

  <li><p>Zur Übernahme der neuen Geschäftsanteile wird ${insertTemplateVariable(
    übernahmeZugelasseneGesellschafter
  )} zugelassen. ${insertTemplateVariable(
      übernahmeZugelasseneGesellschafter
    )} hat der Gesellschaft mit Vertrag vom ${insertTemplateVariable(
      darlehenDatum
    )} ein Darlehen in Höhe von ${insertTemplateVariable(
      darlehenHöhe
    )} gewährt. Eine Rückzahlung des Darlehens ist bisher nicht erfolgt. Zinsen wurden von der Gesellschaft bisher nicht bezahlt. Als Sacheinlage hat ${insertTemplateVariable(
      übernahmeZugelasseneGesellschafter
    )} seine Darlehensforderung unverzüglich in die Gesellschaft einzubringen und diese der Gesellschaft mit Wirkung ab Eintragung der Kapitalerhöhung in das Handelsregister zu erlassen.</p></li>

  <li><p>Im Übrigen ist das Bezugsrecht der Gesellschafter ausgeschlossen. Die sachliche Rechtfertigung für den Bezugsrechtsausschluss ergibt sich daraus, dass eine Sanierung der Gesellschaft nur durch Umwandlung des vorstehend genannten Darlehens in Stammkapital möglich ist. Die übrigen Gesellschafter haben sich geweigert, der Gesellschaft weiteres Kapital in der erforderlichen Höhe zur Verfügung zu stellen, so dass ein Barkapitalerhöhung mit Bezugsrecht der Gesellschafter ausscheidet.</p></li>

 <li><p>Gemäß der Ermächtigung in ${insertTemplateVariable(
   satzungErmächtigung
 )} des Gesellschaftsvertrags beschließt die Geschäftsführung weiter, dass aufgrund der teilweisen Ausnutzung des genehmigten Kapitals mit der Eintragung der Durchführung der Kapitalerhöhung im Handelsregister ${insertTemplateVariable(
      satzungErmächtigung
    )} des Gesellschaftsvertrags (Genehmigtes Kapital) und ${insertTemplateVariable(
      satzungStammkapital
    )} des Gesellschaftsvertrags (Stammkapital) folgende Fassung erhalten:

    <dl><dd><p style="text-align:center">„${insertTemplateVariable(
      satzungStammkapital
    )} Stammkapital</p>

    <p>Das Stammkapital der Gesellschaft beträgt ${insertTemplateVariable(
      stammkapitalBetragNachErhöhung
    )} – in Worten: ${insertTemplateVariable(
      stammkapitalBetragNachErhöhungInWorten
    )}."</p>

  <p>${insertTemplateVariable(
    satzungGenehmigtesKapitalAngepassteFassung
  )}</p></dd></dl></p></li></ol>`,
    begründung: undefined,
    templateVariables: [
      satzungErmächtigung,
      satzungStammkapital,
      ausnutzungFrist,
      genehmigtesKapitalMaximum,
      stammkapitalErhöhungsbetrag,
      stammkapitalBetragNachErhöhung,
      stammkapitalBetragNachErhöhungInWorten,
      neueGeschäftsanteileAnzahl,
      neueGeschäftsanteileBetrag,
      neueGeschäftsanteileAusgabebetrag,
      tagBeginnGewinnberechtigung,
      übernahmeZugelasseneGesellschafter,
      darlehenDatum,
      darlehenHöhe,
      satzungGenehmigtesKapitalAngepassteFassung,
    ],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: true,
    noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss (§ 57 Abs. 1 i.V.m. § 78 GmbHG). Die Handelsregisteranmeldung muss von sämtlichen Geschäftsführern unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
  });
