import SignaturePlaceholder from "../../../../components/01-atoms/SignaturePlaceholder/SignaturePlaceholder";
import { multilineTextFragment } from "../../../../helpers/clientSide/multilineTextFragment";
import { GrNumber } from "../../../../interfaces/grNumber";
import { useCompanyInfo } from "../../../../state/companyInfo/companyInfoSlice";
import { datumBeschluss } from "../../../vorlagen/vorlagenShared/allTemplateVariables";
import { getRoleName } from "../../namesForRoles/getRoleName";
import {
  VotingMode,
  VotingModeIdentifier,
  VotingModeSwitchKey,
} from "../votingModeInterfaces";
import DefaultCoatingHeader from "./DefaultCoatingHeader/DefaultCoatingHeader";
import { useCoatingInformation } from "./useCoatingInformation";

/**
 * "Allein-Beschluss"
 */
export const votingModeSingleDecider: VotingMode = {
  identifier: VotingModeIdentifier.SingleDecider,
  CoatingHeader: () => {
    const coatingInformation = useCoatingInformation(GrNumber.Singular);
    const { articleGenitive } = useCompanyInfo();

    if (!coatingInformation) {
      return null;
    }

    const headline = multilineTextFragment(
      `Beschluss`,
      `des ${coatingInformation.whoGenitive}`,
      `${articleGenitive} ${coatingInformation.company}`
    );

    const bodyText = `Der Allein-${coatingInformation.whoNominative} der ${coatingInformation.company} mit Sitz in ${coatingInformation.sitz} - nachfolgend auch "Gesellschaft" genannt - beschließt:`;

    return <DefaultCoatingHeader {...{ headline, bodyText }} />;
  },
  CoatingFooter: () => {
    const { firma, whoCanDecide, rechtsform } = useCompanyInfo();
    const who = getRoleName(whoCanDecide, rechtsform, GrNumber.Singular);

    if (!who) {
      return null;
    }

    return (
      <SignaturePlaceholder>{`Allein-${who} der ${firma}`}</SignaturePlaceholder>
    );
  },
  label: (t) => t("votingMode.singleDecider"),
  additionalInputs: [datumBeschluss],
  switchValues: {
    [VotingModeSwitchKey.AreMultiplePeopleInvolved]: false,
    [VotingModeSwitchKey.IsUnanimous]: null,
    [VotingModeSwitchKey.DoPeopleMeetInPerson]: null,
  },
};
