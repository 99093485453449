import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { important } from "@nvon/react-toolbox";

export const useRVOFootnotesSectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      paddingLeft: 0,
      marginLeft: 0,
      listStyle: "none",
      counterReset: "item",
      paddingInlineStart: important(0),
    },
  })
);
