import { assertTruthy } from "@nvon/baseline";
import { CompanyTypeLandingpage } from "../../helpers/clientSide/constants";
import { CompanyTypeName } from "../vorlagen/vorlagenShared/generatedTypes/CompanyTypeName";

export const isCompanyTypeLandingpage = (
  companyType: CompanyTypeName
): companyType is CompanyTypeLandingpage => {
  return record[companyType as CompanyTypeLandingpage] === null;
};

export const assertCompanyTypeLandingpage: (
  companyType: CompanyTypeName
) => asserts companyType is CompanyTypeLandingpage = (companyType) => {
  assertTruthy(
    isCompanyTypeLandingpage(companyType),
    `${companyType} is not a CompanyTypeLandingpage`
  );
};

/**
 * We need this object because CompanyTypeLandingpage is just a type,
 *  and cannot be used for runtime checking.
 */
const record: Record<CompanyTypeLandingpage, null> = {
  [CompanyTypeName.GmbHDe]: null,
  [CompanyTypeName.UgDe]: null,
  [CompanyTypeName.UgCoKgDe]: null,
  [CompanyTypeName.GmbHCoKgDe]: null,
  [CompanyTypeName.KgDe]: null,
  [CompanyTypeName.OHgDe]: null,
  [CompanyTypeName.GbRDe]: null,
  [CompanyTypeName.PartGDe]: null,
  [CompanyTypeName.PartGmbBDe]: null,
  [CompanyTypeName.EVDe]: null,
};

export const companyTypesLandingpage: CompanyTypeLandingpage[] = Object.keys(
  record
) as CompanyTypeLandingpage[];
