import { anschriftVerwahrer, nameVerwahrer } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageAuflosungOhneLiquidationUndOhneSperrjahr: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.liquidation,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.handelsregisterAnmeldung],
    bezug: [],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung:
      'Auflösung ohne Liquidation aufgrund Vermögenslosigkeit ("Blitzlöschung")',
    description:
      "Der Gesellschafterbeschluss über die Auflösung bedarf einer 3/4-Mehrheit der abgegebenen Stimmen, wenn der Gesellschaftsvertrag keine andere Mehrheit vorsieht (§ 60 Abs. 1 Nr. 2 GmbHG). Eine Beurkdungspflicht besteht grundsätzlich nicht.",
    antrag: `Die Gesellschaft ist aufgelöst und ohne Liquidation erloschen, denn:

  <ol><li><p>Vermögen oder Verbindlichkeiten der Gesellschaft sind nicht vorhanden. Insbesondere stehen keine Zahlungen auf Geschäftsanteile aus. Auch sind keine Ausschüttungen beziehungsweise Auszahlungen des Gesellschaftsvermögens an Gesellschafter über einen ordentlichen Gewinnverteilungsplan hinaus erfolgt.</p></li>

  <li><p>Ein Fall der Zahlungsunfähigkeit oder Überschuldung der Gesellschaft liegt nicht vor.</p></li>

  <li><p>Die Gesellschaft ist nicht persönlich haftender Gesellschafter einer GmbH & Co. KG oder oHG.</p></li>

  <li><p>Es bestehen keine Rückforderungen der Gesellschaft gegenüber dem Finanzamt.</p></li>

  <li><p>Es sind keine gerichtlichen Rechtsstreitigkeiten anhängig, an welchen die Gesellschaft beteiligt ist.</p></li></ol>

  Die Bücher und Schriften der Gesellschaft werden nach Beendigung der Liquidation ${insertTemplateVariable(
    nameVerwahrer
  )}, ${insertTemplateVariable(
      anschriftVerwahrer
    )}, für die Dauer von zehn Jahren in Verwahrung gegeben.`,
    begründung: undefined,
    templateVariables: [nameVerwahrer, anschriftVerwahrer],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: true,
    noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss (§ 65 Abs. 1 Satz 1 GmbHG). Die Handelsregisteranmeldung muss von den Geschäftsführern (bzw. Liquidatoren) in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
  });
