import {
  nameGeschäftsleiter,
  vertragBezeichnung,
  vertragPartner,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGeschaftsfuhrerBefreiung181Einzellfall: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [
      CompanyTypeName.GmbHDe,
      CompanyTypeName.UgDe,
      CompanyTypeName.GmbHCoKgDe,
      CompanyTypeName.KgDe,
      CompanyTypeName.PartGmbBDe,
      CompanyTypeName.PartGDe,
      CompanyTypeName.UgCoKgDe,
      CompanyTypeName.OHgDe,
      CompanyTypeName.GbRDe,
    ],
    vorlageKategorie: VorlageKategorie.geschäftsführer,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.zugang],
    bezug: [],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung:
      "Befreiung von den Beschränkungen des § 181 BGB (im Einzellfall)",
    description: undefined,
    metaDescription:
      "Befreiung des Geschäftsführers von Verbot des Insichgeschäfts/der Mehrfachvertretung für bestimmtes Geschäft mit Gesellschafterbeschluss. Muster/Vorlagen kostenlos.",
    antrag: `
      <p>
      ${insertTemplateVariable(
        nameGeschäftsleiter
      )} wird für den Abschluss des ${insertTemplateVariable(
      vertragBezeichnung
    )} mit
  ${insertTemplateVariable(
    vertragPartner
  )} von den Beschränkungen des § 181 BGB befreit, d.h. ${insertTemplateVariable(
      nameGeschäftsleiter
    )} kann im Namen der Gesellschaft sowohl mit sich im eigenen Namen oder als auch als Vertreter eines Dritten <strong>das vorbezeichente Rechtsgeschäft</strong> vornehmen.
  </p>`,
    begründung: undefined,
    templateVariables: [
      nameGeschäftsleiter,
      vertragBezeichnung,
      vertragPartner,
    ],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
