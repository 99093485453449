import {
  aufsichtsratLadungsfristWochen,
  aufsichtsratPflichtsitzungenAnzahl,
  ausschussNominierung,
  ausschussPraesidial,
  ausschussPruefung,
  ausschussTechnologie,
  vorstandZustimmungspflichtSchwelle,
  vorstandZustimmungspflichtSchwelleMitarbeiter,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageAgDeAufsichtsratGeschäftsordnungMitMitbestimmungMitAusschüssen: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [
      CompanyTypeName.AgDe,
      CompanyTypeName.SeDe,
      CompanyTypeName.KgaADe,
    ],
    vorlageKategorie: VorlageKategorie.struktur,
    vorlage_rolleTyp: [RolleInGesellschaft.Aufsichtsrat],
    vollzug: [Vollzug.selfexecuting],
    bezug: [],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung: "Geschäftsordnung des mitbestimmten Aufsichtsrats",
    description: `
    <p>
        Diese Beschlussvorlage ist geeignet für den Beschluss einer Geschäftsordnung für den Aufsichtsrat einer mitbestimmten Aktiengesellschaft.
    </p>
    <p>
       Die Muster-Vorlage ist an die die gesetzlichen Vorgaben des Mitbestimmungsgesetzes 1976 (MitbestG) angepasst und enthält Regelungen zur inneren Ordnung des Aufsichtsrats.
    </p>
    <p>
        Das MitbestG greift ein, wenn in einer Aktiengesellschaft in der Regel mehr als 2.000 Arbeitnehmer beschäftigt sind. Die Geschäftsordnung des Aufsichtsrats muss die Mitbestimmung der Arbeitnehmer berücksichtigen.
    </p>

    `,
    metaDescription:
      "Geschäftsordnung eines mitbestimmten Aufsichtsrats aufgrund des Mitbestimmungsgesetzes 1976 (MitbestG). Juristisch geprüfte Standard-Muster/Vorlagen kostenlos ausfüllen und downloaden.",
    tags: [
      "Geschäftsordnung",
      "Aufsichtsrat",
      "Mitbestimmung",
      "Ausschüsse",
      "Präsidialausschuss",
      "Nominierungsausschuss",
      "Audit Committee",
      "Prüfungsausschuss",
      "Technologieausschuss",
      "Mitbestimmungsgesetz",
      "MitbestG",
      "Vermittlungsausschuss",
      "Arbeitnehmervertreter",
      "Frauenquote",
      "Geschlechterqutoe",
    ],
    antrag: `
  <p>Folgende</p>

  <h2>Geschäftsordnung</h2>

  <p>wird für den Aufsichtsrat erlassen:</p>

  <ol data-list-style="decimal" data-list-style-prefix="§">
    <li>
        <h3>
            Allgemeines
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Der Aufsichtsrat führt seine Geschäfte nach den Vorschriften der Gesetze, der Satzung und dieser Geschäftsordnung.
                </p>
            </li>
            <li>
                <p>
                    In der vorliegenden Geschäftsordnung wird aus Gründen der sprachlichen Einfachheit nur die männliche Form verwandt. Inhaltlich ist diese geschlechtsneutral.
                </p>
            </li>
        </ol>
    </li>
    <li>
        <h3>
            Vorsitzender, Stellvertreter
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Der Aufsichtsrat wählt unter Vorsitz des an Lebensjahren ältesten Aufsichtsratsmitglieds in der ersten Sitzung nach seiner Wahl aus seiner Mitte den Vorsitzenden des Aufsichtsrats und seinen Stellvertreter nach Maßgabe von § 27 Abs. 1 und 2 des Mitbestimmungsgesetzes 1976 (MitbestG).
                </p>
            </li>
            <li>
                <p>
                     Die Amtszeit des Vorsitzenden und seines Stellvertreters muss für beide gleich lang bemessen sein. Scheidet der Vorsitzende des Aufsichtsrats oder sein Stellvertreter vor Ablauf seiner Amtszeit aus, hat der Aufsichtsrat unverzüglich eine Neuwahl für die restliche Amtszeit des Ausgeschiedenen vorzunehmen, die sich ebenfalls nach § 27 Abs. 1 und 2 MitbestG richtet.
                </p>
            </li>
        </ol>
    </li>
    <li>
        <h3>
            Einberufung von Sitzungen
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                   Der Aufsichtsrat muss ${insertTemplateVariable(
                     aufsichtsratPflichtsitzungenAnzahl
                   )} im Kalenderhalbjahr abhalten.
                </p>
            </li>
            <li>
                <p>
                    Aufsichtsratssitzungen werden vom Vorsitzenden unter Einhaltung einer Frist von ${insertTemplateVariable(
                      aufsichtsratLadungsfristWochen
                    )} postalisch, per Telefax, fernmündlich oder elektronisch einberufen. Der Tag der Absendung bzw. der mündlichen Mitteilung der Einberufung und der Tag der Sitzung werden nicht mitgerechnet. In dringenden Fällen kann die Einberufungsfrist abgekürzt werden.
                </p>
            </li>
            <li>
                <p>
                    Mit der Einberufung sind die Gegenstände der Tagesordnung sowie alle für die Entscheidung maßgeblichen Unterlagen, insbesondere der Jahresabschluss und der Prüfungsbericht den Mitgliedern des Aufsichtsrats rechtzeitig zuzuleiten. Die Tagesordnung ist zu ergänzen, wenn ein Mitglied des Aufsichtsrats oder der Vorstand dies unter Angabe des Zwecks und der Gründe spätestens drei Kalendertage vor der Aufsichtsratssitzung verlangt.
                </p>
            </li>
            <li>
                <p>
                    Zu einem nicht ordnungsgemäß angekündigten Tagesordnungspunkt darf nur Beschluss gefasst werden, wenn kein Aufsichtsratsmitglied widerspricht. Abwesenden Aufsichtsratsmitgliedern ist in einem solchen Fall Gelegenheit zu geben, binnen 3 Kalendertagen ab Mitteilung der ergänzten Tagesordnung der Beschlussfassung zu widersprechen oder ihre Stimme schriftlich abzugeben. Absatz 2 Satz 2 gilt entsprechend. Der Beschluss wird erst nach widerspruchslosem Ablauf der Frist wirksam.
                </p>
            </li>
        </ol>
    </li>
    <li>
        <h3>
            Sitzungsablauf und Teilnahme
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    In der Sitzung führt der Vorsitzende des Aufsichtsrats den Vorsitz.
                </p>
            </li>
            <li>
                <p>
                    Der Vorsitzende bestimmt die Verhandlungssprache. Ist ein Mitglied des Aufsichtsrats der Verhandlungssprache nicht mächtig, ist ein Simultandolmetscher herbeizuziehen.
                </p>
            </li>
            <li>
                <p>
                    Der Vorsitzende des Aufsichtsrats bestimmt die Reihenfolge, in der die Gegenstände der Tagesordnung verhandelt werden, sowie Art und Reihenfolge der Abstimmungen. Er kann die Beratung und Beschlussfassung über einzelne Gegenstände der Tagesordnung auf Antrag des Vorstands oder aufgrund eines wichtigen Grundes auf eine nachfolgende Sitzung vertagen. Wenn es ihm nötig erscheint, ist er berechtigt, die Sitzung für die Dauer von höchstens einer Woche zu unterbrechen, insbesondere im Fall der Stimmengleichheit.
                </p>
            </li>
            <li>
                <p>
                    Der Aufsichtsrat soll regelmäßig auch ohne den Vorstand tagen. Die Mitglieder des Vorstands nehmen jedoch an den Sitzungen des Aufsichtsrats teil, sofern nicht im Einzelfall der Aufsichtsrat oder sein Vorsitzender dieses bestimmt. Zu den Sitzungen der Ausschüsse können die Mitglieder des Vorstands nach Bedarf hinzugezogen werden.
                </p>
            </li>
            <li>
                <p>
                    Der Vorsitzende entscheidet über die Zuziehung von Sachverständigen und Auskunftspersonen zur Beratung über einzelne Gegenstände der Tagesordnung.
                </p>
            </li>
        </ol>
    </li>
    <li>
        <h3>
            Beschlussfassung
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Beschlüsse des Aufsichtsrats werden in der Regel in Sitzungen gefasst. Eine Sitzung erfordert nicht immer die gleichzeitige körperliche Anwesenheit aller Mitglieder, sondern es ist auch eine Sitzung in Form einer Telefon- oder Videokonferenz erlaubt. Abwesende Mitglieder des Aufsichtsrats können dadurch an der Beschlussfassung in einer Sitzung teilnehmen, dass sie schriftliche Stimmabgaben durch ein anderes Mitglied des Aufsichtsrats überreichen lassen. Als schriftliche Stimmabgabe gilt auch eine elektronisch übermittelte Stimmabgabe, insbesondere über die Resolvio-Plattform. Die nachträgliche Stimmabgabe eines abwesenden Mitglieds ist nur möglich, wenn sie von allen anwesenden Mitgliedern zugelassen wurde.
                </p>
            </li>
            <li>
                <p>
                    Beschlussfassungen des Aufsichtsrats und seiner Ausschüsse per Schriftform, E-Mail und elektronisch, insbesondere über die Resolvio-Plattform, (Umlaufverfahren) sind zulässig.
                </p>
            </li>
            <li>
                <p>
                    Der Aufsichtsrat ist beschlussfähig, wenn sämtliche Mitglieder unter ihrer jeweilig zuletzt bekannten Adresse eingeladen worden sind und mindestens die Hälfte der Mitglieder, aus denen er insgesamt zu bestehen hat, an der Beschlussfassung teilnimmt. Bei der Beschlussfassung im Umlaufverfahren gilt die Stimmabgabe (einschließlich der Enthaltung) als Teilnahme.
                </p>
            </li>
            <li>
                <p>
                    Die Beschlüsse werden mit einfacher Mehrheit der abgegebenen Stimmen gefasst, soweit nicht im Gesetz andere Mehrheiten zwingend vorgeschrieben sind. Stimmenthaltungen werden bei Feststellung des Abstimmungsergebnisses nicht mitgezählt. Der Vorsitzende entscheidet, ob bei Stimmengleichheit die Abstimmung wiederholt wird. Sofern sich bei erneuter Abstimmung über denselben Gegenstand wiederum Stimmengleichheit ergibt, hat der Vorsitzende zwei Stimmen. Dem Stellvertreter steht die zweite Stimme nicht zu.
                </p>
            </li>
            <li>
                <p>
                    Nehmen an einer Beschlussfassung nicht die gleiche Anzahl von Aufsichtsratsmitgliedern der Aktionäre und der Arbeitnehmer teil, kann der Vorsitzende des Aufsichtsrats die Beschlussfassung vertagen. Bei einer Vertagung findet die erneute Beschlussfassung, wenn keine besondere Aufsichtsratssitzung einberufen wird, innerhalb der nächsten vier Wochen statt. Eine nochmalige Vertagung durch den Vorsitzenden aus dem gleichen Grund ist nicht zulässig.
                </p>
            </li>
        </ol>
    </li>
    <li>
        <h3>
            Niederschriften über Sitzungen und Beschlüsse
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Über Sitzungen und Beschlussfassungen im Umlaufverfahren ist ein Protokoll anzufertigen. Dieses Protokoll kann auch von einem vom Vorsitzenden zu bestimmenden Protokollführer, der, sofern kein Aufsichtsratsmitglied widerspricht, dem Aufsichtsrat nicht angehören muss, angefertigt werden. Es ist vom Vorsitzenden des Aufsichtsrats zu unterzeichnen. In dem Protokoll sind der Ort und der Tag der Sitzung, die Teilnehmer, die Gegenstände der Tagesordnung und die Beschlüsse des Aufsichtsrats anzugeben. Das Protokoll ist jedem Mitglied des Aufsichtsrats zeitnah zugänglich zu machen.
                </p>
            </li>
            <li>
                <p>
                    Das Protokoll gilt als genehmigt, wenn kein Mitglied des Aufsichtsrats, das an der Beschlussfassung teilgenommen hat, innerhalb von zwei Wochen seit Zugänglichmachung beim Vorsitzenden des Aufsichtsrats der Fassung des Protokolls in Textform widersprochen hat. Ein Mitglied des Aufsichtsrats kann einen Widerspruch nur gegen die Fassung des Protokolls, nicht jedoch gegen den in ihr enthaltenen Beschluss richten.
                </p>
            </li>
            <li>
                <p>
                    Die vom Aufsichtsrat gefassten Beschlüsse können in der Sitzung im Wortlaut protokolliert und sogleich vom Vorsitzenden des Aufsichtsrats als Teil des Protokolls unterzeichnet werden. Soweit Beschlüsse in der Sitzung in dieser Form gesondert protokolliert werden, ist ein Widerspruch nur in der Sitzung möglich.
                </p>
            </li>
        </ol>
    </li>
    <li>
        <h3>
            Schweigepflicht
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Die Mitglieder des Aufsichtsrats haben über die ihnen bei ihrer Tätigkeit als Mitglied des Aufsichtsrats bekannt gewordenen Tatsachen, deren Offenbarung die Interessen der Gesellschaft oder eines mit ihr verbundenen Unternehmens beeinträchtigen könnte, Dritten gegenüber Stillschweigen zu bewahren. Die Mitglieder des Aufsichtsrats stellen sicher, dass auch die von ihnen eingeschalteten Mitarbeiter und Berater die Verschwiegenheitspflicht in gleicher Weise einhalten. Diese Verpflichtung besteht auch nach Beendigung ihres Amtes. Dem Gebot der Schweigepflicht unterliegen insbesondere die Stimmabgabe, der Verlauf der Debatte, die Stellungnahmen sowie persönliche Äußerungen der einzelnen Mitglieder des Aufsichtsrats.
                </p>
            </li>
            <li>
                <p>
                    Hält ein Mitglied des Aufsichtsrats die Weitergabe von Informationen für geboten, bei denen es sich um derartige Angaben oder Geheimnisse handeln könnte, so ist vorab der Aufsichtsratsvorsitzende hierüber zu informieren. Stimmt der Aufsichtsratsvorsitzende der Weitergabe nicht zu, sind die anderen Mitglieder des Aufsichtsrats zu unterrichten und eine unverzügliche Stellungnahme herbeizuführen. Das betreffende Aufsichtsratsmitglied ist verpflichtet, bis zu dieser Stellungnahme Stillschweigen über die betreffenden Tatsachen zu bewahren. Beabsichtigt der Aufsichtsratsvorsitzende die Weitergabe von Informationen, deren Mitteilung nicht offensichtlich zulässig ist, gelten Satz 2 und 3 entsprechend.
                </p>
            </li>
            <li>
                <p>
                    Die Mitglieder des Aufsichtsrats sind bei einem Ausscheiden aus dem Amt verpflichtet, sämtliche Unterlagen wie Schriftstücke, Korrespondenzen, Sitzungsprotokolle, Aufzeichnungen und dergleichen, die sich auf Angelegenheiten der Gesellschaft beziehen und sich in ihrem Besitz befinden, unverzüglich an die Gesellschaft zu übergeben. Diese Verpflichtung erstreckt sich auch auf Duplikate und ihre Ablichtungen. Den Mitgliedern des Aufsichtsrats steht kein Zurückbehaltungsrecht an derartigen Unterlagen zu.
                </p>
            </li>
        </ol>
    </li>
    <li>
        <h3>
            Bestellung und Entlassung der Mitglieder des Vorstands
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Der Aufsichtsrat achtet bei der Zusammensetzung des Vorstandes auf Vielfalt (Diversity). Er legt für den Frauenanteil im Vorstand eine Zielgröße fest, die den gesetzlichen Vorgaben entspricht. Mitglied des Vorstands soll nur derjenige sein, der das 65. Lebensjahr noch nicht vollendet hat. Das Lebensalter der Vorstandsmitglieder ist daher bei ihrer Bestellungsdauer entsprechend zu berücksichtigen. Der Aufsichtsrat ist angehalten, gemeinsam mit dem Vorstand für eine langfristige Nachfolgeplanung Sorge zu tragen. Das Vergütungssystem für den Vorstand wird vom Gesamtaufsichtsrat festgelegt.
                </p>
            </li>
            <li>
                <p>
                    Wird ein Vorstandsmitglied erstmalig bestellt, sollte die maximal Bestelldauer drei Jahre sein. Die Wiederbestellung eines Vorstandsmitglieds vor Ablauf eines Jahres vor dem Ende der Bestelldauer bei gleichzeitiger Aufhebung der laufenden Bestellung darf nur bei Vorliegen besonderer Umstände erfolgen.
                </p>
            </li>
        </ol>
    </li>
     <li>
        <h3>
            Berichtspflicht/Zustimmungsvorbehalte
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Der Aufsichtsrat legt die Informations- und Berichtspflichten des Vorstandes näher fest.
                </p>
            </li>
            <li>
                <p>
                    Der Vorstand bedarf für die folgenden Maßnahmen oder Geschäfte der vorherigen Zustimmung des Aufsichtsrats:
                </p>

            <ol data-list-style="lower-latin">
                <li>
                    <p>
                        Aufnahme von Geschäftsaktivitäten außerhalb der bestehenden Geschäftsfelder oder Aufgabe von Geschäftsaktivitäten;
                    </p>
                </li>
                <li>
                    <p>
                        Erwerb, Veräußerung, Pacht oder Verpachtung sowie Joint Ventures von Unternehmen und Unternehmensbeteiligungen mit einem Wert von im Einzelfall über ${insertTemplateVariable(
                          vorstandZustimmungspflichtSchwelle
                        )};
                    </p>
                </li>
                <li>
                    <p>
                       Gründung, Auflösung, Verschmelzung oder Spaltung von Unternehmen und Unternehmensbeteiligungen mit einem Wert von im Einzelfall über ${insertTemplateVariable(
                         vorstandZustimmungspflichtSchwelle
                       )};
                    </p>
                </li>
                <li>
                    <p>
                        Investitionen sowie Erwerb und Veräußerungen von Grundstücken, Gebäuden und grundstücksgleichen Rechten mit einem Wert von im Einzelfall über ${insertTemplateVariable(
                          vorstandZustimmungspflichtSchwelle
                        )};
                    </p>
                </li>
                <li>
                    <p>
                        Festlegung von Geschäfts-, Investitions- und Finanzrahmenplänen;
                    </p>
                </li>
                <li>
                    <p>
                        Abschluss, Änderung und Aufhebung von Unternehmensverträgen;
                    </p>
                </li>
                <li>
                    <p>
                        Aufnahme von Krediten und Durchführung sonstiger Finanzgeschäfte mit einer auf das Geschäftsjahr gerechneten Gesamtsumme über ${insertTemplateVariable(
                          vorstandZustimmungspflichtSchwelle
                        )}, soweit diese nicht bereits durch den Investitions- oder Finanzrahmenplan gedeckt sind;
                    </p>
                </li>
                <li>
                    <p>
                       Schließung von Produktionsstandorten mit mehr als ${insertTemplateVariable(
                         vorstandZustimmungspflichtSchwelleMitarbeiter
                       )};
                    </p>
                </li>
                <li>
                    <p>
                        Übernahme von Bürgschaften, Garantien, Haftungen oder sonstigen Gewährleistungen oder die Gewährung von Darlehen und Krediten, soweit sie über den normalen Geschäftsumfang hinaus gehen;
                    </p>
                </li>
                <li>
                    <p>
                        Die Festlegung des Spendenetats sowie die Gewährung von Spenden mit einer auf das Geschäftsjahr gerechneten Gesamtsumme über ${insertTemplateVariable(
                          vorstandZustimmungspflichtSchwelle
                        )};
                    </p>
                </li>
                <li>
                    <p>
                        Grundsätze über die Festlegung der Vergütungsstruktur, dh Einführung von „Stock Incentive Programmen“ oder Belegschaftsaktienprogrammen;
                    </p>
                </li>
                <li>
                    <p>
                        Aufnahme von Anleihen und Ausgabe von Schuldverschreibungen;
                    </p>
                </li>
                <li>
                    <p>
                        Finanzderivatsgeschäfte oder Fremdwährungsgeschäfte, die über die normale frist¬gerechte geschäftsbedingte Absicherung gegen Währungsrisiken hinaus gehen.
                    </p>
                </li>
            </ol>
            </li>
            <li>
                <p>
                    Anderweitige Tätigkeiten eines Vorstandsmitgliedes gemäß § 88 AktG sowie sonstige Nebentätigkeiten, insbesondere die Wahrnehmung von Aufsichtsratsmandaten und Mandaten in vergleichbaren Kontrollgremien außerhalb des Unternehmens, bedürfen der Zustimmung des Aufsichtsrats.
                </p>
            </li>
            <li>
                <p>
                    Der Zustimmungsbeschluss des Aufsichtsrats bedarf der einfachen Mehrheit der abgegebenen Stimmen.
                </p>
            </li>
        </ol>
    </li>
    <li>
        <h3>
            Ausschüsse
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Unmittelbar nach der Wahl des Vorsitzenden und seines Stellvertreters wählt der Aufsichtsrat zur Wahrnehmung der in § 31 Abs. 3 MitbestG genannten Aufgaben einen Vermittlungsausschuss gemäß § 27 Abs. 3 MitbestG. Diesem Ausschuss gehören der Vorsitzende, sein Stellvertreter sowie je ein von den Aufsichtsratsmitgliedern der Arbeitnehmer und der Aktionäre mit der Mehrheit der abgegebenen Stimmen gewähltes Mitglied an. Ein Zweitstimmrecht des Aufsichtsratsvorsitzenden besteht bei Abstimmungen im Vermittlungsausschuss nicht.
                </p>
            </li>
            <li>
                <p>
                    Soweit im Übrigen Ausschüsse gebildet werden, gelten die folgenden Absätze dieses Paragraphen.
                </p>
            </li>

            <li>
                <p>
                    Die Amtszeit der Mitglieder der Ausschüsse entspricht ihrer Amtszeit als Mitglied des Aufsichtsrats, soweit bei der Wahl durch den Aufsichtsrat nicht eine kürzere Amtszeit bestimmt worden ist.
                </p>
            </li>
            <li>
                <p>
                    Die in dieser Geschäftsordnung getroffenen Regelungen gelten für die innere Ordnung der Ausschüsse entsprechend, soweit nicht nachfolgend Abweichendes bestimmt ist.
                </p>
            </li>
            <li>
                <p>
                    Soweit nicht in dieser Geschäftsordnung abweichend bestimmt, ist ein Ausschuss beschlussfähig, wenn mindestens drei Ausschussmitglieder an der Beschlussfassung teilnehmen.
                </p>
            </li>
            <li>
                <p>
                    Die Ausschüsse haben regelmäßig dem Aufsichtsrat über ihre Arbeit zu berichten.
                </p>
            </li>
        </ol>
    </li>
    ${insertTemplateVariable(ausschussPraesidial)}
        ${insertTemplateVariable(ausschussNominierung)}
            ${insertTemplateVariable(ausschussPruefung)}
                ${insertTemplateVariable(ausschussTechnologie)}
    <li>
        <h3>
            Zusammensetzung des Aufsichtsrats
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Der Aufsichtsrat setzt sich mindestens aus 30 % Frauen und zu 30 % aus Männern zusammen. Die Erfüllung erfolgt solange als Gesamterfüllung bis keine der Aufsichtsratsseiten widerspricht. Ein solcher Widerspruch kann nicht von einem einzelnen Aufsichtsratsmitglied erklärt werden, es bedarf vielmehr eines Mehrheitsbeschlusses der jeweiligen Aufsichtsratsseite.
                </p>
            </li>
            <li>
                <p>
                    Mitglied des Aufsichtsrats darf nicht sein, wer das 70. Lebensjahr bereits vollendet hat. Daher ist bei dem Vorschlag des Aufsichtsrats zur Wahl oder Wiederwahl eines Aufsichtsratsmitglieds darauf zu achten, dass das Lebensalter des betreffenden Aufsichtsratsmitglieds bei der vorgeschlagenen Amtsdauer entsprechend berücksichtigt wird. Die Regelgrenze für die Zugehörigkeit soll bei 3 Amtsperioden liegen. Im Einzelfall kann davon per Beschluss abgewichen werden.
                </p>
            </li>
            <li>
                <p>
                    Bei der Zusammensetzung des Aufsichtsrats soll darauf geachtet werden, dass eine ausreichende Anzahl unabhängiger Mitglieder dem Aufsichtsrat angehören. Unter den Mitgliedern sollen nicht mehr als zwei Aufsichtsratsmitglieder sein, die in den letzten Jahren vor ihrer Wahl Mitglied des Vorstands der Gesellschaft waren oder derzeit eine Organfunktion oder Beratungsaufgaben bei wesentlichen Wettbewerbern der Gesellschaft ausüben. Der Aufsichtsrat informiert über seine Einschätzung zur angemessenen Zahl unabhängiger Anteilseignervertreter und deren Namen in der Erklärung zur Unternehmerführung.
                </p>
            </li>
            <li>
                <p>
                    Zum Mitglied des Aufsichtsrats darf nicht gewählt werden, wer dem Vorstand einer börsennotierten Gesellschaft angehört und bereits zwei Aufsichtsratsmandate in konzernexternen börsennotierten Gesellschaften oder in Aufsichtsgremien von Gesellschaften mit vergleichbaren Anforderungen wahrnimmt. Wer keinem geschäftsführenden Organ einer Unternehmung angehört, soll nur gewählt werden, wenn er/sie nicht mehr als fünf Aufsichtsratsmandate bei börsennotierten Gesellschaften oder vergleichbaren Funktionen wahrnimmt. Der Aufsichtsratsvorsitz zählt doppelt.
                </p>
            </li>
        </ol>
    </li>
    <li>
        <h3>
            Interessenkonflikte
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Jedes Aufsichtsratsmitglied hat potentielle Interessenkonflikte nach § 111a AktG sowie solche, die aufgrund einer Beratung oder Organfunktion bei Kunden, Lieferanten, Kreditgebern oder sonstigen Geschäftspartnern entstehen können, dem Aufsichtsrat unverzüglich offen zu legen. Die Verfahrensweise bei der Offenlegung ist mit dem Aufsichtsratsvorsitzenden abzustimmen.
                </p>
            </li>
            <li>
                <p>
                    Ändert sich die berufliche Tätigkeit eines Aufsichtsratsmitglieds gegenüber dem Zeitpunkt seiner Wahl wesentlich, hat dieses Aufsichtsratsmitglied mit dem Aufsichtsratsvorsitzenden über eine mögliche Beendigung seines Aufsichtsratsmandates zu sprechen.
                </p>
            </li>
            <li>
                <p>
                    Der Aufsichtsrat wird in seinem Bericht an die Hauptversammlung über aufgetretene Interessenkonflikte und deren Behandlung informieren.
            </li>
            <li>
                <p>
                    Wesentliche und nicht nur vorübergehende Interessenkonflikte in der Person eines Aufsichtsratsmitglieds sollen zur unverzüglichen Beendigung des Mandats im Wege der Amtsniederlegung führen.
                </p>
            </li>
        </ol>
    </li>
    <li>
        <h3>
            Meldepflichten
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Jedes Mitglied des Aufsichtsrats ist verpflichtet, den Kauf und Verkauf von Aktien oder Schuldtiteln der Gesellschaft sowie ihrer Konzernunternehmen, sowie von damit verbundenen Optionen oder sonstigen Derivaten nach Vollzug unverzüglich, das heißt spätestens innerhalb von drei Geschäftstagen nach Datum des Geschäfts, der Gesellschaft bekannt zu geben.
                </p>
            </li>
            <li>
                <p>
                    Dieselbe Verpflichtung trifft mit dem Aufsichtsratsmitglied eng verbundene Personen. Eng verbundene Personen sind Ehe- oder Lebenspartner, unterhaltspflichtige Kinder, Verwandte, die im Zeitpunkt des Geschäfts seit mindestens 1 Jahr dem selben Haushalt angehören, sowie juristische Personen, Trusts oder Gesellschaften, deren Führung durch eine dieser Personen wahrgenommen wird, direkt oder indirekt kontrolliert wird, zugunsten einer solchen Person gegründet wurde oder eine sonstige Interessendeckung besteht. Jedes Aufsichtsratsmitglied hat eine entsprechende Aufklärungspflicht gegenüber den zuvor genannten Personen.
                </p>
            </li>
        </ol>
    </li>
    <li>
        <h3>
            Selbstevaluierung/Fortbildung
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Der Aufsichtsrat überprüft regelmäßig, mindestens jedoch einmal jährlich, die Wirksamkeit seiner Aufgabenerfüllung sowie die seiner Ausschüsse. Er kann dabei zur Unterstützung externe Berater hinzuziehen.
                </p>
            </li>
            <li>
                <p>
                   Die Mitglieder des Aufsichtsrats nehmen die für ihre Aufgaben erforderlichen Aus- und Fortbildungsmaßnahmen eigenverantwortlich wahr.
                </p>
            </li>
        </ol>
    </li>
</ol>

  `,
    begründung: undefined,
    templateVariables: [
      aufsichtsratPflichtsitzungenAnzahl,
      aufsichtsratLadungsfristWochen,
      vorstandZustimmungspflichtSchwelle,
      vorstandZustimmungspflichtSchwelleMitarbeiter,
      ausschussPraesidial,
      ausschussNominierung,
      ausschussPruefung,
      ausschussTechnologie,
    ],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: true,
    noteHandelsregisteranmeldung: undefined,
  });
