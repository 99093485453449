import { Box, Typography, TypographyProps } from "@material-ui/core";
import { SignaturePlaceholderProps } from "./SignaturePlaceholderInterfaces";
import { useSignaturePlaceholderStyles } from "./SignaturePlaceholderStyles";

/**
 * Placeholder for a signature on a document.
 */
const SignaturePlaceholder = (
  props: SignaturePlaceholderProps
): JSX.Element => {
  const classes = useSignaturePlaceholderStyles();

  const typoProps: TypographyProps = {
    variant: "body1",
    ...props.typographyProps,
  };

  return (
    <Box className={classes.wrapper}>
      <Typography className={classes.typo} {...typoProps}>
        {props.children}
      </Typography>
    </Box>
  );
};

export default SignaturePlaceholder;
