import { planJahr } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageWirtschaftsplanFeststellung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.regelmäßigerBeschluss,
  vorlage_rolleTyp: [
    RolleInGesellschaft.Mitglieder,
    RolleInGesellschaft.Aufsichtsrat,
    RolleInGesellschaft.Beirat,
  ],
  vollzug: [Vollzug.selfexecuting],
  tags: [
    "Jahresplanung",
    "Unternehmensplanung",
    "planen",
    "Liquiditätsplan",
    "Jahresplan",
    "Finanzplan",
    "Businessplan",
    "Business plan",
    "Ertragsplanung",
    "Investitionsplan",
    "Kapitalbedarfsplan",
    "Finanzierungsplan",
    "Bilanzplan",
    "Personalplan",
    "Personalbedarfsplan",
    "Beschaffungsplan",
    "Produktioinsplan",
    "Materialbedarfsplan",
    "Verwaltungsplan",
    "Absatzplan",
    "Budgetplan",
    "Wirtschaftsplan",
    "Erfolgsplan",
    "Vermögensplan",
    "Stellenübersicht",

    "feststellen",
    "Feststellung",
    "genehmigen",
    "absegnen",
    "zustimmen",
    "grünes Licht",
    "Weg frei machen",
    "Okay geben",
    "O.K. geben",
    "OK geben",
    "Erlaubnis",
    "Unterstützung",
    "erlauben",
    "unterstützen",
    "einwilligen",
    "Einwilligung",
    "Genehmigung",
    "Zustimmung",
    "Approval",
    "blessing",
    "confirmation",
    "consent",
    "endorsement",
    "green light",
    "persmission",
    "ratification",
    "support",
  ],

  description: `
  <p>Die Aufstellung von Wirtschaftsplänen soll eine zukunftsgerichtete Unternehmensentwicklung sicherstellen. Insbesonde bei kommunalen Unternehmen ist die Beschlussfassung von Wirtschaftsplänen gesetzlich vorgeschrieben und in den entsprechenden Satzungen verankert.
  </p>

  <p>Ein Wirtschaftsplan beruht typischerweise Teilplänen mit einem Planungshorizont von einem Jahr, nämlich:
  </p>
    <ul data-list-style="square">
      <li><p><strong>Erfolgsplan,</strong></p></li>
       <li><p><strong>Liquiditäts- und Vermögensplan,</strong></p></li>
        <li><p><strong>Stellenübersicht,</strong></p></li>
    </ul>

  <p>sowie aus einer <strong>fünfjähigen Finanzplanung</strong>. </p>
  `,
  bezug: [
    "EntlastungRegelmäßigAufsichtsrat",
    "EntlastungKomplementärRegelmäßig",
    "EntlastungRegelmäßigKomplementär",
    "EntlastungRegelmäßigGeschäftsführenderPartner",
    "EntlastungRegelmäßigBeirat",
    "EntlastungRegelmäßigGeschäftsführenderGesellschafter",
    "EntlastungRegelmäßigGeschäftsführer",
    "EntlastungRegelmäßigKomplementärGmbH",
    "AbschlussprüferBestellung",
    "JahresabschlussAufstellung",
    "JahresabschlussFeststellung",
    "VerwendungDesGewinns",
  ],
  kurzBezeichnung: "Feststellung des Wirtschaftsplans",
  metaDescription:
    "Wirtschaftsplan mit Erfolgsplan, Vermögensplan und Stellenübersicht per Beschluss feststellen. Juristisch geprüfte Muster/Vorlagen kostenlos nutzen.",
  antrag: `
   <p>
Der als <strong>Anlage</strong> beigefügte
  </p>

  <p>
    <ul data-list-style="none">
      <li><p>
       Wirtschaftsplan für den Zeitraum ${insertTemplateVariable(planJahr)}
      </p></li>
    </ul>
  </p>

  <p>
    wird festgestellt und genehmigt.
  </p>
  `,
  begründung: undefined,
  templateVariables: [planJahr],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
