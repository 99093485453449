import { AllVotingModeSwitchValues } from "../../../data/juristicalData/votingModes/votingModeInterfaces";
import { votingModeSwitchEntries } from "../../../data/juristicalData/votingModes/votingModeSwitchEntries";
import { RVORootState } from "../../storeInterfaces";

export const stateVotingModeEmpty: RVORootState["votingMode"] = {
  switchValues: Object.fromEntries(
    votingModeSwitchEntries.map(([switchKey]) => [switchKey, null])
  ) as AllVotingModeSwitchValues,
  changeVotingModeTrigger: 0,
};
