import { Button, Typography, TypographyProps } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { clsx } from "clsx";
import { useMemo } from "react";
import { DropdownTextProps } from "./DropdownTextInterfaces";
import { useDropdownTextStyles } from "./DropdownTextStyles";

const typoPropsDefault: TypographyProps = {
  variant: "body1",
};

/**
 * A text that is suggesting a dropdown option, but it's actually a button.
 */
const DropdownText = ({
  onClick,
  typographyProps,
  children,
  className,
  iconOverride,
}: DropdownTextProps): JSX.Element => {
  const classes = useDropdownTextStyles();

  const typoProps = useMemo(
    () => ({
      ...typoPropsDefault,
      ...typographyProps,
      className: clsx(typographyProps?.className, classes.typography),
    }),
    [classes.typography, typographyProps]
  );

  return (
    <Button
      variant="text"
      onClick={onClick}
      className={className}
      endIcon={iconOverride || <KeyboardArrowDownIcon />}
    >
      <Typography {...typoProps}>{children}</Typography>
    </Button>
  );
};

export default DropdownText;
