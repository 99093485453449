import { nameGeschäftsleiter, weisungInhalt } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageWeisungAllgemein: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.geschäftsführer,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.zugang],
  bezug: [],
  tags: [
    "Weisung",
    "Anweisung",
    "Anordnung",
    "Befehl",
    "weisen",
    "anweisen",
    "anordnen",
    "Instruktion",
    "instruieren",
    "instruction",
    "order",
    "command",
    "befolgen",
    "obey",

    "Entscheider",
    "Entscheidungsträger",
    "Direktor",
    "Director",
    "Vorstand",
    "Vorsitzender",
    "Führung",
    "Leitung",
    "Leiter",
    "Geschäftsführung",
    "Geschäftsleitung",
    "Geschäftsleiter",
    "Chef",
    "Leiter",
    "Vorgesetzter",
    "Führungskraft",
    "Geschäftsführer",
    "Manager",
    "CEO",
    "CTO",
    "CFO",
    "leader",
    "head",
    "chief",
    "executive",
    "boss",
    "governor",
    "governance",
  ],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Weisung an Geschäftsführung (Freitext)",
  metaDescription:
    "Gesellschafterbeschluss, der Geschäftsführer anweist/beauftragt, etwas zu tun/zu unterlassen. Juristisch geprüfte Muster/Vorlagen kostenlos downloaden.",

  description: `
<p>
  Mit dieser Vorlage kannst du für beliebige Geschäftsführungsmaßnahmen eine Weisung an die Geschäftsführung erteilen!
  </p>

<p>
Falls der Geschäftsführung zum <strong>Abschluss eines Vertrags</strong> eine Weisung erteilt werden soll, könnten die folgende Spezialvorlagen noch hilfreicher für dich sein:
</p>

      <ul data-list-style="square">
        <li><p><a href="/beschluss-vorlagen/weisung-zum-abschluss-eines-vertrags-(mit-anlage)">Weisung zum Abschluss eines Vertrags (<strong>mit Anlage</strong>)</a>,</p></li>
        <li><p><a href="/beschluss-vorlagen/weisung-zum-abschluss-eines-vertrags-(eckpunkte)">Weisung zum Abschluss eines Vertrags (<strong>mit vorgegebenen Eckpunkten</strong>)</a>.</p></li>
</ul>

  <p>
  Durch Erteilung einer Weisung können Gesellschafter (oder der Beirat bzw. Aufsichtsrat) unmittelbaren Einfluss auf die Tätigkeit der Geschäftsführung nehmen.
  </p>
<p>
Inhaltlich kann eine Weisung flexibel ausgestaltet werden:
</p>

    <p>
      <ul data-list-style="square">
        <li><p>Die Weisung kann ein <strong>Gebot zum Tätigwerden ("Do")</strong> enthalteny</p></li>
        <li><p>oder ein <strong>Verbot ("Don't")</strong> aussprechen ("Don't").</p></li>
        <li><p>Sie kann sich auf einen <strong>Einzelfall</stronG> beziehen</p></li>
        <li><p>oder eine allgemeine <strong>Regel</strong> für die Geschäftsführung aufstellen.</p></li>
      </ul>
    </p>


  `,
  antrag: `
  <p>
    ${insertTemplateVariable(
      nameGeschäftsleiter
    )} wird die folgende Weisung erteilt:
  </p>

    <ul data-list-style="none">
      <li><p>
      ${insertTemplateVariable(weisungInhalt)}
      </p></li>
    </ul>

    <p>
    Zum Zwecke der Erfüllung der vorbezeichneten Weisung wird zugleich Befreiung von den Beschränkungen des § 181 BGB erteilt, soweit erforderlich.
  </p>

  `,
  begründung: undefined,
  templateVariables: [nameGeschäftsleiter, weisungInhalt],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
