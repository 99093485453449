import { zuVersicherendePerson } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageZustimmungDUndOVertragsabschlussAnlage: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [
      CompanyTypeName.GmbHDe,
      CompanyTypeName.UgDe,
      CompanyTypeName.GmbHCoKgDe,
      CompanyTypeName.UgCoKgDe,
      CompanyTypeName.KgDe,
      CompanyTypeName.OHgDe,
      CompanyTypeName.PartGmbBDe,
      CompanyTypeName.PartGDe,
    ],
    vorlageKategorie: VorlageKategorie.geschäftsführer,
    vorlage_rolleTyp: [
      RolleInGesellschaft.Mitglieder,
      RolleInGesellschaft.Aufsichtsrat,
    ],
    vollzug: [Vollzug.zugang],
    bezug: ["vorlageZustimmungVertragsabschlussDUndOEckpunkte"],
    zustimmungErforderlichDes: undefined,
    metaDescription: `Gesellschafterbeschluss für Abschuss einer D&O-Versicherung. Juristisch geprüfte Muster/Vorlagen kostenlos.`,

    kurzBezeichnung:
      "Zustimmung zum Abschluss einer D&O-Versicherung (mit Anlage)",
    description: `
  <p>
  Geschäftsführer verletzten ihre Pflichten gegenüber ihrer Gesellschaft, wenn sie für sich eine Unternehmens-D&O-Versicherung abschließen, ohne dass ein legitimierender Gesellschafterbeschluss gefasst worden ist. Im Schadensfall kann der Versicherungsschutz fehlen, wenn die Gesellschafter dem Versicherungsabschluss nicht zugestimmt haben.
  </p>
  <p>
  Diese Beschlussvorlage hilft dem Geschäftsführer, sich die notwendige Zustimmung der Gesellschafter (oder des Beirats oder Aufsichtsrats) einzuholen. Der Versicherungsvertrag, für den die Zustimmung gelten soll, ist beizufügen.
  </p>
  `,
    tags: [
      "D&O",
      "Versicherung",
      "D&O-Versicherung",
      "Vermögensschaden-Haftpflichtversicherung",
      "Managerversicherung",
      "Managerhaftpflichtversicherung",
      "Organversicherung",
      "versichern",
      "Abschluss einer D&O-Versicherung",

      "Entscheider",
      "Entscheidungsträger",
      "Direktor",
      "Director",
      "Vorstand",
      "Vorsitzender",
      "Führung",
      "Leitung",
      "Leiter",
      "Geschäftsführung",
      "Geschäftsleitung",
      "Geschäftsleiter",
      "Chef",
      "Leiter",
      "Vorgesetzter",
      "Führungskraft",
      "Geschäftsführer",
      "Manager",
      "CEO",
      "CTO",
      "CFO",
      "leader",
      "head",
      "chief",
      "executive",
      "boss",
      "governor",
      "governance",

      "feststellen",
      "Feststellung",
      "genehmigen",
      "absegnen",
      "zustimmen",
      "grünes Licht",
      "Weg frei machen",
      "Okay geben",
      "O.K. geben",
      "OK geben",
      "Erlaubnis",
      "Unterstützung",
      "erlauben",
      "unterstützen",
      "einwilligen",
      "Einwilligung",
      "Genehmigung",
      "Zustimmung",
      "Approval",
      "blessing",
      "confirmation",
      "consent",
      "endorsement",
      "green light",
      "persmission",
      "ratification",
      "support",
    ],

    antrag: `
  <p>
    Der Geschäftsführung wird die Zustimmung erteilt, den  <strong>in der Anlage beigefügten</strong>
  </p>


    <ul data-list-style="none">
    <li><p>
      Versicherungsvertrag über eine Vermögensschaden-Haftpflichtversicherung (D&O-Versicherung) für den Geschäftsführer ${insertTemplateVariable(
        zuVersicherendePerson
      )} als versicherte Person
      </p>
        </p></li>
    </ul>

     <p>
  im Namen der und mit Wirkung für die Gesellschaft abzuschließen.
  </p>

  `,
    begründung: undefined,
    templateVariables: [zuVersicherendePerson],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
