import { createStyles, makeStyles } from "@material-ui/core";
import RVOModal from "../RVOModal/RVOModal";
import { RVOModalLevel, RVOModalProps } from "../RVOModal/RVOModalInterfaces";

const useRVOSettingStyles = makeStyles(() =>
  createStyles({
    modal: {
      zIndex: "1301 !important" as unknown as number,
    },
  })
);

/**
 * A visual full-screen success message.
 */
export const RVOSetting = (props: RVOModalProps): JSX.Element | null => {
  const classes = useRVOSettingStyles();

  return (
    <RVOModal
      className={classes.modal}
      {...{ ...props, level: RVOModalLevel.Setting }}
    />
  );
};
