import {
  geschäftsanteileNachTeilungAnzahl,
  geschäftsanteileNachTeilungNennbetragJeweils,
  geschäftsanteileNummerVorTeilung,
  geschäftsanteileTeilungNennbetragGesamt,
  nameGesellschafter,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGesellschafterGeschaftsanteilTeilung: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.kapital,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.handelsregisterGesellschafterliste],
    bezug: [],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung: "Teilung eines Gesellschafteranteils",
    description: undefined,
    antrag: `<ol>
  <li><p>Der von ${insertTemplateVariable(
    nameGesellschafter
  )} gehaltene Geschäftsanteil mit laufender Nr. ${insertTemplateVariable(
      geschäftsanteileNummerVorTeilung
    )}  und einem Gesamt-Nennbetrag von ${insertTemplateVariable(
      geschäftsanteileTeilungNennbetragGesamt
    )} wird in ${insertTemplateVariable(
      geschäftsanteileNachTeilungAnzahl
    )} Geschäftsanteile mit einem Nennbetrag von je ${insertTemplateVariable(
      geschäftsanteileNachTeilungNennbetragJeweils
    )} geteilt.</p></li>

   <li><p>Die Geschäftsführung wird angewiesen, eine aktualisierte Gesellschafterliste gemäß § 40 Abs. 1 GmbHG zum Handelsregister einzureichen.</p></li>


</ol>`,
    begründung: undefined,
    templateVariables: [
      nameGesellschafter,
      geschäftsanteileNummerVorTeilung,
      geschäftsanteileTeilungNennbetragGesamt,
      geschäftsanteileNachTeilungAnzahl,
      geschäftsanteileNachTeilungNennbetragJeweils,
    ],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: true,
    noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass aufgrund der geänderten Geschäftsanteile eine aktualisierte Gesellschafterliste zum Handelsregister durch die Geschäftsführer eingereicht werden muss (§ 40 Abs. 1 GmbHG). Die neue Gesellschafterliste muss von den Geschäftsführern in vertretungsberechtigter Zahl unterschrieben und über einen Notar zum Handelsregister eingereicht werden.</p>`,
  });
