import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useRVOCtaBlockCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctaBlockCardWrapper: {
      textAlign: "center",
      borderRadius: "10px",
      minHeight: "300px",
      margin: theme.spacing(2, 0),
      display: "flex",
      justifyContent: "center",
    },
    subheadline: {
      marginBottom: theme.spacing(0.5),
    },
    text: {
      color: theme.palette.common.white,
    },
    center: {
      textAlign: "center",
    },
    content: {
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    ctaLink: {
      margin: "0 auto",
      "& span": {
        color: theme.palette.primary.dark,
        "& svg": {
          fill: theme.palette.primary.dark,
        },
      },
    },
  })
);
