import {
  bankDatenGesellschaft,
  betragKapitalrücklage,
  nameGesellschafter,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGesellschafterKapitalrucklage: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
  vorlageKategorie: VorlageKategorie.kapital,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.selfexecuting],
  bezug: [],
  tags: [
    "Darlehen",
    "Darlehn",
    "Kredit",
    "Pump",
    "Finanzierung",
    "Zins",
    "Kapital",
    "Liquidität",
    "Risiko",
    "Venture Capital",
    "Bonität",
    "Einzahlen",
    "Privatvermögen",
    "Gesellschafterdarlehen",
    "Gesellschafterdarlehn",
    "Verzinsung",
    "Mittelbeschaffung",
    "Fazilität",
    "Kreditlinie",
    "Kreditrahmen",
    "Mittel",
    "Geld",
    "sanierung",
    "loan",
    "allowance",
    "credit",
    "inveestment",
    "invest",
    "mortage",
    "risk",
    "money",
    "capital",
    "crisis",
    "Kapitalerhöhung",
    "Kapitalrücklage",
    "Rücklage",
    "Eigenkapital",
    "Krise",
    "Fremdkapital",
    "freie Rücklage",
    "Gesellschafter",
    "Mitglied",
    "Anteilsinhaber",
    "shareholder",
    "Partner",
    "Teilhaber",
    "Socius",
    "shareowner",
    "bondholder",
  ],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Einzahlung in die Kapitalrücklage",
  metaDescription:
    "Gesellschafterbeschluss, um Geld in Kapitalrücklage der GmbH bzw. UG einzahlen. Juristisch geprüfte Muster/Vorlagen kostenlos nutzen.",
  description: `
  <p>
    Die Einzahlung in die Kapitalrücklage ist eine Möglichkeit für die Gesellschafter, um ihre GmbH  mit <strong>liquiden Mitteln</strong> auszustatten.
  </p>

  <p>
  Weitere Möglichkeiten sind:
  </p>


    <ul data-list-style="square">
      <li><p><a href="/beschluss-vorlagen/gewahrung-eines-gesellschafterdarlehens">Gesellschafterdarlehen</a>,</p></li>
      <li><p><a href="/beschluss-vorlagen/barkapitalerhohung">Kapitalerhöhung</a>.</p></li>
    </ul>

  `,
  antrag: `
  <p>
    ${insertTemplateVariable(nameGesellschafter)} wird einen Betrag in Höhe von
  </p>

  <ul data-list-style="none">
    <li><p>
       ${insertTemplateVariable(betragKapitalrücklage)}
    </p></li>
  </ul>

  <p>
    in die Kapitalrücklage der Gesellschaft einzahlen.
  </p>

  <p>
    Die Einzahlung erfolgt binnen fünf Werktagen auf das Bankkonto der Gesellschaft mit der folgenden IBAN: ${insertTemplateVariable(
      bankDatenGesellschaft
    )}.
  </p>

  `,
  begründung: undefined,
  templateVariables: [
    nameGesellschafter,
    betragKapitalrücklage,
    bankDatenGesellschaft,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
