import { clsx } from "clsx";
import { noop } from "lodash-es";
import { useTranslation } from "react-i18next";
import {
  pricingPath,
  securityAndPrivacyPath,
  ueberResolvioPath,
  wissenPath,
} from "../../../../../../helpers/general/frontendUrlPaths";
import { NVONPageSectionLink } from "../../../../../02-molecules/RVOPageSectionLinks/NVONPageSectionLink/NVONPageSectionLink";
import { RVONavigationSection } from "../../../../../02-molecules/RVOPageSectionLinks/RVOPageSectionLinksInterfaces";
import { HeaderCenterComponentProps } from "../../../PageLayoutInterfaces";
import { useRVOHeaderCenterMenuStyles } from "./RVOHeaderCenterMenuStyles";

const RVOHeaderCenterMenu = (
  props: HeaderCenterComponentProps
): JSX.Element => {
  const classes = useRVOHeaderCenterMenuStyles(props);
  const { t } = useTranslation();

  return (
    <div className={clsx(classes.wrapper, classes.mainWrapper)}>
      <ul className={clsx(classes.wrapper, classes.centerLinks)}>
        {/* currently disables, as we do not have on page section links anymore */}

        {/* <RVOPageSectionLinks navigationSection={RVONavigationSection.Header} /> */}

        <NVONPageSectionLink
          navigationSection={RVONavigationSection.Header}
          href={`${securityAndPrivacyPath}`}
          onClick={noop}
        >
          {t("header.securityAndDataProtection")}
        </NVONPageSectionLink>

        <NVONPageSectionLink
          navigationSection={RVONavigationSection.Header}
          href={`${pricingPath}/`}
          onClick={noop}
        >
          {t("header.pricing")}
        </NVONPageSectionLink>

        <NVONPageSectionLink
          navigationSection={RVONavigationSection.Header}
          href={`${ueberResolvioPath}/`}
          onClick={noop}
        >
          {t("header.ueber")}
        </NVONPageSectionLink>

        <NVONPageSectionLink
          navigationSection={RVONavigationSection.Header}
          href={`${wissenPath}/`}
          onClick={noop}
          className={classes.wissenLink}
        >
          {t("header.wissen")}
        </NVONPageSectionLink>
      </ul>
    </div>
  );
};

export default RVOHeaderCenterMenu;
