import { anschriftVerwahrer, nameVerwahrer } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageAuflosungMitLiquidationUndSperrjahr: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
  vorlageKategorie: VorlageKategorie.liquidation,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.bundesanzeiger, Vollzug.handelsregisterAnmeldung],
  bezug: [],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Auflösung mit Liquidation und Sperrjahr",
  description:
    "Der Gesellschafterbeschluss über die Auflösung bedarf einer 3/4-Mehrheit der abgegebenen Stimmen, wenn der Gesellschaftsvertrag keine andere Mehrheit vorsieht (§ 60 Abs. 1 Nr. 2 GmbHG). Eine Beurkdungspflicht besteht grundsätzlich nicht.",
  antrag: `Die Gesellschaft wird nach folgender Maßgabe aufgelöst:

  <ol><li><p>Die Gesellschaft ist im Wege der Liquidation abzuwickeln und besteht bis Beendigung der Liquidation fort.</p></li>

  <li><p> Der Firma der Gesellschaft ist der auf die Liquidation hindeutender Zusatz "in Liquidation" anzufügen.</p></li>

  <li><p>Die amtierenden Geschäftsführer sind die Liquidatoren der Gesellschaft, soweit sie nicht abberufen werden. Die Liquidatoren haben die Auflösung im Handelsregister anzumelden und im Bundesanzeiger und ggf. in weiteren im Gesellschaftsvertrag bestimmten Medien mit einem Aufruf an die Gläubiger bekanntzumachen.</p></li>

  <li><p>Das Liquidationsgeschäftsjahr beginnt mit der Eintragung der Auflösung im Handelsregister. Die Liquidatoren haben zu Beginn des Liquidationsgeschäftsjahrs eine Liquidationseröffnungsbilanz sowie nach Tilgung der Verbindlichkeiten und Ablauf des Sperrjahrs eine Liquidationsschlussbilanz aufzustellen, die die Gesellschafter jeweils feststellen müssen.</p></li>

  <li><p>Im Rahmen der Auflösung sollen die Gläubiger befriedigt und sodann das restliche Vermögen verteilt werden. Die Verteilung darf nicht vor Tilgung oder Sicherstellung der Schulden der Gesellschaft und nicht vor Ablauf eines Jahres seit der Veröffentlichung des Gläubigeraufrufs (Sperrjahr) erfolgen.</p></li>

  <li><p>Nach Beendigung der Liquidation haben die Liquidatoren den Schluß der Liquidation zur Eintragung in das Handelsregister anzumelden und die Gesellschaft löschen zu lassen.</p></li>

  <li><p>Die Bücher und Schriften der Gesellschaft werden nach Beendigung der Liquidation ${insertTemplateVariable(
    nameVerwahrer
  )}, ${insertTemplateVariable(
    anschriftVerwahrer
  )}, für die Dauer von zehn Jahren in Verwahrung gegeben.</p></li></ol>`,
  begründung: undefined,
  templateVariables: [nameVerwahrer, anschriftVerwahrer],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: true,
  noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss (§ 65 Abs. 1 Satz 1 GmbHG). Die Handelsregisteranmeldung muss von den Geschäftsführern (bzw. Liquidatoren) in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
});
