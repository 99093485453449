import { alpha, createStyles, makeStyles, Theme } from "@material-ui/core";
import { important } from "@nvon/react-toolbox";
import {
  containerPaddingBigDevices,
  containerPaddingSmallDevicesTopBottom,
} from "../../../helpers/clientSide/constants";
import {
  GradientColors,
  isBackgroundColor,
  isBackgroundGradient,
  RVOBackgroundColor,
  RVOContainerProps,
} from "./RVOContainerInterfaces";

export const useRVOContainerStyles = makeStyles((theme: Theme) => {
  const backgroundColorMap: { [key in RVOBackgroundColor]: string } = {
    [RVOBackgroundColor.PrimaryDarkBlue]: theme.palette.primary.dark,
    [RVOBackgroundColor.SecondaryLightBlue]: theme.palette.secondary.light,
    [RVOBackgroundColor.SecondaryMediumBlue]: theme.palette.secondary.main,
    [RVOBackgroundColor.WarningLightGold]: alpha(
      theme.palette.warning.light,
      0.4
    ),
  };

  return createStyles({
    wrapper: {
      position: "relative",
      width: "100%",
      padding: 0,
      overflow: ({ overflow }: RVOContainerProps) => overflow,
      backgroundColor: ({ background }) =>
        background && isBackgroundColor(background)
          ? backgroundColorMap[background]
          : "transparent",
      backgroundImage: ({ background }) =>
        background && isBackgroundGradient(background)
          ? `linear-gradient(${
              backgroundColorMap[GradientColors[background][0]]
            }, ${backgroundColorMap[GradientColors[background][1]]})`
          : "none",
    },
    container: {
      paddingTop: ({ noPaddingTop }) =>
        noPaddingTop ? important(0) : theme.spacing(containerPaddingBigDevices),
      paddingBottom: ({ paddingBottom }) =>
        paddingBottom
          ? theme.spacing(containerPaddingBigDevices)
          : "0 !important",
      [theme.breakpoints.down("sm")]: {
        paddingTop: ({ noPaddingTop }) =>
          noPaddingTop
            ? important(0)
            : theme.spacing(containerPaddingSmallDevicesTopBottom),
        paddingBottom: ({ paddingBottom }) =>
          paddingBottom
            ? theme.spacing(containerPaddingSmallDevicesTopBottom)
            : important(0),
      },
    },
  });
});
