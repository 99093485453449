import { Box } from "@material-ui/core";
import { RVOFootnoteProps } from "./RVOFootnoteInterfaces";
import { useRVOFootnoteStyles } from "./RVOFootnoteStyles";

const RVOFootnote = (props: RVOFootnoteProps): JSX.Element => {
  const classes = useRVOFootnoteStyles();

  return (
    <Box className={classes.footnoteWrapper} id={props.footnoteId}>
      <Box className={classes.footnoteBox}>
        <li className={classes.footnote}>
          <Box className={classes.footnoteInlet}>{props.children}</Box>
        </li>
      </Box>
    </Box>
  );
};

export default RVOFootnote;
