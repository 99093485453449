import { Typography } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { clsx } from "clsx";
import { IconButton } from "gatsby-material-ui-components";
import { MouseEvent, ReactNode, useEffect, useMemo } from "react";
import { useBooleanState } from "../../../helpers/clientSide/useBooleanState";
import HtmlFromLangFile from "../../01-atoms/HtmlFromLangFile/HtmlFromLangFile";
import RVOAskUsInTheChat from "../RVOAskUsInTheChat/RVOAskUsInTheChat";
import RVOModal from "../RVOModal/RVOModal";
import { RVOHelpButtonProps } from "./RVOHelpButtonInterfaces";
import { useRVOHelpButtonStyles } from "./RVOHelpButtonStyles";

/**
 * A button triggering a help view (right now, a modal).
 */
const RVOHelpButton = ({
  iconOverride,
  helpText,
  customButtonComponent,
  helpTitle,
  className,
  helpButtonIconClassName,
  helpButtonWrapperClassName,
  modalEmoji,
  modalLevel,
  triggerExternalClose,
  iconColor,
  size,
}: RVOHelpButtonProps): JSX.Element => {
  const {
    off: setIsOpenFalse,
    on: setIsOpenTrue,
    state: isOpen,
  } = useBooleanState(false);

  const defaultIcon = isOpen ? <HighlightOffIcon /> : <HelpOutlineIcon />;

  const iconToUse = iconOverride || defaultIcon;

  const classes = useRVOHelpButtonStyles();

  useEffect(() => {
    if (triggerExternalClose) {
      setIsOpenFalse();
    }
  }, [triggerExternalClose, setIsOpenFalse]);

  const modalContent = useMemo(() => {
    if (typeof helpText !== "function") {
      return (
        <Typography variant="body1">
          <HtmlFromLangFile contentContainingHtml={helpText as string} />
        </Typography>
      );
    }

    /*
     * If helpText is given as a function,
     *  only invoke it when the modal is open.
     */
    if (isOpen) {
      return helpText() as ReactNode;
    }

    return null;
  }, [helpText, isOpen]);

  const onClick = (e: MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setIsOpenTrue();
  };

  const button = customButtonComponent?.({ onClick }) || (
    <IconButton
      aria-label={helpTitle}
      className={clsx(classes.button, helpButtonWrapperClassName)}
      classes={{ label: helpButtonIconClassName }}
      color="primary"
      onClick={onClick}
      size={size}
      style={{ color: iconColor ? iconColor : undefined }}
    >
      {iconToUse}
    </IconButton>
  );

  const emoji = modalEmoji || HelpOutlineIcon;

  return (
    <div className={clsx(className, "rvoHelpButton")}>
      {button}

      <RVOModal
        title={helpTitle}
        isOpen={isOpen}
        level={modalLevel}
        emoji={emoji}
        onClose={setIsOpenFalse}
      >
        <>
          {modalContent}

          <RVOAskUsInTheChat additionalOnClick={setIsOpenFalse} />
        </>
      </RVOModal>
    </div>
  );
};

export default RVOHelpButton;
