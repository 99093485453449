/* eslint-disable import/no-unused-modules */
import { LegacyTemplateBase } from "../VorlageInterface";
import { StripeProduct } from "../generatedTypes/StripeProduct";
import { vorlageAgDeAufsichtsratGeschäftsordnungMitMitbestimmungMitAusschüssen } from "../vorlagenLibrary/vorlageAgDeAufsichtsratGeschäftsordnungMitMitbestimmungMitAusschüssen";
import { vorlageAgDeAufsichtsratGeschäftsordnungOhneMitbestimmungMitAusschüssen } from "../vorlagenLibrary/vorlageAgDeAufsichtsratGeschäftsordnungOhneMitbestimmungMitAusschüssen";
import { vorlageAgDeVorstandBestellung } from "../vorlagenLibrary/vorlageAgDeVorstandBestellung";
import { vorlageArbeitsvertragEinfach } from "../vorlagenLibrary/vorlageArbeitsvertragEinfach";
import { vorlageArbeitsvertragEinfachTeilzeit } from "../vorlagenLibrary/vorlageArbeitsvertragEinfachTeilzeit";
import { vorlageArbeitsvertragPhantomStock } from "../vorlagenLibrary/vorlageArbeitsvertragPhantomStock";
import { vorlageDienstvertragFreierMitarbeiter } from "../vorlagenLibrary/vorlageDienstvertragFreierMitarbeiter";
import { vorlageGesellschafterEinbringungsvertragÜbertragungDomain } from "../vorlagenLibrary/vorlageGesellschafterKapitalrucklageÜbertragungDomain";
import { vorlageGesellschaftervereinbarungGrowthShares } from "../vorlagenLibrary/vorlageGesellschaftervereinbarungGrowthShares";
import { vorlageVereinAnbauvereinigungBeitragsordnung } from "../vorlagenLibrary/vorlageVereinAnbauvereinigungBeitragsordnung";
import { vorlageVereinSatzungAnbauvereinigung } from "../vorlagenLibrary/vorlageVereinSatzungAnbauvereinigung";
import { vorlageVereinVorstandErnennungPraeventivbeauftragter } from "../vorlagenLibrary/vorlageVereinVorstandErnennungPraeventivbeauftragter";

type PremiumProduct = Exclude<StripeProduct, StripeProduct.Free>;

export const premiumVorlagen: Record<PremiumProduct, LegacyTemplateBase[]> = {
  [StripeProduct.Starter]: [
    vorlageArbeitsvertragEinfach,
    vorlageArbeitsvertragEinfachTeilzeit,
    vorlageGesellschafterEinbringungsvertragÜbertragungDomain,
    vorlageDienstvertragFreierMitarbeiter,
    vorlageVereinSatzungAnbauvereinigung,
    vorlageVereinVorstandErnennungPraeventivbeauftragter,
    vorlageVereinAnbauvereinigungBeitragsordnung,
  ],
  [StripeProduct.Pro]: [
    vorlageArbeitsvertragPhantomStock,
    vorlageGesellschaftervereinbarungGrowthShares,
    vorlageAgDeAufsichtsratGeschäftsordnungMitMitbestimmungMitAusschüssen,
    vorlageAgDeAufsichtsratGeschäftsordnungOhneMitbestimmungMitAusschüssen,
    vorlageAgDeVorstandBestellung,
  ],
};
