import {
  switchVorstandsmitgliedAnstellungsvertrag,
  switchVorstandsmitgliedBesonderesAmt,
  switchVorstandsmitgliedRessortzuweisung,
  variantsVorstandsmitgliedBefreiungInsichgeschaeft,
  variantsVorstandsmitgliedVertretungsbefugnis,
  vorstandsmitgliedAmtszeit,
  vorstandsmitgliedBestellungTag,
  vorstandsmitgliedGeburtsdatum,
  vorstandsmitgliedName,
  vorstandsmitgliedWohnort,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageAgDeVorstandBestellung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.AgDe,
    CompanyTypeName.SeDe,
    CompanyTypeName.KgaADe,
  ],
  vorlageKategorie: VorlageKategorie.geschäftsführerNeu,
  vorlage_rolleTyp: [RolleInGesellschaft.Aufsichtsrat],
  vollzug: [Vollzug.zugang, Vollzug.handelsregisterAnmeldung],
  bezug: [],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Bestellung eines neuen Vorstandsmitglieds",
  description: `Mit dieser Beschluss-Vorlage/Beschluss-Muster kann der Aufsichtsrat ein neues Vorstandsmitglied bestellen.`,
  metaDescription:
    "Bestellung eines Vorstandsmitglieds einer Aktiengesellschaft durch Aufsichtsratsbeschluss. Juristisch geprüfte Standard-Muster/Vorlagen kostenlos ausfüllen und downloaden.",
  tags: [
    "bestellen",
    "berufen",
    "installieren",
    "Vorstand",
    "Vorstandsmitglied",
    "Einzelvertretungsbefugnis",
    "Gesamtvertretungsbefugnis",
    "Gemischte Vertretungsbefugnis",
    "Befreiung von § 181 BGB",
    "Befreiung vom Verbot des Insichgeschäfts",
    "Gestattung von Mehrfachvertretung",
  ],
  antrag: `
  <ol>
    <li>
      <p>
          Zum neuen Vorstandsmitglied der Gesellschaft wird mit Wirkung ab ${insertTemplateVariable(
            vorstandsmitgliedBestellungTag
          )} bestellt:
      </p>
      <ul data-list-style="none">
            <li>
                <p>
                  ${insertTemplateVariable(vorstandsmitgliedName)},<br/>
                  geboren am ${insertTemplateVariable(
                    vorstandsmitgliedGeburtsdatum
                  )},<br/>
                  wohnhaft in ${insertTemplateVariable(
                    vorstandsmitgliedWohnort
                  )}.
                </p>
            </li>
      </ul>
    </li>
    <li>
        <p>
            Die Amtszeit von  ${insertTemplateVariable(
              vorstandsmitgliedName
            )} beträgt ${insertTemplateVariable(
    vorstandsmitgliedAmtszeit
  )} Jahre.
        </p>
    </li>

             ${insertTemplateVariable(
               variantsVorstandsmitgliedVertretungsbefugnis
             )}

             ${insertTemplateVariable(
               variantsVorstandsmitgliedBefreiungInsichgeschaeft
             )}

             ${insertTemplateVariable(switchVorstandsmitgliedRessortzuweisung)}

             ${insertTemplateVariable(switchVorstandsmitgliedBesonderesAmt)}

             ${insertTemplateVariable(
               switchVorstandsmitgliedAnstellungsvertrag
             )}

    <li>
        <p>
             Der Vorsitzende des Aufsichtsrats wird beauftragt und ermächtigt,
         </p>
         <ul>
             <li><p>
                die vorstehenden Beschlüsse unverzüglich an  ${insertTemplateVariable(
                  vorstandsmitgliedName
                )} zu übermitteln und
            </p></li>
             <li><p>
                alle nach eigenem Ermessen erforderlichen und/oder zweckdienlichen Erklärungen abzugeben und entgegenzunehmen sowie alle erforderlichen und/oder zweckdienlichen Handlungen und Maßnahmen vorzunehmen, die im Zusammenhang mit der Durchführung der vorstehenden Beschlüsse stehen.
            </p></li>
        </ul>
    </li>

  </ol>

  `,
  begründung: undefined,
  templateVariables: [
    vorstandsmitgliedBestellungTag,
    vorstandsmitgliedName,
    vorstandsmitgliedGeburtsdatum,
    vorstandsmitgliedWohnort,
    vorstandsmitgliedAmtszeit,
    variantsVorstandsmitgliedVertretungsbefugnis,
    variantsVorstandsmitgliedBefreiungInsichgeschaeft,
    switchVorstandsmitgliedRessortzuweisung,
    switchVorstandsmitgliedBesonderesAmt,
    switchVorstandsmitgliedAnstellungsvertrag,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: true,
  noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss (§ 81 Abs. 1 AktG). Die Handelsregisteranmeldung muss von den Vorstandsmitgliedern in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
});
