import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/playfair-display/400.css";
import "@fontsource/playfair-display/600.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import { ComponentWithChildren } from "@nvon/react-toolbox";
import { SEOContext } from "gatsby-plugin-wpgraphql-seo";
import { merge } from "lodash-es";
import { Helmet } from "react-helmet";
import { Provider as StoreProvider } from "react-redux";
import { defaultLanguage } from "../../../../config/LanguageConfiguration";
import { googleTrackingId } from "../../../helpers/clientSide/constants";
import theme from "../../../layouts/theme";
import { useSiteInfoQuery } from "../../../queries/siteInfoQuery";
import { store } from "../../../state/store";
import { RVOChatCookieModal } from "../../02-molecules/RVOChatCookieModal/RVOChatCookieModal";
import RVOVotingModeUrlTrigger from "../../02-molecules/RVOVotingModeUrlSwitch/RVOVotingModeUrlSwitch";
import PageLayout from "../PageLayout/PageLayout";
import { useCurrentPageLayout } from "../PageLayout/useCurrentPageLayout";
import { RVOModalContextProvider } from "../RVOModalContextProvider/RVOModalContextProvider";
import { RVOPostHogProvider } from "../RVOPostHogProvider";
import { TruendoBrevoChatWidget } from "../TruendoBrevoChatWidget/TruendoBrevoChatWidget";
import { TruendoConsentProvider } from "../TruendoCookieConsent/TruendoConsentProvider";
import TruendoGoogleTagManager from "../TruendoGoogleTagManager/TruendoGoogleTagManager";
import "./../../../i18n/i18n";
import { useDayJs } from "./useDayJs";

/**
 * Wraps all page content globally:
 *  - does not unmount on page navigation
 *  - used for app-wide concerns, such as (theming, state, static meta, layouts …)
 *
 * Inserted through `gatsby-plugin-layout`, which injects `children`.
 */
const GlobalWrapper: ComponentWithChildren = ({ children }) => {
  const layoutOptions = useCurrentPageLayout();
  const siteInfo = useSiteInfoQuery();

  useDayJs();

  // This is somehow necessary because of our strict mode and the maybe type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-assignment
  const seo = siteInfo.wp as any;

  return (
    <>
      {/* setting language as attribute here also because some pages do not have seo and it does not work if we don't add it here */}

      <Helmet htmlAttributes={{ lang: defaultLanguage }} />

      <StoreProvider store={store}>
        <TruendoConsentProvider>
          <RVOModalContextProvider>
            <TruendoGoogleTagManager googleTrackingId={googleTrackingId} />

            <RVOPostHogProvider>
              <ThemeProvider theme={theme}>
                <RVOChatCookieModal />

                <TruendoBrevoChatWidget />

                <SEOContext.Provider
                  value={{
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    global: merge(seo, {
                      schema: { inLanguage: defaultLanguage },
                    }),
                  }}
                >
                  <CssBaseline />

                  <RVOVotingModeUrlTrigger />

                  <PageLayout {...layoutOptions}>{children}</PageLayout>
                </SEOContext.Provider>
              </ThemeProvider>
            </RVOPostHogProvider>
          </RVOModalContextProvider>
        </TruendoConsentProvider>
      </StoreProvider>
    </>
  );
};

export default GlobalWrapper;
