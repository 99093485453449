import SignaturePlaceholder from "../../../../components/01-atoms/SignaturePlaceholder/SignaturePlaceholder";
import { multilineTextFragment } from "../../../../helpers/clientSide/multilineTextFragment";
import { GrNumber } from "../../../../interfaces/grNumber";
import { useCompanyInfo } from "../../../../state/companyInfo/companyInfoSlice";
import { datumBeschluss } from "../../../vorlagen/vorlagenShared/allTemplateVariables";
import { getRoleName } from "../../namesForRoles/getRoleName";
import {
  VotingMode,
  VotingModeIdentifier,
  VotingModeSwitchKey,
} from "../votingModeInterfaces";
import DefaultCoatingHeader from "./DefaultCoatingHeader/DefaultCoatingHeader";
import { useCoatingInformation } from "./useCoatingInformation";

/**
 * Multiple people, unanimous decision, voting asynchronously.
 *
 * "Umlaufbeschluss"
 */
export const votingModeAsyncDecision: VotingMode = {
  identifier: VotingModeIdentifier.AsyncDecision,
  CoatingHeader: () => {
    const coatingInformation = useCoatingInformation(GrNumber.Plural);
    const { articleGenitive, isVerein } = useCompanyInfo();

    if (!coatingInformation) {
      return null;
    }

    const headline = multilineTextFragment(
      `Beschluss`,
      `der ${coatingInformation.whoGenitive}`,
      `${articleGenitive} ${coatingInformation.company}`
    );

    const bodyText = `Sämtliche ${
      coatingInformation.whoNominative
    } ${articleGenitive} ${coatingInformation.company} mit Sitz in ${
      coatingInformation.sitz
    } - nachfolgend auch ${
      isVerein ? '"Verein"' : '"Gesellschaft"'
    } genannt - erklären sich mit der Beschlussfassung im Wege der schriftlichen Abgabe der Stimmen durch Unterzeichnung einverstanden. Auf die Einhaltung etwaiger hiervon abweichenden Bestimmungen zum Verfahren der Beschlussfassung wird, soweit zulässig, einvernehmlich verzichtet. Dies vorausgeschickt, wird mit allen Stimmen der folgende Beschluss gefasst:`;

    return <DefaultCoatingHeader {...{ headline, bodyText }} />;
  },
  CoatingFooter: () => {
    const { firma, whoCanDecide, rechtsform, articleGenitive } =
      useCompanyInfo();
    const who = getRoleName(whoCanDecide, rechtsform, GrNumber.Plural);

    if (!who) {
      return null;
    }

    return (
      <SignaturePlaceholder>{`Unterschriften sämtlicher ${who} ${articleGenitive} ${firma}`}</SignaturePlaceholder>
    );
  },
  label: (t) => t("votingMode.asyncDecision"),
  additionalInputs: [datumBeschluss],
  switchValues: {
    [VotingModeSwitchKey.AreMultiplePeopleInvolved]: true,
    [VotingModeSwitchKey.IsUnanimous]: true,
    [VotingModeSwitchKey.DoPeopleMeetInPerson]: false,
  },
};
