import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useCallback } from "react";
import { getRoleName } from "../../data/juristicalData/namesForRoles/getRoleName";
import {
  maleCompanyTypes,
  vereinCompanyTypes,
} from "../../data/juristicalData/namesForRoles/roleNamesForRechtsform";
import { CompanyTypeName } from "../../data/vorlagen/vorlagenShared/generatedTypes/CompanyTypeName";
import { GrNumber } from "../../interfaces/grNumber";
import { AnyRole } from "../../interfaces/variousInterfaces";
import { useAppDispatch, useAppSelector } from "../hooks";
import { RootState } from "../storeInterfaces";
import { stateCompanyInfoEmpty } from "./states/stateCompanyInfoEmpty";

export type Firma = CompanyInfoState["firma"];

export interface CompanyInfoState {
  firma: string;
  rechtsform: CompanyTypeName | null;
  sitz: string;
  /**
   * Define who can decide in the current Beschluss.
   * The answer will be a certain role, meaning people with this role in the company
   *  will be taking part in the Beschluss-making.
   *
   * Not strictly something belonging to the company,
   *  but rather to an individual Beschluss.
   *
   * But for now, we assume the user only wants to select this once,
   *  and still store it with the company data.
   */
  whoCanDecide: AnyRole | null;
}

export const companyInfoSlice = createSlice({
  name: "companyInfo",
  initialState: stateCompanyInfoEmpty,
  reducers: {
    setFirma: (state, action: PayloadAction<CompanyInfoState["firma"]>) => {
      state.firma = action.payload;
    },
    setSitz: (state, action: PayloadAction<CompanyInfoState["sitz"]>) => {
      state.sitz = action.payload;
    },
    setRechtsform: (
      state,
      action: PayloadAction<CompanyInfoState["rechtsform"]>
    ) => {
      state.rechtsform = action.payload;
    },
    setWhoCanDecide: (
      state,
      action: PayloadAction<CompanyInfoState["whoCanDecide"]>
    ) => {
      state.whoCanDecide = action.payload;
    },
  },
});

// Actions …

export const {
  setFirma: setFirmaUserAction,
  setSitz: setSitzUserAction,
  setWhoCanDecide: setWhoCanDecideUserAction,
  setRechtsform: setRechtsformUseraction,
} = companyInfoSlice.actions;

// … and hooks to use the actions

export const useSetFirma = (): ((firma: CompanyInfoState["firma"]) => void) => {
  const dispatcher = useAppDispatch();
  return (firma) => dispatcher(setFirmaUserAction(firma));
};
export const useSetSitz = (): ((sitz: CompanyInfoState["sitz"]) => void) => {
  const dispatcher = useAppDispatch();
  return (sitz) => dispatcher(setSitzUserAction(sitz));
};
export const useSetRechtsform = (): ((
  rechtsform: CompanyInfoState["rechtsform"]
) => void) => {
  const dispatcher = useAppDispatch();
  return useCallback(
    (rechtsform) => dispatcher(setRechtsformUseraction(rechtsform)),
    [dispatcher]
  );
};
export const useSetWhoCanDecide = (): ((
  whoCanDecide: CompanyInfoState["whoCanDecide"]
) => void) => {
  const dispatcher = useAppDispatch();
  return (whoCanDecide) => dispatcher(setWhoCanDecideUserAction(whoCanDecide));
};

// Selectors …

export const selectCompanyInfo = (state: RootState): CompanyInfoState =>
  state.companyInfo;

export const selectCompanyRechtsform = (
  state: RootState
): CompanyInfoState["rechtsform"] => state.companyInfo.rechtsform;

export const selectCompanyFirma = (
  state: RootState
): CompanyInfoState["firma"] => state.companyInfo.firma;

export const selectCompanySitz = (state: RootState): CompanyInfoState["sitz"] =>
  state.companyInfo.sitz;

export const selectCompanyWhoCanDecide = (
  state: RootState
): CompanyInfoState["whoCanDecide"] => state.companyInfo.whoCanDecide;

// … and hooks to get the data from the selectors

export const useCompanyInfo = (): ReturnType<typeof selectCompanyInfo> & {
  articleGenitive: string;
  isVerein: boolean;
} => {
  const storeData = useAppSelector(selectCompanyInfo);
  const { rechtsform } = storeData;

  const articleGenitive =
    rechtsform && maleCompanyTypes.includes(rechtsform) ? "des" : "der";

  const isVerein = !!rechtsform && vereinCompanyTypes.includes(rechtsform);

  return {
    ...storeData,
    articleGenitive,
    isVerein,
  };
};

/**
 * Return the name of the role that is put in "whoCanDecide".
 *
 * This is dependant on the selected Rechtsform,
 *  and of course the whoCanDecide
 */
export const useWhoCanDecideName = (grNumber: GrNumber): string | null => {
  const rechtsform = useCompanyRechtsform();
  const role = useCompanyWhoCanDecide();

  return getRoleName(role, rechtsform, grNumber);
};

export const useCompanyWhoCanDecide = (): ReturnType<
  typeof selectCompanyWhoCanDecide
> => useAppSelector(selectCompanyWhoCanDecide);

export const useCompanyFirma = (): ReturnType<typeof selectCompanyFirma> =>
  useAppSelector(selectCompanyFirma);

export const useCompanySitz = (): ReturnType<typeof selectCompanySitz> =>
  useAppSelector(selectCompanySitz);

export const useCompanyRechtsform = (): ReturnType<
  typeof selectCompanyRechtsform
> => useAppSelector(selectCompanyRechtsform);

export default companyInfoSlice.reducer;
