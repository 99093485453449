import {
  geschäftsanteileNennbetragGesamt,
  geschäftsanteileNummer,
  klauselAbfindung,
  klauselEinziehung,
  nameGesellschafter,
  wichtigerGrund,
  wichtigerGrundAbwägung,
  wichtigerGrundEntgegnung,
  wichtigerGrundUltimaratio,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGesellschafterEinziehungAusWichtigemGrund: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.ausscheidenEinesGesellschafters,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.zugang, Vollzug.handelsregisterGesellschafterliste],
    bezug: [
      "GesellschafterEinziehungRevalorisierung",
      "GesellschafterEinziehungAufstockung",
    ],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung: "Einziehung aus wichtigem Grund",
    description: undefined,
    antrag: `<ol>
  <li><p>Der/die Geschäftsanteil(e) von ${insertTemplateVariable(
    nameGesellschafter
  )} mit laufender Nr. ${insertTemplateVariable(
      geschäftsanteileNummer
    )}  und einem Gesamt-Nennbetrag von ${insertTemplateVariable(
      geschäftsanteileNennbetragGesamt
    )} werden gemäß § ${insertTemplateVariable(
      klauselEinziehung
    )} des Gesellschaftsvertrags eingezogen. Die Geschäftsführung wird angewiesen, ${insertTemplateVariable(
      nameGesellschafter
    )} die Einziehung schriftlich und unter Beifügung dieses Einziehungsbeschlusses mitzuteilen.</p></li>

    <li><p>Die Geschäftsführung wird angewiesen, eine aktualisierte Gesellschafterliste gemäß § 40 Abs. 1 GmbHG zum Handelsregister einzureichen und eine Meldepflicht der Gesellschaft zum Transparenzregister zu prüfen und ggf. zu erfüllen.</p></li>

  <li><p>Die Gesellschaft hat für den eingezogenen Geschäftsanteil nach § ${insertTemplateVariable(
    klauselEinziehung
  )} des Gesellschaftsvertrags eine Abfindung in folgender Höhe zu zahlen: ${insertTemplateVariable(
      klauselAbfindung
    )}. Die Geschäftsführung wird angewiesen, die Abfindung zu ermitteln.</p></li>
</ol>

  <p>Begründung:</p>
  <p>Die Voraussetzung einer Einziehung liegen vor:</p>

  <ul><li><p>Die eingezogenen Geschäftsanteile sind voll eingezahlt.</p></li>
  <li><p>Die Zahlung der Abfindung kann aus dem über die Stammkapitalziffer hinaus vorhandenen Reinvermögen der Gesellschaft erfolgen, sodass keine Unterbilanz entsteht.</p></li>
  <li><p>Die Einziehung ist im Gesellschaftsvertrag auch gemäß § 34 Abs. 1 GmbHG generell zugelassen.</p></li>
  <li><p>Die Einziehung der Geschäftsanteile von ${insertTemplateVariable(
    nameGesellschafter
  )} ist auch gerechtfertigt, denn es liegt ein wichtiger Grund in der Person von ${insertTemplateVariable(
      nameGesellschafter
    )} vor, der nach Maßgabe einer unter Einbeziehung aller relevanter Umstände vorzunehmenden Gesamtbewertung die weitere Mitgliedschaft in der Gesellschaft als untragbar erscheinen lässt bzw. die Erreichung des Gesellschaftszwecks ernsthaft gefährdet oder unmöglich macht. Der wichtige Grund lässt sich auch nicht auf eine andere Weise als durch Einziehung der Geschäftsanteile beheben.

  <p>Folgender wichtiger Grund liegt in der Person von ${insertTemplateVariable(
    nameGesellschafter
  )}vor: ${insertTemplateVariable(wichtigerGrund)}.</p>

  <p>Das Vorliegen des wichtigen Grundes lässt die weitere Mitgliedschaft in der Gesellschaft aufgrund der folgenden Abwägungsgesichtspunkte als untragbar erscheinen: ${insertTemplateVariable(
    wichtigerGrundAbwägung
  )}.</p>

   <p>Der wichtige Grund lässt sich auch nicht auf eine andere Weise als durch Ausschließung aus der Gesellschaft beheben, denn: ${insertTemplateVariable(
     wichtigerGrundUltimaratio
   )}.</p>

   <p>${insertTemplateVariable(
     nameGesellschafter
   )} wurde mit den vorstehenden Umstände im Einzelnen konfrontiert und ihm Gelegenheit zur Stellungnahme gegeben. Hierzu erklärte ${insertTemplateVariable(
      nameGesellschafter
    )}: ${insertTemplateVariable(wichtigerGrundEntgegnung)}.</p></li></ul>`,
    begründung: undefined,
    templateVariables: [
      nameGesellschafter,
      geschäftsanteileNummer,
      geschäftsanteileNennbetragGesamt,
      klauselEinziehung,
      klauselAbfindung,
      wichtigerGrund,
      wichtigerGrundAbwägung,
      wichtigerGrundUltimaratio,
      wichtigerGrundEntgegnung,
    ],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: true,
    noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass aufgrund des geänderten Gesellschafterbestandes eine aktualisierte Gesellschafterliste zum Handelsregister durch die Geschäftsführer eingereicht werden muss (§ 40 Abs. 1 GmbHG). Die neue Gesellschafterliste muss von den Geschäftsführern in vertretungsberechtigter Zahl unterschrieben und über einen Notar zum Handelsregister eingereicht werden.</p>`,
  });
