import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { important } from "@nvon/react-toolbox";
import { headerHeight } from "../../../helpers/clientSide/constants";

export const useRVOFootnoteNumberStyles = makeStyles((theme: Theme) =>
  createStyles({
    numberBox: {
      paddingTop: headerHeight,
      marginTop: -headerHeight,
      "& > a": {
        textDecoration: important("none"),
        border: `2px solid ${theme.palette.secondary.dark}`,
        borderRadius: "8px",
        padding: "2px 6px 2px 6px",
        marginLeft: "4px",
        "&:hover": {
          background: theme.palette.secondary.dark,
        },
        fontSize: theme.typography.caption.fontSize,
      },
    },
  })
);
