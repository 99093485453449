import { nameLiquidator } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageLiquidatorBefreiung181: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.OHgDe,
  ],
  vorlageKategorie: VorlageKategorie.liquidation,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.zugang, Vollzug.handelsregisterAnmeldung],
  bezug: [
    "LiquidatorBestellung",
    "LiquidatorVertretungsbefugnisEinzel",
    "LiquidatorVertretungsbefugnisGesamt",
  ],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Befreiung von den Beschränkungen des § 181 BGB",
  description: undefined,
  antrag: `${insertTemplateVariable(
    nameLiquidator
  )} wird von den Beschränkungen des § 181 BGB befreit, d.h. ${insertTemplateVariable(
    nameLiquidator
  )} kann im Namen der Gesellschaft sowohl mit sich im eigenen Namen oder als auch als Vertreter eines Dritten ein Rechtsgeschäft vornehmen. `,
  begründung: undefined,
  templateVariables: [nameLiquidator],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: true,
  noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss. Die Handelsregisteranmeldung muss von den Geschäftsführern (bei einer GmbH oder UG) bzw. persönlich haftenden Gesellschaftern (bei KG/OHG) in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
});
