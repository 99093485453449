import {
  cliffPeriode,
  datumAcceleratedVesting,
  exitSchwelleProzent,
  mitarbeiterAdresse,
  mitarbeiterGeburtsdatum,
  mitarbeiterName,
  mitarbeiterVertrag,
  mitarbeiterVertragDatum,
  phantomstockPercentAfterCliffPeriode,
  phantomstockPercentAfterCliffPeriodeMonthly,
  phantomstockPercentMaximal,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageArbeitsvertragPhantomStock: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  isEmployeeParticipationTemplate: true,
  isPremium: true,
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
  vorlageKategorie: VorlageKategorie.startup,
  vorlage_rolleTyp: [RolleInGesellschaft.Geschftsleiter],
  vollzug: [Vollzug.zustimmungBetroffener],
  bezug: [],
  tags: [
    "Werktätiger",
    "HR",
    "Human Ressource",
    "Belegschaft",
    "Arbeiter",
    "Arbeit",
    "Arbeitskraft",
    "Arbeitnehmer",
    "Personal",
    "Beschäftigte",
    "Angestellte",
    "Mitarbeiter",
    "worker",
    "employee",
    "laborer",
    "operative",
    "staff",
    "clerk",
    "job",
    "employ",
    "employment",
    "work",
    "VSOP",
    "ESOP",
    "Phantom Stock",
  ],
  zustimmungErforderlichDes: "Mitarbeiters",
  kurzBezeichnung: "Virtuelle Mitarbeiterbeteiligung (Phantom Stock Agreement)",
  metaDescription:
    'Vertragsmuster für Mitarbeiterbeteiligung ("Phantom Stocks" bzw. "VSOP" oder "ESOP") für den Exitfall. Juristisch geprüfte Muster/Vorlagen kostenlos nutzen.',
  description: `
  <p>
    Bewusst einfach gehaltene Ausgestaltung einer virtuellen Mitarbeiterbeteiligung ("Phantom Stocks" oder "VSOP"/"ESOP") für den Exitfall. Die virtuell beteiligten Mitarbeiter können Geschäftsführer, Arbeitnehmer oder sonstige Personen sein, die für die Gesellschaft tätig werden.
  </p>

  <p>
    Zusätzlich ist die Unterschrift des Mitarbeiters erforderlich, damit der Vertrag wirksam wird.
  </p>

  <p>
    Phantom Stocks werden vor allem bei Startups eingesetzt, um Mitarbeiter an der erwarteten besonders starken Wertsteigerung des Unternehmens teilhaben zu lassen.
  </p>

  `,
  antrag: `
  <p>
    Die Gesellschaft, vertreten durch die Geschäftsführung,<br/>
    (nachfolgend "<strong>Gesellschaft</strong>" genannt)
  </p>

  <p>
    schließt mit
  </p>

  <p>
    <strong>${insertTemplateVariable(mitarbeiterName)}</strong>,<br/>
    geboren am ${insertTemplateVariable(mitarbeiterGeburtsdatum)},<br/>
    wohnhaft in ${insertTemplateVariable(mitarbeiterAdresse)},
  </p>

  <p>
    ein
  </p>

  <h2>Phantom Stock Agreement</h2>

  <p>mit dem folgenden Inhalt ab:</p>

  <ol data-list-style="decimal" data-list-style-prefix="§">
    <li>
      <h3>Präambel</h3>
      <p>
        Die Parteien erwarten, dass sich der Wert der Gesellschaft in den kommenden Jahren erheblich steigern wird. Vor dem Hintergrund dieser Erwartung ist Folgendes beabsichtigt:
      </p>
      <ul data-list-style="square">
        <li><p>
          ${insertTemplateVariable(
            mitarbeiterName
          )} soll für seinen Beitrag an der erwarteten Wertsteigerung des Unternehmens eine finanzielle Beteiligung gewährt werden, wenn sich die künftige Wertsteigerung im Rahmen der Veräußerung wesentlicher Anteile an der Gesellschaft (<strong>„Exit“</strong>) realisiert.
        </p></li>
        <li><p>
          Die finanzielle Beteiligung soll dadurch erreicht werden, dass ${insertTemplateVariable(
            mitarbeiterName
          )} virtuelle Gesellschaftsanteile an der Gesellschaft eingeräumt werden.
        </p></li>

        <li><p>
          Aufgrund der virtuellen Geschäftsanteile sollen ${insertTemplateVariable(
            mitarbeiterName
          )} im Falle eines Exits ein Anteil an dem erzielten Verkaufserlös zustehen. Dieser Anteil soll dem Umfang der virtuellen Geschäftsanteile entsprechen (<strong>"Exit-Erlös"</strong>).
        </p></li>
      </ul>
      <p>
        Die Parteien sind sich der Tatsache bewusst, dass sowohl die erwartete Wertsteigerung als auch ihre Realisierung ungewiss sind, so dass keine der Parteien im Falle ihres Ausbleibens irgendwelche Rechte gegenüber der anderen Partei aus dieser Vereinbarung geltend machen können. Insbesondere erwirbt ${insertTemplateVariable(
          mitarbeiterName
        )} weder Gesellschafterrechte an der Gesellschaft auf Grund dieser Vereinbarung noch sind die Gesellschaft oder deren Gesellschafter dazu verpflichtet, einen Exit herbeizuführen.
      </p>
    </li>

    <li>
      <h3>Fortgeltung vertraglicher Vereinbarungen</h3>

      <p>
        Der zwischen ${insertTemplateVariable(
          mitarbeiterName
        )} und der Gesellschaft bereits bestehende ${insertTemplateVariable(
    mitarbeiterVertrag
  )} vom ${insertTemplateVariable(
    mitarbeiterVertragDatum
  )} (<strong>"Mitarbeiter-Vertragsverhältnis"</strong>) soll fortgelten, soweit sich nachfolgend nicht etwas Abweichendes ergibt.
      </p>
    </li>

    <li><h3>Vertragsgegenstand</h3>

      <p>
       Die Gesellschaft wird ${insertTemplateVariable(
         mitarbeiterName
       )} nach Maßgabe der nachfolgenden Bestimmungen und unter den dort genannten Voraussetzungen an einem späteren im Rahmen einer Unternehmensveräußerung erzielten Exit-Erlös beteiligen („Exit-Beteiligung“), indem ${insertTemplateVariable(
    mitarbeiterName
  )} virtuelle Geschäftsanteile an der Gesellschaft eingeräumt werden.
        </p>
    </li>

    <li>
      <h3>Virtuelle Geschäftsanteile und Vesting</h3>
      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">

        <li><p>
        Die Gesellschaft verpflichtet sich, ${insertTemplateVariable(
          mitarbeiterName
        )} virtuelle Geschäftsanteile an der Gesellschaft im folgenden Umfang einzuräumen (<strong>"Umfang der virtuellen Geschäftsanteile"</strong>):
        </p>

        <ul data-list-style="square">
          <li><p>
            nach Vollendung einer ununterbrochenen zwölfmonatigen Laufzeit des Mitarbeiter-Vertragsverhältnisses, beginnend am ${insertTemplateVariable(
              cliffPeriode
            )}  (<strong>„Cliff-Periode“</strong>): virtuelle Geschäftsanteile der Gesellschaft im Umfang von ${insertTemplateVariable(
    phantomstockPercentAfterCliffPeriode
  )} Prozent des Stammkapitals der Gesellschaft,
          </p></li>

          <li><p>
            für jeden weiteren Monat der ununterbrochenen Laufzeit des Mitarbeiter-Vertragsverhältnisses nach Ablauf der Cliff-Periode: weitere virtuelle Geschäftsanteile der Gesellschaft im Umfang von ${insertTemplateVariable(
              phantomstockPercentAfterCliffPeriodeMonthly
            )} Prozent des Stammkapitals der Gesellschaft, höchstens jedoch virtuelle Geschäftsanteile der Gesellschaft im Umfang von insgesamt ${insertTemplateVariable(
    phantomstockPercentMaximal
  )} Prozent des Stammkapitals der Gesellschaft ("Maximalumfang der virtuellen Geschäftsanteile"),
          </p></li>

          <li><p>
          für den Fall eines Exits vor dem ${insertTemplateVariable(
            datumAcceleratedVesting
          )}, sofern ${insertTemplateVariable(
    mitarbeiterName
  )} zu diesem Zeitpunkt nicht aus dem Mitarbeiter-Vertragsverhältnis ausgeschieden ist: unmittelbare Erhöhung des Umfangs der virtuellen Geschäftsanteile auf den Maximalumfang der virtuellen Geschäftsanteile („Accelerated Vesting“).
          </p></li>

        </ul>

        </li>

        <li><p>
        Der Umfang der virtuellen Geschäftsanteile bezieht sich auf das Stammkapital der Gesellschaft zum Zeitpunkt der Unterzeichnung dieser Vereinbarung. Die virtuellen Geschäftsanteile nehmen an Kapitalmaßnahmen nur in den folgenden Fällen teil:
        </p>
        <ul data-list-style="square">
          <li><p>
              Wenn das Stammkapital der Gesellschaft im Wege der Kapitalerhöhung durch Gesellschaftsmittel erhöht wird, werden auch die  ${insertTemplateVariable(
                mitarbeiterName
              )} gewährten virtuellen Geschäftsanteile entsprechend erhöht.
          </p></li>
          <li><p>
              Wird das Stammkapital der Gesellschaft im Wege der vereinfachten Kapitalherabsetzung ohne Einlagenrückgewähr herabgesetzt, verringert sich die Anzahl der  ${insertTemplateVariable(
                mitarbeiterName
              )} gewährten virtuellen Geschäftsanteile entsprechend.
          </p></li>
        </ul>
       <p>
       Jede Erhöhung oder Herabsetzung der Anzahl der virtuellen Geschäftsanteile wird durch die Gesellschaft umgesetzt und erfolgt durch schriftliche Mitteilung gegenüber ${insertTemplateVariable(
         mitarbeiterName
       )}. Jede Erhöhung oder Herabsetzung der Anzahl der virtuellen Geschäftsanteile lässt den Lauf der Cliff-Periode unberührt.
        </p></li>

        <li><p>
        Bei der Bestimmung der Laufzeit des Mitarbeiter-Vertragsverhältnisses werden nur volle Kalendarmonate berücksichtigt. Unberücksichtigt bleiben Zeiten, in denen das Vertragsverhältnis ruht, sowie Zeiten, in denen der Mitarbeiter von einer Lohnfortzahlung ausgeschlossen ist, sofern es sich um einen Anstellungsvertrag oder Arbeitsvertrag handelt.
          </p></li>

           <li><p>
       Zum Zwecke der Klarstellung erklären die Parteien, dass  ${insertTemplateVariable(
         mitarbeiterName
       )} durch die Gewährung der virtuellen Gesellschaftsanteile keine Gesellschafterstellung und somit auch keine Gesellschafterrechte an der Gesellschaft erlangt. Die virtuellen Geschäftsanteile dienen ausschließlich dazu, bei einem Exit einen Zahlungsanspruch von  ${insertTemplateVariable(
    mitarbeiterName
  )} gegenüber der Gesellschaft zu begründen und dadurch eine Teilhabe an dem erzielten Exit-Erlös zu begründen. Die Rechte, die sich aus den eingeräumten virtuellen Gesellschaftsanteilen ergeben, sind rein schuldrechtlicher Natur und ausschließlich auf die finanzielle Beteiligung an einem Exit-Erlös nach Maßgabe der vorliegenden Vereinbarung beschränkt.
          </p></li>
        </ol>
    </li>

    <li>
    <h3>Definition Exit</h3>
      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>
           Ein Exit im Sinne dieser Vereinbarung liegt vor, wenn die an der Gesellschaft beteiligten Gesellschafter mindestens  ${insertTemplateVariable(
             exitSchwelleProzent
           )} % der zu diesem Zeitpunkt  von ihnen gehaltenen Gesellschaftsanteile im Wege der Veräußerung an einen Dritten und/oder einen anderen Gesellschafter übertragen.
        </p></li>

        <li><p>
          Ein Exit liegt jedoch nicht bei einer Veräußerung vor, bei der die Gegenleistung für die veräußerten Gesellschaftsanteile in der Einräumung von Gesellschaftsanteilen an anderen Gesellschaften besteht.
        </p></li>
      </ol>
    </li>

    <li>
      <h3>Beteiligung am Exit-Erlös</h3>
      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>
        ${insertTemplateVariable(
          mitarbeiterName
        )} hat einen Anspruch gegen die Gesellschaft auf Zahlung der Exit-Erlösbeteiligung, wenn die folgenden beiden Bedingungen eingetreten sind:
        </p>
        <ul data-list-style="square">
          <li><p>
            Ein Exit im Sinne dieser Vereinbarung ist erfolgt.
          </p></li>
          <li><p>
            Die veräußerenden Gesellschafter haben den vollständigen Kaufpreises in Bezug auf alle veräußerten Geschäftsanteile erhalten.
          </p></li>
        </ul>
        </li>

        <li><p>
        Die Exit-Erlösbeteiligung ist der Betrag, der dem Anteil am erzielten Verkaufserlös entspricht, den ${insertTemplateVariable(
          mitarbeiterName
        )} erhalten hätte, wenn ${insertTemplateVariable(
    mitarbeiterName
  )} entsprechend seiner im Zeitpunkt des Exits bestehenden virtuellen Gesellschaftsanteile am Stammkapital der Gesellschaft beteiligt gewesen wäre und diese Geschäftsanteile mitveräußert hätte.
        </p></li>
        <li><p>
        Stichtag für die Bestimmung des Umfangs der virtuellen Gesellschaftsanteile ist der Tag der Beurkundung der Geschäftsanteilsübertragung.
        </p></li>
        <li><p>
          ${insertTemplateVariable(
            mitarbeiterName
          )} nimmt mit seinen virtuellen Geschäftsanteilen nur einmalig am Exit teil. Sollten die zum Zeitpunkt der Veräußerung dieser Vereinbarung an der Gesellschaft beteiligten Gesellschafter nach dem Exit Beteiligungen an der Gesellschaft halten, so findet eine Beteiligung von ${insertTemplateVariable(
    mitarbeiterName
  )} an einer späteren Veräußerung nicht statt.
        </p></li>

        <li><p>
        Der erzielte Verkaufserlös ist der für die Veräußerung der den Exit auslösenden Geschäftsanteile vereinbarte Netto-Kaufpreis nach Berücksichtigung einer etwaigen  Liquidationspräferenz, nach Steuern sowie abzüglich der auf die Geschäftsanteile entfallenden Anschaffungskosten sowie der tatsächlich angefallenen Veräußerungskosten.
        </p></li>

        <li><p>
        Der Zahlungsanspruch entsteht nicht, solange und soweit der Erwerber den Kaufpreis in Bezug auf die zu übertragenden Gesellschaftsanteile nicht leistet. Der Kaufpreis gilt als geleistet, sobald er den veräußernden Gesellschaftern zur freien Verfügung steht. Der Zahlungsanspruch wird einen Monat nach Erhalt des vollständigen Kaufpreises durch die veräußernden Gesellschafter fällig. Wird der Kaufpreis nicht zum selben Zeitpunkt an alle Gesellschafter geleistet, so ist der Zeitpunkt maßgeblich, zu dem der letzte Gesellschafter seinen Anteil am Kaufpreis erhält.     Soweit Teile des Kaufpreises vom Erwerber zurückgehalten werden oder sich auf einem Treuhandkonto befinden, gelten diese Teile nicht als Verkaufserlös; die Zahlung an ${insertTemplateVariable(
          mitarbeiterName
        )} wird auf der Basis des angepassten Verkaufserlös neuberechnet, sobald diese zurückgehaltenen oder Treuhand-Beträge schließlich gezahlt werden.
        </p></li>

                <li><p>
        Die Exit-Erlösbeteiligung versteht sich als Bruttobetrag und wird nach Abzug von Steuern und Sozialabgaben bargeldlos auf ein von ${insertTemplateVariable(
          mitarbeiterName
        )} zu benennendes Konto überwiesen.
        </p></li>

                <li><p>
        Die Gesellschaft ist nicht verpflichtet, einen Exit herbeizuführen oder darauf hinzuwirken. Kommt es nicht zu einem Exit, kann ${insertTemplateVariable(
          mitarbeiterName
        )} aus den erworbenen virtuellen Gesellschaftsanteilen keinerlei Rechte herleiten. Insbesondere sind Schadensersatz- oder Abgeltungsansprüche ausgeschlossen.
        </p></li>


      </ol>
    </li>

    <li>
      <h3>Beendigung des Mitarbeiter-Vertragsverhältnisses</h3>

      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>
        Scheidet ${insertTemplateVariable(
          mitarbeiterName
        )} vor Ablauf der Cliff-Periode  aus dem Mitarbeiter-Vertragsverhältnisaus, so verfallen alle Ansprüche aus dieser Vereinbarung ersatzlos.
        </p></li>

        <li><p>
        Scheidet ${insertTemplateVariable(
          mitarbeiterName
        )} nach Ablauf der Cliff-Periode, aber vor dem Eintritt eines Exits aus dem Mitarbeiter-Vertragsverhältnis als Good-Leaver aus, so bleiben die Ansprüche aus dieser Vereinbarung, deren Umfang sich nach der Höhe der zum Zeitpunkt der Beendigung des Mitarbeiter-Vertragsverhältnisses erworbenen virtuellen Gesellschaftsanteile richtet, bis zum Ablauf von 36 Monaten nach Beendigung des Mitarbeiter-Vertragsverhältnisses erhalten, danach verfallen sie ersatzlos. ${insertTemplateVariable(
    mitarbeiterName
  )} ist ein <strong>„Good-Leaver“</strong>, wenn das Mitarbeiter-Vertragsverhältnis aufgrund von Kündigung oder Nicht-Verlängerung aus einem anderen als den im folgenden Absatz genannten Gründen endet, insbesondere aufgrund von Kündigung oder Nicht-Verlängerung durch ${insertTemplateVariable(
    mitarbeiterName
  )} aus wichtigem Grund, den die Gesellschaft zu vertreten hat, oder aufgrund von Kündigung oder Nicht-Verlängerung durch die Gesellschaft, wenn kein wichtiger Grund für eine Kündigung vorliegt, oder wegen dauerhafter Erkrankung, Renteneintritt oder Tod.
        </p></li>

         <li><p>
        Die Bestimmung des vorstehenden Absatzes gilt nicht, wenn ${insertTemplateVariable(
          mitarbeiterName
        )} die Gründe für sein Ausscheiden zu vertreten hat (<strong>„Bad-Leaver-Event“</strong>). Ein Bad-Leaver Event liegt insbesondere, aber nicht ausschließlich im Fall einer  verhaltensbedingten außerordentlichen oder ordentlichen Kündigung durch die Gesellschaft sowie gleichermaßen vor, wenn das Mitarbeiter-Vertrgsverhältnis durch einen Aufhebungsvertrag oder Auflösungsvertrag beendet wird und Anlass hierfür ein Auflösungsverschulden oder ein Verhalten von ${insertTemplateVariable(
    mitarbeiterName
  )} ist, das der Gesellschaft ein Recht zur außerordentlichen oder ordentlichen verhaltensbedingten Kündigung gegeben hätte. Im Fall eines Bad-Leaver-Events entfallen alle Ansprüche von ${insertTemplateVariable(
    mitarbeiterName
  )} aus dieser Vereinbarung ersatzlos, ohne dass es hierzu einer gesonderten Mitteilung der Gesellschaft bedarf.
        </p></li>
      </ol>
    </li>

    <li>
      <h3>Abtretungs- und Verpfändungsverbot/ Vererblichkeit</h3>

      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>
        Die Abtretung oder Verpfändung der Ansprüche von ${insertTemplateVariable(
          mitarbeiterName
        )} aus und in Zusammenhang mit dieser Vereinbarung ist ausgeschlossen. Ein Verstoß gegen dieses Verbot führt zum sofortigen Verlust der Ansprüche.
        </p></li>

        <li><p>
        Die virtuellen Gesellschaftsanteile können im Wege des Erbfalls übertragen werden. Die jeweiligen Erben sind an die vorliegenden Bestimmungen gebunden. Sie sind verpflichtet, die Gesellschaft über den Eintritt der Rechtsnachfolge zu informieren und die Gesellschaft ist dazu berechtigt, die Vorlage entsprechender Nachweise in Form eines Erbscheins zu verlangen. Soweit die virtuellen Gesellschaftsanteile von mehreren Personen geerbt werden, sind diese Personen dazu berechtigt, die sich aus den virtuellen Anteilen ergebenden Rechte gemeinsam auszuüben. In einem solchen Fall sind die Erben verpflichtet, einen gemeinsamen Vertreter zu benennen, der berechtigt ist, für diese zu handeln; eine entsprechende Bevollmächtigung ist der Gesellschaft durch schriftliche Erklärung mitzuteilen. Der gemeinsame Bevollmächtigte ist insbesondere dazu berechtigt, alle Erklärungen und alle weiteren Mitteilungen gegenüber und von der Gesellschaft für die Erben abzugeben und in Empfang zu nehmen. Zahlungen der Gesellschaft an die Erben können mit befreiender Wirkung an den gemeinsamen Bevollmächtigten geleistet werden.
        </p></li>
      </ol>
    </li>



    <li>
      <h3>Schlussbestimmung</h3>

      <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li><p>
        Änderungen, Ergänzungen und die Aufhebung dieses Vertrags bedürfen zu ihrer Wirksamkeit der Schriftform. Dies gilt auch für die Änderung dieser Schriftformklausel selbst. Ausgeschlossen sind damit insbesondere Vertragsänderungen durch betriebliche Übung. Das vorstehende Schriftformerfordernis findet keine Anwendung bei Abreden, die nach Vertragsschluss unmittelbar zwischen den Parteien mündlich getroffen werden.
        </p></li>

        <li><p>
        Mündliche Nebenabreden bestehen nicht.
        </p></li>

        <li><p>
          Sollte eine Bestimmung dieser Vereinbarung ganz oder teilweise unwirksam sein oder werden, so wird hiervon die Wirksamkeit der übrigen Bestimmungen nicht berührt. An die Stelle der unwirksamen Bestimmung tritt die gesetzlich zulässige Bestimmung, die dem Gewollten am nächsten kommt. Das gilt auch im Falle einer unbeabsichtigten Regelungslücke.
        </p></li>
      </ol>
    </li>
  </ol>
  <br/>
  <br/>
  <p>
  ___________________________________<br/>
  Ort, Datum
  </p>
  <br/>
  <br/>
  <p>
  ___________________________________<br/>
  Unterschrift ${insertTemplateVariable(mitarbeiterName)}
    </p>

  `,
  begründung: undefined,
  templateVariables: [
    cliffPeriode,
    datumAcceleratedVesting,
    exitSchwelleProzent,
    mitarbeiterAdresse,
    mitarbeiterGeburtsdatum,
    mitarbeiterName,
    mitarbeiterVertrag,
    mitarbeiterVertragDatum,
    phantomstockPercentAfterCliffPeriode,
    phantomstockPercentAfterCliffPeriodeMonthly,
    phantomstockPercentMaximal,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
