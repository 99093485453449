import {
  defaultLanguage,
  Language,
} from "../../../config/LanguageConfiguration";
import { mapPageToPath } from "../../../config/mapPageToPath";
import { Page } from "../../../config/Pages";

export const pathForPageAndLanguage = (
  language: Language,
  page: Page,
  hash?: string
): string => {
  const hashToUse = hash || "";

  if (language === defaultLanguage) {
    return `/${mapPageToPath[page][language]}${hashToUse}`;
  }

  return `/${language}/${mapPageToPath[page][language]}${hashToUse}`;
};
