import { firma, zweigniederlassungOrt } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageErrichtungZweigniederlassung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.handelsrecht,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.handelsregisterAnmeldung],
  bezug: [],
  kurzBezeichnung: "Errichtung einer Zweigniederlassung",
  antrag: `Die Gesellschaft errichtet in ${insertTemplateVariable(
    zweigniederlassungOrt
  )} eine Zweigniederlassung. Die Zweigniederlassung führt die Firma

<p style="text-align:center">${insertTemplateVariable(firma)}<br/>
Zweigniederlassung ${insertTemplateVariable(zweigniederlassungOrt)}.<p>`,
  begründung: undefined,
  templateVariables: [zweigniederlassungOrt, firma],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: true,
  noteHandelsregisteranmeldung: `<p>Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss (§ 13 Abs. 1 HGB). Die Handelsregisteranmeldung muss von den Geschäftsführern (bei einer GmbH) bzw. den persönlich haftenden Gesellschaftern (bei einer KG/OHG) in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.</p>`,
});
