import { graphql, useStaticQuery } from "gatsby";

export const useSiteInfoQuery = (): GatsbyTypes.SiteInfoQueryQuery =>
  useStaticQuery<GatsbyTypes.SiteInfoQueryQuery>(graphql`
    query SiteInfoQuery {
      wp {
        seo {
          contentTypes {
            post {
              title
              metaDesc
            }
            page {
              metaDesc
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
      }
    }
  `);
