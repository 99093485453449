import {
  aufnahmegebuehrHoehe,
  aufsichtsratPflichtsitzungenAnzahl,
  listOfBoardMembers,
  switchBegründung,
  variantsVorstandsmitgliedDienstverhaeltnisBeendigung,
  variantsVorstandsmitgliedFreistellung,
  vereinMitgliederMindestalter,
  vorstandsmitgliedName,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlagePlayground: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.AgDe,
    CompanyTypeName.SeDe,
    CompanyTypeName.KgaADe,
  ],
  vorlageKategorie: VorlageKategorie.geschäftsführerNeu,
  vorlage_rolleTyp: [RolleInGesellschaft.Aufsichtsrat],
  vollzug: [Vollzug.zugang, Vollzug.handelsregisterAnmeldung],
  bezug: [],
  zustimmungErforderlichDes: undefined,
  isArchived: true,
  kurzBezeichnung: "Playground",
  description: `
  <p>
     Diese Vorlage hat alle möglichen Variablen-Input-Varianten in sich vereint.
</p>
`,
  metaDescription: "Playground Meta Description.",
  tags: ["Playground", "Test", "Variablen", "Input", "Template"],
  antrag: `
 <h2>H2 </h2>
  <p>
    Auflistung aller Gremienmitglieder (Hierfür gibt es kein entsprechendes Inputfeld):
  </p>
  <div>
   ${insertTemplateVariable(listOfBoardMembers)}
  </div>
  <p>Ol-Liste mit data-list-style="decimal" und data-list-prefix="§":<p>

  <ol  data-list-style="decimal" data-list-style-prefix="§ ">
    <li>
    <h3>H3 </h3>
        <p>
             Einrückung mit ul data-list-style="none":
        </p>
        <ul data-list-style="none">
            <li>

                <p>
                    Inputfeld <strong>variableTypeString</strong>: ${insertTemplateVariable(
                      vorstandsmitgliedName
                    )}</p>
                </p>
            </li>
        </ul>

    </li>
    <li>
    <h2>Inputfeld <strong>variableTypeVariants</strong> (reiner string als value): </h2>
        <p>
            Mit sofortiger Wirkung ${insertTemplateVariable(
              variantsVorstandsmitgliedDienstverhaeltnisBeendigung
            )}
        </p>
    </li>
    <li>
    <p>
    Ol liste mit data-list-style="lower-latin"
    </p>


<ol data-list-style="lower-latin">





    <li>
    <ol>
            <li>
            <p>
    Inputfeld <strong>variableTypeNumberMoney</strong>:  ${insertTemplateVariable(
      aufnahmegebuehrHoehe
    )}
  </p>

            </li>
            <li>
             <p>
    Inputfeld <strong>variableTypeNumber</strong> mit inputUnit (in diesem Fall "Jahre") und ohne outputUnit:  ${insertTemplateVariable(
      vereinMitgliederMindestalter
    )}
  </p>

            </li>
               <li>
             <p>
     <p>
    Inputfeld <strong>variableTypeNumberSlider</strong> mit inputUnit + outputUnit (in diesem Fall "Sitzung/Sitzungen"):  ${insertTemplateVariable(
      aufsichtsratPflichtsitzungenAnzahl
    )}
  </p>
  </p>
<p>Inputfeld <strong>variableTypeVariants</strong> (html als value,  in diesem Fall li p tags):</p>

    ${insertTemplateVariable(variantsVorstandsmitgliedFreistellung)}
            </li>
    </ol>
    </li>

    </ol>
    </li>


  </ol>

  <p>Inputfeld <strong>variableTypeVariants</strong> (html als value,  in diesem Fall li p tags):</p>
<ul>
    ${insertTemplateVariable(variantsVorstandsmitgliedFreistellung)}
    </ul>

           <p>
    Inputfeld <strong>variableTypeSwitch</strong> mit zusätzlichem Input (hier <strong>variableTypeTextarea</strong>):
  </p>

 ${insertTemplateVariable(switchBegründung)}





  `,
  begründung: undefined,
  templateVariables: [
    listOfBoardMembers,
    vorstandsmitgliedName,
    variantsVorstandsmitgliedDienstverhaeltnisBeendigung,

    aufnahmegebuehrHoehe,
    vereinMitgliederMindestalter,
    aufsichtsratPflichtsitzungenAnzahl,
    variantsVorstandsmitgliedFreistellung,

    switchBegründung,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: true,
  noteHandelsregisteranmeldung: `
  <p>
    Es ist gesetzlich vorgeschrieben, dass dieser Beschluss zum <strong>Handelsregister</strong> angemeldet werden muss (§ 81 Abs. 1 AktG). Die Handelsregisteranmeldung muss von den Vorstandsmitgliedern in vertretungsberechtigter Zahl unterschrieben und von einem Notar beglaubigt und zum Handelsregister eingereicht werden.
</p>`,
});
