import { GrCase } from "../../../../interfaces/GrCase";
import { GrNumber } from "../../../../interfaces/grNumber";
import { useCompanyInfo } from "../../../../state/companyInfo/companyInfoSlice";
import { getMeetingName } from "../../namesForMeeting/getMeetingName";
import { getRoleName } from "../../namesForRoles/getRoleName";

export const useCoatingInformation = (
  grNumber: GrNumber
): {
  whoCompound: string;
  whoGenitive: string;
  whoNominative: string;
  company: string;
  sitz: string;
  meetingNameSingNomNoncap: string;
  meetingNameSingGenCap: string;
} | null => {
  const { firma, sitz, rechtsform, whoCanDecide } = useCompanyInfo();

  const whoGenitive = getRoleName(
    whoCanDecide,
    rechtsform,
    grNumber,
    GrCase.Genitive
  );

  const whoNominative = getRoleName(
    whoCanDecide,
    rechtsform,
    grNumber,
    GrCase.Nominative
  );

  const whoCompound = getRoleName(
    whoCanDecide,
    rechtsform,
    grNumber,
    GrCase.CompoundForm
  );

  const meetingNameSingGenCap = getMeetingName(
    whoCanDecide,
    rechtsform,
    GrNumber.Singular,
    GrCase.Genitive
  );

  const meetingNameSingNomNoncap = getMeetingName(
    whoCanDecide,
    rechtsform,
    GrNumber.Singular,
    GrCase.Nominative
  )?.toLowerCase();

  if (
    !whoCompound ||
    !whoGenitive ||
    !whoNominative ||
    !meetingNameSingGenCap ||
    !meetingNameSingNomNoncap
  ) {
    return null;
  }

  return {
    whoCompound,
    whoGenitive,
    whoNominative,
    company: firma,
    sitz: sitz,
    meetingNameSingGenCap,
    meetingNameSingNomNoncap,
  };
};
