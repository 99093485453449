import { zustimmungsparagraph } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";
import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageZustimmungZurAnteilsabtretung: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
  vorlageKategorie: VorlageKategorie.ausscheidenEinesGesellschafters,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  kurzBezeichnung: "Zustimmung zur Anteilsabtretung",
  antrag: `Zu der gemäß der beigefügten Anlage vereinbarten Abtretung wird aufgrund von § ${insertTemplateVariable(
    zustimmungsparagraph
  )} der Satzung der Gesellschaft die Zustimmung erteilt. Die Zustimmung kann nicht widerrufen werden.`,
  templateVariables: [zustimmungsparagraph],
  begründung: undefined,
  anhangName: "Abtretungsvertrag (Beispiel)",
  anhangDatei: "Beispielabtretungsvertrag.doc",
  dateOfCreation: undefined,
  vollzug: [Vollzug.zugang],
});
