import RVOFootnoteNumber from "../../../../components/02-molecules/RVOFootnoteNumber/RVOFootnoteNumber";
import { TagToComponentMap } from "../replaceHtmlElementInterfaces";

/**
 * Footnote numbers come without the right id for anchoring, so the mapping is used to add the right id. In addition an offset is added, so that our navigation header does not overlap with the anchors
 */
export const mappedFootnoteNumbers: TagToComponentMap = {
  sup: ({ children, replacedElement }) => {
    const dataFn = replacedElement.attribs["data-fn"];
    const footnoteNumberId = dataFn ? `${dataFn}-link` : null;

    if (dataFn) {
      return (
        <RVOFootnoteNumber dataFn={dataFn} footnoteNumberId={footnoteNumberId}>
          {children}
        </RVOFootnoteNumber>
      );
    } else {
      return <sup> {children}</sup>;
    }
  },
};
