import {
  adresseFreierMitarbeiter,
  ausnahmeWettbewerbsverbotUnternehmen,
  beginnFreierMitarbeiter,
  beschreibungTätigkeitFreierMitarbeiter,
  betragHonorarNetto,
  bezeichnungTätigkeitFreierMitarbeiter,
  leistungenWettbewerberFreierMitarbeiter,
  nameAnsprechpartnerFreierMitarbeiter,
  nameFreierMitarbeiter,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageDienstvertragFreierMitarbeiter: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.mitarbeiter,
  vorlage_rolleTyp: [RolleInGesellschaft.Geschftsleiter],
  vollzug: [Vollzug.zustimmungBetroffener],
  bezug: [],
  zustimmungErforderlichDes: "freien Mitarbeiters",
  kurzBezeichnung:
    "Einfacher Dienstvertrag mit freiem Mitarbeiter (Freelancer) im Beschluss",
  description: undefined,
  antrag: `Die Gesellschaft, vertreten durch die Geschäftsführung, schließt mit

  <p>${insertTemplateVariable(nameFreierMitarbeiter)},<br/>
  mit Anschrift in ${insertTemplateVariable(adresseFreierMitarbeiter)},</p>

  <p>einen

  <p style="text-align:center"><strong>Freier-Mitarbeiter-Dienstvertrag</strong></p>

  mit dem folgenden Inhalt ab:</p>

  <ol><li><p><strong>Vertragsgegenstand</strong>

    <p>(1) ${insertTemplateVariable(
      nameFreierMitarbeiter
    )} wird als ${insertTemplateVariable(
    bezeichnungTätigkeitFreierMitarbeiter
  )} bei der Gesellschaft tätig. Zur Tätigkeit von ${insertTemplateVariable(
    nameFreierMitarbeiter
  )} gehört insbesondere: ${insertTemplateVariable(
    beschreibungTätigkeitFreierMitarbeiter
  )}.
</p>

  <p>(2) Ansprechpartner von ${insertTemplateVariable(
    nameFreierMitarbeiter
  )} bei der Gesellschaft ist ${insertTemplateVariable(
    nameAnsprechpartnerFreierMitarbeiter
  )}. </p></li>

  <li><p><strong>Leistungsumfang und Leistungserbringung</strong>

    <p>(1) ${insertTemplateVariable(
      nameFreierMitarbeiter
    )} wird für die Gesellschaft als selbständiger Unternehmer tätig und erbringt alle Leistungen nach diesem Dienstvertrag im eigenen Namen und auf eigene Rechnung. ${insertTemplateVariable(
    nameFreierMitarbeiter
  )} ist nicht zur rechtsgeschäftlichen Vertretung der Gesellschaft berechtigt. ${insertTemplateVariable(
    nameFreierMitarbeiter
  )} wird frei von Weisungen der Gesellschaft tätig.</p>

    <p>(2) ${insertTemplateVariable(
      nameFreierMitarbeiter
    )} ist in der Bestimmung des Arbeitsortes und der Arbeitszeit frei.</p>

    <p>(3) Die Gesellschaft stellt ${insertTemplateVariable(
      nameFreierMitarbeiter
    )} alle zur Tätigkeitsausübung erforderlichen Informationen und Unterlagen zur Verfügung.</p></li>

  <li><p><strong>Vergütung</strong>

    <p> (1) ${insertTemplateVariable(
      nameFreierMitarbeiter
    )} erhält für alle erbrachten Tätigkeiten ein monatliches Pauschalhonorar von ${insertTemplateVariable(
    betragHonorarNetto
  )} (netto) zuzüglich der gesetzlichen Umsatzsteuer.</p>

     <p> (2) ${insertTemplateVariable(
       nameFreierMitarbeiter
     )} steht kein Vergütungs- oder Vergütungsfortzahlungsanspruch zu, wenn die Tätigkeitserbringung infolge Krankheit oder sonstiger Arbeitsverhinderung nicht möglich ist.</p>

    <p> (3) ${insertTemplateVariable(
      nameFreierMitarbeiter
    )} stellt der Gesellschaft das Honorar jeweils zum Ende eines Kalendermonats in Rechnung. Die Umsatzsteuer ist auf der Rechnung gesondert auszuweisen. Die Zahlung der Vergütung erfolgt spätestens 15 Kalendertage nach Erhalt der Rechnung auf das von ${insertTemplateVariable(
    nameFreierMitarbeiter
  )} angegebene Konto. Stellt sich heraus, dass die Leistungen von ${insertTemplateVariable(
    nameFreierMitarbeiter
  )} nicht umsatzsteuerpflichtig sind, hat ${insertTemplateVariable(
    nameFreierMitarbeiter
  )} der Gesellschaft die zu Unrecht ausgewiesene und gezahlte Umsatzsteuer unverzüglich zurückzuzahlen.</p>

      <p>(4) Steuern und Sozialversicherungsbeiträge führt ${insertTemplateVariable(
        nameFreierMitarbeiter
      )} selbst ab.</p>

      <p>(5) Mit dem vorstehend vereinbarten Honorar sind sämtliche Aufwendungen von ${insertTemplateVariable(
        nameFreierMitarbeiter
      )} im Zusammenhang mit allen vertraglichen Tätigkeit abgegolten, sofern sich aus § 4 nicht etwas anders ergibt.</p></li>

  <li><p><strong>Reisekosten</strong>

    <p>(1) Die Gesellschaft ersetzt ${insertTemplateVariable(
      nameFreierMitarbeiter
    )} die erforderlichen und nachgewiesenen Reisekosten, die ${insertTemplateVariable(
    nameFreierMitarbeiter
  )} in Ausübung der Aufgaben im Rahmen dieses Vertrages entstehen. Die Erforderlichkeit von Reisen ist vor Reiseantritt mit der Gesellschaft abzustimmen.</p>

    <p> (2) ${insertTemplateVariable(
      nameFreierMitarbeiter
    )} hat etwaige Reisekosten monatlich abzurechnen und durch steuerlich anerkennungsfähige Original-Quittungen nachzuweisen. </p></li>

  <li><p><strong>Einsatz Dritter</strong>

    <p>${insertTemplateVariable(
      nameFreierMitarbeiter
    )} kann sich bei der Erfüllung aller vertraglichen Tätigkeiten auch anderer Personen bedienen. Der Einsatz von Subunternehmern bedarf wegen der in § 13 MiLoG iVm § 14 AEntG enthaltenen Haftungsregelungen jedoch einer vorherigen schriftlichen Zustimmung durch die Gesellschaft.</p></li>

  <li><p><strong>Verschwiegenheit</strong>

    <p>Über alle ${insertTemplateVariable(
      nameFreierMitarbeiter
    )} während der Tätigkeit für die Gesellschaft bekanntwerdenden vertraulichen Informationen und Unterlagen, die die Gesellschaft betreffen, insbesondere Geschäftsgeheimnisse, ist Stillschweigen zu bewahren. ${insertTemplateVariable(
    nameFreierMitarbeiter
  )} ist auch zur Geheimhaltung solcher Tatsachen verpflichtet, die von der Gesellschaft ausdrücklich als vertraulich bekannt gegeben worden sind oder deren Geheimhaltungsbedürftigkeit sonst für ${insertTemplateVariable(
    nameFreierMitarbeiter
  )} erkennbar ist. Die Verschwiegenheitspflicht gilt auch nach Vertragsbeendigung fort.</p></li>

  <li><p><strong>Aufbewahrung und Rückgabe von Unterlagen</strong>

    <p>(1) Alle ${insertTemplateVariable(
      nameFreierMitarbeiter
    )} zur Verfügung gestellten Geschäfts- und Betriebsunterlagen sind ordnungsgemäß aufzubewahren. ${insertTemplateVariable(
    nameFreierMitarbeiter
  )} hat dafür zu sorgen, dass Dritte nicht Einsicht nehmen können. Die zur Verfügung gestellten Unterlagen sowie etwaige Kopien hiervon sind während der Dauer des Vertragsverhältnisses auf Anforderung, nach Beendigung des Vertragsverhältnisses unverzüglich unaufgefordert an die Gesellschaft zurückzugeben.</p>

    <p>(2) Dieselbe Aufbewahrungs- und Herausgabepflicht gilt für sämtliche Schriftstücke, die Angelegenheiten der Gesellschaft betreffen (wie Aufzeichnungen, Entwürfe etc.) und sich im Besitz von ${insertTemplateVariable(
      nameFreierMitarbeiter
    )} befinden.</p>

    <p>(3) ${insertTemplateVariable(
      nameFreierMitarbeiter
    )} ist nicht berechtigt, an solchen Unterlagen ein Zurückbehaltungsrecht auszuüben.</p>

    </p></li>

  <li><p><strong>Andere Tätigkeiten, Wettbewerbsverbot</strong>

    <p>(1) ${insertTemplateVariable(
      nameFreierMitarbeiter
    )} steht es frei, während der Dauer dieses Vertrages auch für andere Auftraggeber tätig zu werden.</p>

    <p>(2) ${insertTemplateVariable(
      nameFreierMitarbeiter
    )} verpflichtet sich jedoch, während der Dauer des Vertragsverhältnisses nicht für ein Unternehmen tätig zu sein, das mit der Gesellschaft in Wettbewerb steht („Wettbewerbsunternehmen“) und insbesondere folgende Erzeugnisse entwickelt, herstellt oder vertreibt bzw. folgende Dienstleistungen anbietet: ${insertTemplateVariable(
    leistungenWettbewerberFreierMitarbeiter
  )}.  ${insertTemplateVariable(
    nameFreierMitarbeiter
  )} hat sich jeder selbstständigen – direkten oder indirekten – oder unselbstständigen Tätigkeit für ein solches Wettbewerbsunternehmen zu enthalten. ${insertTemplateVariable(
    nameFreierMitarbeiter
  )} ist es ferner untersagt, ein solches Wettbewerbsunternehmen zu gründen oder sich an seinem solchen Wettbewerbsunternehmen mittelbar oder unmittelbar zu beteiligen.</p>

   <p>(3) Von dem Wettbewerbsverbot ausgenommen ist eine Tätigkeit für folgende Unternehmen: ${insertTemplateVariable(
     ausnahmeWettbewerbsverbotUnternehmen
   )}</p></li>

  <li><p><strong>Vertragsdauer</strong>

    <p>(1) Der Vertrag tritt am ${insertTemplateVariable(
      beginnFreierMitarbeiter
    )} in Kraft und wird auf unbestimmte Zeit geschlossen.</p>

    <p>(2) Das Vertragsverhältnis kann beiderseits mit einer Frist von einem Monat zum Monatsende gekündigt werden. Das Recht zur außerordentlichen Kündigung aus wichtigem Grund bleibt unberührt.</p></li>

  <li><p><strong> Schlussbestimmungen</strong>

    <p>(1) Mündliche Nebenabreden bestehen nicht. Änderungen und Ergänzungen dieses Vertrages bedürfen zu ihrer Wirksamkeit der Schriftform. Dies gilt auch für die Aufhebung und Änderung dieser Schriftformklausel. Der Vorrang von individuellen mündlichen Vertragsabreden zwischen den Parteien nach Vertragsschluss (§ 305b BGB) bleibt hiervon unberührt.</p>

    <p>(2) Gerichtsstand ist – soweit rechtlich zulässig – am Sitz der Gesellschaft.</p>

    <p>(3) Sind einzelne Bestimmungen dieses Vertrages unwirksam, so berührt dies nicht die Wirk¬samkeit der übrigen Regelungen des Vertrages. Die Parteien sind verpflichtet, die unwirksame Bestimmung durch eine wirksame Bestimmung zu ersetzen, die den mit der unwirksamen Bestimmung angestrebten wirtschaftlichen Erfolg soweit wie möglich erreicht. Dies gilt auch im Falle von Vertragslücken.</p></li></ol>
      <br/>
  <br/>
  <p>
  ___________________________________<br/>
  Ort, Datum
  </p>
  <br/>
  <br/>
  <p>
  ___________________________________<br/>
  Unterschrift ${insertTemplateVariable(nameFreierMitarbeiter)}
    </p>`,
  begründung: undefined,
  templateVariables: [
    nameFreierMitarbeiter,
    adresseFreierMitarbeiter,
    bezeichnungTätigkeitFreierMitarbeiter,
    beschreibungTätigkeitFreierMitarbeiter,
    nameAnsprechpartnerFreierMitarbeiter,
    betragHonorarNetto,
    leistungenWettbewerberFreierMitarbeiter,
    ausnahmeWettbewerbsverbotUnternehmen,
    beginnFreierMitarbeiter,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
