import { createEnumTypeGuard } from "@nvon/baseline";
import { RolleInGesellschaft } from "../../../common-interfaces/VorlageInterface";
import { GrCase } from "../../../interfaces/GrCase";
import { GrNumber } from "../../../interfaces/grNumber";
import { AnyRole } from "../../../interfaces/variousInterfaces";
import { CompanyTypeName } from "../../vorlagen/vorlagenShared/generatedTypes/CompanyTypeName";
import { assertCompanyTypeLandingpage } from "../isCompanyTypeLandingpage";
import { additionalRoleNames } from "./additionalRolesForRechtsform";
import { roleNamesForRechtsform } from "./roleNamesForRechtsform";

export const isRegularRole = createEnumTypeGuard(RolleInGesellschaft);

const defaultCase: GrCase = GrCase.Nominative;

/**
 * Return the name for a certain role in a Rechtsform.
 *
 * Can be singular or plural.
 */
export const getRoleName = (
  role: AnyRole | null,
  companyType: CompanyTypeName | null,
  grNumber: GrNumber,
  grCase: GrCase = defaultCase
): string | null => {
  if (!role || !companyType) {
    return null;
  }

  assertCompanyTypeLandingpage(companyType);

  if (isRegularRole(role)) {
    return roleNamesForRechtsform[companyType][role][grNumber][grCase];
  }

  return additionalRoleNames[role][grNumber][grCase];
};
