/* eslint-disable @typescript-eslint/naming-convention */
import { CompanyTypeName } from "./generatedTypes/CompanyTypeName";
import { StripeProduct } from "./generatedTypes/StripeProduct";
import { RVOVariableTypeId } from "./variableTypes/RVOVariableTypeId";

/**
 * URL-sichere Bezeichnung der Vorlage. Muss unique sein.
 */
type URLSlug = string;
type RelatedBeschlussVorlagen = unknown[];

/**
 * Vorlage für den Beschlussantrag, teils mit Anlagen und Musterbegründung.
 */
export interface LegacyTemplate {
  id: string;
  description?: string;
  metaDescription?: string;
  /**
   * Can be set to avoid showing the template in the picker.
   * A successor can be provided for redirects.
   *
   * 🚨 This is not functional yet.
   */
  isArchived?:
    | boolean
    | {
        successor: LegacyTemplate["id"];
      };
  tags?: string[];
  vorlageTyp: VorlageTyp;
  /**
   * The products that enable using this template.
   */
  availableWithProducts: StripeProduct[];
  /**
   * Convenience flag over availableWithProducts
   * - true if the template is NOT usable with Free Product
   */
  isPremium: boolean;
  templateVariables: TemplateVariable[];
  urlSlug: URLSlug;
  /**
   * Convenience flag for the template being a template for employee participation.
   */
  isEmployeeParticipationTemplate?: boolean;
  /**
   * Company types that this template is suitable for.
   */
  geeignetFuerRechtsformen: CompanyTypeName[];
  /**
   * @deprecated this is not really used anywhere and not maintained either.
   */
  vorlageKategorie: VorlageKategorie | VorlageKategorie[];
  vorlage_rolleTyp: RolleInGesellschaft[];
  kurzBezeichnung: string;
  /**
   * An alternative title that's used when rendering the document.
   *
   * For example: "Allgmeine Beschlussvorlage" becomes "Beschlussfassung"
   *  inside the votingModeFormalPlenum header.
   */
  titleInDocument?: string;
  anhangName?: string;
  dateOfCreation?: string;
  anhangDatei?: string;
  bezug?: RelatedBeschlussVorlagen;
  zustimmungErforderlichDes?: string;
  antrag: string;
  begründung?: string;
  vollzug: Vollzug[];
  isBeurkundungspflichtig?: boolean;
  isHandelsregisterpflichtig?: boolean;
  noteHandelsregisteranmeldung?: string;
  vollzugshinweise?: string;
  shouldStartWithoutTitle?: boolean;
}

interface TemplateContext {
  insertTemplateVariable: (
    variable: TemplateVariable,
    linkText?: string
  ) => string;
  insertVorlagenLink: (
    vorlage: LegacyTemplateBase,
    linkText?: string
  ) => string;
}

/**
 * A template without any generated fields.
 *
 * This is the type we use for editing.
 */
export type LegacyTemplateBase = (
  context: TemplateContext
) => Omit<
  LegacyTemplate,
  "urlSlug" | "id" | "availableWithProducts" | "isPremium"
>;

/**
 * One of many values for a dropdown-type variable.
 */
interface TemplateVariableOption {
  label: string;
  html: string;
}

export interface TemplateVariableSliderValue {
  label: string;
  value: number;
}

/**
 * Variabeln beschreiben die Stellen in der Vorlage, die für die Anpassung durch den Verwender vorgesehen sind.
 */

export interface TemplateVariable {
  name: string;
  variableType: TemplateVariableType;
  description: string;
  options?: TemplateVariableOption[]; // for variableTypeVariants
  step?: number; // for variableTypeSlider
  sliderValues?: TemplateVariableSliderValue[]; // for variableTypeSlider
  min?: number; // for variableTypeSlider
  max?: number; // for variableTypeSlider
  defaultValue?: number; // for variableTypeSlider
  html?: string; // for switch and options
  unitInput?: string; // for variableTypeNumber
  unitInputSingle?: string; // for variableTypeNumber
  unitOutput?: string; // for variableTypeNumber and variableTypeSlider
  unitOutputSingle?: string; // for variableTypeNumber and variableTypeSlider
  decimalScale?: number; // for variableTypeNumber
  additionalInput?: Omit<TemplateVariableType, "variableTypeSwitch">;
  additionalInputPrefix?: string;
  additionalInputSuffix?: string;
}

/**
 * This entity represents the type of a variable that's used in a template.
 */
export interface TemplateVariableType {
  typeId: RVOVariableTypeId;
  baseType: BaseType;
  widget?: Widget;
  /**
   * Whether the navigation to the variable input
   *  should be disabled.
   */
  disableNavigation?: boolean;
}

export enum VorlageTyp {
  resolvioVorlage = "resolvioVorlage",
  vorlageAusBeschluss = "vorlageAusBeschluss",
}
export enum BaseType {
  number = "number",
  string = "string",
  static = "static",
}
export enum Widget {
  textarea = "textarea",
}

export enum VorlageKategorie {
  regelmäßigerBeschluss = "regelmäßigerBeschluss",
  geschäftsführerAusscheiden = "geschäftsführerAusscheiden",
  geschäftsführerNeu = "geschäftsführerNeu",
  ausscheidenEinesGesellschafters = "ausscheidenEinesGesellschafters",
  geschäftsführer = "geschäftsführer",
  steuerrecht = "steuerrecht",
  liquidation = "liquidation",
  beirat = "beirat",
  mitarbeiter = "mitarbeiter",
  weisungGeschäftsführer = "weisungGeschäftsführer",
  leererBeschluss = "leererBeschluss",
  bestätigung = "bestätigung",
  kapital = "kapital",
  struktur = "struktur",
  handelsrecht = "handelsrecht",
  prokura = "prokura",
  sanierung = "sanierung",
  übertragungBeteiligung = "übertragungBeteiligung",
  startup = "startup",
  gesellschaftsvertrag = "gesellschaftsvertrag",
  anbauvereinigung = "anbauvereinigung",
}

export enum RolleInGesellschaft {
  Mitglieder = "Mitglieder",
  Geschftsleiter = "Geschäftsleiter",
  Aufsichtsrat = "Aufsichtsrat",
  Beirat = "Beirat",
  Sonstige = "Sonstige",
}

export enum Vollzug {
  selfexecuting = "selfexecuting",
  beurkundung = "beurkundung",
  handelsregisterAnmeldung = "handelsregisterAnmeldung",
  handelsregisterEintragung = "handelsregisterEintragung",
  handelsregisterGesellschafterliste = "handelsregisterGesellschafterliste",
  transparenzregister = "transparenzregister",
  zustimmungGesellschafter = "zustimmungGesellschafter",
  zustimmungBetroffener = "zustimmungBetroffener",
  kapErhöhung_abgabeÜbernahmeerklärungen = "kapErhöhung_abgabeÜbernahmeerklärungen",
  kapErhöhung_sacheinlageEinbringungsvertrag = "kapErhöhung_sacheinlageEinbringungsvertrag",
  kapErhöhung_sacheinlageSachkapitalerhöhungsbericht = "kapErhöhung_sacheinlageSachkapitalerhöhungsbericht",
  kapErhöhung_sacheinlageUnterlagenWerthaltigkeit = "kapErhöhung_sacheinlageUnterlagenWerthaltigkeit",
  zugang = "zugang",
  bundesanzeiger = "bundesanzeiger",
}
