import { zustimmungInhalt } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";
import { vorlageZustimmungVertragsabschlussAnlage } from "./vorlageZustimmungVertragsabschlussAnlage";

export const vorlageZustimmungAllgemein: LegacyTemplateBase = ({
  insertTemplateVariable,
  insertVorlagenLink,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.geschäftsführer,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.zugang],
  bezug: [],
  zustimmungErforderlichDes: undefined,
  metaDescription: `Zustimmungsbeschluss der Gesellschafter für Vertragsschluss des Geschäftsführers (Genehmigung/Einwilligung). Juristisch geprüfte Muster/Vorlagen kostenlos.`,
  kurzBezeichnung: "Zustimmung zur Geschäftsführungsmaßnahme (Freitext)",

  description: `
  <p>
  Mit dieser Vorlage können <strong>für beliebige Geschäftsführungsmaßnahmen die Zustimmung</strong> der Gesellschafter (oder des Beirats/Aufsichtsrats) einholt werden.
  </p>

  <p>
  Beachte: Für die ${insertVorlagenLink(
    vorlageZustimmungVertragsabschlussAnlage,
    "Zustimmung zu einem Vertragsabschluss"
  )} haben wir eine Spezialvorlage vorbereitet.
  </p>
  `,
  tags: [
    "Entscheider",
    "Entscheidungsträger",
    "Direktor",
    "Director",
    "Vorstand",
    "Vorsitzender",
    "Führung",
    "Leitung",
    "Leiter",
    "Geschäftsführung",
    "Geschäftsleitung",
    "Geschäftsleiter",
    "Chef",
    "Leiter",
    "Vorgesetzter",
    "Führungskraft",
    "Geschäftsführer",
    "Manager",
    "CEO",
    "CTO",
    "CFO",
    "leader",
    "head",
    "chief",
    "executive",
    "boss",
    "governor",
    "governance",

    "feststellen",
    "Feststellung",
    "genehmigen",
    "absegnen",
    "zustimmen",
    "grünes Licht",
    "Weg frei machen",
    "Okay geben",
    "O.K. geben",
    "OK geben",
    "Erlaubnis",
    "Unterstützung",
    "erlauben",
    "unterstützen",
    "einwilligen",
    "Einwilligung",
    "Genehmigung",
    "Zustimmung",
    "Approval",
    "blessing",
    "confirmation",
    "consent",
    "endorsement",
    "green light",
    "persmission",
    "ratification",
    "support",
  ],
  antrag: `
  <p>
    Der Geschäftsführung wird für die Vornahme der folgenden Maßnahme die Zustimmung erteilt:
  </p>


    <ul data-list-style="none">
      <li><p>
      ${insertTemplateVariable(zustimmungInhalt)}
      </p></li>
    </ul>


  <p>
    Zum Abschluss des vorbezeichneten Rechtsgeschäfts wird zugleich Befreiung von den Beschränkungen des § 181 BGB erteilt, soweit hierfür erforderlich.
  </p>

  `,
  begründung: undefined,
  templateVariables: [zustimmungInhalt],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
