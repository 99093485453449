import { ComponentWithClassNameProps } from "@nvon/react-toolbox";
import { ReactNode } from "react";
import { EmojiComponent } from "../../01-atoms/Emoji/createEmoji";

export enum RVOModalLevel {
  Info = "Info",
  Warning = "Warning",
  Success = "Success",
  Setting = "Setting",
}

export enum RVOModalSize {
  Regular = "regular",
  Large = "large",
}

export interface RVOModalProps extends ComponentWithClassNameProps {
  disableClose?: boolean;
  isOpen: boolean;
  onClose: VoidFunction;
  title?: string;
  blur?: number;
  /**
   * When a simple string is provided, it gets wrapped in a Typography element.
   */
  children: string | ReactNode;
  /**
   * Defaults to "info".
   */
  level?: RVOModalLevel;
  /**
   * If an emoji is provided, the level is without effect.
   */
  emoji?: EmojiComponent;
  size?: RVOModalSize;
}

export interface RVOModalStyleProps {
  size: RVOModalSize;
}
