import { useCallback } from "react";
import { Page } from "../../../config/Pages";
import { pathForPageAndLanguage } from "./pathForPageAndLanguage";
import { useActiveLanguage } from "./useActiveLanguage";

export const usePathToPage = (): ((toPage: Page, hash?: string) => string) => {
  const activeLanguage = useActiveLanguage();

  return useCallback(
    (toPage: Page, hash?: string) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      return pathForPageAndLanguage(activeLanguage, toPage, hash);
    },
    [activeLanguage]
  );
};
