import {
  ausschüttung,
  bestehenderGewinnvortrag,
  bestehenderVerlustvortrag,
  bilanzgewinn,
  bilanzStichtag,
  jahresüberschuss,
  künftigerGewinnvortrag,
  rücklage,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageVerwendungDesGewinns: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe],
  vorlageKategorie: VorlageKategorie.regelmäßigerBeschluss,
  vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
  vollzug: [Vollzug.selfexecuting],
  bezug: ["Entlastung"],
  tags: [
    "Gewinn",
    "Ergebnis",
    "Erlös",
    "Ertrag",
    "Marge",
    "Rendite",
    "schwarze Zahlen",
    "Überschuss",
    "profit",
    "winnings",
    "gain",
    "win",

    "Gewinnvortrag",
    "Vortrag",
    "Bilanzgewinn",
    "Kapitalrücklage",
    "freie Rücklage",

    "Ausschüttung",
    "Ergebnisverteilung",
    "Gewinnausschüttung",
    "Gewinnverwendung",
    "Verteilung",
    "Aufteilung",
    "Zuteilung",
    "Auszahlung",
    "Dividende",
    "distribution",
    "payout",
    "dividend",
    "disburse",
    "disbursement",
    "distribute",
  ],
  description: `
  <p>
    Die Verwendung des Gewinns kann nur nach oder gleichzeitig der <a href="/beschluss-vorlagen/feststellung-des-jahresabschlusses"><strong>Feststellung des Jahresabschlusses</strong></a> erfolgen.
  </p>

  <p>
    Mit dem Beschluss über die Gewinnverwendung entsteht der Anspruch der Gesellschafter auf die <strong>Ausschüttung ihres Gewinnanteils</strong>.
  </p>
  `,
  kurzBezeichnung: "Verwendung des Gewinns (GmbH)",
  metaDescription:
    "Gesellschafterbeschluss für Gewinnverwendung/Gewinnausschüttung in der GmbH. Muster/Vorlagen sind juristisch geprüft - jetzt kostenlos nutzen.",
  antrag: `
  <p>
    Der Bilanzgewinn der Gesellschaft zum ${insertTemplateVariable(
      bilanzStichtag
    )} setzt sich wie folgt zusammen:
  </p>

    <ul data-list-style="none">
      <li><p>
      <table>
        <tr>
          <td>
            Jahresergebnis
          </td>
          <td>
              ${insertTemplateVariable(jahresüberschuss)} €
          </td>
        </tr>
        <tr>
          <td>
            zuzüglich Gewinnvortrag
          </td>
          <td>
              ${insertTemplateVariable(bestehenderGewinnvortrag)} €
          </td>
        </tr>
        <tr>
          <td>
            abzüglich Verlustvortrag
          </td>
          <td>
              ${insertTemplateVariable(bestehenderVerlustvortrag)} €
          </td>
        </tr>
            <tr>
          <td>
            <strong>Bilanzgewinn</strong>
          </td>
          <td>
              <strong>${insertTemplateVariable(bilanzgewinn)} €</strong>
          </td>
        </tr>
      </table>
      </p></li>
   </ul>


  <p>
    Der Bilanzgewinn wird wie folgt verteilt:
  </p>


    <ul data-list-style="none">
    <li><p>
      <table>
        <tr>
          <td>
            Ausschüttung an die Gesellschafter
          </td>
          <td>
              ${insertTemplateVariable(ausschüttung)} €
          </td>
        </tr>
        <tr>
          <td>
            Einstellung in die freie Rücklage
          </td>
          <td>
              ${insertTemplateVariable(rücklage)} €
          </td>
        </tr>
        <tr>
          <td>
            Gewinnvortrag
          </td>
          <td>
             ${insertTemplateVariable(künftigerGewinnvortrag)} €
          </td>
        </tr>
      </table>
      </p></li>
   </ul>

`,
  begründung: undefined,
  templateVariables: [
    bilanzStichtag,
    jahresüberschuss,
    bestehenderGewinnvortrag,
    bestehenderVerlustvortrag,
    bilanzgewinn,
    ausschüttung,
    rücklage,
    künftigerGewinnvortrag,
  ],
  isBeurkundungspflichtig: false,
  isHandelsregisterpflichtig: false,
});
