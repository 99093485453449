import { freitext } from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageAllgemein: LegacyTemplateBase = ({
  insertTemplateVariable,
}) => ({
  metaDescription:
    "Juristisch geprüfte Vorlagen/Muster für Gesellschafterbeschluss und für Beirat/Aufsichtsrat oder Geschäftsführer. Beschlussdokumente einfach erstellen.",
  vorlageTyp: VorlageTyp.resolvioVorlage,
  geeignetFuerRechtsformen: [
    CompanyTypeName.GmbHDe,
    CompanyTypeName.UgDe,
    CompanyTypeName.GmbHCoKgDe,
    CompanyTypeName.UgCoKgDe,
    CompanyTypeName.GbRDe,
    CompanyTypeName.KgDe,
    CompanyTypeName.OHgDe,
    CompanyTypeName.PartGmbBDe,
    CompanyTypeName.PartGDe,
  ],
  vorlageKategorie: VorlageKategorie.leererBeschluss,
  vorlage_rolleTyp: [
    RolleInGesellschaft.Mitglieder,
    RolleInGesellschaft.Geschftsleiter,
    RolleInGesellschaft.Aufsichtsrat,
    RolleInGesellschaft.Beirat,
  ],
  vollzug: [Vollzug.zugang],
  bezug: [],
  zustimmungErforderlichDes: undefined,
  kurzBezeichnung: "Allgemeine Beschlussvorlage",
  shouldStartWithoutTitle: true,
  titleInDocument: "Beschlussfassung",
  tags: [
    "phrase freely",
    "express freely",
    "empty resolution",
    "free resolution",
    "leeres Dokument",
    "frei formulieren",
    "ohne Muster",
    "kein Muster",
    "ohne Formular",
    "kein Formular",
    "ohne Vorlage",
    "keine Vorlage",
    "selbst formulieren",
    "frei schreiben",
  ],
  description: `
  <p>
  Hier kannst du ganz <strong>frei formulieren</strong>! Mache aus jeder Frage, die entschieden werden soll, einen Beschluss!
  </p>

   <p>
  Falls dir gerade die richtigen Worte fehlen: Vielleicht haben wir auch schon eine passende Vorlage für dich. Nutze einfach unsere <a href="/beschluss-vorlagen"><strong>Stichwortsuche</strong></a> dafür.
  </p>
  `,
  antrag: `<p>${insertTemplateVariable(freitext)}</p>`,
  begründung: undefined,
  templateVariables: [freitext],
  isHandelsregisterpflichtig: false,
  isBeurkundungspflichtig: false,
});
