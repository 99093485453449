import { stateBeschlussVorlageEmpty } from "../../beschlussVorlage/states/stateBeschlussVorlageEmpty";
import { stateCompanyInfoEmpty } from "../../companyInfo/states/stateCompanyInfoEmpty";
import { stateOnboardingHelpFresh } from "../../onboardingHelp/states/stateOnboardingHelpFresh";
import { RVORootState } from "../../storeInterfaces";
import { stateVotingModeEmpty } from "../../votingMode/states/stateVotingModeEmpty";

export const rootStateEmpty: RVORootState = {
  beschlussVorlage: stateBeschlussVorlageEmpty,
  companyInfo: stateCompanyInfoEmpty,
  votingMode: stateVotingModeEmpty,
  onboardingHelp: stateOnboardingHelpFresh,
};
