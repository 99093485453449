/**
 * The Grammatical Number.
 *
 * @see https://en.wikipedia.org/wiki/Grammatical_number
 */

export enum GrNumber {
  Singular = "Singular",
  Plural = "Plural",
}
