import {
  AllVotingModeSwitchValues,
  VotingMode,
  VotingModeSwitchKey,
} from "./votingModeInterfaces";
import { votingModeAsyncDecision } from "./votingModes/votingModeAsyncDecision";
import { votingModeFormalPlenum } from "./votingModes/votingModeFormalPlenum";
import { votingModePlenum } from "./votingModes/votingModePlenum";
import { votingModeSingleDecider } from "./votingModes/votingModeSingleDecider";

/**
 * From the choices the user makes on the voting mode switches,
 *  infer the desired voting mode.
 */
export const inferVotingModeFromSwitches = (
  switchValues: AllVotingModeSwitchValues
): VotingMode | null => {
  const multiplePeople =
    switchValues[VotingModeSwitchKey.AreMultiplePeopleInvolved];
  const unanimous = switchValues[VotingModeSwitchKey.IsUnanimous];
  const meetingInPerson =
    switchValues[VotingModeSwitchKey.DoPeopleMeetInPerson];

  if (multiplePeople === false) {
    return votingModeSingleDecider;
  }

  /*
   * 🚨 be sure to use `=== false` to check for a "no",
   *  since `null` is also a possible value.
   */

  if (multiplePeople && unanimous === false) {
    return votingModeFormalPlenum;
  }

  if (multiplePeople && unanimous) {
    if (meetingInPerson) {
      return votingModePlenum;
    }
    if (meetingInPerson === false) {
      return votingModeAsyncDecision;
    }
  }

  return null;
};
