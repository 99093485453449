import { rethrow } from "@nvon/baseline";
import i18nPackage from "i18next";
import { initReactI18next } from "react-i18next";
import { defaultLanguage } from "../../config/LanguageConfiguration";
import translationDe from "./locales/de.json";

export const resources = {
  de: { translation: translationDe },
};

// eslint-disable-next-line import/no-named-as-default-member
i18nPackage
  .use(initReactI18next)
  .init({
    debug: false,
    saveMissing: true,
    fallbackLng: defaultLanguage,
    resources,
    react: {
      useSuspense: false,
    },
  })
  .catch(rethrow);

export default i18nPackage;
