import {
  datumBUndGVertrag,
  nameBeherrschteGesellschaft,
  nameHerrschendeGesellschaft,
  sitzBeherrschteGesellschaft,
  sitzHerrschendeGesellschaft,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";

export const vorlageGesellschafterZustimmungBeherrUndGewinnabfuhrVertrag: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.GmbHDe, CompanyTypeName.UgDe],
    vorlageKategorie: VorlageKategorie.übertragungBeteiligung,
    vorlage_rolleTyp: [RolleInGesellschaft.Mitglieder],
    vollzug: [Vollzug.zugang],
    bezug: [],
    zustimmungErforderlichDes: undefined,
    kurzBezeichnung:
      "Zustimmung zu einem Beherrschungs- und Gewinnabführungsvertrag",
    description:
      "Die Zustimmungbeschluss der beherrschten Gesellschaft ist aufgrund seiner 'Satzungsgleichheit' beurkundungsbedüftig.",
    antrag: `Dem Beherrschungs- und Gewinnabführungsvertrag vom ${insertTemplateVariable(
      datumBUndGVertrag
    )}  zwischen der ${insertTemplateVariable(
      nameHerrschendeGesellschaft
    )} mit dem Sitz in ${insertTemplateVariable(
      sitzHerrschendeGesellschaft
    )} als herrschender Gesellschaft (Organträger) und der ${insertTemplateVariable(
      nameBeherrschteGesellschaft
    )}  mit dem Sitz in ${insertTemplateVariable(
      sitzBeherrschteGesellschaft
    )} als beherrschter Gesellschaft (Organgesellschaft) wird rückwirkend ab Beginn von dessen Laufzeit zugestimmt.
  `,
    begründung: undefined,
    templateVariables: [
      datumBUndGVertrag,
      nameHerrschendeGesellschaft,
      sitzHerrschendeGesellschaft,
      nameBeherrschteGesellschaft,
      sitzBeherrschteGesellschaft,
    ],
    isBeurkundungspflichtig: false,
    isHandelsregisterpflichtig: false,
  });
