import {
  LegacyTemplateBase,
  RolleInGesellschaft,
  Vollzug,
  VorlageKategorie,
  VorlageTyp,
} from "../VorlageInterface";
import {
  aufnahmegebuehrHoehe,
  grundbeitragGeldBasic,
  grundbeitragGeldBronze,
  grundbeitragGeldGold,
  grundbeitragGeldSilber,
  grundbeitragStundenBasic,
  grundbeitragStundenBronze,
  grundbeitragStundenGold,
  grundbeitragStundenSilber,
  vereinName,
} from "../allTemplateVariables";
import { CompanyTypeName } from "../generatedTypes/CompanyTypeName";

export const vorlageVereinAnbauvereinigungBeitragsordnung: LegacyTemplateBase =
  ({ insertTemplateVariable }) => ({
    vorlageTyp: VorlageTyp.resolvioVorlage,
    geeignetFuerRechtsformen: [CompanyTypeName.EVDe],
    vorlageKategorie: VorlageKategorie.anbauvereinigung,
    vorlage_rolleTyp: [RolleInGesellschaft.Geschftsleiter],
    vollzug: [Vollzug.zustimmungGesellschafter],
    bezug: [],
    tags: [
      "Verein",
      "Beitragsordnung",
      "Beiträge",
      "Gebühren",
      "Mitgliedsbeiträge",
      "Mitgliedsbeitrag",
      "Entgelt",

      "eV",
      "e.V.",
      "Anbauvereinigung",
      "Anbauverein",
      "Cannabis Social Club",
      "Cannabis Club",
      "Cannabis Verein",
      "Cannabis e.V.",
      "Cannabis Social Club e.V.",
      "Cannabis Club e.V.",
      "Cannabis Verein e.V.",
      "Cannabis Social Club Satzung",
      "Cannabis Club Satzung",
      "Cannabis Verein Satzung",
      "Cannabis e.V. Satzung",
      "CSC",
    ],
    zustimmungErforderlichDes: "",
    kurzBezeichnung:
      "Muster-Beitragsordnung für einen Cannabis Social Club e.V.",
    metaDescription:
      "Muster-Beitragsordnung für Anbauvereinigung (Cannabis Social Club CSC) als e.V./Verein gemäß Cannabisgesetz. Juristisch geprüfte Muster/Vorlagen kostenlos nutzen.",
    description: `
  <p>
    Dieses Muster enthält eine Standard-Beitragsordnung für einen Anbauvereinigung (Cannabis Social Club/CSC), der als Verein (e.V.) organisiert ist.
  </p>
  <p>
    Das Muster ist auf die ebenfalls von Resolvio zur Verfügung gestellte Mustersatzung abgestimmt und auf die spezifischen Bedürfnisse von Cannabis Social Clubs zugeschnitten. Die besonderen Anforderungen, die sich aus dem Cannabisgesetz ergeben, werden berücksichtigt.
  </p>
   <p>
        Neben einer Aufnahmegebühr können in diesem Muster monatliche Mitgliedbeiträge (in Geld und Arbeit) in <strong>4 Tarifstufen</strong> festgelegt werden. Die Mitglieder erhalten dafür monatliche Bezüge von Cannabis und Anbauprodukten.
  </p>
  <p>
       Die ehrenamtliche Arbeitsleistungen können in allen Tarifstufen durch einen erhöhten Geldbeitrag ersetzt werden. Von diesen Geldbeträgen kann der Cannabis Social Club andere Mitglieder per Minijob anstellen. (§ 17 Abs. 1 Cannabisgesetz)
  </p>
<p>
    Sofern Ihr CSC-Verein nicht die Resolvio-Mustersatzung verwendet, empfehlen wir Ihnen, die Beitragsordnung von einem Rechtsanwalt prüfen zu lassen, um sicherzustellen, dass die Beitragsordnung mit der Vereinssatzung und den sonstigen Vereinsordnungen des Vereins in Einklang steht. Wenden Sie sich hierfür gerne <strong>an einen Resolvio-Partneranwalt</strong>.
</p>
  `,
    antrag: `
  <h2>
    Beitragsordnung des Vereins ${insertTemplateVariable(vereinName)} e.V.
 </h2>
   <ol data-list-style="decimal" data-list-style-prefix="§">
    <li>
      <h3>Satzungsgrundlage</h3>
       <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
        <li>
            <p>
                Die Beitragsordnung wird durch den Vorstand des ${insertTemplateVariable(
                  vereinName
                )} e.V. beschlossen, der hierzu durch die Satzung des Vereins ermächtigt ist. Damit die Beitragsordnung in Kraft tritt, bedarf es jedoch eines Zustimmungsbeschlusses der Vereinsmitglieder. Der Zustimmungsbeschluss der Vereinsmitglieder wird gesondert über Resolvio gefasst.
            </p>
        </li>


      </ol>
    </li>

    <li>
        <h3>
           Aufnahmegebühr
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
             <li>
                <p>
                    Der Verein erhebt eine <strong>Aufnahmegebühr</strong> in Höhe von ${insertTemplateVariable(
                      aufnahmegebuehrHoehe
                    )}.
                </p>
            </li>
            <li>
                <p>
                   Die Aufnahmegebühr ist mit der Antragstellung und Aufnahme in den Verein fällig.
                </p>
            </li>

        </ol>
    </li>

    <li>
        <h3>
            Beiträge, Bezug von Anbauprodukten, Tarifstufen
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Der Verein erhebt von seinen Mitgliedern einen laufenden Beitrag, der zu dem Bezug von den Anbauprodukten des Vereins berechtigt. Die Höhe der laufenden Beiträge und der entsprechenden Bezüge richtet sich nach der vom Mitglied gewählten Tarifstufe "Basic", "Bronze", "Silber" oder "Gold". Die Tarifstufe kann von den Mitgliedern höchstens alle 3 Monate durch Erklärung gegenüber dem Vorstand gewechselt werden mit 6 Wochen Vorlaufzeit.
                </p>
            </li>
            <li>
                <p>
                    Der Beitrag im Tarif <strong>"Basic"</strong> beträgt ${insertTemplateVariable(
                      grundbeitragGeldBasic
                    )}/Monat und ${insertTemplateVariable(
      grundbeitragStundenBasic
    )} Stunden/Monat ehrenamtliche Arbeit im Zusammenhang mit dem Cannabis-Anbau. Vereinsmitglieder im Basic-Tarif erhalten:
                </p>
                 <ol data-list-style="lower-latin">
                    <li>
                        <p>
                            höchstens 20 Gramm Cannabis pro Monat aus dem gemeinschaftlichen Eigenanbau des Vereins,
                        </p>
                    </li>
                    <li>
                        <p>
                            insgesamt höchstens 3 Samen oder 2 Stecklinge oder jeweils insgesamt 3 Samen und Stecklinge pro Monat aus dem gemeinschaftlichen Eigenanbau des Vereins.
                        </p>
                    </li>
                </ol>
            </li>
             <li>
                <p>
                    Der Beitrag im Tarif <strong>"Bronze"</strong> beträgt ${insertTemplateVariable(
                      grundbeitragGeldBronze
                    )}/Monat und ${insertTemplateVariable(
      grundbeitragStundenBronze
    )} Stunden/Monat ehrenamtliche Arbeit im Zusammenhang mit dem Cannabis-Anbau. Vereinsmitglieder im Bronze-Tarif erhalten:
                </p>
                 <ol data-list-style="lower-latin">
                    <li>
                        <p>
                            höchstens 30 Gramm Cannabis pro Monat aus dem gemeinschaftlichen Eigenanbau des Vereins,
                        </p>
                    </li>
                    <li>
                        <p>
                            insgesamt höchstens 4 Samen oder 3 Stecklinge oder jeweils insgesamt 4 Samen und Stecklinge pro Monat aus dem gemeinschaftlichen Eigenanbau des Vereins.
                        </p>
                    </li>
                </ol>
            </li>
            <li>
                <p>
                    Der Beitrag im Tarif <strong>"Silber"</strong> beträgt ${insertTemplateVariable(
                      grundbeitragGeldSilber
                    )}/Monat und ${insertTemplateVariable(
      grundbeitragStundenSilber
    )} Stunden/Monat ehrenamtliche Arbeit im Zusammenhang mit dem Cannabis-Anbau. Vereinsmitglieder im Silber-Tarif erhalten:
                </p>
                 <ol data-list-style="lower-latin">
                    <li>
                        <p>
                            höchstens 40 Gramm Cannabis pro Monat aus dem gemeinschaftlichen Eigenanbau des Vereins,
                        </p>
                    </li>
                    <li>
                        <p>
                            insgesamt höchstens 5 Samen oder 4 Stecklinge oder jeweils insgesamt 5 Samen und Stecklinge pro Monat aus dem gemeinschaftlichen Eigenanbau des Vereins.
                        </p>
                    </li>
                </ol>
            </li>
            <li>
                <p>
                    Der Beitrag im Tarif <strong>"Gold"</strong> beträgt ${insertTemplateVariable(
                      grundbeitragGeldGold
                    )}/Monat und ${insertTemplateVariable(
      grundbeitragStundenGold
    )} Stunden/Monat ehrenamtliche Arbeit im Zusammenhang mit dem Cannabis-Anbau. Vereinsmitglieder im Gold-Tarif erhalten:
                </p>
                 <ol data-list-style="lower-latin">
                    <li>
                        <p>
                            höchstens 50 Gramm Cannabis pro Monat aus dem gemeinschaftlichen Eigenanbau des Vereins,
                        </p>
                    </li>
                    <li>
                        <p>
                            insgesamt höchstens 7 Samen oder 5 Stecklinge oder jeweils insgesamt 7 Samen und Stecklinge pro Monat aus dem gemeinschaftlichen Eigenanbau des Vereins.
                        </p>
                    </li>
                </ol>
            </li>
            <li>
                <p>
                    Unabhängig von der Tarifstufe darf an Mitglieder, die noch nicht das 21. Lebensjahr vollendet haben, höchstens 30 Gramm Cannabis pro Monat weitergegeben werden, das einen THC-Gehalt von zehn Prozent nicht überschreitet.
                </p>
            </li>
        </ol>
    </li>

    <li>
        <h3>
            Fälligkeit der Beiträge, Ersetzung der Arbeitsleistung, Abruf der Anbauprodukte, Anteilige Kürzung bei Knappheit
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">

             <li>
                <p>
                    Die Beiträge in Geld sind monatlich im Voraus zu entrichten. Die entsprechende Arbeitsleistung ist im Laufe des Monats in Abstimmung mit dem Verein zu erbringen.
                </p>
            </li>
              <li>
                <p>
                    Vereinsmitglieder können die ehrenamtliche Arbeitsleistung dadurch ersetzen, dass Sie den doppelten Geldbetrag der jeweiligen Tarifstufe an den Verein entrichten. Dies ist dem Verein zusammen mit der Wahl der Tarifstufe mitzuteilen.
                </p>
            </li>
            <li>
                <p>
                    Die Bezugsmengen der Anbauprodukte können im Laufe des jeweiligen Monats von dem Mitglied abgerufen werden. Die Bezugsmengen, die in dem jeweiligen Monat abgerufen werden, können nicht auf die Folgemonate übertragen werden; der entsprechende Anspruch verfällt ohne Beitragserstattung.
                </p>
            </li>
            <li>
                <p>
                    Sofern der vorhandene Anbauertrag in einem Monat nicht ausreicht, um sämtliche Bezugsmengen zu decken, werden die Bezugsmengen bei allen Mitgliedern anteilig gegen Erstattung von etwaig bereits geleisteten Beiträgen gekürzt.
                </p>
            </li>
        </ol>
    </li>

    <li>
        <h3>
            Zahlungsform
        </h3>
        <ol data-list-style="decimal" data-list-style-prefix="(" data-list-style-suffix=")">
            <li>
                <p>
                    Die Mitgliedsbeiträge und sonstigen Gebühren werden in im SEPA-Lastschriftverfahren eingezogen. Die Mitglieder sind verpflichtet, dem Vorstand bei Aufnahme in dem Verein ein SEPA-Lastschriftmandat zu erteilen.
                </p>
            </li>
             <li>
                <p>
                    Erteilt ein Mitglied keine Einzugsermächtigung, ist der Verein berechtigt, den erhöhen Verwaltungsaufwand pauschal mit 20,00 Euro in Rechnung zu stellen
                </p>
            </li>
              <li>
                <p>
                   Kann der Bankeinzug aus Gründen, die das Mitglied zu vertreten hat, nicht durchgeführt werden, ist der Verein berechtigt, die dadurch entstehenden Kosten dem Mitglied in Rechnung zu stellen.
                </p>
            </li>
        </ol>
    </li>

    <li>
        <h3>
            Zahlungsrückstand
        </h3>
        <p>
            Bei einem Zahlungsrückstand beträgt die Mahngebühr 5,00 Euro je Mahnung.
        </p>
    </li>

    <li>
        <h3>
            Inkrafttreten
        </h3>
        <p>
            Diese Beitragsordnung tritt in Kraft, wenn die Vereinsmitglieder die Zustimmung zu dieser Beitragsordnung per Beschluss erteilt haben. Sofern bereits eine Beitragsordnung besteht, tritt diese Beitragsordnung an die Stelle der bisherigen Beitragsordnung.
        </p>
    </li>

  </ol>`,
    begründung: undefined,
    vollzugshinweise: `
    <p>
        Diese Beitragsordnung muss zunächst durch den <strong>Vereinsvorstand</strong> beschlossen werden.
    </p>
    <p>
        Anschließend muss die Beitragsordnung durch die <strong>Vereinsmitglieder</strong> genehmigt werden. Die Zustimmung der Vereinsmitglieder erfolgt durch einen gesonderten Beschluss, der über Resolvio als Umlaufbeschluss einfach und rechtssicher gefasst werden kann:
    </p>
    <ul>
        <li>
            <p>
                Um den Zustimmungsbeschluss über Resolvio rechtssicher zu gestalten, sollte in Resolvio für den Cannabis Social Club ein neues Gremium "Mitglieder" erstellt werden und dort alle Vereinsmitglieder per Email eingeladen werden (sofern nicht bereits geschehen).
            </p>
        </li>
        <li>
            <p>
                Im Anschluss kann ein Zustimmungsbeschluss mit dem folgenden Freitext erstellt werden: "Der in der Anlage beigefügten Beitragsordnung wird zugestimmt."
            </p>
        </li>
        <li>
            </p>
                Als Anlage kann der vorher gefasste Beitragsordnung-Vorstandsbeschluss als PDF-Datei hochgeladen werden.
            </p>
        </li>
    </ul>
     <p>
        Da durch diese Beitragsordnung die Satzung nicht geändert wird, ist eine Anmeldung oder Eintragung in das Vereinsregister <strong>nicht erforderlich</strong>.
    </p>
    <p>
       Wenn die Geldbeträge oder Arbeitsstunden später einmal geändert werden sollen, ist ein neuer Vorstandsbeschluss und Mitgliederbeschluss erforderlich. Auch hierfür kann dieses Muster genutzt werden: Einfach neue Beträge eintragen, Abstimmung starten bei Vorstand und Vereinsmitgliedern und fertig.
    </p>
    `,
    templateVariables: [
      vereinName,
      aufnahmegebuehrHoehe,
      grundbeitragGeldBasic,
      grundbeitragStundenBasic,
      grundbeitragGeldBronze,
      grundbeitragStundenBronze,
      grundbeitragGeldSilber,
      grundbeitragStundenSilber,
      grundbeitragGeldGold,
      grundbeitragStundenGold,
    ],
  });
